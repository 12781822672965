import React, { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";

import {
    getLangText,
    MultiLangText,
    setAnimationTimeout,
    secToMs,
} from "../shared";
import { DEFAULT_DURATION_S } from "../constants/transition";
import { BaseChapterProps } from "./ChapterRenderer";
import Grid from './Grid';
import TitleText from "./TitleText";

import '../styles/screens/title-card.scss';


export type TitleProps = BaseChapterProps & {
    title: MultiLangText;
}

const cellWidth = 216;
const cellHeight = 216;
const gridLineThickness = 1;
const gridDuration = DEFAULT_DURATION_S;
const codificationCharDuration = 0.03;
const characterNextTrigger = 2;
const languageTransitionDelay = 0.25;
const pause = 4;

export default function Title(props: TitleProps) {
    const {
        width,
        height,
        title,
        languages,
        theme,
    } = props;

    const cols = Math.ceil(width / cellWidth);
    const rows = Math.ceil(height / cellHeight);

    const [toShowTitle, setToShowTitle] = useState(false);
    const [langIndex, setLangIndex] = useState(0);
    const [nextLangIndex, setNextLangIndex] = useState(0);

    useEffect(() => {
        return setAnimationTimeout(() => setToShowTitle(true), secToMs(gridDuration));
    }, []);

    useEffect(() => {
        if (nextLangIndex === langIndex) {
            return;
        }

        return setAnimationTimeout(() => {
            setLangIndex(nextLangIndex);
        }, secToMs(pause));
    }, [nextLangIndex]);

    return (
        <>
            <Grid
                width={cellWidth * cols}
                height={cellHeight * rows}
                cols={cols}
                rows={rows}
                initialAnimation={'hidden'}
                animation={'in'}
                style={{
                    position: 'absolute'
                }}
                maxDuration={gridDuration}
            />
            <AnimatePresence>
                {toShowTitle ? (
                    <TitleText
                        text={title}
                        languages={languages}
                        theme={theme}
                        langIndex={langIndex}
                        cellLength={cellWidth}
                        cols={cols}
                        rows={rows}
                        delay={gridDuration}
                        gridLineThickness={gridLineThickness}
                        onTyped={() => {
                            setNextLangIndex(i => {
                                return (i >= languages.length - 1) ? i : (i + 1);
                            });
                        }}
                    />
                ) : null}
            </AnimatePresence>
        </>
    );
}

export function calculateDuration(data: TitleProps) {
    const {
        languages
    } = data;

    const entryDuration = gridDuration * 2;

    const maxTitle = languages.reduce((result, lang) => Math.max(result, getLangText(data.title, lang).length), 0);
    const oneLangDuration = maxTitle * codificationCharDuration * characterNextTrigger + languageTransitionDelay;
    // extra 0.5s is to compensate the uneven framerate for cidification
    const textAnimation = (oneLangDuration + pause + 0.5) * languages.length;

    const total = entryDuration + textAnimation;

    const exitDuration = calculateExitDuration(data);

    return secToMs(total) + exitDuration;
}

export function calculateExitDuration(data: TitleProps) {
    const duration = 2 * DEFAULT_DURATION_S + gridDuration;

    return secToMs(duration);
}
