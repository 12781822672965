// const {
//   lineColor = 0,
//   lineThickness = 1,
//   antialias = true,
//   autoDensity = true,
//   resolution = window.devicePixelRatio || 1,
//   preserveDrawingBuffer = false,
//   maxFPS = 60,
// } = config;
import * as Hyperlive from "../../hyperlive/utils";
import {ANIMATION_TYPES} from "../../../../conductor/entry";

export function configOverride() {
  const params = new URLSearchParams(window.location.search);
  let overrides = {
    multiplier: 1,
  };

  if (params.has('multiplier')) {
    overrides.resolution = Number(params.get('multiplier'));
    overrides.multiplier = Number(params.get('multiplier'));
  }

  if (params.has('lineColor')) {
    overrides.lineColor = Number(params.get('lineColor'));
  }

  if (params.has('lineThickness')) {
    overrides.lineThickness = Number(params.get('lineThickness')) * overrides.multiplier;
  }

  if (params.has('antialias')) {
    overrides.antialias = params.get('antialias') === 'true';
  }

  if (params.has('autoDensity')) {
    overrides.autoDensity = params.get('autoDensity') === 'true';
  }

  if (params.has('resolution')) {
    overrides.resolution = Number(params.get('resolution'));
  }

  if (params.has('preserveDrawingBuffer')) {
    overrides.preserveDrawingBuffer = params.get('preserveDrawingBuffer') === 'true';
  }

  if (params.has('maxFPS')) {
    overrides.maxFPS = Number(params.get('maxFPS'));
  }

  if (params.has('size')) {
    overrides.size = params.get('size');
  }

  if (params.has('qr-width')) {
    overrides.qrWidth = Number(params.get('qr-width'));
  }

  // can be either `svg` or `false` to remove
  if (params.has('circle')) {
    overrides.circle = params.get('circle');
  }

  if (params.has('circleThickness')) {
    overrides.circleThickness = Number(params.get('circleThickness'));
  }

  // Can be 0-255 gray scale value, or hex color without the `#`.
  if (params.has('circleColor')) {
    overrides.circleColor = params.get('circleColor');
  }

  if(params.has('disableWebGl')) {
    overrides.disableWebGl = params.get('disableWebGl') === 'true';
  }

  if (params.has('fontSizeMultiplier')) {
    overrides.fontSizeMultiplier = Number(params.get('fontSizeMultiplier'));
  }

  if (params.has('svg-lines')) {
    overrides.svgLines = params.get('svg-lines') === 'true';
  }

  return overrides;
}