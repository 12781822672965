.chapter_container {
    display: grid;
    grid-template-areas:
        "main"
        "footer";
    height: 100%;

    .main_container {
        position: relative;
    }

}
