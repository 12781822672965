import {PillarType} from "../shared";
import {motion, AnimatePresence} from "framer-motion";
import {useMemo} from "react";

const VIDEO_ORBS: Record<PillarType, string> = {
    rest: "https://res.cloudinary.com/jbboerner/video/upload/v1693599371/ecosystemboard/NIKE_FUTUREFITNESS_GRADIENT_Rest_v3.mp4",
    nutrition: "https://res.cloudinary.com/jbboerner/video/upload/v1693599365/ecosystemboard/NIKE_FUTUREFITNESS_GRADIENT_Nutrition_v3.mp4",
    movement: "https://res.cloudinary.com/jbboerner/video/upload/v1693599363/ecosystemboard/NIKE_FUTUREFITNESS_GRADIENT_Movement_v3.mp4",
    connection: "https://res.cloudinary.com/jbboerner/video/upload/v1693599360/ecosystemboard/NIKE_FUTUREFITNESS_GRADIENT_Connection_v3.mp4",
    mindfulness: "https://res.cloudinary.com/jbboerner/video/upload/v1693599313/ecosystemboard/NIKE_FUTUREFITNESS_GRADIENT_Mindfulness_v3.mp4",
};

export default function OrbVideo({pillar}: { pillar: PillarType }) {
    const videoUrl = useMemo(() => {
        const url = VIDEO_ORBS?.[pillar] ?? VIDEO_ORBS.rest;

        return url.replace('upload/', 'upload/q_auto,w_1080/');
    }, [pillar]);

    return (
        <AnimatePresence>
            <motion.div
                key={`media-${pillar}`}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 0,
                }}
                initial={{
                    opacity: 0,
                }}
                animate={{
                    opacity: 1,
                }}
                exit={{
                    opacity: 0,
                }}
            >
                <video
                    src={videoUrl}
                    autoPlay
                    muted
                    loop
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        zIndex: 1,
                    }}/>
            </motion.div>
        </AnimatePresence>
    )
}