import {
    CSSProperties,
    useEffect,
    useMemo,
    useState
} from "react";

type ContainerProps = {
    width: number;
    height: number;
    children: any;
    framed?: boolean;
    circle?: boolean;
    forceColor?: string;
    style?: CSSProperties;
}

export default function Container(props: ContainerProps) {
    const {
        width,
        height,
        children,
        framed,
        circle,
        forceColor,
        style: customStyles
    } = props;
    const [forceFrame, setForceFrame] = useState(framed);
    const style = useMemo<CSSProperties>(() => ({
        width: forceFrame ? width : undefined,
        height: forceFrame ? height : '100%',
        backgroundColor: forceColor ?? 'white',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: (circle && forceFrame) ? width : undefined,
        ...customStyles,
    }), [forceFrame, width, height, circle]);

    useEffect(() => {
        // hitting Alt+Space will toggle forcing dimentions
        addEventListener('keydown', ({code, altKey}) => {
            if (code === 'Space' && altKey) {
                setForceFrame(i => !i);
            }
        });
    }, []);

    return (
        <div style={{width: '100vw', height: '100vh', overflow: 'hidden'}}>
            <div style={style}>
                {children}
            </div>
        </div>
    );
}
