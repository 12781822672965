import {pixelMapReady} from "../../../../utilities";

if (module.hot) {
  module.hot.accept(() => {
    location.reload()
  })
}

import * as THREE from 'three';
import { Pane } from 'tweakpane';
import { debounce } from 'lodash';

import Intro from './sections/intro/intro.js';
import Grid from './sections/grid/grid.js';
import Snkr from './sections/snkr/snkr.js';


export default function (drops, data, demo= false) {
  window.SIZE_ID = (Math.min(window.innerWidth, window.innerHeight) > 719)
    ? 'LARGE'
    : (Math.min(window.innerWidth, window.innerHeight) > 479)
      ? 'MEDIUM'
      : 'SMALL'

  const apps = [];
  let appIndex = 0;
  let running = false;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const showGUI = urlParams.get('showGUI');
  const noAutoStart = urlParams.get('noAutoStart');
  const chapterName = urlParams.get('chapterName');
  const chapterIndex = urlParams.get('chapter') || 'null';
  const keyframe = urlParams.get('keyframe');
  const iframeIndex = urlParams.get('index');
  let renderers = {};
  let canvasWrapperFront = document.createElement('div');
  let canvasWrapperCutout = document.createElement('div');

  if (Number.isInteger(Number(chapterIndex))) {
    appIndex = Number(chapterIndex);
  }
  const maxItems = Number(data?.max_entries ?? 16);
  let reducedDatas = drops.length > maxItems ? drops.slice(0, maxItems): drops;
  const activeApps = {
    'intro': !chapterName || (chapterName === 'intro'),
    'grid': !chapterName || (chapterName === 'grid'),
    'snkr': !chapterName || (chapterName === 'snkr'),

  };

  const gui = new Pane({
    title: 'SnkrsCal',
    container: document.querySelector('.LLS-gui')
  });
  gui.hidden = !showGUI;

  gui.addButton({ title: 'restart', label: 'start (shift + space)' }).on('click', (e) => {
    appIndex = 0;
    apps[appIndex].start(keyframe);
    if (apps[appIndex].gui) {
      apps[appIndex].gui.hidden = !showGUI;
    }
  });
  gui.addButton({ title: 'play', label: 'play (space)' }).on('click', (e) => {
    apps[appIndex].resume();
  });
  gui.addButton({ title: 'pause', label: 'pause (space)' }).on('click', (e) => {
    apps[appIndex].pause();
  });

  const guiF1 = gui.addFolder({
    title: 'chapters list',
    expanded: demo
  });

  function addApp (name, app) {
    activeApps[name] && apps.push(app);
    guiF1.addInput(activeApps, name).on('change', (e) => {
      if (e.value) {
        apps.push(app);
      } else {
        const index = apps.indexOf(app);
        if (index !== -1) {
          apps.splice(index, 1);
        }
      }
    });
  }

  function nextApp () {
    if (apps[appIndex]) {
      apps[appIndex].stop();
      if (apps[appIndex].gui) {
        apps[appIndex].gui.hidden = true;
      }
    }
    if (chapterIndex !== 'null' ) {
      appIndex = (appIndex % apps.length);
    } else {
      appIndex = ((appIndex + 1) % apps.length);
    }

    apps[appIndex].start(keyframe);
    
    if (apps[appIndex].gui) {
      apps[appIndex].gui.hidden = !apps[appIndex].gui.hidden;
    }
  }

  function createRenderers () {
    const rendererBack = new THREE.WebGLRenderer({ antialias: true, alpha: true, });
    const rendererCutout = new THREE.WebGLRenderer({ antialias: true, alpha: true, });
    renderers = { front: rendererBack, cutout: rendererCutout}
 
    canvasWrapperFront.classList.add('canvas-wrapper-front');
    canvasWrapperFront.style.position = 'absolute';
    canvasWrapperFront.style.top = 0;
    canvasWrapperFront.style.left = 0;
    canvasWrapperFront.style.zIndex = 20;
    canvasWrapperFront.style.display = 'none';


    canvasWrapperCutout.classList.add('canvas-wrapper-cutout');
    canvasWrapperCutout.style.position = 'absolute';
    canvasWrapperCutout.style.top = 0;
    canvasWrapperCutout.style.zIndex = 10;

  
    canvasWrapperFront.appendChild(rendererBack.domElement);
    canvasWrapperCutout.appendChild(rendererCutout.domElement);
    document.body.appendChild(canvasWrapperFront);
    document.body.appendChild(canvasWrapperCutout);
  }

  createRenderers();
  const intro = new Intro('Intro', reducedDatas, data, nextApp, apps.length, renderers);
  const grid = new Grid('Grid', reducedDatas, nextApp, apps.length, renderers);
  const snkr = new Snkr('Snkr', reducedDatas, nextApp, apps.length, renderers);
  

  addApp('intro', intro);
  if(reducedDatas.length > 0) {
    addApp('grid', grid);
    addApp('snkr', snkr);
  }

  window.addEventListener('keypress', (e) => {
    if (e.code === 'Space') {
      if (e.shiftKey) {
        if (apps[appIndex].running) {
          apps[appIndex].stop();
        }
        appIndex = 0;
        if (apps[appIndex].gui) {
          apps[appIndex].gui.hidden = !apps[appIndex].gui.hidden;
        }
        apps[appIndex].start(keyframe);
      } else {
        if (apps[appIndex].running) {
          apps[appIndex].pause();
        } else {
          apps[appIndex].resume();
        }
      }
    } else if (e.code === 'KeyH') {
      gui.hidden = !gui.hidden;
      if (apps[appIndex].gui) {
        apps[appIndex].gui.hidden = !apps[appIndex].gui.hidden;
      }
    }
  }, false);

  function resetApps () {
    location.reload();
  }

  window.addEventListener('resize', debounce(resetApps, 500, { trailing: true, leading: false }), false);

  pixelMapReady(() => {
    appIndex = 0;
    apps[appIndex].start(keyframe);
  })

  if (!noAutoStart) apps[appIndex]?.start(keyframe);
};
