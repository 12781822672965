import './verticalText.scss';

export default function verticalText (content) {
  const div = document.createElement('div');
  div.classList.add('vertical-text');

  const h2 = document.createElement('h2');
  h2.innerHTML = content.lowercase ? content.text : content.text.toUpperCase();
  div.appendChild(h2);
 
  return div;
}
