import { fetchJSON } from '/src/touchpoints/rise/hyperlive/utils';

import '../../styles/universal-live-template.scss';
import createApp from './app'

import presentation from './presentation';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const demo = urlParams.get('demo_mode');

console.log('demo', demo);

const { json_data } = !!demo ? presentation.animation : fetchJSON();
console.log(json_data);

createApp(json_data, !!demo);
