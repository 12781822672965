import React from 'react';

interface BackgroundProps {
    variant: 'black-grit' | 'dark-dust' | 'gray-grit' | 'light-dust' | 'red-hard-dust' | 'red-print-wash' | 'black-print-wash';
    children?: any;
    hide?: boolean;
}

// I know this looks ugly but Parcel's resolving engine for assets is quite dumb.
const images = {
    'black-grit': new URL(`../assets/images/backgrounds/black-grit.png?as=webp&width=1080`, import.meta.url),
    'dark-dust': new URL('../assets/images/backgrounds/dark-dust.png?as=webp&width=1080', import.meta.url),
    'gray-grit': new URL('../assets/images/backgrounds/gray-grit.png?as=webp&width=1080', import.meta.url),
    'light-dust': new URL('../assets/images/backgrounds/light-dust.png?as=webp&width=1080', import.meta.url),
    'red-hard-dust': new URL('../assets/images/backgrounds/red-hard-dust.png?as=webp&width=1080', import.meta.url),
    'red-print-wash': new URL('../assets/images/backgrounds/red-print-wash.png?as=webp&width=1080', import.meta.url),
    'black-print-wash': new URL('../assets/images/backgrounds/black-print-wash.png?as=webp&width=1080', import.meta.url),
}

export default function Background({variant, children, hide}: BackgroundProps) {
    const style = hide === true ? {} : {
        backgroundImage: `url(${images[variant]})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }

    return (
        <div
            style={{
                ...style,
                display: 'flex',
                height: '100%'
            }}
        >
            {children}
        </div>
    )
}