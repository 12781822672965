import _ from 'lodash';

const pulseDataHydrator = async () => {
	const promises = window.animations.map(({ json_data }) => {
		const endpoint = _.get(json_data, `pulse_data_story.endpoint`);
		if (endpoint) {
			return req(endpoint);
		}
	});

	const results = await Promise.all(promises);

	results.map((result, index) => {
		if (!result) {
			return;
		}
		const pulse = _.get(
			window.animations,
			`[${index}].json_data.pulse_data_story`,
		);

		let data = null;

		if (pulse.type === 'snapshot') {
			const { sport_pulse_snapshots = [] } = result;
			data = sport_pulse_snapshots.find(snapshot => {
				return _.get(snapshot, pulse.key, null) !== null;
			});
		} else if (pulse.type === 'story') {
			const [stories = {}] = result;
			data = stories;
		}

		_.set(
			window.animations[index],
			`json_data.pulse_data_story.data`,
			data,
		);
	});
};

const req = async url => {
	const response = await fetch(`${url}`);

	return response.json();
};

export default pulseDataHydrator;
