import React, { useEffect, useMemo, useState } from 'react';
import { AnimatePresence, usePresence } from 'framer-motion';

import {
    getLangText,
    MultiLangText,
    secToMs,
    setAnimationTimeout,
} from '../../shared';
import {
    BaseChapterProps,
} from '../../components/ChapterRenderer';
import
    CodificationWithTranslations,
    { calculateDuration as calculateCodificationDuration }
from '../../components/Codification/CodificationWithTranslations';
import GridWithCells from '../../components/GridWithCells';

import '../../styles/screens/product/intro-v1.scss';


export type IntroV1Props = BaseChapterProps & {
    text: MultiLangText,
}


const cellLength = 68;
const gridYOffset = -2;
const gridXOffset = -38;

const codificationCharDuration = 0.03;
const characterSwitchAmount = 4;
const characterNextTrigger = 2;
const languageTransitionDelay = 0.25;
const pause = 4;

export default function IntroV1(props: IntroV1Props) {
    const {
        text,
        languages,
        width,
        height,
    } = props;

    const [langIndex, setLangIndex] = useState(0);
    const [nextLangIndex, setNextLangIndex] = useState(0);
    const [isPresent, safeToRemove] = usePresence();
    const gridRows = Math.ceil((height - gridYOffset) / cellLength);
    const gridCols = Math.ceil((width - gridXOffset) / cellLength);
    const gridWidth = gridCols * cellLength;
    const gridHeight = gridRows * cellLength;

    useEffect(() => {
        if (nextLangIndex === langIndex) {
            return;
        }

        return setAnimationTimeout(() => {
            setLangIndex(nextLangIndex);
        }, secToMs(pause));
    }, [nextLangIndex]);

    const textComponent = useMemo(() => (
        <CodificationWithTranslations
            text={text}
            languages={languages}
            langIndex={langIndex}
            codificationProps={{
                characterSwitchAmount,
                characterNextTrigger,
                timingConfig: {
                    control: 'character',
                    duration: codificationCharDuration,
                },
            }}
            runFinalTextAnimation
            languageTransitionDelay={languageTransitionDelay}
            onTyped={() => {
                setNextLangIndex(i => {
                    return (i >= languages.length - 1) ? i : (i + 1);
                });
            }}
        />
    ), [text, langIndex]);

    return (
        <div className='product-intro-v1'>
            <div
                className='grid-container'
                style={{
                    top: `${gridYOffset}px`,
                    left: `${gridXOffset}px`,
                }}
            >
                <GridWithCells
                    rows={gridRows}
                    cols={gridCols}
                    height={gridHeight}
                    width={gridWidth}
                    cellLength={cellLength}
                />
            </div>
            <div className='text-container'>
                <div className='text'>
                    <AnimatePresence onExitComplete={() => !isPresent && safeToRemove()}>
                        {isPresent ? textComponent : null}
                    </AnimatePresence>
                </div>
            </div>
        </div>
    );
}

export function calculateDuration(data: IntroV1Props) {
    const {
        text,
        languages,
    } = data;
    const entryDuration = 0;

    const textDuration = calculateCodificationDuration({
        text,
        languages,
        codificationCharDuration,
        characterNextTrigger,
        characterSwitchAmount,
        languageTransitionDelay,
    });

    const mainDuration = textDuration + pause * languages.length;

    const total = entryDuration + mainDuration;

    const duration = secToMs(total) + calculateExitDuration(data);

    return duration;
}

export function calculateExitDuration(data: IntroV1Props) {
    const {
        languages,
    } = data;

    const maxText = languages.reduce((result, lang) => {
        const text = getLangText(data.text, lang);
        return text ? Math.min(result, text.length) : result;
    }, Infinity);
    const oneLangDuration = maxText * codificationCharDuration * characterNextTrigger;

    return secToMs(oneLangDuration);
}
