import gsap from 'gsap';
import { ScrambleTextPlugin } from '/src/touchpoints/live/lib/js/vendors/ScrambleTextPlugin.min.js';

gsap.registerPlugin(ScrambleTextPlugin);

export default function presetTL (lang, showFinder, callback) {
  const text = lang === 'KO' ? '        매순간 편안함       ' :  '       NIKE STYLE           '; 
  const tl = this.tl;

  const { uniforms } = this.visualLayer;
  tl.add(gsap.set(this.wrapper.querySelector('.img-logo'), {opacity: 0 }), 0);
  tl.add(gsap.set(this.wrapper.querySelectorAll('h2'), {opacity: 0 }), 0);
  tl.add(gsap.set(this.viewFinderMarks, { opacity: 0, scale: 0.95 }), 0);
  tl.add(gsap.set(this.wrapper.querySelectorAll('h2'), {opacity: 0 }), 0);
  tl.add(gsap.to(this.viewFinderMarks, { scale: 1, opacity:1, duration: 0.4, ease: 'expo.out' }), 1.7);
  tl.add(gsap.set(this.wrapper.querySelector('.img-logo'), {opacity: 1 }), 1.7);
  tl.add(gsap.set(this.wrapper.querySelectorAll('h2'), {opacity: 1 }), 1.8);
  tl.add(gsap.to(this.wrapper.querySelectorAll('h2')[0], {
    duration: 1,
    scrambleText: {
      text: this.wrapper.querySelectorAll('h2')[0].innerText,
      chars: text,
      rightToLeft:false
    },
  }), 1.8);
  tl.add(gsap.to(this.wrapper.querySelectorAll('h2')[1], {
    duration: 1,
    scrambleText: {
      text: this.wrapper.querySelectorAll('h2')[1].innerText,
      chars: text,
      rightToLeft:false
    },
  }), 1.8);
  tl.add(gsap.set(this.viewFinderMarks, { opacity: 0, scale: 0.95 }), 1.8);
  tl.add(gsap.set(this.wrapper.querySelectorAll('h2'), {opacity: 0 }), 7.9);
  // tl.add(gsap.set(this.wrapper.querySelector('.img-logo'), {opacity: 0 }), 8.2);



  if(showFinder) {
    tl.add(gsap.set(this.rangeFinder, {opacity: 0 }), 0);

    tl.add(gsap.set(this.rangeFinder, {opacity: 1 }), 0);
    tl.add(gsap.set(this.rangeFinder, {scale: 0.2 }), 0);
    tl.set(uniforms.rangeFinder.value, { x: 0}, 0);
    tl.to(uniforms.rangeFinder.value, { x: (((document.documentElement.clientHeight * window.devicePixelRatio))) * 0.4, duration: 0.5, ease: 'expo.out' }, 0);
    tl.to(uniforms.pixRF, { value: 20, duration: 0.5, repeat: 1, yoyo: true, ease: 'power0.easeNone' }, 0);
    // tl.to(uniforms.zoomRF, { value: 0, duration: 0.5 }, 0);
    tl.to(uniforms.pixa, { value: 20, duration: 1, ease: 'power3.out' }, 1);
    tl.to(uniforms.mosaicAmount, { value: 0, duration: 1, ease: 'power3.out' }, 1);
    tl.to(this.rangeFinder, {scale: 1.5, duration: 1.8, ease: 'expo.out' }, 1.5);
  
    tl.to(uniforms.rangeFinder.value, { x: ((document.documentElement.clientHeight * window.devicePixelRatio)) * 2.5, duration: 1.5, ease: 'expo.out' }, 1.5);
    tl.to(uniforms.pixRF, { value: 0, duration: 0.5, ease: 'power0.easeNone' }, 1.5);
    tl.to(uniforms.zoomRF, { value: 1, duration: 0.5 }, 1.5);
    tl.to(uniforms.pixels, { value: 1, duration: 0.5 }, 1.5);
  }
}
