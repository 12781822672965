import React, {useState} from "react";
import {DURATIONS} from "../shared";
import Flash from "./Flash";
import Track from "./Track";

export default function FastLane({info, data, onComplete}) {
  const trackCount = Math.ceil(info.trackLength / info.renderWidth);
  const [playOutro, setPlayOutro] = useState(false);
  const tracks = [...new Array(trackCount)].map((a, i) => i);

  const onTracksComplete = () => {
    setPlayOutro(true);
    setTimeout(onComplete, DURATIONS.SWEEP_IN);
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: info.renderWidth,
        overflow: 'hidden',
        zIndex: 99,
        height: info.trackHeight * trackCount,
      }}
    >
      <Flash/>
      {
        tracks.map((i) => (
          <div
            key={i}
            style={{
              transform: `translateX(${i * -info.renderWidth}px)`,
              height: info.trackHeight,
              width: info.trackLength,
            }}
          >
            <Track
              index={i}
              data={data}
              info={info}
              playOutro={playOutro}
              onComplete={onTracksComplete}
            />
          </div>
        ))
      }
    </div>
  )
}