export const transformImage = function (img, transform) {
    return transform ? img.split('/upload/').join(`/upload/${transform}/`) : img;
}

// Tip: use with await.
// const preloaded = await preloadAll(['img1.jpg', 'img2.jpg'])

export const preloadImage = src => new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = "anonymous";
    img.onload = resolve
    img.onerror = reject
    img.src = src
})

export const preloadVideo = src => new Promise((resolve, reject) => {
    fetch(src).then(response => {
        return response.blob();
    }).then(data => {
        resolve()
    }).catch(error => {
        reject()
    })
})


export const preloadImages = srcs => Promise.all(srcs.map(preloadImage))

export const preloadVideos = srcs => Promise.all(srcs.map(preloadVideo))

// export const preloadMedia = ({ images, video }) => Promise.all(srcs.map(preload))
