import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion, useIsPresent } from 'framer-motion';

import cssClassNames from '../styles/screens/CampaignIntro.module.scss';

import { DEFAULT_EASE, getMediaDuration, MediaType, secToMs, setAnimationTimeout } from '../shared';
import { DEFAULT_DURATION_S } from '../constants/transition';
import { BaseChapterProps } from '../components/ChapterRenderer';
import Transition from '../components/Transition';
import Grid from '../components/Grid';
import GridWithCells from '../components/GridWithCells';
import MediaPlayer from '../components/Media';
import MediaRenderer, { MediaRendererProps } from '../components/MediaRenderer';

const bigGridCellLength = 136;
const smallGridCellLength = bigGridCellLength / 2;

const mediaEntryDuration = DEFAULT_DURATION_S;
const showOverlayDuration = DEFAULT_DURATION_S;
const pause = 4;
const ease = DEFAULT_EASE;

export type CampaignIntroProps = BaseChapterProps & {
    media: MediaType,
    overlay: MediaRendererProps,
}

export default function CampaignIntro(props: CampaignIntroProps) {
    const {
        width,
        height,
        media,
        overlay,
    } = props;

    const smallGridCols = Math.ceil(width / smallGridCellLength) + 1;
    const smallGridRows = Math.ceil(height / smallGridCellLength);

    const isPresent = useIsPresent();
    const [toShowOverlay, setToShowOverlay] = useState(false);

    useEffect(() => {
        if (toShowOverlay === false) {
            return;
        }

        const {
            media: overlayMedia
        } = overlay;
        const overlayDuration = getMediaDuration(overlayMedia) || pause;
        return setAnimationTimeout(() => setToShowOverlay(false), secToMs(overlayDuration));
    }, [toShowOverlay]);

    return (
        <div className={cssClassNames.campaign_intro}>
            <Transition
                variant={'wipe-x'}
                cover
                onAnimationComplete={() => isPresent && setToShowOverlay(true)}
                duration={mediaEntryDuration}
            >
                <MediaPlayer
                    preventInitial
                    media={[media]}
                    width={'100%'}
                    height={'100%'}
                    useInternalTiming
                    preventExit
                />
            </Transition>
            <Grid
                className={cssClassNames.grid_solid}
                cols={3}
                rows={10}
                width={3 * bigGridCellLength}
                height={10 * bigGridCellLength}
                lineStyle={{
                    strokeWidth: '2px',
                }}
                toDrawBoundaries
                initialAnimation='visible'
            />
            <GridWithCells
                className={cssClassNames.grid_dotted}
                width={smallGridCols * smallGridCellLength}
                height={smallGridRows * smallGridCellLength}
                cols={smallGridCols}
                rows={smallGridRows}
                cellLength={smallGridCellLength}
            />
            <AnimatePresence>
                {toShowOverlay ? (
                    <motion.div
                        className={cssClassNames.overlay_media}
                        initial={{
                            opacity: 0
                        }}
                        animate={{
                            opacity: 1
                        }}
                        exit={{
                            opacity: 0
                        }}
                        transition={{
                            duration: showOverlayDuration,
                            ease,
                        }}
                    >
                        <MediaRenderer
                            {...overlay}
                            media={{
                                fit: 'fit',
                                ...overlay.media,
                            }}
                        />
                    </motion.div>
                ) : null}
            </AnimatePresence>
        </div>
    );
}

export function calculateDuration(data: CampaignIntroProps) {
    const {
        media,
        overlay,
    } = data;

    const entry = mediaEntryDuration + showOverlayDuration;
    const mediaDuration = getMediaDuration(media) || pause;
    const overlayDuration = getMediaDuration(overlay.media) || pause;
    const total = Math.max(entry, Math.max(mediaDuration, overlayDuration + entry));
    const result = secToMs(total) + calculateExitDuration(data);

    return result;
}

export function calculateExitDuration(data: CampaignIntroProps) {
    return secToMs(mediaEntryDuration);
}

