import React, {useEffect, useMemo} from "react";
import {motion} from 'framer-motion';
import {DEFAULT_EASE} from "../shared";
import Transition from "./Transition";
import {ENABLED_DISPLAYS_LOCAL_STORAGE_CACHE} from "../../../config";

interface GradientPlayerProps {
    gradient?: {
        type?: 'linear' | 'radial' | 'disabled';
        colors?: string[][];
    }
}

export default function GradientPlayer({gradient = {}}: GradientPlayerProps) {
    const background = useMemo<string>(() => {
        const [colorSet = []] = gradient?.colors ?? [];
        const colors = colorSet.filter((color) => color);

        if (!colors?.length) {
            return 'black';
        }

        if (colors?.length === 1) {
            return colors[0];
        }

        const gradientType = {
            linear: 'linear-gradient(',
            radial: 'radial-gradient(',
        }[gradient?.type ?? 'linear'];
        return `${gradientType}${colors?.join(', ')})`;
    }, [gradient]);

    return (
        <Transition
            cover
            variant={'fade'}
            delayExit={1.8}
            preventChildExit
        >
            <motion.div
                style={{
                    width: '100%',
                    height: '100%',
                }}
                animate={{
                    scale: [2, 1],
                }}
                transition={{
                    duration: 10,
                    ease: DEFAULT_EASE,
                    repeat: Infinity,
                    repeatType: 'mirror',
                }}
            >
                <motion.div
                    key={'gradient_player'}
                    style={{
                        width: '100%',
                        height: '100%',
                    }}

                    animate={{
                        background,
                    }}

                    transition={{
                        duration: 3,
                        ease: DEFAULT_EASE,
                    }}
                >
                </motion.div>
            </motion.div>
        </Transition>
    )
}