import React, {
    useState,
    useEffect,
    CSSProperties,
    useMemo,
    useRef
} from 'react';
import {
    motion,
    AnimatePresence,
    TargetAndTransition,
} from "framer-motion";

import {
    CoverType,
    MediaType,
    secToMs,
    setAnimationTimeout,
    ThemeType,
} from "../shared";
import Transition from "./Transition";
import MediaRenderer from './MediaRenderer';


interface MediaProps {
    style?: CSSProperties;
    mediaStyle?: CSSProperties;
    mediaContainerStyle?: CSSProperties;
    media: Array<MediaType>;
    width: number | string;
    height: number | string;
    theme?: ThemeType;
    cover?: CoverType;
    dim?: boolean;
    duration?: number;
    delay?: number;
    preventInitial?: boolean;
    preventInitialSlide?: boolean;
    preventExit?: boolean;
    transitionDuration?: number;
    effect?: MediaEffect;
    forceEffectOnVideo?: boolean;
    transparent?: boolean;
    layoutId?: string;
    useInternalTiming?: boolean;
    preventTransitions?: boolean;
}

export type MediaEffect =
    'zoom-top'
    | 'zoom-center'
    | 'pan-left'
    | 'pan-right'
    | 'zoom-pan-left'
    | 'zoom-pan-right'
    | 'zoom-out-center'
    | 'rotate-out';

const effects: Record<MediaEffect, TargetAndTransition> = {
    'zoom-center': {
        scale: [1, 1.5],
    },
    'zoom-top': {
        scale: [1, 1.5],
        transformOrigin: 'top center',
    },
    'zoom-out-center': {
        scale: [1.5, 1],
    },
    'pan-left': {
        scale: [1.5, 1.5],
        x: ['10%', '-10%'],
    },
    'pan-right': {
        scale: [1.5, 1.5],
        x: ['-10%', '10%'],
    },
    'zoom-pan-left': {
        scale: [1.5, 1],
        x: ['-10%', '0%'],
    },
    'zoom-pan-right': {
        scale: [1.5, 1],
        x: ['0%', '10%'],
    },
    'rotate-out': {
        scale: [2, 1],
        rotate: ['25deg', '-10deg'],
    }
};

export default function Media(props: MediaProps) {
    const {
        layoutId,
        media,
        transparent,
        style = {},
        mediaContainerStyle = {},
        width,
        height,
        preventInitial = false,
        preventInitialSlide = false,
        preventExit = false,
        theme,
        dim = false,
        cover = 'fill',
        duration = 2_500,
        useInternalTiming = false,
        transitionDuration,
        mediaStyle = {},
        delay = 0,
        effect,
        preventTransitions = false,
        forceEffectOnVideo = false
    } = props;

    const [showMedia, setShowMedia] = useState(false);
    const filteredMedia = useMemo(() => media?.filter(i => i) ?? [], [media]);
    const [rendered, setRendered] = useState(false);
    const [activeMediaIndex, setActiveMediaIndex] = useState(0);

    const asset = filteredMedia[activeMediaIndex % filteredMedia.length];

    const animate = useMemo(() => {
        if (asset?.resource_type === 'video' && forceEffectOnVideo === false) {
            return {};
        }

        return effects[effect] || {};
    }, [effect, activeMediaIndex]);

    useEffect(() => {
        setAnimationTimeout(() => {
            setShowMedia(true);
        }, secToMs(delay));
    }, []);

    const playSlideshow = (index = null) => {
        let a = index !== null ? filteredMedia[index % filteredMedia.length] : asset;
        const durationSec = duration / 1000;
        let d = durationSec;

        if (useInternalTiming) {
            d = a?.force_duration === 'full' ? (a?.duration ?? durationSec) : Number(a.force_duration);
        }

        setAnimationTimeout(() => {
            setActiveMediaIndex(i => {
                playSlideshow(i + 1);
                return i + 1;
            });
        }, secToMs(d));
    }

    useEffect(() => {
        if (!showMedia) {
            return;
        }

        if (filteredMedia.length > 1) {
            playSlideshow();
        }

        setRendered(true);
    }, [showMedia]);

    return (
        <motion.div
            layoutId={layoutId}
            style={{
                width,
                height,
                position: 'relative',
                ...style
            }}
        >
            {
                dim && (
                    <div
                        style={{
                            top: 0,
                            left: 0,
                            zIndex: 1,
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            backgroundColor: theme === 'dark' ? 'rgba(0,0,0,0.2)' : 'rgba(100,100,100,0.2)'
                        }}
                    />
                )
            }
            {
                showMedia && (
                    <Transition
                        variant={'wipe-y'}
                        delay={delay}
                        preventInitial={preventInitial}
                        preventExit={preventExit}
                        duration={transitionDuration}
                    >
                        <div
                            style={{
                                background: transparent ? 'transparent' : 'white',
                                filter: dim ? `contrast(.9) brightness(1.3)` : 'contrast(1.1)',
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                                ...mediaContainerStyle,
                            }}
                        >
                            <AnimatePresence>
                                <Transition
                                    duration={transitionDuration}
                                    cover
                                    preventTransitions={preventTransitions}
                                    variant={(activeMediaIndex === 0 && preventInitialSlide) ? 'wipe-x' : 'wipe-slide-x'}
                                    initial={rendered}
                                    preventInitial={preventInitial && activeMediaIndex === 0}
                                    preventExit={preventExit}
                                    key={activeMediaIndex}
                                >
                                    <motion.div
                                        style={{width: '100%', height: '100%'}}
                                        animate={animate}

                                        transition={{
                                            duration: 15,
                                            ease: "linear",
                                            repeatType: 'mirror',
                                            repeat: Infinity,
                                        }}
                                    >
                                        {asset ? (
                                            <MediaRenderer
                                                mediaStyle={mediaStyle}
                                                media={asset}
                                                cover={cover}
                                                height={height}
                                            />
                                        ) : null }
                                    </motion.div>
                                </Transition>
                            </AnimatePresence>
                        </div>
                    </Transition>
                )
            }
        </motion.div>
    )
}
