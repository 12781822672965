// Todo: Separate lines more content manager friendly
import { SyllableSeparation } from './../../utils.js';

export default function CanvasGrid (content) {
    let canvasImage = '';
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const width = content.width;
    const height = content.height;
    const size = content.size;
    const padding = 0;

    const createCanvas = () => {
        ctx.canvas.width  = width;
        ctx.canvas.height = height;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.rect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = 'white';
        ctx.fill();
    };

    const createLines = () => {
        for (var x = 0; x <= width; x += size) {
           ctx.moveTo(-3 + x + padding, padding);
           ctx.lineTo(-3 + x + padding, height + padding);
        }
    
        for (var x = 0; x <= height; x += size) {
           ctx.moveTo(padding, -3 + x + padding);
           ctx.lineTo(width + padding, -3 + x + padding);
        }
       ctx.strokeStyle = '#000000';
       ctx.lineWidth = 2;
       ctx.stroke();
    }
    const generateTexture = () => {
        canvasImage = canvas.toDataURL("image/png");
    }

  createCanvas();
  createLines();
  generateTexture();

  return canvasImage;
}
