
import React, { useEffect, useState, useRef } from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { motion, useAnimation } from 'framer-motion';
import './LottiePlayer.scss';

export const LottieModes = {
    Global: 'global',
    Chapter: 'chapter'
}

const LottiePlayer = ({ grid, onEvent, mode = LottieModes.Global }) => {

    useEffect(() => {
        return () => {
            // console.log('unmount');
        }
    }, []);

    return (<div className={'LottiePlayer'}>
        <Player
            autoplay
            loop={mode === LottieModes.Global}
            src={grid}
            key={`lottie`}
            style={{ height: '100%', width: '100%' }}
            rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
            onEvent={onEvent}
        />
    </div >)

};

export default LottiePlayer;
