import React, {useContext} from "react";

import Context from "./Codification.context";
import CodificationWord from "./CodificationWord";

export default function CodificationLine({
    words,
    index,
}: {
    words: string[][],
    index: number,
}) {
    const { lines } = useContext(Context);
    const isLastLine = lines.length - 1 === index;
    const startIndex = lines
        .slice(0, index)
        .reduce((total, line) => (total + line.length), 0);
    
    return (<>
        {words.map((word, wordIndex) => {
            const isLastWord = wordIndex === words.length - 1;
            const totalIndex = startIndex + wordIndex;

            return (
                <CodificationWord
                    key={`codification-word-${word.join('')}-${totalIndex}`}
                    characters={word}
                    index={totalIndex}
                    isLastWord={isLastWord}
                />
            );
        })}
        {!isLastLine && <br/>}
    </>);
};
