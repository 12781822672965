import React, {
    CSSProperties,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import {
    AnimatePresence,
    HTMLMotionProps,
    motion,
    useIsPresent,
    Variants
} from 'framer-motion';

import {
    DEFAULT_EASE,
    getLangText,
    MultiLangText,
    scaleText,
    secToMs,
    setAnimationTimeout
} from '../../shared';
import { DEFAULT_DURATION_S } from '../../constants/transition';
import CodificationWithTranslations from '../Codification/CodificationWithTranslations';
import useAreFontsLoaded from '../../hooks/useAreFontsLoaded';


export type ServiceItemProps = {
    title: MultiLangText,
    description: MultiLangText,
    language: string,
    rowHeight: number,
    toCodifyTitle?: boolean,
    containerProps?: HTMLMotionProps<'div'>,
    backgroundStyle?: CSSProperties,
    animationDuration?: number,
    delay?: number,
    onEntryAnimationComplete?: () => void,
    onAnimationComplete?: () => void,
    toExitCodification?: boolean,
    onExitComplete?: () => void,
}

const mainContainerVariants: Variants = {
    show: {
        clipPath: 'inset(0% 0% 0% 0%)',
    },
    grow: ({expandedHeight: height}) => ({
        clipPath: 'inset(0% 0% 0% 0%)',
        height: `${height}px`,
    }),
    shrink: ({collapsedHeight: height}) => ({
        clipPath: 'inset(0% 0% 0% 0%)',
        height: `${height}px`,
    }),
    hide: {
        clipPath: 'inset(0% 100% 0% 0%)'
    },
};


export default function ServiceItem(props: ServiceItemProps) {
    const {
        title,
        description,
        language,
        rowHeight,
        toCodifyTitle = false,
        containerProps = {},
        backgroundStyle = {},
        animationDuration = DEFAULT_DURATION_S,
        delay = 0,
        onAnimationComplete,
        onEntryAnimationComplete,
        toExitCodification = false,
        onExitComplete
    } = props;

    const areFontsLoaded = useAreFontsLoaded();
    const descriptionLines = useMemo(() => (
        getLangText(description, language).split('\n').map((text, index) => (
            <div key={index}><span>{text}</span></div>
        ))
    ), [description, language]);

    const isPresent = useIsPresent();
    const titleRef = useRef<HTMLDivElement>();
    const descriptionRef = useRef<HTMLDivElement>();
    const [toShow, setToShow] = useState(delay === 0);
    const [descriptionHeight, setDescriptionHeight] = useState(0);

    useEffect(() => {
        if (toShow) {
            return;
        }

        return setAnimationTimeout(() => setToShow(true), secToMs(delay));
    }, []);

    useEffect(() => {
        if (!titleRef.current || !areFontsLoaded) {
            return;
        }

        scaleText(descriptionRef.current, 'span');
    }, [titleRef.current, areFontsLoaded]);

    useEffect(() => {
        if (!descriptionRef.current || !areFontsLoaded) {
            return;
        }

        scaleText(descriptionRef.current, 'span');

        const {height} = descriptionRef.current.getBoundingClientRect();
        setDescriptionHeight(Math.ceil(height / rowHeight) * rowHeight);
    }, [descriptionRef.current, areFontsLoaded]);

    const mainContainerAnimationHandler = (...args: any[]) => {
        if (containerProps.animate === 'show' && !toCodifyTitle) {
            onEntryAnimationComplete?.();
            return;
        }

        if (isPresent) {
            onAnimationComplete?.(...args);
            return;
        }

        onExitComplete?.();
    }

    if (!toShow) {
        return null;
    }

    const gridDescription = descriptionHeight ? `${descriptionHeight}px` : 'auto';
    return (
        <motion.div
            variants={mainContainerVariants}
            transition={{
                duration: animationDuration,
                ease: DEFAULT_EASE,
            }}
            {...containerProps}
            style={{
                height: `${rowHeight}px`,
                gridTemplateRows: `${rowHeight}px ${gridDescription}`,
                ...containerProps.style
            }}
            custom={{
                collapsedHeight: rowHeight,
                expandedHeight: rowHeight + descriptionHeight,
            }}
            onAnimationComplete={mainContainerAnimationHandler}
        >
            <motion.div
                variants={serviceItemBackgroundAnimationVariants}
                transition={{
                    duration: animationDuration,
                    ease: DEFAULT_EASE,
                }}
                initial='hide'
                animate='show'
                exit='hide'
                className='service-item-background'
                style={backgroundStyle}
            >
            </motion.div>
            <div
                className='service-item-title'
                style={{
                    width: containerProps.style?.width
                }}
            >
                {toCodifyTitle ? (
                    <AnimatePresence>
                        <CodificationWithTranslations
                            text={title}
                            languages={[language]}
                            langIndex={0}
                            codificationProps={{
                                characterSwitchAmount: 4,
                                characterNextTrigger: 2,
                            }}
                            languageTransitionDelay={0}
                            runFinalTextAnimation={toExitCodification}
                            onTyped={() => {
                                if (!isPresent) {
                                    return;
                                }

                                onEntryAnimationComplete?.();
                            }}
                        />
                    </AnimatePresence>
                ) : (
                    <div ref={titleRef}>
                        {getLangText(title, language).split('\n').map((text, index) => (
                            <div key={index}><span>{text}</span></div>
                        ))}
                    </div>
                )}
            </div>
            <div
                className='service-item-description'
                style={{
                    width: containerProps.style?.width
                }}
            >
                <div ref={descriptionRef}>
                    {descriptionLines}
                </div>
            </div>
        </motion.div>
    );
};

const serviceItemBackgroundAnimationVariants: Variants = {
    hide: {
        transform: 'translateX(-100%)'
    },
    show: {
        transform: 'translateX(0%)'
    }
}
