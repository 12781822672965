if (module.hot) {
  module.hot.accept(() => {
    location.reload()
  })
}

import {
  Uniform,
  Vector2,
  Color
} from 'three';

import UniversalLiveTemplate from '/src/touchpoints/live/lib/js/UniversalLiveTemplate';

import defaultSwoosh from 'url:/src/touchpoints/live/lib/assets/swoosh.png';

import vert from '/src/touchpoints/live/lib/glsl/shader.vert';
import frag from './viewfinder.frag';
import presetTL from './viewfinder-timeline';

import './viewfinder.scss'

class Viewfinder extends UniversalLiveTemplate {
  constructor (name, datas, onComplete) {
    super(name);

    if (window.ORIENTATION === 'PORTRAIT') {
      this.setReferencesDimensions(605, 1080);
    } else {
      this.setReferencesDimensions(1620, 1080);
    }

    this.datas = datas;

    this.overlayWrapper = this.addNode({type: 'overlayWrapper', freeflow:true});
    this.viewFinderMarks = this.addNode({type: 'viewFinderMarks', freeflow: true,
    content: { stroke: '2px', size: 42 }})
    this.verticalTextTop = this.addNode({type: 'verticalText', parent: true, className:"top-right",
    content: { text: '' }})
    this.verticalTextBottom = this.addNode({type: 'verticalText', parent: true, className:"bottom-right",
    content: { text: '' }})

    this.registerTextureURL({
      src: this.datas.assets[0]?.url ?? defaultSwoosh,
      key: 'img1',
      pos: { x: (this.width / 2), y: (this.height / 2) },
      dim: { x: this.width, y: this.height },
      offset: { x: 0, y: 0 },
      pixel: 1,
      zoom: 1.5,
      zoomRF: 1.75,
      forceJPG: true,
    });

    this.registerTextureURL({
      src: this.datas.assets[1]?.url ?? defaultSwoosh,
      key: 'img2',
      pos: { x: (this.width / 2), y: (this.height / 2) },
      dim: { x: this.width, y: this.height },
      offset: { x: 0, y: 0 },
      pixel: 1,
      zoom: 1.4,
      zoomRF: 1.4,
      forceJPG: true,
    });

    const center = new Vector2(
      (this.width / 2),
      (this.height / 2)
    );

    const uniforms = {
      time: new Uniform(0),
      res: new Uniform(center),
      pixa: new Uniform(1),
      pixr: new Uniform(window.devicePixelRatio),
      stroke: new Uniform(this.relWidth(0.8)),
      zoom: new Uniform(0.25),
      transitionScale: new Uniform(0),
      mosaicAmount: new Uniform(0),
      mosaicColor: new Uniform(1),
      gridSteps: window.ORIENTATION === 'PORTRAIT'
        ? new Uniform(new Vector2(4, 6))
        : new Uniform(new Vector2(6, 4)),
      gridMosaic: new Uniform(0),
      gridOpacity: new Uniform(1),
      rfPos: new Uniform(center),
      rfRadius: new Uniform(Math.min(Math.min((this.width * 0.4), (this.height * 0.4)), this.relWidth(230))),
      rfOpacity: new Uniform(1),
    }

    this.createVisualLayer({ frag, vert }, uniforms);
    this.setupTimeline(onComplete);
  }

  populateTimeline () {
    presetTL.bind(this)();
  }

  preStart () {
    this.wrapper.classList.add('active');
  }
}
export default Viewfinder;
