import React from "react";
import { motion } from "framer-motion";

import Title, {
    TitleProps,
    calculateDuration as titleCalculateDuration,
    calculateExitDuration as titleCalculateExitDuration,
} from '../components/Title';
import TitleWithSubtitle, {
    TitleWithSubtitleProps,
    calculateDuration as titleWithSubTitleCalculateDuration,
    calculateExitDuration as titleWithSubTitleCalculateExitDuration,
} from '../components/TitleWithSubtitle';

import '../styles/screens/title-card.scss';


type Props = TitleProps | TitleWithSubtitleProps;

export default function TitleCard(props: Props) {
    const component = props.subtitle ? (
        <TitleWithSubtitle {...props} />
    ) : (
        <Title {...props} />
    );

    return (
        <motion.div
            className='title-chapter'
            initial={{opacity: 1}}
            exit={{
                opacity: 1,
                transition: {
                    duration: 0,
                    delay: 3
                },
            }}
        >
            {component}
        </motion.div>
    );
}

export function calculateDuration(data: Props) {
    return data.subtitle ? titleWithSubTitleCalculateDuration(data) : titleCalculateDuration(data);
}

export function calculateExitDuration(data: Props) {
    return data.subtitle ? titleWithSubTitleCalculateExitDuration(data) : titleCalculateExitDuration(data);
}
