import React, { forwardRef } from 'react';

import PhantomText from './PhantomText';

export type PhantomTranslationProps = {
    translations: string[],
    className?: string,
};

function PhantomTranslation(props: PhantomTranslationProps, ref: any) {
    const {
        translations,
        className,
    } = props;

    return (
        <div
            ref={ref}
            className={className}
        >
            {translations.map((item, index)  => (
                <PhantomText text={item} key={index}/>
            ))}
        </div>
    )
};

export default forwardRef(PhantomTranslation);
