import React from 'react';
import { HubGrid } from '../components';
import { useHubGrid } from '../hooks/useHubGrid';
import services from '../mocks/services.json';

export const ServicesHub = ({ data }) => {
	const hubGridProps = useHubGrid({
		rawData: data?.json_data,
		fallback: services,
		template: 'services',
	});

	return (
		<HubGrid
			{...hubGridProps}
			titleRatio={0.5}
		/>
	);
};
