if (module.hot) {
    module.hot.accept(() => {
      location.reload()
    })
  }
  
  import * as THREE from 'three';
  
  import moment from 'moment';

  import markup from './grid-markup';
  import vert from '/src/touchpoints/live/lib/glsl/shader.vert';
  import frag from './grid.frag';
  import createTL from './grid-timeline';

  import swatchList from '/src/touchpoints/live/lib/js/components/SwatchList/SwatchList';
  import verticalText from '/src/touchpoints/live/lib/js/components/VerticalText/VerticalText';
  import placedCanvas from '/src/touchpoints/live/lib/js/components/PlacedCanvas/PlacedCanvas';
  import SnkrsGrid from '/src/touchpoints/live/lib/js/components/SnkrsGrid';
  import defaultSwoosh from 'url:/src/touchpoints/live/lib/assets/swoosh.png';
  import rangeFinder from '/src/touchpoints/live/lib/js/components/RangeFinder';
  import './grid.scss';

  class Grid {
    constructor (name, datas, onComplete, index, renderers) {
      this.id = `${name}-${index}`;
      this.name = name;
      this.datas = datas;
      this.onComplete = onComplete;
      const width = window.innerWidth;
      const height = window.innerHeight;
  
      this.wrapper = document.createElement('div');
      this.wrapper.id = this.id;
      this.wrapper.classList.add('grid-wrapper');
      this.wrapper.classList.add(`is-size-${window.SIZE_ID?.toLowerCase()}`);
      this.wrapper.style.display = 'none'
      document.querySelector('body').style.backgroundColor = 'white';
      this.wrapper.classList.add('loading');
      const units = {
        SMALL: '15px',
        MEDIUM: '25px',
        LARGE: '40px',
      }
      document.documentElement.style.setProperty('--lls-ca-closeup-unit', units[window.SIZE_ID]);
      const borderThicknesses = {
        SMALL: '1px',
        MEDIUM: '2px',
        LARGE: '2px',
      }
      document.documentElement.style.setProperty('--lls-ca-closeup-border-thick', borderThicknesses[window.SIZE_ID]);
  
      if (width < height) {
        this.isVertical = true;
        this.wrapper.classList.add('is-vertical');
      }
      this.wrapper.innerHTML += markup;
      document.body.appendChild(this.wrapper);
  
      this.renderer = this.createRenderer('ca-closeup-main-canvas', renderers.front);
  
      this.scenes = [];
      this.creditsTexts = [];
      this.insertMediaView(`.freeflow-item`, '', 0);
      this.loadIndex = 1;
      this.running = false;
      this.snkrsGrid = new SnkrsGrid(datas);
      this.wrapper.appendChild(this.snkrsGrid.getWrapper());

      this.colors = this.snkrsGrid.getSwatchColors();
      let rangeFinderContent =  {
        size: `${window.innerHeight}`,
        stroke: 10
      }
      this.rangeFinder = rangeFinder(rangeFinderContent)
      this.rangeFinder.style.width = `${window.innerHeight * 2}px`
      this.rangeFinder.style.height = `${window.innerHeight * 2}px`
      this.wrapper.appendChild(this.rangeFinder);
    }
  
    createCreditsText (selector, credits, position) {
      const vt = verticalText({ text: credits });
      vt.classList.add(`vt-${position}`);
      this.wrapper.querySelector(`${selector} .corner-${position}-right`).appendChild(vt);
      this.creditsTexts.push(vt);
    }
  
    createRenderer (className, renderer) {
      renderer.setClearColor(0xFFFFFF, 0);
      
      this.resizeRendererToDisplaySize(renderer);
      renderer.domElement.classList.add('renderer');
      return renderer;
    }
  
    resizeRendererToDisplaySize (renderer) {
      const canvas = renderer.domElement;
      const pixelRatio = window.devicePixelRatio;
      const width = window.innerWidth;
      const height = window.innerHeight;
      const needResize = canvas.width !== width || canvas.height !== height;
      if (needResize) {
        renderer.setSize(width, height, false);
      }
      return needResize;
    }
  
    updateTexture (url, index) {
      this.createTexture(url, (texture) => {
        texture.needsUpdate = true;
        this.scenes[index].userData.shader.uniforms.img.value = texture;
        this.scenes[index].userData.shader.uniforms.imgRes.value.x = texture.image.width;
        this.scenes[index].userData.shader.uniforms.imgRes.value.y = texture.image.height;
      });
    }
  
    onStart () {
      console.log('start')
      this.wrapper.classList.remove('loading');
    }
  
    createTexture (url, onLoaded) {
      const img = new Image();
      const texture = new THREE.Texture(img);
      texture.encoding = THREE.sRGBEncoding;
      texture.generateMipmaps = false;
      texture.minFilter = THREE.NearestFilter;
      texture.magFilter = THREE.NearestFilter;
      texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
  
      img.addEventListener('load', () => {
        onLoaded(texture);
      });
  
      img.crossOrigin = 'anonymous';
      img.src = url?.replace(/(.png)/gmi, '.jpg') || defaultSwoosh;
    }
  
    insertMediaView (selector, url, index) {
      const width = window.innerWidth;
      const height = window.innerHeight;
  
      this.createTexture(url, (texture) => {
        texture.needsUpdate = true;
        
        const scene = placedCanvas({
          parent: document.querySelector('.renderer'),
          uniforms: {
            // GENERAL
            seed: (Math.random() * 1000),
            time: new THREE.Uniform(0),
            opacity: new THREE.Uniform(1),
            pixr: new THREE.Uniform(window.devicePixelRatio),
            res: new THREE.Uniform(new THREE.Vector2(width, height)),
            // IMG
            img: new THREE.Uniform(texture),
            offset: new THREE.Uniform(new THREE.Vector2(0)),
            imgRes: new THREE.Uniform(new THREE.Vector2(
              texture.image.width,
              texture.image.height
            )),
            mosaic: new THREE.Uniform(1),
            pixels: new THREE.Uniform(1),
            zoom: new THREE.Uniform(1),
          },
          renderer: this.renderer,
          shader: {
            vert,
            frag: frag
          },
        });
        
        this.scenes[index] = scene;

 
        if (this.scenes.filter(s => s).length === this.loadIndex) {
          this.tl = createTL(this.scenes, this.onComplete, this.onStart.bind(this), this.wrapper, this.rangeFinder);
          if (this.delayedPlay) {
            this.tl.play(0);
          }
        }
      });
    }
  
    renderScene (scene, renderer) {
      const element = scene.userData.parent;
      const rect = element.getBoundingClientRect();
  
      const width = (rect.right - rect.left);
      const height = (rect.bottom - rect.top);
      const left = rect.left;
      const bottom = (renderer.domElement.clientHeight - rect.bottom);
      const vp = new THREE.Vector4(left, bottom, width, height);
  
      renderer.setViewport(vp);
      renderer.setScissor(vp);
  
      scene.userData.shader.uniforms.time.value++;
      scene.userData.shader.uniforms.res.value.x = width;
      scene.userData.shader.uniforms.res.value.y = height;
  
      renderer.render(scene, scene.userData.camera);
    }
  
    render () {
      if (this.running) {
        this.renderer.setScissorTest(true);
        this.scenes.forEach((scene) => {
          this.renderScene(scene, this.renderer);
        });
        this.renderer.setScissorTest(false);
      }
  
      this.loop = requestAnimationFrame(this.render.bind(this));
    }
  
    start () {
      this.wrapper.style.display = 'block';
      document.querySelector('body').style.position = 'relative';
  
      if (this.tl) {
        this.tl.play(0);
      } else {
        this.delayedPlay = true;
      }
      this.running = true;
      this.loop = requestAnimationFrame(this.render.bind(this));
    }
    stop () {
      this.running = false;
      this.tl?.pause();
      cancelAnimationFrame(this.loop);  
      setTimeout(() => {
      this.wrapper.style.display = 'none';
      // this.running = false;
      // this.tl?.pause();
      this.tl?.seek(0);
      document.querySelector('body').style.position = 'fixed';
      }, 1000);
    }
    pause () {
      this.running = false;
      this.tl?.pause();
    }
    resume () {
      this.tl?.resume();
      this.running = true;
    }
  }
  export default Grid;
  