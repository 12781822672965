import React from 'react';

export const Repair = ({ data }) => {

    return (
        <div className={'Repair'}>
            CITY REPLAY — REPAIR
        </div>
    );
};
