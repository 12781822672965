import React, {useEffect, useState} from 'react';
import Ticker from './ticker';
import {getStoreLanguages} from "../../hyperlive/utils";
import * as Hyperlive from "../../hyperlive/utils";
import {pixelMapReady} from "../../../../utilities";

// Testing
const infoJson = require('../../data/scroll_ticker.json');


const ScrollTicker = ({json_data = {}, invert = false}) => {
  const {color_theme, solarization = true} = json_data;
  const [key, setState] = useState(0);

  useEffect(() => {
    pixelMapReady(() => {
      setState(key + 1);
    });
  });

  console.log('ready...', key);

  let config = {
    height: 96,
    screenWidth: 3_840,
    totalWidth: 26_880,
  };

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const origin = urlParams.get('origin');
  let space = 2.5;

  const urlSpace = urlParams.get('space');
  const channelSpace = window.channel?.channels?.[0]?.json_config?.space;
  const solarizeSpeed = window.channel?.channels?.[0]?.json_config?.solarize_occurance;
  const timeFactor = Number(window.channel?.channels?.[0]?.json_config?.time_factor ?? 1);

  if (channelSpace) {
    space = channelSpace;
  }

  if (urlSpace) {
    space = parseFloat(urlSpace);
  }


  if (origin === 'pixel-map') {
    const height = Number(urlParams.get('height') ?? 48);
    const width = Number(urlParams.get('width') ?? 3969);

    const isInternalWrapping = urlParams.get('wrapping-style') === 'internal';
    const screenWidth = Number(isInternalWrapping ? urlParams.get('screen-width') : width);

    config = {
      height,
      screenWidth,
      totalWidth: width,
    }
  }

  let effectTypes = ['solarization'];

  if (solarization === false) {
    effectTypes = [];
  }

  return (
    <>
      <Ticker
        key={key}
        {...config}
        poleWidth={325}
        frameCount={5}
        // inset={25}
        top={0}
        left={0}
        languages={Hyperlive.getStoreLanguages()}
        invert={color_theme === 'light' ? false : true}
        space={space}
        time={20}
        // effectTime={solarizeSpeed ? 1 / solarizeSpeed : 1}
        effectTime={1}
        textTime={0.5}
        effectTypes={effectTypes}
        effectOccurrenceMultiplier={solarizeSpeed}
        effectStaggerTime={0.25}
        solarizationInset={0.064}
        timeFactor={timeFactor}
        {...json_data}
        //{...infoJson}
      />
    </>
  );
};

export default ScrollTicker;
