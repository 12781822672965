import ko from "../languages/ko.json";
import zh from "../languages/zh.json";
import en from "../languages/en.json";

export const ENGLISH = {
	NAME: "English",
	NATIVE: "English",
	CODE: "en",
	TRANSLATIONS: en,
};

export const KOREAN = {
	NAME: "Korean",
	NATIVE: "언어 변경",
	CODE: "ko",
	TRANSLATIONS: ko,
};

export const CHINESE = {
	NAME: "Chinese",
	NATIVE: "中國人",
	CODE: "zh",
	TRANSLATIONS: zh,
};

export const LANGUAGES = [ENGLISH, KOREAN, CHINESE];
