import {useMembership} from "./store";
import {MultiLangText} from "../../../house-of-innovation/shared";

export const MEMBERSHIP_COLOR = '#eb4b27';

export async function wait(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function useTranslatable() {
    const language = useMembership(state => state.activeLanguage);

    return {
        t: (multiLang: MultiLangText) => {
            if (!multiLang) {
                return '';
            }

            const content = multiLang[language.toUpperCase()];

            if (content) {
                return content;
            }

            const defaultLang = Object.keys(multiLang)[0];
            return multiLang?.[defaultLang];
        }
    }
}

export function useRpx() {
    const multiplier = window.innerWidth / 1080;

    return (value: number, raw: boolean = false) => {
        const val = value * multiplier;
        return raw ? val : `${val}px`;
    }
}
