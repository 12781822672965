[
    {
        "name": "IntroEntry",
        "circle": [],
        "delayHorizontal": true,
        "delayVertical": false,
        "drawBorder": true,
        "duration": "1000",
        "horizontal": [
            {
                "drawDirection": 1,
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 25,
                "extend": false,
                "name": "Top",
                "startColor": 176,
                "startPosition": 101
            },
            {
                "drawDirection": 1,
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 50,
                "extend": false,
                "name": "Equator",
                "startPosition": 101
            },
            {
                "drawDirection": 1,
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 75,
                "extend": false,
                "name": "Bottom",
                "startPosition": 101
            }
        ],
        "horizontalDelayDirection": 1,
        "id": 0,
        "insetBorder": false,
        "mask": [],
        "startColor": 160,
        "vertical": [
            {
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 5.6,
                "name": "Left",
                "startPosition": 50
            },
            {
                "drawDirection": 1,
                "drawFromLine": "Bottom Border",
                "drawFull": true,
                "drawToLine": "Top Border",
                "extend": false,
                "name": "Equator",
                "startPosition": 50
            },
            {
                "drawDirection": 1,
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 94.4,
                "extend": false,
                "name": "Right",
                "startPosition": 50
            }
        ]
    },
    {
        "name": "IntroEntryResolve",
        "circle": [],
        "delayHorizontal": true,
        "delayVertical": false,
        "drawBorder": true,
        "duration": "1000",
        "horizontal": [
            {
                "drawDirection": 1,
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 25,
                "extend": false,
                "name": "Top",
                "startColor": 176,
                "startPosition": 25
            },
            {
                "drawDirection": 1,
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 50,
                "extend": false,
                "name": "Equator",
                "startPosition": 50
            },
            {
                "drawDirection": 1,
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 75,
                "extend": false,
                "name": "Bottom",
                "startPosition": 75
            }
        ],
        "horizontalDelayDirection": 1,
        "id": 0,
        "insetBorder": false,
        "mask": [],
        "startColor": 160,
        "vertical": [
            {
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 5.6,
                "name": "Left",
                "startPosition": 5.6
            },
            {
                "drawDirection": 1,
                "drawFromLine": "Bottom Border",
                "drawFull": true,
                "drawToLine": "Top Border",
                "endPosition": 50,
                "extend": false,
                "name": "Equator",
                "startPosition": 50
            },
            {
                "drawDirection": 1,
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 94.4,
                "extend": false,
                "name": "Right",
                "startPosition": 94.4
            }
        ]
    },
    {
        "name": "IntroTransition",
        "circle": [],
        "delayHorizontal": true,
        "delayVertical": false,
        "drawBorder": true,
        "duration": "1000",
        "horizontal": [
            {
                "drawDirection": 1,
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 25,
                "extend": false,
                "name": "Top",
                "startColor": 176,
                "startPosition": 25
            },
            {
                "drawDirection": 1,
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 50,
                "extend": false,
                "name": "Equator",
                "startPosition": 50
            },
            {
                "drawDirection": 1,
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 75,
                "extend": false,
                "name": "Bottom",
                "startPosition": 75
            },
            {
                "startPosition": 62.5,
                "delay": 1500,
                "name": "LogoLine",
                "extend": false,
                "drawFromLine": "PrimaryRight",
                "drawToLine": "SecondaryRight",
                "drawFull": false
            }
        ],
        "horizontalDelayDirection": 1,
        "id": 0,
        "insetBorder": false,
        "mask": [
            {
                "horizontal": [
                    "Top",
                    "Equator"
                ],
                "polyOnly": true,
                "vertical": [
                    "PrimaryLeft",
                    "SecondaryRight"
                ],
                "grid": 0,
                "name": "IntroHeadline",
                "_colorFill": true
            },
            {
                "polyOnly": true,
                "_colorFill": true,
                "vertical": [
                    "SecondaryRight",
                    "PrimaryRight"
                ],
                "name": "AppRight",
                "horizontal": [
                    "Equator",
                    "LogoLine"
                ],
                "grid": 0
            }
        ],
        "startColor": 160,
        "vertical": [
            {
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 50,
                "name": "Equator",
                "startPosition": 5.6
            },
            {
                "drawDirection": 1,
                "drawFromLine": "Bottom Border",
                "drawFull": true,
                "drawToLine": "Top Border",
                "endPosition": 72.2,
                "extend": false,
                "name": "SecondaryRight",
                "startPosition": 50
            },
            {
                "drawDirection": 1,
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "extend": false,
                "name": "PrimaryRight",
                "startPosition": 94.4
            },
            {
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 5.6,
                "name": "PrimaryLeft",
                "startPosition": -1
            },
            {
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 27.8,
                "name": "SecondaryLeft",
                "startPosition": 5.6
            }
        ]
    },
    {
        "name": "IntroStart",
        "circle": [],
        "delayHorizontal": true,
        "delayVertical": false,
        "drawBorder": true,
        "duration": "1000",
        "horizontal": [
            {
                "drawDirection": 1,
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 12.5,
                "extend": false,
                "name": "Top",
                "startColor": 176,
                "startPosition": 25
            },
            {
                "drawDirection": 1,
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 25,
                "extend": false,
                "name": "Top2",
                "startPosition": 50
            },
            {
                "drawDirection": 1,
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 37.5,
                "extend": false,
                "name": "Top3",
                "startPosition": 75
            },
            {
                "drawDirection": 1,
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 50,
                "extend": false,
                "name": "Equator",
                "startPosition": 75
            },
            {
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 62.5,
                "name": "Bottom2",
                "startPosition": 101
            },
            {
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 75,
                "name": "Bottom3",
                "startPosition": 101
            },
            {
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "endPosition": 87.5,
                "name": "Bottom",
                "startPosition": 101
            }
        ],
        "horizontalDelayDirection": 1,
        "id": 0,
        "insetBorder": false,
        "mask": [],
        "startColor": 160,
        "vertical": [
            {
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "name": "Equator",
                "startPosition": 50
            },
            {
                "drawDirection": 1,
                "drawFromLine": "Bottom Border",
                "drawFull": true,
                "drawToLine": "Top Border",
                "extend": false,
                "name": "SecondaryRight",
                "startPosition": 72.2
            },
            {
                "drawDirection": 1,
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "extend": false,
                "name": "PrimaryRight",
                "startPosition": 94.4
            },
            {
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "name": "PrimaryLeft",
                "startPosition": 5.6
            },
            {
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "name": "SecondaryLeft",
                "startPosition": 27.8
            }
        ]
    },
    {
        "name": "IntroEnd",
        "circle": [],
        "delayHorizontal": true,
        "delayVertical": false,
        "drawBorder": true,
        "duration": "1000",
        "horizontal": [
            {
                "drawDirection": 1,
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "extend": false,
                "name": "Top",
                "startColor": 176,
                "startPosition": 12.5
            },
            {
                "drawDirection": 1,
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "extend": false,
                "name": "Top2",
                "startPosition": 25
            },
            {
                "drawDirection": 1,
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "extend": false,
                "name": "Top3",
                "startPosition": 37.5
            },
            {
                "drawDirection": 1,
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "extend": false,
                "name": "Equator",
                "startPosition": 50
            },
            {
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "name": "Bottom2",
                "startPosition": 62.5
            },
            {
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "name": "Bottom3",
                "startPosition": 75
            },
            {
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "name": "Bottom",
                "startPosition": 87.5
            }
        ],
        "horizontalDelayDirection": 1,
        "id": 0,
        "insetBorder": false,
        "mask": [],
        "startColor": 160,
        "vertical": [
            {
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "name": "Equator",
                "startPosition": 50
            },
            {
                "drawDirection": 1,
                "drawFromLine": "Bottom Border",
                "drawFull": true,
                "drawToLine": "Top Border",
                "extend": false,
                "name": "SecondaryRight",
                "startPosition": 72.2
            },
            {
                "drawDirection": 1,
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "extend": false,
                "name": "PrimaryRight",
                "startPosition": 94.4
            },
            {
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "name": "PrimaryLeft",
                "startPosition": 5.6
            },
            {
                "drawFromPosition": 0,
                "drawFull": true,
                "drawToPosition": 100,
                "name": "SecondaryLeft",
                "startPosition": 27.8
            }
        ]
    }
]