import {motion} from "framer-motion";
import React, {CSSProperties, useMemo} from "react";
import {TickerData} from "../../core/animations/ticker/ticker/type";
import CoreTicker from "../../core/animations/ticker/CoreTicker";
import {getChannelInfo} from '../../rise/hyperlive/utils';

interface CenterCourtTickerProps {
    type?: 'image' | 'dynamic',
    data?: TickerData,
    url?: string;
    width?: number;
}

const POSITIONING: CSSProperties = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 4,
}

export function CenterCourtTicker({type, data, url, width}: CenterCourtTickerProps) {
    const overscan = useMemo(() => {
        return (getChannelInfo('json_config.ticker_overscan', 0) / 100) * (width / 2);
    }, []);

    if (type === 'image') {
        return <ImageTicker url={url}/>
    }

    if (type === 'dynamic') {
        const correction = (width * 0.04) + overscan;

        return (
            <div style={POSITIONING}>
                <CoreTicker
                    data={{
                        height: width * 0.03,
                        canvas_height: width,
                        canvas_width: width,
                        width: width,
                        ignore_pixelmap: true,
                        type: 'circle',
                        squash: 0.5,
                        speed: 2,
                        direction: 'left',
                        letterspacing: 0.01,
                        font: "jordan-condensed",
                        color: 'white',
                        radius: width / 2 - correction,
                        spacing: width * 0.04,
                        ...data,
                    }}
                />
            </div>
        )
    }

    return null;
}

function ImageTicker({url}: { url: string }) {
    return (
        <motion.div
            animate={{
                rotate: [0, 360],
            }}
            transition={{
                duration: 100,
                ease: 'linear',
                repeat: Infinity,
            }}
            style={{
                backgroundImage: `url(${url})`,
                backgroundSize: 'cover',
                ...POSITIONING
            }}
        >
        </motion.div>
    )
}