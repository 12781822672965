#pragma glslify: mosaic = require('../../lib/glsl/mosaic.glsl')
#pragma glslify: coverUV = require('../../lib/glsl/coverUV.glsl')
#pragma glslify: pixelateUV = require('../../lib/glsl/pixelateUV.glsl')

#ifdef GL_ES
precision mediump float;
#endif

varying vec2 vUv;

uniform float time;
uniform vec2 res; // res
uniform float pixa; // pixelization amount (size of each cell/pixel)
uniform float pixr; // pixel ratio

uniform float mosaicAmount; // how much mosaic is covering the screen (0-1)
uniform vec3 mosaicColor; // mosaic background color
uniform float mosaicAlpha; // greyscale color

uniform sampler2D img0;
uniform sampler2D textOverlay;
uniform vec2 img0Res;

vec4 layer(vec4 foreground, vec4 background) {
    return foreground * foreground.a + background * (1.0 - foreground.a);
}

void main() {
  vec4 color = vec4(1.0);

  float px = (res.x / pixa);
  float ar = (res.x / res.y);
  vec2 st = (gl_FragCoord.xy / res);

  vec2 uv = coverUV(st, (res * pixr), (img0Res * pixr));
  uv = pixelateUV(uv, px, ar);

  color = min(color, texture2D(img0, uv, -10.0));
  vec4 text = texture2D(textOverlay, st, -10.0);
  color = layer(text, color);

  float m = mosaic(st, ar, time, mosaicAmount);
  color = mix(color, vec4(mosaicColor, mosaicAlpha), m);

  gl_FragColor = color;
}
