import React, { useMemo } from 'react';
import classNames from 'classnames';

import style from '../styles/components/GridWithCell.module.scss';

import Grid from './Grid';


type Props = {
    height: number,
    width: number,
    rows: number,
    cols: number,
    cellLength: number,
    toDrawBoundaries?: boolean,
    className?: string,
}

export default function GridWithCells(props: Props) {
    const {
        height,
        width,
        rows,
        cols,
        cellLength,
        toDrawBoundaries,
        className,
    } = props;

    const cells = useMemo(() => buildCells({
        rows,
        cols,
        componentBuilder(x, y, index) {
            return (
                <div
                    key={index}
                    className={classNames(style.cell, 'cell')}
                    style={{
                        width: `${cellLength}px`,
                        height: `${cellLength}px`,
                        top: `${y * cellLength}px`,
                        left: `${x * cellLength}px`,
                    }}
                >
                    <div className={classNames(style.dot, 'dot')}></div>
                </div>
            );
        }
    }), [rows, cols]);


    return (
        <div
            className={classNames(style.GridWithCell, className)}
            style={{
                height: `${height}px`,
                width: `${width}px`,
            }}
        >
            <Grid
                width={width}
                height={height}
                cols={cols}
                rows={rows}
                toDrawBoundaries={toDrawBoundaries}
                initialAnimation='visible'
            />
            <div className='dots'>
                {cells}
            </div>
        </div>
    );
}


type DotsProps = {
    rows: number,
    cols: number,
    componentBuilder: (x: number, y: number, index: number) => JSX.Element,
}

function buildCells(props: DotsProps) {
    const {
        cols,
        rows,
        componentBuilder,
    } = props;

    const cells = new Array(cols * rows);
    for (let y = 0; y < rows; y++)  {
        const indexOffset = y * cols;
        for (let x = 0; x < cols; x++)  {
            const index = indexOffset + x;
            cells[index] = componentBuilder(x, y, index);
        }
    }

    return cells;
}
