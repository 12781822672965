import React, { useRef, useMemo, useState, useEffect } from 'react';
import { Camera, useFrame, useThree } from '@react-three/fiber';
import Flipper from './FlipPage';
import FlipCard from './FlipCard';
import { canvasWidth, canvasHeight, generateFlipcardProps } from './shared';
import { PerspectiveCamera } from '@react-three/drei';

const cameraZPosition = 373;
export const boardColumns = 15;
export const boardRows = 10;

export type TextureMap = {[key:string] : THREE.Texture };

interface flipboardProps {
  screens: any;
  onPagesFinished: () => void;
  textures: TextureMap;
  config: any;
  singleBoard?:boolean;
}

const generateFlipCard = (medias, texts, textures, pageNumber, config) => {
  return Array.from({ length: 10 }).map((_, idx) => {
    return (
      <FlipCard
        {...generateFlipcardProps(idx, medias, texts, textures, boardColumns, boardRows, pageNumber, config)}
      />)
  });
}

function FlipBoard({ screens, onPagesFinished, textures, config }: flipboardProps) {
  const refScene = useRef<THREE.Scene>();
  const refScene2 = useRef<THREE.Scene>();
  const { gl } = useThree();
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPageNumber(pageNumber => {
        let newPageNumber = pageNumber;
        newPageNumber++;
        if (newPageNumber > screens[0].pages.length - 1) {
          onPagesFinished();
          newPageNumber = 0;
        }
        return newPageNumber;
      })
    }, config.page_duration);

    return () => {
      clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screens[0].pages.length]);

  const flipcards1 = useMemo(() => {
    config.numPages = screens?.[0]?.pages?.length;
    config.maxAnsitropy = gl.capabilities.getMaxAnisotropy();
    return generateFlipCard(screens[0].pages[pageNumber].media, screens[0].pages[pageNumber].text, textures, pageNumber, config);
  }, [screens, pageNumber, textures, config, gl]);

  const flipcards2 = useMemo(() => {
    if(config.singleBoard) return null;
    config.numPages = screens?.[1]?.pages?.length;
    config.maxAnsitropy = gl.capabilities.getMaxAnisotropy();
    return generateFlipCard(screens[1].pages[pageNumber].media, screens[1].pages[pageNumber].text, textures, pageNumber, config);
  }, [config, screens, gl.capabilities, pageNumber, textures]);

  useFrame(({ gl, camera } : { gl: THREE.WebGLRenderer, camera: Camera }) => {
    
    refScene.current.visible = true;
    gl.setViewport(0, 0, canvasWidth / 2, canvasHeight);
    gl.setScissor(0, 0, canvasWidth / 2, canvasHeight);
    gl.setScissorTest(true);
    (camera as THREE.PerspectiveCamera).aspect = (canvasWidth / 2) / canvasHeight;
    camera.updateMatrixWorld();
    camera.updateProjectionMatrix();
    gl.render(refScene.current, camera);

    if(!config.singleBoard) {
      refScene.current.visible = false;
      gl.setScissorTest(true);
      gl.setViewport(canvasWidth / 2, 0, canvasWidth / 2, canvasHeight);
      gl.setScissor(canvasWidth / 2, 0, canvasWidth / 2, canvasHeight);
      gl.setScissorTest(true);
      camera.updateMatrixWorld();
      camera.updateProjectionMatrix();
      gl.render(refScene2.current, camera);
    }
  })

  return (
    <>
      <PerspectiveCamera makeDefault
        fov={60} position={[0, 0, cameraZPosition]} near={0.1} far={20000} >
      </PerspectiveCamera>
      <scene ref={refScene}>
        <Flipper>
          {flipcards1}
        </Flipper>
      </scene>
      {!config.singleBoard && <scene ref={refScene2}>
        <Flipper>
          {flipcards2}
        </Flipper>
      </scene>}
    </>
  )
}

export default FlipBoard;