import React from 'react';
import ReactDOM from 'react-dom/client';

import * as Hyperlive from '../../hyperlive/utils.js';
import * as Logger from '../../utils/console';
import '../../../../init';
import '../../styles/main.scss';
import { GridRunner } from 'rise-grid-framework';
import * as GridUtils from '../../risegrid/utils';
import { GridComposer, GRID_SNIPPETS } from '../../risegrid/GridComposer.js';
import CityReplay from './CityReplay.js';
import { chapterSelector } from './chapterSelector.js';
import Preloader from '../../components/Preloader/Preloader.js';
import { assetUrlFormatter } from '../../utils/assetUrlFormatter';


const { json_data } = Hyperlive.fetchJSON();
const { chapters, screen_type } = json_data;

Logger.highlight(`* SCREENTYPE: ${screen_type}`);
Logger.highlight('* JSON');
Logger.log('ANIMATIONS', window.animations);
Logger.highlight('* JSON');
Logger.log('* JSON', chapters);

// Set up frame timing/holds
const HOLD_INTRO = [
	{ frame: 'IntroStart', hold: 1000 },
	{ frame: 'CoverPlay', hold: 5000 }, //5000
];

const HOLD_CHAPTER = [
	{ frame: 'ChapterEntry', hold: 6000 },
	{ frame: 'ChapterEnd', hold: 0 },
];

const { chapter_data, assets, holdFrames, sequences } = chapterSelector(
	chapters,
	screen_type,
	HOLD_INTRO,
	HOLD_CHAPTER,
);

// COMPOSE GRID
let Grid = new GridComposer({
	// ADD COMPOSED SEQUENCES
	sequences: sequences,

	// ADD HOLD FRAMES
	// holdFrames: holdFrames,

	// SLOW DOWN FOR DEBUGGING
	// waitAll: 5000,

	// INSERT GRID SNIPPETS
	inserts: [
		// {
		//     at: "IntroEntryResolve",
		//     snippetId: GRID_SNIPPETS.STACK_ENTRY,
		//     continueLastFrameTo: 'IntroEnd',
		//     remapAsset: "Intro2"
		// }
		// ,
		// {
		//     at: `ChapterStart`,
		//     snippetId: GRID_SNIPPETS.STACK_ENTRY,
		//     continueLastFrameTo: 'ChapterEnd',
		//     remapAsset: "Intro1"
		// }
	],
});

// FINAL GRID
Logger.alert('* GRID');
Logger.log('*', Grid.sequence);

assets[0].forEach(asset => {
	asset.url = assetUrlFormatter(asset.url, { height: 1080 });
});
// INITIALIZE GRID RUNNER
const grids = new GridRunner({
	grids: [1],
	assets: assets,
	sequence: Grid.sequence,
	waitFrames: Grid.wait,
	canvasWidth: 1920,
	canvasHeight: 1080,
	gridWidth: 1920,
	gridHeight: 1080,
	backgroundColor: 0xffffff,
	lineColor: 100,
	lineThickness: 1,
});

// ADD TO DOM
document.body.appendChild(grids.app.view);

// Preload
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Preloader on={true} />);

// STARTUP
(async () => {
	if (assets[0].length > 0) {
		await grids.loadAssets();
	}

	grids.run(true);

	root.render(<CityReplay
		screen_type={screen_type}
		grids={grids}
		key={'1'}
		chapter_data={chapter_data}
		json_data={json_data}
		assets={assets}
	/>);
})();
