import { Pane } from 'tweakpane';
import * as EssentialsPlugin from '@tweakpane/plugin-essentials';
import copy from 'copy-to-clipboard';
import gsap from 'gsap';

import Word from './word';

import Swoosh from './swoosh.svg';

export default class Ticker {
  constructor(
    canvas,
    text,
    date,
    fontSize,
    speed,
    duration,
    repeat,
    startPoint,
    delay,
    hasSwoosh,
    padding,
    isStatic,
    localeCount,
    gui = false,
  ) {
    this.canvas = canvas;
    this.ctx = this.canvas.getContext('2d');
    this.time = 0;
    this.speed = speed;
    this.duration = duration;
    this.hasSwoosh = hasSwoosh;
    this.padding = padding;
    this.localeCount = localeCount;
    this.date = date;

    this.words = [];

    this.guiParams = {
      width: this.canvas.width,
      height: this.canvas.height,
      speed: Number(this.speed),
      repeat,
      padding: this.padding,
      startPoint: isStatic ? 0 : Number(startPoint),
      text: text[0],
      date: '',
      duration: this.duration,
      static: isStatic,
      delay,
    };

    text = Array.isArray(text) ? text : [text];
    this.nonENThreshold = text[0].split(' ').length;
    this.chineseRegex = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/g;
    this.englishRegex = /[a-zA-Z]/;
    this.prepareTicker(text, date, fontSize);
    this.setupGUI(text);

    for (let i = 0; i <= this.repeatGroups; i++) {
      for (let j = 0; j <= this.nonENThreshold; j++) {
        this.wordsGroup[i][j]?.createTween(
          this.guiParams.duration,
          this.guiParams.delay,
          this.guiParams.repeat,
        );
      }
    }

    this.currLocale = 0;
    if (this.isStatic() && this.localeCount > 1) {
      this.staticTween = gsap.to(this, {
        currLocale: 1,
        duration: 0,
        ease: `steps(1)`,
        yoyo: true,
        repeatDelay: delay,
        repeat: -1,
        delay,
      });
    }
    this.locales = [
      {
        text: text[0],
        width: this.getTextWidth(
          text[0],
          this.englishRegex.test(text[0]) ? 'NeuePixelGrotesk' : 'noto',
        ),
      },
      {
        text: text[1] || '',
        width: this.getTextWidth(
          text[1] || '',
          this.englishRegex.test(text[1]) ? 'NeuePixelGrotesk' : 'noto',
        ),
      },
    ];

    this.swoosh = {};
    this.swoosh.img = new Image();
    this.swoosh.img.src = Swoosh;
    this.swoosh.img.onload = () => {
      const swooshRatio = this.swoosh.img.width / this.swoosh.img.height;
      this.swoosh.height = this.fontSize * 0.6;
      this.swoosh.width = this.swoosh.height * swooshRatio;
    };

    this.running = false;
  }

  setupGUI(text) {
    this.gui = new Pane({ title: 'ticker options' });
    this.gui.registerPlugin(EssentialsPlugin);

    this.fpsGraph = this.gui.addBlade({
      view: 'fpsgraph',
      label: 'fps',
      lineCount: 2,
    });
    this.gui.addSeparator();
    this.gui
      .addInput(this.guiParams, 'width', {
        min: 1,
        max: window.innerWidth,
        step: 1,
      })
      .on('change', e => {
        this.canvas.width = e.value;
      });
    this.gui
      .addInput(this.guiParams, 'height', {
        min: 1,
        max: window.innerHeight,
        step: 1,
      })
      .on('change', e => {
        if (e.last) {
          const diff = this.canvas.height / e.value;
          this.fontSize /= diff;
          this.canvas.height = e.value;
          this.prepareTicker(
            [this.guiParams.text],
            [this.guiParams.date],
            this.fontSize,
          );
          for (let i = 0; i <= this.repeatGroups; i++) {
            for (let j = 0; j <= this.nonENThreshold; j++) {
              this.wordsGroup[i][j]?.createTween(
                this.guiParams.duration,
                this.guiParams.delay,
                this.guiParams.repeat,
              );
            }
          }
        }
      });
    this.gui.addSeparator();
    this.gui.addInput(this.guiParams, 'speed', {
      min: 0,
      max: 15,
      step: 0.1,
    });
    this.gui.addInput(this.guiParams, 'repeat', {
      min: -1,
      max: 1,
      step: 1,
    });
    this.gui
      .addButton({ title: this.guiParams.static ? 'Scroll' : 'Static' })
      .on('click', () => {
        this.guiParams.static = !this.guiParams.static;
      });
    this.gui.addInput(this.guiParams, 'padding', {
      min: 0.5,
      max: 1.5,
      step: 0.01,
    });
    this.gui.addInput(this.guiParams, 'startPoint', { min: 0, max: 1 });
    this.gui.addSeparator();
    this.gui.addInput(this.guiParams, 'text');
    this.gui.addInput(this.guiParams, 'date');
    this.gui.addInput(this.guiParams, 'duration', { min: 0.1, max: 2 });
    this.gui.addInput(this.guiParams, 'delay', { min: 0, max: 10 });
    this.gui.addButton({ title: 'create' }).on('click', () => {
      this.running = false;
      this.padding = this.guiParams.padding;
      this.prepareTicker(
        [this.guiParams.text],
        this.guiParams.date,
        this.fontSize,
      );
      for (let i = 0; i <= this.repeatGroups; i++) {
        for (let j = 0; j <= this.nonENThreshold; j++) {
          this.wordsGroup[i][j]?.createTween(
            this.guiParams.duration,
            this.guiParams.delay,
            this.guiParams.repeat,
          );
        }
      }
      this.start();
    });
    this.gui.addButton({ title: 'play/pause' }).on('click', () => {
      this.running ? this.pause() : this.resume();
    });

    this.gui.addButton({ title: 'copy config' }).on('click', () => {
      const params = this.guiParams;
      const copyData = {
        width: params.width,
        height: params.height,
        duration: params.duration,
        delay: params.delay,
        repeat: params.repeat,
        start_point: params.startPoint,
        speed: params.speed,
        padding: params.padding,
      };
      copy(JSON.stringify(copyData));
    });
  }

  prepareTicker(text, date, fontSize) {
    this.wordsGroup = [];
    this.hasDate = date && date?.filter(d => d).length;
    this.text = text
      .map((t, i) => {
        return `${date[i] ? date[i] : ''} ${t} ${this.hasSwoosh ? '§' : ''}`;
      })
      .join(' ¶ ')
      .trim();
    this.fontSize = fontSize;
    this.setTextWidth();
    this.setWords();
  }

  calculateWordCentering(word) {
    const isChinese = this.chineseRegex.test(word);
    this.ctx.font = isChinese
      ? `${this.fontSize * .85}px "CHINA_FUTURA"`
      : `${this.fontSize}px "HelveticaNeue"`;
    let tm = this.ctx.measureText(word.toUpperCase());
    let wordHeight = Math.ceil(
      Math.abs(tm.actualBoundingBoxAscent) +
      Math.abs(tm.actualBoundingBoxDescent),
    );
    console.log(`Word ${word} - wordHeight - ${wordHeight}`);
    let centeringPercentage = isChinese ? 0 : 0.045;
    return (
      (this.canvas.height - wordHeight) / 2 +
      wordHeight -
      wordHeight * centeringPercentage
    );
  }

  isStatic() {
    return this.guiParams.static;
  }

  getTextWidth(text, font) {
    this.ctx.font = `${this.fontSize}px "${font}"`;
    const txt = `${this.date[0] ? this.date[0] : ''} ${text} ${this.hasSwoosh ? '§' : ''
      }`;
    const tm = this.ctx.measureText(txt.toUpperCase());
    return tm.width;
  }

  setTextWidth() {
    this.ctx.font = `${this.fontSize}px "NeuePixelGrotesk"`;
    this.tm = this.ctx.measureText(this.text.toUpperCase());
    this.repeatGroups = this.isStatic()
      ? 0
      : Math.ceil(this.canvas.width / (this.tm.width || this.canvas.width));
  }

  setWords() {
    if (this.words) {
      this.words.forEach(word => {
        word.tween && word.tween.kill();
      });
      this.words = [];
    }
    const words = this.text.split(' ');
    for (let i = 0; i <= this.repeatGroups; i++) {
      this.wordsGroup[i] = words?.map((w, wi) => {
        const threshold = this.hasDate
          ? this.nonENThreshold + 1
          : this.nonENThreshold;
        const keepCase = /^x{1}$/gim.test(w);
        return new Word(
          keepCase ? w : w.toUpperCase(),
          wi,
          words?.length,
          this.hasDate && wi === 0,
          wi >= threshold,
        );
      });

      this.wordsGroup[i]?.forEach(word => {
        const isChinese = this.chineseRegex.test(word);
        word.setBaseline(this.calculateWordCentering(word.word));
        word.setSize(isChinese ? this.fontSize : this.fontSize);
        word.setChinese(word.word);
      });
    }
  }

  render(time) {
    this.ctx.fillStyle = 'white';
    let offwidth = this.tm.width;
    offwidth += Math.max(
      this.fontSize * 0.25,
      this.text.length * (this.fontSize * 0.025),
    );
    offwidth += this.hasSwoosh ? this.swoosh.width + this.fontSize * 2 : 0;
    const offset = offwidth * this.guiParams.padding;

    for (let i = 0; i <= this.repeatGroups; i++) {
      let staticCenter = this.locales[this.currLocale].width / 2;
      if (this.isStatic) {
        staticCenter = 0;
        this.wordsGroup[i].forEach((word, wi) => {
          const wordwidth = word.getSize();
          staticCenter += wordwidth / 2;
          staticCenter += this.fontSize * 0.125;
        });
        staticCenter -= this.fontSize * 0.125;
      }

      let cursor = this.isStatic()
        ? this.canvas.width / 2 - staticCenter
        : Math.round(offset * i - (time % offset) + 10);
      let cursor0 = cursor;

      if (this.isStatic()) cursor -= this.hasSwoosh ? this.swoosh.width / 2 : 0;

      const shouldAnimate = this.isStatic()
        ? this.currLocale === 0
        : cursor < this.canvas.width * this.guiParams.startPoint;

      this.wordsGroup[i].forEach((word, wi) => {
        const isEN = wi > this.nonENThreshold;

        if (this.isStatic()) {
          if (this.currLocale === 0) {
            if (isEN) return;
          } else {
            if (!isEN) {
              word.shouldAnimate = false;
              return;
            }
          }
        }

        if (this.hasSwoosh && /§/gim.test(word.word)) {
          // TODO: Path for launch
          if (this.text === 'WELCOME TO NIKE STYLE §') {
            cursor =
              cursor0 +
              this.locales[0].width -
              this.fontSize +
              this.fontSize * 0.9;
          } else {
            cursor = cursor0 + this.locales[0].width + this.fontSize;
          }

          this.ctx.drawImage(
            this.swoosh.img,
            cursor,
            this.canvas.height / 2 - this.swoosh.height / 2,
            this.swoosh.width,
            this.swoosh.height,
          );
          cursor += this.swoosh.width;
        } else if (!this.hasSwoosh && /¶/gim.test(word.word)) {
          cursor = cursor0 + this.locales[0].width + this.fontSize * 0.25;
        } else if (this.hasSwoosh && /¶/gim.test(word.word)) {
          cursor += this.fontSize;
        } else {
          cursor = word.draw(this.ctx, cursor, cursor > 0 && shouldAnimate);
          cursor += this.fontSize * 0.25;
        }
      });
      if (this.isStatic()) cursor -= this.fontSize * 0.25;
    }
  }

  start() {
    this.running = true;
  }

  resume() {
    this.running = true;
    for (let i = 0; i <= this.repeatGroups; i++) {
      this.wordsGroup[i].forEach(word => {
        word.tween && word.tween.resume();
      });
    }
  }

  stop() {
    this.running = false;
    for (let i = 0; i <= this.repeatGroups; i++) {
      this.wordsGroup[i].forEach(word => {
        word.tween && word.tween.pause() && word.tween.seek(0);
      });
    }
  }

  pause() {
    this.running = false;
    for (let i = 0; i <= this.repeatGroups; i++) {
      this.wordsGroup[i].forEach(word => {
        word.tween && word.tween.pause() && word.tween.seek(0);
      });
    }
  }
}
