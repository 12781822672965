import gsap from 'gsap';

import Word from './word';

export default class Line {
    constructor (ctx, text, fontSize, baseline, width, style ) {
        this.ctx = ctx;
        this.text = text.toUpperCase();
        this.fontSize = fontSize;
        this.baseline = baseline;
        this.width = width;
        this.fontStyle = style;
        this.setWords();
        this.tm = this.ctx.measureText(this.text);
        this.textSize = (this.text.length - 7) * this.fontSize;
        this.leftPercentage = this.calculateFontSize(this.text.length - 7, 0.60,  0.77);
    }

    setWords() {
        const words = this.text.toUpperCase().split(' ');
        this.words = words.map(
            (w, i) => new Word(w, i, words.length, this.ctx, this.fontStyle),
        );
        let cursor = 0;
        this.words.forEach((word, i) => {
            word.setSize(this.fontSize);
            word.setBaseline(this.baseline);
            word.draw(this.ctx, cursor)      
        })
    }

    play() {
        this.words.forEach((word) => {
            word.play();
        })
    }

    stop() {
        this.words.forEach((word) => {
            word.stop();
        })
    }

    calculateFontSize(textLength, minSize, maxSize) {
        const lerp = (x, y, a) => x * (1 - a) + y * a;
        return lerp(maxSize, minSize, textLength/31.2);
    }

    render() {
        let cursor = (this.width -  ((this.tm.width * 2 + (this.tm.width * 0.05)) / 2) ) / 2;
        this.words.forEach((word) => {
            if(word.word == 'N/') {
                cursor = 0;
            } else {
                cursor += this.fontSize * 0.25;
                cursor = word.draw(cursor, this.fontStyle);
            }
            // word.cursor = cursor;
        })
    }
}
