const _ = require('lodash');

export const constructPulseDataStory = (dataStory, lang) => {
	const data = _.get(dataStory, 'data', _.get(dataStory, 'fallback_data'));
	if (!data) {
		return null;
	}

	return composeString(data, _.get(dataStory, `content.${lang}`));
};

const composeString = (data, translatable = '', fallback = null) => {
	const matches = translatable.match(/[^[\]]+(?=])/g);

	if (!matches) {
		return fallback;
	}

	const paths = matches.map(match => match.split('::')).flat();

	paths.forEach(path => {
		const datapoint = _.get(data, path.trim());
		translatable = translatable.replace(`${path.trim()}`, datapoint);
	});

	return translatable.replace(/[\]:[]/g, '');
};

/**
 * @deprecated
 */
const SHOE_FRANCHISE = {
	react: 'Nike React',
	pegasus: 'Nike Pegasus',
	metcon: 'Nike Metcon',
};

/**
 * @deprecated
 */
const SPORT_PULSE = {
	'nrc.total_duration': (metric, franchise) => ({
		EN: `${metric} Minutes ran in ${franchise} in Seoul today`,
		KO: `${metric}분, ${franchise}를 신고 오늘 서울에서 달린 총 시간`,
	}),

	'nrc.total_distance': (metric, franchise) => ({
		EN: `${metric}km Distance run in Seoul today in ${franchise} in Seoul today`,
		KO: `${metric}KM, ${franchise} 신고 오늘 서울에서 달린 총 거리`,
	}),

	'ntc.top_workout': (metric, franchise) => ({
		EN: `${metric}. Seoul's favorite Yoga workout today. Namaste.`,
		KO: `${metric}. 오늘 서울의 가장 인기 있는 요가 프로그램입니다. 나마스테`,
	}),
};

/**
 * @deprecated
 */
export const getPulseDataStory = (pulse_data, fallback_pulse_data, lang) => {
	if (
		pulse_data &&
		pulse_data.metric &&
		pulse_data.grouping &&
		_.get(fallback_pulse_data, 'data')
	) {
		const { metric, grouping } = pulse_data;

		const franchise = SHOE_FRANCHISE[grouping];
		// always formatted nrc.metric
		const metricSpecific = metric.split('.')[1];
		const value = _.get(fallback_pulse_data, `data.${metricSpecific}`);

		if (!value) {
			return;
		}

		let rawPulseString = _.get(
			SPORT_PULSE,
			metric,
			() => null,
		)(value, franchise);

		if (!rawPulseString) {
			return;
		}

		let localizedPulse = rawPulseString[lang];

		if (!localizedPulse) {
			return;
		}

		return localizedPulse;
	}

	return;
};
