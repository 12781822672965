if (module.hot) {
  module.hot.accept(() => {
    location.reload()
  })
}

import {
  Uniform,
  Vector2,
  Color
} from 'three';
import moment from 'moment';

import UniversalLiveTemplate from '/src/touchpoints/live/lib/js/UniversalLiveTemplate';

import vert from '/src/touchpoints/live/lib/glsl/shader.vert';
import frag from './lowvc.frag';
import presetTL from './lowvc-timeline';

class Lowvc extends UniversalLiveTemplate {
  constructor (name, datas, onComplete, _gui, fullMode) {
    super(name);

    this.setReferencesDimensions(1620, 1080);

    this.datas = datas;

    const center = new Vector2(
      (window.innerWidth / 2),
      (window.innerHeight / 2)
    );

    this.setViewfinderMarks();
    this.setTopRightSlot({
      type: 'verticalText',
      content: { text: '' }
    });
    this.setBottomRightSlot({
      type: 'verticalText',
      content: { text: '' }
    });

    const isVideo = (this.datas.assets[0]?.resource_type === 'video') ? true : false;
    this.tlLength = ( isVideo && fullMode === 'full' ) ? this.datas.assets[0]?.duration || 18.16 : 18.16;

    if (isVideo) {
      this.setVideoTexture(this.datas.assets[0], 'tex0');
    } else {
      this.registerTextureURL({
        src: this.datas.assets[0].url?.replace('.png', '.jpg'),
        key: 'tex0',
        pos: { x: (window.innerWidth / 2), y: (window.innerHeight / 2) },
        dim: { x: window.innerWidth, y: window.innerHeight },
        offset: { x: 0, y: 0 },
        pixel: 1,
        zoom: 1,
      });
    }

    const uniforms = {
      // genera stuffs
      time: new Uniform(0),
      res: new Uniform(center),
      pixa: new Uniform(40),
      pixr: new Uniform(window.devicePixelRatio),
      stroke: new Uniform(Math.min(this.relWidth(1), this.relHeight(1))),
      useCover: new Uniform(Number(isVideo) || 0),
      // rangefinder
      rfPos: new Uniform(center),
      rfRadius: new Uniform(Math.min(this.relWidth(175), this.relHeight(175))),
      rfOpacity: new Uniform(1),
      // mosaic
      mosaicAmount: new Uniform(0),
      mosaicColor: new Uniform(new Color('#FFFFFF')),
      // grid
      gridSteps: new Uniform(new Vector2(6, 4)),
      gridMosaic: new Uniform(0),
      gridOpacity: new Uniform(0),
    }

    this.createVisualLayer({ frag, vert }, uniforms);
    this.setupTimeline(onComplete);
  }

  populateTimeline () {
    presetTL.bind(this)();
  }

  preStart () {
    console.log(this.visualLayer.uniforms)
    this.wrapper.classList.add('active');
  }
}
export default Lowvc;
