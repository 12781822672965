import gsap from 'gsap';

import Letter from './letter';

export default class Word {
    constructor(word, index, count, ctx, fontStyle, isChinese) {
        this.ctx = ctx;
        this.word = word;
        this.count = count;
        this.index = index;
        this.isChinese = isChinese;
        this.direction = (this.index % 2);
        this.letters = word.split('').map((l, i) => new Letter(l, this.index, i, count, fontStyle, this.isChinese));
        this.createTween(.5, 0);
        this.timing = 0;
        this.currentTickerIndex = this.letters.length - 1;
        this.firstAnimation = true;
    }

    createTween(duration, delay) {
        if (this.tween) {
            this.tween.kill();
            this.tween = null;
        }
        const steps = (this.count > 1) ? 1 : 3;
        const stagger = (duration / this.letters.length).toFixed(2);
        this.tween = gsap.to(this.letters, {
            duration,
            paused: true,
            fontIndex: (this.index + steps),
            ease: `steps(${steps})`,
            stagger: {
                each: stagger,
                from: this.direction ? 'start' : 'end'
            },
            yoyo: true,
            repeatDelay: delay,
            repeat: true,
            delay
        })
    }


    getChinese() {
        const chineseRegex = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/g;
        let isChinese = chineseRegex.test(this.word);
        return isChinese;
    };

    setBaseline(baseline) {
        this.letters.forEach(letter => {
            letter.setBaseline(baseline);
        });
    }

    setSize(size) {
        this.letters.forEach(letter => {
            letter.setSize(size);
        });
    }

    setChinese() {
        this.letters.forEach(letter => {
            letter.setChinese(this.getChinese());
        });
    }

    draw(cursor) {
        this.letters.forEach(letter => {
            letter.draw(this.ctx, cursor);
            cursor += this.ctx.measureText(letter.value).width + 0.3;

        });
        return cursor;
    }
}
