import * as PIXI from 'pixi.js-legacy';
import { Grid } from 'rise-grid-framework';
import initBackground from './grids/background';
import {SIZES} from "./constants";

export default class Background {
	constructor(app, numPoles, thickness, lineColor, height, size= SIZES.WIDE) {
		this.app = app;
		this.container = new PIXI.Container();
		this.height = height;
		this.size = size;
		this.blockWidth = 325;
		this.width = numPoles * this.blockWidth;
		this.numPoles = numPoles;
		this.grid = new Grid(
			this.container,
			this.width,
			this.height,
			thickness,
			lineColor,
		);
		this.background = initBackground(size);

		this.texture = PIXI.RenderTexture.create({
			width: this.width,
			height: this.height,
		});
	}

	tick() {
		this.app.renderer.render(this.container, this.texture);
	}

	async run() {
		this.app.ticker.add(this.tick, this);
		await this.grid.draw(this.background[0]);
		this.app.ticker.remove(this.tick, this);
	}
	async rewind() {
		this.app.ticker.add(this.tick, this);
		await this.grid.draw(this.background[1]);
		this.app.ticker.remove(this.tick, this);
	}
}
