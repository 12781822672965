export default `
  <div class="app live-app">
    <div class="grid-container">
      <div class="grid-item grid-item-1"></div>
      <div class="grid-item grid-item-2"></div>
      <div class="grid-item grid-item-3"></div>
      <div class="grid-item grid-item-4"></div>
    </div>
  </div>
`;
