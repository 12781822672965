import {ThemeType} from "../shared";
import BaseTicker from "./BaseTicker";

export default function AltitudeTicker({theme, duration = 40, reverse = false}: {theme: ThemeType, duration: number, reverse?: boolean}) {
    const color = theme === 'dark' ? 'white' : 'black';

    return (
        <div style={{height: '100%', flex: 1, marginRight: 20}}>
            <BaseTicker rotation={'90deg'} duration={duration} direction={reverse ? 'forwards' : 'backwards'}>
                <div style={{marginLeft: '36px', width: '100%', height: '100%', color}}>
                    <TickerContent />
                </div>
            </BaseTicker>
        </div>
    )
}

const TickerContent = () => {
    return (
        <svg width="1884" height="47" viewBox="0 0 1884 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1051_2146)">
                <path d="M1826.76 21.9072L1826.76 1.31695L13.223 1.31703L13.223 21.9073" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M194.549 1.31695L194.549 21.9072" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M375.885 1.31695L375.885 21.9072" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M557.332 1.31695L557.332 21.9072" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M738.657 1.31695L738.657 21.9072" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M919.992 1.31695L919.992 21.9072" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M1101.33 1.31695L1101.33 21.9072" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M1282.76 1.31695L1282.76 21.9072" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M1464.1 1.31695L1464.1 21.9072" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M1645.42 1.31695L1645.42 21.9072" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M230.842 1.31693L230.842 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M248.929 1.31693L248.929 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M267.139 1.31693L267.139 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M212.757 1.31693L212.757 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M321.505 1.31693L321.505 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M339.713 1.31693L339.713 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M357.799 1.31693L357.799 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M303.42 1.31693L303.42 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M285.223 1.31731L285.223 15.5371" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M412.178 1.31693L412.178 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M430.375 1.31693L430.375 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M448.463 1.31693L448.463 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M394.08 1.31693L394.08 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M502.84 1.31693L502.84 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M521.038 1.31693L521.038 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M539.124 1.31693L539.124 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M484.756 1.31693L484.756 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M466.67 1.31731L466.67 15.5371" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M49.5063 1.31693L49.5063 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M67.6045 1.31693L67.6045 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M85.8005 1.31693L85.8005 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M31.3108 1.31693L31.3108 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M40.4089 1.31693L40.4089 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M58.6157 1.31693L58.6157 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M76.7036 1.31693L76.7036 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M94.7891 1.31693L94.7891 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M22.3228 1.31693L22.3228 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M131.082 1.31693L131.082 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M149.28 1.31693L149.28 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M167.364 1.31693L167.364 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M185.45 1.31693L185.45 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M112.984 1.31693L112.984 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M140.181 1.31693L140.181 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M158.265 1.31693L158.265 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M176.463 1.31693L176.463 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M121.973 1.31693L121.973 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M103.888 1.31731L103.888 15.5371" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M647.993 1.31693L647.993 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M829.33 1.31693L829.33 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M1010.66 1.31693L1010.66 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M1191.99 1.31693L1191.99 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M1373.44 1.31693L1373.44 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M1554.76 1.31693L1554.76 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M1736.1 1.31693L1736.1 11.6689" stroke="currentColor" strokeWidth="1.59262" strokeMiterlimit="10"/>
                <path d="M25.9638 38.5169C25.9638 43.4085 23.6887 46.3662 20.0485 46.3662C16.4082 46.3662 14.2467 43.4085 14.2467 38.5169C14.2467 33.6253 16.2944 30.6676 20.1621 30.6676C24.0299 30.6676 26.0776 33.739 26.0776 38.5169L25.9638 38.5169ZM16.1805 38.5169C16.1805 42.3847 17.432 44.7736 20.0485 44.7736C22.6649 44.7736 24.0299 42.3847 24.0299 38.5169C24.0299 34.6491 22.7787 32.2602 20.0485 32.2602C17.3183 32.2602 16.1805 34.6491 16.1805 38.5169Z" fill="currentColor"/>
                <path d="M12.3118 38.5169C12.3118 43.4085 10.0366 46.3662 6.39635 46.3662C2.75609 46.3662 0.594738 43.4085 0.594737 38.5169C0.594737 33.6253 2.64244 30.6676 6.51022 30.6676C10.378 30.6676 12.4255 33.739 12.4255 38.5169L12.3118 38.5169ZM2.52861 38.5169C2.52861 42.3847 3.77991 44.7736 6.39635 44.7736C9.0128 44.7736 10.378 42.3847 10.378 38.5169C10.378 34.6491 9.12656 32.2602 6.39635 32.2602C3.66615 32.2602 2.52861 34.6491 2.52861 38.5169Z" fill="currentColor"/>
                <path d="M201.149 42.0439L204.448 42.0439L204.448 43.2952C202.628 43.2952 201.035 43.9778 200.694 46.2529L199.215 46.2529L199.215 31.0093L201.035 31.0093L201.035 42.0439L201.149 42.0439Z" fill="currentColor"/>
                <path d="M196.602 38.5169C196.602 43.4085 194.327 46.3662 190.687 46.3662C187.046 46.3662 184.885 43.4085 184.885 38.5169C184.885 33.6253 186.933 30.6676 190.8 30.6676C194.668 30.6676 196.716 33.739 196.716 38.5169L196.602 38.5169ZM186.819 38.5169C186.819 42.3847 188.07 44.7736 190.687 44.7736C193.303 44.7736 194.668 42.3847 194.668 38.5169C194.668 34.6491 193.417 32.2602 190.687 32.2602C187.956 32.2602 186.819 34.6491 186.819 38.5169Z" fill="currentColor"/>
                <path d="M382.592 38.0618C380.658 39.1994 379.407 39.9957 379.407 41.8159C379.407 43.636 380.658 44.8874 382.706 44.8874C383.73 44.8874 384.526 44.5461 385.095 43.9773C385.664 43.2947 386.005 42.4984 386.005 40.9058L387.939 40.9058C387.939 42.8397 387.484 44.091 386.46 45.0011C385.436 45.9112 384.071 46.3662 382.592 46.3662C379.521 46.3662 377.587 44.4323 377.587 41.7021C377.587 38.7444 379.521 37.8343 382.023 36.4692C383.616 35.5592 385.777 34.3078 386.119 32.6014L377.928 32.6014L377.928 30.8951L388.394 30.8951C388.28 34.8766 385.436 36.3555 382.706 37.9481L382.592 38.0618Z" fill="currentColor"/>
                <path d="M375.649 38.5169C375.649 43.4085 373.374 46.3662 369.734 46.3662C366.093 46.3662 363.932 43.4085 363.932 38.5169C363.932 33.6253 365.98 30.6676 369.847 30.6676C373.715 30.6676 375.763 33.739 375.763 38.5169L375.649 38.5169ZM365.866 38.5169C365.866 42.3847 367.117 44.7736 369.734 44.7736C372.35 44.7736 373.715 42.3847 373.715 38.5169C373.715 34.6491 372.464 32.2602 369.734 32.2602C367.003 32.2602 365.866 34.6491 365.866 38.5169Z" fill="currentColor"/>
                <path d="M569.956 36.0132L568.135 36.0132C568.135 33.6243 566.543 32.2592 564.04 32.2592C561.992 32.2592 560.514 33.283 560.514 35.4444C560.514 37.3783 561.992 38.4022 564.495 38.4022L565.405 38.4022L565.405 39.881L564.495 39.881C562.106 39.881 560.969 40.9049 560.969 42.4975C560.969 44.0901 562.448 45.1139 564.268 45.1139C566.429 45.1139 567.794 43.7488 567.794 41.5874L569.614 41.5874C569.614 44.5451 567.567 46.5928 564.268 46.5928C561.424 46.5928 559.262 45.1139 559.262 42.4975C559.262 40.7911 560.286 39.7673 561.765 39.3122C559.604 38.7434 558.693 37.3783 558.693 35.4444C558.693 32.6005 561.082 30.8941 564.154 30.8941C567.453 30.8941 570.069 32.828 570.183 36.2408L569.956 36.0132Z" fill="currentColor"/>
                <path d="M556.87 38.5169C556.87 43.4085 554.594 46.3662 550.954 46.3662C547.314 46.3662 545.153 43.4085 545.153 38.5169C545.153 33.6253 547.2 30.6676 551.068 30.6676C554.936 30.6676 556.983 33.739 556.983 38.5169L556.87 38.5169ZM547.086 38.5169C547.086 42.3847 548.338 44.7736 550.954 44.7736C553.571 44.7736 554.936 42.3847 554.936 38.5169C554.936 34.6491 553.684 32.2602 550.954 32.2602C548.224 32.2602 547.086 34.6491 547.086 38.5169Z" fill="currentColor"/>
                <path d="M744.006 34.7629L751.173 34.7629L751.173 36.4692L743.665 46.3662L742.186 46.3662L742.186 36.2417L739.683 36.2417L739.683 34.7629L742.186 34.7629L742.186 31.0088L744.006 31.0088L744.006 34.7629ZM749.353 36.2417L744.006 36.2417L744.006 43.2947L749.353 36.2417Z" fill="currentColor"/>
                <path d="M738.317 38.5169C738.317 43.4085 736.042 46.3662 732.401 46.3662C728.761 46.3662 726.6 43.4085 726.6 38.5169C726.6 33.6253 728.647 30.6676 732.515 30.6676C736.383 30.6676 738.431 33.739 738.431 38.5169L738.317 38.5169ZM728.534 38.5169C728.534 42.3847 729.785 44.7736 732.401 44.7736C735.018 44.7736 736.383 42.3847 736.383 38.5169C736.383 34.6491 735.132 32.2602 732.401 32.2602C729.671 32.2602 728.534 34.6491 728.534 38.5169Z" fill="currentColor"/>
                <path d="M932.387 35.3321L930.567 35.3321C930.34 33.3982 928.974 32.2606 926.813 32.2606C924.652 32.2606 923.287 33.7395 923.287 35.9009C923.287 38.0623 924.879 39.5412 926.813 39.5412C928.406 39.5412 929.543 38.8586 930.226 37.9485L932.046 37.9485L930.34 46.0254L922.263 46.0254L922.263 44.4328L928.974 44.4328L929.998 39.8824C929.088 40.6787 927.951 41.1338 926.586 41.1338C923.855 41.1338 921.466 39.1999 921.466 35.9009C921.466 32.6019 923.742 30.668 926.927 30.668C930.112 30.668 932.387 32.6019 932.501 35.3321L932.387 35.3321Z" fill="currentColor"/>
                <path d="M919.757 38.5169C919.757 43.4085 917.482 46.3662 913.842 46.3662C910.202 46.3662 908.04 43.4085 908.04 38.5169C908.04 33.6253 910.088 30.6676 913.956 30.6676C917.823 30.6676 919.871 33.739 919.871 38.5169L919.757 38.5169ZM909.974 38.5169C909.974 42.3847 911.225 44.7736 913.956 44.7736C916.686 44.7736 917.937 42.3847 917.937 38.5169C917.937 34.6491 916.686 32.2602 913.956 32.2602C911.225 32.2602 909.974 34.6491 909.974 38.5169Z" fill="currentColor"/>
                <path d="M1111.44 32.0322C1113.03 33.511 1113.6 35.5587 1113.6 38.6302C1113.6 43.2943 1111.55 46.4795 1107.8 46.4795C1104.73 46.4795 1103.25 44.4318 1103.02 42.2704L1104.84 42.2704C1104.96 43.7493 1105.98 44.8869 1107.69 44.8869C1110.64 44.8869 1111.67 42.4979 1111.78 38.8577C1110.76 40.2228 1109.39 40.9053 1107.69 40.9053C1104.96 40.9053 1102.68 38.6302 1102.68 35.7862C1102.68 32.9422 1104.96 30.6671 1107.8 30.6671C1109.51 30.6671 1110.64 31.1221 1111.55 32.0322L1111.44 32.0322ZM1104.27 35.7862C1104.27 37.9476 1105.75 39.4265 1107.69 39.4265C1109.85 39.4265 1111.33 37.9476 1111.33 35.7862C1111.33 33.6248 1109.73 32.1459 1107.57 32.1459C1105.64 32.1459 1104.16 33.6248 1104.16 35.7862L1104.27 35.7862Z" fill="currentColor"/>
                <path d="M1100.86 38.5169C1100.86 43.4085 1098.58 46.3662 1094.94 46.3662C1091.3 46.3662 1089.14 43.4085 1089.14 38.5169C1089.14 33.6253 1091.19 30.6676 1095.05 30.6676C1098.92 30.6676 1100.97 33.739 1100.97 38.5169L1100.86 38.5169ZM1091.07 38.5169C1091.07 42.3847 1092.32 44.7736 1095.05 44.7736C1097.78 44.7736 1099.04 42.3847 1099.04 38.5169C1099.04 34.6491 1097.78 32.2602 1095.05 32.2602C1092.32 32.2602 1091.07 34.6491 1091.07 38.5169Z" fill="currentColor"/>
                <path d="M1285.95 44.4323L1294.59 44.4323L1294.59 46.1387L1284.01 46.1387L1284.01 44.5461C1287.09 41.8159 1290.16 36.9242 1290.5 31.0088L1292.43 31.0088C1292.09 36.2417 1289.36 41.7021 1285.95 44.4323V44.4323Z" fill="currentColor"/>
                <path d="M1283.1 38.5169C1283.1 43.4085 1280.83 46.3662 1277.19 46.3662C1273.55 46.3662 1271.39 43.4085 1271.39 38.5169C1271.39 33.6253 1273.43 30.6676 1277.3 30.6676C1281.17 30.6676 1283.22 33.739 1283.22 38.5169L1283.1 38.5169ZM1273.32 38.5169C1273.32 42.3847 1274.57 44.7736 1277.3 44.7736C1280.03 44.7736 1281.28 42.3847 1281.28 38.5169C1281.28 34.6491 1280.03 32.2602 1277.3 32.2602C1274.57 32.2602 1273.32 34.6491 1273.32 38.5169Z" fill="currentColor"/>
                <path d="M1476.5 35.3312C1476.5 37.4926 1475.13 38.7439 1473.43 39.199C1474.79 39.7677 1475.81 40.9053 1475.81 42.4979C1475.81 44.8869 1473.65 46.4795 1470.81 46.4795C1467.97 46.4795 1465.8 45.0006 1465.8 42.4979C1465.8 40.9053 1466.71 39.8815 1468.08 39.3127C1466.37 38.7439 1465.01 37.3788 1465.01 35.3312C1465.01 31.9184 1467.85 30.6671 1470.7 30.6671C1473.54 30.6671 1476.5 31.9184 1476.5 35.3312ZM1466.94 35.3312C1466.94 37.2651 1468.42 38.5164 1470.81 38.5164C1473.2 38.5164 1474.68 37.2651 1474.68 35.3312C1474.68 33.3973 1473.2 32.2597 1470.81 32.2597C1468.42 32.2597 1467.06 33.3973 1467.06 35.3312L1466.94 35.3312ZM1467.62 42.4979C1467.62 44.0906 1468.88 45.0006 1470.81 45.0006C1472.74 45.0006 1473.99 44.0906 1473.99 42.4979C1473.99 40.9053 1472.74 39.9953 1470.81 39.9953C1468.88 39.9953 1467.62 40.9053 1467.62 42.4979Z" fill="currentColor"/>
                <path d="M1463.64 38.5159C1463.64 43.4075 1461.37 46.3652 1457.73 46.3652C1454.08 46.3652 1451.92 43.4075 1451.92 38.5159C1451.92 33.6243 1453.97 30.6666 1457.84 30.6666C1461.71 30.6666 1463.75 33.7381 1463.75 38.5159L1463.64 38.5159ZM1453.86 38.5159C1453.86 42.3837 1455.11 44.7726 1457.84 44.7726C1460.57 44.7726 1461.82 42.3837 1461.82 38.5159C1461.82 34.6481 1460.57 32.2592 1457.84 32.2592C1455.11 32.2592 1453.86 34.6481 1453.86 38.5159Z" fill="currentColor"/>
                <path d="M1657.6 35.1036L1655.78 35.1036C1655.66 33.3973 1654.53 32.2597 1652.48 32.2597C1651.34 32.2597 1650.55 32.601 1649.86 33.2835C1648.95 34.1936 1648.61 35.9 1648.5 38.1751C1649.52 36.9238 1650.89 36.2412 1652.71 36.2412C1655.44 36.2412 1657.71 38.5164 1657.71 41.3604C1657.71 44.2043 1655.44 46.4795 1652.48 46.4795C1651 46.4795 1649.86 46.0245 1648.95 45.3419C1647.25 43.9768 1646.68 41.8154 1646.68 38.5164C1646.68 35.4449 1647.25 33.2835 1648.84 31.9184C1649.75 31.1221 1651 30.6671 1652.37 30.6671C1655.32 30.6671 1657.48 32.4872 1657.6 35.1036V35.1036ZM1648.84 41.3604C1648.84 43.5218 1650.55 45.0006 1652.48 45.0006C1654.41 45.0006 1656.01 43.5218 1656.01 41.3604C1656.01 39.199 1654.41 37.7201 1652.48 37.7201C1650.2 37.7201 1648.72 39.199 1648.72 41.3604L1648.84 41.3604Z" fill="currentColor"/>
                <path d="M1644.86 38.5169C1644.86 43.4085 1642.58 46.3662 1638.94 46.3662C1635.3 46.3662 1633.14 43.4085 1633.14 38.5169C1633.14 33.6253 1635.19 30.6676 1639.05 30.6676C1642.92 30.6676 1644.97 33.739 1644.97 38.5169L1644.86 38.5169ZM1635.07 38.5169C1635.07 42.3847 1636.32 44.7736 1639.05 44.7736C1641.78 44.7736 1643.04 42.3847 1643.04 38.5169C1643.04 34.6491 1641.78 32.2602 1639.05 32.2602C1636.32 32.2602 1635.07 34.6491 1635.07 38.5169Z" fill="currentColor"/>
                <path d="M1840.18 42.0429L1843.48 42.0429L1843.48 43.2942C1841.66 43.2942 1840.07 43.9768 1839.72 46.252L1838.25 46.252L1838.25 31.0083L1840.07 31.0083L1840.07 42.0429L1840.18 42.0429Z" fill="currentColor"/>
                <path d="M1835.63 38.5164C1835.63 43.408 1833.36 46.4795 1829.72 46.4795C1826.08 46.4795 1823.92 43.5218 1823.92 38.6302C1823.92 33.7386 1825.96 30.7808 1829.83 30.7808C1833.7 30.7808 1835.75 33.8523 1835.75 38.6302L1835.63 38.5164ZM1825.85 38.5164C1825.85 42.3842 1827.1 44.7731 1829.83 44.7731C1832.56 44.7731 1833.81 42.3842 1833.81 38.5164C1833.81 34.6486 1832.56 32.2597 1829.83 32.2597C1827.1 32.2597 1825.85 34.6486 1825.85 38.5164Z" fill="currentColor"/>
                <path d="M1821.98 38.5164C1821.98 43.408 1819.7 46.4795 1816.06 46.4795C1812.42 46.4795 1810.26 43.5218 1810.26 38.6302C1810.26 33.7386 1812.31 30.7808 1816.18 30.7808C1820.05 30.7808 1822.09 33.8523 1822.09 38.6302L1821.98 38.5164ZM1812.2 38.5164C1812.2 42.3842 1813.45 44.7731 1816.18 44.7731C1818.91 44.7731 1820.16 42.3842 1820.16 38.5164C1820.16 34.6486 1818.91 32.2597 1816.18 32.2597C1813.45 32.2597 1812.2 34.6486 1812.2 38.5164Z" fill="currentColor"/>
            </g>
            <defs>
                <clipPath id="clip0_1051_2146">
                    <rect width="1843" height="45.9584" fill="white" transform="translate(1843.48 46.4795) rotate(180)"/>
                </clipPath>
            </defs>
        </svg>
    )
}