export default function Court1({}) {
    return (
        <svg width="67" height="68" viewBox="0 0 67 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="34.7389" cy="34.2555" r="27.6271" stroke="currentColor" strokeWidth="1.11426"/>
            <g clipPath="url(#clip0_313_295)">
                <circle cx="5.89927" cy="32.9442" r="21.0726" stroke="currentColor" strokeWidth="1.11426"/>
                <circle cx="62.2674" cy="32.9442" r="21.0726" stroke="currentColor" strokeWidth="1.11426"/>
            </g>
            <line y1="33.0435" x2="66.8555" y2="33.0435" stroke="currentColor" strokeWidth="1.11426"/>
            <line x1="33.9849" y1="0.171875" x2="33.9849" y2="67.0274" stroke="currentColor" strokeWidth="1.11426"/>
            <defs>
                <clipPath id="clip0_313_295">
                    <rect x="6.55469" y="6.07129" width="56.3684" height="56.3684" rx="28.1842" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}