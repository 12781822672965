#pragma glslify: circle = require('../../../../lib/glsl/circle.glsl')
#pragma glslify: ring = require('../../../../lib/glsl/ring.glsl')
#pragma glslify: box = require('../../../../lib/glsl/box.glsl')
#pragma glslify: grid = require('../../../../lib/glsl/grid.glsl')
#pragma glslify: mosaic = require('../../../../lib/glsl/mosaic.glsl')
#pragma glslify: viewfinder = require('../../../../lib/glsl/viewfinder.glsl')

#pragma glslify: levels = require('../../../../lib/glsl/levels.glsl')

#pragma glslify: coverUV = require('../../../../lib/glsl/coverUV.glsl')
#pragma glslify: pixelateUV = require('../../../../lib/glsl/pixelateUV.glsl')
#pragma glslify: placedUV = require('../../../../lib/glsl/placedUV.glsl')
#pragma glslify: zoomedUV = require('../../../../lib/glsl/zoomedUV.glsl')
#pragma glslify: gammaCorrect = require('../../../../lib/glsl/gammaCorrect.glsl')

#ifdef GL_ES
precision mediump float;
#endif

varying vec2 vUv;

// general stuffs
uniform float time; // time
uniform vec2 res; // canvas size
uniform float pixa; // pixelization amount (size of each cell/pixel)
uniform float pixr; // pixel ratio
uniform float stroke; // stroke width
uniform float padding;
// mosaic
uniform float mosaicAmount; // how much mosaic is covering the screen (0-1)
uniform float mosaicColor; // greyscale color
// img1
uniform sampler2D img1;
uniform vec2 img1Res;
uniform vec2 img1Pos;
uniform vec2 img1Dim;
uniform vec2 img1Off;
uniform float img1Pix;
uniform float img1Zoom;
// img2
uniform sampler2D img2;
uniform vec2 img2Res;
uniform vec2 img2Pos;
uniform vec2 img2Dim;
uniform vec2 img2Off;
uniform float img2Pix;
uniform float img2Zoom;
// cutout mask
uniform sampler2D cutoutTexture;
uniform float contourPixelAmount;
uniform float maskPixelAmount;
uniform float imgMosaicAmount;
uniform float maskZoom;
uniform float maskLevel;
uniform float maskGamma;

vec3 griddedImage (vec3 color, sampler2D img, vec2 imgRes, vec2 pos, vec2 dim, vec2 offset, float zoom, float pr, float imgpx, float thickness) {
  vec2 center = (gl_FragCoord.xy - pos);

  vec2 uv = coverUV(vUv, res, imgRes);
  vec2 puv = uv;

  uv = zoomedUV(uv, imgRes, zoom, offset);
  uv -= offset;
  uv = pixelateUV(uv, imgpx, (imgRes.x / imgRes.y));
  float b = box(center, dim);
  color = mix(color, texture2D(img, uv).rgb, b);
  color = levels(color, 0.0/255.0, maskGamma, maskLevel/255.0);

  return color;
}

vec3 maskImage (vec3 color, sampler2D img, sampler2D maskTex, vec2 imgRes, vec2 pos, vec2 dim, float zoom, vec2 offset, float pr, float px, float mf) {
  vec3 clr = color;

  vec2 center = (gl_FragCoord.xy - pos);
  float tar = (imgRes.x / imgRes.y);
  vec2 uv = coverUV(vUv, res, imgRes);
  float b = box(center, dim);

  zoom /= max((res.y / res.x), (res.x / res.y)) * padding;

  float mz = (zoom + maskZoom);
  // float mz = zoom;
  vec2 mUv = pixelateUV(uv, contourPixelAmount);
  mUv = zoomedUV(mUv, imgRes, zoom, offset);

  float msk = texture2D(maskTex, mUv, -10.0).a;
  msk += texture2D(maskTex, mUv + vec2(0.025, 0.01), -10.0).a;
  msk += texture2D(maskTex, mUv - vec2(0.025, 0.01), -10.0).a;
  msk += texture2D(maskTex, mUv + vec2(0.0333, 0.02), -10.0).a;
  msk += texture2D(maskTex, mUv - vec2(0.0333, 0.02), -10.0).a;
  float mask = 1.0 - step(msk, 0.15);

  zoom *= 0.9;
  uv = pixelateUV(uv, px, tar);
  uv = zoomedUV(uv, imgRes, zoom, offset);
  vec3 pix = texture2D(img, uv, -10.0).rgb * b;
  color = mix(color, pix, mask);

  float o = (1. - mosaic(vUv, tar, time, (1. - mf), 8., mask));
  if ((uv.x > 1. || uv.x < 0.) || (uv.y > 1. || uv.y < 0.)) {
    o = 0.0;
  }

  return mix(clr, color, o);
  // return mix(clr, vec3(mask), o); // uncomment to show mask
}

void main() {
  vec3 color = vec3(1.);

  vec2 st = (gl_FragCoord.xy / res) / pixr;
  float ar = (res.x / res.y);
  float px = ((res.x * pixr) / pixa);
  float thickness = (stroke * pixr);
  float thickdiff = (stroke / 100.);

  color = griddedImage(color, img1, img1Res, (img1Pos * pixr), ((img1Dim + thickness) * pixr), img1Off, img1Zoom, pixr, (res.x / img1Pix * pixr), thickness);

  float mpx = ((res.x * pixr) / maskPixelAmount);
  color = maskImage(color, img2, cutoutTexture, img2Res, (img2Pos * pixr), (img2Dim * pixr), img2Zoom, img2Off, pixr, mpx, imgMosaicAmount);

  color = mix(color, vec3(mosaicColor), mosaic(st, ar, time, mosaicAmount));

  gl_FragColor = vec4(gammaCorrect(color.rgb,2.2), 1.0);
}
