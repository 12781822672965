import PropTypes from "prop-types";

const codification = {
	tag: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "div", "p"]),
	text: PropTypes.string,
	typography: PropTypes.object,
	reverse: PropTypes.bool,
	start: PropTypes.bool,
	characterSwitchAmount: PropTypes.number,
	characterNextTrigger: PropTypes.number,
	characterTimeout: PropTypes.number,
	align: PropTypes.oneOf(["left", "right"]),
	delay: PropTypes.number,
	onEnded: PropTypes.func,
};

const provider = {
	text: codification.text,
	typography: codification.typography,
	repeat: codification.characterSwitchAmount,
	trigger: codification.characterNextTrigger,
	timeout: codification.characterTimeout,
	start: codification.start,
	reverse: codification.reverse,
	align: codification.align,
	delay: codification.delay,
	onEnded: codification.onEnded,
};

const line = {
	words: PropTypes.array,
	index: PropTypes.number,
};

const word = {
	characters: PropTypes.array,
	index: PropTypes.number,
};

const character = {
	text: PropTypes.string,
	index: PropTypes.number,
};

export default {
	codification,
	provider,
	line,
	word,
	character,
};
