import { useMemo } from 'react';
import Ticker from 'react-ticker';
import _ from 'lodash';
import './NSILSchedule.scss';
import NSILVideo from './components/NSILVideo';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);

const video =
  'https://res.cloudinary.com/jbboerner/video/upload/v1672181443/nsil/BGW2.mp4';
const logo =
  'https://res.cloudinary.com/jbboerner/image/upload/v1665545811/nsil/nsil_logo.png';

const NSILSchedule = ({
  title,
  supertitle,
  status,
  currentMonth,
  currentDate,
  showUpcoming,
  smallFont,
}) => {
  const ticker_text = useMemo(() => {
    switch (status) {
      case 'quiet':
        return (
          <>
            <span>WORK IN PROGRESS</span>
            <span>ENTER QUIETLY</span>
          </>
        );
        break;
      case 'do_not_enter':
        return (
          <>
            <span>MEETING IN PROGRESS</span>
            <span>DO NOT ENTER</span>
          </>
        );
        break;
      case 'open':
      default:
        return (
          <>
            <span>AVAILABLE</span>
          </>
        );
    }
  }, [status]);

  const heading = useMemo(() => {
    if (title) {
      return title.split('\n').map((line, index) => {
        return (
          <span className={smallFont ? 'small-font' : ''} key={index}>
            {line}
          </span>
        );
      });
    } else {
      return null;
    }
  }, [title]);

  const eyebrow = useMemo(() => {
    if (supertitle) {
      return supertitle.split('\n').map((line, index) => {
        return <span key={index}>{line}</span>;
      });
    } else {
      return null;
    }
  }, [supertitle]);

  const Content = () => {
    return (
      <div className={'NSILSchedule-content'}>
        {eyebrow && showUpcoming === 'current-event' && <h2>{eyebrow}</h2>}
        {heading && <h1>{heading}</h1>}
        <div className={'NSILSchedule-content-footer'}>
          Contact NikeStoresInnovationLab@Nike.com for booking.
        </div>
      </div>
    );
  };

  return (
    <div className={`NSILSchedule ${status} ${showUpcoming}`}>
      <div className={`NSILSchedule-ticker ${status}`}>
        <Ticker speed={3}>{({ index }) => <>{ticker_text}</>}</Ticker>
      </div>

      <div className={'NSILSchedule-video'}>
        <NSILVideo data={{ asset: { url: video } }} />
      </div>

      <div className={'NSILSchedule-header'}>
        <img src={logo} />
      </div>

      <div className={'NSILSchedule-header-right'}>
        <h2>
          {currentMonth}
          <br></br>
          {currentDate}
        </h2>
      </div>

      <Content />
    </div>
  );
};

export default NSILSchedule;
