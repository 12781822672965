// React
import React, { useEffect, useMemo, useState, Suspense, useCallback } from 'react';
import ReactDOM from "react-dom/client";
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import FlipBoard, { boardColumns, boardRows } from './Flip/FlipBoard';
import { canvasWidth, canvasHeight, columnWidth, rowHeight, getAssetsByType, characterFlipSpeed } from './Flip/shared';
import { Perf } from "r3f-perf";
import * as THREE from 'three';
import '../../../../init';
// Hyperlive Utils
import * as Hyperlive from '../../../rise/hyperlive/utils.js';



const { json_data } = Hyperlive.fetchJSON();
console.log(JSON.stringify(json_data));
// @Harley this is in seconds.
const {
  page_duration = 10,
  row_flip = 'auto',
  row_spacing = 0,
  media_alpha = 100,
  character_split = 0.4,
  character_flip_speed = characterFlipSpeed,
} = window.channel?.channels?.[0]?.json_config ?? {};

console.log('page_duration', page_duration);

function App() {
  const [chapterNumber, setChapterNumber] = useState(0);
  const [showPerf, setShowPerf] = useState(false);
  const [textures, setTextures] = useState()

  const onPagesFinished = useCallback(() => {
    setChapterNumber(chapterNumber => {
      let newChapterNumber = chapterNumber;
      newChapterNumber++;
      if (newChapterNumber >= json_data?.chapters.length) {
        newChapterNumber = 0;
      }
      return newChapterNumber;
    });
  }, [setChapterNumber]);

  const perf = useMemo(() => <Perf />, []);

  const config = useMemo(() => {
    return {
      row_flip,
      row_spacing,
      page_duration: page_duration * 1000,
      media_alpha,
      character_split,
      character_flip_speed,
      numChapters: json_data?.chapters.length,
      singleBoard: json_data?.chapters[0]?.screens.length === 1
    };
  }, []);

  const flipboard = useMemo(() => {
    const screens = json_data?.chapters[chapterNumber]?.screens;

    return <FlipBoard
      screens={screens}
      onPagesFinished={onPagesFinished}
      textures={textures}
      config={config}
    />
  }, [textures, chapterNumber, onPagesFinished, config]);

  useEffect(() => {
    const cacheAllAssets = async () => {
      const getMediaTransformUrl = (url, width, height, alpha) => {
        const imageTransforms = `q_auto:best/g_north_west,c_fill,w_${width},h_${Math.ceil(height)},b_black,o_${alpha}`;
        const splits = url.split('upload/');
        return splits?.join(`upload/${imageTransforms}/`);
      }
      let textures = {};
      const videoAssets = getAssetsByType(json_data, 'video');
      const imageAssets = getAssetsByType(json_data, 'image');
      const totalAssets = Object.keys(videoAssets).length + Object.keys(imageAssets).length;
      if (totalAssets === 0) return setTextures(textures);
      let loadedAssests = 0;

      const width = boardColumns * columnWidth;
      const height = (boardRows * rowHeight * (1 + config.row_spacing)) - (rowHeight * config.row_spacing);
      const videoPromiseUrls = Object.keys(videoAssets).map(async (videoAsset) => {
        let assetObj;
        const videoUrl = videoAssets[videoAsset];
        try {
          assetObj = {
            id: videoAsset,
            url: URL.createObjectURL(await (await fetch(getMediaTransformUrl(videoUrl, width, height, config.media_alpha))).blob())
          };
          return assetObj;
        }
        catch (e) {
          console.log(`Unable to load url - ${videoUrl} error - ${e.message}`)
        }
      });
      let blobs;
      try {
        blobs = await Promise.all(videoPromiseUrls);
        blobs.forEach((blob) => {
          const video = Object.assign(document.createElement('video'), {
            src: blob.url,
            unsuspend: 'canplay',
            crossOrigin: 'Anonymous',
            muted: true,
            loop: true,
            start: true,
          });
          textures[blob.id] = new THREE.VideoTexture(video);
          textures[blob.id].image.play();
          loadedAssests++;
          if (loadedAssests === totalAssets) return setTextures(textures);
          console.log(`Caching - ${blob.url}`);
        });
      }
      catch (e) {
        console.log(`Unable to load video blobs - error - ${e.message}`)
      }

      const imagePromiseUrls = Object.keys(imageAssets).map(async (imageAsset) => {
        let assetObj;
        const imageUrl = imageAssets[imageAsset];
        try {
          assetObj = {
            id: imageAsset,
            url: URL.createObjectURL(await (await fetch(getMediaTransformUrl(imageUrl, width, height, config.media_alpha))).blob())
          };
        }
        catch (e) {
          console.log(`Unable to load url - ${imageUrl} error - ${e.message}`)
        }
        return assetObj;
      });
      
      try {
        blobs = await Promise.all(imagePromiseUrls);
        blobs.forEach(async (blob) => {
          try {
            const loader = new THREE.TextureLoader();
            textures[blob.id] = await loader.loadAsync(blob.url);
            URL.revokeObjectURL(blob.url);
            console.log(`Caching - ${blob.url}`);
          }
          catch (e) {
            console.log(`Unable to load url - ${blob.url} error - ${e.message}`)
          }
          loadedAssests++;
          if (loadedAssests === totalAssets) setTextures(textures);
        });
      }
      catch (e) {
        console.log(`Unable to load image blobs - error - ${e.message}`)
      }
    }

    cacheAllAssets();

    window.addEventListener('keypress', e => {
      setShowPerf(showPerf => !showPerf);
    });

    return () => {
      window.removeEventListener('keypress');
    }
  }, [config]);

  const isReady = () => !!textures;

  return (
    <div>
      {showPerf && <div style={{ position: 'absolute', right: '0', zIndex: 99 }}>
        <select defaultValue={0}
          onChange={({ target }) => {
            if (parseInt(target.value) === -1) {
              setChapterNumber(0);
            } else {
              setChapterNumber(parseInt(target.value));
            }
          }}
        >
          {
            Array.from({ length: json_data?.chapters.length }).map((j, i) => (
              <option value={i}>Chapter {i + 1}</option>
            ))
          }
        </select>
      </div>}
      <pre>
        {/* {JSON.stringify(text, null, 2)} */}
      </pre>
      {isReady() &&
        <div>
          <Suspense>
            <Canvas
              gl={{ antialias: false }}
              flat={true}
              dpr={[1, 2]}
              style={{
                background: 'black',
                position: "fixed",
                top: 0,
                left: 0,
                width: config.singleBoard ? canvasWidth / 2 : canvasWidth,
                height: canvasHeight
              }}
            >
              {showPerf && <OrbitControls />}
              {flipboard && flipboard}
              {showPerf && perf}
            </Canvas>
          </Suspense>
        </div>
      }
    </div>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
