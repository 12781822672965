import {useEffect, useMemo, useState} from "react";
import {get} from "lodash";

const WEATHER_API = `https://api.openweathermap.org/data/3.0/onecall`;
const OPEN_WEATHER_API_KEY = '66cb6e747123b6b18474f2ab0b8cd750';

export interface WeatherApi {
    lat: number;
    long: number;
    unit?: 'imperial' | 'metric';
    forceRefresh?: boolean;
}

function constructWeatherApiUrl({lat, long, unit = 'imperial'}: WeatherApi) {
    if (lat === undefined || long === undefined) {
        throw new Error('lat and lon are required');
    }

    const url = new URL(WEATHER_API);
    url.searchParams.append('lat', lat.toString());
    url.searchParams.append('lon', long.toString());
    url.searchParams.append('appid', OPEN_WEATHER_API_KEY);
    url.searchParams.append('units', unit);

    return `${url.toString()}&exclude=current,minutely,hourly,alerts`;
}

function getFromLocalStorage(weatherApi: WeatherApi) {
    const key = `weather-${weatherApi.lat}-${weatherApi.long}`;
    const item = localStorage.getItem(key);
    if (item) {
        const parsed = JSON.parse(item);
        parsed.currentDate = new Date(parsed.currentDate);
        return parsed;
    }
    return null;
}

function setToLocalStorage(weatherApi: WeatherApi, weather: WeatherResult) {
    weather.currentDate = new Date();
    const key = `weather-${weatherApi.lat}-${weatherApi.long}`;
    localStorage.setItem(key, JSON.stringify(weather));
}

export async function getWeatherApi(weatherApi: WeatherApi): Promise<WeatherResult> {
    const url = constructWeatherApiUrl(weatherApi);

    const cachedWeather = getFromLocalStorage(weatherApi);
    const currentDate = new Date();
    const lastUpdated = cachedWeather?.currentDate;
    const diff = currentDate.getTime() - lastUpdated?.getTime();
    const LIMIT = 1000 * 60 * 60 * 3; // 3 hours

    if (cachedWeather && !weatherApi.forceRefresh && diff < LIMIT) {
        return cachedWeather;
    }

    try {
        const response = await fetch(url.toString());
        const result = await response.json();
        result.unit = weatherApi.unit;
        setToLocalStorage(weatherApi, result);
        console.log('caching weather', result);
        return result;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export function useWeatherSingleLine() {
    const {weather, loading} = useWeather();

    const weatherString = useMemo(() => {
        if (!weather) return null;

        const temp = Math.round(weather?.daily?.[0].temp.day);
        const rawConditions = weather?.daily?.[0].weather[0].main?.toLowerCase();
        const conditions = {
            'clouds': 'Cloudy',
            'clear': 'Sunny',
            'rain': 'Rainy',
            'snow': 'Snowy',
            'thunderstorm': 'Stormy',
            'drizzle': 'Drizzly',
            'fog': 'Foggy',
        }[rawConditions] || rawConditions;
        const unit = weather.unit === 'imperial' ? '°F' : '°C';

        return `${temp}${unit} ${conditions.toLowerCase()}`;
    }, [weather, loading]);

    return {weather: weatherString, loading};
}

export function useWeather(api: WeatherApi = defaultWeatherApi()) {
    const [weather, setWeather] = useState<WeatherResult>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const getWeather = async () => {
        setLoading(true);
        try {
            const response = await getWeatherApi(api);
            setWeather(response);
        } catch (error) {
            setError(error);
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getWeather().then();
    }, []);

    return {weather, loading, error};
}

export function defaultWeatherApi(): WeatherApi {
    const meta = get(window, 'animation_store.json_meta.geo', {});
    const lat = get(meta, 'lat', 0);
    const long = get(meta, 'long', 0);
    const unit = get(meta, 'units', 'imperial');

    return {
        lat,
        long,
        unit,
    }
}

export interface WeatherResult {
    lat: number;
    lon: number;
    timezone: string;
    timezone_offset: number;
    daily: Daily[];
    currentDate?: Date;
    unit?: 'imperial' | 'metric';
}

export interface Daily {
    dt: number;
    sunrise: number;
    sunset: number;
    moonrise: number;
    moonset: number;
    moon_phase: number;
    summary: string;
    temp: Temp;
    feels_like: FeelsLike;
    pressure: number;
    humidity: number;
    dew_point: number;
    wind_speed: number;
    wind_deg: number;
    wind_gust: number;
    weather: Weather[];
    clouds: number;
    pop: number;
    uvi: number;
}

export interface Temp {
    day: number;
    min: number;
    max: number;
    night: number;
    eve: number;
    morn: number;
}

export interface FeelsLike {
    day: number;
    night: number;
    eve: number;
    morn: number;
}

export interface Weather {
    id: number;
    main: string;
    description: string;
    icon: string;
}
