import React, { useEffect, useState } from 'react';

import '../../styles/normalize.css';
import '../../styles/main.scss';
import '../../i18n';

import { pixelMapReady } from '../../../../utilities';
import {
    ChapterType,
    prepareShowRunner,
    secToMs,
    TransitionConfig
} from '../../shared';
import { TickerRunnerProps } from '../../components/TickerRunner';
import Container from '../../components/Container';
import {ChapterRenderOptions} from '../../components/ChapterRenderer';
import ChapterRunner from '../../components/ChapterRunner';
import TitleCard from '../../screens/TitleCard';
import Wayfinding from '../../screens/Wayfinding';
import Media from '../../screens/Media';
import CampaignIntro from '../../screens/CampaignIntro';
import Services from '../../screens/Services';
import ProductIntro from '../../screens/ProductIntro';
import Carousel from '../../screens/Carousel';
import SnkrsTitle from '../../screens/SnkrsTitle';
import SnkrsCal from '../../screens/SnkrsCal';

type AppProps = {
    data: {
        chapters?: ChapterType[],
        ticker?: TickerRunnerProps,
        transition?: TransitionConfig,
    },
    openChapter?: string | number,
    config: any;
}

const renderOptions: ChapterRenderOptions = {
    title_card: {
        render: TitleCard,
        exitTimeOffset: secToMs(1),
    },
    wayfinding: {
        render: Wayfinding,
    },
    media: {
        render: Media,
    },
    services: {
        render: Services,
    },
    product_intro: {
        render: ProductIntro,
    },
    campaign_intro: {
        render: CampaignIntro,
    },
    carousel: {
        render: Carousel,
    },
    snkrs_title: {
        render: SnkrsTitle,
    },
    snkrs_cal: {
        render: SnkrsCal,
    },
    snkrs_cal_stories: {
        render: SnkrsCal,
    },
    snkrs_cal_blend: {
        render: SnkrsCal,
    },
};

export default function App(props: AppProps) {
    const {
        data = {},
        openChapter = null,
        config
    } = props;

    const [key, setKey] = useState(0);
    const [chapters, setChapters] = useState<ChapterType[]>([]);

    useEffect(() => {
        pixelMapReady(() => {
            setKey(i => i + 1);
        });

        prepareShowRunner(data, config).then((chapters) => {
            setChapters(chapters || []);
        });
    }, []);

    return (
        <Container
            framed={false}
            width={1080}
            height={1920}
        >
            {chapters.length > 0 ? (
                <ChapterRunner
                    key={key}
                    chapters={chapters}
                    config={config}
                    openChapter={openChapter}
                    renderOptions={renderOptions}
                    globalTicker={data.ticker}
                    transition={data.transition}
                />
            ) : null}
        </Container>
    )
}
