import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import NBYMenuItem from "../NBYMenuItem/NBYMenuItem";
import "./NBYMenu.scss";

import { NIKE_EASE } from '../../../../constants/nikerise';
const DEFAULT_TRANSITION = { ease: NIKE_EASE, duration: 1 };
const swoosh = require('../../assets/swoosh.svg');

export default function NBYMenu({ chapters, duration = 4_000, preview = null }) {

  const [chapter, setChapter] = useState(0);
  const chapter_length = chapters ? chapters.length : 0;
  const { entries, title, description } = preview ? chapters[preview] : chapters[chapter];

  setTimeout(() => {
    let new_chapter = chapter + 1;
    if (new_chapter >= chapter_length) {
      new_chapter = 0;
    }
    setChapter(new_chapter)
  }, duration);

  return (
    <div className={'NBYMenu'}>

      <motion.div
        className={'NBYMenu-content'}
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: '100%', transition: { ...DEFAULT_TRANSITION } }}
        exit={{ opacity: 0, height: '0%', transition: { ...DEFAULT_TRANSITION } }}
      >

        <div className={'NBYMenu-chapter'}>
          <header>
            <h1>{title}</h1>
            <h2>{description}</h2>
          </header>

          {
            entries &&
            <div className={'NBYMenu-items'}>
              {entries.map((entry, index) => {
                return <NBYMenuItem entry={entry} key={`textItem-${chapter}-${index}`} index={index} />
              })
              }
            </div>
          }

        </div>

        <div className={'NBYMenu-swoosh'}>
          <img src={swoosh} />
        </div>

      </motion.div>

    </div>
  );
}
