export default function placeText (content) {
  const div = document.createElement('div');
  div.classList.add('placed-text');
  content.class && div.classList.add(content.class);

  div.style.left = content.left;
  div.style.top = content.top;
  div.style.right = content.right;
  div.style.bottom = content.bottom;
  div.style.width = content.width;
  div.style.height = content.height;
  div.style.textAlign = content.textAlign;

  const p = document.createElement('p');
  p.innerHTML = content.text;

  div.appendChild(p);
  return div;
}
