import React from 'react';
import Text from './text';
import {SIZES} from "./constants";
import HorizontalLines from "./horizontal-lines";

const FastLaneViewer = ({
                          runner,
                          lanes,
                          textJson,
                          circleGap,
                          svgLines = false,
                          svgCircle,
                          persistentTitle,
                          qrWidth,
                          size = SIZES.WIDE,
                          fontSizeMultiplier
                        }) => {
  let index = 0;

  return (
    <>
      {lanes
        .map((lane, i) => {
          const lines = svgLines ? (
            <HorizontalLines totalLanes={lanes.length} lineIndex={i} lane={lane}/>
          ) : null;

          if (lane.isFiller || !textJson[index]) return lines;
          const num = lane.getNumContainers();
          const JSX = new Array(num).fill(0).map((_, j) => {
            return (
              <Text
                key={`text_${i}_${j}`}
                lane={lane}
                runner={runner}
                svgCircle={svgCircle}
                persistentTitle={persistentTitle}
                wrap={j === 1}
                circleGap={circleGap}
                json={textJson[index]}
                size={size}
                qrWidth={qrWidth}
                fontSizeMultiplier={fontSizeMultiplier}
              />
            );
          });
          index++;
          return (
            <>
              {lines}
              {JSX}
            </>
          );
        })
        .flat()}
    </>
  );
};

export default FastLaneViewer;
