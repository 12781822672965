[
    {
        "mask": [
            {
                "vertical": [
                    "Left Border",
                    "Right Border"
                ],
                "grid": 0,
                "coverFill": true,
                "asset": "0",
                "name": "Stack All",
                "fadeIn": true,
                "horizontal": [
                    "Top Border",
                    "Bottom Border"
                ]
            }
        ],
        "duration": "1000",
        "vertical": [],
        "startColor": 160,
        "circle": [],
        "insetBorder": false,
        "horizontal": [
            {
                "drawFull": true,
                "name": "StackTop",
                "startColor": 176,
                "extend": false,
                "drawFromPosition": 0,
                "startAlpha": 0.01,
                "drawToPosition": 100,
                "startPosition": 25,
                "drawDirection": 1,
                "endPosition": 25
            },
            {
                "drawToPosition": 100,
                "drawFull": true,
                "extend": false,
                "startPosition": 50,
                "endPosition": 50,
                "startAlpha": 0.01,
                "name": "StackEquator",
                "drawFromPosition": 0,
                "drawDirection": 1
            },
            {
                "drawFromPosition": 0,
                "drawToPosition": 100,
                "name": "StackBottom",
                "startAlpha": 0.01,
                "drawFull": true,
                "endPosition": 75,
                "extend": false,
                "startPosition": 75,
                "drawDirection": 1
            }
        ],
        "name": "StackEntry",
        "drawBorder": true,
        "id": 0
    },
    {
        "mask": [
            {
                "vertical": [
                    "Left Border",
                    "Right Border"
                ],
                "grid": 0,
                "coverFill": true,
                "asset": "0",
                "name": "Stack All",
                "fadeIn": false,
                "horizontal": [
                    "Top Border",
                    "Bottom Border"
                ]
            }
        ],
        "horizontal": [],
        "vertical": []
    }
]