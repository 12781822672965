// React
import React from 'react';
import ReactDOM  from 'react-dom/client';

// Hyperlive Utils
import * as Hyperlive from '../../hyperlive/utils.js';

// Globals
import '../../../../init';
import '../../styles/main.scss';

// Grid
import { GridRunner } from 'rise-grid-framework';
import * as GridUtils from '../../risegrid/utils';

// Template
// import Playground from './Playground';

///////////////////////////////////////////////////////////
// Import Grid JSONs
import base from './grids/fastlaneBase';
import top from './grids/fastlaneTop';
import FPOAssetsVideo from '../../data/gridassets_video.js';
import { GridComposer, GRID_SNIPPETS } from '../../risegrid/GridComposer.js';
import Atrium from "../../../live/animations/atrium/atrium";


const TEST = require('../../risegrid/snippets/stackentry.snippet.json')

let holdFramesChapter = [
  // { frame: "ChapterStart", hold: 3000 },
  // { frame: "ChapterEnd", hold: 3000 },
];

let holdFramesIntro = [
  // { frame: 'IntroStart', hold: 0 },
]

///////////////////////////////////////////////////////////
// COMPOSE GRID

// TIME REMAPPING
// If needed, remap frame or wait timing (multiplies current by scale)
// sequence = GridUtils.timeRemap(sequence, .2);
// waitFrames = GridUtils.timeRemap(waitFrames, 0);

// let Grid = new GridComposer({
//   sequences: [TEST],
//   waitframes: []
// });

let Grid = new GridComposer({
  sequences: [base],
  // sequences: [intro, chapter],
  holdFrames: [holdFramesIntro, holdFramesChapter],
  inserts: [
    {
      at: "ONE",
      snippetId: GRID_SNIPPETS.FASTLANE_OVERLAY,
    }
  ]
});

// let Grid = new GridComposer({
//   sequences: [introAccent, main],
//   waitframes: [waitFramesIntro, waitFramesMain],
//   inserts: [{
//     at: "IntroStart",
//     snippetId: GRID_SNIPPETS.INTRO,
//     continueLastFrameTo: "IntroEnd"
//   },
//   {
//     at: "InsertTest",
//     snippetId: GRID_SNIPPETS.INTRO,
//   }]
// });

// Grid.insertSnippet({
//   at: "IntroStart",
//   snippetId: GRID_SNIPPETS.INTRO,
//   continueLastFrameTo: "IntroEnd"
// });

console.log('GC', Grid.sequence);

///////////////////////////////////////////////////////////
// Hyperlive JSON Data
// console.log('ANIMATIONS', window.animations)

// const { json_data } = Hyperlive.fetchJSON();

// console.log('JSON', json_data);

// const assets = [Hyperlive.mapAssets(
//   json_data.assets,
//   [
//     {
//       tag: 'background',
//       prefix: 'Intro'
//     },
//     {
//       tag: 'product',
//       prefix: 'Grid'
//     },
//     {
//       tag: 'logo',
//       prefix: 'Logo'
//     },
//   ])];

const assets = FPOAssetsVideo;

const grids = new GridRunner({
  grids: [1],
  assets: assets,
  sequence: Grid.sequence,
  waitFrames: Grid.wait,
  canvasWidth: 1300,
  canvasHeight: 225,
  gridWidth: 1300,
  gridHeight: 225,
  backgroundColor: 0xFFFFFF,
  lineColor: 100,
  lineThickness: 1,
  antialias: false
});

document.body.appendChild(grids.app.view);

(async () => {

  await grids.loadAssets();

  grids.run(true);

  console.log('RENDER')

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(<div />);

})();



