import React, {Component} from 'react';
import './Atrium.scss';
import {get, random} from 'lodash';

class Atrium extends Component {
	getStartTime = (data) =>  random(0, data.duration);

	render() {
		const levels = get(this.props.data, 'levels');
		const boxes = [...Array(4).keys()];

		return (
			<div className="Atrium-Container">
				<div className={'Atrium'}>
					{
						boxes.map(i => levels[i] ?? null).map((level = {assets: []}) => (
								<div className={'Atrium-Segment'}>
									{
										boxes.map(i => level?.assets[i] ?? {}).map((data) => {
											const start = this.getStartTime(data);

											return (
												<video
													autoPlay={true}
													muted={true}
													controls={false}
													loop={true}
												>
													<source src={`${data?.url}#t=${start}`} type="video/mp4"/>
												</video>
											)
										})
									}
								</div>
							)
						)
					}
				</div>
			</div>
		);
	}
}

export default Atrium;
