import React from 'react';

import IntroV1, {
    calculateDuration as v1CalculateDuration,
    calculateExitDuration as v1CalculateExitDuration,
    IntroV1Props,
} from './product/IntroV1';
import IntroV2, {
    calculateDuration as v2CalculateDuration,
    calculateExitDuration as v2CalculateExitDuration,
    IntroV2Props
} from './product/IntroV2';
import IntroV3, {
    calculateDuration as v3CalculateDuration,
    calculateExitDuration as v3CalculateExitDuration,
    IntroV3Props,
} from './product/IntroV3';


export type Props =
    ({variant: 'text-only'} & IntroV1Props)
    | ({variant: 'mosaic'} & IntroV2Props)
    | ({variant: 'fullscreen'} & IntroV3Props);


export default function ProductIntro(props: Props) {
    switch(props.variant) {
        case 'text-only':
            return (<IntroV1 {...props} />);

        case 'mosaic':
            return (<IntroV2 {...props} />);

        case 'fullscreen':
            return (<IntroV3 {...props} />);

        default:
            return null;
    }
}

export function calculateDuration(data: Props) {
    switch(data.variant) {
        case 'text-only':
            return v1CalculateDuration(data);

        case 'mosaic':
            return v2CalculateDuration(data);

        case 'fullscreen':
            return v3CalculateDuration(data);

        default:
            return 0;
    }
}

export function calculateExitDuration(data: Props) {
    switch(data.variant) {
        case 'text-only':
            return v1CalculateExitDuration(data);

        case 'mosaic':
            return v2CalculateExitDuration(data);

        case 'fullscreen':
            return v3CalculateExitDuration(data);

        default:
            return 0;
    }
}
