#pragma glslify: mosaicMask = require('../../../../lib/glsl/mosaic.glsl')
#pragma glslify: coverUV = require('../../../../lib/glsl/coverUV.glsl')
#pragma glslify: pixelateUV = require('../../../../lib/glsl/pixelateUV.glsl')
#pragma glslify: zoomedUV = require('../../../../lib/glsl/zoomedUV.glsl')
#pragma glslify: circle = require('../../../../lib/glsl/circle.glsl')
#pragma glslify: containUV = require('../../../../lib/glsl/containUV.glsl')
#pragma glslify: mosaicEffect = require('../../../../lib/glsl/mosaic.glsl')
#pragma glslify: mosaicMask = require('../../../../lib/glsl/mosaic.glsl')
#pragma glslify: gammaCorrect = require('../../../../lib/glsl/gammaCorrect.glsl')

varying vec2 vUv;

uniform float time;

// general stuffs
uniform vec2 res; // res
uniform float pixr; // pixel ratio
uniform float opacity;

uniform vec2 rangeFinder;
uniform float pixRF;
uniform float zoomRF;

uniform sampler2D img;
uniform vec2 imgRes;
uniform vec2 offset;
uniform float mosaic;
uniform float pixels;
uniform float zoom;
uniform sampler2D newTexture;

vec2 centeredOffset () {
  float ar = (res.x / res.y);
  return ((offset - 0.5) * vec2(1.0, (1.0 / ar))) + 0.5;
}

vec4 layer(vec4 foreground, vec4 background) {
    return foreground * foreground.a + background * (1.0 - foreground.a);
}

vec4 addRangeFinder (vec4 color) {
  float ar = (res.x / res.y);
  float iar = (imgRes.x / imgRes.y);
  float px = ((res.x * pixr) / (pixels + pixRF));

  vec2 uv = coverUV(vUv, res, imgRes);
  uv = zoomedUV(uv, imgRes, zoom * iar, centeredOffset());
  uv = pixelateUV(uv, px, ar);
  
  vec2 radiusRF = vec2(rangeFinder / res) * 0.5;
  vec2 cuv = ((vUv - 0.5) * vec2(1.0, (1.0 / ar))) + 0.5;
  float c = distance(cuv, vec2(0.5));
  c = step(c, radiusRF.x);
  uv = pixelateUV(uv, px, ar);

  // float m = mosaicEffect(uv, ar, time, mosaicAmount);
  // color = mix(color, vec4(mosaicColor, mosaicAlpha), m);
  return mix(color, texture2D(newTexture, uv, -10.0), c);
}

void main() {
vec4 background = vec4(1.0, 1.0, 1.0, 1.0); // transparent white

  float ar = (res.x / res.y);
  float iar = (imgRes.x / imgRes.y);
  float px = ((res.x * pixr) / pixels);

  vec2 uv = coverUV(vUv, res, imgRes);
  uv = zoomedUV(uv, imgRes, zoom * iar, centeredOffset());
  uv = pixelateUV(uv, px, ar);

  vec4 color = vec4(texture2D(img, uv, -10.0).rgba);
  vec4 newTexture = texture2D(newTexture, vUv, -10.0);
  color = addRangeFinder(color);
  gl_FragColor = vec4(gammaCorrect(color.rgb,2.2),color.a);
}