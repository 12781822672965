import { GRADIENTS } from '../../../../constants/hub';

export const LEVEL_ROOT_VARIANTS = {
	expand: ({ maxHeight }) => ({
		height: `${maxHeight}px`,
	}),
	shrink: ({ heightRatio }) => ({
		height: `${heightRatio * 100}%`,
	}),
};

export const LEVEL_INNER_VARIANTS = {
	expand: {
		background: GRADIENTS.orange,
	},
	shrink: ({ dark }) => ({
		background: dark ? GRADIENTS.black : GRADIENTS.white,
	}),
};
