import { videosUrlService } from "../../../utils/assets-service";

export default {
    "hub_directory_kr": {
        "url": videosUrlService.getUrl('enjoy_x_sporthub_a_directory.mp4')
    },
    "hub_experience_kr": {
        "url": videosUrlService.getUrl('enjoy_x_sporthub_c_experiences.mp4')
    },
    "hub_services_kr": {
        "url": videosUrlService.getUrl('enjoy_x_sporthub_b_services.mp4')
    },
    "fastlanes_women_kr": {
        "url": null
    },
    "fastlanes_men_kr": {
        "url": null
    },
    "fastlanes_ticker_women_kr": {
        "url": null
    },
    "fastlanes_ticker_men_kr": {
        "url": null
    },
    "pods_women_kr": {
        "url": videosUrlService.getUrl('enjoy_x_pods_V5.mp4')
    },
    "pods_men_kr": {
        "url": videosUrlService.getUrl('spj.mp4')
    },
    "cityreplay_services_kr": {
        "url": videosUrlService.getUrl('_enjoy_x_spillt_cityreplay_screen_2_steps_stringout_HD_updated.mp4')
    },
    "cityreplay_attract_kr": {
        "url": videosUrlService.getUrl('_enjoy_x_spillt_cityreplay_screen_1_sizzle_stringout_HD.mp4')
    },
    "cityreplay_repair_kr": {
        "url": videosUrlService.getUrl('_enjoy_x_spillt_cityreplay_screen_3_repair_stringout_HD_updated.mp4')
    },
    "mtz_kr": {
        "url": videosUrlService.getUrl('enjoy_x_hub_storytelling_v3.mp4')
    },
    "huddle_facets_kr": {
        "url": videosUrlService.getUrl('enjoy_x_huddle_chap2.mp4')
    }
}
