import React, { useEffect, useState } from 'react';
import * as Hyperlive from '../../hyperlive/utils.js';
import './styles/Huddle.scss';
import AppLogo from './components/AppLogo/AppLogo';
import Headline from './components/Headline/Headline';
import FeatureCard from './components/FeatureCard/FeatureCard';
import assets from './assets/assets.js';
import GridCard from '../../components/GridCard/GridCard';
import * as Logger from '../../utils/console';
import * as GridUtils from '../../risegrid/utils';
import {
	constructPulseDataStory,
	getPulseDataStory,
} from '../../utils/pulseData.js';
import PodDiD from './components/PodDiD/PodDiD.js';

// TEMPLATE CONFIG
const CONFIG = {
	chapterStart: 'ChapterStart',
	chapterEnd: 'ChapterEnd',
	layouts: {
		intro: {
			appMask: 'AppRight',
			qrMask: 'AppLeftBottom',
		},

		'story.1': {
			appMask: 'AppLeftBottom',
			qrMask: 'AppRight',
		},

		'story.2': {
			appMask: 'AppRightBottom',
			qrMask: 'AppLeft',
		},

		'story.3': {
			appMask: 'AppLeftBottom',
			qrMask: 'AppRight',
		},
	},
};

const Huddle = ({ grids, chapter_data, json_data }) => {
	Logger.alert('* HUDDLE');

	const {
		title,
		pulse_data,
		fallback_pulse_data,
		pulse_data_story,
	} = json_data;

	const [lang, setLang] = useState('KO');
	const [frame, setFrame] = useState({});
	const [chapter, setChapter] = useState(0);
	const [layout, setLayout] = useState(CONFIG.layouts.intro);

	// const [intro, setIntro] = useState(false);
	// const [frameStatus, setFrameStatus] = useState(false);

	//TODO
	//REPLACE WITH CMS DATA
	const headline = { EN: 'Nike Training\nClub', KO: 'Nike Training\nClub' };
	const logo = assets[0].filter(asset => {
		return asset.name === 'Logo';
	})[0];
	const qr = assets[0].filter(asset => {
		return asset.name === 'qr';
	})[0];

	// FRAME LISTENER

	useEffect(() => {
		// SETUP GRID FRAME CALLBACK
		Logger.highlight('* HUDDLE: COMPONENT START');

		grids.setCallback(0, props => {
			const {
				name,
				frameIndex,
				loop,
				language,
				start: frameStart,
			} = props;
			const {
				frameName,
				frameId,
				frameType,
				chapterId,
			} = GridUtils.keyframeInfo(name);

			setLang(Hyperlive.getLang(language));
			setFrame({
				frameIndex,
				frameName,
				frameId,
				frameStart,
				chapterId,
				frameType,
			});
			setChapter(chapterId);

			Logger.alert(
				`# FRAME ${name} (${frameName}, ${frameType}, ${frameId}) — ${
					frameStart ? 'Start' : 'End'
				}`,
			);
			Logger.highlight(`* ${chapterId}`);
			Logger.log('*', chapter_data[chapterId]);

			// RESET LOOP
			if (frameIndex === 0) {
				// setIntro(true);
				setLayout(CONFIG.layouts.intro);
			}

			// PER CHAPTER SETTINGS
			switch (frameType) {
				case Hyperlive.ChapterTypes.Intro:
					setLayout(CONFIG.layouts.intro);
					break;

				case Hyperlive.ChapterTypes.Story:
					// setIntro(false);
					if (frameStart) {
						setLayout(CONFIG.layouts[chapterId]);
					}
					break;

				case Hyperlive.ChapterTypes.Cover:
					// setIntro(false);
					break;
			}
		});
	}, []);

	Logger.highlight(`# ${frame.frameType} — ${frame.frameId}`);

	// CUSTOMIZE LAYOUT
	const appMask = layout.appMask;
	const qrMask = layout.qrMask;
	let headline_text = '';
	let DID = '';
	let feature_card = null;

	switch (frame.frameType) {
		case Hyperlive.ChapterTypes.Cover:
			Logger.highlight('* » Cover');
			Logger.log(chapter_data, chapter);
			if (chapter_data.hasOwnProperty(chapter)) {
				headline_text = chapter_data[chapter].hasOwnProperty('heading')
					? chapter_data[chapter].heading[lang]
					: ''; //'Nike\nTraining\nClub';
			}
			// headline_text = chapter_data[chapter].hasOwnProperty('heading') ? chapter_data[chapter].heading[lang] : ''; //'Nike\nTraining\nClub';
			break;
		case Hyperlive.ChapterTypes.Story:
			if (chapter_data[chapter]) {
				Logger.highlight('* » Story');
				Logger.log('*', chapter_data[chapter]);
				headline_text = chapter_data[chapter].hasOwnProperty('heading')
					? chapter_data[chapter].heading[lang]
					: '';

				// if (chapter === "story.2") {
				//   const p = getPulseDataStory(pulse_data, fallback_pulse_data, lang);
				//   headline_text = p ? p : headline_text;
				// }

				if (chapter === 'story.2') {
					const p = constructPulseDataStory(pulse_data_story, lang);
					DID = p ? p : headline_text;
					headline_text = '';
				}

				if (chapter_data[chapter].hasOwnProperty('ntc')) {
					feature_card = {
						heading: chapter_data[chapter].ntc.title[lang],
						image: chapter_data[chapter].ntc.media[lang].url,
					};
					// feature_card = {
					//   "heading": chapter_data[chapter].subheading[lang],
					//   "image": chapter_data[chapter].media.secondary[0].url
					// }
				}
			}
			break;
	}

	return (
		<div className={'Huddle'}>
			<React.Fragment>
				<GridCard
					grids={grids}
					frame={frame}
					mask={'IntroHeadline'}
					autoReveal={'Left'}
					fade={false}
					keyframes={['IntroTransition', 'IntroStart']}
					startframes={['IntroTransition']}
				>
					<Headline text={headline_text} />
				</GridCard>

				{/* <GridCard grids={grids} frame={frame} mask={'TextLower'} autoReveal={'Left'} fade={false} keyframes={['ChapterStart', 'ChapterEnd']} startframes={['ChapterStart']}> */}
				<GridCard
					grids={grids}
					frame={frame}
					mask={'IntroHeadline'}
					autoReveal={'Left'}
					fade={false}
					keyframes={['ChapterStart', 'ChapterEnd']}
					startframes={['ChapterStart']}
				>
					<Headline size={'secondary'} text={headline_text} />
				</GridCard>

				<GridCard
					grids={grids}
					frame={frame}
					mask={appMask}
					autoReveal={false}
					fade={false}
					keyframes={[
						'IntroTransition',
						'IntroStart',
						'ChapterStart',
						'ChapterEnd',
					]}
					startframes={['IntroTransition', 'ChapterStart']}
				>
					<AppLogo logo={logo} app={'NTC'} />
				</GridCard>

				<GridCard
					grids={grids}
					frame={frame}
					mask={qrMask}
					autoReveal={false}
					fade={false}
					keyframes={['ChapterStart', 'ChapterEnd']}
					startframes={['ChapterStart']}
					delay={0.5}
				>
					<AppLogo logo={logo} qr={true} app={'NTC'} />
				</GridCard>

				{/* {frame.frameType === 'story' && */}
				<GridCard
					grids={grids}
					frame={frame}
					mask={'Feature'}
					autoReveal={false}
					fade={false}
					keyframes={['ChapterStart']}
					startframes={['ChapterStart']}
					delay={1}
				>
					<FeatureCard card={feature_card} />
				</GridCard>
				{/* } */}

				<PodDiD text={DID} chapter={chapter}></PodDiD>
			</React.Fragment>
		</div>
	);
};

export default Huddle;
