import gsap from 'gsap';

export default function Css3DRotation (content) {
    const timeline = content.timeline
    content.logos.forEach(logo => {
        logo.style.opacity = 0;
        // logo.style.perspective = "800px";
        // logo.style.perspectiveOrigin = "center";

        timeline.set(logo, {
            transform: 'perspective(35vw)',
            backfaceVisibility: 'visible',
            rotationY:-90,
        }, '-=0.6')

        timeline.to(logo, {opacity: 1, duration: 0.1});
        timeline.to(logo, { rotationY:90, duration: 3.5, ease:"Sine.easeInOut"});
        timeline.set(logo, {opacity: 0});
    });
}
    