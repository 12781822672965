import { imagesUrlService } from "../../../../../utils/assets-service";

export default {
  "en": {
    "title": "Nike\nExperiences",
    "subtitle": "Ask a Store Athlete or sign up for Nike Experiences through Naver.",
    "blocks": [
      {
        "title": "Nike Seoul Run Club",
        "description": "Lorem ipsum dolor sit amed, consectetur adipiscing esed eiusmod tempor incididunt.",
        "image": imagesUrlService.getUrl('runclub.jpg', { chainedTransformations: [ 'w_950,h_580' ] })
      },
      {
        "title": "Nike Coaching Session",
        "description": "Lorem ipsum dolor sit amed, consectetur adipiscing esed eiusmod tempor incididunt.",
        "image": imagesUrlService.getUrl('expert-sessions.jpg', { chainedTransformations: [ 'w_950,h_580' ] })
      },
      {
        "title": "1on1 Styling",
        "description": "Lorem ipsum dolor sit amed, consectetur adipiscing esed eiusmod tempor incididunt.",
        "image": imagesUrlService.getUrl('1on1.jpg', { chainedTransformations: [ 'w_950,h_580' ] })
      },
      {
        "title": "Trash Lab",
        "description": "Make new products using waste materials and join us in Nike's journey to protect the future of sports.",
        "image": imagesUrlService.getUrl('trash-lab.jpg', { chainedTransformations: [ 'w_950,h_580' ] })
      }
    ]
  },
  "ko": {
    "title": "나이키 익스피리언스",
    "subtitle": "매장 선수에게 문의하거나 네이버를 통해 나이키 익스피리언스에 가입하세요.",
    "blocks": [
      {
        "title": "나이키 서울 런 클럽",
        "description": "전문 코치의 가이드를 받으며 러닝을 기록하고, 크루들과 함께서울의 여러 명소를 달려보세요.",
        "image": imagesUrlService.getUrl('runclub.jpg', { chainedTransformations: [ 'w_950,h_580' ] })
      },
      {
        "title": "나이키 코칭 세션",
        "description": "스토어 및 온라인에서 건강, 회복, 영양 등 웰니스(Wellness) 전반에 대한 전문가의 가이드를 받아보세요.",
        "image": imagesUrlService.getUrl('expert-sessions.jpg', { chainedTransformations: [ 'w_950,h_580' ] })
      },
      {
        "title": "1on1 스타일링",
        "description": "나이키 스페셜리스트가 고객에 맞는 다양한 제품과 스타일을추천 해 드립니다",
        "image": imagesUrlService.getUrl('1on1.jpg', { chainedTransformations: [ 'w_950,h_580' ] })
      },
      {
        "title": "트래쉬랩",
        "description": "폐기물을 사용하여 새로운 제품을 만들고 스포츠의 미래를 보호하기 위한 나이키의 여정에 함께 하십시오.",
        "image": imagesUrlService.getUrl('trash-lab.jpg', { chainedTransformations: [ 'w_950,h_580' ] })
      }
    ]
  }
}
