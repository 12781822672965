import {
	DATA_BASE_URL,
	ANALYTICS_BASE_URL,
	IS_ANALYTICS_ENABLED,
	ENABLED_DISPLAYS_LOCAL_STORAGE_CACHE
} from '../../config';
import { cachingWrapper, request } from "../../api";

const BASE_URL = `${DATA_BASE_URL}api/public/broadcast-schedule`;
export const HEARTBEAT_URL = generateHeartbeatUrl();

function generateHeartbeatUrl() {
	if (!IS_ANALYTICS_ENABLED || !ANALYTICS_BASE_URL) {
		return `${DATA_BASE_URL}api/public/rise/heartbeats`;
	}

	return `${ANALYTICS_BASE_URL}api/public/heartbeats`
}

export const getChannel = async (channelSlug) => {
	const getChannelCallback = async () => await request({
		baseURL: BASE_URL,
		url: `channel/${channelSlug}`
	});

	return cachingWrapper({
		requestCallback: getChannelCallback,
		storageKey: `channel-${channelSlug}`
	});
};

export const getStore = async storeId => {
	const storeLoadingKey = `store-${storeId}`;

	try {
		const response = await fetch(`${DATA_BASE_URL}api/public/rise/stores/`);
		const stores = await response.json();

		if (!stores || !Array.isArray(stores) || stores.length === 0) {
			localStorage.removeItem(storeLoadingKey);
			return null;
		}

		const foundStore = stores.find(({id}) => id === storeId);

		if (foundStore && ENABLED_DISPLAYS_LOCAL_STORAGE_CACHE) {
			localStorage.setItem(storeLoadingKey, JSON.stringify(foundStore));
		}

		return foundStore;
	} catch (error) {
		console.warn(error);
		const cachedStore = JSON.parse(localStorage.getItem(storeLoadingKey));
		return cachedStore ?? null;
	}
};


