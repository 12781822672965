{
  "en": {
    "title": "Nike\nAt\nYour\nService",
    "subtitle": "Your one-stop shop for services at Nike.",
    "blocks": [
      {
        "title": "Order Pick Up // Returns & Exchanges",
        "description": "Pick up, return and exchange here, including items you bought online.",
        "icons": []
      },
      {
        "title": "Expert Sessions",
        "description": "Book a personal shopping appointment with one of our Nike Experts. Talk to a store athlete to get started.",
        "icons": []
      },
      {
        "title": "Nike Experiences",
        "description": "Sport is better together. Join us for special Member experiences in your city and online.",
        "icons": []
      },
      {
        "title": "Bra Fit",
        "description": "Fit is everything. Get measured by a store athlete for personalized bra recommendations tailored to your activity.",
        "icons": []
      },
      {
        "title": "Nike Recycling & Donation",
        "description": "Instead of throwing it away, leave it with us. We'll clean and donate or recycle your used athletic gear.",
        "icons": []
      },
      {
        "title": "Nike Repair",
        "description": "Join us in protecting our environment and the future by increasing the life span of our products and reducing the waste we emit.",
        "icons": []
      }
    ]
  },
  "ko": {
    "title": "나이키 서비스 허브",
    "subtitle": "나이키가 제공하는 원스탑 쇼핑 서비스",
    "blocks": [
      {
        "title": "주문 픽업 // 교환 및 환불",
        "description": "매장 혹은 온라인에서 주문한 제품을 픽업하거나 교환, 환불할 수 있습니다.",
        "icons": []
      },
      {
        "title": "엑스퍼트 세션",
        "description": "나이키의 전문 스토어 애슬릿과 함께하는 퍼스널 쇼핑을 예약하세요. 자세한 내용은 가까운 직원에게 문의해 주세요.",
        "icons": []
      },
      {
        "title": "나이키 익스피리언스",
        "description": "스포츠는 함께하면 좋습니다. 귀하의 도시와 온라인에서 특별한 회원 경험을 위해 저희와 함께 하십시오.",
        "icons": []
      },
      {
        "title": "나이키 브라 핏 서비스",
        "description": "나에게 꼭 맞는 제품을 찾아보세요. 스토어 애슬릿의 도움을 받아 브라 사이즈를 측정하고, 나의 운동 성향에 최적화된 맞춤형 추천을 받아보세요",
        "icons": []
      },
      {
        "title": "나이키 리사이클링 & 기부",
        "description": "버리지 말고 저희에게 주세요. 나이키는 헌 제품을 모아 깨끗하게 세척하여 기부 또는 재활용 하겠습니다.",
        "icons": []
      },
      {
        "title": "나이키 리페어",
        "description": "수선을 통해 제품의 수명을 늘리고 폐기물의 양을 줄여 환경과 미래를 보호하려는 나이키의 노력에 동참해 주세요. ",
        "icons": []
      }
    ]
  }
}
