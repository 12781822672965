// React
import React from 'react';
import ReactDOM from 'react-dom/client';

// Hyperlive Utils
import * as Hyperlive from '../../hyperlive/utils.js';

// Globals
import '../../../../init';
import '../../styles/main.scss';

// Grid
import { GridRunner } from 'rise-grid-framework';
import * as GridUtils from '../../risegrid/utils';

// Template
import FastlaneTicker from './FastlaneTicker.js';
import { onReady } from '../../../../init';


///////////////////////////////////////////////////////////
// Hyperlive JSON Data
// console.log('ANIMATIONS', window.animations);

const { json_data } = Hyperlive.fetchJSON();

console.log('JSON', json_data);

///////////////////////////////////////////////////////////
console.log('RENDER');
(async () => {

	console.log('not ready...')
	await onReady();

	console.log('ready...')


	const root = ReactDOM.createRoot(document.getElementById('root'));
	root.render(<FastlaneTicker json_data={json_data} />);
})();
