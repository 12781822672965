if (module.hot) {
  module.hot.accept(() => {
    location.reload()
  })
}

import { Pane } from 'tweakpane';
import { debounce } from 'lodash';

import Lowvc from './chapters/lowvc/lowvc-app';
import ContactSheet from './chapters/contact-sheet/contact-sheet-app';
import PixelCutOut from './chapters/pixel-cutout/pixel-cutout-app';
import Type from './chapters/type/type-app';
import GriddedImagery from './chapters/gridded-imagery/gridded-imagery-app';
import Viewfinder from './chapters/viewfinder/viewfinder-app';
import Mannequin from './chapters/mannequin/mannequin-app';

export default function (datas, demo=false) {
  console.log(datas);

  const apps = [];
  let appIndex = 0;
  let running = false;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const showGUI = urlParams.get('showGUI');
  const noAutoStart = urlParams.get('noAutoStart');
  const chapter = urlParams.get('chapter');

  window.SIZE_ID = (Math.min(window.innerWidth, window.innerHeight) > 719)
    ? 'LARGE'
    : (Math.min(window.innerWidth, window.innerHeight) > 479)
      ? 'MEDIUM'
      : 'SMALL'

  window.ORIENTATION = (window.innerHeight > window.innerWidth)
    ? 'PORTRAIT'
    : 'LANDSCAPE'

  const activeApps = {
    'contact-sheet': !chapter || (chapter === 'contact-sheet'),
    'pixel-cutout': !chapter || (chapter === 'pixel-cutout'),
    'mannequin': !chapter || (chapter === 'mannequin'),
    'viewfinder': !chapter || (chapter === 'viewfinder'),
    'gridded-imagery': !chapter || (chapter === 'gridded-imagery'),
    'type': !chapter || (chapter === 'type'),
    'low-vc': !chapter || (chapter === 'low-vc'),
  };
  const gui = new Pane({
    title: 'LLS Coverstory',
    container: document.querySelector('.LLS-gui')
  });
  gui.hidden = !showGUI;

  gui.addButton({ title: 'start', label: 'start (shift + space)' }).on('click', (e) => {
    appIndex = 0;
    apps[appIndex].start();
    if (apps[appIndex].gui) {
      apps[appIndex].gui.hidden = !showGUI;
    }
  });
  gui.addButton({ title: 'play', label: 'play (space)' }).on('click', (e) => {
    apps[appIndex].resume();
  });
  gui.addButton({ title: 'pause', label: 'pause (space)' }).on('click', (e) => {
    apps[appIndex].pause();
  });

  const guiF1 = gui.addFolder({
    title: 'chapters list',
    expanded: demo
  });

  function addApp (name, app) {
    activeApps[name] && apps.push(app);
    guiF1.addInput(activeApps, name).on('change', (e) => {
      if (e.value) {
        apps.push(app);
      } else {
        const index = apps.indexOf(app);
        if (index !== -1) {
          apps.splice(index, 1);
        }
      }
    });
  }

  function nextApp () {
    if (apps[appIndex]) {
      apps[appIndex].wrapper.classList.remove('active');
      apps[appIndex].stop();
    }
    appIndex = ((appIndex + 1) % apps.length);
    apps[appIndex].wrapper.classList.add('active');
    apps[appIndex].start();
  }

  if (datas.type === 'full') {
    if (!datas.chapters.type?.disabled && (datas.chapters.type?.assets?.length || datas.chapters.type?.scc)) {
      const type = new Type('TYPE', datas.chapters.type, nextApp, gui);
      addApp('type', type);
    }

    if (!datas.chapters.grid?.disabled && (datas.chapters.grid?.keyframe_1?.assets?.length || datas.chapters.grid?.keyframe_2?.assets?.length || datas.chapters.grid?.keyframe_3?.assets?.length)) {
      if (window.ORIENTATION === 'LANDSCAPE') {
        const griddedImagery = new GriddedImagery('GRIDDED-IMAGERY', datas.chapters.grid, nextApp, gui);
        addApp('gridded-imagery', griddedImagery);
      }
    }

    if (!datas.chapters.view_finder?.disabled && (datas.chapters.view_finder?.assets?.length || datas.chapters.view_finder?.scc)) {
      const viewfinder = new Viewfinder('VIEWFINDER', datas.chapters.view_finder, nextApp, gui);
      addApp('viewfinder', viewfinder);
    }

    if (!datas.chapters.mannequin?.disabled && (datas.chapters.mannequin?.assets?.length || datas.chapters.mannequin?.scc)) {
      const mannequin = new Mannequin('MANNEQUIN', datas.chapters.mannequin, nextApp, gui);
      addApp('mannequin', mannequin);
    }

    if (!datas.chapters.pixel_cutout?.disabled && (datas.chapters.pixel_cutout?.background?.assets?.length || datas.chapters.pixel_cutout?.primary?.assets?.length)) {
      const pixelCutOut = new PixelCutOut('PIXEL-CUTOUT', datas.chapters.pixel_cutout, nextApp, gui);
      addApp('pixel-cutout', pixelCutOut);
    }

    if (!datas.chapters.contact?.disabled && (datas.chapters.contact?.assets?.length || datas.chapters.contact?.scc)) {
      if (window.ORIENTATION === 'LANDSCAPE') {
        const contactSheet = new ContactSheet('CONTACT-SHEET', datas.chapters.contact, nextApp, gui);
        addApp('contact-sheet', contactSheet);
      }
    }

    if (!datas.chapters.video?.disabled && (datas.chapters.video?.assets?.length) && datas.chapters.video?.assets?.filter(i => i)) {
      const lowvc = new Lowvc('LOW-VC', datas.chapters.video, nextApp, gui, 'full');
      addApp('low-vc', lowvc);
    }
  } else if (datas.type ==='low') {
    if (!datas.chapters.low?.disabled && (datas.chapters.low?.assets?.length || datas.chapters.low?.scc)) {
      const lowvc = new Lowvc('LOW-VC', datas.chapters.low, nextApp, gui);
      addApp('low-vc', lowvc);
    }
  }



  window.addEventListener('keypress', (e) => {
    if (e.code === 'Space') {
      if (e.shiftKey) {
        if (apps[appIndex].running) {
          apps[appIndex].stop();
        }
        appIndex = 0;
        if (apps[appIndex].gui) {
          apps[appIndex].gui.hidden = !apps[appIndex].gui.hidden;
        }
        apps[appIndex].start();
      } else {
        if (apps[appIndex].running) {
          apps[appIndex].pause();
        } else {
          apps[appIndex].resume();
        }
      }
    } else if (e.code === 'KeyH') {
      gui.hidden = !gui.hidden;
      if (apps[appIndex].gui) {
        apps[appIndex].gui.hidden = !apps[appIndex].gui.hidden;
      }
    }
  }, false);

  function resetApps () {
    location.reload();
  }

  window.addEventListener('resize', debounce(resetApps, 500, { trailing: true, leading: false }), false);

  window.dispatchEvent(new CustomEvent('animation_ready'));
  if (!noAutoStart) apps[appIndex]?.start();
};
