import React, {useMemo} from "react";
import {motion} from "framer-motion";
import {containsChinese, containsKorean} from "../../../../../../utilities";

export default function FloorNumber({wide, title, active, dark}) {
  const isChinese = useMemo(() => {
    return containsChinese(title);
  }, [title]);

  return (
    <motion.div
      className={`c-level-block__number u-font-f-primary ${(wide) && 'wide'} ${isChinese && 'chinese'}`}
      animate={{
        borderRightColor: dark
          ? active ? 'rgba(255, 255, 255, 0)' : 'rgba(255, 255, 255, 0.8)'
          : active ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0.8)',
      }}
    >
      {title}
    </motion.div>
  )
}