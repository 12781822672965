import {create} from "zustand";
import {AnimationDataType, EntryType} from "./types";
import {wait} from "./shared";

export interface MembershipState {
    activeEntryIndex: number | null;
    animationData: AnimationDataType | null;
    getEntry: (index: number) => EntryType | null;
    getCurrentEntry: () => EntryType | null;
    activeLanguage: string;
    isTitleSectionActive: () => boolean;

    initialize: (animationData: AnimationDataType) => void;
    start: () => Promise<void>;
    _restart: () => Promise<void>;
    _playNextEntry: () => Promise<void>;
}

export const ENTRY_DURATION = 5000;

export const useMembership = create<MembershipState>()((set, get) => ({
    activeEntryIndex: null,
    animationData: null,
    activeLanguage: 'en',

    isTitleSectionActive: () => {
        const {activeEntryIndex} = get();
        return activeEntryIndex === null;
    },

    getEntry: (index: number) => {
        const {animationData} = get();
        return animationData?.entries?.[index] ?? null;
    },
    getCurrentEntry: () => {
        const {activeEntryIndex} = get();
        return get().getEntry(activeEntryIndex ?? 0);
    },

    initialize: (animationData: AnimationDataType) => {
        set({animationData});
    },
    start: async () => {
        await get()._restart();
    },
    _restart: async () => {
        set({activeEntryIndex: null});
        await wait(ENTRY_DURATION);
        // get entries
        const entries = get().animationData?.entries ?? [];

        for (const entry of entries) {
            // play entry
            await get()._playNextEntry();
        }

        // and loop over them but respect the duration
        await get()._restart();
    },

    _playNextEntry: async () => {
        const {activeEntryIndex} = get();
        const nextIndex = activeEntryIndex === null ? 0 : activeEntryIndex + 1;
        const duration = get().getEntry(nextIndex)?.duration ?? ENTRY_DURATION;
        set(state => ({activeEntryIndex: nextIndex}));

        return await new Promise(resolve => setTimeout(resolve, duration));
    }
}));