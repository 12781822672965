{
  "languages": ["KO", "EN"],
  "segments": [
    {
      "type": "swoosh"
    },
    {
      "type": "text",
      "content": {
        "EN": "Nike",
        "KO": "생방송"
      }
    }
  ]
}
