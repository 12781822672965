import ReactDOM from 'react-dom/client';
import App from './app';
import { fetchJSON } from '/src/touchpoints/rise/hyperlive/utils';
import {channelConfig} from "../../shared";
const {json_data} = fetchJSON();

const params = new URLSearchParams(window.location.search)
const chapter = params.get('chapter');
const openChapter = ["", null, undefined].includes(chapter) ? null : chapter;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App config={channelConfig()} data={json_data} openChapter={openChapter} />);