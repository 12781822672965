import React, { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import {
    DEFAULT_EASE,
    getLangText,
    MultiLangText,
    ThemeType,
    secToMs,
    setAnimationTimeout,
} from "../shared";
import { DEFAULT_DURATION_S } from "../constants/transition";
import { BaseChapterProps } from "./ChapterRenderer";
import Grid from './Grid';
import CodificationWithTranslations from "./Codification/CodificationWithTranslations";
import TitleText from "./TitleText";

import '../styles/screens/title-card.scss';

export type TitleWithSubtitleProps = BaseChapterProps & {
    title: MultiLangText;
    subtitle: MultiLangText;
    theme?: ThemeType;
}

const gridLineThickness = 1;
const cellWidth = 216;
const cellHeight = 216;

const gridDuration = DEFAULT_DURATION_S;
const codificationCharDuration = 0.03;
const subtitleCodificationDuration = 0.015;
const characterNextTrigger = 2;
const languageTransitionDelay = 0.25;
const delayClearGridForTitle = 2 * DEFAULT_DURATION_S;
const delaySubtitleShow = delayClearGridForTitle + 2 * DEFAULT_DURATION_S;
const delaySubtitleType = DEFAULT_DURATION_S / 2;
const pause = 6;

export default function TitleWithSubtitle(props: TitleWithSubtitleProps) {
    const {
        width,
        height,
        title,
        subtitle,
        languages,
        theme,
    } = props;

    const cols = Math.ceil(width / cellWidth);
    const rows = Math.ceil(height / cellHeight);

    const subtitleContainerRef = useRef<HTMLDivElement>();
    const [toShowTitle, setToShowTitle] = useState(false);
    const [showSubtitle, setShowSubtitle] = useState(false);
    const [subtitleMaxSize, setSubtitleMaxSize] = useState(null);
    const [langIndex, setLangIndex] = useState(0);
    const [nextLangIndex, setNextLangIndex] = useState(0);
    const subtitlePadding = subtitleContainerRef.current ? parseFloat(window.getComputedStyle(subtitleContainerRef.current).paddingTop) : 0;
    const subTitleBoundingRect = useMemo(() => ({
        x: 0,
        y: 5,
        rows: subtitleMaxSize ? Math.min(Math.ceil((subtitleMaxSize.height + subtitlePadding * 2) / cellHeight), rows) : 1,
        cols,
    }), [subtitleMaxSize]);

    const subTitleComponent = useMemo(() => (
        <CodificationWithTranslations
            text={subtitle}
            languages={languages}
            langIndex={langIndex}
            className='sub-title-text'
            codificationProps={{
                characterSwitchAmount: 4,
                characterNextTrigger,
                timingConfig: {
                    control: 'character',
                    duration: subtitleCodificationDuration,
                },
            }}
            delay={delaySubtitleType}
            runFinalTextAnimation={false}
            languageTransitionDelay={languageTransitionDelay}
            onLanguageContainerMeassuerd={sizes => {
                let maxWidth = 0;
                let maxHeight = 0;

                sizes.forEach(({width, height}) => {
                    maxWidth = Math.max(maxWidth, width);
                    maxHeight = Math.max(maxHeight, height);
                });

                setSubtitleMaxSize({
                    width: maxWidth,
                    height: maxHeight,
                });
            }}
        />
    ), [title, languages, langIndex]);

    const halfGridLineThickness = gridLineThickness / 2;
    const generalStyle: CSSProperties = {
        marginTop: -halfGridLineThickness,
        marginLeft: -halfGridLineThickness,
        borderWidth: gridLineThickness,
    };
    const subTitleStyle = subtitleMaxSize ? {
        ...generalStyle,
        top: subTitleBoundingRect.y * cellHeight,
        height: subTitleBoundingRect.rows * cellHeight + gridLineThickness,
        width: subTitleBoundingRect.cols * cellWidth
    } : undefined;

    useEffect(() => {
        const cancelShowTitle = setAnimationTimeout(() => setToShowTitle(true), secToMs(gridDuration));
        const cancelShowSubTitle = setAnimationTimeout(() => {
            setShowSubtitle(languages.findIndex(lang => subtitle[lang]?.length > 0) >= 0);
        }, secToMs(delaySubtitleShow));

        return () => {
            cancelShowTitle();
            cancelShowSubTitle();
        }
    }, []);

    useEffect(() => {
        if (nextLangIndex === langIndex) {
            return;
        }

        return setAnimationTimeout(() => {
            setLangIndex(nextLangIndex);
        }, secToMs(pause));
    }, [nextLangIndex]);

    return (
        <>
            <Grid
                width={cellWidth * cols}
                height={cellHeight * rows}
                cols={cols}
                rows={rows}
                initialAnimation={'hidden'}
                animation={'in'}
                style={{
                    position: 'absolute'
                }}
                maxDuration={gridDuration}
            />
            <AnimatePresence>
                {toShowTitle ? (
                    <TitleText
                        text={title}
                        languages={languages}
                        theme={theme}
                        langIndex={langIndex}
                        cellLength={cellWidth}
                        cols={cols}
                        rows={rows}
                        delay={gridDuration}
                        gridLineThickness={gridLineThickness}
                        exit={{
                            clipPath: 'inset(0% 0% 100% 0%)',
                        }}
                        onTyped={() => {
                            setNextLangIndex(i => {
                                return (i >= languages.length - 1) ? i : (i + 1);
                            });
                        }}
                    />
                ) : null}
            </AnimatePresence>

            {showSubtitle ? (
                <motion.div
                    className='sub-title'
                    style={subTitleStyle}
                    initial={{
                        clipPath: 'inset(0% 0% 0% 0%)'
                    }}
                    exit={{
                        clipPath: 'inset(0% 100% 0% 0%)'
                    }}
                    transition={{
                        duration: DEFAULT_DURATION_S,
                        ease: DEFAULT_EASE,
                    }}
                >
                    {subtitleMaxSize ? (
                        <motion.div
                            className='sub-title-background'
                            initial={{
                                clipPath: 'inset(0% 100% 0% 0%)'
                            }}
                            animate={{
                                clipPath: 'inset(0% 0% 0% 0%)'
                            }}
                            transition={{
                                duration: DEFAULT_DURATION_S,
                                ease: DEFAULT_EASE,
                            }}
                        >
                        </motion.div>
                    ) : null}
                    <div
                        ref={subtitleContainerRef}
                        className='sub-title-container'
                    >
                        <div style={{
                            width: subtitleMaxSize?.width,
                            height: subtitleMaxSize?.height
                        }}>
                            <AnimatePresence>
                                {subTitleComponent}
                            </AnimatePresence>
                        </div>
                    </div>
                </motion.div>
            ) : null}
        </>
    );
}

export function calculateDuration(data: TitleWithSubtitleProps) {
    const {
        languages
    } = data;

    const entryDuration = gridDuration * 2;

    const maxTitle = languages.reduce((result, lang) => Math.max(result, getLangText(data.title, lang).length), 0);
    const oneLangTitleDuration = maxTitle * codificationCharDuration * characterNextTrigger + languageTransitionDelay;
    // extra 0.5s is to compensate the uneven framerate for cidification
    const textAnimation = (oneLangTitleDuration + pause + 0.5) * languages.length;

    const total = entryDuration + textAnimation;

    const exitDuration = calculateExitDuration(data);

    return secToMs(total) + exitDuration;
}

export function calculateExitDuration(data: TitleWithSubtitleProps) {
    const duration = 2 * DEFAULT_DURATION_S + gridDuration;

    return secToMs(duration);
}
