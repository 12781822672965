import { head } from 'lodash';
import './mixedTitle.scss'
import { SyllableSeparation } from './../../utils.js';
const fontsOrder = ['futura', 'helvetica', 'neue-pixel-grotesk'];

export default function mixedTitle (content) {
  const div = document.createElement('div');
  div.classList.add('mixed-title');
  div.classList.add('fit-title');
  const fitwrapper = document.createElement('div');
  fitwrapper.classList.add('mixed-title-wrapper')


  const calculateFontSize = (text, maxFontSize, minFontSize, maxLineLength) => {
    const lerp = (x, y, a) => x * (1 - a) + y * a;
    const textArray = text.split('\n');
    const longest = textArray.reduce((a, b) => a.length < b.length ? b : a, "");
    return lerp(maxFontSize, minFontSize, longest.length/maxLineLength);
  }

  if (content.isTicker) {
    div.classList.add('is-ticker');

    const h1 = document.createElement('h1');
    const headingLines = content.heading?.split('\n') ?? [];
    h1.style.fontSize = `${calculateFontSize(content.heading, 100, 40, 13)}px`
    headingLines.forEach((line, i) => {
      const p = document.createElement('p');
      p.innerHTML = line.toUpperCase();
      line.length >= 10 ? p.classList.add('large') : p.classList.add('medium');
      p.classList.add(fontsOrder[i % fontsOrder.length]);
      h1.appendChild(p)
    });
    fitwrapper.appendChild(h1);
  } else {
    const h1 = document.createElement('h1');
    const headingLines = content.heading?.split('\n') ?? [];
    h1.style.fontSize = `${calculateFontSize(content.heading, 100, 48, 12)}px`
    headingLines.forEach((line, i) => {
      const p = document.createElement('p');
      p.innerHTML = line.toUpperCase();
      // p.classList.add('large');
      p.classList.add(fontsOrder[i % fontsOrder.length]);
      h1.appendChild(p)
    });
    
    fitwrapper.appendChild(h1);

    content.subheadings.forEach((sub, i) => {
      const h2 = document.createElement('h2');
      const subheadingLines = sub?.EN?.split('\n') ?? [];
        subheadingLines.forEach((line, i) => {
        const p = document.createElement('p');
        line.length >= 10 ? p.classList.add('large') : p.classList.add('medium');
        p.innerHTML = line.toUpperCase();
        h2.appendChild(p)
      });

      if (i % 2 === 0) {
        h2.classList.add('helvetica');
        h2.classList.add('bold');
      } else {
        h2.classList.add('neue-pixel-grotesk');
      }
      fitwrapper.appendChild(h2);
    });
  }

  div.appendChild(fitwrapper);
  return div;
}
