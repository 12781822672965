import React, {
    useEffect,
    useMemo,
    useState
} from 'react';
import { AnimatePresence } from 'framer-motion';
import { get } from 'lodash';

import * as cssClassNames from '../styles/components/ChapterRunner.module.scss';

import {
    ChapterType,
    setAnimationTimeout,
    TransitionConfig,
    TransitionVariant
} from '../shared';
import TickerRunner, { TickerRunnerProps } from './TickerRunner';
import ChapterRenderer, { ChapterRenderOptions } from './ChapterRenderer';
import * as TwoColumns from './transitions/TwoColumns';


export interface HoiConfig {
    duration: {
        title?: number;
        default?: number;
        interstitial?: number;
        snkrs: {
            overview?: number;
            drop?: number;
            drop_media_count?: number;
            story?: number;
            story_page_count?: number;
        },
    },
    forceAssetHeight?: number;
    meta?: any;
}

type ChapterRunnerProps = {
    chapters: ChapterType[];
    config: HoiConfig;
    openChapter?: null | number | string;
    renderOptions?: ChapterRenderOptions;
    globalTicker?: TickerRunnerProps,
    transition?: TransitionConfig,
};

export default function ChapterRunner(props: ChapterRunnerProps) {
    const {
        config,
        chapters: chaptersData,
        openChapter = null,
        renderOptions,
        globalTicker,
        transition: globalTransition,
    } = props;

    const forcedChapterIndex = openChapter === null ? null : Number(openChapter);

    const [chapters, setChapters] = useState([]);
    const [chapterCounter, setChapterCounter] = useState(0);
    const [isFullScreenChapter, setIsFullScreenChapter] = useState(true);

    useEffect(() => {
        const index = forcedChapterIndex !== null ? forcedChapterIndex : (chapterCounter % chaptersData.length);
        const chapterData = chaptersData[index];
        const {
            data,
            template,
            duration,
            exitDuration,
        } = chapterData;
        const renderConfig = renderOptions[template];
        const {exitTimeOffset = 0} = renderConfig || {};
        const exitOffset = exitTimeOffset - exitDuration;

        const transitionConfig = data.transition || globalTransition;
        const transitionVariant = transitionConfig?.variant;
        const Transition = selectTransitionComponent(transitionVariant);

        const nextChapterData = chaptersData[(index + 1) % chaptersData.length];
        const nextTransitionConfig = nextChapterData?.data.transition || globalTransition;
        const nextChapterTransitionDuration = calculateTransitionDuration(nextTransitionConfig?.variant);

        const offsetNext = forcedChapterIndex === null ? nextChapterTransitionDuration : exitOffset;
        const cancelActivateNewChapterTimeout = setAnimationTimeout(() => {
            setChapterCounter(i => i + 1);
        }, duration - offsetNext);

        const offset = forcedChapterIndex === null ? exitOffset : 0;
        const cancelExitChapterTimeout = setAnimationTimeout(() => {
            setChapters(currentChapters => {
                currentChapters.splice(0, 1);
                return [...currentChapters];
            });
        }, duration + offset);

        const newChapter = (
            <ChapterRenderer
                key={chapterCounter}
                index={index}
                render={renderConfig?.render}
                chapter={chapterData}
                config={config}
            />
        );

        const component = Transition ? (
            <Transition key={chapterCounter}>
                {newChapter}
            </Transition>
        ) : newChapter;

        setIsFullScreenChapter(data.fullScreen === true);
        setChapters(currentChapters => [...currentChapters, component]);

        return () => {
            cancelActivateNewChapterTimeout();
            cancelExitChapterTimeout();
        }
    }, [chapterCounter]);

    const tickerHeight = globalTicker ? get(globalTicker, 'height', 192) : 0;
    const isTickerHasContent = useMemo(() => {
        const tickerContent = globalTicker?.content || [];

        return tickerContent.length > 0;
    }, [globalTicker]);
    const isTickerVisible = isTickerHasContent && !isFullScreenChapter;
    const mainGridArea = !isFullScreenChapter
        ? cssClassNames.main
        : [cssClassNames.main, cssClassNames.main, cssClassNames.footer, cssClassNames.footer].join('/');

    return (
        <div className={cssClassNames.chapter_container}
             style={{
                 gridTemplateRows: `auto ${tickerHeight}px`,
             }}
        >
            <div
                className={cssClassNames.main_container}
                style={{
                    gridArea: mainGridArea,
                }}
            >
                <AnimatePresence>
                    {chapters}
                </AnimatePresence>
            </div>

            {isTickerVisible ? (
                <TickerRunner
                    {...globalTicker}
                    style={{
                        gridArea: cssClassNames.footer
                    }}
                />
            ) : null}
        </div>
    );
}


function selectTransitionComponent(variant: TransitionVariant) {
    let Component;
    switch (variant) {
        case 'two-columns':
            Component = TwoColumns;
            break;

        default:
            return null;
    }

    return Component.default;
}

function calculateTransitionDuration(variant: TransitionVariant, props = {}): number {
    let Component;
    switch (variant) {
        case 'two-columns':
            Component = TwoColumns;
            break;

        default:
            return 0;
    }

    return Component.calculateDuration(props);
}
