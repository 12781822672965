export default `
  <div class="app live-app">
    <div class="freeflow-item"></div>

    <div class="overlay">
      <span class="viewfinder corner-top-left"></span>
      <span class="viewfinder corner-top-right"></span>
      <span class="viewfinder corner-bottom-right"></span>
      <span class="viewfinder corner-bottom-left"></span>
    </div>
  </div>
`;
