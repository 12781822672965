import BaseTicker from "./BaseTicker";
import Court1 from "../assets/icons/Court1";
import Court2 from "../assets/icons/Court2";
import Typography from './Typography';
import Family from "../assets/icons/Family";
import {ThemeType} from "../shared";
import {getColor} from '../styles/color';
import Line from "./Line";
import React from "react";

interface FamilyTickerProps {
    theme: ThemeType;
    hideLine?: boolean;
    duration?: number;
    reverse?: boolean;
    forceContentHeight?: string;
    lineLocation?: 'left' | 'right'
}

export default function FamilyTicker({theme, hideLine = false, forceContentHeight, reverse, duration, lineLocation = 'left'}: FamilyTickerProps) {
    const courtStyle = {
        padding: '20px 0',
    };

    const ticker = (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100%',
                position: 'relative',
                width: '86px',
                color: getColor(theme === 'light' ? 'black' : 'white'),
            }}
        >
            <div style={courtStyle}>
                <Court2 />
            </div>
            <div style={{flexGrow: 1, position: 'relative', width: '100%', overflow: 'hidden'}}>
                <div style={{height: forceContentHeight ?? '100%'}}>
                    <BaseTicker direction={reverse ? 'backwards' : 'forwards'} rotation={'-90deg'} duration={duration}>
                        <div
                            style={{
                                paddingLeft: '33px',
                                paddingRight: '33px',
                                display: 'flex',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                height: '100%',
                            }}>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <Typography size={16} variant={'jordan'} color={theme === 'dark' ? 'white' : "near-black"}>
                                    ©GLOBAL JORDAN FAMILY<br/>
                                    NO ONE IN THIS FAMILY FLIES ALONE. <br/>
                                    JOIN THE FAM.
                                </Typography>
                                <Typography size={16} variant={'jordan'} color={theme === 'dark' ? 'white' : "near-black"}>
                                    家族  FAMILIA  FAMILLE  семья  FAMILJ <br/>
                                    FAMIGLIA  가족  AILE  CIM’R  FAMÍLIA <br/>
                                    RODZINA   家族  FAMILE
                                </Typography>
                            </div>

                            <div style={{}}>
                                <Family theme={theme}/>
                            </div>
                        </div>
                    </BaseTicker>
                </div>
            </div>
            <div style={courtStyle}>
                <Court1/>
            </div>
        </div>
    );

    if (hideLine) {
        return ticker;
    }

    return (
        <div style={{display: 'flex', height: '100%'}}>
            {
                lineLocation === 'left' && (
                    <div style={{padding: '40px 10px', paddingRight: ticker ? '10px' : '40px', height: '100%',}}>
                        <Line theme={theme}/>
                    </div>
                )
            }

            {ticker}

            {
                lineLocation === 'right' && (
                    <div style={{padding: '40px 10px', paddingRight: ticker ? '10px' : '40px', height: '100%',}}>
                        <Line theme={theme}/>
                    </div>
                )
            }
        </div>
    );
}