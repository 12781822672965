import {getChannel} from "../../touchpoints/rise/api";
import InitializeTakeover from "../../touchpoints/jordan/animations/takeover/entry";
import {shouldPlayTakeover} from "../../touchpoints/rise/animations/fastlane-kids-takeover/shared";

interface StoreTakeoverConfig {
    channels?: Array<string>;
}

interface ChannelConfigTakeover {
    channel?: string | false;
}

export interface Channel {
    json_config: {
        store_takeover: ChannelConfigTakeover | false;
    }
}

export interface Store {
    json_meta: {
        store_takeover: StoreTakeoverConfig;
    }
}

export default class StoreTakeover {
    private channel: Channel;
    private store: Store;
    private animation: Animation;
    private invalidations: Array<Function> = [];
    private animationData?: AnimationData;
    private compareTo: string;

    constructor(channel: Channel, store: Store) {
        this.channel = channel;
        this.store = store;

        this.execute();
    }

    execute({afterFetch}: { afterFetch?: Function } = {}): void {
        const channel = this.getDominantChannel();

        if (!channel) {
            return;
        }

        this.loadAnimations((data: AnimationData) => {
            this.compareTo = JSON.stringify(data);

            this.run();

            const schedule = data.takeover_scheduling;

            if (!schedule?.type || (!schedule?.due && !schedule?.interval)) {
                return;
            }

            if (schedule?.type === 'date') {
                shouldPlayTakeoverDue(
                    new Date(schedule.due),
                    () => {
                        triggerTakeover('start');
                    }
                );
            }

            if (schedule?.type === 'interval') {
                shouldPlayTakeover(schedule.interval / 1_000 / 60, () => {
                    triggerTakeover('start');
                });
            }
        });
    }

    getDominantChannel(): string | undefined {
        const storeTakeoverChannels = this.store.json_meta?.store_takeover?.channels;

        if (!storeTakeoverChannels?.length) {
            return;
        }

        const storeTakeover = storeTakeoverChannels?.[0] ?? null;
        const channelTakeover = this.channel.json_config?.store_takeover ?? null;

        if (!channelTakeover && !storeTakeover || (channelTakeover === false || channelTakeover?.channel === false)) {
            return;
        }

        if (channelTakeover?.channel) {
            return channelTakeover.channel;
        }

        if (storeTakeover) {
            return storeTakeover;
        }

        return;
    }

    async loadAnimations(callback: (schedule: AnimationData) => void) {
        const channel = this.getDominantChannel();

        getChannel(channel).then((data: ChannelResult) => {
            const schedule = data.channels[0].schedule?.[0].animation?.json_data;
            // make sure there's at least a bit of scheduling in here.
            if (!schedule) {
                return;
            }

            this.animation = data.channels[0].schedule?.[0].animation;
            callback(schedule);
        }).catch(() => {
            console.warn(`Could not load channel [${channel}] for store-takeover, make sure you use an existing channel.`)
        });
    }

    run() {
        // will find the right animation type
        // append overlay to dom
        // inject the template into it
        // make sure it has handlers for `start_at` and `end_at`
        // this will likely be an array
        // when the time is due
        // kick of the template (so make sure it has an event handler of sorts)
        // when done just hard cut out and wait for the next one

        InitializeTakeover(this.animation.json_data);
    }

    isDifferent(callback: Function) {
        this.loadAnimations((data) => {
            callback(JSON.stringify(data) !== this.compareTo)
        })
    }


    async reload(): Promise<void> {
        if (!this.getDominantChannel()) {
            return;
        }

        this.isDifferent((isDifferent) => {
            if (isDifferent) {
                // window.location.reload();
                window.location.href =
                    window.location.pathname +
                    window.location.search +
                    window.location.hash;
            }
        });
    }
}

const shouldPlayTakeoverDue = (dueDateTime: Date, callback: Function, precision = 3,) => {
    const round = (d: Date) => {
        return Math.floor(Number(d) / 1_000)
    }
    const due = round(dueDateTime);
    let triggered = false;

    const check = () => {
        const isDue = round(new Date()) === due;

        if (triggered) {
            return;
        }

        if (isDue && triggered === false) {
            triggered = true;
            callback();

            setTimeout(() => {
                triggered = false
            }, 1_000);
        }
        requestAnimationFrame(check);
    }

    check();
}

const KEY = 'store_takeover';

type TakeoverCallbackType = 'start' | 'stop';

export function listenForTakeover(callback: (type: TakeoverCallbackType, chapter?: number) => void): Function {
    const action = (e: CustomEvent) => {
        callback(e.detail?.type, e.detail.chapter);
    };

    window.addEventListener(KEY, action);

    return () => {
        window.removeEventListener(KEY, action);
    }
}

function triggerTakeover(type: TakeoverCallbackType, chapter?: number) {
    console.log('triggering Takeover', chapter)
    const event = new CustomEvent(KEY, {
        detail: {type, chapter}
    });

    window.dispatchEvent(event);
}


export interface ChannelResult {
    channels?: (ChannelsEntity)[] | null;
}

export interface ChannelsEntity {
    id: number;
    slug: string;
    title: string;
    image: string;
    store_id: number;
    json_data: JsonData;
    json_config?: null;
    order_number: number;
    animation_types?: (number)[] | null;
    schedule?: (ScheduleEntity)[] | null;
}

export interface JsonData {
}

export interface ScheduleEntity {
    store_id: number;
    id: number;
    animation_id: number;
    channel_id: number;
    draft: number;
    json_data?: null;
    start_at: number;
    end_at?: null;
    created_at: number;
    updated_at: number;
    deleted_at?: null;
    animation: Animation;
}

export interface Animation {
    created_by_app_user_id: number;
    updated_by_app_user_id: number;
    animation_type_id: number;
    store_id: number;
    id: number;
    name: string;
    layout: string;
    json_data: AnimationData;
    status: string;
    created_at: number;
    updated_at: number;
    deleted_at?: null;
}

export interface TakeoverScheduling {
    type: 'interval' | 'date';
    due: Date;
    interval: number;
}

export interface AnimationData {
    chapters?: (ChaptersEntity)[] | null;
    takeover_scheduling: TakeoverScheduling;
    languages?: (string)[] | null;
    assets_meta: AssetsMeta;
}

export interface ChaptersEntity {
    schedule?: (Schedule)[] | null;
    media?: (MediaEntity | null)[] | null;
    title: string;
    disabled: boolean;
    template: string;
}

export interface Schedule {
    due: Date | null
}

export interface MediaEntity {
    url: string;
    etag: string;
    width: number;
    format: string;
    height: number;
    original_url: string;
    resource_type: string;
    force_duration: string;
    duration?: number | null;
}

export interface TakeoverSchedule {
    start: number;
}

export interface AssetsMeta {
    groups?: (null)[] | null;
    primary_tags?: (null)[] | null;
}
