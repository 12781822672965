#pragma glslify: coverUV = require('../../lib/glsl/coverUV.glsl')
#pragma glslify: pixelateUV = require('../../lib/glsl/pixelateUV.glsl')
#pragma glslify: zoomedUV = require('../../lib/glsl/zoomedUV.glsl')
#pragma glslify: mosaicMask = require('../../lib/glsl/mosaic.glsl')
#pragma glslify: mosaicEffect = require('../../lib/glsl/mosaic.glsl')

#ifdef GL_ES
precision highp float;
#endif

varying vec2 vUv;

uniform float time;
uniform vec2 res; // res
uniform float pixa; // pixelization amount (size of each cell/pixel)
uniform float pixr; // pixel ratio
uniform float opacity;

uniform float mosaicAmount; // how much mosaic is covering the screen (0-1)
uniform vec3 mosaicColor; // mosaic background color
uniform float mosaicAlpha; // greyscale color

uniform float pixels;
uniform float pixRF;
uniform float zoom;
uniform float zoomRF;
uniform vec2 rangeFinder;
uniform vec2 offset;
uniform float mosaic;
uniform float index;
uniform bool hasViewFinder;
uniform bool isCutAnim;

uniform sampler2D img0;
uniform sampler2D textOverlay;
uniform sampler2D previousTexture;
uniform sampler2D gridImage;


uniform vec2 img0Res;
uniform vec2 textOverlayRes;
uniform vec2 previousTextureRes;

vec4 layer(vec4 foreground, vec4 background) {
    return foreground * foreground.a + background * (1.0 - foreground.a);
}

vec2 centeredOffset () {
  float ar = (res.x / res.y);
  return ((offset - 0.5) * vec2(1.0, (1.0 / ar))) + 0.5;
}

vec4 addRangeFinder (vec4 color) {
  float ar = (res.x / res.y);
  float iar = (textOverlayRes.x / textOverlayRes.y);
  float px = ((res.x * pixr) / (pixels + pixRF));

  vec2 uv = coverUV(vUv, res, textOverlayRes);
  uv = zoomedUV(uv, textOverlayRes, ((zoom * zoomRF) * iar), centeredOffset());
  vec2 radiusRF = vec2(rangeFinder / res) * 0.5;
  vec2 cuv = ((vUv - 0.5) * vec2(1.0, (1.0 / ar))) + 0.5;
  float c = distance(cuv, vec2(0.5));
  c = step(c, radiusRF.x);
  uv = pixelateUV(uv, px, ar);
  float m = mosaicEffect(uv, ar, time, mosaicAmount);
  color = mix(color, vec4(mosaicColor, mosaicAlpha), m);
  return mix(color, texture2D(gridImage, uv, -10.0), c);
}

void main() {

  vec4 background = vec4(1.0, 1.0, 1.0, 0.0); // transparent white
  float iar = (textOverlayRes.x / textOverlayRes.y);
  float px = (res.x / pixa);
  float ar = (res.x / res.y);
  vec2 st = (gl_FragCoord.xy / res);

  vec2 uv = coverUV(st, (res * pixr), (img0Res * pixr));
  uv = zoomedUV(uv, img0Res, zoom * iar, centeredOffset());
  uv = pixelateUV(uv, px, ar);

  vec4 color = vec4(texture2D(img0, uv, -10.0).rgb, 1.0);
  vec4 text = texture2D(gridImage, st, -10.0);
  color = layer(text, color);


  if (isCutAnim) {
  float m = mosaicEffect(st, ar, time, mosaicAmount);
  color = mix(color, vec4(mosaicColor, mosaicAlpha), m);
  }

  if (hasViewFinder) {
    uv = coverUV(st, (res * pixr), (previousTextureRes * pixr));
    vec4 preTexture = texture2D(previousTexture, uv, -10.0);
    color = layer(preTexture, color);
    color = addRangeFinder(color);
  };

  // m = mosaicEffect(st, ar, time, mosaicAmount);
  // color = ;
  // float o = 0.9;

  gl_FragColor = color;
}
