if (module.hot) {
  module.hot.accept(() => {
    location.reload()
  })
}

import * as THREE from 'three';
import moment from 'moment';

import LLSTemplate from '/src/touchpoints/live/lib/js/LLSTemplate';
import vert from '/src/touchpoints/live/lib/glsl/shader.vert';
import frag from './look-build.frag';
import timeline from './look-build-timeline';

class LookBuild extends LLSTemplate {
  constructor (datas, canvas, renderer, onEnd, name, gui) {
    super(datas, canvas, renderer, { vert, frag }, name, gui);

    this.keyframes = [0, 6];

    this.setupTL(timeline, onEnd);
  }

  preInit () {
    this.refWidth = 480;
    this.refHeight = 1080;
  }

  setupUniforms () {
    const screen = new THREE.Vector2(
      this.canvas.clientWidth,
      this.canvas.clientHeight
    );
    const center = new THREE.Vector2(
      (this.canvas.clientWidth / 2),
      (this.canvas.clientHeight / 2)
    );

    let colors = {
      img1color1: new THREE.Uniform(new THREE.Color('#BDBDBD')),
      img1color2: new THREE.Uniform(new THREE.Color('#707070')),
      img1color3: new THREE.Uniform(new THREE.Color('#363636')),
      img1color4: new THREE.Uniform(new THREE.Color('#161616')),
      img2color1: new THREE.Uniform(new THREE.Color('#BDBDBD')),
      img2color2: new THREE.Uniform(new THREE.Color('#707070')),
      img2color3: new THREE.Uniform(new THREE.Color('#363636')),
      img2color4: new THREE.Uniform(new THREE.Color('#161616')),
      img3color1: new THREE.Uniform(new THREE.Color('#BDBDBD')),
      img3color2: new THREE.Uniform(new THREE.Color('#707070')),
      img3color3: new THREE.Uniform(new THREE.Color('#363636')),
      img3color4: new THREE.Uniform(new THREE.Color('#161616')),
      img4color1: new THREE.Uniform(new THREE.Color('#BDBDBD')),
      img4color2: new THREE.Uniform(new THREE.Color('#707070')),
      img4color3: new THREE.Uniform(new THREE.Color('#363636')),
      img4color4: new THREE.Uniform(new THREE.Color('#161616')),
      img5color1: new THREE.Uniform(new THREE.Color('#BDBDBD')),
      img5color2: new THREE.Uniform(new THREE.Color('#707070')),
      img5color3: new THREE.Uniform(new THREE.Color('#363636')),
      img5color4: new THREE.Uniform(new THREE.Color('#161616')),
    };

    if (this.datas?.colors) {
      const overrides = this.datas.colors.reduce((acc, curr, index) => ({
        ...acc,
        [`color${index + 1}`]: new THREE.Uniform(new THREE.Color(curr))
      }), [])
      colors = {
        ...colors,
        ...overrides,
      }
      console.log('overrides', overrides)
    }

    this.uniforms = {
      // general stuffs
      time: new THREE.Uniform(0),
      pixa: new THREE.Uniform(1),
      pixr: new THREE.Uniform(window.devicePixelRatio),
      stroke: new THREE.Uniform(1),
      gridAlpha: new THREE.Uniform(1),
      // colors
      ...colors,
      // palette
      paletteSize: new THREE.Uniform(Math.min(this.relWidth(20), this.relHeight(20))),
      paletteOpacity: new THREE.Uniform(1),
    }
  }

  setupGUI () {
    this.guiModel['pixels'] = this.uniforms.pixa.value;
    this.gui.addInput(this.guiModel, 'pixels', { min: 1, max: 420, step: 1 }).on('change', ({ value }) => {
      this.uniforms.pixa.value = value;
    });
  }

  setupTL (timeline, tlOnComplete) {
    this.timeline = timeline.createTL(
      this.start.bind(this),
      this.stop.bind(this),
      tlOnComplete,
      this.uniforms,
      this.viewfinderText,
      [{
        time: 8,
        fn: () => {
          this.createTexture({
            src: this.datas['2_landscape']?.assets[0]?.url,
            key: 'img1'
          }, false);
          this.createTextCanvas('img1', ((this.uniforms.img1Dim.value.y - 10) * this.screenDiffY), false);
        }
      }, {
        time: 7,
        fn: () => {
          this.createTexture({
            src: this.datas['2_landscape']?.assets[1]?.url,
            key: 'img2'
          }, false);
          this.createTextCanvas('img2', ((this.uniforms.img2Dim.value.y - 10) * this.screenDiffY), true);
        }
      }, {
        time: 7,
        fn: () => {
          this.createTexture({
            src: this.datas['2_landscape']?.assets[2]?.url,
            key: 'img4'
          }, false);
          this.createTextCanvas('img4', ((this.uniforms.img4Dim.value.y - 10) * this.screenDiffY), false);
        }
      }, {
        time: 6.5,
        fn: () => {
          this.createTexture({
            src: this.datas['2_portrait']?.assets[0]?.url,
            key: 'img5'
          }, false);
          this.createTextCanvas('img5', ((this.uniforms.img5Dim.value.y - 10) * this.screenDiffY), true);
        }
      }]
    );
  }

  preStart () {
    this.createTexture({ src: this.datas['1_landscape']?.assets[0]?.url, key: 'img1' }, false);
    this.createTexture({ src: this.datas['1_landscape']?.assets[1]?.url, key: 'img2' }, false);
    this.createTexture({ src: this.datas['1_landscape']?.assets[2]?.url, key: 'img4' }, false);
    this.createTexture({ src: this.datas['1_portrait']?.assets[1]?.url, key: 'img5' }, false);

    this.uniforms.img2Dim.value.x = this.relWidth(320);
    this.uniforms.img2Dim.value.y = this.relWidth(320);

    this.createTextCanvas('img1', ((this.uniforms.img1Dim.value.y - 10) * this.screenDiffY), false);
    this.createTextCanvas('img2', ((this.uniforms.img2Dim.value.y - 10) * this.screenDiffY), true);
    this.createTextCanvas('img3', ((this.uniforms.img3Dim.value.y - 10) * this.screenDiffY), true);
    this.createTextCanvas('img4', ((this.uniforms.img4Dim.value.y - 10) * this.screenDiffY), false);
    this.createTextCanvas('img5', ((this.uniforms.img5Dim.value.y - 10) * this.screenDiffY), true);
  }

  update () {
    Object.keys(this.viewfinderText).forEach(key => {
      const canvas = this[`${key}TextCanvas`];
      const context = canvas.getContext('2d');
      const vft = this.viewfinderText[key];
      const season = (vft.length > 1);

      context.clearRect(0, 0, canvas.width, canvas.height);
      context.fillStyle = 'white';
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.fillStyle = 'black';

      context.save();
      context.translate((canvas.width / 2), (canvas.height / 2));
      context.rotate(-(Math.PI / 2));

      if (season) {
        context.textAlign = 'left';
        context.fillText(vft[0], -((canvas.height / 2) - this.relHeight(15)), this.relWidth(5));

        context.textAlign = 'right';
        context.fillText(vft[1], ((canvas.height / 2) - this.relHeight(15)), this.relWidth(5));
      } else {
        context.textAlign = 'center';
        context.fillText(vft[0], -this.relHeight(5), this.relWidth(5));
      }

      this.uniforms[`${key}TxtTex`].value.needsUpdate = true;

      context.restore();
    });
  }

  createTextCanvas (key, height, season) {
    const canvas = document.createElement('canvas');
    canvas.width = 60;
    canvas.height = this.relHeight(height);

    const context = canvas.getContext('2d');
    context.font = `500 ${this.relHeight(18)}px "Helvetica Neue"`;
    const textTexture = new THREE.CanvasTexture(canvas);
    textTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    this.uniforms[`${key}TxtTex`] = new THREE.Uniform(textTexture);
    this.uniforms[`${key}TxtRes`] = new THREE.Uniform(new THREE.Vector2(
      canvas.width,
      canvas.height
    ));
    this[`${key}TextCanvas`] = canvas;
    this.viewfinderText[key] = season ? ['', ''] : [''];
  }

  setupTextures () {
    this.viewfinderText = { img1: [], img2: [], img3: [], img4: [], img5: [] };

    console.log(window.innerWidth, window.innerHeight, window.devicePixelRatio);
    console.log(this.screenRatio);

    this.screenDiffY = (1 / this.screenRatio.y);

    this.createTexture({
      src: this.datas['1_landscape'].assets[0]?.url,
      key: 'img1',
      pos: { x: this.relWidth(240), y: this.relHeight(1000) },
      dim: { x: this.relWidth(160), y: this.relHeight(160) },
      offset: { x: 0.5, y: 0.5 },
      zoom: 1.0,
      opacity: 1,
      pixel: 1
    });
    this.createTextCanvas('img1', ((this.uniforms.img1Dim.value.y - 10) * this.screenDiffY), false);

    this.createTexture({
      src: this.datas['1_landscape']?.assets[1]?.url,
      key: 'img2',
      pos: { x: this.relWidth(160), y: this.relHeight(760) },
      dim: { x: this.relWidth(320), y: this.relHeight(320) },
      offset: { x: 0.5, y: 0.5 },
      zoom: 1.0,
      opacity: 1,
      pixel: 1
    });
    this.createTextCanvas('img2', ((this.uniforms.img2Dim.value.y - 10) * this.screenDiffY), true);

    this.createTexture({
      src: this.datas['1_portrait']?.assets[0]?.url,
      key: 'img3',
      pos: { x: ((this.canvas.clientWidth * 0.5) + this.relWidth(160)), y: ((this.canvas.clientHeight * 0.5) + this.relHeight(60)) },
      dim: { x: this.relWidth(160), y: this.relHeight(320) },
      offset: { x: 0.5, y: 0.5 },
      zoom: 0.5,
      opacity: 1,
      pixel: 1
    });
    this.createTextCanvas('img3', ((this.uniforms.img3Dim.value.y - 10) * this.screenDiffY), true);

    this.createTexture({
      src: this.datas['1_landscape']?.assets[2]?.url,
      key: 'img4',
      pos: { x: this.relWidth(80), y: this.relHeight(360) },
      dim: { x: this.relWidth(160), y: this.relHeight(160) },
      offset: { x: 0.5, y: 0.5 },
      zoom: 1.0,
      opacity: 1,
      pixel: 1
    });
    this.createTextCanvas('img4', ((this.uniforms.img4Dim.value.y - 10) * this.screenDiffY), false);

    this.createTexture({
      src: this.datas['1_portrait']?.assets[1]?.url,
      key: 'img5',
      pos: { x: this.relWidth(320), y: this.relHeight(220) },
      dim: { x: this.relWidth(320), y: this.relHeight(440) },
      offset: { x: 0.5, y: 0.5 },
      zoom: 0.75,
      opacity: 1,
      pixel: 1
    });
    this.createTextCanvas('img5', ((this.uniforms.img5Dim.value.y - 10) * this.screenDiffY), true);

  }
}
export default LookBuild;
