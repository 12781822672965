[
  {
    "id": 0,
    "delayVertical": true,
    "horizontal": [
      {
        "name": "PrimaryTop",
        "startPosition": 5.6,
        "drawFull": true,
        "drawToPosition": 100,
        "drawFromPosition": 0
      },
      {
        "delay": 100,
        "drawFromPosition": 0,
        "drawToPosition": 50,
        "drawFull": true,
        "name": "Equator",
        "startPosition": 50
      },
      {
        "drawFromPosition": 0,
        "startPosition": 94.4,
        "delay": 200,
        "drawFull": true,
        "name": "PrimaryBottom",
        "drawToPosition": 100
      },
      {
        "drawToPosition": 100,
        "drawFull": true,
        "name": "EquatorRight",
        "startPosition": 50,
        "drawFromPosition": 50,
        "delay": 100
      }
    ],
    "circle": [],
    "drawBorder": true,
    "verticalDelayDirection": -1,
    "vertical": [
      {
        "delay": 1000,
        "drawFromPosition": 100,
        "drawFull": true,
        "startPosition": 3.1,
        "name": "PrimaryLeft",
        "drawToPosition": 0
      },
      {
        "drawFull": true,
        "drawFromPosition": 0,
        "drawToPosition": 50,
        "name": "Left1",
        "startPosition": 26,
        "delay": 1000
      },
      {
        "drawFull": true,
        "startPosition": 50,
        "name": "Equator",
        "delay": 1000,
        "drawToPosition": 50,
        "drawFromPosition": 0
      },
      {
        "startPosition": 74,
        "drawToPosition": 50,
        "name": "Right1",
        "delay": 1000,
        "drawFull": true,
        "drawFromPosition": 0
      },
      {
        "drawFull": true,
        "drawToPosition": 0,
        "drawFromPosition": 100,
        "delay": 1000,
        "startPosition": 96.9,
        "name": "PrimaryRight"
      },
      {
        "startPosition": 50,
        "drawFull": true,
        "delay": 1000,
        "name": "EquatorBottom",
        "drawFromPosition": 50,
        "drawToPosition": 100
      },
      {
        "delay": 1000,
        "name": "Left1Bottom",
        "startPosition": 26,
        "drawFromPosition": 50,
        "drawToPosition": 100,
        "drawFull": true
      },
      {
        "drawFull": true,
        "name": "RightBottom",
        "startPosition": 74,
        "delay": 1000,
        "drawFromPosition": 50,
        "drawToPosition": 100
      }
    ],
    "delayHorizontal": true,
    "duration": "1000",
    "name": "ChapterSetup",
    "mask": []
  },
  {
    "delayVertical": false,
    "circle": [],
    "delayHorizontal": false,
    "drawBorder": true,
    "verticalDelayDirection": -1,
    "vertical": [
      {
        "endPosition": -1,
        "drawFromPosition": 100,
        "drawFull": true,
        "startPosition": 3.1,
        "name": "PrimaryLeft",
        "drawToPosition": 0
      },
      {
        "drawToPosition": 50,
        "endPosition": -1,
        "startPosition": 26,
        "drawFull": true,
        "drawFromPosition": 0,
        "name": "Left1"
      },
      {
        "drawFromPosition": 0,
        "name": "Equator",
        "delay": 1000,
        "drawToPosition": 50,
        "drawFull": true,
        "startPosition": 50
      },
      {
        "drawFull": false,
        "startPosition": 74,
        "extend": true,
        "drawToLine": "EquatorRight",
        "drawFromPosition": 0,
        "name": "Right1",
        "endPosition": 87.5
      },
      {
        "drawFull": true,
        "endPosition": 101,
        "startPosition": 96.9,
        "drawToPosition": 0,
        "drawFromPosition": 100,
        "name": "PrimaryRight"
      },
      {
        "startPosition": 50,
        "name": "EquatorBottom",
        "drawFull": true,
        "drawToPosition": 100,
        "drawFromPosition": 50,
        "delay": 1000
      },
      {
        "startPosition": 26,
        "endPosition": -1,
        "drawFromPosition": 50,
        "name": "Left1Bottom",
        "drawToPosition": 100,
        "drawFull": true
      },
      {
        "drawToPosition": 100,
        "delay": 1000,
        "drawFull": false,
        "drawFromLine": "EquatorRight",
        "extend": true,
        "name": "RightBottom",
        "startPosition": 74
      },
      {
        "startAlpha": 0.01,
        "delay": 1000,
        "drawFromPosition": 0,
        "drawToPosition": 100,
        "startPosition": 0,
        "endPosition": 50,
        "name": "HeroMask",
        "drawFull": true
      },
      {
        "drawToPosition": 100,
        "drawFull": true,
        "startPosition": 101,
        "startAlpha": 0.01,
        "endPosition": 87.5,
        "drawFromPosition": 0,
        "delay": 1200,
        "name": "GridMask"
      },
      {
        "startPosition": 101,
        "startAlpha": 0.01,
        "drawToPosition": 100,
        "drawFull": true,
        "endPosition": 74,
        "drawFromPosition": 0,
        "delay": 1500,
        "name": "Grid2Mask"
      }
    ],
    "mask": [
      {
        "horizontal": [
          "PrimaryTop",
          "PrimaryBottom"
        ],
        "container": "mask",
        "vertical": [
          "Left Border",
          "HeroMask"
        ],
        "asset": 0,
        "grid": 0,
        "pin": "right",
        "coverFill": true,
        "name": "Hero"
      },
      {
        "asset": 1,
        "name": "Grid1",
        "coverFill": true,
        "container": "mask",
        "vertical": [
          "Right Border",
          "GridMask"
        ],
        "grid": 0,
        "pin": "left",
        "horizontal": [
          "PrimaryTop",
          "RightAux"
        ]
      },
      {
        "vertical": [
          "Right Border",
          "Grid2Mask"
        ],
        "name": "Grid2",
        "grid": 0,
        "container": "mask",
        "pin": "left",
        "horizontal": [
          "EquatorRight",
          "PrimaryBottom"
        ],
        "coverFill": true,
        "asset": 2
      },
      {
        "grid": 0,
        "polyOnly": true,
        "colorFill": true,
        "asset": 0,
        "name": "TextTop",
        "horizontal": [
          "PrimaryTop",
          "RightTop"
        ],
        "vertical": [
          "Equator",
          "Right1"
        ]
      },
      {
        "colorFill": true,
        "horizontal": [
          "RightTop",
          "EquatorRight"
        ],
        "polyOnly": true,
        "asset": 0,
        "name": "TextBottom",
        "grid": 0,
        "vertical": [
          "Equator",
          "Right1"
        ]
      }
    ],
    "name": "ChapterEntry",
    "id": 0,
    "duration": "1000",
    "horizontal": [
      {
        "drawToPosition": 100,
        "drawFull": true,
        "name": "PrimaryTop",
        "startPosition": 5.6,
        "drawFromPosition": 0
      },
      {
        "drawToPosition": 50,
        "drawFull": true,
        "startPosition": 50,
        "name": "Equator",
        "endPosition": 5.6,
        "delay": 100,
        "drawFromPosition": 0
      },
      {
        "startPosition": 94.4,
        "drawFromPosition": 0,
        "drawToPosition": 100,
        "name": "PrimaryBottom",
        "drawFull": true,
        "delay": 200
      },
      {
        "endPosition": 72.2,
        "drawFull": true,
        "startPosition": 50,
        "name": "EquatorRight",
        "drawToPosition": 100,
        "drawFromPosition": 50
      },
      {
        "drawFromLine": "Right Border",
        "drawToPosition": 87.5,
        "extend": false,
        "drawFull": false,
        "name": "RightAux",
        "startPosition": 50,
        "delay": 1000
      },
      {
        "name": "RightTop",
        "drawToPosition": 87.5,
        "drawFull": true,
        "endPosition": 27.8,
        "drawFromPosition": 50,
        "startPosition": 5.6,
        "delay": 1000
      }
    ]
  },
  {
    "verticalDelayDirection": -1,
    "mask": [
      {
        "vertical": [
          "Left Border",
          "HeroMask"
        ],
        "name": "Hero",
        "coverFill": true,
        "asset": 0,
        "pin": "right",
        "horizontal": [
          "PrimaryTop",
          "PrimaryBottom"
        ],
        "grid": 0,
        "container": "mask"
      },
      {
        "horizontal": [
          "PrimaryTop",
          "RightAux"
        ],
        "name": "Grid1",
        "coverFill": true,
        "vertical": [
          "Right Border",
          "GridMask"
        ],
        "grid": 0,
        "asset": 1,
        "pin": "left",
        "container": "mask"
      },
      {
        "grid": 0,
        "horizontal": [
          "EquatorRight",
          "PrimaryBottom"
        ],
        "coverFill": true,
        "name": "Grid2",
        "pin": "left",
        "container": "mask",
        "vertical": [
          "Right Border",
          "Grid2Mask"
        ],
        "asset": 2
      },
      {
        "name": "TextTop",
        "asset": 0,
        "vertical": [
          "Equator",
          "Right1"
        ],
        "grid": 0,
        "colorFill": true,
        "polyOnly": true,
        "horizontal": [
          "PrimaryTop",
          "RightTop"
        ]
      },
      {
        "colorFill": true,
        "vertical": [
          "Equator",
          "Right1"
        ],
        "polyOnly": true,
        "horizontal": [
          "RightTop",
          "EquatorRight"
        ],
        "grid": 0,
        "name": "TextBottom",
        "asset": 0
      }
    ],
    "drawBorder": true,
    "circle": [],
    "horizontal": [
      {
        "drawFromPosition": 0,
        "drawFull": true,
        "startPosition": 5.6,
        "endPosition": 5.6,
        "name": "PrimaryTop",
        "drawToPosition": 100
      },
      {
        "delay": 100,
        "name": "Equator",
        "drawToPosition": 50,
        "startPosition": 5.6,
        "drawFromPosition": 0,
        "endPosition": 5.6,
        "drawFull": true
      },
      {
        "delay": 200,
        "drawToPosition": 100,
        "startPosition": 94.4,
        "drawFull": true,
        "name": "PrimaryBottom",
        "drawFromPosition": 0,
        "endPosition": 94.4
      },
      {
        "drawFull": true,
        "name": "EquatorRight",
        "drawFromPosition": 50,
        "endPosition": 72.2,
        "startPosition": 72.2,
        "drawToPosition": 100
      },
      {
        "delay": 1000,
        "extend": false,
        "name": "RightAux",
        "drawFromLine": "Right Border",
        "endPosition": 50,
        "drawToPosition": 87.5,
        "startPosition": 50,
        "drawFull": true
      },
      {
        "startPosition": 27.8,
        "drawFull": true,
        "name": "RightTop",
        "drawFromPosition": 50,
        "drawToPosition": 87.5,
        "delay": 1000
      }
    ],
    "duration": "1000",
    "delayHorizontal": false,
    "name": "ChapterEntry",
    "delayVertical": false,
    "id": 0,
    "vertical": [
      {
        "drawFromPosition": 100,
        "drawToPosition": 0,
        "name": "PrimaryLeft",
        "endPosition": -1,
        "drawFull": true,
        "startPosition": -1
      },
      {
        "drawFull": true,
        "endPosition": -1,
        "name": "Left1",
        "startPosition": -1,
        "drawToPosition": 50,
        "drawFromPosition": 0
      },
      {
        "drawFromPosition": 0,
        "name": "Equator",
        "endPosition": 50,
        "drawToPosition": 50,
        "drawFull": true,
        "delay": 1000,
        "startPosition": 50
      },
      {
        "startPosition": 87.5,
        "drawFull": true,
        "drawFromPosition": 0,
        "name": "Right1",
        "drawToLine": "EquatorRight",
        "endPosition": 87.5,
        "extend": true
      },
      {
        "drawFromPosition": 100,
        "drawFull": true,
        "startPosition": 101,
        "endPosition": 101,
        "drawToPosition": 0,
        "name": "PrimaryRight"
      },
      {
        "startPosition": 50,
        "delay": 1000,
        "endPosition": 50,
        "name": "EquatorBottom",
        "drawToPosition": 100,
        "drawFromPosition": 50,
        "drawFull": true
      },
      {
        "name": "Left1Bottom",
        "drawToPosition": 100,
        "endPosition": -1,
        "drawFromPosition": 50,
        "drawFull": true,
        "startPosition": -1
      },
      {
        "delay": 1000,
        "endPosition": 74,
        "drawFromLine": "EquatorRight",
        "drawFull": true,
        "name": "RightBottom",
        "drawToPosition": 100,
        "extend": true,
        "startPosition": 74
      },
      {
        "endPosition": 0,
        "name": "HeroMask",
        "startPosition": 50,
        "delay": 5000,
        "drawToPosition": 100,
        "drawFromPosition": 0,
        "startAlpha": 0.01,
        "drawFull": true
      },
      {
        "startPosition": 87.5,
        "delay": 4200,
        "startAlpha": 0.01,
        "endPosition": 100,
        "drawFull": true,
        "drawFromPosition": 0,
        "name": "GridMask",
        "drawToPosition": 100
      },
      {
        "endPosition": 100,
        "name": "Grid2Mask",
        "drawFromPosition": 0,
        "drawToPosition": 100,
        "delay": 3500,
        "drawFull": true,
        "startAlpha": 0.01,
        "startPosition": 74
      }
    ]
  },
  {
    "delayHorizontal": false,
    "vertical": [
      {
        "drawToPosition": 0,
        "endPosition": 3.1,
        "drawFromPosition": 100,
        "name": "PrimaryLeft",
        "startPosition": -1,
        "drawFull": true,
        "delay": 1000
      },
      {
        "endPosition": 26,
        "drawFromPosition": 0,
        "drawFull": true,
        "startPosition": -1,
        "name": "Left1",
        "drawToPosition": 50
      },
      {
        "drawFull": true,
        "name": "Equator",
        "drawToPosition": 50,
        "startPosition": 50,
        "drawFromPosition": 0,
        "delay": 1000
      },
      {
        "drawFromPosition": 0,
        "drawFull": false,
        "drawToLine": "EquatorRight",
        "startPosition": 87.5,
        "endPosition": 74,
        "delay": 1000,
        "extend": true,
        "name": "Right1"
      },
      {
        "delay": 1000,
        "drawFromPosition": 100,
        "drawFull": true,
        "drawToPosition": 0,
        "endPosition": 96.9,
        "name": "PrimaryRight",
        "startPosition": 101
      },
      {
        "drawFromPosition": 50,
        "startPosition": 50,
        "drawFull": true,
        "drawToPosition": 100,
        "delay": 1000,
        "name": "EquatorBottom"
      },
      {
        "drawToPosition": 100,
        "name": "Left1Bottom",
        "drawFull": true,
        "endPosition": 26,
        "startPosition": -1,
        "drawFromPosition": 50
      },
      {
        "delay": 1000,
        "drawToPosition": 100,
        "extend": true,
        "startPosition": 74,
        "drawFromLine": "EquatorRight",
        "name": "RightBottom",
        "drawFull": false
      }
    ],
    "circle": [],
    "horizontal": [
      {
        "drawFromPosition": 0,
        "drawFull": true,
        "startPosition": 5.6,
        "name": "PrimaryTop",
        "drawToPosition": 100
      },
      {
        "drawFromPosition": 0,
        "endPosition": 50,
        "startPosition": 5.6,
        "name": "Equator",
        "drawFull": true,
        "drawToPosition": 50,
        "delay": 100
      },
      {
        "startPosition": 94.4,
        "name": "PrimaryBottom",
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "delay": 200
      },
      {
        "name": "EquatorRight",
        "startPosition": 72.2,
        "drawToPosition": 100,
        "endPosition": 50,
        "drawFromPosition": 50,
        "drawFull": true
      },
      {
        "drawFull": true,
        "name": "RightAux",
        "drawFromLine": "Right Border",
        "drawToPosition": 87.5,
        "extend": false,
        "delay": 1000,
        "startPosition": 50
      }
    ],
    "id": 0,
    "verticalDelayDirection": -1,
    "name": "ChapterExit",
    "delayVertical": false,
    "mask": [],
    "duration": "1000",
    "drawBorder": true
  }
]