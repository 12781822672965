import React, {useEffect, useRef, useState} from "react";
import Activity from "./Activity";
import Background from "./Background";
import {DURATIONS} from "../shared";

export default function Track({data, info, index, onComplete, playOutro}) {
  const container = useRef(null);
  const [actionIndex, setActionIndex] = useState(0);
  const [playIntro, setPlayIntro] = useState(true);
  const [run, setRun] = useState(false);

  const triggerNext = () => {
    const nextIndex = actionIndex + 1;

    if (index === 0 && nextIndex === data?.json_data?.chapters?.length) {
      onComplete();
      return
    }

    setActionIndex(nextIndex)
  }

  const action = data?.json_data?.chapters?.[actionIndex];

  useEffect(() => {
    setTimeout(() => {
      setRun(true);
    }, DURATIONS.SWEEP_IN)
  });

  const repeat = Math.round(info.trackLength / 600);

  return (
    <div
      ref={container}
      style={{
        position: 'relative',
        display: 'flex',
        height: '100%',
        width: '100%',
        flex: '1',
        overflow: 'hidden',
      }}
    >
      {
        data?.json_data?.gradient && (
          <Background
            gradient={data?.json_data?.gradient}
            info={info}
            playOutro={playOutro}
          />
        )
      }
      {
        action && run && playIntro === true && (
          <Activity
            key={`intro_run_${index}`}
            index={-1}
            info={info}
            action={{
              activity: data?.json_data?.ticker_text,
              intro: true,
            }}
            triggerNext={() => {
              setPlayIntro(false);
            }}
            repeat={repeat}
          />
        )
      }

      {
        action && run && playIntro === false && (
          <Activity
            key={`${action?.activity?.['EN']}_${index}`}
            index={index}
            info={info}
            action={action}
            triggerNext={triggerNext}
            repeat={repeat}
          />
        )
      }
    </div>
  )
}