import _ from 'lodash';
import { DEFAULT_LANGUAGE } from '../../../constants/hub';
import { getStoreLanguages } from '../../../hyperlive/utils';

const HEADING_REGEX = /\n/gm;
const SUBHEADING_GLOBAL_REGEX = /^-/gm;
const SUBHEADING_REGEX = /^-/;
export const EMPTY_LINE = '__EMPTY__';

const parseSegments = (segments = [], lang, hasSubheadings, addImages, template) => {
	console.log(segments);
	const parsedSegments = segments.reduce((acc, segment, segmentIndex) => {
		let parentIndex = 0;
		const legend = segment.description?.[lang]
			?.split(HEADING_REGEX)
			.map((e, i) => {console.log(i, e); return e})
			.reduce((acc, curr, i) => {
				if (SUBHEADING_REGEX.test(curr)) {
					try {
						acc[parentIndex].subheadings.push(
							curr.replace(SUBHEADING_REGEX, '→ '),
						);
						return acc;
					} catch (e) {
						console.error('could not add subheading', i, curr)
					}
				}

				console.log('parentIndex', i, curr);

				parentIndex = acc.length;
				acc.push({ title: curr === '' ? EMPTY_LINE : curr, subheadings: [] });
				return acc;
			}, []);

		return [
			...acc,
			{
				title: segment.title?.[lang] || segment.title?.[DEFAULT_LANGUAGE],
				description: segment.description?.[lang] || '',
				date: segment.date?.[lang],
				...(template === 'directory' ? {legend} : null),
				...(addImages ? { image: _.get(segment, 'media[0]') } : null),
				...(addImages ? { icon: _.get(segment, 'media[1]') } : null),
			}
		];
	}, []);

	if (hasSubheadings) {
		return parsedSegments.sort((a, b) => {
			const levelA = parseInt(a.title, 10);
			const levelB = parseInt(b.title, 10);

			return levelA > levelB ? -1 : levelA < levelB ? 1 : 0;
		});
	}

	return parsedSegments;
};

export const parseHubData = (raw, addImages, template) => {
	const storeLanguages = getStoreLanguages().map(lang => lang.toUpperCase());
	const dataLanguages = Object.keys(raw?.header ?? {});

	const matchLanguages = storeLanguages.filter(lang => dataLanguages.includes(lang));
	const missingLanguages = dataLanguages.filter(lang => !storeLanguages.includes(lang));

	const languages = [...matchLanguages, ...missingLanguages];

	const hasSubheadings = raw.segments?.some(segment => {
		return languages.some(lang => SUBHEADING_GLOBAL_REGEX.test(segment.description?.[lang]));
	});

	return languages.reduce((acc, lang) => {
		return {
			...acc,
			[lang]: {
				title: raw.header?.[lang],
				titleImage: raw.header_asset?.[lang],
				subtitle: raw.sub_header?.[lang] || '',
				blocks: parseSegments(raw.segments, lang, hasSubheadings, addImages, template),
			},
		};
	}, {});
};
