import React, {Component} from 'react';
import {motion, AnimatePresence} from "framer-motion";
import * as classes from './ChapterSlideshow.module.scss';
import {HUB_CODIFICATION_PROPS} from "../../constants/transition";
import {Codification} from "../../components";

const DEFAULT_TRANSITION = {
  duration: 1,
  ease: [0.4, 0, 0, 1],
}

class ChapterSlideshow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0
    };

    this.titleRef = React.createRef();
  }

  componentDidMount() {
    if (this.getSlides().length > 1) {
      this.startCycle();
    }
  }

  startCycle = () => {
    setInterval(() => {
      this.setState(({slideIndex}) => ({
        slideIndex: (slideIndex + 1) % this.getSlides().length
      }))
    }, 6_000)
  }

  getSlides = () => {
    console.log(this.props.data)
    const chapters = this.props?.data?.chapters;
    if (!chapters?.length) {
      return [];
    }

    return chapters.reduce((acc, curr) => {
      let title = [];

      if (curr?.title && curr.title !== '') {
        title = [{
          resource_type: 'title',
          title: curr?.title
        }];
      }

      return [
        ...acc,
        ...title,
        ...curr.assets?.filter(e => !!e),
      ]
    }, []);
  }

  render() {
    const slides = this.getSlides();
    const {slideIndex} = this.state;

    const slide = slides[slideIndex];
    console.log('slide', slide);
    // const isVideo = !asset.toLowerCase().match(/(.jpg|.png|.jpeg|.webp)/)

    const props = {
      // initial: isAr === true ? false : null,
      animate: {
        scale: 1.8,
        // y: '30%',
        // x: '-30%',
      },
      transition: {
        duration: 20,
        easing: 'linear',
      }
    }

    return (
      <div className={classes.container}>
        <AnimatePresence className={classes.imageContainer}>
          <motion.div
            key={slideIndex}
            initial={{
              // x: '-5vh',
              opacity: 0,
            }}
            animate={{
              x: '0vh',
              opacity: 1,
            }}
            exit={{
              // x: '5vh',
              opacity: 0,
            }}
            className={classes.img}
            transition={DEFAULT_TRANSITION}
          >
            {
              slide?.resource_type === 'video' && (
                <motion.div
                  {...props}
                  className={classes.video}
                >
                  {/*<div className={classes.videoOverlay}>*/}

                  {/*</div>*/}
                  <video autoPlay muted loop>
                    <source src={slide.url}/>
                  </video>
                </motion.div>
              )
            }

            {
              slide?.resource_type === 'image' && (
                (
                  <motion.img
                    {...props}
                    src={slide.url}
                  />
                )
              )
            }

            {
              slide?.resource_type === 'title' && (
                (
                  <div className={classes.title}>
                    <Codification
                      {...HUB_CODIFICATION_PROPS}
                      delay={500}
                      ref={this.titleRef}
                      // @TODO: remove dependency on the Hub Template
                      className="c-title-block__title"
                      text={slide.title}
                      typography={{primary: true, size: "6xl", tag: "span"}}
                      // reverse={reverse}
                      // onEnded={updateCounter}
                    />
                  </div>
                )
              )
            }
          </motion.div>
        </AnimatePresence>
        {/*<div className={classes.grid}>*/}
        {/*  <div className={classes.gridLeft}></div>*/}
        {/*  <div className={classes.gridTop}></div>*/}
        {/*  <div className={classes.gridRight}></div>*/}
        {/*  <div className={classes.gridBottom}></div>*/}
        {/*</div>*/}
      </div>
    )
  }
}

export default ChapterSlideshow;
