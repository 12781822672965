import React, {useEffect, useState} from 'react';
import {takeoverTimeout} from "../shared";
import Track from "./Track";

export default function FastLaneTickers({info, data, onComplete}) {
  const trackCount = Math.ceil(info.trackLength / info.renderWidth);
  const tracks = [...new Array(trackCount)].map((a, i) => i);
  const [playOutro, setPlayOutro] = useState(false);

  useEffect(() => {
    takeoverTimeout(data, onComplete, () => setPlayOutro(true))
  })

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: info.renderWidth,
        overflow: 'hidden',
        height: info.trackHeight * trackCount,
        zIndex: 99,
      }}
    >
      {
        tracks.map((i) => (
          <div
            key={i}
            style={{
              transform: `translateX(${i * -info.renderWidth}px)`,
              height: info.trackHeight,
              width: info.trackLength,
            }}
          >
            <Track
              index={i}
              data={data}
              info={info}
              playOutro={playOutro}
            />
          </div>
        ))
      }
    </div>
  )
}