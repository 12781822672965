import React from 'react';
import { HubGrid } from '../components';
import { useHubGrid } from '../hooks/useHubGrid';
import experiences from '../mocks/experiences.js';

export const ExperiencesHub = ({ data }) => {
	const hubGridProps = useHubGrid({
		rawData: data?.json_data,
		fallback: experiences,
		// TODO: delete this once images are available from hyperlive
		addImages: true,
		template: 'experiences',
	});

	return (
		<HubGrid
			{...hubGridProps}
			titleRatio={0.5}
		/>
	);
};
