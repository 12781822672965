const fontsIndexes = [
    ['NIKE_FUTURA', 'NeuePixelGrotesk', 'HelveticaNeue'],
    ['NeuePixelGrotesk', 'NIKE_FUTURA'],
    ['NeuePixelGrotesk', 'NIKE_FUTURA', 'HelveticaNeue']
  ]
  
  const fontSizesRatio = {
    NIKE_FUTURA: 0.943,
    NeuePixelGrotesk: 1,
    HelveticaNeue: 1
  }
  
  export default class Letter {
    constructor (letter, wordIndex, letterIndex, count, fontStyle) {
      this.value = letter;
      this.wordIndex = wordIndex;
      this.letterIndex = letterIndex;
      const index = Math.min((count - 1), 2);
      this.fonts = fontsIndexes[index];
      this.fontIndex = this.wordIndex;
      this.fontStyle = fontStyle;
    }
  
    setBaseline (baseline) {
      this.baseline = baseline;
    }
  
    setSize (size) {
      this.size = size;
    }
  
    getFont () {
      return this.fonts[this.fontIndex % this.fonts.length];
    }
  
    draw (ctx, cursor) {
      ctx.font = `${this.size}px "${this.fontStyle}"`;
      ctx.fillStyle = "#000000"; 
      ctx.fillText(this.value, cursor, this.baseline);
    }
  }
  