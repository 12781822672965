import React, { CSSProperties } from 'react';
import {motion, Variants} from 'framer-motion';
import { DEFAULT_EASE } from '../constants/transition';

type DirectionArrowAnimation = 'show' | 'hide';

export type DirectionArrowProps = {
    sideLength: number,
    thickness?: number,
    rotation?: number,
    strokeWidth?: number,
    delay?: number,
    exitDelay?: number,
    duration?: number,
    ease?: number[],
    initial?: DirectionArrowAnimation,
    animate?: DirectionArrowAnimation,
    className?: string,
    style?: CSSProperties,
}

const rectVariants: Variants = {
    hide: {
        pathSpacing: 2,
        pathLength: 0,
        pathOffset: 0,
    },
    show: {
        pathSpacing: 2,
        pathLength: 1,
        pathOffset: 0.5,
    },
}
const lineVariants: Variants = {
    hide: {
        pathLength: 0,
    },
    show: {
        pathLength: 1,
    },
}

const lineDurationPercentage = 0.3;

export default function DirectionArrow(props: DirectionArrowProps) {
    const {
        sideLength,
        strokeWidth = 1,
        rotation = 0,
        duration = 1,
        delay = 0,
        exitDelay = 0,
        ease = DEFAULT_EASE,
        initial = 'hide',
        animate = 'show'
    } = props;

    const containerLength = (sideLength + strokeWidth * 2) * 1.5;
    const halfSideLength = sideLength / 2;
    const containerCenter = containerLength / 2 - halfSideLength;

    const lineDuration = duration * lineDurationPercentage;

    let rectDelay: number;
    let lineDelay: number;
    if (animate === 'show') {
        rectDelay = delay;
        lineDelay = delay + duration - lineDuration;
    } else {
        lineDelay = delay;
        rectDelay = lineDelay + lineDuration;
    }

    return (
        <svg
            width={containerLength}
            height={containerLength}
            viewBox={`0 0 ${containerLength} ${containerLength}`}
            className={props.className}
            style={props.style}
        >
            <g transform={`translate(${containerCenter} ${containerCenter}) rotate(${-45 + rotation})`}
               transform-origin={`${halfSideLength} ${halfSideLength}`}
            >
                <motion.rect
                    height={props.sideLength}
                    width={props.sideLength}
                    stroke='black'
                    strokeWidth={strokeWidth}
                    fill='transparent'
                    initial={initial}
                    animate={animate}
                    variants={rectVariants}
                    transition={{
                        delay: rectDelay,
                        duration,
                        ease,
                    }}
                    exit={{
                        pathSpacing: 2,
                        pathLength: 0,
                        pathOffset: 0,
                        transition: {
                            delay: exitDelay + lineDuration,
                            duration: duration,
                            ease
                        }
                    }}
                />
                <motion.line
                    x1={0}
                    y1={props.sideLength}
                    x2={props.sideLength}
                    y2={0}
                    stroke='black'
                    strokeWidth={strokeWidth}
                    initial={initial}
                    animate={animate}
                    variants={lineVariants}
                    transition={{
                        delay: lineDelay,
                        duration: lineDuration,
                        ease,
                    }}
                    exit={{
                        pathLength: 0,
                        transition: {
                            delay: exitDelay,
                            duration: lineDuration,
                            ease
                        }
                    }}
                />
            </g>
        </svg>
    );
}
