import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { LANGUAGES } from "./constants/language";

const resources = {};
export let isReady = false;

Object.values(LANGUAGES).forEach((lang) => {
	resources[lang.CODE] = {
		translation: lang.TRANSLATIONS,
	};
});

const i18nextOptions = {
	resources,
	lng: LANGUAGES[0].CODE,
	fallbackLng: LANGUAGES[0].CODE,
	debug: true,

	interpolation: {
		escapeValue: false,
	},
};

i18n.use(initReactI18next).init(i18nextOptions, () => {
	isReady = true;
});

export default i18n;

