import React, { useEffect, useState } from 'react';
import Codification from '../../../../components/Codification';
import './TextBlock.scss';
import { NIKE_EASE } from '../../../../constants/nikerise';
import { motion, useAnimation } from 'framer-motion';

const DEFAULTS = { duration: 1, delay: 1 }
const DEFAULT_TRANSITION = { ease: NIKE_EASE, duration: 2 }

export const TEXT_SIZES = {
	Body: 'body',
	Heading: 'heading',
	HeadingLarge: 'headingLarge',
	Super: 'super'
}

const STYLES = {
	default: {
		[TEXT_SIZES.Body]: { fontSize: '50px', lineHeight: '44px' },
		[TEXT_SIZES.Heading]: { fontSize: '134px', lineHeight: '123px' },
		[TEXT_SIZES.Super]: { fontSize: '22.2vw', lineHeight: '22.2vw' }
	},
	landscape: {
		[TEXT_SIZES.Body]: { fontSize: '2.60416667vw', lineHeight: '2.29166667vw' },
		[TEXT_SIZES.Heading]: { fontSize: '4.65273125vw', lineHeight: '4.27040625vw' },
		[TEXT_SIZES.HeadingLarge]: { fontSize: '6.97916667vw', lineHeight: '6.40625vw' },
		[TEXT_SIZES.Super]: { fontSize: '22.2vw', lineHeight: '22.2vw' }
	},
	portrait: {
		[TEXT_SIZES.Body]: { fontSize: '2.60416667vh', lineHeight: '2.29166667vh' },
		[TEXT_SIZES.Heading]: { fontSize: '6.97916667vh', lineHeight: '6.40625vh' },
		[TEXT_SIZES.Super]: { fontSize: '22.2vw', lineHeight: '22.2vw' }
	}
}

const TextBlock = ({ text, codify = true, orientation = 'landscape', overflow = 'visible', textMode = 'black', top = 'auto', left = 'auto', bottom = 'auto', right = 'auto', rotate = false, width = 0, height = 0, delay = DEFAULTS.delay, duration = DEFAULTS.duration, type = TEXT_SIZES.Heading }) => {

	const [reverse, setReverse] = useState(false);

	const typeStyle = STYLES[orientation][type]
	const rotation = rotate ? { transform: `rotate(-90deg)`, transformOrigin: 'top left' } : {}

	const animation = useAnimation();

	useEffect(() => {
		setTimeout(() => {
			setReverse(true)
		}, delay * 1000 + duration * 1000)

	}, []);

	useEffect(() => {
		sequence();
	}, []);

	async function sequence() {
		await animation.start({ opacity: 1, transition: { ease: NIKE_EASE, duration: 1, delay: 2 } })
		await animation.start({ opacity: 0, transition: { ease: NIKE_EASE, delay: 5 } })
	}


	return text ? (
		<div className="TextBlock" style={{ top, left, bottom, right, width, height, overflow: 'hidden' }}>

			<div style={rotation}>

				{
					codify ?
						<Codification
							className={`TextBlock-codify mode-${textMode}`}
							text={text}
							typography={{
								primary: true,
								typeStyle: typeStyle,
								tag: 'span',
							}}
							characterSwitchAmount={3}
							characterNextTrigger={2}
							characterTimeout={30}
							start={true}
							delay={delay * 1000}
							reverse={reverse}
						/>
						:

						<motion.div
							className={'TextBlock-fade'} style={typeStyle}
							initial={{ opacity: 0 }}
							animate={animation}
							exit={{ opacity: 0 }}
						>
							{text && <div>{text.split('\n').map((line) => { return <span>{line}</span> })}</div>}
						</motion.div>



				}
			</div>
		</div >
	) : (
		<div />
	);
};

export default TextBlock;
