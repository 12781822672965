import gsap from 'gsap';

import Letter from './letter';

export default class Word {
  constructor (word, index, count, isDate, nonEN) {
    this.word = word;
    this.count = count;
    this.index = index;
    this.isDate = isDate;
    this.direction = ((this.index + 1) % 2);
    this.letters = word?.split('')?.map((l, i) => new Letter(l, this.index, i, count, nonEN));

    this.shouldAnimate = false;
  }

  getChinese() {
    const chineseRegex = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/g;
    let isChinese = chineseRegex.test(this.word);
    return isChinese;
  };

  createTween (duration, delay, repeat=-1) {
    if (this.isDate) return false;

    this.repeat = repeat;

    if (this.tween) {
      this.tween.kill();
      this.tween = null;
    }
    const steps = (this.count > 1) ? 1 : 3;
    // const stagger = (duration / (this.letters?.length || 1)).toFixed(2);

    this.tween = gsap.timeline({
      repeat,
      repeatDelay: delay,
    });
    this.tween.to(this.letters, {
      duration,
      fontIndex: (this.index + steps),
      ease: `steps(${steps})`,
      stagger: {
        amount: duration,
        // each: stagger,
        from: this.direction ? 'start' : 'end'
      }
    });
    this.tween.to(this.letters, {
      duration,
      fontIndex: this.index,
      ease: `steps(${steps})`,
      stagger: {
        amount: duration,
        // each: stagger,
        from: this.direction ? 'end' : 'start'
      }
    });
  }

  setBaseline (baseline) {
    this.letters.forEach(letter => {
      letter.setBaseline(baseline);
    });
  }

  setSize (size) {
    this.letters.forEach(letter => {
      letter.setSize(size);
    });
  }

  setChinese () {
    this.letters.forEach(letter => {
        letter.setChinese(this.getChinese());
    });
  }

  getSize () {
    let width = 0;
    this.letters.forEach(letter => {
      width += letter.getSize();
    });
    return Math.round(width);
  }

  // checkShouldStop () {
  //   if (this.shouldStop) {
  //     this.tween.pause();
  //   }
  // }

  draw (ctx, cursor, shouldAnimate) {
    if (this.tween && shouldAnimate !== this.shouldAnimate) {
      this.shouldAnimate = shouldAnimate;
      if (this.shouldAnimate) {
        this.tween.repeat(this.repeat).play(0);
      } else {
        this.tween.repeat(0);
      }
    }
    this.letters.forEach(letter => {
      letter.draw(ctx, cursor);
      cursor += ctx.measureText(letter.value).width;
      // cursor += ctx.measureText(letter.value).actualBoundingBoxRight;
    })
    return cursor;
  }
}
