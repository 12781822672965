import React, {
    CSSProperties,
    useCallback,
    useMemo,
    useRef
} from 'react';
import ReactHlsPlayer from 'react-hls-player';

import {
    CoverType,
    MediaType,
} from '../shared';


export type MediaRendererProps = {
    media: MediaType;
    cover?: CoverType;
    mediaStyle?: CSSProperties;
    height?: number | string;
}

export default function MediaRenderer(props: MediaRendererProps) {
    const {
        media,
        cover,
        height,
        mediaStyle = {},
    } = props;
    const {
        resource_type,
        blob,
        url,
    } = media;

    const playerRef = useRef();
    const source = useMemo(() => {
        return blob ? URL.createObjectURL(blob) : url;
    }, [media]);

    const style = useCallback((c: string): CSSProperties => ({
        width: '100%',
        height: '100%',
        objectFit: c === 'fit' ? 'scale-down' : 'cover',
        ...mediaStyle
    }), [mediaStyle]);

    switch (resource_type) {
        case 'image':
            return (
                <img
                    src={source}
                    style={style(media.fit ?? cover)} alt={''}
                />
            );

        case 'video':
            return (
                <video
                    style={style('fill')}
                    src={source}
                    muted
                    loop
                    autoPlay
                />
            );

        case 'm3u8-video':
            return (
                <ReactHlsPlayer
                    playerRef={playerRef}
                    src={url}
                    autoPlay={true}
                    controls={false}
                    loop={true}
                    width='100%'
                    height={height ?? 'auto'}
                    muted={true}
                    style={style(cover)}
                />
            );

        default:
            return null;
    }
}
