import {AnimationDataType} from "./types";
import {useMembership} from "./store";
import {useEffect} from "react";
import {useTranslatable} from "./shared";
import MembershipScreen from "./screens/membership";
import '../../../jordan/styles/fonts.scss';
import './style.scss';

export default function Membership({data}: {data: AnimationDataType}) {
    const initialize = useMembership(state => state.initialize);
    const start = useMembership(state => state.start);

    useEffect(() => {
        initialize(data);
        start().then(() => null);
    }, []);

    return (
        <MembershipScreen/>
    )
}