import React from 'react';
import Codification from '../../components/Codification';

const TickerText = ({
	text,
	elementSize,
	height = elementSize * 1.15,
	start = true,
	isStatic,
	language = 'EN',
	rotate = "0"
}) => {
	return text ? (
		<div
			className="TickerText"
			style={{
				transform: `rotate(${rotate})`,
				// border: '1px solid blue',
				// boxSizing: 'border-box'
		}}>
			<Codification
				text={text}
				typography={{
					typeStyle: {
						fontSize: `${language === 'EN' ? elementSize * .85 : elementSize * .8}px`,
						lineHeight: `${height}px`,
					},
					primary: true,
					tag: 'span',
					lineHeight: 'inherit',
				}}
				characterSwitchAmount={4}
				characterNextTrigger={2}
				characterTimeout={20}
				start={start}
				isStatic={isStatic}
			/>
		</div>
	) : (
		<div />
	);
};

export default TickerText;
