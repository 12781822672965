import React from "react";
import Media from "../components/Media";
import {MotionConfig} from "framer-motion";

export default function StoreTakeover({media}) {
    return (
        <Media
            preventTransitions
            media={media}
            width={'100%'}
            height={'100%'}
            useInternalTiming
            transitionDuration={0}
        />
    )
}
