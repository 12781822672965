.overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .row {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: space-around;
    }

    .square {
        position: relative;

        .overlay-icon {
            width: 100%;
            height: 100%;

            &.display {
                display: block;
            }

            &.hide {
                display: none;
            }
        }
    }
}
