import {fetchStories, SNKRS_CAL_CONFIG} from "../../hoi/animations/snkrs-calendar/js/dataService";
import {MediaType} from "../shared";

interface LoadStoriesProps {
    marketplace: string;
    include_videos: boolean;
    search: {
        includes?: Array<string>;
    };
}

export function loadStories({marketplace = 'US', include_videos = false, search = {}}: LoadStoriesProps) {
    const config = SNKRS_CAL_CONFIG[marketplace];
    const {includes = []} = search;

    return new Promise((resolve, reject) => {
        fetchStories({
            ...config,
            include_videos,
            includes,
            onComplete: ({drops, inStockMode}) => {
                const hydrated = drops.map((drop) => {
                    return {
                        ...drop,
                        images: drop.images.map((image): MediaType => {
                            return {
                                resource_type: image.subType,
                                url: image.url,
                            }
                        }).filter(i => i.resource_type === 'image')?.slice(0, 4)
                    }
                });
                resolve(hydrated.slice(0, 6));
            },
        });
    });
}