import './snkrsGrid.scss'
import ColorThief from 'colorthief';

export default function SnkrsGrid (content) {
    const wrapper = document.createElement('div');
 
    wrapper.classList.add('snkrs-grid');
    let data = content;
    const colorThief = new ColorThief();
    let colors = [];
    let colorsHex = [];
    
    
    const refreshGrid = (newContent) => {
      wrapper.innerHTML = '';
      data = newContent;
      localStorage.removeItem('snkrCalColors');
      createItem();
    };

    const createItem = () => {
      data.forEach((item, index) => {
            let newItem = document.createElement('div');
            newItem.classList.add('snkrs-item');
            let size = data.length <= 8 ? 'small' : 'large';
            newItem.classList.add(`snkrs-item-${size}`);
            newItem.appendChild(createText(item));
       
            let image = createImage(item.images[0].url, newItem, index)
            wrapper.appendChild(newItem);
      });
    }

    const createImage = (url, item, index) => {
      let img = document.createElement('img');
        const newImg = new Image();
        newImg.src = url;
        newImg.crossOrigin = 'Anonymous';
        newImg.addEventListener('load', (e) => {
          img.src = newImg.src;
          img.crossOrigin = 'Anonymous';
          item.appendChild(img);
          
          getColors(newImg);
          item.appendChild(createSwatches(index));
        });
    }

    const getColors = (item) => {
      let color = colorThief.getPalette(item, 4);
      colors.push(color);
      localStorage.setItem("snkrCalColors", JSON.stringify(colors));
    }

    const createText = (item) => {
      let textBlock = document.createElement('div');
      textBlock.classList.add('snkrs-textblock');
      let name = document.createElement('h3');
      name.innerHTML += item.fulltitle;
      textBlock.appendChild(name);
      textBlock.appendChild(createDropTimes(item));
      return textBlock;
    }

    const createDropTimes = (item) => {
      let dropTime = document.createElement('div');
      dropTime.classList.add('snkrs-droptime');
      let name = document.createElement('h3');
  
      let newTimeX = new Date(item.startEntryDate);
      let nowX = new Date();
      let diffDateX =  Math.round((newTimeX - nowX)/(1000*60*60*24));
      if(diffDateX <= 1 && diffDateX > -2) {
        let timer = setInterval(() => {
          name.innerHTML = showRemaining(newTimeX, timer);
        }, 10);
       
      } else {
        name.innerHTML += formatTime(item.startEntryDate);
      }

     
      dropTime.appendChild(name);
      return dropTime;
    }

    const addMask = () => {
      wrapper.style.clipPath = `ellipse(${window.innerWidth/4}px ${window.innerWidth/4}px at ${window.innerWidth/2}px ${window.innerHeight/2}px)`;
    }

    const componentToHex = (c) => {
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    }

    const rgbToHex = (r, g, b) => {
      return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    const showRemaining = (end, timer) => {
      let now = new Date();
      let _second = 1000;
      let _minute = _second * 60;
      let _hour = _minute * 60;
      let _day = _hour * 24;
  
      let distance = end - now;

 
      if (distance < 0) {
        clearInterval( timer ); 

        return 'TODAY';
      }
      let hours = Math.floor( (distance % _day) / _hour );
      let minutes = Math.floor( (distance % _hour) / _minute );
      let seconds = Math.floor( (distance % _minute) / _second );
      let milliseconds = String(distance % _second).slice(0, 2);
      return `${("0" + hours).slice(-2)}:${("0" + minutes).slice(-2)}:${("0" +  seconds).slice(-2)}:${milliseconds}`;
   }

    const formatTime = (time) => {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

      let newTime = new Date(time);
      let now = new Date();
      let diffDate =  Math.round((newTime - now)/(1000*60*60*24));
      let timeText = '';

      if(diffDate <= -2) {
        timeText = '';
      } else {

    

      if(diffDate >= 1 && diffDate < 6) {
        let hours = newTime.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
        });
        timeText = `${days[newTime.getDay()]} ${hours}`;
      } else {
        let hours = newTime.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
        });
        timeText = `${monthNames[newTime.getMonth()]} ${newTime.getDate()}, ${hours}`;
      }
    }
      return timeText;
    }

    const createSwatches = (topIndex) => {
      let swatchItem = document.createElement('ul');
      swatchItem.classList.add('swatch-list-snkrs')
      const totalSwatches = 3;
      for (let index = 0; index < totalSwatches; index++) {
        let listItem = document.createElement('li');
        listItem.classList.add('swatch-item')
        let swatchColor = colors.length > 0 ? colors[topIndex][index] : '';
        if(index == 1) {
          listItem.style.backgroundColor = 'white';
        } else {
          listItem.style.backgroundColor = rgbToHex( swatchColor[0],  swatchColor[1],  swatchColor[2]);
        }

        colorsHex.push(rgbToHex(swatchColor[0],  swatchColor[1],  swatchColor[2]))
        swatchItem.appendChild(listItem);
      }
      return swatchItem;
    }

    

    

    const getWrapper = () => {
      return wrapper;
    }

    const getSwatchColors = () => {
      return colors;
    }

  createItem();
  addMask();

  return {getWrapper, getSwatchColors};
}
