#pragma glslify: box = require('../../../../lib/glsl/box.glsl')
#pragma glslify: mosaic = require('../../../../lib/glsl/mosaic.glsl')
#pragma glslify: viewfinder = require('../../../../lib/glsl/viewfinder.glsl')

#pragma glslify: containUV = require('../../../../lib/glsl/containUV.glsl')
#pragma glslify: pixelateUV = require('../../../../lib/glsl/pixelateUV.glsl')
#pragma glslify: placedUV = require('../../../../lib/glsl/placedUV.glsl')
#pragma glslify: zoomedUV = require('../../../../lib/glsl/zoomedUV.glsl')
#pragma glslify: gammaCorrect = require('../../../../lib/glsl/gammaCorrect.glsl')

#ifdef GL_ES
precision mediump float;
#endif

varying vec2 vUv;

uniform float time;
uniform vec2 res; // res
uniform vec2 screenRatio; // screen ratio
uniform float pixa; // pixelization amount (size of each cell/pixel)
uniform float pixr; // pixel ratio
uniform float stroke; // stroke width

// mosaic
uniform float mosaicAmount; // how much mosaic is covering the screen (0-1)
uniform float mosaicColor; // greyscale color

uniform sampler2D img1;
uniform vec2 img1Res;
uniform vec2 img1Pos;
uniform vec2 img1Dim;
uniform vec2 img1Offset;
uniform float img1Zoom;

vec3 griddedImage (vec3 color, sampler2D img, vec2 imgRes, vec2 pos, vec2 dim, vec2 offset, float zoom, float pr, float px, float thickness) {
  vec2 uv = containUV(vUv, res, imgRes);
  zoom /= (imgRes.y / imgRes.x);
  uv = zoomedUV(uv, imgRes, zoom, offset);
  uv = pixelateUV(uv, px, (imgRes.x / imgRes.y));

  float b = box(gl_FragCoord.xy - pos, res);
  if ((uv.x > 1. || uv.x < 0.) || (uv.y > 1. || uv.y < 0.)) {
    b = 0.0;
  }
  color = mix(color, texture2D(img, uv).rgb, b);

  return color;
}

void main() {
  vec3 color = vec3(1.0, 1.0, 1.0);
  // vec3 color = vec3(0.98, 0.98, 0.964);

  vec2 st = (gl_FragCoord.xy / res) / pixr;
  float ar = (res.x / res.y);
  float px = ((res.x * pixr) / pixa);

  float thickness = (stroke * pixr);
  thickness *= screenRatio.x;
  float thickdiff = (thickness / pixr / 100.);
  
  color = griddedImage(color, img1, (img1Res * pixr), (img1Pos * pixr), ((img1Dim - thickness) * pixr), img1Offset, img1Zoom, pixr, px, thickness);

  color = mix(color, vec3(mosaicColor), mosaic(st, ar, time, mosaicAmount));
  gl_FragColor = vec4(gammaCorrect(color.rgb,2.2), 1.0);
}
