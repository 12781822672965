import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    motion,
    AnimatePresence,
    Variants,
} from 'framer-motion';

import * as cssClassNames from '../styles/screens/Carousel.module.scss';

import {
    DEFAULT_EASE,
    getMediaDuration,
    MediaType,
    MultiLangText,
    secToMs,
    setAnimationTimeout
} from '../shared';
import { BaseChapterProps } from '../components/ChapterRenderer';
import GridWithCells from '../components/GridWithCells';
import MediaRenderer from '../components/MediaRenderer';
import
    CodificationWithTranslations,
    {
        TranslationCodificationProps,
        calculateDuration as calculateCodificationDuration
    }
from '../components/Codification/CodificationWithTranslations';
import classNames from 'classnames';
import { DEFAULT_DURATION_S } from '../constants/transition';


const itemTransitionDuration = DEFAULT_DURATION_S;
const zoomItemDuration = DEFAULT_DURATION_S;
const pause = 4;
const wipeTextDuration = DEFAULT_DURATION_S;

const cellLength = 68;
const gridYOffset = -2;
const itemHeightFactor = 1326 / 1920;
const itemWidthFactor = 884 / 1080;
const topFocusOffsetFactor = 297 / 1920;
const listVariants: Variants = {
    initial: ({amount, itemHeight}) => ({
        transform: `translateY(${-amount * itemHeight}px)`,
    }),
    end: ({amount, itemHeight}) => ({
        transform: `translateY(${amount * itemHeight}px)`,
    }),
    slide: ({index, gap, itemHeight, topOffset}) => ({
        transform: `translateY(${-index * (itemHeight + gap) + topOffset}px)`,
    }),
    focus: ({index, gap, itemHeight}) => ({
        transform: `translateY(${-index * (itemHeight + gap)}px)`,
    }),
};
const carouselItemSequence = ['slide', 'focus', 'slide'];

export type CarouselListItemProps = {
    media?: MediaType,
    description?: MultiLangText,
};

export type CarouselProps = BaseChapterProps & {
    list: CarouselListItemProps[],
};

export default function Carousel(props: CarouselProps) {
    const {
        list,
        languages,
        height,
        width,
    } = props;

    const rows = Math.ceil((height - gridYOffset) / cellLength);
    const cols = Math.ceil(width / cellLength) + 1;
    const gridWidth = cols * cellLength;
    const gridHeight = rows * cellLength;
    const gridXOffset = -(gridWidth - width) / 2
    const itemHeight = height * itemHeightFactor;
    const itemWidth = width * itemWidthFactor;

    const itemsContainerRef = useRef();
    const [gapBetweenItems, setGapBetweenItems] = useState(0);
    const [itemSequenceIndex, setItemSequenceIndex] = useState(-1)
    const [activeIndex, setActiveIndex] = useState(-1);
    const [nextActiveIndex, setNextActiveIndex] = useState(0);
    const itemVariants = useMemo(() => ({
        small: {
            height: `${itemHeight}px`,
            width: `${itemWidth}px`,
            gridTemplateRows: '100% auto',
        },
        big: {
            height,
            width,
        },
        withDescription: {
            gridTemplateRows: '80% auto'
        },
    }), [height, width]);

    const items = useMemo(() => list.map((item, index) => {
        const isActive = activeIndex === index;

        return (
            <CarouselItem
                key={index}
                languages={languages}
                {...item}
                width={width}
                variants={itemVariants}
                isActive={isActive}
                onAnimationComplete={() => {
                    setActiveIndex(-1);

                    if (nextActiveIndex >= items.length - 1) {
                        return;
                    }

                    setAnimationTimeout(() => setNextActiveIndex(i => ++i), secToMs(zoomItemDuration));
                }}
            />
        );
    }), [list, nextActiveIndex, activeIndex]);

    useEffect(() => {
        if (!itemsContainerRef.current) {
            return;
        }

        const gap = window.getComputedStyle(itemsContainerRef.current).gap;
        setGapBetweenItems(parseFloat(gap));
    }, [itemsContainerRef.current]);

    useEffect(() => {
        if (nextActiveIndex === activeIndex) {
            return;
        }

        return setAnimationTimeout(() => {
            setActiveIndex(nextActiveIndex);
        }, secToMs(itemTransitionDuration));
    }, [nextActiveIndex]);

    useEffect(() => {
        if (activeIndex !== -1) {
            return;
        }

        setItemSequenceIndex(i => ++i);
    }, [activeIndex]);

    const onItemsContainerAnimationComplete = useCallback((label: string) => {
        if (label === 'focus') {
            return;
        }

        setItemSequenceIndex(i => (++i % carouselItemSequence.length));
    }, []);

    return (
        <div className={cssClassNames.Carousel}>
            <motion.div
                ref={itemsContainerRef}
                className={cssClassNames.list}
                initial='initial'
                animate={carouselItemSequence[itemSequenceIndex]}
                exit='end'
                variants={listVariants}
                custom={{
                    itemHeight,
                    topOffset: topFocusOffsetFactor * height,
                    amount: items.length,
                    gap: gapBetweenItems,
                    index: nextActiveIndex,
                }}
                transition={{
                    duration: itemTransitionDuration,
                    ease: DEFAULT_EASE,
                }}
                onAnimationComplete={onItemsContainerAnimationComplete}
            >
                {items}
            </motion.div>
            <div
                style={{
                    top: `${gridYOffset}px`,
                    left: `${gridXOffset}px`,
                }}
            >
                <GridWithCells
                    height={gridHeight}
                    width={gridWidth}
                    cols={cols}
                    rows={rows}
                    cellLength={cellLength}
                />
            </div>
        </div>
    );
}

const codificationCharDuration = 0.03;
const characterSwitchAmount = 2;
const characterNextTrigger = characterSwitchAmount - 1;
const languageTransitionDelay = 0.25;
const codificationProps: TranslationCodificationProps = {
    characterSwitchAmount,
    characterNextTrigger,
    timingConfig: {
        control: 'character',
        duration: codificationCharDuration,
    },
};


type ItemProps = CarouselListItemProps & {
    languages: string[],
    width: number,
    variants: Variants,
    isActive?: boolean,
    onAnimationComplete?: () => void,
}

function CarouselItem(props: ItemProps) {
    const {
        media,
        description,
        languages,
        width,
        variants,
        isActive = false,
        onAnimationComplete,
    } = props;

    const [langIndex, setLangIndex] = useState(0);
    const [nextLangIndex, setNextLangIndex] = useState(0);
    const [toShowDescription, setToShowDescription] = useState(false);
    const [animate, setAnimate] = useState<string | string[]>();

    const descriptionComponent = useMemo(() => {
        return description ? (
            <CodificationWithTranslations
                text={description}
                languages={languages}
                langIndex={langIndex}
                ease={DEFAULT_EASE}
                codificationProps={codificationProps}
                runFinalTextAnimation
                languageTransitionDelay={languageTransitionDelay}
                onTyped={() => setNextLangIndex(i => i + 1)}
            />
        ) : null;
    }, [description, langIndex])

    useEffect(() => {
        if (!isActive) {
            setAnimate('small');
            return;
        }

        setAnimate(description ? ['big', 'withDescription'] : 'big');

        return setAnimationTimeout(() => {
            setToShowDescription(true);
        }, secToMs(zoomItemDuration));
    }, [isActive]);

    useEffect(() => {
        if (!toShowDescription || description) {
            return;
        }

        const duration = media && getMediaDuration(media) || pause;
        return setAnimationTimeout(() => {
            onAnimationComplete?.();
        }, secToMs(duration));
    }, [toShowDescription])

    useEffect(() => {
        if (nextLangIndex === langIndex) {
            return;
        }

        const duration = media && getMediaDuration(media) || pause;
        return setAnimationTimeout(() => {
            if (nextLangIndex >= languages.length) {
                setToShowDescription(false);
            } else {
                setLangIndex(nextLangIndex)
            }
        }, secToMs(duration));
    }, [nextLangIndex]);

    return (
        <motion.div
            className={classNames(cssClassNames.CarouselItem, 'item')}
            initial='small'
            animate={animate}
            variants={variants}
            transition={{
                duration: zoomItemDuration,
                ease: DEFAULT_EASE,
            }}
        >
            {media ? (
                <div>
                    <MediaRenderer media={media}/>
                </div>
            ) : null}
            <AnimatePresence onExitComplete={onAnimationComplete}>
                {description && toShowDescription ? (
                    <motion.div
                        initial={{ clipPath: 'inset(0% 0% 0% 0%)' }}
                        animate={{
                            x: ['100%', '0%'],
                            transition: {
                                duration: DEFAULT_DURATION_S,
                                ease: DEFAULT_EASE,
                            }
                        }}
                        exit={{ clipPath: 'inset(0% 100% 0% 0%)' }}
                        transition={{
                            duration: wipeTextDuration,
                            ease: DEFAULT_EASE,
                        }}
                    >
                        <AnimatePresence>
                            <div
                                className={cssClassNames.description}
                                style={{
                                    width: `${width}px`,
                                }}
                            >
                                {descriptionComponent}
                            </div>
                        </AnimatePresence>
                    </motion.div>
                ) : null}
            </AnimatePresence>
        </motion.div>
    );
}

export function calculateDuration(data: CarouselProps) {
    const {
        list,
        languages,
    } = data;
    const itemTransitionsDuration = itemTransitionDuration * list.length;

    const itemsDuration = list.reduce((result, data) => result + calcItemDuration(data, languages), 0);
    const total = itemTransitionsDuration + itemsDuration;

    const duration = secToMs(total) + calculateExitDuration(data);

    return duration;
}

function calcItemDuration(data: CarouselListItemProps, languages: string[]) {
    const {
        description,
        media,
    } = data;

    let textDuration = 0;
    let mediaDurationFactor = 1;
    if (description) {
        textDuration = calculateCodificationDuration({
            text: description,
            languages,
            codificationCharDuration,
            characterSwitchAmount,
            characterNextTrigger,
            languageTransitionDelay,
        });

        mediaDurationFactor *= languages.length;
    }

    const mediaDuration = media && getMediaDuration(media) || pause;

    const duration = zoomItemDuration * 2 + textDuration + mediaDuration * mediaDurationFactor + wipeTextDuration;

    return duration;
}

export function calculateExitDuration(data: CarouselProps) {
    return secToMs(itemTransitionDuration);
}
