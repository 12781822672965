import Typography from "../../jordan/components/Typography";
import {useMemo} from "react";
import {get} from "lodash";
import {useWeatherSingleLine} from "../../../utils/weather";
import {useCity} from "../shared";

export default function ProgressLocation() {
    const city = useCity();

    const date = useMemo(() => {
        // get a string of the current month but shortened to 3 letters
        const month = new Date().toLocaleString('default', {month: 'short'});
        // day of the month
        const day = new Date().getDate();
        return `${month} ${day}`.toLowerCase()
    }, []);

    const {weather, loading} = useWeatherSingleLine();

    return (
        <div>
            <Typography
                variant={'brut'}
                size={36}
                style={{textAlign: 'right', lineHeight: '120%'}}
                color={'currentColor'}
            >
                {city.toLowerCase()}{'   '}{date}<br/>
                {weather}
            </Typography>
        </div>
    )
}