import { imagesUrlService, videosUrlService } from "../../../../utils/assets-service";

export default {
  animation: {
    json_data: {
      // type: 'low',
      chapters: {
        low: {
          assets: [{
            url: videosUrlService.getUrl('AJ_1_Mid_white_LONG_v08_1600x800_CONFORM.mp4', { folderName: 'live/grid' })
          }]
        },
        type: {
          assets: [{
            url: imagesUrlService.getUrl('DUNK.png', { folderName: 'live/type' })
          }]
        },
        "gridded-imagery": {
          assets: [{
            keyframe1: [{
              assetUrl: imagesUrlService.getUrl('FullHD_HO21_ACG_FIELDGUIDE_W_LOOK12_0610.jpg', { folderName: 'live/gridded-imagery' })
            }, {
              assetUrl: imagesUrlService.getUrl('FullHD_HO21_ACG_FIELDGUIDE_W_LOOK12_0590.jpg', { folderName: 'live/gridded-imagery' })
            }, {
              assetUrl: imagesUrlService.getUrl('FullHD_HO21_ACG_FIELDGUIDE_W_LOOK12_0572.jpg', { folderName: 'live/gridded-imagery' })
            }],
            keyframe2: [{
              assetUrl: imagesUrlService.getUrl('FullHD_HO21_ACG_FIELDGUIDE_W_LOOK12_0570-2.jpg', { folderName: 'live/gridded-imagery' })
            }],
            keyframe3: [{
              assetUrl: imagesUrlService.getUrl('FullHD_HO21_ACG_FIELDGUIDE_W_LOOK12_0560-2.jpg', { folderName: 'live/gridded-imagery' })
            }, {
              assetUrl: imagesUrlService.getUrl('Full_HD_ACG_UPDATE.jpg', { folderName: 'live/gridded-imagery' })
            }]
          }]
        },
        view_finder: {
          assets: [{
            url: imagesUrlService.getUrl('Medium_HO21_ACG_ICELAND_HERO_324461-D1-9-011-053.jpg', { folderName: 'live/viewfinder' })
          }, {
            url: imagesUrlService.getUrl('Medium_HO21_ACG_ICELAND_ALT_324461-D1-17-003-003A.jpg', { folderName: 'live/viewfinder' })
          }]
        },
        mannequin: {
          assets: [{
            url: imagesUrlService.getUrl('FullHD_HO21_ACG_FIELDGUIDE_M_LOOK05_0220_ONWHITE.png', { folderName: 'live/mannequin' })
          }, {
            url: imagesUrlService.getUrl('FullHD_HO21_ACG_FIELDGUIDE_M_LOOK05_0223_ONWHITE.png', { folderName: 'live/mannequin' })
          }],
          // scc: [{
          //   assetUrl: 'https://res.cloudinary.com/jbboerner/image/upload/v1644849999/live/mannequin/FullHD_HO21_ACG_FIELDGUIDE_M_LOOK05_0220_ONWHITE.png'
          // }, {
          //   assetUrl: 'https://res.cloudinary.com/jbboerner/image/upload/v1644849999/live/mannequin/FullHD_HO21_ACG_FIELDGUIDE_M_LOOK05_0223_ONWHITE.png'
          // }]
        },
        pixel_cutout: {
          background: {
            assets: [{
              url: imagesUrlService.getUrl('Medium_HO21_ACG_ICELAND_CAST_ALTS_324461-D1-15-029-029A.jpg', { folderName: 'live/pixel-cutout' })
            }]
          },
          primary: {
            assets: [{
              assetUrl: imagesUrlService.getUrl('FullHD_HO21_ACG_FIELDGUIDE_M_LOOK05_0251.jpg', { folderName: 'live/pixel-cutout' })
            }, {
              assetUrl: imagesUrlService.getUrl('Medium_HO21_ACG_FIELDGUIDE_M_LOOK05_0238.jpg', { folderName: 'live/pixel-cutout' })
            }]
          }
        },
        contact: {
          assets: [{
            url: imagesUrlService.getUrl('contact-sheet-0.jpg', { folderName: 'live/contact-sheet' })
          }, {
            url: imagesUrlService.getUrl('contact-sheet-1.jpg', { folderName: 'live/contact-sheet' })
          }, {
            url: imagesUrlService.getUrl('contact-sheet-2.jpg', { folderName: 'live/contact-sheet' })
          }, {
            url: imagesUrlService.getUrl('contact-sheet-3.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-4.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-5.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-6.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-7.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-8.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-9.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-10.jpg', { folderName: 'live/contact-sheet' })
		  }, {
			  url: imagesUrlService.getUrl('contact-sheet-11.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-12.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-13.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-14.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-15.jpg', { folderName: 'live/contact-sheet' })
		  }, {
			  url: imagesUrlService.getUrl('contact-sheet-16.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-17.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-18.jpg', { folderName: 'live/contact-sheet' })
		  }, {
			  url: imagesUrlService.getUrl('contact-sheet-19.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-20.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-21.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-22.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-23.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-24.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-25.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-26.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-27.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-28.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-29.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-30.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-31.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-32.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-33.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-34.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-35.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-36.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-37.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-38.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-39.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-40.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-41.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-42.jpg', { folderName: 'live/contact-sheet' })
          }, {
			  url: imagesUrlService.getUrl('contact-sheet-43.jpg', { folderName: 'live/contact-sheet' })
          }]
        }
      },
    }
  }
}
