import { Text } from '@react-three/drei';
import * as THREE from 'three';
import { Content, TickerProps } from './type';
import React, { useEffect, useRef, useState } from 'react';
import {
  calcTextBound,
  fonts,
  getImageSize,
  getNumber,
  getSVGSize,
  svgs,
} from './shared';
import { useFrame } from '@react-three/fiber';
import RotatedSvg from './components/RotatedSvg';
import { MediaType } from '../../../../jordan/shared';
import { SVGResult } from 'three/examples/jsm/loaders/SVGLoader';
import { customMaterial, customTextMaterial } from './materials';

const CircleTicker = ({ data }: TickerProps) => {
  const [tickerTexts, setTickerTexts] = useState<JSX.Element[]>();
  const groupRef = useRef<THREE.Group>(null);

  useEffect(() => {
    const setupTicker = async () => {
      const circumference = data.radius * 2 * Math.PI;
      const lineHeight = getNumber(data.height, 32);
      const letterSpacing = data.letterspacing;
      let sectors = 0;
      const tickerContent: Content[] = [];
      const squash = data.squash || 1;
      let wordSpacing = getNumber(data.spacing) * squash;
      const fontProps = {
        lineHeight,
        fontSize: lineHeight,
        letterSpacing,
        font: fonts[data.font as string],
        color: data.color || 'white',
      };

      for await (const content of data.content) {
        if (content.type === 'icon') {
          const svgResult = await getSVGSize(svgs[content.content.toString()]);
          const scaleRatio = lineHeight / svgResult.size.height;
          tickerContent.push({
            type: content.type,
            width: svgResult.size.width * scaleRatio,
            scale: scaleRatio,
            content: svgResult.svg,
          });
        } else if (content.type === 'text') {
          //get font without scale
          let visibleBounds = await calcTextBound(
            content.content.toString(),
            lineHeight,
            lineHeight,
            letterSpacing,
            fonts[data.font as string],
          );
          let textWidth = visibleBounds[2] - visibleBounds[0];
          tickerContent.push({
            type: content.type,
            width: textWidth * squash,
            content: content.content,
          });
        } else if (content.type === 'image') {
          const media = content.content as MediaType;
          const size = await getImageSize(media.url);
          const scaleRatio = lineHeight / size.height;
          tickerContent.push({
            type: content.type,
            width: size.width * scaleRatio,
            content: content.content,
            preserve_colors: content.preserve_colors,
          });
        } else {
          Promise.resolve();
        }
      }
      let totalSectorSize = tickerContent.reduce(
        (acc, content) => acc + Number(content.width) + wordSpacing,
        0,
      );
      sectors = Math.floor(circumference / totalSectorSize);
      const sectorLength = circumference / sectors;
      const sectorMod = circumference % totalSectorSize;
      const sectorMod2 = sectorMod / sectors;
      wordSpacing += sectorMod2 / data.content.length;

      const textLengths = Array.from({
        length: sectors,
      }).map((_, idx: number) => {
        let contentOffset = 0;
        return (
          <group key={idx}>
            {tickerContent.map((content, idx2) => {
              if (content.type === 'text') {
                const angleOffset =
                  2 * Math.PI * (contentOffset / circumference);

                const mat = customTextMaterial(
                  data.radius,
                  sectorLength,
                  angleOffset,
                  sectors,
                  idx,
                  squash
                );
                contentOffset += Number(content.width) + wordSpacing;
                return (
                  <Text
                    glyphGeometryDetail={4}
                    anchorX={'left'}
                    anchorY={'middle'}
                    position={[0, 0, 0]}
                    key={`key${idx}-${idx2}`}
                    material={mat}
                    {...fontProps}
                    children={content.content.toString()}
                  />
                );
              } else if (content.type === 'icon') {
                const angleOffset =
                  2 *
                  Math.PI *
                  ((contentOffset + Number(content.width) / 2) / circumference);
                const angle = (2 * Math.PI * idx) / sectors;
                const r2 = data.radius;
                const x = -r2 * Math.cos(angle + angleOffset);
                const y = r2 * Math.sin(angle + angleOffset);
                contentOffset += Number(content.width) + wordSpacing;
                return (
                  <RotatedSvg
                    key={`key${idx}-${idx2}`}
                    x={x}
                    y={y}
                    scale={content.scale as number}
                    svg={content.content as SVGResult}
                    color={data.color}
                    radius={0}
                    angle={-angle - angleOffset + Math.PI / 2}
                  />
                );
              } else if (content.type === 'image') {
                const angleOffset =
                  2 *
                  Math.PI *
                  ((contentOffset + Number(content.width) / 2) / circumference);
                const media = content.content as MediaType;
                const mat = customMaterial(
                  data.radius,
                  sectorLength,
                  angleOffset,
                  sectors,
                  idx,
                  1,
                  media.url,
                  new THREE.Color(content.preserve_colors ? undefined : data.color),
                );
                contentOffset += Number(content.width) + wordSpacing;
                return (
                  <mesh
                    key={`key${idx}-${idx2}`}
                    position={[0, 0, 0]}
                    material={mat}
                  >
                    <planeGeometry
                      attach="geometry"
                      args={[
                        Number(content.width),
                        lineHeight,
                        Number(content.width) / 4,
                        Number(content.width) / 4,
                      ]}
                    />
                  </mesh>
                );
              } else {
                return null;
              }
            })}
          </group>
        );
      });
      setTickerTexts(textLengths);
    };
    setupTicker();
  }, [data]);

  useFrame(() => {
    if (groupRef.current) {
      groupRef.current.rotation.z -= data.speed / 1000;
    }
  });

  return (
    <group ref={groupRef} key={'ticker group'}>
      {tickerTexts}
    </group>
  );
};

export default CircleTicker;
