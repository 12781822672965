if (module.hot) {
    module.hot.accept(() => {
      location.reload()
    })
  }

  import gsap from 'gsap';
  import Text from '../../../life365/L365-Text'
  import '../../../life365/L365.scss'
  class Messaging {
    constructor (name, datas, onComplete, index) {
      this.id = `${name}-${index}`;
      this.name = name;
      this.datas = datas;
      this.wrapper = document.createElement('div');
      this.wrapper.id = this.id;
      this.onComplete = onComplete;
      this.timeline = gsap.timeline({ paused: true, onComplete: () => {
        this.onComplete();
        this.wrapper.style.display = 'none';
        this.wrapper.classList.remove('active');
      } });

      this.chap = new Text(
        datas = {
          duration: 4,
          type: 'MESSAGING',
          index,
          heading: {'EN': this.datas.body},
          show_view_finder: true,
          top_right: {'EN': this.datas.credit},
          heading_style: 'helvetica',
          logo: this.datas.logo,
          use_swatches: true,
          color_0: this.datas.color_0,
          color_1: this.datas.color_1,
          color_2: this.datas.color_2,
          isCatalog: true

      }, 5.5, 'EN', true);
      this.chap.preTexture('grid')
      this.timeline.add(() => {
        this.chap.start();
      });
      this.timeline.add(() => {}, (8));
    }

      start () {
        this.wrapper.style.display = 'block';
        this.timeline?.seek(0);
        this.timeline.play();
        this.running = true;

      }
      stop () {
        this.running = false;
        this.timeline?.pause();
        this.timeline?.seek(0);
        this.wrapper.style.display = 'none';
      }
      pause () {
        this.running = false;
        this.timeline?.pause();
      }
      resume () {
        this.timeline?.resume();
        this.timeline?.seek(0);
        this.running = true;
      }

  }
  export default Messaging;
  