import React, { CSSProperties } from 'react';
import {color, getColor} from '../styles/color';

const fontTypes = {
    'helvetica-neue-hoi': {
        fontFamily: 'helvetica-neue-hoi',
        fontWeight: 500,
        letterSpacing: '-3.33px',
    },
};

export interface TypographyProps {
    children: any;
    variant: 'helvetica-neue-hoi';
    color?: color | string;
    className?: string;
    style?: CSSProperties;
}

export default function Typography({
    children,
    variant,
    color = 'black',
    className,
    style,
}: TypographyProps) {
    return (
        <span className={className} style={{
            whiteSpace: 'break-spaces',
            ...fontTypes[variant] ?? {},
            color: color ? getColor(color) : undefined,
            ...style,
        }}>
            {children}
        </span>
    )
}