import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { transformImage } from '../../../../utils/image';
import Codification from '../../../../components/Codification';
import './PodIntroLogo.scss';
import { KeyframeManager, KEYFRAME_STATUS } from '../../../../risegrid/utils';
import { NIKE_EASE } from '../../../../constants/nikerise';

const VisibleKeyframes = ['IntroResolve', 'IntroOutro']

const Keyframe = new KeyframeManager(VisibleKeyframes);

const PodIntroLogo = ({ text, grids, frame, logo_asset }) => {

    const keyframe_status = Keyframe.status(frame);

    const [start, setStart] = useState(true)

    const { index: frameIndex, name: frameName } = frame;

    // const [dims, setDims] = useState({ width: 0, height: 0, left: 0, top: 0 })


    const [dims, setDims] = useState({ width: '1080px', height: '480px', left: 0, top: '1440px' })



    // const [dims, setDims] = useState({ width: '100vw', height: '25vh', left: 0, top: '50vh' })

    // useEffect(() => {
    //     grids.masks[0].addCallback('Logo', (params) => {
    //         // console.log('[PodIntroLogo] dims', params)
    //         setDims({
    //             top: params.y,
    //             left: params.x,
    //             width: params.width,
    //             height: params.height
    //         })
    //     })

    // }, [])

    // useEffect(() => {
    //     if (!Keyframe.isPlaying(frame)) {
    //         setDims({ width: 0, height: 0, left: 0, top: 0 })
    //     }
    // }, [frame])



    // const show = VisibleKeyframes.includes(frameName);

    // const intro = frameName === VisibleKeyframes[0];

    // const outro = frameName === VisibleKeyframes[VisibleKeyframes.length - 1];




    return <div className="PodIntroLogo" style={{ ...dims, overflow: 'hidden', position: 'absolute' }}>

        {/* {VisibleKeyframes.includes(frameName) && */}

        <div className={'PodIntroLogo-split-left'}>
            <motion.div
                style={{ width: "100%", height: "100%", overflow: 'hidden', position: 'absolute' }}
                initial={{
                    translateY: '-100%',
                }}
                animate={{
                    translateY: keyframe_status == KEYFRAME_STATUS.BEGIN ? '0%' : keyframe_status == KEYFRAME_STATUS.END ? '100%' : keyframe_status == KEYFRAME_STATUS.PLAYING ? '0%' : '-100%',
                }}
                transition={{ ease: NIKE_EASE, duration: 1 }}
            >
                <img src={logo_asset.url} />
            </motion.div>
        </div>
        <div className={'PodIntroLogo-split-right'}>
            <motion.div
                style={{ width: "100%", height: "100%", overflow: 'hidden', position: 'absolute' }}
                initial={{
                    translateY: '100%',
                }}
                animate={{
                    translateY: keyframe_status == KEYFRAME_STATUS.BEGIN ? '0%' : keyframe_status == KEYFRAME_STATUS.END ? '-100%' : keyframe_status == KEYFRAME_STATUS.PLAYING ? '0%' : '100%',
                }}
                transition={{ ease: NIKE_EASE, duration: 1 }}
            >

                <img src={logo_asset.url} />


            </motion.div>
        </div>


        {/* } */}
    </div>

};

export default PodIntroLogo;
