// React
import React from 'react';
import ReactDOM from 'react-dom/client';
import Atrium from './atrium';

// Hyperlive Utils
import { fetchJSON } from '/src/touchpoints/rise/hyperlive/utils';
import App from "../../../../conductor/hoi/App";
const {json_data} = fetchJSON();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Atrium data={json_data} />);
