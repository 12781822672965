import React, {
    useEffect,
    useRef,
    useContext,
    useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import {
    clamp,
    motion,
    useMotionValue,
} from "framer-motion";

import { random } from "../../../rise/utils/math";
import { GLYPHS } from "./glyphs";

import { secToMs } from "../../shared";
import Context from "./Codification.context";


export type CodificationCharacterProps = {
    character: string,
    index: number,
}

export default function CodificationCharacter({
    character,
    index,
}: CodificationCharacterProps) {
    const {
        timeout,
        repeat,
        trigger,
        reverse,
        charIndexMotion,
    } = useContext(Context);
    const { t } = useTranslation();

    const glyphRef = useRef<HTMLSpanElement>();
    const isUpperCase = useMemo(() => character === character.toUpperCase(), [character]);
    const opacity = useMotionValue(0);
    const timeoutMs = useMemo(() => secToMs(timeout), [timeout]);

    function getRandomGlyph () {
        return t(`glyphs.${GLYPHS[random(0, GLYPHS.length - 1)]}`);
    }

    function setGlyph(value: string) {
        if (glyphRef.current) {
            glyphRef.current.innerText = value;
        }
    };

    useEffect(() => {
        let time = Date.now();
        return charIndexMotion.onChange(value => {
            const opacityValue = clamp(0, 1, (value - index) * repeat / trigger);
            opacity.set(reverse ? 1 - opacityValue : opacityValue);

            if (value < index) {
                return;
            }

            const glyphIndex = Math.floor(value);
            if (glyphIndex > index) {
                setGlyph(character);
                return;
            }

            const now = Date.now();
            if (now - time >= timeoutMs) {
                time = now;

                const char = getRandomGlyph();
                setGlyph(isUpperCase ? char : char.toLowerCase());
            }
        });
    }, [reverse]);

    return <span className="hoi-codification__character">
        <span className="hoi-codification__result">{character}</span>
        <motion.span
            ref={glyphRef}
            className="hoi-codification__glyph"
            style={{
                opacity
            }}
        >
            {character}
        </motion.span>
    </span>;
};
