if (module.hot) {
  module.hot.accept(() => {
    location.reload()
  })
}

import { cover } from 'intrinsic-scale';
import Chance from 'chance';

import {
  Uniform,
  Vector2,
  Color,
  Texture,
  CanvasTexture,
  RepeatWrapping,
} from 'three';
4


import UniversalLiveTemplate from '/src/touchpoints/live/lib/js/UniversalLiveTemplate';

import vert from '/src/touchpoints/live/lib/glsl/shader.vert';
import frag from './contact-sheet.frag';
import presetTL from './contact-sheet-timeline';

import defaultSwoosh from 'url:/src/touchpoints/live/lib/assets/swoosh_180-270.png';

const chance = new Chance();

class ContactSheet extends UniversalLiveTemplate {
  constructor (name, datas, onComplete) {
    super(name);

    this.datas = datas;

    const center = new Vector2(
      (this.width / 2),
      (this.height / 2)
    );

    const uniforms = {
      time: new Uniform(0),
      res: new Uniform(screen),
      pixa: new Uniform(40),
      pixr: new Uniform(window.devicePixelRatio),
      stroke: new Uniform(1),
      // mosaic
      mosaicAmount: new Uniform(0),
      mosaicColor: new Uniform(1),
      // rows
      row0Width: new Uniform(1),
      row0Offset: new Uniform(0),
      row1Width: new Uniform(1),
      row1Offset: new Uniform(0),
      row2Width: new Uniform(1),
      row2Offset: new Uniform(0),
      row3Width: new Uniform(1),
      row3Offset: new Uniform(0),
      // general row stuffs
      rowScale: new Uniform(1.01),
      rowOffset: new Uniform(-0.01),
      dimy: new Uniform((this.height / 4)),
    }

    this.createVisualLayer({ frag, vert }, uniforms);

    for (let i = 0; i < 4; i++) {
      this.setupRow(i);
    }

    this.setupTimeline(onComplete);
  }


  setupRow (index) {
    this[`row${index}Canvas`] = document.createElement('canvas');
    this[`row${index}Canvas`].width = this.width * 2;
    this[`row${index}Canvas`].height = (this.height / 4);

    this[`row${index}Ctx`] = this[`row${index}Canvas`].getContext('2d');
    this[`row${index}Ctx`].fillStyle = 'white';
    this[`row${index}Ctx`].fillRect(0, 0, this[`row${index}Canvas`].width, this[`row${index}Canvas`].height);

    const texture = new CanvasTexture(this[`row${index}Canvas`]);
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    texture.anisotropy = 16;
    this.setGLUni(`row${index}Tex`, texture);

    const targetWidth = Math.floor(this.width / 9);
    const targetHeight = Math.floor(this[`row${index}Canvas`].height);

    const shuffled = chance.shuffle(this.datas.assets);
    for (let i = 0; i < 18; i++) {
      const img = new Image();
      img.src = shuffled[i % shuffled.length]?.url?.replace(/(.png)/gmi, '.jpg') ?? defaultSwoosh;
      img.crossOrigin = 'anonymous';

      img.onload = () => {
        const { width, height, x, y } = cover(targetWidth, targetHeight, img.width, img.height);
        this[`row${index}Ctx`].drawImage(img, Math.round(x + (i * targetWidth)), y, Math.round(width), Math.round(height));
        this.setGLUni(`row${index}Tex`, texture, true);
      }
    }
  }

  populateTimeline () {
    presetTL.bind(this)(() => {
      this.visualLayer.createTexture({
        src: this.datas?.primary?.assets[1]?.url,
        key: 'img2',
        forceJPG: true,
      }, false);
      this.setupCutOutTex(this.datas?.primary?.assets[1]?.maskUrl, this.datas?.primary?.assets[1]?.maskType);
    });
  }

  preStart () {
    this.wrapper.classList.add('active');
  }
}
export default ContactSheet;
