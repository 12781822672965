import * as THREE from 'three';

export default function PlacedCanvas (content) {
  if (!content.shader) {
    console.warn('no shader provided for placed canvas!');
    return null;
  }

  const scene = new THREE.Scene();
  scene.background = null;

  const parent = content.parent || document.body;
  // parent.appendChild(canvas);
  const width = parent.clientWidth;
  const height = parent.clientHeight;
  scene.userData.parent = parent;


  const camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 1, 10);
  scene.userData.camera = camera;

  const uniforms = content.uniforms || {};

  const screen = new THREE.Vector2(width, height);
  uniforms.res = new THREE.Uniform(screen);

  const shader = new THREE.ShaderMaterial({
    uniforms,
    transparent: true,
    vertexShader: content.shader.vert,
    fragmentShader: content.shader.frag,
  });
  scene.userData.shader = shader;

  const quad = new THREE.Mesh(new THREE.PlaneGeometry(2, 2), shader);
  quad.position.set(0, 0, -2);
  scene.add(quad);

  return scene;
}
