import gsap from 'gsap';

import Word from './word';

export default class Line {
    constructor (ctx, text, fontSize, baseline, width, fontStyle) {
        this.ctx = ctx;
        this.text = text.toUpperCase();
        this.fontSize = fontSize;
        this.baseline = baseline;
        this.width = width;
        this.fontStyle = fontStyle;
        this.timing = 0;
     
        this.setWords();
        this.tm = this.ctx.measureText(this.text);
        this.currentTickerIndex = this.words.length - 1;
    }

    setWords() {
        const words = this.text.toUpperCase().split(' ');
    
        this.words = words.map(
            (w, i) => new Word(w, i, words.length, this.ctx, this.fontStyle),
        );
        let cursor = 0;
        this.words.forEach((word, i) => {
            word.setSize(this.fontSize);
            word.setBaseline(this.baseline);
            word.draw(this.ctx, cursor)            
        })
    }

    render(lineIndex) {
        let cursor = (this.width - this.tm.width)/ 2 -  this.fontSize * 0.2;
        // if(this.currentTickerIndex > 0) {
        //     this.timing += 1;
        //     if(this.timing > 1) {
        //         this.timing = 0;
        //         this.currentTickerIndex = this.currentTickerIndex > 0 ? this.currentTickerIndex -= 1 : this.words.length;
        //     }
        // }
        for (let index = 0; index < this.words.length - this.currentTickerIndex; index++) {
            if(this.words[index].word == 'N/') {
                cursor = 0;
            } else {
                cursor += this.fontSize * 0.25;
                cursor = this.words[index].draw(cursor, lineIndex);
            }
        }
    }
}
