import React, { useEffect, useMemo, useState } from 'react';
import { AnimatePresence, usePresence } from 'framer-motion';
import classNames from 'classnames';

import * as cssClassNames from '../../styles/screens/product/IntroV3.module.scss';

import {
    getLangText,
    getMediaDuration,
    hasTextToDisplay,
    MediaType,
    MultiLangText,
    secToMs,
    setAnimationTimeout,
} from '../../shared';
import {
    BaseChapterProps,
} from '../../components/ChapterRenderer';
import
    CodificationWithTranslations,
    { calculateDuration as calculateCodificationDuration }
from '../../components/Codification/CodificationWithTranslations';
import GridWithCells from '../../components/GridWithCells';
import Grid from '../../components/Grid';
import MediaRenderer from '../../components/MediaRenderer';



export type IntroV3Props = BaseChapterProps & {
    text: MultiLangText,
    primaryMedia: MediaType,
}


const bigCellLength = 80;
const smallCellLength = bigCellLength / 2;

const codificationCharDuration = 0.03;
const characterSwitchAmount = 4;
const characterNextTrigger = 2;
const languageTransitionDelay = 0.25;
const pause = 4;

export default function IntroV3(props: IntroV3Props) {
    const {
        text,
        languages,
        width,
        height,
        primaryMedia,
    } = props;

    const [langIndex, setLangIndex] = useState(0);
    const [nextLangIndex, setNextLangIndex] = useState(0);
    const [isPresent, safeToRemove] = usePresence();
    const hasAnyTextToDisplay = useMemo(() => hasTextToDisplay(text, languages), [text, languages]);

    useEffect(() => {
        if (nextLangIndex === langIndex) {
            return;
        }

        const duration = primaryMedia && getMediaDuration(primaryMedia) || pause;
        return setAnimationTimeout(() => {
            setLangIndex(nextLangIndex);
        }, secToMs(duration));
    }, [nextLangIndex]);

    const textComponent = useMemo(() => hasAnyTextToDisplay ? (
        <CodificationWithTranslations
            text={text}
            languages={languages}
            langIndex={langIndex}
            codificationProps={{
                characterSwitchAmount,
                characterNextTrigger,
                timingConfig: {
                    control: 'character',
                    duration: codificationCharDuration,
                },
            }}
            runFinalTextAnimation
            languageTransitionDelay={languageTransitionDelay}
            onTyped={() => {
                setNextLangIndex(i => {
                    return (i >= languages.length - 1) ? i : (i + 1);
                });
            }}
        />
    ) : null, [text, languages, langIndex]);

    return (
        <div className={cssClassNames.intro_v3}>
            <div className={classNames(cssClassNames.media_container, {[cssClassNames.dim]: hasAnyTextToDisplay})}>
                <MediaRenderer
                    height={height}
                    media={primaryMedia}
                />
            </div>
            <div className={classNames(cssClassNames.grid_container, cssClassNames.solid)}>
                <Grid
                    rows={10}
                    cols={9}
                    height={10 * bigCellLength}
                    width={9 * bigCellLength}
                    toDrawBoundaries
                    initialAnimation='visible'
                />
            </div>
            <GridWithCells
                rows={20}
                cols={12}
                height={20 * smallCellLength}
                width={12 * smallCellLength}
                cellLength={smallCellLength}
                toDrawBoundaries
                className={classNames(cssClassNames.grid_container, cssClassNames.dotted)}
            />
            <div className={cssClassNames.text_container}>
                <div className={cssClassNames.text}>
                    <AnimatePresence onExitComplete={isPresent ? undefined : safeToRemove}>
                        {isPresent ? textComponent : null}
                    </AnimatePresence>
                </div>
            </div>
        </div>
    );
}

export function calculateDuration(data: IntroV3Props) {
    const {
        text,
        languages,
        primaryMedia,
    } = data;
    const entryDuration = 0;

    const mediaDuration = primaryMedia && getMediaDuration(primaryMedia) || pause;

    const textDuration = calculateCodificationDuration({
        text,
        languages,
        codificationCharDuration,
        characterSwitchAmount,
        characterNextTrigger,
        languageTransitionDelay,
    });

    const mainDuration = textDuration + mediaDuration * languages.length;

    const total = entryDuration + mainDuration;

    const duration = secToMs(total) + calculateExitDuration(data);

    return duration;
}

export function calculateExitDuration(data: IntroV3Props) {
    const {
        languages,
    } = data;

    const maxText = languages.reduce((result, lang) => {
        const text = getLangText(data.text, lang);
        return text ? Math.min(result, text.length) : result;
    }, Infinity);
    const oneLangDuration = maxText * codificationCharDuration * characterNextTrigger;

    return secToMs(oneLangDuration);
}
