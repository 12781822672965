import React, { useEffect, useState } from 'react';
import Codification from '../../../../components/Codification';
import './Headline.scss';

const sizing = {
	eyebrow: { fontSize: '3.8vh', lineHeight: '3.3vh' },
	primary: { fontSize: '6.97vh', lineHeight: '6.4vh' },
	secondary: { fontSize: '5vh', lineHeight: '4.68vh' },
	display: { fontSize: '10vh', lineHeight: '9.6vh' },
	super: { fontSize: '17vh', lineHeight: '14.5vh' },
	grid_two: { fontSize: '15vh', lineHeight: '13vh' }
}
const Headline = ({ text, start = false, test, size = 'primary', theme = 'light', className, width = '100%' }) => {

	const typeStyle = sizing[size];

	return (<div className={`Headline ${className} theme-${theme}`} style={{ width: width }}>
		<Codification
			text={`${text ? text : ''}`}
			typography={{
				primary: true,
				typeStyle: typeStyle,
				tag: 'span',
			}}
			characterSwitchAmount={6}
			characterNextTrigger={2}
			characterTimeout={30}
			start={start}
		// isStatic={!start}
		// reverse={frameName === VisibleKeyframes[2]}
		/>
	</div>
	);
};

export default Headline;
