const fontsIndexes = [
  ['NeuePixelGrotesk', 'NIKE_FUTURA'],
  ['NIKE_FUTURA', 'NeuePixelGrotesk', 'HelveticaNeue'],
  ['NeuePixelGrotesk', 'NIKE_FUTURA', 'HelveticaNeue']
  ]
  
  const fontSizesRatio = {
    NIKE_FUTURA: 0.943,
    NeuePixelGrotesk: 1,
    HelveticaNeue: 1
  }
  
  export default class Letter {
    constructor (letter, wordIndex, letterIndex, count) {
      this.value = letter;
      this.wordIndex = wordIndex;
      this.letterIndex = letterIndex;
      const index = Math.min((count - 1), 2);
      this.fonts = fontsIndexes[index];
      this.fontIndex = this.wordIndex;
    }
  
    setBaseline (baseline) {
      this.baseline = baseline;
    }
  
    setSize (size) {
      this.size = size;
    }
  
    getFont () {
      return this.fonts[this.fontIndex % this.fonts.length];
    }
  
    draw (ctx, cursor, fontStyle) {
      if(fontStyle !== 'Noto') {
        ctx.font = `${this.size * fontSizesRatio[this.getFont()]}px "${this.getFont()}"`;
      } else {
        ctx.font = `${this.size}px "Noto"`;
      }
    
      ctx.fillStyle = "#000000"; 
      ctx.fillText(this.value, cursor, this.baseline);
    }
  }
  