if (module.hot) {
  module.hot.accept(() => {
    location.reload()
  })
}

import * as THREE from 'three';

import markup from './gridded-imagery-markup';
import vert from '/src/touchpoints/live/lib/glsl/shader.vert';
import frag from './gridded-imagery.frag';
import createTL from './gridded-imagery-timeline';
import './gridded-imagery.scss';

import defaultSwoosh from 'url:/src/touchpoints/live/lib/assets/swoosh.png';

import swatchList from '/src/touchpoints/live/lib/js/components/SwatchList/SwatchList';
import verticalText from '/src/touchpoints/live/lib/js/components/VerticalText/VerticalText';
import placedCanvas from '/src/touchpoints/live/lib/js/components/PlacedCanvas/PlacedCanvas';

class GriddedImagery {
  constructor (name, datas, onComplete) {
    this.name = name;
    this.datas = datas;

    this.onComplete = onComplete;

    const width = window.innerWidth;
    const height = window.innerHeight;

    const defaultAsset = { url: defaultSwoosh };
    const keyframe_1 = this.datas?.keyframe_1?.assets ?? [defaultAsset, defaultAsset, defaultAsset];
    const keyframe_2 = this.datas?.keyframe_2?.assets ?? [defaultAsset];
    const keyframe_3 = this.datas?.keyframe_3?.assets ?? [defaultAsset, defaultAsset];
    this.datas.scc = [...keyframe_1, ...keyframe_2, ...keyframe_3];

    this.wrapper = document.createElement('div');
    this.wrapper.id = name;
    this.wrapper.classList.add('chapter-wrapper');
    this.wrapper.classList.add('gridded-imagery');
    this.wrapper.classList.add(`is-size-${window.SIZE_ID?.toLowerCase()}`);

    const units = {
      SMALL: '15px',
      MEDIUM: '23px',
      LARGE: '30px',
    }
    document.documentElement.style.setProperty('--lls-cs-grid-unit', units[window.SIZE_ID]);

    const borderThicknesses = {
      SMALL: '1px',
      MEDIUM: '1px',
      LARGE: '1px',
    }
    document.documentElement.style.setProperty('--lls-cs-grid-border-thick', borderThicknesses[window.SIZE_ID]);

    if (width < height) {
      this.isVertical = true;
      this.wrapper.classList.add('is-vertical');
    }
    this.wrapper.innerHTML += markup;
    document.body.appendChild(this.wrapper);

    this.verticalTextTop = verticalText({ text: '' });
    this.verticalTextTop.classList.add('vt-top');
    document.querySelector('.freeflow-item .overlay').appendChild(this.verticalTextTop);

    this.verticalTextBottom = verticalText({ text: '' });
    this.verticalTextBottom.classList.add('vt-bottom');
    document.querySelector('.freeflow-item .overlay').appendChild(this.verticalTextBottom);

    if (keyframe_3[0]?.swatches?.length) {
      const offsets = [0, 9, 0, 0, 8, 0, 0]; // quick and dirty
      const swatches = new Array(8).fill({}).map((e, i) => {
        return {
          color: keyframe_3[0].swatches[i % keyframe_3[0].swatches.length],
          offset: offsets[i] || 0
        };
      });
      const swatchListHTML = swatchList({ swatches });
      document.querySelector('.freeflow-item .overlay').appendChild(swatchListHTML);
    }

    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, });
    this.resizeRendererToDisplaySize(this.renderer);
    this.renderer.setClearColor(0xFFFFFF, 0);
    
    this.renderer.domElement.classList.add('cs-grid-canvas');
    this.wrapper.appendChild(this.renderer.domElement);

    this.scenes = [];

    console.log('scc', this.datas.scc);

    this.insertMediaView('.content-grid .grid-item-1', this.datas.scc[0]?.url, 0);
    this.insertMediaView('.content-grid .grid-item-2', this.datas.scc[2]?.url, 1);
    this.insertMediaView('.content-grid .grid-item-3', this.datas.scc[1]?.url, 2);
    this.insertMediaView('.freeflow-item', this.datas.scc[3]?.url, 3);

    // this.loadIndex = this.datas.scc.length;
    this.loadIndex = 4;

    this.running = false;
  }

  resizeRendererToDisplaySize (renderer) {
    const canvas = renderer.domElement;
    const pixelRatio = window.devicePixelRatio;
    const width = window.innerWidth;
    const height = window.innerHeight;
    // const width  = canvas.clientWidth  * pixelRatio | 0;
    // const height = canvas.clientHeight * pixelRatio | 0;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
      renderer.setSize(width, height, false);
    }
    return needResize;
  }

  updateTexture (url, index) {
    this.createTexture(url, (texture) => {
      texture.needsUpdate = true;
      this.scenes[index].userData.shader.uniforms.img.value = texture;
      this.scenes[index].userData.shader.uniforms.imgRes.value.x = texture.image.width;
      this.scenes[index].userData.shader.uniforms.imgRes.value.y = texture.image.height;
    });
  }

  onStart () {
    this.verticalTextTop.children[0].textContent = '';
    this.verticalTextBottom.children[0].textContent = '';
    this.updateTexture(this.datas.scc[3]?.url, 3);
  }
  onKeyframe3Front () {
    this.updateTexture(this.datas.scc[4]?.url, 3);
  }
  onKeyframe3Profile () {
    this.updateTexture(this.datas.scc[5]?.url, 3);
  }

  createTexture (url, onLoaded) {
    const img = new Image();
    const texture = new THREE.Texture(img);
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.encoding = THREE.sRGBEncoding;
    texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();

    img.addEventListener('load', () => {
      onLoaded(texture);
    });

    img.crossOrigin = 'anonymous';
    img.src = url?.replace('.png', '.jpg') || defaultSwoosh;
  }

  insertMediaView (selector, url, index) {
    const width = window.innerWidth;
    const height = window.innerHeight;

    this.createTexture(url, (texture) => {
      texture.needsUpdate = true;


      const rf = document.querySelector(`${selector} .overlay .rangefinder`).getBoundingClientRect();

      const scene = placedCanvas({
        parent: document.querySelector(`#GRIDDED-IMAGERY ${selector}`),
        uniforms: {
          seed: (Math.random() * 1000),
          img: new THREE.Uniform(texture),
          imgRes: new THREE.Uniform(new THREE.Vector2(
            texture.image.width,
            texture.image.height
          )),
          res: new THREE.Uniform(new THREE.Vector2(width, height)),
          offset: new THREE.Uniform(new THREE.Vector2(0)),
          mosaic: new THREE.Uniform(1),
          pixels: new THREE.Uniform(1),
          zoom: new THREE.Uniform(1),
          opacity: new THREE.Uniform(1),
          time: new THREE.Uniform(0),
          pixr: new THREE.Uniform(window.devicePixelRatio),
          rangeFinder: new THREE.Uniform(new THREE.Vector2(rf.width, rf.height)),
          pixRF: new THREE.Uniform(1),
          zoomRF: new THREE.Uniform(1),
        },
        renderer: this.renderer,
        shader: { frag, vert },
      });

      this.scenes[index] = (scene);

      if (this.scenes.filter(s => s).length === this.loadIndex) {
        this.tl = createTL(this.scenes, this.onComplete, this.onStart.bind(this), this.onKeyframe3Front.bind(this), this.onKeyframe3Profile.bind(this), this.isVertical);
        if (this.delayedPlay) {
          this.tl.play(0);
        }
      }
    });
  }

  render () {
    if (this.running) {
      this.renderer.setScissorTest(true);

      this.scenes.forEach((scene) => {
        const element = scene.userData.parent;
        const rect = element.getBoundingClientRect();
        // if (rect.bottom < 0 || rect.top > this.renderer.domElement.clientHeight
        //   || rect.right < 0 || rect.left > this.renderer.domElement.clientWidth) {
        //   return;
        // }

        const width = (rect.right - rect.left);
        const height = (rect.bottom - rect.top);
        const left = rect.left;
        const bottom = (this.renderer.domElement.clientHeight - rect.bottom);
        const vp = new THREE.Vector4(left, bottom, width, height);

        this.renderer.setViewport(vp);
        this.renderer.setScissor(vp);

        scene.userData.shader.uniforms.time.value++;
        scene.userData.shader.uniforms.res.value.x = width;
        scene.userData.shader.uniforms.res.value.y = height;

        const rf = element.querySelector('.overlay .rangefinder')?.getBoundingClientRect();
        scene.userData.shader.uniforms.rangeFinder.value.x = rf?.width;
        scene.userData.shader.uniforms.rangeFinder.value.y = rf?.height;

        this.renderer.render(scene, scene.userData.camera);
      });
    }

    this.renderer.setScissorTest(false);

    this.loop = requestAnimationFrame(this.render.bind(this));
  }

  start () {
    this.wrapper.style.display = 'block';
    if (this.tl) {
      this.tl.play(0);
    } else {
      this.delayedPlay = true;
    }
    this.running = true;
    this.loop = requestAnimationFrame(this.render.bind(this));
  }
  stop () {
    this.wrapper.style.display = 'none';
    this.running = false;
    this.tl?.pause();
    this.tl?.seek(0);
    cancelAnimationFrame(this.loop);
  }
  pause () {
    this.running = false;
    console.log('pause');
    this.tl?.pause();
  }
  resume () {
    this.tl?.resume();
    this.running = true;
  }
}
export default GriddedImagery;
