import {NbyDataType} from "./types";
import {useNbyStore} from "./store";
import React, {useEffect} from "react";
import Steps from "./screens/steps";
import Pricing from "./screens/pricing";
import './style.scss';
import '../../../jordan/styles/fonts.scss';

export default function Nby({data}: { data: NbyDataType }) {
    const initialize = useNbyStore(state => state.initialize);
    const play = useNbyStore(state => state.play);
    const {variant = 'steps'} = useNbyStore(state => state?.animationData) ?? {};

    useEffect(() => {
        initialize(data);
        play().then(() => null);
    }, [variant]);

    return variant === 'pricing' ? <Pricing /> : <Steps />;
}