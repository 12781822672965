import {
  fetchJSON,
  fetchChannelInfo,
} from '/src/touchpoints/rise/hyperlive/utils';
import Ticker from './ticker';
import {get} from 'lodash';
import './styles.css';
import {pixelMapReady} from "../../../../utilities";

const {json_data} = fetchJSON();
const params = get(fetchChannelInfo(), 'json_config', {}) ?? {};
console.log('channel config', params);

const allText = json_data.content;
const isStatic = json_data.is_static || false;
const hasSwoosh = json_data.has_swoosh;
const dateContent = json_data.date || [];
const languages = Object.keys(allText || []);

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const speed = urlParams.get('speed') || params.speed || 1.75;
const text =
  urlParams.get('text') || languages.length ? languages.map(l => allText[l] || '') : [''];
const date =
  urlParams.get('date') || languages?.map(l => dateContent[l] || null) || null;
const startPoint = urlParams.get('start_point') || params?.start_point || 0.5;
const width = urlParams.get('width') || params?.width || window.innerWidth;
const height = urlParams.get('height') || params?.height || 200;
const repeat = urlParams.get('repeat') || params?.repeat || -1;
const duration = urlParams.get('duration') || params?.duration || 0.2;
const padding = urlParams.get('padding') || params?.padding || 1;
const delay = urlParams.get('delay') || params?.delay || 4;

const oldCanvas = document.querySelector('canvas');
if (oldCanvas) {
  document.body.removeChild(oldCanvas);
}

const canvas = document.createElement('canvas');
canvas.width = width;
canvas.height = height;

document.body.appendChild(canvas);

const ctx = canvas.getContext('2d');
const fontRatio = canvas.height / 200;
const ticker = new Ticker(
  canvas,
  Array.isArray(text) ? text : [text],
  Array.isArray(date) || date === null ? date : [date],
  180 * fontRatio,
  speed,
  duration,
  repeat,
  startPoint,
  Number(delay),
  hasSwoosh,
  padding,
  isStatic,
  languages?.length,
  true,
);
ticker.gui.hidden = true;

let time = 0;
let lt = new Date().getTime();

function render() {
  let ts = new Date().getTime();
  let dt = (ts - lt) / 1000;
  dt = 1000 / 60;

  if (!ticker.gui.hidden) {
    ticker.fpsGraph.begin();
  }
  if (ticker.running) {
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    time += dt * 100 * ticker.guiParams.speed / 750;
    ticker.render(time);
  }
  requestAnimationFrame(render);

  if (!ticker.gui.hidden) {
    ticker.fpsGraph.end();
  }
  lt = ts;
}

window.addEventListener(
  'keypress',
  e => {
    if (e.shiftKey) {
      if (e.code === 'Space') {
        ticker.running ? ticker.pause() : ticker.resume();
      } else if (e.code === 'KeyR') {
        window.dispatchEvent(new CustomEvent('restart_animation'));
      } else if (e.code === 'Enter') {
        time = 0;
        ticker.start();
      } else if (e.code === 'KeyH') {
        ticker.gui.hidden = !ticker.gui.hidden;
      }
    }
  },
  false,
);

pixelMapReady(() => {
  time = 0;
  ticker.start();
})

ticker.start();
requestAnimationFrame(render);

console.log(json_data);
