import React from 'react';
import {Codification} from "../../../components";

const Text = ({content, count, info}) => (
  <div
    style={{
      width: info.trackHeight * 1.5,
      textAlign: 'center'
    }}
  >
    <Codification
      text={content}
      typography={{
        primary: true,
        typeStyle: {fontSize: info.trackHeight / 4, lineHeight: info.trackHeight / 4},
        tag: 'span',
      }}
      characterSwitchAmount={6}
      characterNextTrigger={2}
      characterTimeout={18}
      reverse={count === 0 || count === false}
    />
  </div>
);

export default function Content({action, count, index, intro, info}) {
  const shouldRenderTag = index % 2 === 0;

  if (intro || shouldRenderTag === false) {
    return (
      <Text
        content={action.activity?.['EN']}
        count={count}
        info={info}
      />
    );
  }

  return (
    <Text
      content={"JUST DO IT"}
      count={count}
      info={info}
    />
  );
}