import runHoi from "./hoi/index";
import runRise from "./entry";
import pixelMap from "./pixelMap";

class StoreTypeConductor {
	conduct() {
		if (this.isHoi()) {
			runHoi();
			return;
		}

		if (this.isPixelMap()) {
			pixelMap();
			return;
		}

		this.runRise();
	}

	runRise() {
		(async () => {
			document.querySelector('#hyperlive-container').style.display = 'none';
			document.querySelector('#loading-status').style.display = 'none';
			document.querySelector('body').style.textAlign = 'left';
			document.querySelector('body').style.backgroundColor = 'white';

			await runRise();
		})();
	}

	/**
	 * Checks whether the template is a HOI template.
	 *
	 * @returns {boolean}
	 */
	isHoi (){
		return window.location.hash.indexOf('#display') === 0;
	}

	isPixelMap() {
		return window.location.search.indexOf('pixelmap=') >= 0;
	}
}


document.fonts.ready.then(() => {
	console.log('Fonts ready');
	(new StoreTypeConductor()).conduct();
});
