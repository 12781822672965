import axios from 'axios';

const baseURL =
  'https://api.airtable.com/v0/app0rmSO2PKgJG31a/tblk7wW3Re2DjyG75?sort%5B0%5D%5Bfield%5D=Event+Start&sort%5B0%5D%5Bdirection%5D=asc&sort%5B1%5D%5Bfield%5D=Event+Date&sort%5B1%5D%5Bdirection%5D=asc';
const testURL =
  'https://api.airtable.com/v0/appLpNcthc9xYKlew/tbl55XgI6BrUyCYkr?sort%5B0%5D%5Bfield%5D=Event+Start&sort%5B0%5D%5Bdirection%5D=asc&sort%5B1%5D%5Bfield%5D=Event+Date&sort%5B1%5D%5Bdirection%5D=asc';

export async function fetchEvents(offset, testing = false) {
  const url = testing === 'true' ? testURL : baseURL;

  return new Promise((resolve, reject) => {
    var config = {
      method: 'get',
      url: offset ? `${url}&offset=${offset}` : url,
      headers: {
        Authorization:
          'Bearer patT6I2iU5OKx5GPX.e9db2fee64fe005d2db9f828a7edcd7bb53da2668670699013e29ba2997153f6',
      },
    };

    axios(config)
      .then(function(response) {
        resolve(response.data);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
