import { v4 as uuidv4 } from 'uuid';

import './grid.css';

export default function grid (content) {
  const div = document.createElement('div');
  div.classList.add('lls-grid');
  content.class && div.classList.add(content.class);
  div.style.zIndex = content.zIndex || 'auto';

  const { name, x, y, width, height, stepX, stepY, stroke, strokecolor, holes } = {
    name: '',
    width: window.innerWidth,
    height: window.innerHeight,
    stepX: 6,
    stepY: 4,
    x: 0,
    y: 0,
    stroke: 2,
    strokecolor: 'black',
    holes: [],
    ...content
  }

  const uuid = uuidv4();

  // todo: get grid width width/steps.x (same for height/y)
  // then compute pattern width as (width - (stroke / steps.x / 2))
  const gridW = (width / stepX);
  const gridH = (height / stepY);
  const patternW = (gridW - (stroke / stepX / 2));
  const patternH = (gridH - (stroke / stepY / 2));

  const gridholes = holes.map(({ x, y, width, height, key }) => {
    return `<rect id="${key}-gridhole" width="${Math.max(0, width - stroke)}" height="${Math.max(0, height - stroke)}" x="${x + stroke}" y="${y + stroke}" fill="black" />`;
  }).join('');

  const svg = `
  <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <mask id="lls-${name}-${uuid}-gridmask" class="lls-${name}-gridmask" maskUnits="userSpaceOnUse">
        <rect width="100%" height="100%" fill="white"/>
        ${gridholes}
      </mask>
      <pattern id="lls-${name}-${uuid}-grid" class="lls-${name}-grid" width="${patternW}" height="${patternH}" x="${x}" y=${y} patternUnits="userSpaceOnUse">
        <path d="M ${window.innerWidth} 0 L 0 0 0 ${window.innerHeight}" fill="none" stroke="${strokecolor}" stroke-width="${stroke}"/>
      </pattern>
    </defs>
    <rect width="100%" height="100%" fill="url(#lls-${name}-${uuid}-grid)" mask="url(#lls-${name}-${uuid}-gridmask)" />
  </svg>
  `;

  div.innerHTML = svg;
  return div;
}
