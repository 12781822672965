{
  "track_length": 8000,
  "track_height": 225,
  "render_width": 1920,
  "occlusions": [
    {
      "start": 2000,
      "width": 400
    },
    {
      "start": 5000,
      "width": 100
    },
    {
      "start": 7000,
      "width": 200
    }
  ],
  "locations": [
    {
      "created_by_app_user_id": 62,
      "animation_type_id": 9,
      "store_id": 53,
      "id": 945,
      "name": "Nike AIR FORCE 1 '07 LE ",
      "layout": "",
      "json_data": {
        "cta": "None",
        "subcopy": {
          "EN": "The classic that redefined street style.",
          "KO": "스트리트 스타일을 재정의하는 클래식 제품"
        },
        "shoe_name": {
          "EN": "Nike AIR FORCE 1 '07 LE",
          "KO": "Nike AIR FORCE 1 '07 LE"
        },
        "pulse_data": {
          "metric": null,
          "grouping": null
        },
        "supertitle": {
          "EN": "Designed for Fashion",
          "KO": "패션을 위한 디자인"
        },
        "start_location": 0,
        "static_pulse_story": {
          "EN": "Ultimate durability and Support",
          "KO": "내구성과 지지력"
        }
      },
      "approved_by_app_user_id": null,
      "approved_at": null,
      "created_at": 1627877202000,
      "updated_at": 1628543363000,
      "deleted_at": null
    },
    {
      "created_by_app_user_id": 62,
      "animation_type_id": 9,
      "store_id": 53,
      "id": 945,
      "name": "Nike AIR FORCE 1 '07 LE ",
      "layout": "",
      "json_data": {
        "cta": "None",
        "subcopy": {
          "EN": "The classic that redefined street style.",
          "CA": "스트리트 스타일을 재정의하는 클래식 제품"
        },
        "shoe_name": {
          "EN": "Nike AIR FORCE 1 '07 LE",
          "CA": "Nike AIR FORCE 1 '07 LE"
        },
        "pulse_data": {
          "metric": null,
          "grouping": null
        },
        "supertitle": {
          "EN": "Designed for Fashion",
          "CA": "패션을 위한 디자인"
        },
        "start_location": 1200,
        "static_pulse_story": {
          "EN": "Ultimate durability and Support",
          "CA": "내구성과 지지력"
        }
      },
      "approved_by_app_user_id": null,
      "approved_at": null,
      "created_at": 1627877202000,
      "updated_at": 1628543363000,
      "deleted_at": null
    }
  ]
}
