import React from "react";

const Display = ({ children, width, height }) => {
	const aspectRatio = (height / width) * 100;

	return (
		<div style={{height: '100vh', width: '100vw', position: 'relative', overflow: 'hidden'}}>
			<div style={{height: `${aspectRatio}vw`, width: '100vw'}}>
				{children}
			</div>
			<div style={{background: 'rgba(0,0,0,.5)', display: 'flex', flex: 1, height: '100%'}} />
		</div>
	);
};

export default Display;
