import ReactDOM from 'react-dom/client';
import App from './nby';
import {fetchJSON} from "../../../rise/hyperlive/utils";
const data = fetchJSON();
import '../../../../init';

const params = new URLSearchParams(window.location.search)
const chapter = params.get('chapter');
const openChapter = ["", null, undefined].includes(chapter) ? null : chapter;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App openEntry={openChapter} data={data?.json_data?.root} />);