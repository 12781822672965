import {SIZES} from "../constants";

export default (size= SIZES.WIDE) => {
  const top = size === SIZES.COMPACT ? 3 : 12.5;
  const bottom = size === SIZES.COMPACT ? 97 : 87.5;

  return [
    {
      horizontal: [
        {
          drawFull: false,
          name: 'Horizontals',
          drawToPosition: 100,
          startPosition: top,
          drawFromPosition: 0,
          segmentGroup: {
            numLines: '3',
            segmentPositionEnd: bottom,
          },
        },
      ],
      vertical: [],
      delayHorizontal: true,
      circle: [],
      duration: 750,
      mask: [],
      insetBorder: false,
      drawBorder: false,
    },
    {
      mask: [],
      delayHorizontal: true,
      horizontal: [
        {
          name: 'Horizontals',
          drawDirection: -1,
          drawFull: true,
          drawFromPosition: 100,
          segmentGroup: {
            numLines: '3',
            segmentPositionEnd: bottom,
          },
          drawToPosition: 0,
          startPosition: top,
        },
      ],
      duration: 1000,
      insetBorder: false,
      circle: [],
      vertical: [],
      drawBorder: false,
    },
  ]
};
