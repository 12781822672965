import gsap from 'gsap';
import { ScrambleTextPlugin } from '/src/touchpoints/live/lib/js/vendors/ScrambleTextPlugin.min.js';
import { ScrollToPlugin } from '/src/touchpoints/live/lib/js/vendors/ScrollToPlugin.min.js';


gsap.registerPlugin(ScrambleTextPlugin);
gsap.registerPlugin(ScrollToPlugin);


export default function createTL (scenes, onComplete, onStart, wrapper, rangeFinder) {

  const tl = gsap.timeline({
    onComplete,
    paused: true,
    onStart: onStart,
    defaults: { ease: 'power2.inOut' }
  });

  const uniforms = scenes.map(scene => scene.userData.shader.uniforms);

  const w = window.innerWidth;
  const h = window.innerHeight;
  
  // INITIAL STATES
  tl.add(() => {
    rangeFinder.style.display = 'block';
  }, 0);



  let scrollable = 0;
  let scrollDuration = 0;
  tl.fromTo(rangeFinder, {scale:0.15}, {scale: 1, duration: 1.56 - 0.38, ease: 'expo.inOut', onComplete: () => {
      rangeFinder.style.display = 'none';
  }}, 0);
  tl.to(wrapper.querySelector('.snkrs-grid'), { ease: 'expo.inOut', duration: 1.8, "clip-path": `ellipse(${window.innerWidth * 10}px ${window.innerWidth * 10}px at ${window.innerWidth/2}px ${window.innerHeight/2.1}px)`
  , onComplete: () => {
    scrollable = document.querySelector('.snkrs-grid').offsetHeight - window.innerHeight;
    scrollDuration = 5.5;
    tl.to(window, {duration: scrollDuration, scrollTo: scrollable, ease: 'power2.inOut'}, 2.0);
    tl.to(window, {duration: scrollDuration, scrollTo: 0, ease: 'power2.inOut'}, 8.0);
  }}, 0);

  tl.add(() => {}, 15);

  return tl;
}
