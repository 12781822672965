import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText.js';

gsap.registerPlugin(SplitText);

export default function createTL (scenes, onComplete, onStart, text, id) {
  const tl = gsap.timeline({
    onComplete,
    paused: true,
    onStart: onStart,
    defaults: { ease: 'power2.inOut' }
  });

  const uniforms = scenes.map(scene => scene.userData.shader.uniforms);

  const cg = `#${id} .content-grid`;
  const cgi = `#${id} .content-grid .grid-item`;

  const cgi1 = `#${id} .content-grid .grid-item-1`;
  const cgi2 = `#${id} .content-grid .grid-item-2`;

  const w = window.innerWidth;
  const h = window.innerHeight;

  const split = new SplitText(`${cgi2} h2`, { type: 'words, chars' });

  // INITIAL STATES
  tl.add(() => {
    gsap.set(uniforms[0].mosaic, { value: 0 });
    gsap.set(uniforms[0].pixels, { value: 60 });

    gsap.set(split.words, { css: { className: 'Helvetica' } }, 0);
    gsap.to(split.chars, { css: { className: 'hidden' } }, 0);

    console.log('CA MESSAGING_GRID #', id);
  }, 0);

  // ANIMATIONS

  tl.to(uniforms[0].mosaic, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.1);
  tl.to(uniforms[0].pixels, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.1);

  tl.to(split.chars, { duration: 1, css: { className: 'visible' }, stagger: 0.075 }, 0.25);

  tl.to(split.words, { duration: 1, css: { className: 'Pixel' }, stagger: 0.1 }, 1);

  tl.to(split.chars, { duration: 0.5, reversed: true, css: { className: 'hidden' }, stagger: 0.01 }, 4.5);

  tl.to(uniforms[0].mosaic, { value: 0, duration: 1, ease: 'power0.easeNone' }, 4.1);
  tl.to(uniforms[0].pixels, { value: 60, duration: 1, ease: 'power0.easeNone' }, 4.1);

  tl.add(() => {}, 5.5);

  return tl;
}
