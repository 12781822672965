import React, { useEffect, useState } from 'react';
import Codification from '../../../../components/Codification';
import './BodyCopy.scss';


const sizing = {
	primary: { fontSize: '3.7vh', lineHeight: '3.3vh' },
	secondary: { fontSize: '5vh', lineHeight: '4.68vh' },
}

const BodyCopy = ({ text, start = false, test, size = 'primary', theme = 'light', width = '100%' }) => {

	const typeStyle = sizing[size];

	return (<div className={`BodyCopy theme-${theme}`} style={{ width: width }}>
		<Codification
			text={`${text}`}
			typography={{
				primary: false,
				typeStyle: typeStyle,
				tag: 'span',
			}}
			characterSwitchAmount={6}
			characterNextTrigger={2}
			characterTimeout={30}
			start={start}
		// isStatic={!start}
		// reverse={frameName === VisibleKeyframes[2]}
		/>
	</div>
	);
};

export default BodyCopy;
