@use '../../variables' as vars;

.intro_v3 {
    height: 100%;
    position: relative;
    color: white;

    & > * {
        position: absolute;
        top: 0;
    }

    .media_container {
        width: 100%;
        height: 100%;
    }

    .dim {
        filter: brightness(0.8);
    }

    .grid_container {
        top: unset;

        &.solid {
            bottom: 220px;
            left: -39px;
        }
        &.dotted {
            bottom: -20px;
            right: -1px;
        }

        line {
            stroke: #dadada;
        }
    }

    .text_container {
        @include vars.hoi-font;

        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        font-size: 188px;
        line-height: 90%;

        .text {
            width: 100%;
            padding: 66px;
        }
    }
}
