import {ServicesProps} from "./screens/Services";
import {centerCourtSeasonalDuration} from "./screens/CenterCourtSeasonal";
import {centerCourtWofDuration} from "./screens/CenterCourtWof";
import {calculateDuration as mediaCalculateDuration} from "./screens/Media";
import {calculateDuration as productCalculateDuration} from "./screens/Product";
import {loadDrops, getDuration as snkrsDuration} from "./screens/SnkrsCal";
import {loadStories} from "./screens/SnkrsStories";
import {clearStorageIfNeeded, createStorage, preloadAllAssets} from "./storage";
import {fetchChannelInfo} from '../rise/hyperlive/utils';
import {JordanConfig} from "./components/ChapterRunner";
import {preloadFont} from 'troika-three-text'
import {get} from 'lodash';
import {fonts} from "../core/animations/ticker/ticker/shared";
import {NIKE_QR_BASE_URL} from '../../config';

export type ThemeType = 'dark' | 'light';

export interface MediaType {
    blob?: Blob;
    etag?: string;
    url: string;
    original_url?: string;
    resource_type: 'image' | 'video' | 'm3u8-video';
    fit?: 'fit' | 'cover';
    force_duration?: string | number | 'full';
    duration?: number;
    width?: number;
    height?: number;
    format?: string;
    dontTransform?: boolean;
    destination_url?: string;
}

export type ChapterTemplateType =
    'intro'
    | 'title_card'
    | 'product'
    | 'ugc'
    | 'snkrs_cal'
    | 'snkrs_cal_blend'
    | 'snkrs_cal_stories'
    | 'snkrs_title'
    | 'membership'
    | 'services'
    | 'lookbook'
    | 'media'
    | 'store_directory'
    | 'cc_intro'
    | 'cc_wof'
    | 'cc_seasonal'
    | 'store_takeover';

export type CenterCourtTemplateType =
    'cc_intro'
    | 'cc_wof'
    | 'cc_seasonal';

export interface ChapterType {
    template: ChapterTemplateType;
    duration: number;
    data: any;
    skip?: boolean;
}

export interface CenterCourtGradient {
    type: 'linear' | 'radial' | undefined;
    colors: Array<Array<string>>
}

async function initialize(): Promise<void> {
    createStorage();
    await clearStorageIfNeeded();

    const characters = '01234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    return new Promise((resolve, reject) => {
        preloadFont({font: fonts['jordan-condensed'], characters}, resolve);
    });
}

function expandChapterMacros(rawData: any): any {
    let chapters = rawData?.chapters?.map((a, chapterIndex) => ({...a, chapterIndex})).reduce((acc, curr, i) => {
        let chapters = [curr];
        const templateType: ChapterTemplateType = curr.template;
        const marketplace = curr.marketplace;
        const hideDates = curr?.hide_dates;
        const ctaLabel = curr?.cta_label;
        const interleave = true;
        const locale = curr?.locale;
        const available_copy = curr?.available_copy;
        const qr_drops = curr?.qr_drops;
        const qr_stories = curr?.qr_stories;

        if (curr?.template === 'snkrs_cal_blend') {
            const MAX_DROPS = 6;
            const rawBlend = curr?.blend ?? '2,2';
            const [drops, stories] = rawBlend.split(',');
            const repeats = Math.floor(MAX_DROPS / drops);

            const createChapters = (acc, ucurr, i) => {
                const drop = {
                    template: 'snkrs_cal',
                    chapterIndex: curr?.chapterIndex,
                    marketplace,
                    locale,
                    available_copy,
                    hide_dates: hideDates,
                    play_range: `${i * drops},${((i + 1) * drops) - 1}`,
                    cta_label: stringIsFilled(curr?.cta_drops) ? curr.cta_drops : ctaLabel,
                    qr_url: qr_drops,
                };
                const story = {
                    template: 'snkrs_cal_stories',
                    locale,
                    marketplace,
                    available_copy,
                    search_includes: curr?.search_includes,
                    play_range: `${i * stories},${((i + 1) * stories) - 1}`,
                    cta_label: stringIsFilled(curr?.cta_stories) ? curr.cta_stories : ctaLabel,
                    qr_url: qr_stories,
                };

                const proceeding = interleave ? ['mark-for-replacement'] : [];
                const markEnd = repeats === (i + 1) ? ['mark-end'] : [];

                return [
                    ...acc,
                    drop,
                    story,
                    ...proceeding,
                    ...markEnd,
                ]
            };


            chapters = [...new Array(repeats)].reduce(createChapters, []);
        }

        return [
            ...acc,
            ...chapters
        ]
    }, []);

    const interleaveProceeding = (ch) => {
        const getRemainingChapters = () => {
            return ch.reduce((acc, curr) => {

                if (acc.start) {
                    return {
                        start: true,
                        chapters: [
                            ...acc.chapters,
                            curr,
                        ]
                    }
                }

                if (curr === 'mark-end') {
                    return {start: true, chapters: []};
                }

                return acc;

            }, {start: false, chapters: []}).chapters;
        }

        return ch?.reduce((acc, curr) => {
            let append = [curr];

            if (curr === 'mark-end' || acc.ended) {
                return {
                    ended: true,
                    chapters: acc.chapters,
                };
            }

            if (curr === 'mark-for-replacement') {
                append = getRemainingChapters();
            }

            return {
                ended: false,
                chapters: [
                    ...acc.chapters,
                    ...append,
                ]
            }
        }, {ended: false, chapters: []}).chapters;
    }

    chapters = interleaveProceeding(chapters);

    return {
        ...rawData,
        chapters,
    }
}

export async function prepareShowRunner(rawData: any, config?: JordanConfig): Promise<Array<ChapterType>> {
    await initialize();

    // inflating the data
    const hydratedData = expandChapterMacros(rawData);

    const calculateDuration = (p): number => {
        const templateType: ChapterTemplateType = p.template;
        let duration = p?.duration ?? config?.duration?.default;
        const mediaCount = p?.media?.length;

        if (templateType === 'snkrs_title') {
            duration = p?.hide_title ? 5_000 : 8_500;
        }

        if (templateType === 'title_card') {
            duration = config?.duration.interstitial;
        }

        if (templateType === 'intro') {
            duration = config?.duration.intro;
        }

        if (p.template === 'snkrs_cal' || p.template === 'snkrs_cal_stories') {
            duration = snkrsDuration(p, config);
        }

        // @TODO: Pull this logic out of this file and somehow dump it into `Services.tsx`.
        if (p.template === 'services') {
            const services = p as ServicesProps;

            duration = Math.max(services?.sections?.reduce((acc, curr) => {
                return acc + (curr?.entries?.length * 3_000);
            }, 1_500), config.duration.default);
        }

        if (p.template === 'cc_wof') {
            duration = centerCourtWofDuration(config?.duration?.cc_wof_speed);
        }

        if (p.template === 'cc_seasonal') {
            duration = centerCourtSeasonalDuration(config?.duration?.cc_seasonal_speed);
        }

        if (p.template === 'media') {
            duration = mediaCalculateDuration(p, config);
        }

        if (p.template === 'store_takeover') {
            duration = mediaCalculateDuration(p, config);
        }

        if (p.template === 'product') {
            const d = productCalculateDuration(p, config);

            if (d) {
                duration = d;
            }
        }

        console.log('duration', {templateType, duration, mediaCount});

        return duration;
    };

    const filterDisabled = ({disabled = false}) => {
        return disabled === false;
    }

    const promises = hydratedData?.chapters?.filter(filterDisabled).map(async (p): Promise<ChapterType> => {
        let {template, ...data} = p;

        // maybe if snkrs make sure we load the data first so that we can calculate the duration based on
        // the entry count...

        if (p.template === 'snkrs_cal') {
            data.drops = await loadDrops(p.marketplace);
            data.mode = 'drops';
        }

        if (p.template === 'snkrs_cal_stories') {
            //TODO: Remove hardcoded includes when editor is sending field data
            p.includes = p.search_includes ?? '';
            data.drops = await loadStories({
                marketplace: p?.marketplace,
                include_videos: p?.include_videos,
                search: {
                    includes: p?.includes?.split?.(",")
                }
            });
            data.mode = 'stories';
        }

        const insideEditor = !get(window, 'channel');

        if (insideEditor) {
            console.log('dont preload in editor');
        }

        data = await preloadAllAssets(data, {
            ...config,
            dontCache: insideEditor
        });

        return {
            data,
            template,
            skip: false,
            duration: calculateDuration({template, ...data}),
        };
    }) ?? [];

    return Promise.all(promises);
}

export function stringIsFilled(str?: string) {
    return str && str.length > 0 && str.replaceAll(' ', '') !== '';
}

export function stringFallback(content: any, defaultValue?: string) {
    return ['', null, undefined, ' '].includes(content) ? defaultValue : content;
}

export const SECOND = 1_000;

export const keyframe = (initialDelay, config: {speed: number} = {speed: 1}) => {
    let timeouts = [];
    let clears = [];
    const {speed = 1} = config;

    const trigger = (after: number, action: TimerHandler) => {
        const id = setTimeout(action, ((after + initialDelay) * SECOND) * speed);
        timeouts.push(id);

        const {trigger, clear} = keyframe(initialDelay + after, config);
        clears.push(clear);
        return trigger;
    }

    return {
        trigger,
        clear: () => {
            timeouts.forEach(i => clearTimeout(i));
            clears.forEach(i => i());
        }
    }
}

/**
 * Gets the preferred item from array falls back in the order of indexes.
 *
 * @param array
 * @param indexes
 */
export const preferredAsset = (array: Array<MediaType>, ...indexes: Array<number>): MediaType | null => {
    const index = indexes.findIndex((i) => {
        return array?.[i]?.url;
    });
    return array?.[indexes[index]];
}

export function countDown(date) {
    const now = new Date().getTime();
    // Find the distance between now and the count down date
    var distance = date - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
}

export const DEFAULT_EASE = [0.4, 0, 0, 1];

export const DEFAULT_CHAPTER_DURATION = 8;

export function channelConfig({forceAssetHeight}: { forceAssetHeight?: boolean } = {}): JordanConfig {
    const {json_config = {}} = fetchChannelInfo() ?? {};

    const calculateSize = () => {
        const MAX_HEIGHT = 3_840;
        const height = window.innerHeight;

        return height > MAX_HEIGHT ? MAX_HEIGHT : height;
    }

    return {
        duration: {
            default: get(json_config, 'duration.default', DEFAULT_CHAPTER_DURATION) * SECOND,
            interstitial: get(json_config, 'duration.interstitial', 3) * SECOND,
            intro: get(json_config, 'duration.intro', 6) * SECOND,
            snkrs: {
                overview: get(json_config, 'duration.snkrs.overview', 3) * SECOND,
                drop: get(json_config, 'duration.snkrs.drop', 10) * SECOND,
                drop_media_count: get(json_config, 'duration.snkrs.drop_media_count', 3),
                story: get(json_config, 'duration.snkrs.story', 15) * SECOND,
                story_page_count: get(json_config, 'duration.snkrs.story_page_count', 3),
            },
            cc_seasonal_speed: get(json_config, 'duration.cc_seasonal_speed'),
            cc_wof_speed: get(json_config, 'duration.cc_wof_speed'),
        },
        forceAssetHeight: forceAssetHeight ? calculateSize() : undefined,
    }
}

export function timedMedia(media: Array<MediaType>, totalDuration): Array<MediaType> {
    const filtered = media.filter(i => i?.url);
    const duration = (totalDuration / SECOND) / filtered.length;

    return filtered.map((m, i) => {
        const isLast = (i + 1) === media.length;

        return {
            ...m,
            force_duration: isLast ? duration + 1 : duration
        }
    });
}

/**
 * Checks if the canvas is blank.
 *
 * @note May be performance issue.
 *
 * @param canvas
 */
export function isCanvasBlank(canvas): boolean {
    const blank = document.createElement('canvas');

    blank.width = canvas.width;
    blank.height = canvas.height;

    return canvas.toDataURL() === blank.toDataURL();
}

export function toTitleCase(str: string): string {
    return str.split(' ')
        .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
        .join(' ');
}

export function makeDynamicFontSize(factor): Function {
    return (size) => {
        return size * factor;
    }
}

export function getCoordinates(): string {
    const meta = get(window, 'animation_store.json_meta', {});

    const {
        n = "9.1873",
        e = "45.4628",
    } = meta?.coordinates ?? {};

    return `${n}°N ${e}°E`;
}

interface QrUrlConfig {
    width?: number;
    trackChannel?: boolean;
}

export function qrUrlTrack(destination: string, config: QrUrlConfig = {}): string {
    const {width = 140, trackChannel = true} = config;
    const channelSlug = trackChannel ? get(window, 'channel.channels[0].slug', null) : null;

	const url = new URL(NIKE_QR_BASE_URL);

	url.searchParams.set('width', width.toString());
	url.searchParams.set('url', destination);

	if (channelSlug) {
		url.searchParams.set('channel-slug', channelSlug);
	}

    return url.toString();
}

export function truncate(content: string, maxLength = 20) {
    return content?.length > maxLength ? `${content?.substring(0, maxLength)}...` : content;
}

export function longestWordInString(content: string): number {
    const words = content.match(/\b(\w+)\b/g);

    return words?.reduce((acc, curr) => {
        const wordLength = curr?.length ?? 0;

        return acc > wordLength ? acc : wordLength;
    }, 0) ?? 0;
}

export function getForcedDuration(media: MediaType, defaultDuration: number = DEFAULT_CHAPTER_DURATION): number {
    const d = media?.duration ?? defaultDuration;
    let duration = media?.duration;

    if (media?.force_duration === 'full') {
        duration = d;
    } else if (media?.force_duration) {
        duration = Number(media.force_duration);
    } else if (!media?.force_duration) {
        duration = defaultDuration;
    }

    return duration;
}

export function calculateMediaDuration(media: Array<MediaType>, defaultDuration: number = DEFAULT_CHAPTER_DURATION): number {
    const duration = media?.filter(i => i)?.reduce((acc, curr) => {
        const mediaDuration = getForcedDuration(curr, defaultDuration);

        return acc + mediaDuration;
    }, 0) ?? 0;

    return duration * 1_000;
}