import { imagesUrlService, videosUrlService } from "../../../../../utils/assets-service";

export default [
    [
        {
            "name": "Intro1",
            "url": videosUrlService.getUrl('JONAH_CLASS_005_Loop_NOCROP_V7.1_2160x2880.mp4')
        },
        {
            "name": "Intro2",
            "url": videosUrlService.getUrl('JONAH_CLASS_006_Loop_NOCROP_V7.1_2160x2880.mp4')
        },
        {
            "name": "Grid1",
            "url": imagesUrlService.getUrl('grid_1.jpg', { chainedTransformations: [ 'q_72' ] })
        },
        {
            "name": "Grid2",
            "url": imagesUrlService.getUrl('grid_2.jpg', { chainedTransformations: [ 'q_72' ] })
        },
        {
            "name": "Grid3",
            "url": videosUrlService.getUrl('Nike_React._Nike.com_-_-_2021-07-20_00-24-10.mp4')
        },
        {
            "name": "Logo",
            "url": imagesUrlService.getUrl('logo_ntc.png')
        }
    ]
];
