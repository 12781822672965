import '../../../../init';
import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import {GridRunner2} from 'rise-grid-framework';
import sequence from './grids/mainv2';
import sequence2 from './grids/blockv2';
import FastLane from './fastlane';
import FastLaneViewer from './index';
import Background from './background';
import {fastlaneConfig} from './config';
import {getStoreLanguages} from '../../hyperlive/utils';
import {configOverride} from "./utils";
import FastlaneKidsTakeover from "../fastlane-kids-takeover";
import {getKidsTakeover, shouldPlayTakeover} from "../fastlane-kids-takeover/shared";
import {AnimatePresence, motion} from "framer-motion";
import {showDevHelpers} from "./devTools";

const {
  size,
  animation_json,
  info,
  waitFrames,
  showRulers,
  persistentTitle,
  zones,
  lanes,
  circleGap,
} = fastlaneConfig();
//
// // TODO remove
// const lanes = _lanes.splice(1,1)

// Fast lanes control placement in pixel map and masking of the grids to constrain them in the renderWidth
const fastLanes = lanes.map(
  ({start, end, filler}) => new FastLane(start, end, info, filler),
);

// Figure out how many grids we need especially if wrapping is necessary
const gridNumArr = fastLanes.map(lane => lane.getNumContainers());

// Figure out where filler blocks are and set sequence2Indexes to run that sequence
const sequence2Indexes = [];
lanes.forEach(({filler}, i) => {
  if (filler) {
    // const index = gridNumArr.slice(0, i).reduce((a, b) => a + b, 0);
    // const num = fastLanes[i].getNumContainers();
    // const indexes = [index];
    // if (num === 2) indexes.push(index + 1);
    // sequence2Indexes.push(...indexes);
  }
});

const {trackHeight} = info;

const overrides = configOverride();

const lineColorDefault = 100;

// Initialize grid runner class
const grids = new GridRunner2({
  grids: gridNumArr,
  sequence: sequence(trackHeight, {size: size ?? overrides?.size, showCircle: false}),
  sequence2: sequence2(trackHeight, size ?? overrides?.size),
  sequence2Indexes,
  canvasWidth: Number(info.renderWidth),
  canvasHeight: 2160,
  gridHeight: Number(trackHeight),
  gridWidth: 1300,
  grid2Width: 1625,
  backgroundColor: 0x000000,
  lineColor: lineColorDefault,
  lineThickness: 1,
  waitFrames,
  languageCount: getStoreLanguages().length,
  ...overrides,
});

const background = new Background(grids.app, {...info, svgLines: overrides?.svgLines}, zones, overrides?.lineThickness ?? 1, overrides?.lineColor ?? lineColorDefault, size ?? overrides?.size);

// Tell the fastlane class which grid container is used for each lane
fastLanes.forEach((lane, i) => {
  const num = lane.getNumContainers();
  const container1 = grids.getContainer(i);

  if (num === 1) {
    lane.setContainers(container1);
  } else {
    const container2 = grids.getContainer(i, 1);
    lane.setContainers(container1, container2);
  }
});

const loop = () => {
  background.run().then(async () => {
    await grids.run(false);
    if (overrides?.disableWebGl) {
      return;
    }
    grids.loop++;
    background.rewind().then(() => {
      grids.destroy();
      grids.fresh();
      loop();
    });
  });
};

// FOR DEVELOPMENT
showDevHelpers(
  showRulers,
  info,
  grids,
);

function FastLaneController() {
  const [playTakeover, setPlayTakeover] = useState(null);
  const kidsTakeover = getKidsTakeover();

  const startFastLane = () => {
    setPlayTakeover(false);
    const freq = kidsTakeover?.json_data?.frequency;

    if (freq) {
      shouldPlayTakeover(freq, () => {
        setPlayTakeover(true);
      })
    }
  }

  useEffect(() => {
    document.body.appendChild(grids.app.view);
    setTimeout(loop, 500);

    startFastLane();
  }, []);

  if (playTakeover === null) {
    return null;
  }

  return (
    <>
      {
        playTakeover && (
          <FastlaneKidsTakeover.FastLane
            info={info}
            data={kidsTakeover}
            onComplete={startFastLane}
          />
        )
      }
      <AnimatePresence initial={false}>
        {
          playTakeover === false && (
            <motion.div
              initial={{
                opacity: 0,
              }}
              exit={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              transition={{
                delay: 4
              }}
            >
              <FastLaneViewer
                persistentTitle={persistentTitle}
                runner={grids}
                lanes={fastLanes}
                circleGap={circleGap}
                // textJson={animation_json}
                textJson={animation_json}
                size={size ?? overrides?.size}
                qrWidth={overrides?.qrWidth}
                svgCircle={overrides.circle === 'svg'}
                svgLines={overrides?.svgLines}
                fontSizeMultiplier={overrides?.fontSizeMultiplier}
              />
            </motion.div>
          )
        }
      </AnimatePresence>
    </>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<FastLaneController/>);
