import React, {useEffect, useState} from "react";
import {motion} from "framer-motion";

export default function Flash() {
  const [flash, setFlash] = useState(false);

  useEffect(() => {
    addEventListener('flash', () => {
      setFlash(true)
      setTimeout(() => setFlash(false), 50);
    })
  })

  return (
    <motion.div
      style={{
        zIndex: 4,
        width: '100%',
        height: '100%',
        position: 'absolute',
        background: 'white',
        opacity: 0,
      }}
      animate={{
        opacity: flash ? 1 : 0,
      }}
      transition={{
        easing: 'linear',
        duration: .125,
      }}
    />
  )
}