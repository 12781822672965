import { AnimatePresence, motion } from 'framer-motion';
import React, { useState, useRef } from 'react';
import './MediaBlock.scss';
import { NIKE_EASE } from '../../../../constants/nikerise';
import ReactHlsPlayer from "react-hls-player";
import { TRANSITIONS } from '../../constants';

const DEFAULT_TRANSITION = { ease: NIKE_EASE, duration: 2 }

const MediaBlock = ({ visible = false, width, height, top, left, media, animationTiming, inset = false, darken = false }) => {

    const playerRef = useRef();

    if (media === null) {
        return <div />;
    }

    const { resource_type, subType, url } = media;
    const { intro_delay, intro_duration, media_duration, exit_duration } = animationTiming;

    const [dims, setDims] = useState({
        width: width ? width : '100px',
        height: height ? height : '100px',
        left: left ? left : 0,
        top: top ? top : 0,
        initial: true,
    });


    const ANIMATION = TRANSITIONS[animationTiming.transition];
    const INTITIAL = { ...ANIMATION.initial }
    const ANIMATE = { ...ANIMATION.animate, transition: { ...DEFAULT_TRANSITION, duration: intro_duration, delay: intro_delay } }
    const EXIT = { ...ANIMATION.exit, transition: { ...DEFAULT_TRANSITION, duration: exit_duration, delay: 0 } }


    return <AnimatePresence>
        {visible &&
            <div className={`MediaBlock inset-${inset}`} style={{ ...dims, overflow: 'hidden', position: 'absolute' }}>
                <motion.div
                    style={{
                        ...INTITIAL,
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        position: 'absolute',
                        opacity: 1,
                    }}
                    intitial={INTITIAL}
                    animate={ANIMATE}
                    exit={EXIT}
                >
                    <div className={`MediaBlock-inset`}>

                        {
                            resource_type === 'video' && url &&
                            <video autoPlay={true} muted={true} controls={false} loop={true}>
                                <source src={url} type="video/mp4"></source>
                            </video>
                        }

                        {
                            (resource_type === 'image' || subType === 'image') && url &&
                            <img src={url} />
                        }

                        {
                            (media?.resource_type === 'm3u8-video') &&

                            <ReactHlsPlayer
                                playerRef={playerRef}
                                src={media.url}
                                autoPlay={true}
                                controls={false}
                                width="100%"
                                height="auto"
                                muted={true}
                            />

                        }

                        {
                            darken && <div className={'darken'} />
                        }

                    </div>

                </motion.div>
            </div>
        }
    </AnimatePresence>

};

export default MediaBlock;
