export default `
  <div class="app live-app">
    <main class="main-container">
      <div class="main-layer"></div>
      <canvas class="LLS-canvas"></canvas>
    </main>

    <div class="overlay-container">
      <div class="corner-slot top-left">
        <div class="content-slot"></div>
        <div class="viewfinder-mark"></div>
      </div>
      <div class="corner-slot top-right">
        <div class="content-slot"></div>
        <div class="viewfinder-mark"></div>
      </div>
      <div class="corner-slot bottom-right">
        <div class="content-slot"></div>
        <div class="viewfinder-mark"></div>
      </div>
      <div class="corner-slot bottom-left">
        <div class="content-slot"></div>
        <div class="viewfinder-mark"></div>
      </div>
      <div class="corner-slot center-left">
        <div class="content-slot"></div>
      </div>
      <div class="corner-slot center-right">
        <div class="content-slot"></div>
      </div>
      <div class="corner-slot full-center">
        <div class="content-slot"></div>
      </div>
    </div>
  </div>
`;
