import React, {useMemo} from 'react';
import {motion, MotionConfig, MotionStyle, PresenceContext} from 'framer-motion';
import {NIKE_EASE} from '../../rise/constants/nikerise';
import WrapAnimatePresence, {PreventAnimationBubble} from "./WrapAnimatePresence";

interface TransitionProps {
    variant: 'wipe-y' | 'wipe-x' | 'wipe-x-reverse' | 'slide-y' | 'wipe-slide-x' | 'fade';
    children: any;
    style?: object;
    cover?: boolean;
    initial?: boolean;
    preventInitial?: boolean;
    preventExit?: boolean;
    delay?: number;
    delayExit?: number;
    duration?: number;
    preventChildExit?: boolean;
    preventTransitions?: boolean;
}

export default function Transition({
                                       variant,
                                       duration = 2,
                                       delay = 0,
                                       delayExit = 0,
                                       preventExit = false,
                                       preventChildExit = false,
                                       preventInitial = false,
                                       initial = true,
                                       children,
                                       preventTransitions = false,
                                       cover,
                                       style = {}
                                   }: TransitionProps) {
    const animation = {
        'fade': {
            initial: {
                opacity: 0,
            },
            animate: {
                opacity: 1,
            },
            exit: {
                opacity: 0,
            },
        },
        'wipe-x': {
            initial: {
                clipPath: initial ? `inset(0% 100% 0% 0%)` : 'inset(0% 0% 0% 0%)',
            },
            animate: {
                clipPath: 'inset(0% 0% 0% 0%)',
            },
            exit: {
                clipPath: `inset(0% 0% 0% 100%)`,
            },
        },
        'wipe-x-reverse': {
            initial: {
                clipPath: initial ? `inset(0% 0% 0% 100%)` : 'inset(0% 0% 0% 0%)',
            },
            animate: {
                clipPath: 'inset(0% 0% 0% 0%)',
            },
            exit: {
                clipPath: `inset(0% 100% 0% 0%)`,
            },
        },
        'wipe-slide-x': {
            initial: {
                clipPath: initial ? `inset(0% 100% 0% 0%)` : 'inset(0% 0% 0% 0%)',
            },
            animate: {
                clipPath: 'inset(0% 0% 0% 0%)',
            },
            exit: {
                clipPath: `inset(0% 0% 0% 100%)`,
            },
        },
        'none': {},
        'wipe-y': {
            initial: {
                clipPath: initial ? `inset(0% 0% 100% 0%)` : 'inset(0%, 0%, 0%, 0%)',
            },
            animate: {
                clipPath: 'inset(0% 0% 0% 0%)'
            },
            exit: {
                clipPath: `inset(100% 0% 0% 0%)`,
            },
        },
        'slide-y': {
            initial: {y: '10%'},
            animate: {y: '0%'},
            exit: {y: '-10%'},
        },
        'slide-x-in': {
            initial: {x: '-10%'},
            animate: {x: '0%'},
            exit: {x: '0%'},
        }
    }

    const active = animation[variant];

    const shouldCover: MotionStyle = cover ? {
        position: 'absolute',
        left: '0',
        top: 0,
    } : {};

    const content = useMemo(() => {
        if (variant === 'wipe-slide-x') {
            return (
                <motion.div
                    style={{
                        width: '100%',
                        height: '100%',
                        ...shouldCover,
                        ...style,
                    }}
                    transition={{
                        duration,
                        delay,
                        ease: NIKE_EASE
                    }}
                    initial={animation['slide-x-in'].initial}
                    animate={animation['slide-x-in'].animate}
                    exit={preventExit ? animation['slide-x-in'].animate : animation['slide-x-in'].exit}
                >
                    {children}
                </motion.div>
            )
        }

        return children
    }, [variant, children]);


    return (
        <motion.div
            style={{
                width: '100%',
                height: '100%',
                ...shouldCover,
                ...style,
            }}
            transition={{
                duration,
                delay,
                ease: NIKE_EASE
            }}
            initial={preventInitial ? active.animate : active?.initial}
            animate={active.animate}
            exit={preventExit ? active.animate : active.exit}
        >
            {
                preventChildExit ? (
                    <PreventAnimationBubble>
                        {content}
                    </PreventAnimationBubble>
                ) : content
            }

        </motion.div>
    )
}