import {useMemo, useRef} from "react";
import Circle from "./circle";
import {configOverride} from "./utils";

interface CircleContainerProps {
    count: number;
    gap: number;
    height?: number;
    textOffset?: number;
}

export default function CircleContainer({height, count = 1, gap = 0, textOffset = 0}: CircleContainerProps) {
    const containerRef = useRef<HTMLDivElement>();

    const width = useMemo(() => {
        const w = height * 1.2;
        if (!w) {
            return undefined;
        }

        return w * (count + 1);
    }, [height]);

    const circles = useMemo(() => {
        const overrides = configOverride();
        if (overrides.circle === 'false') {
            return [];
        }

        return [...new Array(count)].map((_, i) => i);
    }, [count, containerRef.current]);

    const left = height === 225 ? 310 : 295;

    return (
        <div
            ref={containerRef}
            style={{
                position: 'absolute',
                // top: `${.04 * height}px`,
                top: 0,
                height: `${height + 2}px`,
                width: width ?? '1300px',
                left: left - Number(textOffset),
                display: 'block',
                overflow: 'hidden',
                marginLeft: 0,
                // border: `1px solid white`,
                // boxSizing: 'border-box',
            }}
        >
            {
                circles.map((_, i) => (
                    <Circle
                        key={i}
                        index={i}
                        height={height + 2}
                        marginRight={gap}
                        renderLineRight={i === circles.length - 1}
                    />
                ))
            }
        </div>
    )
}