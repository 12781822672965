import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import MediaBlock from '../../components/MediaBlock/MediaBlock.js';
import './Background.scss';
import { MEDIA_TRANSITIONS } from '../../constants';

const TIMINGS = {
    text_intro_delay: .75,
    text_intro_duration: 5,
    media_transition: MEDIA_TRANSITIONS.LeftCorner,
    media_intro_delay: 1,
    media_intro_duration: 2,
    media_duration: 3,
    media_exit_duration: 2
}

const FALLBACK_VIDEO = 'https://res.cloudinary.com/jbboerner/video/upload/v1678584272/nikehyperlive/1678584264524.mp4'

const Background = ({ chapter, chapters }) => {


    return <div className={`Background`}>

        {
            chapters?.[0]?.media?.[0]?.url &&

            <MediaBlock
                key={`chapter0`}
                visible={chapter === 0}
                media={
                    {
                        resource_type: 'video',
                        url: chapters?.[0]?.media?.[0]?.url
                    }
                }
                left={"27.777%"}
                top={'0%'}
                width={"72.222%"}
                height={"76.04%"}
                animationTiming={{
                    transition: MEDIA_TRANSITIONS.TopToBottom,
                    intro_delay: TIMINGS.media_intro_delay,
                    intro_duration: TIMINGS.media_intro_duration,
                    media_duration: TIMINGS.media_duration,
                    exit_duration: TIMINGS.media_exit_duration

                }}
            />
        }

        {
            chapters?.[1]?.media?.[0]?.url &&

            <MediaBlock
                key={`chapter1`}
                visible={chapter === 1}
                media={
                    {
                        resource_type: 'video',
                        url: chapters?.[1]?.media?.[0]?.url
                    }
                }
                left={"0%"}
                top={'0%'}
                width={"100%"}
                height={"100%"}
                animationTiming={{
                    transition: MEDIA_TRANSITIONS.RightToLeft,
                    intro_delay: 0,
                    intro_duration: TIMINGS.media_intro_duration,
                    media_duration: TIMINGS.media_duration,
                    exit_duration: TIMINGS.media_exit_duration
                }}
            />
        }

        {

            chapters?.[2]?.media?.[0]?.url &&

            <MediaBlock
                key={`chapter2`}
                visible={chapter === 2}
                media={
                    {
                        resource_type: 'video',
                        url: chapters?.[2]?.media?.[0]?.url
                    }
                }
                left={"0%"}
                top={'0%'}
                width={"100%"}
                height={"62.5%"}
                animationTiming={{
                    transition: MEDIA_TRANSITIONS.RightToLeft,
                    intro_delay: 0,
                    intro_duration: TIMINGS.media_intro_duration,
                    media_duration: TIMINGS.media_duration,
                    exit_duration: TIMINGS.media_exit_duration
                }}
            />
        }

        {

            chapters?.[3]?.media?.[0]?.url &&

            <MediaBlock
                key={`chapter3`}
                visible={chapter === 3}
                media={
                    {
                        resource_type: 'video',
                        url: chapters?.[3]?.media?.[0]?.url
                    }
                }
                left={"0%"}
                top={'0%'}
                width={"100%"}
                height={"62.5%"}
                animationTiming={{
                    transition: MEDIA_TRANSITIONS.TopBack,
                    intro_delay: 0,
                    intro_duration: TIMINGS.media_intro_duration,
                    media_duration: TIMINGS.media_duration,
                    exit_duration: TIMINGS.media_exit_duration
                }}
            />
        }

        {

            chapters?.[4]?.media?.[0]?.url &&

            <MediaBlock
                key={`chapter4`}
                visible={chapter === 4}
                media={
                    {
                        resource_type: 'video',
                        url: chapters?.[4]?.media?.[0]?.url
                    }
                }
                left={"0%"}
                top={'0%'}
                width={"100%"}
                height={"100%"}
                animationTiming={{
                    transition: MEDIA_TRANSITIONS.RightToLeft,
                    intro_delay: 0,
                    intro_duration: TIMINGS.media_intro_duration,
                    media_duration: TIMINGS.media_duration,
                    exit_duration: TIMINGS.media_exit_duration
                }}
            />
        }

    </div>

};

export default Background;
