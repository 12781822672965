export default [
    {
      "id": 0,
      "mask": [
        {
          "vertical": [
            "LeftPrimary",
            "PrimaryRight"
          ],
          "horizontal": [
            "Top Border",
            "Equator"
          ],
          "container": "mask",
          "grid": 0,
          "polyOnly": false,
          "name": "TopPrimary",
          "assetEndPosition": [
            0,
            0
          ],
          "coverFill": true,
          "asset": "0",
          "fadeIn": true,
          "assetStartPosition": [
            0,
            0
          ]
        },
        {
          "asset": "2",
          "grid": 0,
          "polyOnly": false,
          "scaleWithMask": false,
          "horizontal": [
            "Bottom1",
            "AuxRight1"
          ],
          "vertical": [
            "AuxRight1",
            "PrimaryRight"
          ],
          "name": "SecondaryRight",
          "container": "mask",
          "coverFill": true,
          "assetPositionWithMask": false
        },
        {
          "grid": 0,
          "fadeIn": true,
          "coverFill": true,
          "scaleWithMask": true,
          "asset": "1",
          "container": "mask",
          "polyOnly": false,
          "horizontal": [
            "Bottom1",
            "Bottom Border"
          ],
          "assetPositionWithMask": false,
          "name": "SecondaryLeft",
          "vertical": [
            "LeftPrimary",
            "AuxEquator1"
          ]
        },
        {
          "polyOnly": true,
          "vertical": [
            "AuxLeft1",
            "PrimaryRight"
          ],
          "grid": 0,
          "horizontal": [
            "Equator",
            "Bottom1"
          ],
          "assetPositionWithMask": false,
          "_colorFill": true,
          "name": "TextPrimary"
        }
      ],
      "horizontal": [
        {
          "drawToPosition": 0,
          "drawFromPosition": 100,
          "name": "Equator",
          "drawFull": true,
          "startPosition": 50
        },
        {
          "extend": true,
          "name": "Bottom1",
          "drawToLine": "PrimaryRight",
          "drawFromLine": "LeftPrimary",
          "startPosition": 75,
          "drawFull": false
        },
        {
          "extend": false,
          "drawToLine": "PrimaryRight",
          "startPosition": 87.5,
          "name": "AuxRight1",
          "drawFromLine": "AuxEquator1",
          "drawFull": false
        }
      ],
      "delayVertical": false,
      "duration": "1500",
      "drawBorder": true,
      "delayHorizontal": false,
      "vertical": [
        {
          "drawFullDelay": 1500,
          "endPosition": 5.6,
          "name": "AuxLeft1",
          "drawFull": true,
          "drawFromLine": "Equator",
          "drawToLine": "Bottom1",
          "startPosition": 5.6
        },
        {
          "drawToLine": "Bottom Border",
          "startPosition": 50,
          "drawFromLine": "Top Border",
          "name": "LeftPrimary",
          "drawFull": true,
          "endPosition": 5.6
        },
        {
          "drawFromLine": "Bottom1",
          "startPosition": 50,
          "name": "AuxEquator1",
          "drawFull": true,
          "drawToLine": "Bottom Border"
        },
        {
          "endAlpha": 1,
          "startAlpha": 0,
          "drawFromLine": "Bottom1",
          "name": "AuxRight1",
          "drawFull": true,
          "drawToLine": "AuxRight1",
          "endPosition": 72.2,
          "startPosition": 50
        },
        {
          "drawToLine": "Bottom Border",
          "drawFromLine": "Top Border",
          "startPosition": 50,
          "name": "PrimaryRight",
          "endPosition": 94.4,
          "drawFull": true
        }
      ],
      "name": "MainA",
      "circle": []
    },
    {
      "vertical": [
        {
          "drawFromLine": "Equator",
          "endPosition": 5.6,
          "startPosition": 5.6,
          "drawFullDelay": 0,
          "drawFull": true,
          "drawToLine": "Bottom1",
          "name": "AuxLeft1"
        },
        {
          "drawFromLine": "Top Border",
          "drawToLine": "Bottom Border",
          "drawFull": true,
          "endPosition": 5.6,
          "startPosition": 5.6,
          "name": "LeftPrimary"
        },
        {
          "drawToLine": "Bottom Border",
          "startPosition": 50,
          "drawFromLine": "Bottom1",
          "drawFull": true,
          "name": "AuxEquator1",
          "endPosition": 50
        },
        {
          "drawFull": true,
          "startPosition": 72.2,
          "endPosition": 72.2,
          "drawToLine": "AuxRight1",
          "drawFromLine": "Bottom1",
          "name": "AuxRight1",
          "delay": 250
        },
        {
          "name": "PrimaryRight",
          "startPosition": 94.4,
          "endPosition": 94.4,
          "drawToLine": "Bottom Border",
          "drawFull": true,
          "drawFromLine": "Top Border"
        }
      ],
      "circle": [],
      "name": "MainB",
      "id": 1,
      "drawBorder": true,
      "horizontal": [
        {
          "drawFromPosition": 100,
          "endPosition": 50,
          "startPosition": 50,
          "drawToPosition": 0,
          "drawFull": true,
          "name": "Equator"
        },
        {
          "name": "AuxLeft1",
          "startPosition": 50,
          "endPosition": 62.5,
          "extend": true,
          "drawFromLine": "LeftPrimary",
          "drawFull": false,
          "drawToLine": "AuxLeft1"
        },
        {
          "endPosition": 75,
          "drawFromLine": "LeftPrimary",
          "extend": true,
          "startPosition": 75,
          "drawFull": true,
          "drawToLine": "PrimaryRight",
          "name": "Bottom1"
        },
        {
          "extend": false,
          "startPosition": 87.5,
          "drawFromLine": "AuxEquator1",
          "drawFull": true,
          "name": "AuxRight1",
          "drawToLine": "PrimaryRight",
          "endPosition": 87.5
        }
      ],
      "delayVertical": false,
      "delayHorizontal": false,
      "mask": [
        {
          "horizontal": [
            "Top Border",
            "Equator"
          ],
          "asset": "0",
          "container": "mask",
          "vertical": [
            "LeftPrimary",
            "PrimaryRight"
          ],
          "name": "TopPrimary",
          "polyOnly": false,
          "assetStartPosition": [
            0,
            0
          ],
          "grid": 0,
          "assetEndPosition": [
            0,
            0
          ],
          "coverFill": true
        },
        {
          "horizontal": [
            "Equator",
            "Bottom1"
          ],
          "_colorFill": true,
          "assetPositionWithMask": false,
          "polyOnly": true,
          "name": "TextPrimary",
          "grid": 0,
          "vertical": [
            "AuxLeft1",
            "PrimaryRight"
          ]
        },
        {
          "container": "mask",
          "grid": 0,
          "polyOnly": false,
          "horizontal": [
            "Bottom1",
            "AuxRight1"
          ],
          "scaleWithMask": true,
          "vertical": [
            "AuxRight1",
            "PrimaryRight"
          ],
          "asset": "2",
          "assetPositionWithMask": false,
          "name": "SecondaryRight",
          "coverFill": true
        },
        {
          "name": "SecondaryLeft",
          "assetPositionWithMask": false,
          "horizontal": [
            "Bottom1",
            "Bottom Border"
          ],
          "grid": 0,
          "scaleWithMask": true,
          "container": "mask",
          "vertical": [
            "LeftPrimary",
            "AuxEquator1"
          ],
          "asset": "1",
          "polyOnly": false,
          "coverFill": true
        }
      ],
      "duration": "1500"
    },
    {
      "vertical": [
        {
          "drawFullDelay": 0,
          "drawToLine": "Bottom1",
          "startPosition": 5.6,
          "drawFull": true,
          "drawFromLine": "Equator",
          "name": "AuxLeft1"
        },
        {
          "startPosition": 5.6,
          "drawFromLine": "Top Border",
          "drawToLine": "Bottom Border",
          "drawFull": true,
          "name": "LeftPrimary"
        },
        {
          "startPosition": 50,
          "name": "AuxEquator1",
          "drawFull": true,
          "drawToLine": "Bottom Border",
          "drawFromLine": "Bottom1"
        },
        {
          "drawToLine": "AuxRight1",
          "startPosition": 72.2,
          "drawFromLine": "Bottom1",
          "delay": 250,
          "drawFull": true,
          "name": "AuxRight1"
        },
        {
          "drawFromLine": "Top Border",
          "startPosition": 94.4,
          "drawFull": true,
          "drawToLine": "Bottom Border",
          "name": "PrimaryRight"
        }
      ],
      "horizontal": [
        {
          "drawFromPosition": 100,
          "name": "Equator",
          "startPosition": 50,
          "drawToPosition": 0,
          "drawFull": true
        },
        {
          "drawToLine": "AuxLeft1",
          "startPosition": 62.5,
          "name": "AuxLeft1",
          "extend": true,
          "drawFull": true,
          "drawFromLine": "LeftPrimary"
        },
        {
          "drawFull": true,
          "name": "Bottom1",
          "startPosition": 75,
          "drawToLine": "PrimaryRight",
          "drawFromLine": "LeftPrimary",
          "extend": true
        },
        {
          "name": "AuxRight1",
          "drawToLine": "PrimaryRight",
          "drawFromLine": "AuxEquator1",
          "drawFull": true,
          "startPosition": 87.5,
          "extend": false
        }
      ],
      "duration": "1500",
      "delayHorizontal": false,
      "id": 1,
      "delayVertical": false,
      "circle": [],
      "drawBorder": true,
      "mask": [
        {
          "polyOnly": false,
          "grid": 0,
          "horizontal": [
            "Top Border",
            "Equator"
          ],
          "assetEndPosition": [
            0,
            0
          ],
          "assetStartPosition": [
            0,
            0
          ],
          "asset": "0",
          "container": "mask",
          "vertical": [
            "LeftPrimary",
            "PrimaryRight"
          ],
          "coverFill": true,
          "name": "TopPrimary"
        },
        {
          "vertical": [
            "AuxLeft1",
            "PrimaryRight"
          ],
          "polyOnly": true,
          "name": "TextPrimary",
          "assetPositionWithMask": false,
          "_colorFill": true,
          "horizontal": [
            "Equator",
            "Bottom1"
          ],
          "grid": 0
        },
        {
          "assetPositionWithMask": false,
          "container": "mask",
          "asset": "2",
          "polyOnly": false,
          "name": "SecondaryRight",
          "grid": 0,
          "scaleWithMask": true,
          "coverFill": true,
          "horizontal": [
            "Bottom1",
            "AuxRight1"
          ],
          "vertical": [
            "AuxRight1",
            "PrimaryRight"
          ]
        },
        {
          "asset": "1",
          "scaleWithMask": true,
          "horizontal": [
            "Bottom1",
            "Bottom Border"
          ],
          "assetPositionWithMask": false,
          "coverFill": true,
          "container": "mask",
          "grid": 0,
          "name": "SecondaryLeft",
          "vertical": [
            "LeftPrimary",
            "AuxEquator1"
          ],
          "polyOnly": false
        }
      ],
      "name": "MainB"
    }
  ]