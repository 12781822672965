import React from 'react';
import {CenterCourtGradient, MediaType, ThemeType} from "../shared";
import Transition from "../components/Transition";
import Background from "../components/Background";
import Slide from "../components/Slide";
import StoreJumpman from "../components/StoreJumpman";
import Typography from "../components/Typography";
import {JordanConfig} from "../components/ChapterRunner";
import GradientPlayer from "../components/GradientPlayer";

interface IntroProps {
    variant: 'general' | 'store';
    fly_asset: MediaType;
    title_asset: MediaType;
    supertitle: string;
    theme: ThemeType;
    config: JordanConfig;
    gradient: CenterCourtGradient,
    disable_logo?: boolean;
}

const ASSETS = {
    jordan_1: new URL('../demo/center-court/jordan_1.png?as=webp', import.meta.url),
    jordan_2: new URL('../demo/center-court/jordan_2.png?as=webp', import.meta.url),
    title_wof: new URL('../demo/center-court/intro_wof_title_no_logo.png?as=webp', import.meta.url),
    title_milan: new URL('../demo/center-court/intro_milan_title.png?as=webp', import.meta.url),
    logo: new URL('../assets/images/logo.png?as=webp', import.meta.url),
};

const toAsset = (url) => {
    return {
        url,
        resource_type: 'image',
    } as MediaType;
}

export default function CenterCourtIntro(props: IntroProps) {
    let component = props.variant === 'store' ?
        <Store {...props} /> : <General {...props} />;

    return (
        <Transition delayExit={1.7} variant={'wipe-x'} cover>
            {component}
        </Transition>
    )
}

function General({
                     disable_logo = false,
                     title_asset = toAsset(ASSETS.title_wof),
                     fly_asset = toAsset(ASSETS.jordan_2),
                     supertitle = 'Welcome to',
                     theme,
                     config,
                 }: IntroProps) {
    const {width, isRectangular} = config?.meta;

    return (
        <Background variant={'black-print-wash'}>
            <div style={{position: 'relative', width: '100%', height: disable_logo ? '105%' : '100%'}}>
                <FlyBy isRectangular={isRectangular} asset={fly_asset}/>

                <SlideElement>
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                    }}>
                        <Supertitle isRectangular={isRectangular} supertitle={supertitle} width={width}/>
                        <img
                            height={isRectangular ? '108%' : '100%'}
                            src={title_asset.url}
                            alt=""
                            style={{
                                marginTop: isRectangular ? '-2%' : undefined,
                            }}
                        />

                        {
                            !disable_logo && (
                                <div style={{position: 'absolute', bottom: isRectangular ? width * 0.03 : width * 0.05}}>
                                    <div>
                                        <StoreJumpman
                                            theme={theme}
                                            style={{
                                                width: isRectangular ? width / 14 : width / 8
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </SlideElement>
            </div>
        </Background>
    )
}

function Store({
                   title_asset = toAsset(ASSETS.title_milan),
                   fly_asset = toAsset(ASSETS.jordan_1),
                   supertitle = 'Welcome to',
                   config,
               }: IntroProps) {
    const {width, isRectangular = false} = config?.meta;

    return (
        <Background variant={'black-grit'}>
            <div style={{position: 'relative', width: '100%', height: '100%'}}>
                <FlyBy isRectangular={isRectangular} asset={fly_asset}/>

                <SlideElement>
                    <Supertitle isRectangular={isRectangular} supertitle={supertitle} width={width}/>
                    <img
                        height={isRectangular ? '108%' : '100%'}
                        src={title_asset.url}
                        alt=""
                        style={{
                            marginTop: isRectangular ? '-2%' : undefined,
                        }}
                    />
                </SlideElement>
            </div>
        </Background>
    )
}

function FlyBy({asset, isRectangular}: { asset: MediaType, isRectangular: boolean }) {
    return (
        <div style={{position: 'absolute', width: '100%', height: '100%', top: 0, zIndex: 2}}>
            <Slide
                position={'absolute'}
                direction={'to-left'}
                showAfter={2}
                // hideAfter={4}
                duration={1.5}
            >
                <div style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <img
                        style={{
                            width: '100%',
                            height: isRectangular ? '108%' : '100%',
                            objectFit: isRectangular ? 'scale-down' : 'cover',
                            marginTop: isRectangular ? '4%' : undefined,
                        }}
                        src={asset.url} alt=""/>
                </div>
            </Slide>
        </div>
    )
}

function SlideTitle({asset}: { asset: MediaType }) {
    return (
        <Slide direction={'to-top'} duration={1.5} showAfter={.5} multiplier={1.1}>
            <div style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <img height={'100%'} src={asset.url} alt=""/>
            </div>
        </Slide>
    )
}

function SlideElement({children}: { children: React.ReactNode }) {
    return (
        <Slide direction={'to-top'} duration={1.5} showAfter={.5} multiplier={1.1}>
            {children}
        </Slide>
    )
}

function Supertitle({
                        supertitle,
                        width,
                        isRectangular
                    }: { supertitle: string, width: number, isRectangular: boolean, }) {
    return (
        <div
            style={{
                position: 'absolute',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                top: isRectangular ? width * .05 : width * 0.12,
            }}
        >
            <Typography
                align={'center'}
                variant={'pp-ultralight'}
                color={'white'}
                size={isRectangular ? width * 0.02 : width * 0.035}
            >
                {supertitle}
            </Typography>
        </div>
    )
}