import { useEffect, useState } from 'react';

import { setAnimationTimeout } from '../shared';


export default function useAreFontsLoaded() {
    const [areFontsLoaded, setAreFontsLoaded] = useState(false);

    useEffect(() => {
        let cancelTimeout: () => void;
        document.fonts.ready.then(() => {
            // Timeout is needed here because loaded fonts are not always applied at this moment.
            // 100ms looks good enought and not visible to a user.
            cancelTimeout = setAnimationTimeout(() => setAreFontsLoaded(true), 100);
        });

        return () => {
            cancelTimeout?.();
        };
    }, []);

    return areFontsLoaded;
};
