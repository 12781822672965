// React
import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';

// Hyperlive Utils
import * as Hyperlive from '../../hyperlive/utils.js';
import * as Logger from '../../utils/console';

// Globals
import '../../../../init';
import '../../styles/main.scss';

// Template
import ScrollTicker from './ScrollTicker.js';
import { onReady } from '../../../../init';

///////////////////////////////////////////////////////////
// Hyperlive JSON Data
Logger.log('ANIMATIONS', window.animations);

const { json_data } = Hyperlive.fetchJSON();


// const json_data = {}

Logger.highlight('* JSON')
Logger.log('* JSON', json_data);

///////////////////////////////////////////////////////////
console.log('RENDER');
(async () => {
	console.log('not ready...');
	await onReady();

	const root = ReactDOM.createRoot(document.getElementById('root'));
	root.render(<ScrollTicker json_data={json_data} />);
})();
