import { imagesUrlService } from "../../../../utils/assets-service";

export default {
  animation: {
    json_data: {
      type: 'low',
      chapters: {
        look_book: {
          '1_portrait': {
            assets: [{
              url: imagesUrlService.getUrl('1-portrait-1.jpg', { folderName: 'live/pdp-look-book' , chainedTransformations: [ 'e_make_transparent', 'e_pixelate_faces', 'ar_1.0,c_fill,g_face', 'f_png' ] })
            }]
          },
          '2_portrait': {
            assets: [{
              url: imagesUrlService.getUrl('2-portrait-1.jpg', { folderName: 'live/pdp-look-book' }),
            }, {
              url: imagesUrlService.getUrl('2-portrait-2.png', { folderName: 'live/pdp-look-book' }),
            }]
          },
          '1_landscape': {
            assets: [{
              url: imagesUrlService.getUrl('1-landscape-1.png', { folderName: 'live/pdp-look-book' })
            }, {
              url: imagesUrlService.getUrl('1-landscape-2.jpg', { folderName: 'live/pdp-look-book' }),
            }, {
              url: imagesUrlService.getUrl('1-landscape-3.jpg', { folderName: 'live/pdp-look-book' }),
            }]
          },
          '2_landscape': {
            assets: [{
              url: imagesUrlService.getUrl('2-landscape-1.png', { folderName: 'live/pdp-look-book' }),
            }, {
              url: imagesUrlService.getUrl('2-landscape-2.jpg', { folderName: 'live/pdp-look-book' }),
            }]
          }
          // assets: [{
          //   url: 'https://res.cloudinary.com/jbboerner/image/upload/v1645193265/live/look-build/look-build-2.png'
          // }, {
          //   url: 'https://res.cloudinary.com/jbboerner/image/upload/v1645193265/live/look-build/look-build-5.jpg'
          // }, {
          //   url: 'https://res.cloudinary.com/jbboerner/image/upload/v1645193266/live/look-build/look-build-1.jpg'
          // }, {
          //   url: 'https://res.cloudinary.com/jbboerner/image/upload/v1645193265/live/look-build/look-build-3.png'
          // }, {
          //   url: 'https://res.cloudinary.com/jbboerner/image/upload/v1645193265/live/look-build/look-build-4.jpg'
          // }, {
          //   url: 'https://res.cloudinary.com/jbboerner/image/upload/v1645193265/live/look-build/look-build-8.png'
          // }, {
          //   url: 'https://res.cloudinary.com/jbboerner/image/upload/v1645193265/live/look-build/look-build-6.jpg'
          // }, {
          //   url: 'https://res.cloudinary.com/jbboerner/image/upload/v1645193263/live/look-build/look-build-9.png'
          // }, {
          //   url: 'https://res.cloudinary.com/jbboerner/image/upload/v1645193265/live/look-build/look-build-7.png'
          // }],
        },
        full_body: {
          assets: [{
            url: imagesUrlService.getUrl('FLEECE-HOODIE-W-RED-5.jpg', { folderName: 'live/pdp-full-body' })
          }, {
            url: imagesUrlService.getUrl('FLEECE-HOODIE-W-RED-2.jpg', { folderName: 'live/pdp-full-body' })
          }, {
            url: imagesUrlService.getUrl('FLEECE-HOODIE-W-RED-4.jpg', { folderName: 'live/pdp-full-body' })
          }],
        },
        head_to_toe: {
          assets: [{
            url: imagesUrlService.getUrl('FA21_ETHOS_M_FUTURE_IS_IN_THE_AIR_LTCC_LOOK_254_12721-68541.jpg', { folderName: 'live/pdp-head-toe' })
          }, {
            url: imagesUrlService.getUrl('FA21_ETHOS_M_FUTURE_IS_IN_THE_AIR_LTCC_LOOK_254_12723-68543.jpg', { folderName: 'live/pdp-head-toe' })
          }, {
            url: imagesUrlService.getUrl('FA21_ETHOS_M_FUTURE_IS_IN_THE_AIR_LTCC_LOOK_254_12730-68544.jpg', { folderName: 'live/pdp-head-toe' })
          }, {
            url: imagesUrlService.getUrl('FA21_ETHOS_M_FUTURE_IS_IN_THE_AIR_LTCC_LOOK_254_12723-68543_FLIP.jpg', { folderName: 'live/pdp-head-toe' })
          }],
        },
      },
    }
  }
}
