import React from 'react';
import {color, getColor} from '../styles/color';

export interface TypographyProps {
    children: any;
    variant: 'jordan-condensed' | 'jordan' | 'pp-ultralight' | 'pp-ultralight-italic' | 'pp-regular' | 'mono' | 'brut' | 'neue';
    style?: any;
    size?: number | 'xl' | string;
    align?: 'center' | 'left' | 'right';
    color?: color | string;
    uppercase?: boolean;
    scaleX?: number;
}

export default function Typography({children, variant, style, uppercase, scaleX = 0.5, size, color = 'black', align = 'left'}: TypographyProps) {

    const fontTypes = {
        'jordan-condensed': {
            fontFamily: 'jordan-display',
            fontWeight: 700,
            transform: `scaleX(${scaleX})`,
            transformOrigin: 'left',
            width: `${(1 / scaleX)* 100}%`, // because it's squished
            lineHeight: '80%',
            fontSize: {
                'xl': 184,
            }?.[size] ?? size,
        },
        'jordan': {
            fontFamily: 'jordan-display',
            fontWeight: 500,
            fontSize: size,
        },
        'brut': {
            fontFamily: 'Brut Grotesque',
            fontWeight: 'bold',
            fontSize: size,
            letterSpacing: '-.04em',
            lineHeight: `${Number(size) * .8}px`,
        },
        'neue': {
            fontFamily: 'Helvetica Neue',
            fontSize: size,
            lineHeight: `${Number(size) * .8}px`,
        },
        'pp-ultralight': {
            fontFamily: 'pp-ultralight',
            fontSize: size,
        },
        'pp-regular': {
            fontFamily: 'pp-regular',
            lineHeight: '120%',
            fontSize: size,
        },
        'pp-ultralight-italic': {
            fontFamily: 'pp-ultralight',
            fontStyle: 'italic',
            fontSize: size,
        },
        'mono': {
            fontFamily: 'monospace-typewriter',
            fontSize: size,
        },
    };


    return (
        <div style={{
            ...fontTypes[variant] ?? {fontSize: size},
            textAlign: align,
            whiteSpace: 'break-spaces',
            color: color ? getColor?.(color) : undefined,
            textTransform: uppercase ? 'uppercase' : undefined,
            ...style,
        }}>
            {children}
        </div>
    )
}