import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { isEmpty, get } from "lodash";
// import Codification from '../Codification';
import Codification from '../../../../components/Codification';
import { NIKE_EASE } from '../../../../constants/nikerise';
import * as Logger from '../../../../utils/console';
import './FeatureCard.scss';


const CONFIG = {
	START: '-102%',
	END: 0,
	DELAY: 0
}

const FeatureCard = (config = {}) => {
	if (isEmpty(config)) {
		return null;
	}

	const { card, start } = config;
	const cardImage = get(card, 'image', null);
	const cardHeading = get(card, 'heading', null);

	// Logger.highlight('FeatureCard', start)

	return <div className="FeatureCard">

		{/* <motion.div
			className={'FeatureCard-cover'}

			initial={{
				translateX: CONFIG.END,
				width: dims.width - 4,
				height: dims.height - 4,
				margin: '2px'
			}}
			animate={{
				translateX: start ? CONFIG.END : CONFIG.START,
			}}
			transition={{ ease: NIKE_EASE, duration: 1 }}
		> */}
		<div className={'FeatureCard-cover'}>
			{cardImage && <img src={cardImage} />}
		</div>
		{/* </motion.div>

		<motion.div
			className={'FeatureCard-headline'}

			initial={{
				translateX: CONFIG.START,
				width: dims.width - 4,
				height: dims.height - 4,
				margin: '2px'
			}}
			animate={{
				translateX: start ? CONFIG.END : CONFIG.START,
			}}
			transition={{ ease: NIKE_EASE, duration: 1 }}
		> */}
		<div className={'FeatureCard-caption'}>
			{cardHeading && (
				<Codification
					text={cardHeading}
					typography={{
						size: 'lg',
						primary: true,
						// typeStyle: { fontSize: '24px', lineHeight: '24px' },
						tag: 'span',
					}}
					characterSwitchAmount={6}
					characterNextTrigger={2}
					characterTimeout={30}
					start={start}
				/>
			)}
		</div>

		{/* </motion.div> */}

	</div>

};

export default FeatureCard;
