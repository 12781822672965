import React, { CSSProperties, MutableRefObject, useEffect, useRef, useState} from 'react';
import { motion, useTime, useTransform } from 'framer-motion';

import '../styles/components/ticker.scss';
import useAreFontsLoaded from '../hooks/useAreFontsLoaded';

type TickerProps = {
    children: any;
    direction?: 'forwards' | 'backwards';
    duration?: number;
    style?: CSSProperties;
};

export default function Ticker(props: TickerProps) {
    const {
        children,
        direction = 'forwards',
        duration = 10000,
        style = {},
    } = props;
    const container = useRef<HTMLDivElement>();
    const sampleRef = useRef<HTMLDivElement>();
    const [size, setSize] = useState<null | { width: number, height: number }>(null);
    const [amountOfCopies, setAmountOfCopies] = useState(0);
    const [sampleWidth, setSampleWidth] = useState(0);
    const areFontsLoaded = useAreFontsLoaded();

    useEffect(() => {
        if (!container.current) {
            return;
        }

        setSize({
            height: container.current.clientHeight,
            width: container.current.clientWidth,
        });
    }, [container.current]);

    useEffect(() => {
        if (!areFontsLoaded || !sampleRef.current) {
            return;
        }

        const {width: sampleWidth} = sampleRef.current.getBoundingClientRect();

        setSampleWidth(sampleWidth);

        if (sampleWidth) {
            setAmountOfCopies(Math.floor(size.width / sampleWidth + 1));
        }
    }, [areFontsLoaded, sampleRef.current]);

    const createContent = (
        key: string | number,
        ref: MutableRefObject<HTMLDivElement> | null = null
    ) => (
        <div
            ref={ref}
            key={key}
            className='ticker-sample'
        >
            {children}
        </div>
    );

    const sample = createContent(0, sampleRef);

    const copies = new Array(amountOfCopies);
    for (let i = 0; i < amountOfCopies; i ++) {
        copies[i] = createContent(i);
    }

    const isBackwards = direction === 'backwards';
    const timeLine = useTransform(
        useTime(),
        [0, duration],
        isBackwards ? [0, -1] : [-1, 0],
        { clamp: false }
    );
    const x = useTransform(
        timeLine,
        (value: number) => {
            const offset = value % 1 * sampleWidth;
            return offset;
        },
    );

    return (
        <div
            ref={container}
            className='ticker'
            style={style}
        >
            {size && (
                <motion.div
                    style={{ x }}
                    className='ticker-content-container'
                >
                    {sample}
                    {copies}
                </motion.div>
            )}
        </div>
    );
}
