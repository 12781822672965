import React, {useEffect, useRef, useState} from 'react';
import {motion} from 'framer-motion';

interface TickerProps {
    children: any;
    rotation?: '0deg' | '90deg' | '-90deg';
    direction?: 'forwards' | 'backwards';
    duration?: number;
}

type Orientation = 'horizontal' | 'vertical';

export default function BaseTicker({children, direction = 'forwards', duration = 10, rotation = '0deg'}: TickerProps) {
    const orientation: Orientation = rotation === '0deg' ? 'horizontal' : 'vertical';

    const container = useRef(null);
    const [size, setSize] = useState<null | { width: number, height: number }>(null);

    const transforms = () => {
        let transforms: any = {
            left: 0,
            transform: `rotate(${rotation})`,
        };

        if (orientation === 'vertical') {
            transforms = {
                ...transforms,
                width: size.height,
                height: size.width,
            }
        }

        if (rotation === '90deg') {
            transforms = {
                ...transforms,
                transformOrigin: 'left top',
                left: size.width,
            }
        } else if (rotation === '-90deg') {
            transforms = {
                ...transforms,
                transformOrigin: 'left top',
                top: size.height,
            }
        } else {
            transforms = {
                ...transforms,
                transformOrigin: 'left top',
                top: 0,
                left: 0,
                width: size.width,
                height: size.height,
                transform: undefined,
            }
        }

        return transforms;
    }

    useEffect(() => {
        setSize({
            height: container.current.clientHeight,
            width: container.current.clientWidth,
        });
    }, [container.current]);

    return (
        <div
            ref={container}
            style={{position: 'relative', width: '100%', height: '100%', overflow: 'hidden'}}
        >
            {
                size && (
                    <div style={{
                        position: 'absolute',
                        ...transforms()
                    }}>
                        <motion.div
                            style={{
                                position: 'absolute',
                                width: '100%',
                                top: 0,
                                left: 0,
                                height: '100%'
                            }}
                            animate={{
                                x: direction === 'backwards' ? ['0%', '-100%'] : ['-100%', '0%'],
                            }}
                            transition={{
                                repeat: Infinity,
                                duration,
                                ease: 'linear'
                            }}
                        >
                            {children}
                        </motion.div>

                        <motion.div
                            style={{
                                position: 'absolute',
                                width: '100%',
                                top: 0,
                                left: 0,
                                height: '100%'
                            }}
                            animate={{
                                x: direction === 'backwards' ? ['100%', '0%'] : ['0%', '100%'],
                            }}
                            transition={{
                                repeat: Infinity,
                                duration,
                                ease: 'linear'
                            }}
                        >
                            {children}
                        </motion.div>
                    </div>
                )
            }
        </div>
    )
}