import React, { useEffect, useState } from 'react';
import './NTCIntro.scss';
import _ from 'lodash';
import TextBlock from '../../components/TextBlock/TextBlock.js';
import MediaBlock from '../../components/MediaBlock/MediaBlock.js';
import { MEDIA_TRANSITIONS, TRANSITIONS, DEFAULT_TRANSITION, STYLES, TEXT_SIZES } from '../../constants';
import { AnimatePresence } from 'framer-motion';
import ContentBlock from '../../components/ContentBlock/ContentBlock';

const TIMINGS = {
    text_intro_delay: .75,
    text_intro_duration: 5,
    media_transition: MEDIA_TRANSITIONS.LeftToRight,
    media_intro_delay: 1,
    media_intro_duration: 2,
    media_duration: 3,
    media_exit_duration: 2
}

const POSITIONS = {
    Header: {
        left: "3.125vh",
        top: "3.125vh",
        width: "12.5vh",
        height: "93.75vh"
    }
}

const NTCIntro = ({ title, visible }) => {

    return <AnimatePresence>

        <div className={'NTCIntro'}>

            <ContentBlock
                visible={visible}
                key={`header`}
                transparent={true}
                left={POSITIONS.Header.left}
                top={POSITIONS.Header.top}
                width={POSITIONS.Header.width}
                height={POSITIONS.Header.height}
                inset={false}
                animationTiming={{
                    transition: MEDIA_TRANSITIONS.LeftToRight,
                    intro_delay: 0,
                    intro_duration: 1,
                    media_duration: TIMINGS.media_duration,
                    exit_duration: TIMINGS.media_exit_duration
                }}
            >
                <TextBlock
                    text={title}
                    left={".15vh"}
                    top={"101%"}
                    width={"200vh"}
                    height={"12.5vh"}
                    rotate={true}
                    textMode={'black'}
                    delay={TIMINGS.text_intro_delay}
                    duration={TIMINGS.text_intro_duration}
                    orientation={'portrait'}
                    type={TEXT_SIZES.Super}
                />
            </ContentBlock>
            
        </div>
    </AnimatePresence>

};

export default NTCIntro;