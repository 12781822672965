import {useEffect, useMemo, useState} from "react";
import {AnimatePresence, motion} from "framer-motion";

interface SlideProps {
    direction: Direction;
    children: any;
    showAfter?: number;
    hideAfter?: number;
    delay?: number;
    position?: 'absolute' | 'relative';
    duration?: number;
    multiplier?: number
}

type Direction = 'to-left' | 'to-right' | 'to-bottom' | 'to-top';

export default function Slide({
                                  children,
                                  position = 'relative',
                                  direction,
                                  showAfter = 0,
                                  multiplier = 2,
                                  hideAfter,
                                  duration = 1
                              }: SlideProps) {
    const m = (n) => `${n * multiplier}%`;

    const animate = useMemo(() => {
        const transitions: Partial<Record<Direction, any>> = {
            'to-left': {
                initial: {
                    x: m(100)
                },
                animate: {
                    x: m(0)
                },
                exit: {
                    x: m(-100)
                }
            },
            'to-right': {
                initial: {
                    x: m(-100)
                },
                animate: {
                    x: m(0)
                },
                exit: {
                    x: m(100)
                }
            },
            'to-bottom': {
                initial: {
                    y: m(-100)
                },
                animate: {
                    y: m(0)
                },
                exit: {
                    y: m(100)
                }
            },
            'to-top': {
                initial: {
                    y: m(100)
                },
                animate: {
                    y: m(0)
                },
                exit: {
                    y: m(-100)
                }
            }
        };

        return transitions[direction];
    }, [direction]);

    const [show, setShow] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShow(true);

            if (!hideAfter) {
                return;
            }

            setTimeout(() => {
                setShow(false);
            }, hideAfter * 1000);
        }, showAfter * 1000);
    }, []);

    return (
        <div style={{position, width: '100%', height: '100%'}}>
            {
                show && (
                    <motion.div
                        style={{width: '100%', height: '100%'}}
                        initial={animate.initial}
                        animate={animate.animate}
                        exit={animate.exit}
                        transition={{
                            duration
                        }}
                    >
                        {children}
                    </motion.div>
                )
            }
        </div>
    )
}