import React from 'react';
import PropTypes from 'prop-types';

/**
 * Just for the propsss
 * THIS ELEMENT DOES NOTHING, BUT PROP CHECKING!!!
 *
 * @return {JSX.Element}
 * @constructor
 */
const WrappableElement = ({}) => {
	return (
		<div>
		</div>
	);
};

WrappableElement.propTypes = {
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
	testOff: PropTypes.bool,
	transform: PropTypes.object,
	wrapsInternally: PropTypes.bool,
};

export default WrappableElement;
