import React, { useEffect, useState } from 'react';
import './NTCFullscreen.scss';

import _ from 'lodash';
import TextBlock from '../../components/TextBlock/TextBlock.js';
import MediaBlock from '../../components/MediaBlock/MediaBlock.js';
import ContentBlock from '../../components/ContentBlock/ContentBlock';
import QRCode from '../../components/QRCode/QRCode';
import { MEDIA_TRANSITIONS, TRANSITIONS, DEFAULT_TRANSITION, TEXT_SIZES, NTC_FALLBACK, BLOCK_SIZES } from '../../constants';
import { AnimatePresence } from 'framer-motion';


const BLOCK_POSITIONS = {
    Primary: {
        Logo: {
            top: '50vh',
            left: '3.125vh',
            width: BLOCK_SIZES.Logo.width,
            height: BLOCK_SIZES.Logo.height
        },
        Header: {
            top: '62.5vh',
            left: '15.625vh',
            width: BLOCK_SIZES.Header.width,
            height: BLOCK_SIZES.Header.height
        },
        Body: {
            top: '75vh',
            left: '15.625vh',
            width: BLOCK_SIZES.Body.width,
            height: BLOCK_SIZES.Body.height
        },
    },
    Secondary: {
        Logo: {
            top: '37.5vh',
            left: '3.125vh',
            width: BLOCK_SIZES.Logo.width,
            height: BLOCK_SIZES.Logo.height
        },
        Header: {
            top: '50vh',
            left: '15.625vh',
            width: BLOCK_SIZES.Header.width,
            height: BLOCK_SIZES.Header.height
        },
        Body: {
            top: '62.5vh',
            left: '15.625vh',
            width: BLOCK_SIZES.Body.width,
            height: BLOCK_SIZES.Body.height
        },
    },

}

const TIMINGS = {
    logo_delay: 2,
    text_intro_delay: .75,
    text_intro_duration: 5,
    media_transition: MEDIA_TRANSITIONS.LeftCorner,
    media_intro_delay: 1,
    media_intro_duration: 2,
    media_duration: 3,
    media_exit_duration: 1,
    header_delay: 2,
    header_duration: 8,
}



const NTCFullscreen = ({ layout = 'Primary', visible = false, data = {} }) => {

    const POSITIONS = BLOCK_POSITIONS[layout];

    const { media, body, title, qr_section } = data;

    const qrcode = qr_section?.media?.[1]?.url;

    const ntc = qr_section?.media?.[0]?.url;

    return <div className={'NTCFullscreen'}>

        <div className={'NTCFullscreen-content'}>

            <MediaBlock
                visible={visible}
                key={`logo`}
                media={
                    {
                        resource_type: 'image',
                        url: ntc ? ntc : NTC_FALLBACK
                    }
                }
                left={POSITIONS.Logo.left}
                top={POSITIONS.Logo.top}
                width={POSITIONS.Logo.width}
                height={POSITIONS.Logo.height}
                inset={true}
                animationTiming={{
                    transition: MEDIA_TRANSITIONS.Center,
                    intro_delay: TIMINGS.logo_delay,
                    intro_duration: TIMINGS.logo_duration,
                    media_duration: TIMINGS.media_duration,
                    exit_duration: 1
                }}
            />


            <ContentBlock
                visible={visible}
                key={`headline`}
                left={POSITIONS.Header.left}
                top={POSITIONS.Header.top}
                width={POSITIONS.Header.width}
                height={POSITIONS.Header.height}
                inset={true}
                animationTiming={{
                    transition: MEDIA_TRANSITIONS.LeftCorner,
                    intro_delay: TIMINGS.media_intro_delay + .25,
                    intro_duration: TIMINGS.media_intro_duration,
                    media_duration: TIMINGS.media_duration,
                    exit_duration: TIMINGS.media_exit_duration
                }}
            >
                <TextBlock
                    text={title}
                    left={"2.5%"}
                    top={"5%"}
                    width={"100%"}
                    height={"100%"}
                    textMode={'black'}
                    delay={TIMINGS.header_delay}
                    duration={TIMINGS.header_duration}
                    orientation={'portrait'}
                    type={TEXT_SIZES.Heading}
                />
            </ContentBlock>

            <ContentBlock
                visible={visible}
                key={`body`}
                left={POSITIONS.Body.left}
                top={POSITIONS.Body.top}
                width={POSITIONS.Body.width}
                height={POSITIONS.Body.height}
                inset={true}
                theme={'dark'}
                animationTiming={{
                    transition: MEDIA_TRANSITIONS.TopBack,
                    intro_delay: TIMINGS.media_intro_delay + .5,
                    intro_duration: TIMINGS.media_intro_duration,
                    media_duration: TIMINGS.media_duration,
                    exit_duration: TIMINGS.media_exit_duration
                }}
            >

                <p className={'paragraph'}>
                    {body}
                </p>

                <QRCode qrcode={qrcode} />

            </ContentBlock>



        </div>


    </div>

};

export default NTCFullscreen;





{/* <ContentBlock
                key={`c1`}
                left={POSITIONS.Body.left}
                top={POSITIONS.Body.top}
                width={POSITIONS.Body.width}
                height={POSITIONS.Body.height}
                text={'HEADLINE'}
                inset={true}
                theme={'dark'}
                animationTiming={{
                    transition: MEDIA_TRANSITIONS.TopBack,
                    intro_delay: TIMINGS.media_intro_delay,
                    intro_duration: TIMINGS.media_intro_duration,
                    media_duration: TIMINGS.media_duration,
                    exit_duration: TIMINGS.media_exit_duration
                }}
            >

                <MediaBlock
                    media={
                        {
                            // resource_type: 'image',
                            // url: 'https://static.nike.com/a/images/w_960,c_limit/xdtgf2klx0tjcyt8yb76/make-your-nights-more-efficient.jpg'
                            resource_type: 'm3u8-video',
                            url: 'https://api.nike.com/content/asset_video_manifest/v1/6259197215001.m3u8'
                        }
                    }
                    key={`media1`}
                    left={"0%"}
                    top={'0%'}
                    width={"100%"}
                    height={"100%"}
                    animationTiming={{
                        transition: MEDIA_TRANSITIONS.None,
                        intro_delay: TIMINGS.media_intro_delay,
                        intro_duration: TIMINGS.media_intro_duration,
                        media_duration: TIMINGS.media_duration,
                        exit_duration: TIMINGS.media_exit_duration
                    }}
                />

                <QRCode />

            </ContentBlock> */}