.campaign_intro {
    > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    svg line {
        stroke: #A9AAAD;
    }
}

.grid_solid {
    left: unset;
    right: -40px;
    top: 336px;
}

.grid_dotted {
    top: -4px;
    left: -35px;
    opacity: 0.6;
}

.overlay_media {
    display: flex;
    align-items: center;
    justify-content: center;
}
