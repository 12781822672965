import React from "react";
import {motion} from "framer-motion";
import {NIKE_EASE} from "../../../constants/nikerise";
import {DURATIONS} from "../shared";

export default function Background({gradient, info, playOutro, upsideDown, heightMultiple = 8}) {
  const gradientString = gradient.reduce((acc, curr) => {
    return `${acc ? `${acc},` : ''} ${curr.color} ${curr.offset * 100}%`
  }, null);

  const to = -(info.trackHeight * (heightMultiple - 1));
  const from = 0;

  return (
    <motion.div
      initial={{
        width: '0px',
      }}
      exit={{
        width: '0px',
      }}
      animate={{
        width: playOutro ? '0px' : `${info.trackLength}px`,
      }}
      style={{
        width: info.trackLength,
        height: info.trackLength,
        position: 'absolute',
        overflow: 'hidden',
      }}
      transition={{
        // total of 4 seconds divided up between delay and play duration.
        duration: (DURATIONS.SWEEP_IN * 0.875) / DURATIONS.SECOND,
        delay: (DURATIONS.SWEEP_IN * 0.125) / DURATIONS.SECOND,
        ease: NIKE_EASE,
      }}
    >
      <motion.div
        style={{
          background: `radial-gradient(${gradientString})`,
          width: info.trackLength * 3,
          height: info.trackHeight * heightMultiple,
          position: 'absolute',
          top: upsideDown ? to : from,
          left: upsideDown ? -info.trackLength * 2 : 0,
        }}
        animate={{
          top: upsideDown ? from : to,
        }}
        transition={{
          duration: 20,
          repeat: Infinity,
          repeatType: 'reverse'
        }}
      />
    </motion.div>
  )
}