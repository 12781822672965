import React, { useEffect, useState } from 'react';
import MediaBlock from '../../components/MediaBlock/MediaBlock.js';
import TextBlock from '../../components/TextBlock/TextBlock.js';
import './RiseHorizontalGrid.scss';
import LottiePlayer, { LottieModes } from '../../components/LottiePlayer/LottiePlayer.js';
import { NIKE_EASE } from '../../../../constants/nikerise';
const DEFAULT_TRANSITION = { ease: NIKE_EASE, duration: 2 }

const TIMINGS = {
    mediaDuration: 3.5,
    introDelay: 1,
    contentDuration: 2,
    headlineDelay: 2,
    headlineDuration: 5
}

const RiseHorizontalGrid = ({ headline, body, media, width, height, top, left, grid, onEvent }) => {

    const [dims, setDims] = useState({
        width: width ? width : '100%',
        height: height ? height : '100%',
        left: left ? left : 0,
        top: top ? top : 0,
        initial: true,
    });

    return <div className="RiseHorizontalGrid" style={{ ...dims, overflow: 'hidden', position: 'absolute' }}>

        {grid && <LottiePlayer grid={grid} onEvent={onEvent} mode={LottieModes.Chapter} />}

        {
            headline && !body &&
            <TextBlock
                text={headline}
                left={"50.3%"}
                top={"27.8%"}
                width={"37.44%"}
                height={"22.2%"}
                delay={TIMINGS.headlineDelay}
                duration={TIMINGS.headlineDuration}
                overflow={'hidden'}
                type={headline.length < 13 ? 'headingLarge' : 'heading'}
            />
        }

        {
            headline && body &&
            <TextBlock
                text={headline}
                left={"50.3%"}
                top={"5.62%"}
                width={"37.44%"}
                height={"22.2%"}
                delay={TIMINGS.headlineDelay}
                duration={TIMINGS.headlineDuration}
                overflow={'hidden'}
                type={headline.length < 13 ? 'headingLarge' : 'heading'}
            />
        }

        {
            body &&
            <TextBlock
                text={body}
                type={'body'}
                left={"50.3%"}
                top={"27.8%"}
                width={"37.44%"}
                height={"22.2%"}
                delay={TIMINGS.headlineDelay}
                duration={TIMINGS.headlineDuration}
                codify={false}
            />
        }


        {

            media.length > 0 &&
            <MediaBlock
                media={media[0]}
                // key={media[0]}
                top={"5.6%"}
                width={"50%"}
                height={"88.8%"}
                delay={TIMINGS.introDelay}
                duration={TIMINGS.mediaDuration}
                inset={true}
            />

        }

        {

            media.length > 1 &&
            <MediaBlock
                media={media[1]}
                // key={media[1]}
                left={"87.5%"}
                width={"12.56%"}
                height={"22.2%"}
                top={"5.6%"}
                delay={TIMINGS.introDelay}
                duration={TIMINGS.mediaDuration}
                inset={true}
            />
        }

        {

            media.length > 2 &&
            <MediaBlock
                media={media[2]}
                // key={media[2]}
                left={"75%"}
                width={"25%"}
                height={"44.4%"}
                top={"50%"}
                duration={TIMINGS.mediaDuration}
                delay={TIMINGS.introDelay}
                inset={true}
            />
        }

    </div>

};

export default RiseHorizontalGrid;
