export const ASSETS_RESOURCE_TYPES = {
    IMAGE: 'image',
    VIDEO: 'video'
};

const ASSETS_TYPES = {
    UPLOAD: 'upload',
};

const ASSETS_FOLDER_NAME = 'sportpulse-ui';

export class AssetsUrlService {
    /**
      * Example of ui assets url: https://res.cloudinary.com/jbboerner/image/upload/v1658748519/sportpulse-ui/swoosh-logo-black.png
      * * https:// - schema
      * * res.cloudinary.com - domain
      * * jbboerner - cloudname
      * * image - resource type
      * * upload - type
      * * v1658748519/sportpulse-ui/swoosh-logo-black.png - file path
      *
      * @param {string} domain
      * @param {string} base
      * @param {string} resourceType
      */
    constructor(domain, base, resourceType) {
        this.domain = domain;
        this.base = base;

        this.resource_type = resourceType;
        this.type = ASSETS_TYPES.UPLOAD;
    }

    getBasePath() {
        return `${this.base}/${this.resource_type}/${this.type}`
    }

    extendWithChainedTransformations(url, transformations = []) {
        const urlTransformations = transformations.join('/');
        return url.replace(`${this.type}/`, `${this.type}/${urlTransformations}/`);
    }

    /**
     *
     * @param {string} filePath
     * @param {Object} options
     * @param {boolean} options?.secure - by default true
     * @param {string[]} options?.chainedTransformations
     * @returns {string}
     */
    getBasicUrl(filePath, options = {}) {
        const {
            secure = true,
            chainedTransformations = []
        } = options;

        const schema = secure ? 'https' : 'http';

        const rawUrl = `${schema}://${this.domain}/${this.getBasePath()}/${filePath}`;

        if (chainedTransformations.length) {
            return this.extendWithChainedTransformations(rawUrl, chainedTransformations);
        }

        return rawUrl
    }

    /**
     * Get file name and return file url from assets cloudinary directory (by default ASSETS_FOLDER_NAME)
     * @param {string} fileName
     * @param {Object} options
     * @param {boolean} options?.secure
     * @param {string[]} options?.chainedTransformations
     * @param {string[]} options?.folderName
     * @returns {string}
     */
    getUrl(fileName, options = {}) {
		const {
			folderName = null
		} = options;
        const filePath = `${folderName || ASSETS_FOLDER_NAME}/${fileName}`;
        return this.getBasicUrl(filePath, options);
    }
}
