import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion, usePresence, Variants } from 'framer-motion';

import { random } from "../../rise/utils/math";
import { GLYPHS } from "../components/Codification/glyphs";

import { MultiLangText, secToMs, setAnimationTimeout } from '../shared';
import { DEFAULT_DURATION_S, DEFAULT_EASE } from '../constants/transition';
import { BaseChapterProps } from '../components/ChapterRenderer';
import Transition from "../components/Transition";
import Grid from '../components/Grid';
import FloorComponent, { FloorDirection } from '../components/FloorComponent';
import TextIteration from '../components/TextIteration';

import '../styles/screens/wayfinding.scss';

export type WayFindingProps = BaseChapterProps & {
    activeFloorIndex?: number;
    floors: Array<Floor>;
}

type Floor = {
    level: string,
    title: MultiLangText,
    description: MultiLangText,
};

type CellSize = {
    height: number,
    width: number
};

const gridLineThickness = 1;

const coverDuration = 0.75;
const floorCounterDuration = 0.1;
const floorNumberDuration = 2;
const activeFloorZoomOutDuration = DEFAULT_DURATION_S;
const nextFloorBackgroundDelay = 0.15;
const floorBackgroundDuration = DEFAULT_DURATION_S;
const languageTransitionDelay = 0.2;
const languageDisplayDuration = 12;
const floorTitleDelay = 2;
const exitActiveFloorDuration = DEFAULT_DURATION_S / 2;
const gridDuration = DEFAULT_DURATION_S;

const scaleFactor = 29.5;
const activeFloorNumberAnimationProps: Variants = {
    initial: ({index, heightCorrection}) => {
        return {
            clipPath: 'inset(0% 0% 0% 0%)',
            transform: `scale(${scaleFactor})`,
            transformOrigin: `calc(100% - 91px) calc(68px + ${index * heightCorrection}px)`
        };
    },
    target: {
        clipPath: 'inset(0% 0% 0% 0%)',
        transform: 'scale(1)',
    },
}

export default function Wayfinding(props: WayFindingProps) {
    const {
        width,
        height,
        languages,
        activeFloorIndex = 0
    } = props;
    const [isPresent, safeToRemove] = usePresence();

    const [showIntroWipe, setShowIntroWipe] = useState(true);
    const [showFloors, setShowFloors] = useState(true);
    const [showGrid, setShowGrid] = useState(true);

    const cellSize: CellSize = {
        width: 216,
        height: 216,
    };
    const cols = Math.ceil(width / cellSize.width);
    const rows = Math.ceil(height / cellSize.height);
    const backgroundWidth = cellSize.width * 3;
    const floorHeight = cellSize.height * 2;
    const floorGridTemplateColumns = new Array<string>(cols).fill('1fr');
    const activeFloorProps = props.floors[activeFloorIndex] || props.floors[0];

    const floorsRange = props.floors.reduce((result, floor) => {
        const value = parseInt(floor.level);
        if (value < result[0]) {
            result[0] = value;
        }
        if (value > result[1]) {
            result[1] = value;
        }

        return result;
    }, [Infinity, -Infinity]);

    const currentFloorNumberTexts: string[] = [''];
    for (let value = floorsRange[0]; value <= floorsRange[1]; value++) {
        if (value === 0) {
            currentFloorNumberTexts.push('/')
        }
        currentFloorNumberTexts.push(`${value}`);
        if (value === parseInt(activeFloorProps.level)) {
            break;
        }
    }
    const showFloorsDelay = coverDuration + currentFloorNumberTexts.length * floorCounterDuration + floorNumberDuration + activeFloorZoomOutDuration;

    const floors = props.floors.map((floor, index) => {
        const isCurrent = index === activeFloorIndex;
        let direction: FloorDirection = 'down';
        if (isCurrent) {
            direction = 'current'
        } else if (index < activeFloorIndex) {
            direction = 'up';
        }

        return (
            <NotCurrentFloor
                key={index}
                index={index}
                isCurrent={isCurrent}
                floor={floor}
                languages={languages}
                floorHeight={floorHeight}
                floorGridTemplateColumns={floorGridTemplateColumns}
                backgroundWidth={backgroundWidth}
                direction={direction}
                floorBackgroundDelay={showFloorsDelay}
            />
        );
    });

    useEffect(() => {
        if (isPresent) {
            return;
        }

        // for some reasons active floor is removed after 4-5 seconds, not right after exit animation
        return setAnimationTimeout(() => setShowFloors(false), secToMs(exitActiveFloorDuration));
    }, [isPresent]);

    return (
        <div className='wayfinding-chapter'>
            {showIntroWipe ? (
                <Transition
                    variant={'wipe-y'}
                    cover
                    duration={coverDuration}
                    style={{backgroundColor: 'black'}}
                    preventExit
                    onAnimationComplete={() => setShowIntroWipe(false)}
                >
                    <div></div>
                </Transition>
            ) : (
                <>
                    <AnimatePresence onExitComplete={() => !isPresent && safeToRemove()}>
                        {showGrid ? (
                            <Grid
                                width={cellSize.width * cols}
                                height={cellSize.height * rows}
                                rows={rows}
                                cols={cols}
                                minDuration={gridDuration / 2}
                                maxDuration={gridDuration}
                                initialAnimation='hidden'
                                animation='visible'
                                exitAnimation='out'
                                style={{
                                    position: 'absolute',
                                }}
                                lineStyle={{
                                    strokeWidth: gridLineThickness,
                                    stroke: 'black'
                                }}
                            />
                        ) : null}
                    </AnimatePresence>
                    <div className='floors'>
                        <AnimatePresence
                            onExitComplete={() => !isPresent && setShowGrid(false)}
                        >
                            {showFloors ? floors : null}
                        </AnimatePresence>
                        <AnimatePresence>
                            {isPresent ? (
                                <CurrentFloor
                                    key={`${activeFloorIndex}-active`}
                                    index={activeFloorIndex}
                                    floorHeight={floorHeight}
                                    floorGridTemplateColumns={floorGridTemplateColumns}
                                    floor={activeFloorProps}
                                    languages={languages}
                                    backgroundWidth={backgroundWidth}
                                    floorNumberTexts={currentFloorNumberTexts}
                                    width={width}
                                    cols={cols}
                                    floorBackgroundDelay={showFloorsDelay}
                                />
                            ) : null}
                        </AnimatePresence>
                    </div>
                </>
            )}
        </div>
    );
}

export function calculateDuration(data: WayFindingProps) {
    const {
        languages
    } = data;

    const showMainFloorNumberDuration = (data.floors.length - data.activeFloorIndex) * floorCounterDuration;
    const entryDuration = coverDuration + showMainFloorNumberDuration + floorNumberDuration + activeFloorZoomOutDuration;

    const floorDuration = languageDisplayDuration;
    const floorsDuration = floorDuration * languages.length;

    const duration = entryDuration + floorsDuration;

    const exitDuration = calculateExitDuration(data);

    return secToMs(duration) + exitDuration;
}

export function calculateExitDuration(data: WayFindingProps) {
    const duration = exitActiveFloorDuration + floorNumberDuration + gridDuration;
    return secToMs(duration);
}


type NotCurrentFloorProps = {
    index: number,
    isCurrent: boolean,
    floor: Floor,
    languages: string[],
    floorHeight: number,
    floorGridTemplateColumns: string[],
    backgroundWidth: number,
    direction: FloorDirection,
    floorBackgroundDelay: number,
};

function NotCurrentFloor(props: NotCurrentFloorProps) {
    const {
        index,
        isCurrent,
        floor,
        languages,
        floorHeight,
        floorGridTemplateColumns,
        backgroundWidth,
        direction,
        floorBackgroundDelay,
    } = props;

    const { t } = useTranslation();

    const backgroundDelay = nextFloorBackgroundDelay * index;

    const exitTextToIterate = new Array(23);
    const exitFloorNumberDuration = 1;
    for (let i = 0; i < exitTextToIterate.length; i++) {
        exitTextToIterate[i] = t(`glyphs.${GLYPHS[random(0, GLYPHS.length - 1)]}`);
    }
    const floorNumber = !isCurrent ? (
        <TextIteration
            textToIterate={[floor.level]}
            exitTextToIterate={exitTextToIterate}
            iterationDuration={exitFloorNumberDuration / exitTextToIterate.length}
            animationProps={{
                exit: {
                    opacity: 0,
                    transition: {
                        duration: exitFloorNumberDuration
                    }
                }
            }}
        />
    ) : null;

    return (
        <FloorComponent
            key={floor.level}
            level={floor.level}
            title={floor.title}
            languages={languages}
            delay={floorBackgroundDelay + floorTitleDelay}
            description={floor.description}
            languageTransitionDelay={languageTransitionDelay}
            languageDisplayDuration={languageDisplayDuration}
            direction={direction}
            gridLineThickness={gridLineThickness}
            floorGridTemplateColumns={floorGridTemplateColumns}
            className='floor'
            style={{
                height: floorHeight,
            }}
            backgroundAnimationProps={{
                initial: {
                    transform: `translateX(-${backgroundWidth}px)`,
                },
                animate: {
                    transform: 'translateX(1px)',
                },
                transition: {
                    delay: floorBackgroundDelay + backgroundDelay + nextFloorBackgroundDelay,
                    duration: floorBackgroundDuration,
                    ease: DEFAULT_EASE
                },
                exit: {
                    transform: `translateX(-${backgroundWidth}px)`,
                    transition: {
                        delay: backgroundDelay,
                        duration: floorBackgroundDuration,
                        ease: DEFAULT_EASE
                    }
                }
            }}
        >{floorNumber}</FloorComponent>
    );
}


type CurrentFloorProps = {
    index: number,
    floorHeight: number,
    floorGridTemplateColumns: string[],
    floor: Floor,
    languages: string[],
    backgroundWidth: number,
    floorNumberTexts: string[],
    width: number,
    cols: number,
    floorBackgroundDelay: number,
};

function CurrentFloor(props: CurrentFloorProps) {
    const {
        index,
        floorHeight,
        floorGridTemplateColumns,
        floor,
        languages,
        backgroundWidth,
        floorNumberTexts,
        width,
        cols,
        floorBackgroundDelay,
    } = props;

    return (
        <motion.div
            key='active'
            className='active-floor-container'
            style={{
                height: floorHeight,
                gridTemplateColumns: floorGridTemplateColumns.join(' '),
                gridRowStart: index + 1
            }}
            variants={activeFloorNumberAnimationProps}
            transition={{
                delay: coverDuration + floorCounterDuration * floorNumberTexts.length + floorNumberDuration,
                duration: activeFloorZoomOutDuration,
                ease: DEFAULT_EASE,
            }}
            custom={{
                index: index,
                heightCorrection: floorHeight / scaleFactor
            }}
            initial='initial'
            animate='target'
            exit={{
                clipPath: 'inset(0% 0% 100% 0%)',
                transition: {
                    delay: 0,
                    duration: exitActiveFloorDuration
                }
            }}
        >
            <Transition
                variant='wipe-x'
                delay={floorBackgroundDelay}
                duration={floorBackgroundDuration}
                style={{ position: 'absolute' }}
                preventExit
            >
                <Grid
                    height={floorHeight}
                    width={width}
                    rows={2}
                    cols={cols}
                    initialAnimation='visible'
                    animation='visible'
                    lineStyle={{
                        strokeWidth: gridLineThickness,
                        stroke: 'white'
                    }}
                />
            </Transition>
            <FloorComponent
                level={floor.level}
                title={floor.title}
                languages={languages}
                delay={floorBackgroundDelay}
                description={floor.description}
                languageTransitionDelay={languageTransitionDelay}
                languageDisplayDuration={languageDisplayDuration}
                runFinalTextExitAnimation={false}
                floorGridTemplateColumns={floorGridTemplateColumns}
                gridLineThickness={gridLineThickness}
                className='floor active'
                backgroundAnimationProps={{
                    initial: {
                        transform: `translateX(-${backgroundWidth}px)`,
                    },
                    animate: {
                        transform: 'translateX(1px)',
                    },
                    transition: {
                        delay: floorBackgroundDelay,
                        duration: floorBackgroundDuration,
                        ease: DEFAULT_EASE
                    }
                }}
            >
                <TextIteration
                    textToIterate={floorNumberTexts}
                    iterationDuration={floorCounterDuration}
                    delay={coverDuration}
                />
            </FloorComponent>
        </motion.div>
    )
}
