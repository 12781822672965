import React, { useEffect, useState } from 'react';
import MTZTitle from './components/MTZTitle/MTZTitle';
import PodLogo from './components/PodLogo/PodLogo';
import PodDiD from './components/PodDiD/PodDiD';
import PodIntroLogo from './components/PodIntroLogo/PodIntroLogo';
import * as Hyperlive from '../../hyperlive/utils.js';
import './styles/MTZ.scss';
import _ from 'lodash';

const MTZ = ({ grids, json_data, chapters }) => {



  const { title, static_pulse_story } = json_data;

  // console.log("Title", title)


  const [lang, setLang] = useState('KO');
  const [frame, setFrame] = useState({});
  const [copy, setCopy] = useState('');


  // console.log("LANGUAGE", lang)

  useEffect(() => {
    grids.setCallback(0, (props) => {
      // console.log(props)
      const { name, frameIndex, loop, language } = props;

      const frameName = name ? name.split('#')[1] : undefined

      // console.log(chapters)
      if (frameName) {
        const frameData = chapters[frameName];
        const { headline, title } = frameData;
        // console.log("»", frameData)
        // console.log("»»»»»»»", frameName)
        // console.log("»", frameData)
        const _heading = _.get(frameData, `heading.${Hyperlive.getLang(language)}`, false);
        const _title = _.get(frameData, `title.${Hyperlive.getLang(language)}`, false);
        // const _copy = _heading ? _heading : _title;
        const _copy = _title;
        // console.log("»", _copy)
        setCopy(_copy);
      } else {
        // console.log('noframe')
        setCopy(false)
      }



      setLang(Hyperlive.getLang(language))
      setFrame({ index: frameIndex, name })

    })
  }, [])

  // useEffect(() => {
  let logo_assets = Hyperlive.fetchAssets('logo');
  // console.log('logo_asset', logo_assets)
  // }, []);

  return (
    <div className={'MTZ'}>
      {/* {logo_assets.length > 0 && <PodIntroLogo grids={grids} frame={frame} logo_asset={logo_assets[0]} />} */}
      {/* <PodLogo text={'Nike Test'} grids={grids} frame={frame} logo_asset={logo_assets[0]}/> */}
      {copy && <MTZTitle text={copy} grids={grids} frame={frame} />}
      {/* <PodDiD text={static_pulse_story[lang]} grids={grids} frame={frame} /> */}
    </div>
  );
};

export default MTZ;
