import {AnimatePresence} from "framer-motion";
import React from "react";
import {useId} from "react";

/**
 * An AnimatePresence inside another one will cause a TypeScript error
 * this will prevent that lol...
 */
export default function WrapAnimatePresence(props) {
    return (
        <>
            <AnimatePresence {...props} />
        </>
    )
}

/**
 * Prevents bubbling through to children and triggering exit animations.
 *
 * @param children
 * @param other
 * @constructor
 */
export function PreventAnimationBubble({children, ...other}) {
    const id = useId();

    return (
        <WrapAnimatePresence {...other}>
            <React.Fragment key={id}>
                {children}
            </React.Fragment>
        </WrapAnimatePresence>
    )
}