if (module.hot) {
    module.hot.accept(() => {
      location.reload()
    })
  }
  
import gsap from 'gsap';
import { duration } from 'moment';
import { debounce } from 'lodash';
  
import Logo from './displays/Logo'
import Text from './displays/Text'
  
export default function (datas, demo=false) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const chapterIndex = urlParams.get('chapter');
  let chapterCount = 0

  let chaptersDuration = [];
  const timeline = gsap.timeline({ paused: true, repeat: -1 });

  // datas.languages.forEach((lang) => {
    datas.chapters.forEach((ch, index) => {
      let chapterPlaying = !chapterIndex || chapterIndex == 'null' ? true : index == chapterIndex ? true : false
      if(chapterPlaying) {
        ch.index = chapterCount;
        if (ch.type === 'LOGO' && !ch.disabled) {
          let totalLogoAssets = ch.assets.filter((item) => item && item.url);
          const chapter = new Logo(ch, (totalLogoAssets.length * 4.3) + 1.5);
          chaptersDuration.push((totalLogoAssets.length * 4.3)+ 1.5)
          timeline.add(() => {
            chapter.start();
          }, (getPreviusTime(chapterCount)));
          chapterCount++
        } else if (ch.type === 'EVENT' && !ch.disabled) {
          const chapter = new Text(ch, 8.9);
          chaptersDuration.push(8.9)
          timeline.add(() => {
            chapter.start();
          }, (getPreviusTime(chapterCount)));
          chapterCount++
        }
      }
    });

  function getPreviusTime(index) {
    let sum = chaptersDuration.map((elem, index) => chaptersDuration.slice(0,index + 1).reduce((a, b) => a + b));
    return sum[index - 1] ? sum[index - 1] : 0
  }
  let totalDuration = chaptersDuration.reduce((partialSum, a) => partialSum + a, 0);
  timeline.add(() => {}, (totalDuration));
  
  timeline.play(getPreviusTime(chapterIndex));

    function resetApps () {
      location.reload();
    }

    window.addEventListener('resize', debounce(resetApps, 500, { trailing: true, leading: false }), false);


    window.addEventListener('keypress', (e) => {
      if (e.code === 'Space') {
        timeline.paused(!timeline.paused());
      }
    });
};
  