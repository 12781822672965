import React, { useContext } from "react";

import Context from "./Codification.context";
import Character from "./CodificationCharacter.component";
import Space from "./CodificationSpace.component";
import defaultProps from "./Codification.defaultProps";
import propTypes from "./Codification.propTypes";

const CodificationWord = ({ characters, index, setLanguageClassName, respectWhitespace = false }) => {
	const { text, words, isAlignLeft, isAlignRight } = useContext(Context);
	const isFirstWord = index === 0;
	const isLastWord = index === words.length - 1;
	const startIndex = words
		.slice(0, index + 1)
		.reduce((total, item, itemIndex) => (itemIndex < index ? total + item.length : total), 0);

	return (
		<span className="c-codification__word">
			{
				respectWhitespace && characters.filter((character) => character === " ").length > 0 && (
					<Space />
				)
			}
			{characters.map((character, characterIndex) => {
				const totalIndex = startIndex + characterIndex;
				const isFirstCharacter = characterIndex === 0;
				const isLastCharacter = characterIndex === characters.length - 1;

				return (
					// eslint-disable-next-line react/no-array-index-key
					<React.Fragment key={`codification-character-${text}-${totalIndex}`}>
						{isAlignRight && isFirstCharacter && !isFirstWord && <Space />}

						<Character
							// text={isLastCharacter && !isLastWord ? `${character} ` : character}
							text={character}
							index={totalIndex}
							setLanguageClassName={setLanguageClassName}
						/>

						{isAlignLeft && isLastCharacter && !isLastWord && <Space />}
					</React.Fragment>
				);
			})}
		</span>
	);
};

CodificationWord.defaultProps = defaultProps.word;
CodificationWord.propTypes = propTypes.word;

export default CodificationWord;
