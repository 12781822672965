import React, { useCallback, useEffect, useState } from 'react';
import * as Hyperlive from '../../hyperlive/utils.js';
import _ from 'lodash';
import i18n from '../../i18n';
import './NTC.scss';
import LottiePlayer from './components/LottiePlayer/LottiePlayer.js';
import NTCIntro from './scenes/NTCIntro/NTCIntro.js';
import NTCFullscreen from './scenes/NTCFullscreen/NTCFullscreen.js';
import NTCSplitscreen from './scenes/NTCSplitscreen/NTCSplitscreen.js';
import Background from './scenes/Background/Background.js';
import { CHAPTERS, CHAPTER_TIMING } from './constants.js';
// LANGUAGES
const storeLanguages = Hyperlive.getStoreLanguages();
// TEST LANG
// const storeLanguages = ['EN', 'KO', 'CN']

// SPORTPULSE PREVIEW OVERRIDES
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

let PREVIEW_CHAPTER = urlParams.get('chapter');
if (PREVIEW_CHAPTER === "null") { PREVIEW_CHAPTER = null }
if (PREVIEW_CHAPTER) { console.warn('FORCE CHAPTER', PREVIEW_CHAPTER); }

const PREVIEW_LANGUAGE = urlParams.get('lang');
if (PREVIEW_LANGUAGE) { console.warn('FORCE LANGUAGE', PREVIEW_LANGUAGE); }

const ORIENTATION = 'Portrait';
const LOTTIE_GRID = require('./lottie/full.json')

const NTC = ({ json_data }) => {

  const [chapter, setChapter] = useState(0);
  const [loop, setLoop] = useState(0);
  const [lang, setLang] = useState(storeLanguages[0])
  const [frame, setFrame] = useState(0);

  useEffect(() => {
    console.log(json_data)
  }, []);

  // RUN OF SHOW 
  useEffect(() => {

    let nextChapter = chapter + 1 > CHAPTERS ? 0 : chapter + 1
    if (nextChapter < CHAPTERS) {
      // console.log('CHAPTER', chapter, 'CHAPTER DURATION', CHAPTER_TIMING[chapter])
      const interval = setTimeout(() => {
        setChapter(nextChapter);
      }, CHAPTER_TIMING[chapter]);
      return () => clearTimeout(interval);
    } else {
      // EMPTY CHAPTER RESET ANIMATION AT END
      // console.log('LAST CHAPTER', chapter)
    }

  }, [chapter])

  const onLottie = (event) => {
    switch (event) {
      case "frame":
        // setFrame(frame + 1)
        // console.log(frame)
        break;
      case "complete":
      case "loop":
        // console.warn('COMPLETE CHAPTER')
        setFrame(0);
        setLoop(loop + 1);
        setChapter(0);
        break;
      case "load":
        // Do nothing on purpose
        break;
    }
  }

  return (
    <div className={`NTC orientation-${ORIENTATION}`} key={`NTC-${loop}`}>

      {
        json_data && <React.Fragment>
          <LottiePlayer grid={LOTTIE_GRID} onEvent={onLottie} preview={PREVIEW_CHAPTER} />

          <div className={'NTC-videobg'}>
            <Background chapter={chapter} chapters={json_data?.chapters} />
          </div>

          <div className={'NTC-content'}>
            <NTCIntro visible={chapter === 0} title={json_data?.chapters?.['0']?.title} key={`chapter0-${loop}`} />
            <NTCFullscreen visible={chapter === 1} layout={'Primary'} data={json_data?.chapters?.['1']} key={`chapter1-${loop}`} />
            <NTCSplitscreen visible={chapter === 2} layout={'Primary'} data={json_data?.chapters?.['2']} key={`chapter2-${loop}`} />
            <NTCSplitscreen visible={chapter === 3} layout={'Secondary'} data={json_data?.chapters?.['3']} key={`chapter3-${loop}`} />
            <NTCFullscreen visible={chapter === 4} layout={'Secondary'} data={json_data?.chapters?.['4']} key={`chapter4-${loop}`} />
          </div>
        </React.Fragment>
      }

    </div>
  );
};

export default NTC;




  // // CREATE RUN OF SHOW
  // useEffect(() => {
  //   console.log(`${SCREEN.width}x${SCREEN.height}`);
  //   console.warn('MODE', LOTTIE_MODE);
  //   console.log('RISE_STORY_TELLING » JSON', json_data);
  //   let animation_sequence = [];
  //   let precache_media = [];
  //   const chapters = _.get(json_data, 'chapters');
  //   chapters.map((chapter, index) => {
  //     const assets = _.get(chapter, `assets`);
  //     const media = assets.filter(item => item !== null);
  //     const headline = _.get(chapter, `headline`);
  //     const body = _.get(chapter, `body`);
  //     const chapter_type = _.get(chapter, `chapter_type`);

  //     console.log('CT', chapter_type)
  //     // // TEST LANG
  //     // if (headline) {
  //     //   headline.EN = 'ENGLISH HEADING'
  //     //   headline.KO = 'KOREAN HEADING'
  //     //   headline.CN = 'CHINESE HEADING'
  //     // }
  //     // if (body) {
  //     //   body.EN = 'ENGLISH BODY'
  //     //   body.KO = 'KOREAN BODY'
  //     //   body.CN = 'CHINESE BODY'
  //     // }

  //     // console.log(media, headline, body)
  //     const { component, grid } = selectLayout(media, chapter_type);
  //     animation_sequence.push({
  //       id: `chapter${index}`,
  //       grid,
  //       component,
  //       media,
  //       headline,
  //       body,
  //     });
  //     precache_media = precache_media.concat(media);
  //   })
  //   setSEQUENCE(animation_sequence);

  // }, [])
