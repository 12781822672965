import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

export default function runHoi() {
	setTimeout(() => {
		const root = ReactDOM.createRoot(document.getElementById('root'));
		root.render(<App/>);
	}, 20);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
