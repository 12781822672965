import * as Hyperlive from '../../hyperlive/utils';
import * as Logger from '../../utils/console';
import { Services } from './views/Services/Services';
import { Attract } from './views/Attract/Attract';
import { Repair } from './views/Repair/Repair';

export const CR_ANIMATIONS = {
	SERVICES: 'services',
	ATTRACT: 'sizzle',
	REPAIR: 'repair',
};

export const CR_TEMPLATES = {
	[CR_ANIMATIONS.SERVICES]: Services,
	[CR_ANIMATIONS.ATTRACT]: Attract,
	[CR_ANIMATIONS.REPAIR]: Repair,
};

// TODO
// TEMPLATE SELECTOR

// let CityReplayComponent = CR_TEMPLATES[CR_ANIMATIONS.SERVICES];

// switch (screen_type) {
//     case CR_ANIMATIONS.SERVICES:
//         CityReplayComponent = CR_TEMPLATES[CR_ANIMATIONS.SERVICES];
//         break;
//     case CR_ANIMATIONS.ATTRACT:
//         CityReplayComponent = CR_TEMPLATES[CR_ANIMATIONS.ATTRACT];
//         break;
//     default:
//     case CR_ANIMATIONS.REPAIR:
//         CityReplayComponent = CR_TEMPLATES[CR_ANIMATIONS.REPAIR];
//         break;
// }

// return <CityReplayComponent data={json_data} />;

export const chapterData = (
	frameType,
	screen_type,
	chapter_data,
	chapterId,
	language,
	CONFIG,
) => {
	const CHAPTER = {
		headline_text: '',
		headline_mask: CONFIG.cover.masks.coverHeadingRT,
		description: '',
	};

	// PER CHAPTER SETTINGS
	switch (frameType) {
		case Hyperlive.ChapterTypes.Story:
			switch (screen_type) {
				case CR_ANIMATIONS.SERVICES:
					CHAPTER.headline_text =
						chapter_data[chapterId].heading[
							Hyperlive.getLang(language)
						];
					CHAPTER.headline_mask = CONFIG.story.masks.heading;

					CHAPTER.title = chapter_data[chapterId].title
						? chapter_data[chapterId].title?.[
								Hyperlive.getLang(language)
						  ]
						: '';
					CHAPTER.subheading = chapter_data[chapterId].subheading
						? chapter_data[chapterId].subheading?.[
								Hyperlive.getLang(language)
						  ]
						: '';

					break;
				case CR_ANIMATIONS.ATTRACT:
					CHAPTER.headline_text =
						chapter_data[chapterId].heading[
							Hyperlive.getLang(language)
						];
					CHAPTER.headline_mask = CONFIG.story.masks.heading;
					break;
				case CR_ANIMATIONS.REPAIR:
					CHAPTER.cover_text =
						chapter_data[chapterId].title[
							Hyperlive.getLang(language)
						];
					CHAPTER.headline_text =
						chapter_data[chapterId].title[
							Hyperlive.getLang(language)
						];
					CHAPTER.description =
						chapter_data[chapterId].heading[
							Hyperlive.getLang(language)
						];
					break;
				default:
					break;
			}

			break;

		case Hyperlive.ChapterTypes.Cover:
			// Logger.highlight(`++ ${chapterId}`);
			// Logger.log('++ ', chapter_data[chapterId]);
			// Logger.log('++ ', chapter_data[chapterId].title);
			// Logger.log('++ ', chapter_data[chapterId].heading);

			CHAPTER.headline_text = chapter_data[chapterId].title
				? chapter_data[chapterId].title?.[Hyperlive.getLang(language)]
				: '';
			CHAPTER.description = chapter_data[chapterId].heading
				? chapter_data[chapterId].heading?.[Hyperlive.getLang(language)]
				: '';
			CHAPTER.headline_mask = CONFIG.cover.masks.coverHeadingLF;
			CHAPTER.description_mask = CONFIG.cover.masks.textRT1;

			// Logger.log('++ ', chapter_data[chapterId].repair_headline)

			// switch (screen_type) {
			//   case CR_ANIMATIONS.SERVICES:
			//     CHAPTER.headline_text = chapter_data[chapterId].title ? chapter_data[chapterId].title?.[Hyperlive.getLang(language)] : '';
			//     CHAPTER.description = chapter_data[chapterId].heading ? chapter_data[chapterId].heading?.[Hyperlive.getLang(language)] : '';
			//     CHAPTER.headline_mask = CONFIG.cover.masks.coverHeadingLF;
			//     CHAPTER.description_mask = CONFIG.cover.masks.textRT1;
			//     break;
			//   case CR_ANIMATIONS.ATTRACT:
			//     CHAPTER.headline_text = chapter_data[chapterId].title ? chapter_data[chapterId].title?.[Hyperlive.getLang(language)] : '';
			//     CHAPTER.description = chapter_data[chapterId].heading ? chapter_data[chapterId].heading?.[Hyperlive.getLang(language)] : '';
			//     CHAPTER.headline_mask = CONFIG.cover.masks.coverHeadingLF;
			//     CHAPTER.description_mask = CONFIG.cover.masks.textRT1;
			//     break;
			//   case CR_ANIMATIONS.REPAIR:
			//     CHAPTER.headline_text = chapter_data[chapterId].title ? chapter_data[chapterId].title?.[Hyperlive.getLang(language)] : '';
			//     CHAPTER.description = chapter_data[chapterId].heading ? chapter_data[chapterId].heading?.[Hyperlive.getLang(language)] : '';
			//     CHAPTER.headline_mask = CONFIG.cover.masks.coverHeadingLF;
			//     CHAPTER.description_mask = CONFIG.cover.masks.textRT1;
			//     break;
			//   default:
			//     break;
			// }

			break;
	}

	return CHAPTER;
};
