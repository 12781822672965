import { create } from 'lodash';
import './viewfinderMarks.scss';

export default function viewFinderMarks (content) {
  const div = document.createElement('div');
  div.classList.add('view-finder-marks');
  let marks = [];
  let totalMarks = 4;

  const createMarks = () => {
    for (let index = 0; index < totalMarks; index++) {
      let mark = document.createElement('div');
      mark.classList.add('view-finder-mark');
      marks.push(mark);
      div.appendChild(mark);  
    }
  };

  const styleMarks = (stroke='1px', size='50') => {
    marks.forEach(mark => {
      mark.style.width = `${content.size}px`;
      mark.style.height = `${content.size}px`;

      mark.style.borderLeft = `${stroke} solid black`;
      mark.style.borderTop = `${stroke} solid black`;
    });
  }

  createMarks();
  styleMarks(content.stroke, content.size);

  return div;
}
