import gsap from 'gsap';

export default function RangeFinderBlink (content) {
    const timeline = content.timeline;
    timeline.to(content.markers, 0, { scale: 0.8 });
    timeline.set(content.markers, { autoAlpha: 0 });
    timeline.to(content.markers, 0.2, { autoAlpha: 1 });
    timeline.to(content.markers, 0.2, { autoAlpha: 0 });
    timeline.to(content.markers, 0.2, { autoAlpha: 1 });
    timeline.to(content.markers, 0.2, { autoAlpha: 0 });
    timeline.to(content.markers, 0.2, { autoAlpha: 1 });
    timeline.to(content.markers, 0.6, { scale: 1.0 }, '-=0.6');
    timeline.set(content.rangeFinder, { autoAlpha: 0 });
    timeline.to(content.rangeFinder, 0.2, {autoAlpha:1});
    timeline.to(content.rangeFinder, 0.2, {autoAlpha:0});
    timeline.to(content.rangeFinder, 0.2, {autoAlpha:1});
    timeline.to(content.rangeFinder, 0.2, {autoAlpha:0});
    timeline.to(content.markers, 0.2, { autoAlpha: 0 });
    timeline.to(content.markers, 0.2, { autoAlpha: 1 });
    timeline.to(content.markers, 0.2, { autoAlpha: 0 });
}
    