import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { transformImage } from '../../../../utils/image';
import Codification from '../../../../components/Codification';
import './ColorBlock.scss';
import { NIKE_EASE } from '../../../../constants/nikerise';


export const COLOR_TRANSITIONS = {
    TopToBottom: 'topToBottom',
    BottomToTop: 'bottomToTop',
    LeftToRight: 'leftToRight',
    RightToLeft: 'rightToLeft',
    Top: 'top'
}

const DEFAULT_TRANSITION = { ease: NIKE_EASE, duration: 2, delay: 1 }

const ENTRY = {
    left: 'left',
    right: 'right',
    top: 'top',
    bottom: 'bottom',
    fade: 'fade',
    scale: 'scale',
}



const ColorBlock = ({ width, height, top, left, delay, duration = 3, inset = false, direction = COLOR_TRANSITIONS.BottomToTop }) => {

    const [dims, setDims] = useState({
        width: width ? width : '100px',
        height: height ? height : '100px',
        left: left ? left : 0,
        top: top ? top : 0,
        initial: true,
    });

    const animation = useAnimation();

    useEffect(() => {
        sequence();
    }, []);

    async function sequence() {

        let TRANSITIONS = {

            [COLOR_TRANSITIONS.TopToBottom]: [
                { top: '-100vh', transition: { ...DEFAULT_TRANSITION, duration: 1, delay: delay } },
                { top: '0vh', transition: { ...DEFAULT_TRANSITION, duration: duration / 2, delay: delay } },
                { top: '100vh', transition: { ...DEFAULT_TRANSITION, duration: 1, delay: 3 - delay } }
            ],
            [COLOR_TRANSITIONS.BottomToTop]: [
                { top: '100vh', transition: { ...DEFAULT_TRANSITION, duration: 1, delay: delay } },
                { top: '50vh', transition: { ...DEFAULT_TRANSITION, duration: duration / 2, delay: delay } },
                { top: '-100vh', transition: { ...DEFAULT_TRANSITION, duration: 1, delay: 3 - delay } }
            ],
            [COLOR_TRANSITIONS.Top]: [
                { top: '-100vh', transition: { ...DEFAULT_TRANSITION, duration: 1, delay: delay } },
                { top: '100vh', transition: { ...DEFAULT_TRANSITION, duration: 2, delay: 3 - delay } },
                { top: '100vh', transition: { ...DEFAULT_TRANSITION, duration: 1, delay: 3 - delay } }
            ],
            [COLOR_TRANSITIONS.LeftToRight]: [
                { left: '-100vw', transition: { ...DEFAULT_TRANSITION, duration: 1, delay: delay } },
                { left: '0vw', transition: { ...DEFAULT_TRANSITION, duration: duration / 2, delay: delay } },
                { left: '100vw', transition: { ...DEFAULT_TRANSITION, duration: 1, delay: 3 - delay } }
            ],
            [COLOR_TRANSITIONS.RightToLeft]: [
                { left: '100vw', transition: { ...DEFAULT_TRANSITION, duration: 1, delay: delay } },
                { left: '50vw', transition: { ...DEFAULT_TRANSITION, duration: duration / 2, delay: delay } },
                { left: '-100vw', transition: { ...DEFAULT_TRANSITION, duration: 1, delay: 3 - delay } }
            ]
        }

        await animation.start({ ...TRANSITIONS[direction][0] })
        await animation.start({ ...TRANSITIONS[direction][1] })
        await animation.start({ ...TRANSITIONS[direction][2] })

        animation.start({ scale: 1 })
    }

    // return <div className={`ColorBlock inset-${inset}`} style={{ ...dims, overflow: 'hidden', position: 'absolute' }}>
    return <motion.div
        className={`ColorBlock inset-${inset}`}
        style={{
            ...dims,
            overflow: 'hidden',
            position: 'absolute',
            opacity: 1,
            background: 'rgba(255,255,255,.3)',
            backdropFilter: 'blur(10px)',
        }}
        initial={{
            ...dims
        }}
        animate={animation}
        transition={{ ease: NIKE_EASE, duration: 2 }}
    >

    </motion.div>


    // </div >

};

export default ColorBlock;
