import gsap from 'gsap';
import { ScrambleTextPlugin } from '/src/touchpoints/live/lib/js/vendors/ScrambleTextPlugin.min.js';

gsap.registerPlugin(ScrambleTextPlugin);

export default function createTL (scenes, onComplete, onStart, credits=[], id) {
  const tl = gsap.timeline({
    onComplete,
    paused: true,
    onStart: onStart,
    defaults: { ease: 'power2.inOut' }
  });

  const uniforms = scenes.map(scene => scene.userData.shader.uniforms);

  const cg = `#${id} .content-grid`;
  const bgg = `#${id} .background-grid`;
  const ffi = `#${id} .freeflow-item`;
  const cgi = `#${id} .content-grid .grid-item`;

  const cgi1 = `#${id} .content-grid .grid-item-1`;
  const cgi2 = `#${id} .content-grid .grid-item-2`;
  const cgi3 = `#${id} .content-grid .grid-item-3`;

  const w = window.innerWidth;
  const h = window.innerHeight;

  // INITIAL STATES
  tl.add(() => {
    gsap.set(uniforms[0].mosaic, { value: 0 });
    gsap.set(uniforms[1].mosaic, { value: 0 });
    gsap.set(uniforms[2].mosaic, { value: 0 });

    gsap.set(uniforms[0].pixels, { value: 60 });
    gsap.set(uniforms[1].pixels, { value: 60 });
    gsap.set(uniforms[2].pixels, { value: 60 });

    console.log('CA STYLEGRID #', id);
  }, 0);

  // ANIMATIONS

  tl.to(uniforms[0].mosaic, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.1);
  tl.to(uniforms[1].mosaic, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.2);
  tl.to(uniforms[2].mosaic, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.3);

  tl.to(uniforms[0].pixels, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.1);
  tl.to(uniforms[1].pixels, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.2);
  tl.to(uniforms[2].pixels, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.3);

  function animateCredits (selector, credit) {
    tl.to(`${selector} .vt-bottom h2`, {
      duration: 1,
      opacity: 1,
      scrambleText: {
        text: credit || ' ',
        chars: '             nike             ',
      }
    }, 1);
  }

  animateCredits(cgi1, credits[0]);
  animateCredits(cgi2, credits[1]);
  animateCredits(cgi3, credits[2]);

  tl.to(`${cgi} .vt-bottom h2`, {
    duration: 1,
    scrambleText: {
      text: ' ',
      chars: '             nike             ',
    }
  }, 4);

  tl.to(uniforms[0].mosaic, { value: 0, duration: 1, ease: 'power0.easeNone' }, 4.1);
  tl.to(uniforms[1].mosaic, { value: 0, duration: 1, ease: 'power0.easeNone' }, 4.2);
  tl.to(uniforms[2].mosaic, { value: 0, duration: 1, ease: 'power0.easeNone' }, 4.3);

  tl.to(uniforms[0].pixels, { value: 60, duration: 1, ease: 'power0.easeNone' }, 4.1);
  tl.to(uniforms[1].pixels, { value: 60, duration: 1, ease: 'power0.easeNone' }, 4.2);
  tl.to(uniforms[2].pixels, { value: 60, duration: 1, ease: 'power0.easeNone' }, 4.3);

  tl.add(() => {}, 5.5);

  return tl;
}
