import {useMemo} from "react";
import Typography from "../../jordan/components/Typography";

export default function Title({content}: { content: string }) {
    const hasLineBreak = useMemo(() => {
        return content.includes('\n');
    }, [content]);

    return (
        <div style={{maxWidth: hasLineBreak ? '80%' : '60%'}}>
            <Typography
                variant={'brut'}
                size={150}
                color={'currentColor'}
                style={{
                    textTransform: 'lowercase',
                    textWrap: hasLineBreak ? undefined : 'balance',
                    lineHeight: '0.91em',
                }}
            >
                {content}
            </Typography>
        </div>
    )
}