import { imagesUrlService } from "../../../../utils/assets-service";

export default {
  animation: {
    json_data: {
      chapters: {
        full_body: {
          assets: [{
            url: imagesUrlService.getUrl('FLEECE-HOODIE-W-RED-5.jpg', { folderName: 'live/pdp-full-body' })
          }, {
            url: imagesUrlService.getUrl('FLEECE-HOODIE-W-RED-2.jpg', { folderName: 'live/pdp-full-body' })
          }, {
			  url: imagesUrlService.getUrl('FLEECE-HOODIE-W-RED-4.jpg', { folderName: 'live/pdp-full-body' })
          }],
        },
      },
    }
  }
}
