import ReactDOM from 'react-dom/client';
import React, { Component } from 'react';
import './VisibleHeatTest.scss';
import { imagesUrlService } from "../../../utils/assets-service";
import { getAnimation } from "../../../api";

const DEMO_DATA = {
	"created_by_app_user_id": 9,
	"animation_type_id": 5,
	"store_id": 16,
	"id": 35,
	"name": "Fearless mid Jordan 1 ",
	"layout": "",
	"json_data": {
		"demo": false,
		"tags": [
			"SNKRS"
		],
		"text": [
			"BLUE THE GREAT",
			"Air Jordan I Mid Fearless",
			"Designed in collaboration with Los Angeles based artist, Blue the Great, this AJ I leans on his love for primary colors on a suede and corduroy upper with his BTG artist signature featured on the heel. BTG started his art career in high school by hand-painting sneakers for friends, and has used the AJ I as a canvas in the past, but never like this.\n\n\nAs often seen in his work, each primary color plays off the one next to it to accentuate its individual impact. His red, black, and white color suede overlays in this special-edition AJ I are a familiar color blocking scheme to any AJ I fan, but that combination sets the rest of the sneaker up for tasteful experimentation with royal blue, golden yellow, and green suede overlays that are worthy of a double-take in the streets. There may be six distinct colors in this AJ I, and yet they all come together for one cohesive design thanks to perfect placement."
		],
		"assets": {
			"logo": {
				"black": imagesUrlService.getUrl('hoi-nyc-vert-black.png'),
				"white": imagesUrlService.getUrl('hoi-nyc-vert-white.png')
			},
			"background": {
				"black": imagesUrlService.getUrl('bg-jordan-vert-black.png'),
				"white": imagesUrlService.getUrl('bg-jordan-vert-white.png')
			}
		},
		"extras": [],
		"jordan": true,
		"slides": [],
		"disabled": false,
		"orientation": "portrait"
	},
	"approved_by_app_user_id": null,
	"approved_at": null,
	"created_at": 1572979071000,
	"updated_at": 1572994379000,
	"deleted_at": null
}

export class VisibleHeatTest {
	constructor(element, baseUrl, display) {
		const root = ReactDOM.createRoot(element);
		root.render(<VisibleHeat baseUrl={baseUrl} display={display} />);
	}
}

class VisibleHeat extends Component {
	constructor(props) {
		super(props);

		this.state = {
			updated_at: props.updated_at,
			color: 'black',
			data: null,
		}
	}

	async componentDidMount() {
		const app = window.app ? window.app : {};
		window.app = {
			...app,
			toggleActive: () => {
				this.toggleColor();
			},
		};

		window.addEventListener('keyup', ({ key, keyCode }) => {
			if (keyCode !== 32 && key !== 32) {
				return;
			}

			this.toggleColor();
		})

		await this.fetch();
	}

	fetch = async () => {
		const data = await getAnimation({
			baseURL: this.props.baseUrl,
			animationId: this.props.display.animation_id
		});
		this.setState({ data })
	}

	toggleColor = () => {
		this.setState({
			color: this.state.color == 'black' ? 'white' : 'black',
		});
	}

	render() {
		if (!this.state.data) {
			return (<div>no data</div>);
		}

		const { assets, text } = this.state.data.json_data;
		const { background } = assets;
		const { color } = this.state;
		const textColor = color == 'black' ? 'white' : 'black'

		return (
			<div style={{ backgroundImage: `url(${background[this.state.color]})`, color: textColor }} className={'VisibleHeatTest'}>
				<div className={'VisibleHeatTest-Hide'}>
					<img src={background['black']} alt="" />
					<img src={background['white']} alt="" />
				</div>
				<div className="VisibleHeatTest-SubTitle">
					{text[1]}
				</div>
				<div className="VisibleHeatTest-Heading">
					{text[0]}
				</div>

				<div className="VisibleHeatTest-Description">
					<div>
						{text[2] || ' '}
					</div>
				</div>
			</div>
		)
	}
}
