[
    {
      "duration": 1000,
      "vertical": [
        {
          "name": "Left",
          "drawToPosition": 0,
          "drawFromPosition": 100,
          "drawDirection": 1,
          "startPosition": 2.7,
          "drawFull": false
        },
        {
          "name": "Circle Center",
          "drawFull": false,
          "drawFromLine": null,
          "drawFromPosition": 100,
          "delay": 100,
          "startPosition": 15,
          "drawToPosition": 0
        },
        {
          "drawFull": false,
          "drawFromLine": null,
          "delay": 200,
          "drawFromPosition": 100,
          "segmentGroup": {
            "segmentPositionEnd": 33.5,
            "numLines": "4"
          },
          "startPosition": 21.5,
          "drawToPosition": 0,
          "name": "Left Seg"
        },
        {
          "drawToPosition": 0,
          "name": "Right Seg",
          "delay": 700,
          "drawFromPosition": 100,
          "drawFromLine": null,
          "drawFull": false,
          "segmentGroup": {
            "numLines": "4",
            "segmentPositionEnd": 87.3
          },
          "startPosition": 41
        },
        {
          "drawFromPosition": 100,
          "drawFull": false,
          "startPosition": 97.3,
          "drawToPosition": 0,
          "delay": 1100,
          "name": "Right"
        }
      ],
      "horizontal": [
        {
          "drawToPosition": 87.35,
          "drawFull": false,
          "delay": 1200,
          "startPosition": 31.25,
          "drawFromPosition": 71.8
        },
        {
          "startPosition": 68.75,
          "delay": 800,
          "drawFull": false,
          "drawToPosition": 97.3,
          "drawFromPosition": 2.7
        }
      ],
      "mask": [],
      "circle": [
        {
          "delay": 800,
          "startPositionY": 50,
          "clockwise": false,
          "drawAngle": 270,
          "startPositionX": 15,
          "drawFull": false,
          "startBoundaryY": 37.5,
          "drawFromAngle": 0
        }
      ],
      "drawBorder": true,
      "insetBorder": true
    },
    {
      "horizontal": [
        {
          "delay": 1200,
          "drawToPosition": 87.35,
          "drawFromPosition": 71.8,
          "drawFull": true,
          "startPosition": 31.25
        },
        {
          "drawFull": true,
          "drawToPosition": 97.3,
          "drawFromPosition": 2.7,
          "startPosition": 68.75,
          "delay": 800
        }
      ],
      "drawBorder": true,
      "mask": [],
      "insetBorder": true,
      "vertical": [
        {
          "startPosition": 2.7,
          "name": "Left",
          "drawToPosition": 0,
          "drawFull": true,
          "drawFromPosition": 100
        },
        {
          "name": "Circle Center",
          "startPosition": 15,
          "drawFromLine": null,
          "drawFromPosition": 100,
          "drawToPosition": 0,
          "delay": 100,
          "drawFull": true
        },
        {
          "segmentGroup": {
            "numLines": "4",
            "segmentPositionEnd": 33.5
          },
          "drawFull": true,
          "delay": 200,
          "startPosition": 21.5,
          "drawFromLine": null,
          "drawFromPosition": 100,
          "name": "Left Seg",
          "drawToPosition": 0
        },
        {
          "name": "Right Seg",
          "drawToPosition": 0,
          "drawFull": true,
          "segmentGroup": {
            "segmentPositionEnd": 87.3,
            "numLines": "4"
          },
          "startPosition": 41,
          "drawFromPosition": 100,
          "drawFromLine": null,
          "delay": 700
        },
        {
          "drawToPosition": 0,
          "delay": 1100,
          "drawFromPosition": 100,
          "name": "Right",
          "startPosition": 97.3,
          "drawFull": true
        }
      ],
      "duration": 1000,
      "circle": [
        {
          "clockwise": false,
          "startPositionY": 50,
          "drawFromAngle": 0,
          "drawAngle": 270,
          "startPositionX": 15,
          "delay": 800,
          "drawFull": true,
          "startBoundaryY": 37.5
        }
      ]
    }
  ]