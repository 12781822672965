export default class imgBackground extends HTMLElement {
  constructor (content) {
    super();

    this.classList.add('img-background');

    const img = document.createElement('img');
    img.src = content.src;
    this.appendChild(img);
  }
}
customElements.define('img-background', imgBackground, { extends: 'div' });
