function createForeignObject (opts) {
  return `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${opts.width} ${opts.height}">
  <foreignObject x="5" y="5" width="${opts.width}" height="${opts.height}">
    <div class="foreign-object-wrapper" xmlns="http://www.w3.org/1999/xhtml">
      ${opts.content.outerHTML}
    </div>
  </foreignObject>
</svg>`
}

function SyllableSeparation (text, maximumCharacters, syllablePerLine) {
  const syllableRegex = /[^aeiouy]*[aeiouy]+(?:[^aeiouy]*$|[^aeiouy](?=[^aeiouy]))?/gi;
  let separatedLines = [];
  text.forEach((line, i) => {
    if(line.length >= maximumCharacters) {
      let newLine = line.match(syllableRegex);
      for (let index = 0; index <= newLine.length; index++) {
        let lines = newLine.splice(0, syllablePerLine).join('');
        if(index < newLine.length - 1) {
          lines += '-';
        }
        separatedLines.push(lines);
      }
    } else {
      separatedLines.push(line);
    }
    });
    return separatedLines;
  }


function getSeason() {
  return `NS${new Date().getFullYear().toString().substr(-2)}`;
}

export { createForeignObject, SyllableSeparation, getSeason }


