#ifdef GL_ES
precision highp float;
#endif


#pragma glslify: mosaicMask = require('../../../../lib/glsl/mosaic.glsl')
#pragma glslify: coverUV = require('../../../../lib/glsl/coverUV.glsl')
#pragma glslify: pixelateUV = require('../../../../lib/glsl/pixelateUV.glsl')
#pragma glslify: zoomedUV = require('../../../../lib/glsl/zoomedUV.glsl')
#pragma glslify: circle = require('../../../../lib/glsl/circle.glsl')
#pragma glslify: gammaCorrect = require('../../../../lib/glsl/gammaCorrect.glsl')

varying vec2 vUv;

uniform float time;

// general stuffs
uniform vec2 res; // res
uniform float pixr; // pixel ratio
uniform float opacity;

uniform vec2 rangeFinder;
uniform float pixRF;
uniform float zoomRF;

uniform sampler2D img;
uniform vec2 imgRes;
uniform vec2 offset;
uniform float mosaic;
uniform float pixels;
uniform float zoom;

// cutout mask
uniform sampler2D cutoutTexture;
uniform float contourPixelAmount;
uniform float maskPixelAmount;
uniform float imgMosaicAmount;
uniform float maskZoom;

#define ITERATIONS 100
vec3 samplex(vec2 uv);
#pragma glslify: blur = require('../../../../lib/glsl/blur.glsl', sample=samplex, iterations=ITERATIONS)

vec3 samplex(vec2 uv) {
  return texture2D(cutoutTexture, uv).aaa;
}

vec2 centeredOffset () {
  float ar = (res.x / res.y);
  return ((offset - 0.5) * vec2(1.0, (1.0 / ar))) + 0.5;
}

vec4 layer(vec4 foreground, vec4 background) {
    return foreground * foreground.a + background * (1.0 - foreground.a);
}


void main() {
  vec4 background = vec4(1.0, 1.0, 1.0, 0.0); // transparent white

  float ar = (res.x / res.y);
  float iar = (imgRes.x / imgRes.y);
  float px = ((res.x * pixr) / pixels);

  vec2 uv = coverUV(vUv, res, imgRes);
  uv = zoomedUV(uv, imgRes, (zoom * iar), offset);

  float radius = 0.15;

  vec4 msk = texture2D(cutoutTexture, uv, -10.0);
  vec3 pix = texture2D(img, uv, -10.0).rgb;

  uv = pixelateUV(uv, 22.0);   
  float alpha = blur(uv, radius, ar, 0.0).r;
  alpha = 1.0 - step(0.9, 1.0 - alpha);
  vec4 newcolor = vec4(vec3(alpha * 1.), 1.0);
  if(newcolor.g < 0.99) {
    newcolor.a = 0.0;
  }

  
  float o = opacity * (1. - mosaicMask(vUv, ar, (time * 0.01), mosaic, 8.));
  if ((uv.x > 1. || uv.x < 0.) || (uv.y > 1. || uv.y < 0.)) {
    o = 1.0;
  } else if(newcolor.r < 0.1) {
    o = 0.0;
  }

  newcolor = vec4(pix.rgb, newcolor.r);
  gl_FragColor = mix(vec4(gammaCorrect(newcolor.rgb,2.2),newcolor.a), background, o);
}