import gsap from 'gsap';
import { ScrambleTextPlugin } from '/src/touchpoints/live/lib/js/vendors/ScrambleTextPlugin.min.js';
import {getSeason} from '/src/touchpoints/live/lib/js/utils.js';
gsap.registerPlugin(ScrambleTextPlugin);

function relWidth (w) {
  return w * (document.querySelector('.LLS-canvas').clientWidth / 480);
}
function relHeight (h) {
  return h * (document.querySelector('.LLS-canvas').clientHeight / 1080);
}

function createTL (start, stop, onComplete, uniforms, viewfinderText, texSwitches) {
  const tl = gsap.timeline({
    paused: true,
    repeat: 0,
    onComplete,
    defaults: {
      ease: 'power2.inOut'
    }
  });

  function getTextElements (key) {
    return viewfinderText[key].map((t) => {
      const e = document.createElement('span');
      e.textContent = t;
      return e;
    });
  }

  const textElements = {
    img1: getTextElements('img1'),
    img2: getTextElements('img2'),
    img3: getTextElements('img3'),
    img4: getTextElements('img4'),
    img5: getTextElements('img5'),
  }

  function addTextRemove (key, time) {
    tl.add(gsap.set(textElements[key][0], {
      scrambleText: { text: '', chars: '' },
      onComplete () { viewfinderText[key][0] = textElements[key][0].textContent }
    }), time);

    (textElements[key].length > 1) && tl.add(gsap.set(textElements[key][1], {
      scrambleText: { text: '', chars: '' },
      onComplete () { viewfinderText[key][1] = textElements[key][1].textContent }
    }), time);
  }

  function addTextScramble (key, time) {
    const season = (textElements[key].length > 1);

    tl.add(gsap.to(textElements[key][0], {
      duration: .33,
      onUpdate () { viewfinderText[key][0] = textElements[key][0].textContent },
      scrambleText: {
        text: season ? getSeason() : 'NIKE STYLE',
        // text: season ? 'SP22' : key,
        chars: 'ABCDEFGHIJKLMNOPQRSTUBWYZ1234567890><@=!&*#|-_',
      }
    }), time);

    season && tl.add(gsap.to(textElements[key][1], {
      duration: 1,
      onUpdate () { viewfinderText[key][1] = textElements[key][1].textContent },
      scrambleText: {
        text: 'NIKE STYLE',
        // text: key,
        chars: 'ABCDEFGHIJKLMNOPQRSTUBWYZ1234567890><@=!&*#|-_',
      }
    }), time);
  }

  tl.add(gsap.set(uniforms.img1Pix, { value: 120 }), 0);
  tl.add(gsap.set(uniforms.img2Pix, { value: 120 }), 0);
  tl.add(gsap.set(uniforms.img3Pix, { value: 120 }), 0);
  tl.add(gsap.set(uniforms.img4Pix, { value: 120 }), 0);
  tl.add(gsap.set(uniforms.img5Pix, { value: 120 }), 0);

  tl.add(gsap.set(uniforms.img1Opacity, { value: 0 }), 0);
  tl.add(gsap.set(uniforms.img2Opacity, { value: 1 }), 0);
  tl.add(gsap.set(uniforms.img3Opacity, { value: 0 }), 0);
  tl.add(gsap.set(uniforms.img4Opacity, { value: 0 }), 0);
  tl.add(gsap.set(uniforms.img5Opacity, { value: 0 }), 0);

  // INTRO IMG 1
  // tl.add(gsap.set(uniforms.img1Zoom, { value: 0.125 }), 0);
  tl.add(gsap.set(uniforms.img1Pos.value, { x: relWidth(360), y: relHeight(1000) }), 0);
  tl.add(gsap.set(uniforms.img1Dim.value, { x: relWidth(240), y: relHeight(160) }), 0);

  tl.add(gsap.set(uniforms.img1Opacity, { value: 1 }), 0.33);
  tl.add(gsap.to(uniforms.img1Pix, { value: 1, duration: 0.5, ease: 'expo.out' }), 0.33);
  tl.add(gsap.to(uniforms.img1Dim.value, {
    x: relWidth(160),
    y: relHeight(160),
    duration: 0.5,
    ease: 'power3.out'
  }), 0.33);
  tl.add(gsap.to(uniforms.img1Pos.value, {
    x: relWidth(240),
    y: relHeight(1000),
    duration: 0.5,
    ease: 'power3.out'
  }), 0.33);
  // tl.add(gsap.to(uniforms.img1Zoom, { value: 0.08, duration: 0.5, ease: 'power3.out' }), 0.33);

  // INTRO IMG 2
  tl.add(gsap.set(uniforms.img2Dim.value, { x: relWidth(480), y: relHeight(480) }), 0.1);
  tl.add(gsap.set(uniforms.img2Pos.value, { x: relWidth(240), y: relHeight(680) }), 0);
  tl.add(gsap.set(uniforms.img2Zoom, { value: 2.0 }), 0);

  tl.add(gsap.to(uniforms.img2Pix, { value: 1, duration: 0.5, ease: 'expo.out' }), 0.1);
  tl.add(gsap.to(uniforms.img2Dim.value, {
    x: relWidth(320),
    y: relHeight(320),
    duration: .5,
    ease: 'power3.out'
  }), 0.33);
  tl.add(gsap.to(uniforms.img2Pos.value, {
    x: relWidth(160),
    y: relHeight(760),
    duration: .5,
    ease: 'power3.out'
  }), 0.33);
  tl.add(gsap.to(uniforms.img2Zoom, { value: 1.0, duration: .5, ease: 'power3.out' }), 0.33);

  // INTRO IMG 3
  tl.add(gsap.set(uniforms.img3Opacity, { value: 1 }), 0.77);
  tl.add(gsap.to(uniforms.img3Pix, { value: 1, duration: 0.5, ease: 'power2.out' }), 0.77);

  // INTRO IMG 3
  tl.add(gsap.set(uniforms.img3Opacity, { value: 1 }), 0.77);
  tl.add(gsap.to(uniforms.img3Pix, { value: 1, duration: 0.5, ease: 'power2.out' }), 0.77);

  // INTRO IMG 4
  tl.add(gsap.set(uniforms.img4Opacity, { value: 1 }), 1);
  tl.add(gsap.to(uniforms.img4Pix, { value: 1, duration: 0.5, ease: 'power2.out' }), 1);

  // INTRO IMG 5
  tl.add(gsap.set(uniforms.img5Dim.value, { x: relWidth(320), y: relHeight(600) }), 0.1);
  tl.add(gsap.set(uniforms.img5Pos.value, { x: relWidth(360), y: relHeight(300) }), 0);
  tl.add(gsap.set(uniforms.img5Zoom, { value: 1.25 }), 0);

  tl.add(gsap.to(uniforms.img5Pix, { value: 1, duration: 0.5, ease: 'expo.out' }), 0.5);
  tl.add(gsap.set(uniforms.img5Opacity, { value: 1 }), 0.5);
  tl.add(gsap.to(uniforms.img5Dim.value, {
    x: relWidth(320),
    y: relHeight(440),
    duration: .5,
    ease: 'power2.in'
  }), 0.5);
  tl.add(gsap.to(uniforms.img5Pos.value, {
    x: relWidth(320),
    y: relHeight(220),
    duration: .5,
    ease: 'power2.in'
  }), 0.5);
  tl.add(gsap.to(uniforms.img5Zoom, { value: 0.75, duration: .5, ease: 'power2.in' }), 0.5);

  // IMG 1
  addTextScramble('img1', 1);
  addTextRemove('img1', 6);
  tl.add(gsap.to(uniforms.img1Zoom, { value: 1.5, duration: .5, ease: 'power2.in' }), 6);
  tl.add(gsap.to(uniforms.img1Pos.value, {
    x: relWidth(360),
    y: relHeight(1000),
    duration: .5,
    ease: 'power2.in'
  }), 6);
  tl.add(gsap.to(uniforms.img1Dim.value, {
    x: relWidth(240),
    y: relHeight(160),
    duration: .5,
    ease: 'power2.in'
  }), 6);
  tl.add(gsap.to(uniforms.img1Pix, { value: 120, duration: .5, ease: 'expo.in' }), 7.5);
  tl.add(gsap.to(uniforms.img1Pix, { value: 1, duration: .5, ease: 'expo.out' }), 8);
  addTextScramble('img1', 8.5);

  // IMG 2
  addTextScramble('img2', 1.5);
  addTextRemove('img2', 6);
  tl.add(gsap.to(uniforms.img2Zoom, { value: 2.0, duration: .5, ease: 'power2.in' }), 6);
  tl.add(gsap.to(uniforms.img2Zoom, { value: 1.0, duration: .5, ease: 'power2.in' }), 7);
  tl.add(gsap.to(uniforms.img2Pos.value, {
    x: relWidth(240),
    y: relHeight(680),
    duration: .5,
    ease: 'power2.in'
  }), 6);
  tl.add(gsap.to(uniforms.img2Dim.value, {
    x: relWidth(480),
    y: relHeight(480),
    duration: .5,
    ease: 'power2.in'
  }), 6);
  tl.add(gsap.to(uniforms.img2Pix, { value: 120, duration: .5, ease: 'expo.in' }), 6.5);
  tl.add(gsap.to(uniforms.img2Pix, { value: 1, duration: .5, ease: 'expo.out' }), 7);
  addTextScramble('img2', 7.5);

  // IMG 3
  addTextScramble('img3', 1.33);
  addTextRemove('img3', 6);
  tl.add(gsap.to(uniforms.img3Dim.value, {
    x: relWidth(0),
    duration: .5,
    ease: 'power2.in'
  }), 6);
  tl.add(gsap.to(uniforms.img3Pos.value, {
    x: relWidth(480),
    duration: .5,
    ease: 'power2.in'
  }), 6);

  // IMG 4
  addTextScramble('img4', 1.333);
  addTextRemove('img4', 6);
  tl.add(gsap.to(uniforms.img4Zoom, { value: 3.0, duration: 0.5, ease: 'power2.in' }), 6.0);
  tl.add(gsap.to(uniforms.img4Zoom, { value: 1.5, duration: 0.5, ease: 'power2.in' }), 7.0);
  tl.add(gsap.to(uniforms.img4Pos.value, {
    x: relWidth(120),
    y: relHeight(220),
    duration: .5,
    ease: 'power2.in'
  }), 6);
  tl.add(gsap.to(uniforms.img4Pos.value, {
    y: relHeight(80),
    duration: .5,
    ease: 'power2.in'
  }), 6.5);
  tl.add(gsap.to(uniforms.img4Dim.value, {
    x: relWidth(240),
    y: relHeight(440),
    duration: .5,
    ease: 'power2.in'
  }), 6);
  tl.add(gsap.to(uniforms.img4Dim.value, {
    y: relHeight(160),
    duration: .5,
    ease: 'power2.in'
  }), 6.5);
  tl.add(gsap.to(uniforms.img4Pix, { value: 120, duration: .5, ease: 'expo.in' }), 6.5);
  tl.add(gsap.to(uniforms.img4Pix, { value: 1, duration: .5, ease: 'expo.out' }), 7);
  addTextScramble('img4', 7.5);

  // IMG 5
  addTextScramble('img5', 1.5);
  addTextRemove('img5', 6);
  tl.add(gsap.to(uniforms.img5Zoom, { value: 0.55, duration: .5, ease: 'power2.in' }), 6);
  tl.add(gsap.to(uniforms.img5Pos.value, {
    x: relWidth(360),
    duration: .5,
    ease: 'power2.in'
  }), 6);
  tl.add(gsap.to(uniforms.img5Dim.value, {
    x: relWidth(240),
    duration: .5,
    ease: 'power2.in'
  }), 6);
  tl.add(gsap.to(uniforms.img5Pix, { value: 120, duration: .5, ease: 'expo.in' }), 6);
  tl.add(gsap.to(uniforms.img5Pix, { value: 1, duration: .5, ease: 'expo.out' }), 6.5);
  addTextScramble('img5', 8);

  texSwitches.forEach(({ time, fn}) => {
    tl.call(fn, null, time);
  });

  tl.call(stop, null, 12);

  return tl
}


export default { createTL };
