import React, { useEffect, useRef, useState } from "react";
import { DURATIONS } from "../shared";
import Background from "../FastLane/Background";
import { Codification } from "../../../components";
import swoosh from '../../../assets/swoosh.svg';
import { NIKE_EASE } from "../../../constants/nikerise";
import { motion } from 'framer-motion';

export default function Track({ data, info, index, onComplete, playOutro }) {
  const container = useRef(null);
  const [run, setRun] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRun(true);
    }, DURATIONS.SWEEP_IN)
  });

  const repeat = Math.round(info.trackLength / 600);

  return (
    <div
      ref={container}
      style={{
        position: 'relative',
        display: 'flex',
        height: '100%',
        width: '100%',
        flex: '1',
        overflow: 'hidden',
      }}
    >
      {
        data?.json_data?.gradient && (
          <Background
            heightMultiple={20}
            gradient={data?.json_data?.gradient}
            info={info}
            playOutro={playOutro}
            upsideDown
          />
        )
      }
      {
        run && (
          <div
            style={{
              zIndex: 9,
              width: '100%',
              height: '100%',
              color: 'white',
              display: 'flex',
              justifyContent: 'space-around',
              position: 'relative',
              alignItems: 'center'
            }}
          >
            {
              [...new Array(repeat)].map((a, i) => (
                <React.Fragment key={i}>
                  <div
                    style={{
                      width: info.trackHeight * 4,
                      textAlign: 'center',
                    }}
                  >
                    <Codification
                      text={data?.json_data?.ticker_text?.['EN']}
                      typography={{
                        primary: true,
                        typeStyle: { fontSize: info.trackHeight * .7, lineHeight: info.trackHeight * .7 },
                        tag: 'span',
                      }}
                      characterSwitchAmount={6}
                      characterNextTrigger={2}
                      characterTimeout={18}
                      start={true}
                      reverse={playOutro}
                    />
                  </div>

                  {
                    (i + 1) < repeat && (
                      <motion.img
                        src={swoosh}
                        initial={{
                          opacity: 0,
                          height: info.trackHeight * .5,
                          width: "auto"
                        }}
                        animate={{
                          opacity: !playOutro ? 1 : 0
                        }}
                        transition={{
                          ease: NIKE_EASE,
                        }}
                      />
                    )
                  }
                </React.Fragment>
              ))
            }
          </div>
        )
      }
    </div>
  )
}