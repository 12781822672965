import {createRef, CSSProperties, useLayoutEffect, useMemo, useRef, useState} from "react";
import Typography from "./Typography";
import {getCoordinates, ThemeType} from "../shared";
import logo from 'data-url:../assets/images/logo.png?as=webp';

export default function StoreJumpman({theme, style = {}}: { theme: ThemeType, style: CSSProperties }) {
    const root = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);

    const {coordinates, name} = useMemo(() => {
        const meta = window?.animation_store?.json_meta ?? {};
        return {
            coordinates: getCoordinates(),
            name: meta?.name,
        }
    }, []);


    useLayoutEffect(() => {
        setWidth(root.current?.getBoundingClientRect()?.width);
    }, [])

    const color = theme === 'dark' ? 'white' : 'black';

    return (
        <div ref={root} style={{position: 'relative', flex: 0, ...style}}>
            <div style={{
                clipPath: 'inset(0 0 20% 0)',
                filter: theme === 'light' ? 'invert(100%)' : undefined,
                marginBottom: -width * 0.08
            }}>
                <img src={logo} alt=""/>
            </div>
            <Typography
                variant={'pp-ultralight'}
                color={color}
                align={'center'}
                size={width * 0.08}
                style={{
                    lineHeight: '120%'
                }}
            >
                {name?.toUpperCase()} <br/>
                {coordinates}
            </Typography>
        </div>
    )
}