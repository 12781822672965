import './AppLogo.scss';

import React from 'react';
import Codification from '../../../../components/Codification';
import { imagesUrlService } from "../../../../../../utils/assets-service";

const QR = imagesUrlService.getUrl('qr_ntc.png');
const typeStyle = { fontSize: '1.1vh', lineHeight: '1.1vh' }

const AppLogo = ({ logo, qr, start }) => {
	return <div className={`AppLogo qr-${qr}`} >

		{
			qr ?
				<div className={'QR'}>
					<div className={'QR-headline'}>
						<Codification
							text={`Nike Training Club`}
							typography={{
								primary: true,
								typeStyle: typeStyle,
								tag: 'span',
							}}
							characterSwitchAmount={6}
							characterNextTrigger={2}
							characterTimeout={30}
							start={start}
						// isStatic={!start}
						// reverse={frameName === VisibleKeyframes[2]}
						/>
					</div>
					<img src={qr ? QR : logo.url} />
					<div className={'QR-subhead'}>
						<Codification
							text={`Scan to download`}
							typography={{
								primary: true,
								typeStyle: typeStyle,
								tag: 'span',
							}}
							characterSwitchAmount={6}
							characterNextTrigger={2}
							characterTimeout={30}
							start={start}
						// isStatic={!start}
						// reverse={frameName === VisibleKeyframes[2]}
						/>
					</div>
				</div>
				:
				<div className={'AppLogo-container'}>
					<img src={qr ? QR : logo.url} />
				</div>
		}


	</div >
};

export default AppLogo;
