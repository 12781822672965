import React from 'react';
import ReactDOM from 'react-dom/client';
import Overlay from './Overlay';

export default function renderAnimationOverlay() {
    console.log('animation-overlay: RENDER');

    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(<Overlay />);
}
