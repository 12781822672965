export default `
  <div class="app live-app">
    <div class="grid-container offset-grid zoomed-offset-grid background-grid">
      <div class="grid-item grid-item-1"></div>
      <div class="grid-item grid-item-2"></div>
      <div class="grid-item grid-item-3"></div>
      <div class="grid-item grid-item-4"></div>
      <div class="grid-item grid-item-5"></div>
      <div class="grid-item grid-item-6"></div>
      <div class="grid-item grid-item-7"></div>
      <div class="grid-item grid-item-8"></div>
      <div class="grid-item grid-item-9"></div>
    </div>

    <div class="grid-container standard-grid content-grid">
      <div class="grid-item grid-item-1">
        <div class="overlay">
          <span class="viewfinder corner-bottom-right"></span>
        </div>
      </div>
      <div class="grid-item grid-item-2">
        <div class="overlay">
          <span class="viewfinder corner-bottom-right"></span>
        </div>
      </div>
      <div class="grid-item grid-item-3">
        <div class="overlay">
          <span class="viewfinder corner-bottom-right"></span>
        </div>
      </div>
    </div>
  </div>
`;
