if (module.hot) {
  module.hot.accept(() => {
    location.reload()
  })
}

import * as THREE from 'three';

import moment from 'moment';

import markup from './pdp-closeup-markup';
import vert from '/src/touchpoints/live/lib/glsl/shader.vert';
import frag from './pdp-closeup.frag';
import fragCutOut from './pdp-closeup-cutout.frag';
import createTL from './pdp-closeup-timeline';
import './pdp-closeup.scss';

import {getSeason} from '/src/touchpoints/live/lib/js/utils.js';

import defaultMask from 'url:/src/touchpoints/live/lib/assets/contour-wide.png';
import defaultSwoosh from 'url:/src/touchpoints/live/lib/assets/swoosh.png';

import swatchList from '/src/touchpoints/live/lib/js/components/SwatchList/SwatchList';
import verticalText from '/src/touchpoints/live/lib/js/components/VerticalText/VerticalText';
import placedCanvas from '/src/touchpoints/live/lib/js/components/PlacedCanvas/PlacedCanvas';

class PDPCloseup {
  constructor (name, datas, onComplete, index, renderers, iframeIndex) {
    this.id = `${name}-${index}`;
    this.name = name;
    this.datas = datas;
    this.isWide = datas.is_wide;
    const that = {};
    this.onComplete = onComplete;
    const width = window.innerWidth;
    const height = window.innerHeight;

    this.wrapper = document.createElement('div');
    this.wrapper.id = this.id;
    this.wrapper.classList.add('chapter-wrapper');
    this.wrapper.classList.add('ca-closeup');
    this.wrapper.classList.add(`is-size-${window.SIZE_ID?.toLowerCase()}`);

    this.wrapper.classList.add('loading');
    // Timing Functions
    this.totalTimeWide = 20;
    // FilterAssets
    this.currentWideAsset = 0;
    this.filteredAssets =  this.datas.assets.filter(asset => asset !==  null && !asset.maskUrl);
    this.cutoutAsset = this.datas.assets.filter(asset => asset !==  null && asset.hasOwnProperty("maskUrl"));
    this.totalAssets = [];
    this.filteredAssets.forEach((asset) => {
      this.totalAssets.push(asset);
    })
    if(this.hasCutout()) {
      this.totalAssets.push(this.cutoutAsset[0]);
    } else {
      document.querySelector('.canvas-wrapper-cutout')?.remove();
    }

    this.timePerItemWide =  this.totalTimeWide / this.totalAssets.length;
     const units = {
      SMALL: '15px',
      MEDIUM: '25px',
      LARGE: '40px',
    }
    document.documentElement.style.setProperty('--lls-ca-closeup-unit', units[window.SIZE_ID]);
    const borderThicknesses = {
      SMALL: '1px',
      MEDIUM: '2px',
      LARGE: '2px',
    }
    document.documentElement.style.setProperty('--lls-ca-closeup-border-thick', borderThicknesses[window.SIZE_ID]);

    if (width < height) {
      this.isVertical = true;
      this.wrapper.classList.add('is-vertical');
    }
    this.wrapper.innerHTML += markup;
    document.body.appendChild(this.wrapper);

    this.renderer = this.createRenderer('ca-closeup-main-canvas', renderers.front);
    this.cutoutRenderer = this.createRenderer('ca-closeup-cutout-canvas', renderers.cutout);

    this.scenes = [];
    this.creditsTexts = [];

    this.credits = [this.datas.credit?.EN, getSeason()];

    this.insertMediaView(`.freeflow-item`, this.filteredAssets[0]?.url, 0);
    this.insertMediaView(`.freeflow-item`, this.cutoutAsset[0]?.url, 1, (this.cutoutAsset[0]?.maskUrl || defaultMask));
    this.loadIndex = 2;
    this.timingData = {
      timePerAsset: this.timePerItemWide,
      totalTime: this.totalTimeWide,
      filteredAssets: this.filteredAssets,
      cutoutAsset: this.cutoutAsset,
      updateTex: this.updateWideAsset,
      iframeIndex: iframeIndex,
      parent: this
    }
    
    this.createCreditsText(`.overlay`, '', 'top');
    this.createCreditsText(`.overlay`, '', 'bottom');

    if(this.datas.credit) {
      // this.wrapper.querySelector('.vt-top').style.minHeight = `${(this.datas.credit?.EN.length) * (window.innerHeight < 500 ? 8.5 : 14)}px`
    }

    const swatchesDatas = this.datas.assets[0]?.swatches;
    if (swatchesDatas?.length) {
      const offsets = [];
      const swatches = new Array(swatchesDatas.length).fill({}).map((e, i) => {
        return {
          color: swatchesDatas[i % swatchesDatas.length]
        };
      });
      const swatchListHTML = swatchList({ swatches }, 4);
      this.wrapper.querySelector(`.overlay`).appendChild(swatchListHTML);
    }

    this.running = false;
  }

  updateWideAsset(parent, index) {
    parent.updateTexture(this.filteredAssets[index]?.url, 0);
  }

  createCreditsText (selector, credits, position) {
    const vt = verticalText({ text: credits });
    vt.classList.add(`vt-${position}`);
    this.wrapper.querySelector(`${selector} .corner-${position}-right`).appendChild(vt);
    this.creditsTexts.push(vt);
  }

  hasCutout() {
    return this.cutoutAsset && this.cutoutAsset.length > 0;
  }

  createRenderer (className, renderer) {
    renderer.setClearColor(0xFFFFFF, 0);
    
    this.resizeRendererToDisplaySize(renderer);
    renderer.domElement.classList.add('ca-closeup-canvas');
    renderer.domElement.classList.add(className);
    return renderer;
  }

  resizeRendererToDisplaySize (renderer) {
    const canvas = renderer.domElement;
    const pixelRatio = window.devicePixelRatio;
    const width = window.innerWidth;
    const height = window.innerHeight;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
      renderer.setSize(width, height, false);
    }
    return needResize;
  }

  updateTexture (url, index) {
    this.createTexture(url, (texture) => {
      texture.needsUpdate = true;
      this.scenes[index].userData.shader.uniforms.img.value = texture;
      this.scenes[index].userData.shader.uniforms.imgRes.value.x = texture.image.width;
      this.scenes[index].userData.shader.uniforms.imgRes.value.y = texture.image.height;
    });
  }

  onStart () {
    this.wrapper.classList.remove('loading');
  }

  createTexture (url, onLoaded) {
    const img = new Image();
    const texture = new THREE.Texture(img);
    texture.encoding = THREE.sRGBEncoding;
    texture.generateMipmaps = false;
    texture.minFilter = THREE.NearestFilter;
    texture.magFilter = THREE.NearestFilter;
    texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();

    img.addEventListener('load', () => {
      onLoaded(texture);
    });

    img.crossOrigin = 'anonymous';
    img.src = url?.replace(/(.png)/gmi, '.jpg') || defaultSwoosh;
  }

  insertMediaView (selector, url, index, cutOutURL) {
    const width = window.innerWidth;
    const height = window.innerHeight;

    this.createTexture(url, (texture) => {
      texture.needsUpdate = true;

      const cutOutUniforms = cutOutURL
        ? {
          maskPixelAmount: new THREE.Uniform(10),
          contourPixelAmount: new THREE.Uniform(35),
          maskZoom: new THREE.Uniform(0.1),
          cutoutTexture: new THREE.Uniform(new THREE.Texture())
        } : {}

      const scene = placedCanvas({
        parent: this.wrapper.querySelector(selector),
        uniforms: {
          // GENERAL
          isWide: new THREE.Uniform(this.isWide ? true : false),
          seed: (Math.random() * 1000),
          time: new THREE.Uniform(0),
          opacity: new THREE.Uniform(1),
          pixr: new THREE.Uniform(window.devicePixelRatio),
          res: new THREE.Uniform(new THREE.Vector2(width, height)),
          // IMG
          img: new THREE.Uniform(texture),
          offset: new THREE.Uniform(new THREE.Vector2(0)),
          imgRes: new THREE.Uniform(new THREE.Vector2(
            texture.image.width,
            texture.image.height
          )),
          hasCutout: new THREE.Uniform(this.cutoutAsset[0]?.url === undefined ? false : true),
          mosaic: new THREE.Uniform(1),
          pixels: new THREE.Uniform(1),
          zoom: new THREE.Uniform(cutOutURL ? 0.8 : 1),

          // CUTOUT (if needed)
          ...cutOutUniforms
        },
        renderer: this.renderer,
        shader: {
          vert,
          frag: fragCutOut
        },
      });

      if (cutOutURL) {
        this.createCutOutTex(cutOutURL, scene.userData.shader);
        scene.userData.isCutout = true;
      }
      this.scenes[index] = scene;

      if (this.scenes.filter(s => s).length === this.loadIndex) {
        this.tl = createTL(this.scenes, this.onComplete, this.onStart.bind(this), this.credits, this.isWide,  this.timingData);
        if (this.delayedPlay) {
          this.tl.play(0);
        }
      }
    });
  }

  createCutOutTex (url, shader) {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = url?.replace('.webp', '.png');
    img.addEventListener('load', (e) => {
      const texture = new THREE.Texture(img);
      texture.encoding = THREE.sRGBEncoding;
      texture.generateMipmaps = false;
      texture.minFilter = THREE.NearestFilter;
      texture.magFilter = THREE.NearestFilter;
      texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
      shader.uniforms.cutoutTexture.value = texture;
      shader.uniforms.cutoutTexture.value.needsUpdate = true;
    });
  }

  renderScene (scene, renderer) {
    if (renderer === null) {
      return;
    }

    const element = scene.userData.parent;
    const rect = element.getBoundingClientRect();

    const width = (rect.right - rect.left);
    const height = (rect.bottom - rect.top);
    const left = rect.left;
    const bottom = (renderer.domElement.clientHeight - rect.bottom);
    const vp = new THREE.Vector4(left, bottom, width, height);

    renderer.setViewport(vp);
    renderer.setScissor(vp);

    scene.userData.shader.uniforms.time.value++;
    scene.userData.shader.uniforms.res.value.x = width;
    scene.userData.shader.uniforms.res.value.y = height;

    renderer.render(scene, scene.userData.camera);
  }

  render () {
    if (this.running) {
      this.renderer.setScissorTest(true);
      this.scenes.forEach((scene) => {
        const cutoutRenderer = this.hasCutout() ? this.cutoutRenderer : null;
        const renderer = scene.userData.isCutout ? cutoutRenderer : this.renderer;
        this.renderScene(scene, renderer);
      });
      this.renderer.setScissorTest(false);
    }

    this.loop = requestAnimationFrame(this.render.bind(this));
  }

  start () {
    this.wrapper.style.display = 'block';
    if(this.loop) {
      cancelAnimationFrame(this.loop);
    }
  
    if (this.tl) {
      this.tl.play(0);
    } else {
      this.delayedPlay = true;
    }
    this.running = true;
    this.loop = requestAnimationFrame(this.render.bind(this));
  }
  stop () {
    this.wrapper.style.display = 'none';
    this.running = false;
    this.tl?.pause();
    this.tl?.seek(0);
    cancelAnimationFrame(this.loop);
  }
  pause () {
    this.running = false;
    this.tl?.pause();
  }
  resume () {
    this.tl?.resume();
    this.running = true;
  }
}
export default PDPCloseup;
