import * as PIXI from 'pixi.js-legacy';

/** Add a line where poles are located. Note: Moving away from the idea of poles */
const addPole = (x, y, index, grids) => {
  const pole = new PIXI.Graphics();
  pole.lineStyle({
    width: 6,
    color: 0xda5df6,
  });
  pole.moveTo(x, y);
  pole.lineTo(x, y + height);

  grids.app.stage.addChild(pole);

  const style = new PIXI.TextStyle({
    fill: ['#da5df6'],
  });

  const basicText = new PIXI.Text(index, style);
  basicText.position.set(x + 10, y + height / 2);
  grids.app.stage.addChild(basicText);
};

/** Place lines based on pixel position with pixel labels */
const addRulerLines = (x, y, grids, height, index) => {
  const pole = new PIXI.Graphics();
  pole.lineStyle({
    width: 1,
    color: 0xf1d61a,
  });
  pole.moveTo(x, y + 50);
  pole.lineTo(x, y + height - 50);

  const style = new PIXI.TextStyle({
    fill: ['#f1d61a'],
    fontSize: 8,
  });

  const basicText = new PIXI.Text((index * 25).toString(), style);
  const textY = index % 2 ===0? y + height - 50: y + 40
  basicText.position.set(x - 10, textY);

  grids.app.stage.addChild(pole);
  grids.app.stage.addChild(basicText);
};

/** Function to call any of the available placement visualization helpers*/
export const showDevHelpers = (showRulers, info, grids) => {
  if (showRulers) {
    const rulerLines = info.trackLength / 25;
    for (let i = 0; i < rulerLines; i++) {
      const x = (i * 25) % info.renderWidth;
      const y = Math.floor((i * 25) / info.renderWidth) * info.trackHeight;

      addRulerLines(x, y, grids, info.trackHeight, i);
    }
  }
};

export const Colors = [0x0d353f, 0x006475, 0x017979, 0xf6f5c7, 0xf57d6c];
