import React from "react";
import ReactDOM from "react-dom/client";

// Hyperlive Utils
import * as Hyperlive from '../../hyperlive/utils.js';

import Demo from './Demo.js';

const url = new URL(window.location.href);

const channel = url.searchParams.get('channel') ? url.searchParams.get('channel') : null;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Demo channel={channel} />);
