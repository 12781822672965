import React, {useCallback, useEffect, useMemo, useState} from 'react';
import "../../../rise/styles/normalize.css";
import '../../styles/fonts.scss';
import Container from "../../components/Container";
import ChapterRenderer, {ChapterRenderOptions} from "../../components/ChapterRenderer";
import TitleCard from "../../screens/TitleCard";
import Product from "../../screens/Product";
import UgcCard from "../../screens/UgcCard";
import Membership from "../../screens/Membership";
import Intro from "../../screens/Intro";
import Services from "../../screens/Services";
import Lookbook from "../../screens/lookbook";
import StoreDirectory from "../../screens/StoreDirectory";
import ChapterRunner from "../../components/ChapterRunner";
import SnkrsCal from "../../screens/SnkrsCal";
import Media from "../../screens/Media";
import SnkrsTitle from "../../screens/SnkrsTitle";
import {getChannelInfo} from '../../../rise/hyperlive/utils';
import {listenForTakeover} from "../../../../utils/assets-service/StoreTakeover";
import {ChapterType, prepareShowRunner} from "../../shared";
import WrapAnimatePresence from "../../components/WrapAnimatePresence";
import {ShowRunnerContext} from '../../contexts/ShowRunner';
import './style.scss';
import {renderOptions} from "../core/app";

export const takeoverWidth = getChannelInfo('json_config.store_takeover.width', Math.round(window.innerWidth));
export const takeoverHeight = getChannelInfo('json_config.store_takeover.height', Math.round(window.innerHeight));

export default function App({data = {}, config}) {
    const [activeChapter, setActiveChapter] = useState<null | number>(null);
    const [chapters, setChapters] = useState<Array<ChapterType>>([]);

    useEffect(() => {
        prepareShowRunner(data, config).then((c) => {
            setChapters(c);
            listen();
        });
    }, []);

    const playChapter = useCallback((chapter) => {
        setActiveChapter(chapter);
    }, [chapters]);


    const listen = useCallback(() => {
        const clean = listenForTakeover((type, chapter) => {
            if (type === 'start') {
                playChapter(0);
            } else {
                console.warn('stop');
                setActiveChapter(null);
            }
        });

        return () => clean();
    }, [chapters]);

    // @TODO: remove some duplicate stuff right here.
    const contextMethods = useMemo(() => {
        if (chapters.length === 0) {
            return;
        }

        const activeChapterIndex = activeChapter === null ? null : activeChapter % chapters.length;

        return {
            chapters,
            activeChapterIndex,
            getActiveChapter: () => chapters[activeChapterIndex],
        }
    }, [chapters, activeChapter]);

    useEffect(() => {
        if (!activeChapter && activeChapter !== 0) {
            // stopped
            return;
        }

        setTimeout(() => {
            setActiveChapter(null);
        }, contextMethods.chapters?.[activeChapter]?.duration);

    }, [activeChapter])

    if (chapters.length === 0 || !contextMethods.getActiveChapter() || activeChapter === null) {
        return null;
    }

    return (
        <Container
            framed={true}
            width={takeoverWidth}
            height={takeoverHeight}
        >
            <ShowRunnerContext.Provider value={contextMethods as any}>
                <WrapAnimatePresence>
                    <ChapterRenderer
                        renderOptions={renderOptions}
                        key={contextMethods.activeChapterIndex}
                        index={contextMethods.activeChapterIndex}
                        chapter={contextMethods.getActiveChapter()}
                        config={config}
                    />
                </WrapAnimatePresence>
            </ShowRunnerContext.Provider>
        </Container>
    )
}