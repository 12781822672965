export const GLYPHS = [
	"lesserThan",
	"hashtag",
	"ampersand",
	"openRoundParentheses",
	"exclamation",
	"closeRoundParentheses",
	"degrees",
	"hyphen",
	"underscore",
	"circumflex",
	"multiply",
	"percent",
	"colon",
	"forwardSlash",
	"semiColon",
	"dot",
	"comma",
	"questionMark",
	"plus",
	"equal",
	"largerThan",
	"0",
	"1",
	"2",
	"3",
	"4",
	"5",
	"6",
	"7",
	"8",
	"9",
];

export const EXCEPTIONS = [".", ","];

export default {
	GLYPHS,
	EXCEPTIONS,
};
