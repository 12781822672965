import {CSSProperties, useEffect, useMemo, useState} from "react";

interface ContainerProps {
    width: number;
    height: number;
    children: any;
    framed?: boolean;
    circle?: boolean;
    forceColor?: string;
}

export default function Container({width, height, children, framed, circle, forceColor}: ContainerProps) {
    const [forceFrame, setForceFrame] = useState(framed);
    const style = useMemo(() => {
        return {
            width: forceFrame ? width : undefined,
            height: forceFrame ? height : undefined,
            backgroundColor: forceColor ?? 'white',
            position: 'relative',
            overflow: 'hidden',
            borderRadius: (circle && forceFrame) ? width : undefined,
        } as CSSProperties;
    }, [forceFrame, width, height, circle]);

    useEffect(() => {
        // hitting Alt+Space will force dims
        addEventListener('keydown', ({code, altKey}) => {
            if (code === 'Space' && altKey) {
                setForceFrame(i => !i);
            }
        });
    }, []);

    return (
        <div style={{background: 'black', width: '100vw', height: '100vh', overflow: 'hidden'}}>
            <div style={style}>
                {children}
            </div>
        </div>
    );
}