import gsap from 'gsap';
import { ScrambleTextPlugin } from '/src/touchpoints/live/lib/js/vendors/ScrambleTextPlugin.min.js';

gsap.registerPlugin(ScrambleTextPlugin);

export default function presetTL (onPixelEffect, topText, callback) {
  const tl = this.tl;
  const { uniforms } = this.visualLayer;
  tl.add(gsap.set(this.viewFinderMarks, { opacity: 1 }), 0);
  tl.add(gsap.set(this.app, { scale: 0.9 }), 0);
  // tl.add(gsap.set(uniforms.img1Zoom, { value: 0.8 }), 0);

  tl.add(gsap.to(this.app, { scale: 1, duration: .33 }), 0);
  // tl.add(gsap.to(uniforms.img1Zoom, { value: 1, duration: .7 }), 0);

  tl.add(gsap.to(this.verticalTextTop.querySelector('h2'), {
    duration: 1,
    scrambleText: {
      text: topText,
      chars: '             NIKE             ',
    }
  }), 2.1);

  tl.add(gsap.to(uniforms.pixa, {
    value: 240,
    duration: .3,
    ease: 'power3.in',
    onComplete: onPixelEffect
  }), 2.0);

  tl.add(gsap.to(uniforms.pixa, {
    value: 1,
    duration: .3,
    ease: 'power3.out'
  }), 2.3);

  tl.add(gsap.to(uniforms.mosaicAmount, { value: 1, duration: 2.0, ease: 'power3.out' }), 4);
  tl.add(gsap.to(this.getAllInOverlay('h2'), {
    duration: 1,
    scrambleText: {
      text: '  ',
      chars: '             NIKE             ',
    }
  }), 4.5);
  tl.add(gsap.to(this.getAllInOverlay('.swatch-item'), { opacity: 0, duration: 1, ease: 'expo.out' }), 4.5);
  tl.add(gsap.set(this.viewFinderMarks, { opacity: 0, duration: 1, ease: 'expo.out' }), 4.5);
}
