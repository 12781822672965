import {Chapter} from "../shared";
import Transition from "../../jordan/components/Transition";
import {ReactNode} from "react";
import OrbSvg from "./orb-svg";
import Progress from "./progress";
import OrbVideo from "./orb-video";

export default function ChapterContainer({chapter, children}: { chapter: Chapter, children: ReactNode }) {
    return (
        <Transition variant={'fade'} style={{position: 'absolute', color: chapter?.pillar_color?.color}}>
            {/* @HARLEY WEBGL */}
            {/*<Orb pillarColor={chapter.pillar_color}/>*/}
            <OrbVideo pillar={chapter.pillar} />
            <Progress chapter={chapter}/>
            {children}
        </Transition>
    )
}