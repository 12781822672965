import React, { useEffect, useState } from 'react';
import './NTCSplitscreen.scss';

import _ from 'lodash';
import TextBlock from '../../components/TextBlock/TextBlock.js';
import MediaBlock from '../../components/MediaBlock/MediaBlock.js';
import ContentBlock from '../../components/ContentBlock/ContentBlock';
import QRCode from '../../components/QRCode/QRCode';
import { MEDIA_TRANSITIONS, BLOCK_SIZES, TRANSITIONS, DEFAULT_TRANSITION, TEXT_SIZES, NTC_FALLBACK } from '../../constants';
import AppText from '../../components/AppText/AppText';


const BLOCK_POSITIONS = {
    Primary: {
        Logo: {
            top: '25vh',
            left: '3.125vh',
            width: BLOCK_SIZES.Logo.width,
            height: BLOCK_SIZES.Logo.height
        },
        Header: {
            top: '37.5vh',
            left: '15.625vh',
            width: BLOCK_SIZES.Header.width,
            height: BLOCK_SIZES.Header.height
        },
        Body: {
            top: '50vh',
            left: '15.625vh',
            width: BLOCK_SIZES.AppCardTall.width,
            height: BLOCK_SIZES.AppCardTall.height
        },
    },
    Secondary: {
        Logo: {
            top: '37.5vh',
            left: '3.125vh',
            width: BLOCK_SIZES.Logo.width,
            height: BLOCK_SIZES.Logo.height
        },
        Header: {
            top: '50vh',
            left: '15.625vh',
            width: BLOCK_SIZES.Header.width,
            height: BLOCK_SIZES.Header.height
        },
        Body: {
            top: '62.5vh',
            left: '15.625vh',
            width: BLOCK_SIZES.AppCard.width,
            height: BLOCK_SIZES.AppCard.height
        },
    },

}

const TIMINGS = {
    text_intro_delay: .75,
    text_intro_duration: 5,
    media_transition: MEDIA_TRANSITIONS.LeftCorner,
    media_intro_delay: 1,
    media_intro_duration: 2,
    media_duration: 3,
    media_exit_duration: 1,
    logo_delay: 1,
    logo_duration: 1,
    header_delay: 2,
    header_duration: 8,
}

const NTCSplitscreen = ({ layout = 'Primary', visible = false, data = {} }) => {

    const POSITIONS = BLOCK_POSITIONS[layout];


    const { title, qr_section, selected_entries } = data;

    const qrcode = qr_section?.media?.[1]?.url;

    const ntc = qr_section?.media?.[0]?.url;

    const entry = selected_entries?.[Math.floor(Math.random() * selected_entries.length)]

    return <div className={'NTCSplitscreen'}>

        <div className={'NTCSplitscreen-content'}>
            <MediaBlock
                visible={visible}
                key={`logo`}
                inset={true}
                media={
                    {
                        resource_type: 'image',
                        url: ntc ? ntc : NTC_FALLBACK
                    }
                }
                left={POSITIONS.Logo.left}
                top={POSITIONS.Logo.top}
                width={POSITIONS.Logo.width}
                height={POSITIONS.Logo.height}
                animationTiming={{
                    transition: MEDIA_TRANSITIONS.Center,
                    intro_delay: TIMINGS.logo_delay,
                    intro_duration: TIMINGS.logo_duration,
                    media_duration: TIMINGS.media_duration,
                    exit_duration: TIMINGS.media_exit_duration
                }}
            />


            <ContentBlock
                visible={visible}
                key={`header`}
                left={POSITIONS.Header.left}
                top={POSITIONS.Header.top}
                width={POSITIONS.Header.width}
                height={POSITIONS.Header.height}
                inset={true}
                animationTiming={{
                    transition: MEDIA_TRANSITIONS.LeftCorner,
                    intro_delay: TIMINGS.media_intro_delay + .25,
                    intro_duration: TIMINGS.media_intro_duration,
                    media_duration: TIMINGS.media_duration,
                    exit_duration: TIMINGS.media_exit_duration
                }}
            >
                <TextBlock
                    text={title}
                    left={"2.5%"}
                    top={"5%"}
                    width={"100%"}
                    height={"100%"}
                    textMode={'black'}
                    delay={TIMINGS.header_delay}
                    duration={TIMINGS.header_duration}
                    orientation={'portrait'}
                    type={TEXT_SIZES.Heading}
                />
            </ContentBlock>

            {entry &&
                <ContentBlock
                    visible={visible}
                    key={`appcard`}
                    left={POSITIONS.Body.left}
                    top={POSITIONS.Body.top}
                    width={POSITIONS.Body.width}
                    height={POSITIONS.Body.height}
                    inset={true}
                    theme={'dark'}
                    animationTiming={{
                        transition: MEDIA_TRANSITIONS.TopBack,
                        intro_delay: TIMINGS.media_intro_delay + .5,
                        intro_duration: TIMINGS.media_intro_duration,
                        media_duration: TIMINGS.media_duration,
                        exit_duration: TIMINGS.media_exit_duration
                    }}
                >


                    <MediaBlock
                        visible={visible}
                        darken={true}
                        media={
                            entry?.video_url ?
                                {
                                    resource_type: 'm3u8-video',
                                    url: entry?.video_url
                                }
                                :
                                {
                                    resource_type: 'image',
                                    url: entry?.image_url
                                }
                            // resource_type: 'image',
                            // url: 'https://static.nike.com/a/images/w_960,c_limit/xdtgf2klx0tjcyt8yb76/make-your-nights-more-efficient.jpg'
                            // resource_type: 'm3u8-video',
                            // url: 'https://api.nike.com/content/asset_video_manifest/v1/6259197215001.m3u8'
                        }
                        key={`media0`}
                        left={"0%"}
                        top={'0%'}
                        width={"100%"}
                        height={"100%"}
                        animationTiming={{
                            transition: MEDIA_TRANSITIONS.None,
                            intro_delay: TIMINGS.media_intro_delay,
                            intro_duration: TIMINGS.media_intro_duration,
                            media_duration: TIMINGS.media_duration,
                            exit_duration: TIMINGS.media_exit_duration
                        }}
                    />

                    <QRCode qrcode={qrcode} />

                    <AppText title={entry?.title} supertitle={entry?.subtitle} />



                </ContentBlock>
            }


        </div>


    </div>

};

export default NTCSplitscreen;



{/* <ContentBlock
                key={`c1`}
                left={"27.7778%"}
                top={'62.7%'}
                width={"66.666%"}
                height={"26.56%"}
                text={'HEADLINE'}
                inset={true}
                theme={'dark'}
                animationTiming={{
                    transition: MEDIA_TRANSITIONS.TopBack,
                    intro_delay: TIMINGS.media_intro_delay,
                    intro_duration: TIMINGS.media_intro_duration,
                    media_duration: TIMINGS.media_duration,
                    exit_duration: TIMINGS.media_exit_duration
                }}
            >

                <p className={'paragraph'}>
                    Download the Nike Training App to reach your fitness goals. Kirsty Godso brings her signature energy and style to every workout.
                </p>

                <QRCode />

            </ContentBlock> */}