import {Chapter, EventType, getFullEventType, transitions} from "../shared";
import Typography from "../../jordan/components/Typography";
import Title from "../components/title";
import {motion} from "framer-motion";
import ChapterContainer from "../components/chapter-container";
import {DEFAULT_TRANSITION} from "../../rise/constants/transition";
import {ReactNode, useEffect, useMemo, useState} from "react";
import Media from "../../jordan/components/Media";
import QrCode from "../components/qr-code";
import BottomSection from "../components/bottom-section";
import QrSection from "../components/qr-section";

const transition = transitions.slideIn;

export default function Event({chapter}: { chapter: Chapter }) {
    const [playVideo, setPlayVideo] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setPlayVideo(true)
        }, 6_000);
    }, []);

    const m = useMemo(() => {
        if (!chapter?.media?.length) {
            return;
        }

        // use corsproxy to avoid cors issues
        function makeVideoUrl(videoUrl) {
            if (process.env.NODE_ENV === 'development' && ['u3m8', 'm8u3'].filter(i => videoUrl.includes(i)).length > 0) {
                videoUrl = 'https://corsproxy.io/?' + encodeURIComponent(videoUrl);
            }
            return videoUrl;
        }

        return chapter.media.map(i => ({
            ...i,
            url: makeVideoUrl(i?.url),
        }));
    }, [chapter?.media]);

    const singleLine = useMemo(() => {
        return chapter?.details?.reduce((acc, curr = '') => {
            return acc + curr.length;
        }, 0) < 20;
    }, [chapter?.details]);

    return (
        <ChapterContainer chapter={chapter}>
            {
                chapter.media?.length > 0 && (
                    <motion.div
                        style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', zIndex: 0}}
                        initial={{
                            opacity: 0,
                        }}
                        animate={{
                            opacity: playVideo ? 1 : 0,
                        }}
                    >
                        <Media
                            cover={'fill'}
                            preventExit={true}
                            media={m}
                            width={'100%'}
                            dim={true}
                            theme={'dark'}
                            height={'100%'}
                            effect={'zoom-out-center'}
                        />
                    </motion.div>
                )
            }

            <motion.div
                style={{top: 362, position: 'absolute', left: 50}}
                {...transition}
            >
                <Typography
                    color={'currentColor'} size={53} style={{marginBottom: 12, textTransform: 'uppercase'}}
                    variant={'brut'}>
                    {chapter?.pillar}
                </Typography>

                {
                    chapter?.title && <Title content={chapter.title}/>
                }

                <Typography color={'currentColor'} size={60} style={{marginTop: 66, textTransform: 'lowercase'}}
                            variant={'pp-ultralight-italic'}>
                    {chapter?.subtitle}
                </Typography>
            </motion.div>

            <motion.div
                style={{
                    top: 1260,
                    position: 'absolute',
                    right: 50,
                    width: '50%',
                }}
                {...transition}
                transition={{
                    ...DEFAULT_TRANSITION,
                    delay: 1,
                }}
            >
                <Typography
                    variant={'brut'}
                    style={{textTransform: 'uppercase'}}
                    size={53}
                    color={'currentColor'}
                >
                    {chapter.bottom_section?.title ? '' : getFullEventType(chapter?.event_type as EventType)}
                </Typography>

                <div style={{
                    display: singleLine ? 'flex' : undefined,
                    gap: 20,
                    textTransform: 'uppercase',
                    justifyContent: 'space-between',
                    marginRight: 20,
                    position: 'relative',
                }}>
                    {
                        chapter?.details?.map((detail, i) => (
                            <Typography
                                color={'currentColor'}
                                size={53}
                                key={i}
                                style={{
                                    marginTop: 24,
                                    textTransform: 'lowercase',
                                }}
                                variant={'pp-ultralight-italic'}
                            >
                                {detail}
                            </Typography>
                        ))
                    }
                </div>

            </motion.div>

            {
                chapter.bottom_section ? (
                    <BottomSection
                        media={chapter.bottom_section.media}
                        title={chapter.bottom_section.title}
                        superTitle={chapter.bottom_section.super_title}
                    />
                ) : (
                    <QrCode
                        activeChapterIndex={chapter?.index}
                        cta={chapter?.event_type === 'local_event' ? chapter?.cta : null}
                        hideSwoosh={chapter?.event_type === 'local_event'}
                        icon={<EventIcon eventType={chapter?.event_type}/>}
                    />
                )
            }


        </ChapterContainer>
    )
}

function EventIcon({eventType}: { eventType: EventType | string }) {
    const icons: Record<EventType, ReactNode> = {
        local_event: null,
        ntc: NtcIcon(),
        nrc: NrcIcon(),
    };

    return <>{icons?.[eventType]}</>;
}

function NrcIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="150"
            height="150"
            fill="none"
            viewBox="0 0 150 150"
        >
            <path fill="#fff" d="M29 23H121V120H29z"></path>
            <path
                fill="#000"
                d="M123.343 0H26.157A26.202 26.202 0 000 26.157v97.186A26.2 26.2 0 0026.157 149.5h97.186a26.202 26.202 0 0026.157-26.157V26.157A26.202 26.202 0 00123.343 0zM64.406 108.196h-7.384l-6.185-16.23v16.332h-8.772V74.977h9.386l4.255 14.928V74.977h8.685l.015 33.219zm15.381 0l-5.073-13.788c0 1.638.13 3.275.13 4.912v8.876h-8.99V74.874h8.684c7.808 0 13.451 2.296 13.451 11.098a8.406 8.406 0 01-5.161 8.348l6.697 13.876h-9.738zm27.78-23.394a5.966 5.966 0 00-4.05-1.96 4.812 4.812 0 00-4.313 2.355 12.092 12.092 0 00-1.462 6.447c-.108 2.163.4 4.313 1.462 6.2a4.667 4.667 0 004.181 2.251 5.51 5.51 0 004.197-1.842v9.022a11.473 11.473 0 01-5.41 1.359 12.737 12.737 0 01-5.425-1.155 11.892 11.892 0 01-4.24-3.363 16.075 16.075 0 01-2.792-5.336 23.233 23.233 0 01-1.01-7.136 22.53 22.53 0 011.024-6.93 16.083 16.083 0 012.925-5.38 13.058 13.058 0 014.576-3.48 14.008 14.008 0 015.995-1.243c.736 0 1.471.072 2.193.22.742.155 1.474.356 2.193.599l-.044 9.372zm-48.25-21.551c-5.278 2.251-13.553 4.84-17.004-.76-1.93-3.1-.716-7.925 2.135-12.34a64.117 64.117 0 016.448-7.97c-1.301 2.106-4.928 9.168-.176 12.692 1.872 1.345 5.132 1.462 9.182.424l48.396-12.91-48.98 20.864z"
            ></path>
            <path
                fill="#000"
                d="M74.845 82.126v8.218h.57a3.845 3.845 0 003.656-4.24 3.628 3.628 0 00-4.226-3.978z"
            ></path>
        </svg>
    );
}

function NtcIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="150"
            height="150"
            fill="none"
            viewBox="0 0 1034 1034"
        >
            <rect width="1034" height="1034" fill="#fff" rx="150"></rect>
            <path
                fill="#000"
                d="M584.122 330.693c-96.863 26.034-180.723 48.393-186.354 49.516-12.39 2.655-32.868 3.165-41.469 1.021-17.509-4.288-29.489-16.744-32.766-33.793-2.867-15.008 1.331-35.223 11.366-55.438l4.198-8.372-3.277 3.063c-5.631 5.309-22.628 25.626-33.482 40.123-37.476 49.925-41.776 97.706-10.546 118.431 20.683 13.783 48.841 13.374 91.641-1.634 7.884-2.654 95.225-39.306 194.034-81.37 206.832-87.904 186.252-79.021 184.306-79.021-.819.102-80.788 21.44-177.651 47.474zM696.242 523.551c-19.249 4.083-35.427 13.374-50.786 29.301-15.359 15.927-25.701 36.652-30.82 61.87-2.356 11.434-2.56 52.681-.41 64.524 3.584 20.215 14.744 45.637 26.11 59.93 11.775 14.804 28.26 25.728 46.691 30.935 5.324 1.531 11.775 2.45 21.093 2.757 15.359.612 24.472-.817 37.066-6.024l7.372-3.063v-66.158l-6.655 4.595c-8.908 6.023-15.871 8.065-26.52 7.452-15.052-.816-24.369-7.452-31.844-22.665-10.751-21.848-9.932-62.584 1.741-84.535 3.686-6.942 10.956-14.395 16.587-17.152 13.414-6.33 30.206-3.777 42.391 6.739l4.3 3.675-.205-34.508-.307-34.508-6.143-2.042c-8.089-2.553-39.319-3.369-49.661-1.123zM282.577 647.086v123.025h64.507l.205-59.42.307-59.419 22.629 59.113 22.628 59.215 27.851.307 27.748.204v-246.05h-65.531l-.205 53.804-.307 53.804-15.461-53.497-15.359-53.601-34.506-.306-34.506-.204v123.025zM460.739 553.669v29.607h46.077v186.835h62.459V583.276h46.077v-59.215H460.739v29.608z"
            ></path>
        </svg>
    );
}