import { scaleText } from '../../shared';
import cssClassNames from '../../styles/components/PhantomText.module.scss';


export default function rewrapText(translations: string[], containerElement: HTMLElement) {
    const {width} = containerElement.getBoundingClientRect();
    const space = containerElement.querySelector(`.${cssClassNames.space}`);
    const spaceWidth = space ? space.getBoundingClientRect().width : 0;

    const scale = scaleText(containerElement, `.${cssClassNames.word}`);

    const rewrappedTanslations: string[][] = [];
    const translationElements = containerElement.querySelectorAll(`.${cssClassNames.text}`);
    for (let trIndex = 0; trIndex < translations.length; trIndex++) {
        const translationContaner = translationElements[trIndex];

        const rewrappedLines: string[][] = [];
        const lines = Array.from(translationContaner.querySelectorAll(`.${cssClassNames.line}`));
        for (const line of lines) {
            const words = line.querySelectorAll<HTMLSpanElement>(`.${cssClassNames.word}`);

            let wordsInLine: string[] = [];
            rewrappedLines.push(wordsInLine);
            let occupiedWidth = 0;
            for (let w = 0; w < words.length; w++) {
                const wordElement = words[w];
                const word = wordElement.innerText;
                const wordWidth = wordElement.getBoundingClientRect().width || 0;
                const goingToOccupie = occupiedWidth + wordWidth;

                if (goingToOccupie <= width || w === 0) {
                    wordsInLine.push(word);
                    occupiedWidth = goingToOccupie + spaceWidth;
                    continue;
                }

                occupiedWidth = wordWidth;
                wordsInLine = [word];
                rewrappedLines.push(wordsInLine);
            }
        }

        rewrappedTanslations.push(rewrappedLines.map(words => words.join(' ')));
    }

    const maxAmountOfLines = rewrappedTanslations.reduce((result, current) => {
        return Math.max(result, current.length);
    }, 0);
    const newTranslatedLines: string[][] = [];
    for (let n = 0; n < maxAmountOfLines; n++) {
        const translatedLine: string[] = [];
        for (let tr = 0; tr < translations.length; tr++) {
            translatedLine[tr] = rewrappedTanslations[tr][n] || '';
        }
        newTranslatedLines[n] = translatedLine;
    }

    return {
        lines: newTranslatedLines,
        scale,
    };
}
