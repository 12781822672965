// React
import React from 'react';
import ReactDOM from 'react-dom/client';
import Video from './video';

// Hyperlive Utils
import * as Hyperlive from '../../hyperlive/utils.js';

const { json_data } = Hyperlive.fetchJSON();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Video data={json_data} />);
