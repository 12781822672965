export const DEFAULT_BORDER = `1px solid #222`;
export const DEFAULT_GRADIENT = 'linear-gradient(160deg, #e77833 0%, #dd1d1d 68.33%)';

export function randomString() {
    return Math.random().toString(36).substr(2, 5);
}

export function fairlyUnique() {
    return `${Date.now()}-${randomString()}`;
}

export async function wait(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function useRpx() {
    const WIDTH = 1920;

    const multiplier = window.innerWidth / WIDTH;

    return (value: number, raw: boolean = false) => {
        const val = value * multiplier;
        return raw ? val : `${val}px`;
    }
}
