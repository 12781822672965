import {fetchDrops, SNKRS_CAL_CONFIG} from "../../hoi/animations/snkrs-calendar/js/dataService";
import Transition from "../components/Transition";
import {useCallback, useEffect, useMemo, useState} from "react";
import {
    keyframe,
    truncate,
    countDown,
    MediaType,
    qrUrlTrack,
    toTitleCase,
    DEFAULT_EASE,
    stringFallback,
    stringIsFilled,
} from "../shared";
import Media from "../components/Media";
import Typography from "../components/Typography";
import Drop from "../components/SnkrsCalDrop";
import {AnimateSharedLayout, motion} from "framer-motion";
import {getStoreLanguages} from '../../rise/hyperlive/utils';
import {JordanConfig} from "../components/ChapterRunner";
import {containsJapanese} from "../../../utilities";

const BG_COLOR = 'rgb(241,242,240)';
export const DEFAULT_PLAY_RANGE = '0,5';
export const MAIN_SCREEN_DURATION = 3;
export const SNKR_DETAIL_DURATION = 10;
const snkrsIcon = new URL('../assets/images/snkrs-app-icon.png?as=webp&width=120', import.meta.url);
const SNKRS_URL = `https://www.nike.com/launch`;

export function loadDrops(marketplace = 'US', play_range = DEFAULT_PLAY_RANGE) {
    const config = SNKRS_CAL_CONFIG[marketplace];

    return new Promise((resolve, reject) => {
        fetchDrops({
            inStock: "true",
            onlyJordan: true,
            ...config,
            onComplete: ({drops, inStockMode}) => {
                const hydrated = drops.map((drop) => {
                    return {
                        ...drop,
                        colorDescription: drop.colorDescription.split('/').slice(0, 2).join(' / '),
                        url: config?.launchUrl ? `${config?.launchUrl}${drop.urlSlug}` : undefined,
                        key: drop.key,
                        images: drop.images.map((image): MediaType => ({
                            resource_type: 'image',
                            url: image.url,
                        }))
                    } as DropInterface;
                });

                const filtered = hydrated.filter(({brand}) => brand?.toUpperCase() === 'JORDAN');
                const results = filtered.length < 6 ? hydrated : filtered;
                const deDuped = results.reduce((acc, curr) => {
                    const drop = acc.filter(i => i.key === curr.key).length > 0 ? [] : [curr];

                    return [
                        ...acc,
                        ...drop
                    ]
                }, []);
                resolve(deDuped.slice(0, 6));
            }
        });
    });
}

export function getDuration({play_range = DEFAULT_PLAY_RANGE, drops, mode,}: SnkrsCalProps, config: JordanConfig) {
    const {snkrs} = config.duration;

    return getPlayArray(play_range).reduce((acc, a, i) => {
        const specificDuration = mode === 'stories' ? snkrs.story : snkrs.drop;
        return acc + snkrs.overview + specificDuration + 1_000;
    }, 0) + snkrs.overview;
}

export function dateFormat(drop, {
    format = 'simple',
    locale = 'US',
    available_copy = 'Released'
}: { format?: 'simple' | 'complex', locale?: string, available_copy?: string } = {}) {
    if (!drop?.startEntryDate || !drop?.startEntryDate?.getTime || isNaN(drop.startEntryDate)) {
        return '';
    }
    const now = new Date();
    const diffTime = Math.abs(now.getTime() - drop.startEntryDate.getTime());
    const diffDays = diffTime / (1000 * 3600 * 24);

    if (format === 'complex') {
        if (diffDays < 1) {
            return countDown(drop.startEntryDate);
        }

        if (diffDays < 8) {
            return drop.startEntryDate.toLocaleDateString(locale, {weekday: 'long'})
        }
    }

    const dirty = new Intl.DateTimeFormat(locale, {dateStyle: 'long'}).format(drop.startEntryDate);
    const formatted = dirty.replace(drop.startEntryDate.getFullYear(), '').trimEnd().replace(/,(\s+)?$/, '');
    const titleCased = toTitleCase(formatted);

    if (drop.startEntryDate <= now) {
        return `${available_copy} ${titleCased}`;
    }

    return titleCased;
}

export interface SnkrsCalProps {
    drops: Array<DropInterface>;
    count?: number;
    play_range?: string;
    mode: SnkrsCalMode;
    index: number;
    hide_dates?: boolean;
    cta_label?: string;
    locale?: string;
    config: JordanConfig;
    available_copy?: string;
    qr_url?: string;
}

export type SnkrsCalMode = 'drops' | 'stories';

export interface DropInterface {
    fulltitle: string;
    subtitle: string;
    slug: string;
    url?: string;
    key: string;
    colorDescription: string;
    description: string;
    startEntryDate: Date;
    images: Array<MediaType>;
    cover: MediaType;

    // Stories
    title?: string;
}

export function getPlayArray(play_range: string) {
    const [start, end] = play_range.split(',').map(i => Number(i));

    return [...new Array((end - start) + 1)].map((a, i) => i + start);
}

export default function SnkrsCal({
                                     drops,
                                     play_range = '0,5',
                                     count = 6,
                                     mode = 'drops',
                                     index,
                                     hide_dates = false,
                                     cta_label,
                                     qr_url,
                                     locale: givenLocale,
                                     config,
                                     available_copy
                                 }: SnkrsCalProps) {
    const {snkrs} = config.duration;
    const locale = useMemo(() => {
        if (givenLocale) {
            return givenLocale;
        }

        return getStoreLanguages()?.[0] ?? 'US';
    }, [givenLocale]);
    const [selected, setSelected] = useState(null);
    const [runner, setRunner] = useState(0);
    const filtered = useMemo(() => drops?.slice(0, count), [drops, count]);

    useEffect(() => {
        const {trigger, clear} = keyframe(0);
        const playLength = getPlayArray(play_range);
        const modeDuration = ((mode === 'drops' ? snkrs.drop : snkrs.story) / 1_000) + 1;
        const overview = snkrs.overview / 1_000;

        const retrigger = playLength.reduce((trigger, index) => {
            return trigger
            (overview, () => setSelected(index))
            (modeDuration, () => setSelected(null));
        }, trigger);

        retrigger(6, () => setRunner(i => i + 1));

        return clear;
    }, [runner]);

    return (
        <Transition variant={'wipe-x'} style={{position: 'absolute'}}>
            <div
                style={{
                    background: BG_COLOR,
                    height: '100%',
                    width: '100%',
                }}
            >
                <div style={{
                    width: '100%',
                    height: mode === 'stories' ? '100%' : '1708px',
                    display: 'flex',
                    flexWrap: 'wrap'
                }}>
                    {
                        filtered.map((drop, i) =>
                            <Drop
                                locale={locale}
                                hide_dates={hide_dates}
                                index={index}
                                mode={mode}
                                key={drop.key}
                                drop={drop}
                                available_copy={available_copy}
                                onTop={selected === i}
                            />
                        )
                    }
                </div>
                {
                    mode === 'drops' && (
                        <div
                            style={{
                                height: '212px',
                                width: '100%',
                                background: 'white',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                paddingLeft: '50px'
                            }}
                        >
                            <div>
                                <Typography size={48} variant={'pp-ultralight-italic'} color={'black'}>
                                    SNKRS
                                </Typography>
                                <Typography size={48} variant={'pp-ultralight'} color={'black'}>
                                    Upcoming Drops
                                </Typography>
                            </div>
                        </div>
                    )
                }

                <Selected
                    drop={filtered?.[selected]}
                    config={config}
                    mode={mode}
                    show={selected !== null}
                    index={index}
                    hide_dates={hide_dates}
                    cta_label={cta_label}
                    qr_url={qr_url}
                    locale={locale}
                    available_copy={available_copy}
                />
            </div>
        </Transition>
    )
}

function Selected({
                      drop,
                      show,
                      mode,
                      index,
                      hide_dates = false,
                      cta_label,
                      locale,
                      qr_url,
                      config,
                      available_copy,
                  }: { drop: DropInterface, show?: boolean, qr_url?: string, mode: SnkrsCalMode, index: number, hide_dates?: boolean, cta_label?: string, locale?: string, config: JordanConfig, available_copy?: string }) {
    const {snkrs} = config.duration;
    const [showCoverCard, setShowCoverCard] = useState(true);

    const DEFAULT_STORY_COVER_DURATION = 4;

    const storyCoverDuration = useMemo(() => {
        if (mode === 'drops') {
            return;
        }

        if (snkrs.story_page_count === 0) {
            return DEFAULT_STORY_COVER_DURATION + (snkrs.story / 1_000);
        }

        return DEFAULT_STORY_COVER_DURATION;
    }, [mode, drop, snkrs]);

    const droppingAt = useMemo(() => dateFormat(drop, {locale, available_copy}), [drop, hide_dates]);

    const height = useMemo(() => {
        if (mode === 'drops') {
            return 1212;
        }
        return showCoverCard ? 1_492 : 1920
    }, [mode, showCoverCard]);
    const [showBackground, setShowBackground] = useState(false);

    const makeMedia = useCallback((drop) => {
        const totalTime = mode === 'drops' ? snkrs.drop : snkrs.story - (DEFAULT_STORY_COVER_DURATION * 1_000);
        const totalCount = mode === 'drops' ? (snkrs.drop_media_count + 1) : snkrs.story_page_count;

        const images = drop.images.map((i) => ({
            ...i,
            force_duration: (totalTime / totalCount) / 1_000,
        }))

        if (mode === 'drops') {
            const [first] = images;
            const i = images.splice(0, totalCount - 1);
            return [...i, {...first, force_duration: 10}];
        }

        const cover = {...drop.cover, force_duration: storyCoverDuration};

        if (snkrs.story_page_count === 0) {
            return [cover];
        }

        return [cover, ...images];
    }, [mode, storyCoverDuration]);

    useEffect(() => {
        let coverId: number = null;
        let bgId: number = null;

        if (show === true) {
            coverId = setTimeout(() => {
                setShowCoverCard(false);
            }, storyCoverDuration * 1_000 + 200);

            if (mode === 'drops') {
                setShowBackground(true);

                setTimeout(() => {
                    setShowBackground(false);
                }, (snkrs.drop + 500))
            }

        } else {
            setTimeout(() => {
                setShowCoverCard(true);
            }, snkrs.story_page_count === 0 ? storyCoverDuration : 2_000);
        }


        return () => clearTimeout(coverId);
    }, [show, storyCoverDuration]);

    const hasJapanese = useCallback((content) => {
        return containsJapanese(content);
    }, []);

    const titleHasJapanese = hasJapanese(drop?.subtitle);

    return (
        <motion.div
            initial={false}
            animate={{
                background: showBackground ? `rgba(241, 242, 240, 1)` : `rgba(241, 242, 240, 0)`,
            }}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
            }}
            transition={{
                ease: DEFAULT_EASE,
                duration: showBackground ? 1 : 2,
            }}
        >
            {
                drop && (
                    <motion.div
                        style={{
                            width: 1080,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        animate={{height}}
                        initial={false}
                        transition={{
                            duration: 1,
                            delay: 0,
                            ease: DEFAULT_EASE,
                        }}
                    >
                        <Media
                            width={mode === 'drops' ? '100%' : '100%'}
                            height={mode === 'drops' ? '90%' : '100%'}
                            preventInitial
                            preventInitialSlide
                            layoutId={`${drop.slug}-${index}`}
                            transparent
                            useInternalTiming
                            media={makeMedia(drop)}
                        />
                    </motion.div>
                )
            }

            {
                mode === 'stories' && (
                    <motion.div
                        initial={false}
                        animate={{
                            y: show ? '0%' : '110%',
                            background: showCoverCard ? 'linear-gradient(rgba(255,255,255,1), rgba(255,255,255,1))' : 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.8))',
                            height: showCoverCard ? 428 : 108,
                        }}
                        transition={{
                            ease: DEFAULT_EASE,
                            delay: 0,
                            duration: showCoverCard ? .5 : 1,
                        }}
                        style={{
                            zIndex: 2,
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            padding: '50px 30px',
                        }}
                    >
                        <Typography variant={(showCoverCard && !hasJapanese(drop?.title ?? drop?.subtitle)) ? 'pp-ultralight-italic' : 'pp-ultralight'}
                                    color={showCoverCard ? 'black' : 'white'} size={42}>
                            {stringFallback(drop?.title, drop?.subtitle)}
                        </Typography>

                        <motion.div
                            style={{marginTop: 10}}
                            animate={{
                                opacity: showCoverCard ? 1 : 0,
                            }}
                            transition={{
                                ease: DEFAULT_EASE,
                                duration: .5,
                            }}
                        >
                            <Typography variant={'pp-ultralight'} color={showCoverCard ? 'black' : 'white'} size={42}>
                                ↑{drop?.subtitle}
                            </Typography>
                        </motion.div>

                        <motion.div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                            }}
                            animate={{
                                opacity: showCoverCard ? 1 : 0,
                            }}
                            transition={{
                                ease: DEFAULT_EASE,
                                duration: .5,
                            }}
                        >
                            <SnkrsDropQr cta={cta_label} url={stringFallback(qr_url, SNKRS_URL)}/>
                        </motion.div>
                    </motion.div>
                )
            }

            {
                mode === 'drops' && (
                    <motion.div
                        initial={false}
                        animate={{
                            y: show ? '0%' : '100%',
                        }}
                        transition={{
                            ease: DEFAULT_EASE,
                            duration: 1,
                        }}
                        style={{
                            zIndex: 2,
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            height: 708,
                            padding: '33px 30px',
                            background: 'white',
                        }}
                    >
                        <Typography
                            variant={titleHasJapanese ? 'jordan' : 'jordan-condensed'}
                            color={'black'}
                            size={titleHasJapanese ? 125 : 200}
                            style={{
                                lineHeight: titleHasJapanese ? '90%' : '80%',
                            }}
                        >
                            {truncate(drop?.subtitle?.toUpperCase(), titleHasJapanese ? 24 : 24)}
                        </Typography>

                        <Typography
                            size={42}
                            style={{marginTop: 10}}
                            variant={hasJapanese(drop?.colorDescription) ? 'pp-ultralight' : 'pp-ultralight-italic'}
                            color={'black'}
                        >
                            {truncate(drop?.colorDescription, 20)}
                        </Typography>
                        {
                            hide_dates ? null : (
                                <Typography
                                    size={42}
                                    style={{marginTop: 17}}
                                    variant={'pp-ultralight'}
                                    color={'black'}
                                >
                                    {droppingAt}
                                </Typography>
                            )
                        }
                        {
                            drop?.url && <SnkrsDropQr cta={cta_label}
                                                      url={stringFallback(qr_url, stringFallback(drop.url, SNKRS_URL))}/>
                        }
                    </motion.div>
                )
            }
        </motion.div>
    );
}

function SnkrsDropQr({url, cta = 'VIEW IN SNKRS'}: { url: string, cta?: string }) {
    const fullUrl = useMemo(() => qrUrlTrack(url), [url]);

    return (
        <div style={{position: 'absolute', bottom: 45, minWidth: 400}}>
            {
                stringIsFilled(cta) && (
                    <Typography variant={'jordan'} color={'black'} size={20}>
                        {cta}
                    </Typography>
                )
            }

            <div style={{display: 'flex', gap: 20, marginTop: 13, alignItems: 'center'}}>
                <img style={{width: 70, height: 70}} src={fullUrl} alt=""/>
                <img style={{width: 67, height: 67}} src={snkrsIcon.toString()} alt=""/>
            </div>
        </div>
    )
}