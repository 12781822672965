import {useNbyStore} from "../store";
import {useEffect, useMemo, useState} from "react";
import ImageScroller from "./image-scroller";
import {AnimatePresence} from "framer-motion";

export default function StepsRight() {
    const [exit, setExit] = useState(false);
    const duration = useNbyStore(state => state.stepDuration);
    const activeIndex = useNbyStore(state => state.currentIndex);
    const steps = useNbyStore(state => state.animationData.steps);
    const step = useNbyStore(state => state.getCurrentStep());

    const columnCount = useMemo(() => {
        return activeIndex === steps.length - 1 ? 1 : 2;
    }, [steps, activeIndex]);

    const columns = useMemo(() => {
        const media = step?.media || [];

        if (columnCount === 1) {
            return [media];
        }

        return [media.slice(0, 3), media.slice(3, 6)];
    }, [columnCount, activeIndex]);

    useEffect(() => {
        setExit(false);

        setTimeout(() => {
            setExit(true);
        }, duration - 1000);
    }, [activeIndex]);

    return (
        <div
            style={{
                display: 'flex',
                height: '100%',
            }}
        >
            <AnimatePresence mode={'wait'}>
                {columns.map((mediaList, index) => exit ? <></> : (
                    <ImageScroller
                        columnCount={columnCount}
                        direction={index % 2 === 0 ? 'down' : 'up'}
                        media={mediaList}
                        index={index}
                        key={`image-scroller-${index}-column-${activeIndex}}`}
                    />
                ))}
            </AnimatePresence>
        </div>
    )
}