import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {get} from 'lodash';
import {motion} from 'framer-motion';
import '../../styles/fullscreen.scss';
import '../demo/Demo.scss';

function resizeAndCompress(videoUrl) {
  // check window.location url for query param full-resolution
  const urlParams = new URLSearchParams(window.location.search);
  const fullResolution = urlParams.get('full-resolution');

  if (fullResolution) {
    return videoUrl;
  }

  return videoUrl?.replace('upload/', 'upload/w_2688,c_limit,q_auto:low/');
}

export default function Video({data, muted = true}) {
  const mediaRefs = useRef([]);
  const isLandscape = useMemo(() => get(data, 'orientation', 'portrait') === 'landscape', [data]);
  const [activeIndex, setActiveIndex] = useState(null);

  const media = useMemo(() => {
    const a = data?.assets?.length > 0 ? data.assets : [data?.asset];
    return a.filter(a => a?.url).map((i, j) =>
      ({...i, key: `${i?.url}-${j}`})
    );
  }, [data?.assets]);

  const play = useCallback(() => {
    let triggered = false;
    const incrementIndex = () => {
      if (triggered) {
        return;
      }
      triggered = true;
      setActiveIndex(i => (i + 1) % media.length);
    }

    const selectedMedia = media?.[activeIndex];
    const videoPlayer = mediaRefs.current[activeIndex];

    let duration = selectedMedia?.duration ?? 120;
    duration = selectedMedia.force_duration === 'full' ? duration : selectedMedia.force_duration;
    console.log('resource_type', selectedMedia?.resource_type, duration);

    if (selectedMedia?.resource_type === 'video' || videoPlayer?.tagName === 'VIDEO') {
      videoPlayer.currentTime = 0;
      videoPlayer.play();
      videoPlayer.addEventListener('ended', incrementIndex, {once: true});
    }

    setTimeout(() => {
      // console.log('end playing', activeIndex);
      incrementIndex();
    }, parseInt(duration) * 1000);

  }, [activeIndex]);

  useEffect(() => {
    if (activeIndex === null) {
      return;
    }
    play();
  }, [activeIndex]);

  useEffect(() => {
    const refCount = mediaRefs?.current?.length;
    if (refCount && media.length === refCount && refCount > 0) {
      setActiveIndex(0);
    }
  }, [mediaRefs?.current?.length]);

  console.log('media', media, mediaRefs?.current?.length, activeIndex);

  const safeUrl = useCallback((url) => {
    return url.replace('http://', 'https://');
  }, []);

  return (
    <div>
      {
        media.map((mediaItem, i) => (
          <motion.div
            initial={false}
            className={'Demo'}
            key={`${mediaItem?.key}`}
            animate={{
              opacity: activeIndex === i ? 1 : 0,
            }}
            transition={{
              duration: 1
            }}
          >
            {
              mediaItem?.resource_type === 'image' ? (
                <img
                  src={resizeAndCompress(safeUrl(mediaItem.url))}
                  ref={el => mediaRefs.current[i] = el}
                  className={`${isLandscape ? 'landscape' : ''}`}
                />
              ) : (
                (
                  <video
                    ref={el => mediaRefs.current[i] = el}
                    autoPlay={false}
                    muted={muted}
                    controls={false}
                    loop={media.length === 1}
                    className={`${isLandscape ? 'landscape' : ''}`}
                  >
                    <source
                      src={resizeAndCompress(safeUrl(mediaItem.url))}
                      type="video/mp4"
                    />
                  </video>
                )
              )
            }
          </motion.div>
        ))
      }
    </div>
  )
}