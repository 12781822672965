[
    {
        "mask": [],
        "circle": [],
        "name": "IntroEntry",
        "delayVertical": true,
        "verticalDelayDirection": -1,
        "vertical": [
            {
                "endPosition": 3.1,
                "startPosition": 0,
                "name": "PrimaryLeft",
                "delay": 1000,
                "drawToPosition": 0,
                "drawFromPosition": 100,
                "drawFull": true
            },
            {
                "delay": 1000,
                "startPosition": 0,
                "drawToPosition": 100,
                "drawFull": true,
                "name": "Left1",
                "endPosition": 26,
                "drawFromPosition": 0
            },
            {
                "drawToPosition": 100,
                "name": "Equator",
                "drawFromPosition": 0,
                "delay": 1000,
                "drawFull": true,
                "endPosition": 50,
                "startPosition": 0
            },
            {
                "delay": 1000,
                "drawToPosition": 100,
                "name": "Right1",
                "endPosition": 74,
                "startPosition": 0,
                "drawFull": true,
                "drawFromPosition": 0
            },
            {
                "delay": 1000,
                "endPosition": 96.9,
                "drawToPosition": 0,
                "drawFromPosition": 100,
                "drawFull": true,
                "startPosition": 0,
                "name": "PrimaryRight"
            }
        ],
        "drawBorder": true,
        "id": 0,
        "horizontal": [
            {
                "startPosition": 5.6,
                "name": "PrimaryTop",
                "drawFromPosition": 0,
                "drawFull": false,
                "drawToPosition": 100
            },
            {
                "delay": 100,
                "name": "Equator",
                "drawToPosition": 100,
                "drawFromPosition": 0,
                "startPosition": 50,
                "drawFull": false
            },
            {
                "drawFromPosition": 0,
                "drawFull": false,
                "drawToPosition": 100,
                "startPosition": 94.4,
                "delay": 200,
                "name": "PrimaryBottom"
            }
        ],
        "duration": "1000",
        "delayHorizontal": true
    }
]