import { fetchJSON } from '/src/touchpoints/rise/hyperlive/utils';

import '../../styles/universal-live-template.scss';
import createApp from './app'

import presentation from './presentation';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const demo = urlParams.get('demo_mode');

const { json_data } = !!demo ? presentation.animation : fetchJSON();
console.log(json_data);

// const canvas = document.createElement('canvas');
// canvas.classList.add('LLS-canvas');
// canvas.classList.add('vertical');
// document.body.appendChild(canvas);

createApp(json_data, !!demo);
