#pragma glslify: box = require('../../../../lib/glsl/box.glsl')
#pragma glslify: mosaic = require('../../../../lib/glsl/mosaic.glsl')
#pragma glslify: viewfinder = require('../../../../lib/glsl/viewfinder.glsl')
#pragma glslify: grid = require('../../../../lib/glsl/grid.glsl')

#pragma glslify: coverUV = require('../../../../lib/glsl/coverUV.glsl')
#pragma glslify: pixelateUV = require('../../../../lib/glsl/pixelateUV.glsl')
#pragma glslify: placedUV = require('../../../../lib/glsl/placedUV.glsl')
#pragma glslify: zoomedUV = require('../../../../lib/glsl/zoomedUV.glsl')
#pragma glslify: gammaCorrect = require('../../../../lib/glsl/gammaCorrect.glsl')

#ifdef GL_ES
precision mediump float;
#endif

varying vec2 vUv;

// general stuffs
uniform vec2 res; // res
uniform vec2 screenRatio; // screen ratio
uniform float pixa; // pixelization amount (size of each cell/pixel)
uniform float pixr; // pixel ratio
uniform float stroke; // stroke width
uniform float gridAlpha;

// palette
uniform float paletteSize;
uniform float paletteOpacity;

// IMG 1
uniform sampler2D img1;
uniform float img1Opacity;
uniform vec2 img1Res;
uniform vec2 img1Pos;
uniform vec2 img1Dim;
uniform vec2 img1Off;
uniform float img1Pix;
uniform float img1Zoom;
uniform sampler2D img1TxtTex; // sampler for the texture
uniform vec2 img1TxtRes; // size of the image (in pixels)
uniform vec3 img1color1;
uniform vec3 img1color2;
uniform vec3 img1color3;
uniform vec3 img1color4;

// IMG 2
uniform sampler2D img2;
uniform float img2Opacity;
uniform vec2 img2Res;
uniform vec2 img2Pos;
uniform vec2 img2Dim;
uniform vec2 img2Off;
uniform float img2Pix;
uniform float img2Zoom;
uniform sampler2D img2TxtTex; // sampler for the texture
uniform vec2 img2TxtRes; // size of the image (in pixels)
uniform vec3 img2color1;
uniform vec3 img2color2;
uniform vec3 img2color3;
uniform vec3 img2color4;

// IMG 3
uniform sampler2D img3;
uniform float img3Opacity;
uniform vec2 img3Res;
uniform vec2 img3Pos;
uniform vec2 img3Dim;
uniform vec2 img3Off;
uniform float img3Pix;
uniform float img3Zoom;
uniform sampler2D img3TxtTex; // sampler for the texture
uniform vec2 img3TxtRes; // size of the image (in pixels)
uniform vec3 img3color1;
uniform vec3 img3color2;
uniform vec3 img3color3;
uniform vec3 img3color4;

// IMG 4
uniform sampler2D img4;
uniform float img4Opacity;
uniform vec2 img4Res;
uniform vec2 img4Pos;
uniform vec2 img4Dim;
uniform vec2 img4Off;
uniform float img4Pix;
uniform float img4Zoom;
uniform sampler2D img4TxtTex; // sampler for the texture
uniform vec2 img4TxtRes; // size of the image (in pixels)
uniform vec3 img4color1;
uniform vec3 img4color2;
uniform vec3 img4color3;
uniform vec3 img4color4;

// IMG 5
uniform sampler2D img5;
uniform float img5Opacity;
uniform vec2 img5Res;
uniform vec2 img5Pos;
uniform vec2 img5Dim;
uniform vec2 img5Off;
uniform float img5Pix;
uniform float img5Zoom;
uniform sampler2D img5TxtTex; // sampler for the texture
uniform vec2 img5TxtRes; // size of the image (in pixels)
uniform vec3 img5color1;
uniform vec3 img5color2;
uniform vec3 img5color3;
uniform vec3 img5color4;

vec3 paletteBox (vec3 color, vec3 c2, vec2 pos, vec2 size, float opacity, float thickness) {
  vec3 c1 = color;

  float bb6 = box(pos, size * (1. + thickness));
  c1 = mix(c1, vec3(0.), bb6);

  float b6 = box(pos, size);
  c1 = mix(c1, c2, b6);

  return mix(color, c1, opacity);
}

vec3 griddedImage (vec3 color, sampler2D img, vec2 imgRes, vec2 pos, vec2 dim, vec2 offset, float zoom, float pix, sampler2D txt, vec2 txtRes, float opacity, vec2 st) {
  vec3 transparency = color;
  float ar = (imgRes.x / imgRes.y);

  vec2 uv = placedUV((pos / pixr), imgRes, dim, pixr);

  zoom *= max((screenRatio.x * screenRatio.y), (screenRatio.x / screenRatio.y));

  offset /= max((screenRatio.x * screenRatio.y), (screenRatio.x / screenRatio.y));

  uv = zoomedUV(uv, imgRes, zoom, offset);
  uv -= offset;
  float b = box(gl_FragCoord.xy - pos, dim);
  uv = pixelateUV(uv, pix, ar);
  color = mix(color, texture2D(img, uv).rgb, b);

  // text overlay
  vec2 txtPos = vec2(pos + (dim * 0.5));
  txtPos.x += (8. * screenRatio.x);
  vec2 txtUV = placedUV((txtPos / pixr), (txtRes * pixr), (dim * pixr), pixr);
  color = min(color, texture2D(txt, txtUV).rgb);

  // float s = (20. / res.x) / (dim.x / res.x);
  // vec2 size = vec2(s, (s * ar));
  // vec2 palPos = vec2(pos + 50.);
  // vec2 palUV = placedUV((palPos / pixr), dim, dim, pixr);
  // color = paletteBox(color, img1color1, palUV, size, 1.0 - opacity);

  color = mix(transparency, color, opacity);

  return color;
}

vec3 imgPalette (vec3 color, vec3 swatch, float index, vec2 res, vec2 pos, vec2 dim, float baseSize, float op, float thickness) {
  float ar = (dim.x / dim.y);

  baseSize += thickness;
  baseSize *= pixr;
  float s = (baseSize / res.x) / (dim.x / res.x);
  vec2 size = vec2(s, (s * ar));

  pos.x += (baseSize * 1.5);
  pos.y += (dim.y - ((index * baseSize) + (baseSize * 0.5)));

  vec2 palUV = placedUV((pos / pixr), dim, dim, pixr);
  color = paletteBox(color, swatch, palUV, size, op, thickness);

  return color;
}


void main() {
  vec3 color = vec3(1.);

  vec2 st = (gl_FragCoord.xy / res) / pixr;
  float ar = (res.x / res.y);
  vec2 var = vec2(1., ar);

  float thickness = (stroke * pixr);
  float thickdiff = (stroke / 100.);
  float pThick = (thickness * 0.1);
  pThick /= max((screenRatio.x * screenRatio.y), (screenRatio.x / screenRatio.y));

  color = griddedImage(color, img1, (img1Res * pixr), (img1Pos * pixr), (img1Dim * pixr), img1Off, img1Zoom, ((res.x * pixr) / img1Pix), img1TxtTex, img1TxtRes, img1Opacity, st);
  color = imgPalette(color, img1color1, 1.0, (img1Res * pixr), (img1Pos * pixr), (img1Dim * pixr), paletteSize, paletteOpacity, pThick);
  color = imgPalette(color, img1color2, 2.0, (img1Res * pixr), (img1Pos * pixr), (img1Dim * pixr), paletteSize, paletteOpacity, pThick);
  color = imgPalette(color, img1color3, 3.0, (img1Res * pixr), (img1Pos * pixr), (img1Dim * pixr), paletteSize, paletteOpacity, pThick);
  color = imgPalette(color, img1color4, 4.0, (img1Res * pixr), (img1Pos * pixr), (img1Dim * pixr), paletteSize, paletteOpacity, pThick);

  color = griddedImage(color, img2, (img2Res * pixr), (img2Pos * pixr), (img2Dim * pixr), img2Off, img2Zoom, ((res.x * pixr) / img2Pix), img2TxtTex, img2TxtRes, img2Opacity, st);
  color = imgPalette(color, img2color1, 1.0, (img2Res * pixr), (img2Pos * pixr), (img2Dim * pixr), paletteSize, paletteOpacity, pThick);
  color = imgPalette(color, img2color2, 2.0, (img2Res * pixr), (img2Pos * pixr), (img2Dim * pixr), paletteSize, paletteOpacity, pThick);
  color = imgPalette(color, img2color3, 3.0, (img2Res * pixr), (img2Pos * pixr), (img2Dim * pixr), paletteSize, paletteOpacity, pThick);
  color = imgPalette(color, img2color4, 4.0, (img2Res * pixr), (img2Pos * pixr), (img2Dim * pixr), paletteSize, paletteOpacity, pThick);

  color = griddedImage(color, img3, (img3Res * pixr), (img3Pos * pixr), (img3Dim * pixr), img3Off, img3Zoom, ((res.x * pixr) / img3Pix), img3TxtTex, img3TxtRes, img3Opacity, st);
  color = imgPalette(color, img3color1, 1.0, (img3Res * pixr), (img3Pos * pixr), (img3Dim * pixr), paletteSize, paletteOpacity, pThick);
  color = imgPalette(color, img3color2, 2.0, (img3Res * pixr), (img3Pos * pixr), (img3Dim * pixr), paletteSize, paletteOpacity, pThick);
  color = imgPalette(color, img3color3, 3.0, (img3Res * pixr), (img3Pos * pixr), (img3Dim * pixr), paletteSize, paletteOpacity, pThick);
  color = imgPalette(color, img3color4, 4.0, (img3Res * pixr), (img3Pos * pixr), (img3Dim * pixr), paletteSize, paletteOpacity, pThick);

  color = griddedImage(color, img4, (img4Res * pixr), (img4Pos * pixr), (img4Dim * pixr), img4Off, img4Zoom, ((res.x * pixr) / img4Pix), img4TxtTex, img4TxtRes, img4Opacity, st);
  color = imgPalette(color, img4color1, 1.0, (img4Res * pixr), (img4Pos * pixr), (img4Dim * pixr), paletteSize, paletteOpacity, pThick);
  color = imgPalette(color, img4color2, 2.0, (img4Res * pixr), (img4Pos * pixr), (img4Dim * pixr), paletteSize, paletteOpacity, pThick);
  color = imgPalette(color, img4color3, 3.0, (img4Res * pixr), (img4Pos * pixr), (img4Dim * pixr), paletteSize, paletteOpacity, pThick);
  color = imgPalette(color, img4color4, 4.0, (img4Res * pixr), (img4Pos * pixr), (img4Dim * pixr), paletteSize, paletteOpacity, pThick);

  color = griddedImage(color, img5, (img5Res * pixr), (img5Pos * pixr), (img5Dim * pixr), img5Off, img5Zoom, ((res.x * pixr) / img5Pix), img5TxtTex, img5TxtRes, img5Opacity, st);
  color = imgPalette(color, img5color1, 1.0, (img5Res * pixr), (img5Pos * pixr), (img5Dim * pixr), paletteSize, paletteOpacity, pThick);
  color = imgPalette(color, img5color2, 2.0, (img5Res * pixr), (img5Pos * pixr), (img5Dim * pixr), paletteSize, paletteOpacity, pThick);
  color = imgPalette(color, img5color3, 3.0, (img5Res * pixr), (img5Pos * pixr), (img5Dim * pixr), paletteSize, paletteOpacity, pThick);
  color = imgPalette(color, img5color4, 4.0, (img5Res * pixr), (img5Pos * pixr), (img5Dim * pixr), paletteSize, paletteOpacity, pThick);

  gl_FragColor = vec4(gammaCorrect(color.rgb, 2.2), 1);
}
