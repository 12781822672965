export default [
    {
        "name": "ONE",
        "drawBorder": false,
        "delayHorizontal": true,
        "mask": [],
        "horizontal": [
            {
                "drawFromPosition": 0,
                "drawFull": false,
                "name": "Horizontals",
                "drawToPosition": 100,
                "startPosition": 12.5,
                "segmentGroup": {
                    "numLines": "3",
                    "segmentPositionEnd": 87.5
                }
            }
        ],
        "insetBorder": false,
        "circle": [],
        "duration": 1000,
        "vertical": []
    },
    {
        "mask": [],
        "duration": 1000,
        "circle": [],
        "vertical": [],
        "horizontal": [
            {
                "drawToPosition": 100,
                "drawFromPosition": 0,
                "drawFull": true,
                "segmentGroup": {
                    "segmentPositionEnd": 87.5,
                    "numLines": "3"
                },
                "name": "Horizontals",
                "startPosition": 12.5
            }
        ],
        "drawBorder": false,
        "delayHorizontal": true,
        "insetBorder": false
    }
]