import React, { useEffect } from 'react';
import { HubGrid } from '../components';
import { useHubGrid } from '../hooks/useHubGrid';
import directory from '../mocks/directory.json';

export const DirectoryHub = ({ data }) => {
	const hubGridProps = useHubGrid({
		rawData: data?.json_data,
		fallback: directory,
		template: 'directory',
	});

	return <HubGrid {...hubGridProps} titleRatio={0.48} />;
};
