import './centeredLogo.css';

export default function CenteredLogo (content) {
    this.element = document.createElement('div');
    this.element.classList.add('centeredLogo');

    const setSrc = (src) => {
        this.element.style.backgroundImage = `url(${content.src})`;
    };
    
    if(content.src) {
        setSrc(content.src);
    }
    
    return this.element;
}
    
