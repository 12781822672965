import React from 'react';

export const Services = ({ data }) => {

    return (
        <div className={'Services'}>
            CITY REPLAY — SERVICES
        </div>
    );
};
