import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import Codification from '../../../../components/Codification';
import './PodDiD.scss';
import { NIKE_EASE } from '../../../../constants/nikerise';
import { KeyframeManager, KEYFRAME_STATUS } from '../../../../risegrid/utils';

const cornerImage = require('../../assets/did-corner.svg');
const VisibleKeyframes = ['MainB', 'MainC'];
const Keyframe = new KeyframeManager(VisibleKeyframes);

const PodDiD = ({ text, grids, frame }) => {

    //TODO use keyframe status
    const keyframe_status = Keyframe.status(frame);

    const [dims, setDims] = useState({ width: 1080, height: 960, left: 0, top: 0 })
    const [start, setStart] = useState(false)

    const { index: frameIndex, name: frameName } = frame;

    useEffect(() => {
        // grids.masks[0].addCallback('TopPrimary', (params) => {
        //     setDims({
        //         top: params.y,
        //         left: params.x,
        //         width: params.width,
        //         height: params.height,
        //     })
        // })
    }, [])

    useEffect(() => {
        if (frameName == VisibleKeyframes[0]) {

            setTimeout(() => {
                setStart(true)
            }, 1)
        }
    }, [frame]);

    const show = frameName == VisibleKeyframes[0] //keyframe_status ==  KEYFRAME_STATUS.BEGIN

    return text ? (

        <div className="PodDiD" style={{ ...dims, overflow: 'hidden', position: 'absolute' }}>

            {show &&

                <motion.div
                    initial={{
                        translateX: '10%',
                        translateY: '-100%',
                    }}
                    animate={{
                        translateY: frameName == VisibleKeyframes[0] ? '155%' : '-120%',
                    }}
                    transition={{
                        ease: NIKE_EASE,
                        duration: 1,
                        delay: frameName == VisibleKeyframes[0] ? 0.5 : 0
                    }}
                >
                    <div className="PodDiD-container">
                        <div className="PodDiD-corner">
                            <img src={cornerImage} />
                        </div>
                        <div className="PodDiD-text">
                            {/* <div className="PodDiD-top">

                                <Codification
                                    text={'NTC'}
                                    typography={{
                                        primary: false,
                                        typeStyle: { fontSize: '25px', lineHeight: '25px' },
                                        tag: 'span',
                                    }}
                                    characterSwitchAmount={6}
                                    characterNextTrigger={2}
                                    characterTimeout={30}
                                    start={start}

                                />
                            </div> */}
                            <div className="PodDiD-bottom">
                                <Codification
                                    text={text}
                                    typography={{
                                        primary: true,
                                        typeStyle: { fontSize: '44px', lineHeight: '44px' },
                                        tag: 'span',
                                    }}
                                    characterSwitchAmount={6}
                                    characterNextTrigger={2}
                                    characterTimeout={0.03}
                                    start={start}
                                    reverse={frameName === VisibleKeyframes[2]}

                                />
                            </div>
                        </div>
                    </div>
                </motion.div>

            }



        </div>


    ) : <div />;
};

export default PodDiD;
