import ReactDOM from 'react-dom/client';
import App from './app';
import {mergeChapters, fetchChannelInfo, fetchJSON} from '/src/touchpoints/rise/hyperlive/utils';
import {channelConfig} from "../../../jordan/shared";

const {json_data} = mergeChapters();

const raw = fetchJSON();
const globalChannelSlug = raw?.json_data?.global?.global_channel_slug;

const params = new URLSearchParams(window.location.search)
const chapter = params.get('chapter');
const openChapter = ["", null, undefined].includes(chapter) ? null : chapter;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App
    config={channelConfig({forceAssetHeight: true})}
    openChapter={openChapter}
    data={json_data}
    globalChannelSlug={globalChannelSlug}
  />
);