import React, { useEffect, useState } from 'react';
import Codification from '../../../../components/Codification';
import './Description.scss';

const Description = ({ text, start = false, test, size = 'primary', theme = 'light' }) => {

	const typeStyle = size === 'primary' ? { fontSize: '6.97vh', lineHeight: '6.4vh' } : { fontSize: '5vh', lineHeight: '4.68vh' }

	return (<div className={`Description theme-${theme}`} style={{ width: '100%' }}>
		<Codification
			text={`${text ? text : ''}`}
			typography={{
				primary: true,
				typeStyle: typeStyle,
				tag: 'span',
			}}
			characterSwitchAmount={6}
			characterNextTrigger={2}
			characterTimeout={30}
			start={start}
		// isStatic={!start}
		// reverse={frameName === VisibleKeyframes[2]}
		/>
	</div>
	);
};

export default Description;
