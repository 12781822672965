import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import classNames from 'classnames';
import {
    AnimatePresence,
    motion,
    usePresence,
} from 'framer-motion';

import * as cssClassNames from '../../styles/screens/product/IntroV2.module.scss';

import {
    DEFAULT_EASE,
    getLangText,
    getMediaDuration,
    MediaType,
    MultiLangText,
    secToMs,
    setAnimationTimeout,
} from '../../shared';
import { DEFAULT_DURATION_S } from '../../constants/transition';
import {
    BaseChapterProps,
} from '../../components/ChapterRenderer';
import
    CodificationWithTranslations,
    { calculateDuration as calculateCodificationDuration }
from '../../components/Codification/CodificationWithTranslations';
import Grid from '../../components/Grid';
import MediaRenderer from '../../components/MediaRenderer';


export type IntroV2Props = BaseChapterProps & {
    text: MultiLangText,
    primaryMedia: MediaType,
    secondaryMedia: MediaType,
};


const bigCellLength = 80;

const codificationCharDuration = 0.03;
const characterSwitchAmount = 4;
const characterNextTrigger = 2;
const languageTransitionDelay = 0.25;
const primaryMediaEntryDuration = DEFAULT_DURATION_S;
const secondaryMediaEntryDuration = primaryMediaEntryDuration / 2;
const secondaryMediaDelay = 0.3;
const pause = 4;

const driftSpeed = 13; // px/s
const driftDuration = 16; // s
const driftDistance = driftSpeed * driftDuration;

export default function IntroV2(props: IntroV2Props) {
    const {
        text,
        languages,
        width,
        height,
        primaryMedia,
        secondaryMedia,
    } = props;

    const rows = Math.ceil(height / bigCellLength);

    const [langIndex, setLangIndex] = useState(0);
    const [nextLangIndex, setNextLangIndex] = useState(0);
    const [toShowMedia, setToShowMedia] = useState(false);
    const [toShowSecondaryMedia, setToShowSecondaryMedia] = useState(false);
    const [isPresent, safeToRemove] = usePresence();

    useEffect(() => {
        if (nextLangIndex === langIndex) {
            return;
        }

        const primaryDuration = primaryMedia ? getMediaDuration(primaryMedia) : 0;
        const secondaryDuration = secondaryMedia ? getMediaDuration(secondaryMedia) : 0;
        const duration = Math.max(primaryDuration, secondaryDuration) || pause;

        return setAnimationTimeout(() => {
            setLangIndex(nextLangIndex);
        }, secToMs(duration));
    }, [nextLangIndex]);

    useEffect(() => {
        if (!toShowMedia) {
            return;
        }

        return setAnimationTimeout(() => {
            setToShowSecondaryMedia(true);
        }, secToMs(secondaryMediaDelay));
    }, [toShowMedia]);

    const textComponent = useMemo(() => (
        <CodificationWithTranslations
            text={text}
            languages={languages}
            langIndex={langIndex}
            codificationProps={{
                characterSwitchAmount,
                characterNextTrigger,
                timingConfig: {
                    control: 'character',
                    duration: codificationCharDuration,
                },
            }}
            runFinalTextAnimation
            languageTransitionDelay={languageTransitionDelay}
            onTyped={() => {
                setToShowMedia(true);
                setNextLangIndex(i => {
                    return (i >= languages.length - 1) ? i : (i + 1);
                });
            }}
        />
    ), [text, langIndex]);

    return (
        <div className={cssClassNames.product_intro_v2}>
            {primaryMedia && toShowMedia ? (
                <div
                    className={cssClassNames.primary_media_container}
                >
                    <motion.div
                        style={{
                            height: `calc(100% + ${driftDistance}px)`,
                        }}
                        initial={{
                            transform: 'translateY(100%)'
                        }}
                        animate={{
                            transform: 'translateY(0%)'
                        }}
                        exit={{
                            transform: 'translateY(-100%)'
                        }}
                        transition={{
                            duration: primaryMediaEntryDuration,
                            ease: DEFAULT_EASE
                        }}
                    >
                        <motion.div
                            style={{height: '100%'}}
                            animate={{
                                y: ['0px', `-${driftDistance}px`],
                                transition: {
                                    duration: driftDuration,
                                    ease: "linear",
                                    repeatType: 'mirror',
                                    repeat: Infinity,
                                }
                            }}
                            exit={{
                                y: `-${driftDistance}px'`
                            }}
                            transition={{
                                duration: primaryMediaEntryDuration,
                                ease: DEFAULT_EASE
                            }}
                        >
                            <MediaRenderer
                                media={primaryMedia}
                            />
                        </motion.div>
                    </motion.div>
                </div>
            ) : null}
            <div className={classNames(cssClassNames.grid_container)}>
                <Grid
                    className={cssClassNames.grid}
                    rows={rows}
                    cols={5}
                    height={rows * bigCellLength}
                    width={5 * bigCellLength}
                    toDrawBoundaries
                    initialAnimation='visible'
                />
            </div>
            {secondaryMedia && toShowSecondaryMedia ? (
                <motion.div
                    className={cssClassNames.secondary_media_container}
                    initial={{
                        clipPath: 'inset(0% 0% 100% 0%)'
                    }}
                    animate={{
                        clipPath: 'inset(0% 0% 0% 0%)'
                    }}
                    exit={{
                        clipPath: 'inset(100% 0% 0% 0%)'
                    }}
                    transition={{
                        duration: secondaryMediaEntryDuration,
                        ease: DEFAULT_EASE
                    }}
                >
                    <MediaRenderer
                        media={secondaryMedia}
                    />
                </motion.div>
            ) : null}
            <div className={cssClassNames.text_container}>
                <div>
                    <AnimatePresence onExitComplete={() => !isPresent && safeToRemove()}>
                        {isPresent ? textComponent : null}
                    </AnimatePresence>
                </div>
            </div>
        </div>
    );
}

export function calculateDuration(data: IntroV2Props) {
    const {
        text,
        languages,
        primaryMedia,
        secondaryMedia,
    } = data;
    const entryDuration = 0;

    const primaryMediaDuration = primaryMedia ? getMediaDuration(primaryMedia) : 0;
    const secondaryMediaDuration = secondaryMedia ? getMediaDuration(secondaryMedia) : 0;
    const mediaDuration = Math.max(primaryMediaDuration, secondaryMediaDuration) || pause;

    const textDuration = calculateCodificationDuration({
        text,
        languages,
        codificationCharDuration,
        characterSwitchAmount,
        characterNextTrigger,
        languageTransitionDelay,
    });

    const mainDuration = textDuration +  mediaDuration * languages.length;

    const total = entryDuration + mainDuration;

    const duration = secToMs(total) + calculateExitDuration(data);

    return duration;
}

export function calculateExitDuration(data: IntroV2Props) {
    const {
        languages,
    } = data;

    const maxText = languages.reduce((result, lang) => {
        const text = getLangText(data.text, lang);
        return text ? Math.min(result, text.length) : result;
    }, Infinity);
    const langExitDuration = maxText * codificationCharDuration * characterNextTrigger;

    const duration = Math.max(langExitDuration, primaryMediaEntryDuration);

    return secToMs(duration);
}
