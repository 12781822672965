{
  "glyphs": {
    "lesserThan": "<",
		"hashtag": "#",
		"ampersand": "&",
		"openRoundParentheses": "(",
		"exclamation": "!",
		"closeRoundParentheses": ")",
		"degrees": "°",
		"hyphen": "-",
		"underscore": "_",
		"circumflex": "^",
		"multiply": "*",
		"percent": "%",
		"colon": ":",
		"forwardSlash": "/",
		"semiColon": ";",
		"dot": ".",
		"comma": ",",
		"questionMark": "?",
		"plus": "+",
		"equal": "=",
		"largerThan": ">",
		"0": "공",
		"1": "일",
		"2": "이",
		"3": "삼",
		"4": "사",
		"5": "오",
		"6": "육",
		"7": "칠",
		"8": "팔",
		"9": "구"
  }
}
