import { motion } from "framer-motion";
import { DEFAULT_TRANSITION } from "../../../../rise/constants/transition";
import {MEMBERSHIP_COLOR, useRpx} from "../shared";

export default function Line({margin}: {margin?: string}) {
    const rpx = useRpx();

    return (
        <motion.div
            layout
            initial={false}
            transition={{
                ...DEFAULT_TRANSITION,
                duration: .5,
            }}
            style={{
                backgroundColor: MEMBERSHIP_COLOR,
                height: rpx(2),
                width: '100%',
                margin: margin || `${rpx(20)} 0`
            }}
        />
    )
}