$color: #dadada;

.GridWithCell {
    & > * {
        position: absolute;
        top: 0;
    }

    :global(.grid) {
        line {
            stroke-dasharray: 2 2;
            stroke: $color;
        }
    }
}

.cell {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.dot {
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background: $color;
}
