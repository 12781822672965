import React from 'react';
import {fetchJSON} from '/src/touchpoints/rise/hyperlive/utils';
import {fetchDrops, SNKRS_CAL_CONFIG, splitByTimeframe} from "../../../hoi/animations/snkrs-calendar/js/dataService";
import ReactDOM from "react-dom/client";
import {useEffect, useState} from "react";
import createApp from './app'

function App({}) {
  const [drops, setDrops] = useState([]);
  const [inStockMode, setInStockMode] = useState(undefined);
  const {json_data = {}} = fetchJSON();
  const config = SNKRS_CAL_CONFIG[json_data?.marketplace ?? 'US'];

  useEffect(() => {
    fetchDrops({
      // inStock: "true",
      includeInactive: true,
      onlyFootwear: true,
      ...config,
      onComplete: ({drops, inStockMode}) => {
        const {today, upcoming, past} = splitByTimeframe(drops);

        console.log({
          today, upcoming, past,
        });

        const joinedDrops = [...today, ...upcoming, ...past];
        console.log('drops', joinedDrops);
        setDrops(joinedDrops);
        setInStockMode(inStockMode);

        createApp(joinedDrops, json_data);
      }
    });
  }, []);

  return (
    <div/>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);