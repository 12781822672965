import React, { useMemo, useEffect, memo, useRef, Suspense, useState, forwardRef, useLayoutEffect } from 'react';
import { useThree, addEffect, addAfterEffect, addTail, useFrame, Canvas } from '@react-three/fiber';
import * as THREE from 'three';
import { MathUtils } from 'three';
import create from 'zustand';
import { styled } from '@stitches/react';
import { PauseIcon, TriangleDownIcon, TriangleUpIcon, LayersIcon, ImageIcon, RocketIcon, EyeOpenIcon, EyeNoneIcon, ButtonIcon, CubeIcon, VercelLogoIcon, ActivityLogIcon, LightningBoltIcon, RulerHorizontalIcon, LapTimerIcon, TextAlignJustifyIcon, BarChartIcon, MarginIcon, MinusIcon, DotIcon, DropdownMenuIcon } from '@radix-ui/react-icons';
import { Text } from '@react-three/drei';
import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { createRoot } from 'react-dom/client';
import { estimateBytesUsed } from 'three-stdlib';

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

class GLPerf {
  constructor(settings = {}) {
    this.names = [''];
    this.finished = [];
    this.gl = void 0;
    this.extension = void 0;
    this.query = void 0;
    this.paused = false;
    this.queryHasResult = false;
    this.queryCreated = false;
    this.isWebGL2 = true;
    this.memAccums = [];
    this.gpuAccums = [];
    this.activeAccums = [];
    this.chart = [];
    this.gpuChart = [];
    this.memChart = [];

    this.paramLogger = () => {};

    this.glFinish = () => {};

    this.chartLogger = () => {};

    this.chartLen = 60;
    this.maxMemory = 1500;
    this.chartHz = 10;
    this.chartFrame = 0;
    this.gpuTimeProcess = 0;
    this.chartTime = 0;
    this.activeQueries = 0;
    this.circularId = 0;
    this.detected = 0;
    this.frameId = 0;
    this.rafId = 0;
    this.checkQueryId = 0;
    this.uuid = undefined;
    this.currentMem = 0;
    this.paramFrame = 0;
    this.paramTime = 0;

    this.now = () => {};

    this.t0 = 0;
    window.GLPerf = window.GLPerf || {};
    Object.assign(this, settings);
    this.chart = new Array(this.chartLen).fill(0);
    this.gpuChart = new Array(this.chartLen).fill(0);
    this.memChart = new Array(this.chartLen).fill(0);

    this.now = () => window.performance && window.performance.now ? window.performance.now() : Date.now();

    this.initGpu();
    this.is120hz();
  }

  initGpu() {
    this.uuid = MathUtils.generateUUID();

    if (this.gl) {
      this.isWebGL2 = true;

      if (!this.extension) {
        this.extension = this.gl.getExtension('EXT_disjoint_timer_query_webgl2');
      }

      if (this.extension === null) {
        this.isWebGL2 = false;
      }
    }
  }

  is120hz() {
    let n = 0;

    const loop = t => {
      if (++n < 20) {
        this.rafId = window.requestAnimationFrame(loop);
      } else {
        this.detected = Math.ceil(1e3 * n / (t - this.t0) / 70);
        window.cancelAnimationFrame(this.rafId);
      }

      if (!this.t0) this.t0 = t;
    };

    this.rafId = window.requestAnimationFrame(loop);
  }

  addUI(name) {
    if (this.names.indexOf(name) === -1) {
      this.names.push(name);
      this.gpuAccums.push(0);
      this.activeAccums.push(false);
    }
  }

  nextFrame(now) {
    this.frameId++;
    const t = now || this.now();
    const duration = t - this.paramTime;
    let gpu = 0;

    if (this.frameId <= 1) {
      this.paramFrame = this.frameId;
      this.paramTime = t;
    } else {
      if (t >= this.paramTime + 200) {
        this.maxMemory = window.performance.memory ? window.performance.memory.jsHeapSizeLimit / 1048576 : 0;
        const frameCount = this.frameId - this.paramFrame;
        const fps = frameCount * 1000 / duration;

        for (let i = 0; i < this.names.length; i++) {
          gpu = this.isWebGL2 ? this.gpuAccums[i] : this.gpuAccums[i] / duration;
          this.currentMem = Math.round(window.performance && window.performance.memory ? window.performance.memory.usedJSHeapSize / 1048576 : 0);
          this.paramLogger({
            gpu,
            i,
            mem: this.currentMem,
            maxMemory: this.maxMemory,
            fps: fps,
            duration: Math.round(duration),
            frameCount
          });

          if (this.isWebGL2) {
            this.gpuAccums[i] = 0;
          } else {
            Promise.all(this.finished).then(() => {
              this.gpuAccums[i] = 0;
              this.finished = [];
            });
          }
        }

        this.paramFrame = this.frameId;
        this.paramTime = t;
      }
    }

    if (!this.detected || !this.chartFrame) {
      this.chartFrame = this.frameId;
      this.chartTime = t;
      this.circularId = 0;
    } else {
      const timespan = t - this.chartTime;
      let hz = this.chartHz * timespan / 1e3;

      while (--hz > 0 && this.detected) {
        const frameCount = this.frameId - this.chartFrame;
        const fps = frameCount / timespan * 1e3;
        this.chart[this.circularId % this.chartLen] = fps;
        const memS = 1000 / this.currentMem;
        const gpuS = (this.isWebGL2 ? this.gpuAccums[1] * 2 : Math.round(this.gpuAccums[1] / duration * 100)) + 4;

        if (gpuS > 0) {
          this.gpuChart[this.circularId % this.chartLen] = gpuS;
        }

        if (memS > 0) {
          this.memChart[this.circularId % this.chartLen] = memS;
        }

        for (let i = 0; i < this.names.length; i++) {
          this.chartLogger({
            i,
            data: {
              fps: this.chart,
              gpu: this.gpuChart,
              mem: this.memChart
            },
            circularId: this.circularId
          });
        }

        this.circularId++;
        this.chartFrame = this.frameId;
        this.chartTime = t;
      }
    }
  }

  startGpu() {
    const gl = this.gl;
    const ext = this.extension;
    if (!gl || !ext) return;

    if (this.isWebGL2) {
      let available = false;
      let disjoint, ns;

      if (this.query) {
        this.queryHasResult = false;
        let query = this.query;
        available = gl.getQueryParameter(query, gl.QUERY_RESULT_AVAILABLE);
        disjoint = gl.getParameter(ext.GPU_DISJOINT_EXT);

        if (available && !disjoint) {
          ns = gl.getQueryParameter(this.query, gl.QUERY_RESULT);
          const ms = ns * 1e-6;

          if (available && ms > 0) {
            if (!disjoint) {
              this.activeAccums.forEach((_active, i) => {
                this.gpuAccums[i] = ms;
              });
            }
          }
        }
      }

      if (available || !this.query) {
        this.queryCreated = true;
        this.query = gl.createQuery();
        gl.beginQuery(ext.TIME_ELAPSED_EXT, this.query);
      }
    }
  }

  endGpu() {
    const ext = this.extension;
    const gl = this.gl;

    if (this.isWebGL2 && this.queryCreated && gl.getQuery(ext.TIME_ELAPSED_EXT, gl.CURRENT_QUERY)) {
      gl.endQuery(ext.TIME_ELAPSED_EXT);
    }
  }

  begin(name) {
    this.startGpu();
    this.updateAccums(name);
  }

  end(name) {
    this.endGpu();
    this.updateAccums(name);
  }

  updateAccums(name) {
    let nameId = this.names.indexOf(name);

    if (nameId === -1) {
      nameId = this.names.length;
      this.addUI(name);
    }

    const t = this.now();
    this.activeAccums[nameId] = !this.activeAccums[nameId];
    this.t0 = t;
  }

}

const countGeoDrawCalls = programs => {
  programs.forEach((program, _pkey) => {
    const {
      meshes
    } = program;

    if (!meshes) {
      return;
    }

    let drawCounts = {
      total: 0,
      type: 'Triangle',
      data: []
    };
    Object.keys(meshes).forEach(key => {
      const mesh = meshes[key];
      const {
        geometry,
        material
      } = mesh;
      let index = geometry.index;
      const position = geometry.attributes.position;
      if (!position) return;
      let rangeFactor = 1;

      if (material.wireframe === true) {
        rangeFactor = 0;
      }

      const dataCount = index !== null ? index.count : position.count;
      const rangeStart = geometry.drawRange.start * rangeFactor;
      const rangeCount = geometry.drawRange.count * rangeFactor;
      const drawStart = rangeStart;
      const drawEnd = Math.min(dataCount, rangeStart + rangeCount) - 1;
      let countInstanceRatio = 1;
      const instanceCount = mesh.count || 1;
      let type = 'Triangle';
      let mostDrawCalls = 0;

      if (mesh.isMesh) {
        if (material.wireframe === true) {
          type = 'Line';
          countInstanceRatio = countInstanceRatio / 2;
        } else {
          type = 'Triangle';
          countInstanceRatio = countInstanceRatio / 3;
        }
      } else if (mesh.isLine) {
        type = 'Line';

        if (mesh.isLineSegments) {
          countInstanceRatio = countInstanceRatio / 2;
        } else if (mesh.isLineLoop) {
          countInstanceRatio = countInstanceRatio;
        } else {
          countInstanceRatio = countInstanceRatio - 1;
        }
      } else if (mesh.isPoints) {
        type = 'Point';
        countInstanceRatio = countInstanceRatio;
      } else if (mesh.isSprite) {
        type = 'Triangle';
        countInstanceRatio = countInstanceRatio / 3;
      }

      const drawCount = Math.round(Math.max(0, drawEnd - drawStart + 1) * (countInstanceRatio * instanceCount));

      if (drawCount > mostDrawCalls) {
        mostDrawCalls = drawCount;
        drawCounts.type = type;
      }

      drawCounts.total += drawCount;
      drawCounts.data.push({
        drawCount,
        type
      });
      mesh.userData.drawCount = {
        type,
        count: drawCount
      };
    });
    program.drawCounts = drawCounts;
  });
};

const updateMatrixWorldTemp = THREE.Object3D.prototype.updateMatrixWorld;
const updateWorldMatrixTemp = THREE.Object3D.prototype.updateWorldMatrix;
const updateMatrixTemp = THREE.Object3D.prototype.updateMatrix;
let matriceWorldCount = {
  value: 0
};
let matriceCount = {
  value: 0
};

const isUUID = uuid => {
  let s = '' + uuid;
  s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');

  if (s === null) {
    return false;
  }

  return true;
};

const addMuiPerfID = (material, currentObjectWithMaterials) => {
  if (!material.defines) {
    material.defines = {};
  }

  if (material.defines && !material.defines.muiPerf) {
    material.defines = Object.assign(material.defines || {}, {
      muiPerf: material.uuid
    });
  }

  const uuid = material.uuid;

  if (!currentObjectWithMaterials[uuid]) {
    currentObjectWithMaterials[uuid] = {
      meshes: {},
      material: material
    };
    material.needsUpdate = true;
  }

  material.needsUpdate = false;
  return uuid;
};

const getMUIIndex = muid => muid === 'muiPerf';

const usePerfStore = create(set => ({
  log: null,
  paused: false,
  triggerProgramsUpdate: 0,
  customData: 0,
  chart: {
    data: {
      fps: [],
      gpu: [],
      mem: []
    },
    circularId: 0
  },
  gl: undefined,
  objectWithMaterials: null,
  scene: undefined,
  programs: new Map(),
  sceneLength: undefined,
  tab: 'infos'
}));
const setCustomData = customData => {
  usePerfStore.setState({
    customData
  });
};
const getCustomData = () => {
  return usePerfStore.getState().customData;
};
const usePerfFunc = () => {
  var _usePerfStore;

  return {
    log: usePerfStore(state => state.log),
    gl: (_usePerfStore = usePerfStore(state => state.gl)) === null || _usePerfStore === void 0 ? void 0 : _usePerfStore.info,
    programs: usePerfStore(state => state.programs)
  };
};
const Headless = ({
  trackCPU,
  chart,
  deepAnalyze,
  matrixUpdate
}) => {
  const {
    gl,
    scene
  } = useThree();
  usePerfStore.setState({
    gl,
    scene
  });
  const PerfLib = useMemo(() => {
    const PerfLib = new GLPerf({
      trackGPU: true,
      chartLen: chart ? chart.length : 120,
      chartHz: chart ? chart.hz : 60,
      gl: gl.getContext(),
      chartLogger: chart => {
        usePerfStore.setState({
          chart
        });
      },
      paramLogger: logger => {
        usePerfStore.setState({
          log: {
            maxMemory: logger.maxMemory,
            gpu: logger.gpu,
            mem: logger.mem,
            fps: logger.fps,
            totalTime: logger.duration,
            frameCount: logger.frameCount
          }
        });
      }
    });
    const callbacks = new Map();
    const callbacksAfter = new Map();
    Object.defineProperty(THREE.Scene.prototype, 'onBeforeRender', {
      get() {
        return (...args) => {
          var _callbacks$get;

          if (PerfLib) {
            PerfLib.begin('profiler');
          }

          (_callbacks$get = callbacks.get(this)) === null || _callbacks$get === void 0 ? void 0 : _callbacks$get(...args);
        };
      },

      set(callback) {
        callbacks.set(this, callback);
      },

      configurable: true
    });
    Object.defineProperty(THREE.Scene.prototype, 'onAfterRender', {
      get() {
        return (...args) => {
          var _callbacksAfter$get;

          if (PerfLib) {
            PerfLib.end('profiler');
          }

          (_callbacksAfter$get = callbacksAfter.get(this)) === null || _callbacksAfter$get === void 0 ? void 0 : _callbacksAfter$get(...args);
        };
      },

      set(callback) {
        callbacksAfter.set(this, callback);
      },

      configurable: true
    });
    return PerfLib;
  }, []);
  useEffect(() => {
    if (matrixUpdate) {
      THREE.Object3D.prototype.updateMatrixWorld = function () {
        if (this.matrixWorldNeedsUpdate || arguments[0]) {
          matriceWorldCount.value++;
        }

        updateMatrixWorldTemp.apply(this, arguments);
      };

      THREE.Object3D.prototype.updateWorldMatrix = function () {
        matriceWorldCount.value++;
        updateWorldMatrixTemp.apply(this, arguments);
      };

      THREE.Object3D.prototype.updateMatrix = function () {
        matriceCount.value++;
        updateMatrixTemp.apply(this, arguments);
      };
    }

    gl.info.autoReset = false;
    let effectSub = null;
    let afterEffectSub = null;
    if (!gl.info) return;
    effectSub = addEffect(function preRafR3FPerf() {
      if (usePerfStore.getState().paused) {
        usePerfStore.setState({
          paused: false
        });
      }

      matriceCount.value -= 1;
      matriceWorldCount.value = 0;
      matriceCount.value = 0;

      if (gl.info) {
        gl.info.reset();
      }
    });
    afterEffectSub = addAfterEffect(function postRafR3FPerf() {
      if (PerfLib && !PerfLib.paused) {
        PerfLib.nextFrame(window.performance.now());
      }

      if (deepAnalyze) {
        var _gl$info, _gl$info$programs;

        const currentObjectWithMaterials = {};
        const programs = new Map();
        scene.traverse(function deepAnalyzeR3FPerf(object) {
          if (object instanceof THREE.Mesh || object instanceof THREE.Points) {
            if (object.material) {
              let uuid = object.material.uuid;
              const isTroika = Array.isArray(object.material) && object.material.length > 1;

              if (isTroika) {
                uuid = addMuiPerfID(object.material[1], currentObjectWithMaterials);
              } else {
                uuid = addMuiPerfID(object.material, currentObjectWithMaterials);
              }

              currentObjectWithMaterials[uuid].meshes[object.uuid] = object;
            }
          }
        });
        gl === null || gl === void 0 ? void 0 : (_gl$info = gl.info) === null || _gl$info === void 0 ? void 0 : (_gl$info$programs = _gl$info.programs) === null || _gl$info$programs === void 0 ? void 0 : _gl$info$programs.forEach(program => {
          const cacheKeySplited = program.cacheKey.split(',');
          const muiPerfTracker = cacheKeySplited[cacheKeySplited.findIndex(getMUIIndex) + 1];

          if (isUUID(muiPerfTracker) && currentObjectWithMaterials[muiPerfTracker]) {
            const {
              material,
              meshes
            } = currentObjectWithMaterials[muiPerfTracker];
            programs.set(muiPerfTracker, {
              program,
              material,
              meshes,
              drawCounts: {
                total: 0,
                type: 'triangle',
                data: []
              },
              expand: false,
              visible: true
            });
          }
        });

        if (programs.size !== usePerfStore.getState().programs.size) {
          countGeoDrawCalls(programs);
          usePerfStore.setState({
            programs: programs,
            triggerProgramsUpdate: usePerfStore.getState().triggerProgramsUpdate++
          });
        }
      }
    });
    return () => {
      if (PerfLib) {
        window.cancelAnimationFrame(PerfLib.rafId);
        window.cancelAnimationFrame(PerfLib.checkQueryId);
      }

      if (matrixUpdate) {
        THREE.Object3D.prototype.updateMatrixWorld = updateMatrixTemp;
      }

      effectSub();
      afterEffectSub();
    };
  }, [PerfLib, gl, trackCPU, chart, matrixUpdate]);
  useEffect(() => {
    const unsub = addTail(function postRafTailR3FPerf() {
      if (PerfLib) {
        PerfLib.paused = true;
        matriceCount.value = 0;
        matriceWorldCount.value = 0;
        usePerfStore.setState({
          paused: true,
          log: {
            maxMemory: 0,
            gpu: 0,
            mem: 0,
            fps: 0,
            totalTime: 0,
            frameCount: 0
          }
        });
      }

      return false;
    });
    return () => {
      unsub();
    };
  }, []);
  return null;
};

const PerfS = styled('div', {
  position: 'fixed',
  top: 0,
  right: 0,
  zIndex: 9999,
  fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif`,
  backgroundColor: 'rgba(36, 36, 36, .9)',
  color: '#fff',
  margin: 0,
  minHeight: '100px',
  padding: '4px 0',
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale',
  userSelect: 'none',
  '&.top-left': {
    right: 'initial',
    left: 0
  },
  '&.bottom-left': {
    right: 'initial',
    top: 'initial',
    bottom: 0,
    left: 0,
    '.__perf_toggle': {
      top: '-20px',
      bottom: 'initial'
    }
  },
  '&.bottom-right': {
    top: 'initial',
    bottom: 0,
    '.__perf_toggle': {
      top: '-20px',
      bottom: 'initial'
    }
  },
  '&.minimal': {
    backgroundColor: 'rgba(36, 36, 36, .75)'
  },
  '*': {
    margin: '0',
    padding: '0',
    border: '0',
    fontSize: '100%',
    lineHeight: '1',
    verticalAlign: 'baseline'
  }
});
const PerfSmallI = styled('small', {
  position: 'absolute',
  right: 0,
  fontSize: '10px'
});
const PerfI = styled('div', {
  display: 'inline-flex',
  fontStyle: 'normal',
  padding: 0,
  lineHeight: '13px',
  fontSize: '14px',
  width: '62px',
  position: 'relative',
  pointerEvents: 'auto',
  cursor: 'default',
  fontWeight: 500,
  letterSpacing: '0px',
  textAlign: 'left',
  height: '29px',
  whiteSpace: 'nowrap',
  justifyContent: 'space-evenly',
  fontVariantNumeric: 'tabular-nums',
  small: {
    paddingLeft: '12px'
  },
  svg: {
    padding: 0,
    color: 'rgba(145, 145, 145, 0.3)',
    fontSize: '40px',
    position: 'absolute',
    zIndex: 1,
    maxHeight: '20px',
    left: ' 50%',
    marginLeft: '-23px',
    top: '4px'
  }
});
const PerfB = styled('span', {
  verticalAlign: 'bottom',
  position: 'absolute',
  bottom: '5px',
  color: 'rgba(101, 197, 188, 1)',
  textAlign: 'right',
  letterSpacing: '1px',
  fontSize: '8px',
  fontWeight: '500',
  width: '60px'
});
const PerfIContainer = styled('div', {
  display: 'flex'
});
const ProgramHeader = styled('div', {
  backgroundColor: '#404040',
  padding: '6px',
  display: 'block',
  fontSize: '12px',
  marginBottom: '6px',
  cursor: 'pointer',
  '*': {
    cursor: 'pointer !important'
  },
  '> span': {},
  small: {
    fontSize: '9px'
  },
  '> b': {
    marginRight: '4px',
    cursor: 'pointer'
  }
});
const Graph = styled('div', {
  height: '66px',
  overflow: 'hidden',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  top: '0px',
  justifyContent: 'center',
  width: '100%',
  minWidth: '310px',
  margin: '0 auto',
  canvas: {
    background: 'transparent !important',
    position: 'absolute !important'
  }
});
const Graphpc = styled('div', {
  textAlign: 'center',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  verticalAlign: 'middle',
  color: '#f1f1f1',
  padding: '7px',
  width: '100%',
  backgroundColor: 'rgba(36, 36, 37, 0.8)',
  zIndex: 1,
  position: 'absolute',
  height: '100%'
});
const Toggle = styled('div', {
  pointerEvents: 'auto',
  justifyContent: 'center',
  cursor: 'pointer',
  fontSize: '12px',
  backgroundColor: 'rgb(41, 43, 45)',
  marginTop: '6px',
  width: 'auto',
  margin: '0',
  color: 'rgba(145, 145, 145, 1)',
  textAlign: 'center',
  display: 'inline-block',
  verticalAlign: 'middle',
  padding: '4px 6px',
  '&.__perf_toggle_tab_active': {
    backgroundColor: 'rgb(31 31 31)'
  },
  svg: {
    width: '12px',
    height: '12px',
    float: 'left'
  }
});
const ToggleVisible = styled('div', {
  pointerEvents: 'auto',
  justifyContent: 'center',
  cursor: 'pointer',
  fontSize: '12px',
  float: 'right',
  backgroundColor: 'rgb(41, 43, 45)',
  width: 'auto',
  margin: '0',
  color: 'rgba(145, 145, 145, 1)',
  textAlign: 'center',
  display: 'inline-block',
  verticalAlign: 'middle',
  padding: '4px 6px',
  '&.__perf_toggle_tab_active': {
    backgroundColor: 'rgb(31 31 31)'
  },
  svg: {
    width: '12px',
    height: '12px',
    float: 'left'
  }
});
const ProgramGeo = styled('div', {
  padding: '4px 6px',
  fontSize: '12px',
  pointerEvents: 'auto'
});
const ProgramTitle = styled('span', {
  fontWeight: 'bold',
  letterSpacing: '0.08em',
  maxWidth: '145px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  verticalAlign: 'middle',
  fontSize: '11px',
  marginRight: '10px'
});
const ContainerScroll = styled('div', {
  maxHeight: '50vh',
  overflowY: 'auto',
  marginTop: '38px'
});
const ProgramsContainer = styled('div', {
  marginTop: '0'
});
const ProgramsULHeader = styled('div', {
  display: 'flex',
  position: 'relative',
  fontWeight: 'bold',
  color: '#fff',
  lineHeight: '14px',
  svg: {
    marginRight: '4px',
    display: 'inline-block'
  }
});
const ProgramsUL = styled('ul', {
  display: 'block',
  position: 'relative',
  paddingLeft: '10px',
  margin: '6px 6px',
  img: {
    maxHeight: '60px',
    maxWidth: '100%',
    margin: '6px auto',
    display: 'block'
  },
  '&:after': {
    content: '',
    position: 'absolute',
    left: '0px',
    top: '0px',
    width: '1px',
    height: '100%',
    backgroundColor: 'grey',
    transform: 'translateX(-50%)',
    maxHeight: '50vh',
    overflowY: 'auto'
  },
  li: {
    borderBottom: '1px solid #313131',
    display: 'block',
    padding: '4px',
    margin: 0,
    lineHeight: 1,
    verticalAlign: 'middle',
    height: '24px'
  },
  b: {
    fontWeight: 'bold'
  },
  small: {
    textAlign: 'revert',
    letterSpacing: '1px',
    fontSize: '10px',
    fontWeight: '500',
    marginLeft: '2px',
    color: 'rgb(101, 197, 188)'
  }
});
const ProgramConsole = styled('button', {
  fontWeight: 'bold',
  letterSpacing: '0.02em',
  backgroundColor: 'rgb(41, 43, 45)',
  color: 'rgb(211, 211, 211)',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  cursor: 'pointer',
  display: 'block',
  verticalAlign: 'middle',
  fontSize: '11px',
  padding: '5px',
  margin: '4px auto'
});
const ToggleContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
  fontSize: '12px',
  backgroundColor: 'rgb(41, 43, 45)',
  marginTop: '6px',
  width: 'auto',
  margin: '0 auto',
  color: 'rgba(145, 145, 145, 1)',
  textAlign: 'center',
  position: 'absolute',
  right: 0,
  bottom: ' -20px',
  svg: {
    width: '12px',
    height: '12px',
    float: 'left'
  }
});
const ProgramsGeoLi = styled('li', {
  display: 'flex !important',
  height: 'auto !important',
  span: {
    height: '40px',
    display: 'block',
    position: 'relative'
  },
  b: {
    paddingLeft: '12px'
  }
});

const TextHighHZ = memo(({
  isPerf,
  color,
  customData,
  isMemory,
  isShadersInfo,
  metric,
  fontSize,
  offsetY: _offsetY = 0,
  offsetX,
  round,
  hasInstance
}) => {
  const {
    width: w,
    height: h
  } = useThree(s => s.viewport);
  const fpsRef = useRef(null);
  const fpsInstanceRef = useRef(null);
  useFrame(function updateR3FPerfText() {
    const gl = usePerfStore.getState().gl;
    const log = usePerfStore.getState().log;
    if (!log || !fpsRef.current) return;

    if (customData) {
      fpsRef.current.text = usePerfStore.getState().customData;
    }

    if (!metric) return;
    let info = log[metric];

    if (isShadersInfo) {
      var _gl$info$programs;

      info = (_gl$info$programs = gl.info.programs) === null || _gl$info$programs === void 0 ? void 0 : _gl$info$programs.length;
    } else if (metric === 'matriceCount') {
      info = matriceCount.value;
    } else if (!isPerf && gl.info.render) {
      const infos = isMemory ? gl.info.memory : gl.info.render;
      info = infos[metric];
    }

    fpsRef.current.text = (metric === 'maxMemory' ? '/' : '') + (Math.round(info * Math.pow(10, round)) / Math.pow(10, round)).toFixed(round);

    if (hasInstance) {
      const infosInstance = gl.info.instance;

      if (typeof infosInstance === 'undefined' && metric !== 'matriceCount') {
        return;
      }

      let infoInstance;

      if (metric === 'matriceCount') {
        infoInstance = matriceWorldCount.value;
      } else {
        infoInstance = infosInstance[metric];
      }

      if (infoInstance > 0) {
        fpsRef.current.fontSize = fontSize / 1.15;
        fpsInstanceRef.current.fontSize = info > 0 ? fontSize / 1.4 : fontSize;
        fpsRef.current.position.y = h / 2 - _offsetY - fontSize / 1.9;
        fpsInstanceRef.current.text = ' ±	' + (Math.round(infoInstance * Math.pow(10, round)) / Math.pow(10, round)).toFixed(round);
      } else {
        fpsRef.current.position.y = h / 2 - _offsetY - fontSize;
        fpsRef.current.fontSize = fontSize;
      }
    }

    matriceCount.value -= 1;
    fpsRef.current.updateMatrix();
    fpsRef.current.matrixWorld.copy(fpsRef.current.matrix);
  });
  return jsxs(Suspense, {
    fallback: null,
    children: [jsx(Text, {
      textAlign: "justify",
      matrixAutoUpdate: false,
      ref: fpsRef,
      fontSize: fontSize,
      position: [-w / 2 + offsetX + fontSize, h / 2 - _offsetY - fontSize, 0],
      color: color,
      characters: "0123456789",
      onUpdate: self => {
        self.updateMatrix();
        matriceCount.value -= 1;
        self.matrixWorld.copy(self.matrix);
      },
      children: "0"
    }), hasInstance && jsx(Text, {
      textAlign: "justify",
      matrixAutoUpdate: false,
      ref: fpsInstanceRef,
      fontSize: 8,
      position: [-w / 2 + offsetX + fontSize, h / 2 - _offsetY - fontSize * 1.15, 0],
      color: 'lightgrey',
      characters: "0123456789",
      onUpdate: self => {
        self.updateMatrix();
        matriceCount.value -= 1;
        self.matrixWorld.copy(self.matrix);
      }
    })]
  });
});

const TextsHighHZ = ({
  colorBlind,
  customData,
  minimal,
  matrixUpdate
}) => {
  const fontSize = 14;
  return jsxs(Fragment, {
    children: [jsx(TextHighHZ, {
      color: `rgb(${colorsGraph(colorBlind).fps.toString()})`,
      isPerf: true,
      metric: "fps",
      fontSize: fontSize,
      offsetX: 140,
      round: 0
    }), jsx(TextHighHZ, {
      color: '',
      isPerf: true,
      metric: "mem",
      fontSize: fontSize,
      offsetX: 80,
      round: 0
    }), jsx(TextHighHZ, {
      color: '',
      isPerf: true,
      metric: "maxMemory",
      fontSize: 8,
      offsetX: 112,
      offsetY: 10,
      round: 0
    }), jsx(TextHighHZ, {
      color: `rgb(${colorsGraph(colorBlind).gpu.toString()})`,
      isPerf: true,
      metric: "gpu",
      fontSize: fontSize,
      offsetX: 10,
      round: 3
    }), !minimal ? jsxs(Fragment, {
      children: [jsx(TextHighHZ, {
        metric: "calls",
        fontSize: fontSize,
        offsetX: 200,
        round: 0,
        hasInstance: true
      }), jsx(TextHighHZ, {
        metric: "triangles",
        fontSize: fontSize,
        offsetX: 260,
        round: 0,
        hasInstance: true
      }), jsx(TextHighHZ, {
        isMemory: true,
        metric: "geometries",
        fontSize: fontSize,
        offsetY: 30,
        offsetX: 0,
        round: 0
      }), jsx(TextHighHZ, {
        isMemory: true,
        metric: "textures",
        fontSize: fontSize,
        offsetY: 30,
        offsetX: 80,
        round: 0
      }), jsx(TextHighHZ, {
        isShadersInfo: true,
        metric: "programs",
        fontSize: fontSize,
        offsetY: 30,
        offsetX: 140,
        round: 0
      }), jsx(TextHighHZ, {
        metric: "lines",
        fontSize: fontSize,
        offsetY: 30,
        offsetX: 200,
        round: 0,
        hasInstance: true
      }), jsx(TextHighHZ, {
        metric: "points",
        fontSize: fontSize,
        offsetY: 30,
        offsetX: 260,
        round: 0,
        hasInstance: true
      }), matrixUpdate && jsx(TextHighHZ, {
        isPerf: true,
        metric: "matriceCount",
        fontSize: fontSize,
        offsetY: 30,
        offsetX: 320,
        round: 0,
        hasInstance: true
      })]
    }) : null, customData && jsx(TextHighHZ, {
      color: `rgb(${colorsGraph(colorBlind).custom.toString()})`,
      customData: customData,
      fontSize: fontSize,
      offsetY: 0,
      offsetX: minimal ? 200 : 320,
      round: 0
    })]
  });
};

const ChartCurve = ({
  colorBlind,
  minimal,
  chart: _chart = {
    length: 30,
    hz: 15
  }
}) => {
  const curves = useMemo(() => {
    return {
      fps: new Float32Array(_chart.length * 3),
      mem: new Float32Array(_chart.length * 3),
      gpu: new Float32Array(_chart.length * 3)
    };
  }, [_chart]);
  const fpsRef = useRef(null);
  const gpuRef = useRef(null);
  const memRef = useRef(null);
  const dummyVec3 = useMemo(() => new THREE.Vector3(0, 0, 0), []);

  const updatePoints = (element, factor = 1, ref, viewport) => {
    let maxVal = 0;
    const {
      width: w,
      height: h
    } = viewport;
    const chart = usePerfStore.getState().chart.data[element];

    if (!chart || chart.length === 0) {
      return;
    }

    const padding = minimal ? 2 : 6;
    const paddingTop = minimal ? 12 : 50;
    let len = chart.length;

    for (let i = 0; i < len; i++) {
      let id = (usePerfStore.getState().chart.circularId + i + 1) % len;

      if (chart[id] !== undefined) {
        if (chart[id] > maxVal) {
          maxVal = chart[id] * factor;
        }

        dummyVec3.set(padding + i / (len - 1) * (w - padding * 2) - w / 2, Math.min(100, chart[id]) * factor / 100 * (h - padding * 2 - paddingTop) - h / 2, 0);
        dummyVec3.toArray(ref.attributes.position.array, i * 3);
      }
    }

    ref.attributes.position.needsUpdate = true;
  };

  const [supportMemory] = useState(window.performance.memory);
  useFrame(function updateChartCurve({
    viewport
  }) {
    updatePoints('fps', 1, fpsRef.current, viewport);
    updatePoints('gpu', 5, gpuRef.current, viewport);

    if (supportMemory) {
      updatePoints('mem', 1, memRef.current, viewport);
    }
  });
  return jsxs(Fragment, {
    children: [jsxs("line", {
      children: [jsx("bufferGeometry", {
        ref: fpsRef,
        children: jsx("bufferAttribute", {
          attach: 'attributes-position',
          count: _chart.length,
          array: curves.fps,
          itemSize: 3,
          needsUpdate: true
        })
      }), jsx("lineBasicMaterial", {
        color: `rgb(${colorsGraph(colorBlind).fps.toString()})`,
        transparent: true,
        opacity: 0.5
      })]
    }), jsxs("line", {
      children: [jsx("bufferGeometry", {
        ref: gpuRef,
        children: jsx("bufferAttribute", {
          attach: 'attributes-position',
          count: _chart.length,
          array: curves.gpu,
          itemSize: 3,
          needsUpdate: true
        })
      }), jsx("lineBasicMaterial", {
        color: `rgb(${colorsGraph(colorBlind).gpu.toString()})`,
        transparent: true,
        opacity: 0.5
      })]
    }), supportMemory && jsxs("line", {
      children: [jsx("bufferGeometry", {
        ref: memRef,
        children: jsx("bufferAttribute", {
          attach: 'attributes-position',
          count: _chart.length,
          array: curves.mem,
          itemSize: 3,
          needsUpdate: true
        })
      }), jsx("lineBasicMaterial", {
        color: `rgb(${colorsGraph(colorBlind).mem.toString()})`,
        transparent: true,
        opacity: 0.5
      })]
    })]
  });
};

const ChartUI = ({
  colorBlind,
  chart,
  customData,
  matrixUpdate,
  showGraph: _showGraph = true,
  antialias: _antialias = true,
  minimal
}) => {
  const canvas = useRef(undefined);
  const paused = usePerfStore(state => state.paused);
  return jsxs(Graph, {
    style: {
      display: 'flex',
      position: 'absolute',
      height: `${minimal ? 37 : _showGraph ? 100 : 60}px`,
      minWidth: `${minimal ? '100px' : customData ? '370px' : '310px'}`
    },
    children: [jsx(Canvas, {
      ref: canvas,
      orthographic: true,
      dpr: _antialias ? [1, 2] : 1,
      gl: {
        antialias: true,
        alpha: true,
        stencil: false,
        depth: false
      },
      onCreated: ({
        scene
      }) => {
        scene.traverse(obj => {
          obj.matrixWorldAutoUpdate = false;
          obj.matrixAutoUpdate = false;
        });
      },
      flat: true,
      style: {
        marginBottom: `-42px`,
        position: 'relative',
        pointerEvents: 'none',
        background: 'transparent !important',
        height: `${minimal ? 37 : _showGraph ? 100 : 60}px`
      },
      children: !paused ? jsxs(Fragment, {
        children: [jsx(Renderer, {}), jsx(TextsHighHZ, {
          customData: customData,
          minimal: minimal,
          matrixUpdate: matrixUpdate
        }), _showGraph && jsx(ChartCurve, {
          colorBlind: colorBlind,
          minimal: minimal,
          chart: chart
        })]
      }) : null
    }), paused && jsxs(Graphpc, {
      children: [jsx(PauseIcon, {}), " PAUSED"]
    })]
  });
};

const Renderer = () => {
  useFrame(function updateR3FPerf({
    gl,
    scene,
    camera
  }) {
    camera.updateMatrix();
    matriceCount.value -= 1;
    camera.matrixWorld.copy(camera.matrix);
    camera.matrixWorldInverse.copy(camera.matrixWorld).invert();
    gl.render(scene, camera);
    matriceWorldCount.value = 0;
    matriceCount.value = 0;
  }, Infinity);
  return null;
};

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};
  var target = _objectWithoutPropertiesLoose(source, excluded);
  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

const _excluded = ["portal", "className", "children"];
const Html = forwardRef((_ref, ref) => {
  let {
    portal,
    className,
    children
  } = _ref,
      props = _objectWithoutProperties(_ref, _excluded);

  const gl = useThree(state => state.gl);
  const group = useRef(null);
  const rootRef = useRef(null);
  const target = (portal === null || portal === void 0 ? void 0 : portal.current) != null ? portal.current : gl.domElement.parentNode;
  useLayoutEffect(() => {
    if (!group.current || !target) return;
    const el = document.createElement('div');
    const root = rootRef.current = createRoot(el);
    target.appendChild(el);
    return () => {
      root.unmount();
      rootRef.current = null;
      target.removeChild(el);
    };
  }, [target]);
  useLayoutEffect(() => {
    const root = rootRef.current;
    if (!root) return;
    root.render(jsx("div", {
      ref: ref,
      className: className,
      children: children
    }));
  });
  return jsx("group", _objectSpread2(_objectSpread2({}, props), {}, {
    ref: group
  }));
});

const addTextureUniforms = (id, texture) => {
  const repeatType = wrap => {
    switch (wrap) {
      case 1000:
        return 'RepeatWrapping';

      case 1001:
        return 'ClampToEdgeWrapping';

      case 1002:
        return 'MirroredRepeatWrapping';

      default:
        return 'ClampToEdgeWrapping';
    }
  };

  const encodingType = encoding => {
    switch (encoding) {
      case 3000:
        return 'LinearEncoding';

      case 3001:
        return 'sRGBEncoding';

      case 3002:
        return 'RGBEEncoding';

      case 3003:
        return 'LogLuvEncoding';

      case 3004:
        return 'RGBM7Encoding';

      case 3005:
        return 'RGBM16Encoding';

      case 3006:
        return 'RGBDEncoding';

      case 3007:
        return 'GammaEncoding';

      default:
        return 'ClampToEdgeWrapping';
    }
  };

  return {
    name: id,
    url: texture.image.currentSrc,
    encoding: encodingType(texture.encoding),
    wrapT: repeatType(texture.image.wrapT),
    flipY: texture.flipY.toString()
  };
};

const UniformsGL = ({
  program,
  material,
  setTexNumber
}) => {
  const gl = usePerfStore(state => state.gl);
  const [uniforms, set] = useState(null);
  useEffect(() => {
    if (gl) {
      const data = program === null || program === void 0 ? void 0 : program.getUniforms();
      let TexCount = 0;
      const format = new Map();
      data.seq.forEach(e => {
        if (!e.id.includes('uTroika') && e.id !== 'isOrthographic' && e.id !== 'uvTransform' && e.id !== 'lightProbe' && e.id !== 'projectionMatrix' && e.id !== 'viewMatrix' && e.id !== 'normalMatrix' && e.id !== 'modelMatrix' && e.id !== 'modelViewMatrix') {
          let values = [];
          let data = {
            name: e.id
          };

          if (e.cache) {
            e.cache.forEach(v => {
              if (typeof v !== 'undefined') {
                values.push(v.toString().substring(0, 4));
              }
            });
            data.value = values.join();

            if (material[e.id] && material[e.id].image) {
              if (material[e.id].image) {
                TexCount++;
                data.value = addTextureUniforms(e.id, material[e.id]);
              }
            }

            if (!data.value) {
              data.value = 'empty';
            }

            format.set(e.id, data);
          }
        }
      });

      if (material.uniforms) {
        Object.keys(material.uniforms).forEach(key => {
          const uniform = material.uniforms[key];

          if (uniform.value) {
            const {
              value
            } = uniform;
            let data = {
              name: key
            };

            if (key.includes('uTroika')) {
              return;
            }

            if (value instanceof THREE.Texture) {
              TexCount++;
              data.value = addTextureUniforms(key, value);
            } else {
              let sb = JSON.stringify(value);

              try {
                sb = JSON.stringify(value);
              } catch (_err) {
                sb = value.toString();
              }

              data.value = sb;
            }

            format.set(key, data);
          }
        });
      }

      if (TexCount > 0) {
        setTexNumber(TexCount);
      }

      set(format);
    }
  }, []);
  return jsx(ProgramsUL, {
    children: uniforms && Array.from(uniforms.values()).map(uniform => {
      return jsx("span", {
        children: typeof uniform.value === 'string' ? jsx("li", {
          children: jsxs("span", {
            children: [uniform.name, " :", ' ', jsxs("b", {
              children: [uniform.value.substring(0, 30), uniform.value.length > 30 ? '...' : '']
            })]
          })
        }) : jsxs(Fragment, {
          children: [jsx("li", {
            children: jsxs("b", {
              children: [uniform.value.name, ":"]
            })
          }), jsxs("div", {
            children: [Object.keys(uniform.value).map(key => {
              return key !== 'name' ? jsx("div", {
                children: key === 'url' ? jsx("a", {
                  href: uniform.value[key],
                  target: "_blank",
                  children: jsx("img", {
                    src: uniform.value[key]
                  })
                }) : jsxs("li", {
                  children: [key, ": ", jsx("b", {
                    children: uniform.value[key]
                  })]
                })
              }, key) : null;
            }), jsxs(ProgramConsole, {
              onClick: () => {
                var _material$uniforms$un;

                console.info(material[uniform.value.name] || (material === null || material === void 0 ? void 0 : (_material$uniforms$un = material.uniforms[uniform.value.name]) === null || _material$uniforms$un === void 0 ? void 0 : _material$uniforms$un.value));
              },
              children: ["console.info(", uniform.value.name, ");"]
            })]
          })]
        })
      }, uniform.name);
    })
  });
};

const DynamicDrawCallInfo = ({
  el
}) => {
  usePerfStore(state => state.log);
  const gl = usePerfStore(state => state.gl);

  const getVal = el => {
    if (!gl) return 0;
    const res = Math.round(el.drawCounts.total / (gl.info.render.triangles + gl.info.render.lines + gl.info.render.points) * 100 * 10) / 10;
    return isFinite(res) && res || 0;
  };

  return jsx(Fragment, {
    children: el.drawCounts.total > 0 && jsxs(PerfI, {
      style: {
        height: 'auto',
        width: 'auto',
        margin: '0 4px'
      },
      children: [el.drawCounts.type === 'Triangle' ? jsx(VercelLogoIcon, {
        style: {
          top: '-1px'
        }
      }) : jsx(ActivityLogIcon, {
        style: {
          top: '-1px'
        }
      }), el.drawCounts.total, jsxs("small", {
        children: [el.drawCounts.type, "s"]
      }), gl && jsxs(PerfB, {
        style: {
          bottom: '-10px',
          width: '40px',
          fontWeight: 'bold'
        },
        children: [el.visible && !el.material.wireframe ? getVal(el) : 0, "%"]
      })]
    })
  });
};

const ProgramUI = ({
  el
}) => {
  const [showProgram, setShowProgram] = useState(el.visible);
  const [toggleProgram, set] = useState(el.expand);
  const [texNumber, setTexNumber] = useState(0);
  const {
    meshes,
    program,
    material
  } = el;
  return jsxs(ProgramGeo, {
    children: [jsxs(ProgramHeader, {
      onClick: () => {
        el.expand = !toggleProgram;
        Object.keys(meshes).forEach(key => {
          const mesh = meshes[key];
          mesh.material.wireframe = false;
        });
        set(!toggleProgram);
      },
      children: [jsx(Toggle, {
        style: {
          marginRight: '6px'
        },
        children: toggleProgram ? jsx("span", {
          children: jsx(TriangleDownIcon, {})
        }) : jsx("span", {
          children: jsx(TriangleUpIcon, {})
        })
      }), program && jsxs("span", {
        children: [jsx(ProgramTitle, {
          children: program.name
        }), jsxs(PerfI, {
          style: {
            height: 'auto',
            width: 'auto',
            margin: '0 4px'
          },
          children: [jsx(LayersIcon, {
            style: {
              top: '-1px'
            }
          }), Object.keys(meshes).length, jsx("small", {
            children: Object.keys(meshes).length > 1 ? 'users' : 'user'
          })]
        }), texNumber > 0 && jsxs(PerfI, {
          style: {
            height: 'auto',
            width: 'auto',
            margin: '0 4px'
          },
          children: [texNumber > 1 ? jsx(ImageIcon, {
            style: {
              top: '-1px'
            }
          }) : jsx(ImageIcon, {
            style: {
              top: '-1px'
            }
          }), texNumber, jsx("small", {
            children: "tex"
          })]
        }), jsx(DynamicDrawCallInfo, {
          el: el
        }), material.glslVersion === '300 es' && jsxs(PerfI, {
          style: {
            height: 'auto',
            width: 'auto',
            margin: '0 4px'
          },
          children: [jsx(RocketIcon, {
            style: {
              top: '-1px'
            }
          }), "300", jsx("small", {
            children: "es"
          }), jsx(PerfB, {
            style: {
              bottom: '-10px',
              width: '40px'
            },
            children: "glsl"
          })]
        })]
      }), jsx(ToggleVisible, {
        onPointerEnter: () => {
          Object.keys(meshes).forEach(key => {
            const mesh = meshes[key];
            mesh.material.wireframe = true;
          });
        },
        onPointerLeave: () => {
          Object.keys(meshes).forEach(key => {
            const mesh = meshes[key];
            mesh.material.wireframe = false;
          });
        },
        onClick: e => {
          e.stopPropagation();
          Object.keys(meshes).forEach(key => {
            const mesh = meshes[key];
            const invert = !showProgram;
            mesh.visible = invert;
            el.visible = invert;
            setShowProgram(invert);
          });
        },
        children: showProgram ? jsx(EyeOpenIcon, {}) : jsx(EyeNoneIcon, {})
      })]
    }), jsxs("div", {
      style: {
        maxHeight: toggleProgram ? '9999px' : 0,
        overflow: 'hidden'
      },
      children: [jsxs(ProgramsULHeader, {
        children: [jsx(ButtonIcon, {}), " Uniforms:"]
      }), jsx(UniformsGL, {
        program: program,
        material: material,
        setTexNumber: setTexNumber
      }), jsxs(ProgramsULHeader, {
        children: [jsx(CubeIcon, {}), " Geometries:"]
      }), jsx(ProgramsUL, {
        children: meshes && Object.keys(meshes).map(key => meshes[key] && meshes[key].geometry && jsxs(ProgramsGeoLi, {
          children: [jsxs("span", {
            children: [meshes[key].geometry.type, ": "]
          }), meshes[key].userData && meshes[key].userData.drawCount && jsxs("b", {
            children: [jsxs("div", {
              children: [meshes[key].userData.drawCount.count, jsxs("small", {
                children: [" ", meshes[key].userData.drawCount.type, "s"]
              })]
            }), jsx("br", {}), jsxs("div", {
              children: [Math.round(estimateBytesUsed(meshes[key].geometry) / 1024 * 1000) / 1000, "Kb", jsx("small", {
                children: " memory used"
              })]
            })]
          })]
        }, key))
      }), jsxs(ProgramConsole, {
        onClick: () => {
          console.info(material);
        },
        children: ["console.info(", material.type, ")"]
      })]
    })]
  });
};

const ProgramsUI = () => {
  usePerfStore(state => state.triggerProgramsUpdate);
  const programs = usePerfStore(state => state.programs);
  return jsx(ProgramsContainer, {
    children: programs && Array.from(programs.values()).map(el => {
      if (!el) {
        return null;
      }

      return el ? jsx(ProgramUI, {
        el: el
      }, el.material.uuid) : null;
    })
  });
};

const colorsGraph = colorBlind => {
  const colors = {
    fps: colorBlind ? '100, 143, 255' : '238,38,110',
    mem: colorBlind ? '254, 254, 98' : '66,226,46',
    gpu: colorBlind ? '254,254,254' : '253,151,31',
    custom: colorBlind ? '86,180,233' : '40,255,255'
  };
  return colors;
};

const DynamicUI = ({
  showGraph,
  colorBlind,
  customData,
  minimal
}) => {
  const gl = usePerfStore(state => state.gl);
  return gl ? jsxs(PerfIContainer, {
    children: [jsxs(PerfI, {
      children: [jsx(LightningBoltIcon, {}), jsx(PerfB, {
        style: showGraph ? {
          color: `rgb(${colorsGraph(colorBlind).gpu.toString()})`
        } : {},
        children: "GPU"
      }), jsx(PerfSmallI, {
        children: "ms"
      })]
    }), jsxs(PerfI, {
      children: [jsx(RulerHorizontalIcon, {}), jsx(PerfB, {
        style: showGraph ? {
          color: `rgb(${colorsGraph(colorBlind).mem.toString()})`
        } : {},
        children: "Memory"
      }), jsx(PerfSmallI, {
        children: "mb"
      })]
    }), jsxs(PerfI, {
      children: [jsx(LapTimerIcon, {}), jsx(PerfB, {
        style: showGraph ? {
          color: `rgb(${colorsGraph(colorBlind).fps})`
        } : {},
        children: "FPS"
      })]
    }), !minimal && gl && jsxs(PerfI, {
      children: [jsx(TextAlignJustifyIcon, {}), jsx(PerfB, {
        children: gl.info.render.calls === 1 ? 'call' : 'calls'
      })]
    }), !minimal && gl && jsxs(PerfI, {
      children: [jsx(VercelLogoIcon, {}), jsx(PerfB, {
        children: "Triangles"
      })]
    }), customData && jsxs(PerfI, {
      children: [jsx(BarChartIcon, {}), jsx(PerfB, {
        style: showGraph ? {
          color: `rgb(${colorsGraph(colorBlind).custom})`
        } : {},
        children: customData.name
      }), customData.info && jsx(PerfSmallI, {
        children: customData.info
      })]
    })]
  }) : null;
};

const PerfUI = ({
  showGraph,
  colorBlind,
  deepAnalyze,
  customData,
  matrixUpdate,
  openByDefault,
  minimal
}) => {
  return jsxs(Fragment, {
    children: [jsx(DynamicUI, {
      showGraph: showGraph,
      colorBlind: colorBlind,
      customData: customData,
      minimal: minimal
    }), !minimal && jsx(PerfThree, {
      matrixUpdate: matrixUpdate,
      openByDefault: openByDefault,
      deepAnalyze: deepAnalyze,
      showGraph: showGraph
    })]
  });
};

const InfoUI = ({
  matrixUpdate
}) => {
  return jsxs("div", {
    children: [jsxs(PerfI, {
      children: [jsx(MarginIcon, {}), jsx(PerfB, {
        children: "Geometries"
      })]
    }), jsxs(PerfI, {
      children: [jsx(ImageIcon, {}), jsx(PerfB, {
        children: "Textures"
      })]
    }), jsxs(PerfI, {
      children: [jsx(ActivityLogIcon, {}), jsx(PerfB, {
        children: "shaders"
      })]
    }), jsxs(PerfI, {
      children: [jsx(MinusIcon, {}), jsx(PerfB, {
        children: "Lines"
      })]
    }), jsxs(PerfI, {
      children: [jsx(DotIcon, {}), jsx(PerfB, {
        children: "Points"
      })]
    }), matrixUpdate && jsxs(PerfI, {
      children: [jsx(DropdownMenuIcon, {}), jsx(PerfB, {
        children: "Matrices"
      })]
    })]
  });
};

const ToggleEl = ({
  tab,
  title,
  set
}) => {
  const tabStore = usePerfStore(state => state.tab);
  return jsx(Toggle, {
    className: `${tabStore === tab ? ' __perf_toggle_tab_active' : ''}`,
    onClick: () => {
      set(true);
      usePerfStore.setState({
        tab: tab
      });
    },
    children: jsx("span", {
      children: title
    })
  });
};

const PerfThree = ({
  openByDefault,
  showGraph,
  deepAnalyze,
  matrixUpdate
}) => {
  const [show, set] = React.useState(openByDefault);
  return jsxs("span", {
    children: [jsx(TabContainers, {
      show: show,
      showGraph: showGraph,
      matrixUpdate: matrixUpdate
    }), openByDefault && !deepAnalyze ? null : jsxs(ToggleContainer, {
      className: '__perf_toggle',
      children: [deepAnalyze && jsx(ToggleEl, {
        tab: "programs",
        title: "Programs",
        set: set
      }), deepAnalyze && jsx(ToggleEl, {
        tab: "infos",
        title: "Infos",
        set: set
      }), jsx(Toggle, {
        onClick: () => {
          set(!show);
        },
        children: show ? jsxs("span", {
          children: [jsx(TriangleDownIcon, {}), " Minimize"]
        }) : jsxs("span", {
          children: [jsx(TriangleUpIcon, {}), " More"]
        })
      })]
    })]
  });
};

const TabContainers = ({
  show,
  showGraph,
  matrixUpdate
}) => {
  const tab = usePerfStore(state => state.tab);
  return jsxs(Fragment, {
    children: [jsx(InfoUI, {
      matrixUpdate: matrixUpdate
    }), show && jsx("div", {
      children: jsx(ContainerScroll, {
        style: {
          marginTop: showGraph ? '38px' : 0
        },
        children: tab === 'programs' && jsx(ProgramsUI, {})
      })
    })]
  });
};

const Gui = ({
  showGraph,
  colorBlind,
  openByDefault,
  className,
  style,
  position,
  chart,
  deepAnalyze,
  antialias,
  customData,
  matrixUpdate,
  minimal
}) => {
  const perfContainerRef = useRef(null);
  return jsxs(Fragment, {
    children: [jsx(Headless, {
      chart: chart,
      deepAnalyze: deepAnalyze,
      matrixUpdate: matrixUpdate
    }), jsx(Html, {
      transform: false,
      children: jsxs(PerfS, {
        className: (className ? ' '.concat(className) : ' ') + ` ${position ? position : ''} ${minimal ? 'minimal' : ''}`,
        style: _objectSpread2({
          minHeight: minimal ? '37px' : showGraph ? '100px' : '60px'
        }, style),
        ref: perfContainerRef,
        children: [jsx(ChartUI, {
          perfContainerRef: perfContainerRef,
          colorBlind: colorBlind,
          chart: chart,
          showGraph: showGraph,
          antialias: antialias,
          customData: customData,
          minimal: minimal,
          matrixUpdate: matrixUpdate
        }), jsx(PerfUI, {
          colorBlind: colorBlind,
          showGraph: showGraph,
          deepAnalyze: deepAnalyze,
          openByDefault: openByDefault,
          customData: customData,
          matrixUpdate: matrixUpdate,
          minimal: minimal
        })]
      })
    })]
  });
};

const Perf = ({
  headless: _headless = false,
  colorBlind: _colorBlind = false,
  showGraph: _showGraph = true,
  trackCPU: _trackCPU = false,
  deepAnalyze: _deepAnalyze = false,
  antialias: _antialias = true,
  openByDefault: _openByDefault = true,
  customData,
  matrixUpdate: _matrixUpdate = false,
  position: _position = 'top-right',
  chart: _chart = {
    length: 30,
    hz: 15
  },
  className,
  style,
  minimal: _minimal = false
}) => {
  return _headless ? jsx(Headless, {
    chart: _chart,
    deepAnalyze: _deepAnalyze,
    matrixUpdate: _matrixUpdate
  }) : jsx(Gui, {
    colorBlind: _colorBlind,
    showGraph: _showGraph,
    trackCPU: _trackCPU,
    openByDefault: _openByDefault,
    className: className,
    style: style,
    position: _position,
    antialias: _antialias,
    chart: _chart,
    deepAnalyze: _deepAnalyze,
    minimal: _minimal,
    customData: customData,
    matrixUpdate: _matrixUpdate
  });
};
const usePerf = usePerfFunc;

export { Perf, getCustomData, setCustomData, usePerf };
