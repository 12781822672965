import React, {useMemo} from 'react';
import {ThemeType} from "../shared";
import BaseTicker from "./BaseTicker";
import Typography from "./Typography";

interface TimeZoneTickerProps {
    theme: ThemeType;
    duration?: number;
    reverse?: boolean;
}

export default function TimeZoneTicker({theme, duration = 40, reverse = false }: TimeZoneTickerProps) {
    const color = theme === 'dark' ? 'white' : 'black';
    const START_AT = -10;
    const LENGTH = 21;

    const numbers = useMemo(() => {
        return [...new Array(LENGTH)].map((a, i) => i + START_AT);
    }, []);

    return (
        <div style={{height: '100%', flex: 1, paddingRight: 28}}>
            <BaseTicker rotation={'90deg'} duration={duration} direction={reverse ? 'forwards' : 'backwards'}>
                <div
                    style={{
                        width: '100%',
                        height: 19,
                        display: 'flex',
                        justifyContent: 'space-around'
                    }}
                >
                    {
                        numbers.map((a) => (
                            <div key={a} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <Typography  size={11} color={color} variant={'jordan'} align={'center'}>
                                    UTC
                                </Typography>
                                <Typography size={11} color={color} variant={'jordan'} align={'center'}>
                                    {a >= 0 ? '+' : '-'}{a.toString().replace('-', '').padStart(2, '0')}
                                </Typography>
                            </div>
                        ))
                    }
                </div>
            </BaseTicker>
        </div>
    )
}