import React, { useMemo, useRef, useEffect } from 'react';
import { SVGResult } from 'three/examples/jsm/loaders/SVGLoader';
import * as THREE from 'three';
  
const SvgShape = ({ shape, color }) => {
  const meshRef = useRef<THREE.Mesh>();

  useEffect(() => {
    let middle = new THREE.Vector3();
    const mesh = meshRef.current;
    let geometry =
      mesh instanceof THREE.Mesh || (mesh as THREE.Mesh).isMesh
        ? mesh.geometry
        : mesh;
    geometry.computeBoundingBox();
    geometry.boundingBox.getSize(middle);
    middle.x = middle.x / 2;
    middle.y = middle.y / 2;
    mesh.translateX(-middle.x);
    mesh.translateY(-middle.y);
  }, [shape]);

  return (
    <mesh ref={meshRef}>
      <meshBasicMaterial
        attach="material"
        color={color}
        side={THREE.DoubleSide}
      />
      <shapeGeometry attach="geometry" args={[shape]} />
    </mesh>
  );
};

const RotatedSvg = React.memo(
  ({
    x,
    y,
    scale,
    color,
    angle,
    svg
  }: {
    x: number;
    y: number;
    scale: number;
    color: number | string;
    radius: number;
    angle: number;
    svg: SVGResult;
  }) => {
    const meshGroup = useRef<THREE.Group>();
    const shapes = useMemo(
      () =>
        svg.paths.flatMap((path, index) =>
          path
            .toShapes(true)
            .map(shape => ({ index, shape, color: new THREE.Color(color) })),
        ),
      [color, svg.paths],
    );

    return (
      <group
        key={`svg-${x}`}
        ref={meshGroup}
        rotation-z={angle}
        position={[x, y, 0]}
        scale={[scale / 1.2, -scale  / 1.2, 1]}
      >
        {shapes.map((props, key) => (
          <SvgShape key={key} {...props} />
        ))}
      </group>
    );
  },
);

export default RotatedSvg;
