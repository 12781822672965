import React, { useContext } from "react";

import Context from "./Codification.context";
import CodificationCharacter from "./CodificationCharacter";
import CodificationSpace from "./CodificationSpace";


export default function CodificationWord({
    characters,
    index,
    isLastWord,
}: {
    characters: string[],
    index: number,
    isLastWord?: boolean,
}) {
    const { text, words } = useContext(Context);
    const startIndex = words
        .slice(0, index)
        .reduce((total, item) => (total + item.length), 0);

    return (<>
        <span className="hoi-codification__word">
            {characters.map((character, characterIndex) => {
                const totalIndex = startIndex + characterIndex;
                const key = `codification-character-${text}-${totalIndex}`;
                return character === " "
                    ? <CodificationSpace key={key} />
                    : <CodificationCharacter
                        key={key}
                        character={character}
                        index={totalIndex}
                    />;
            })}
        </span>
        {!isLastWord ? <CodificationSpace /> : null}
    </>);
}
