if (module.hot) {
    module.hot.accept(() => {
      location.reload()
    })
  }

  import {
    Texture
  } from 'three';
  import gsap from 'gsap';
  import UniversalLiveTemplate from '/src/touchpoints/live/lib/js/UniversalLiveTemplate';
  import RangeFinderBlink from '/src/touchpoints/live/lib/js/animations/RangeFinderBlink';
  import Css3DRotation from '../../../lib/js/animations/Css3DRotation';
  import vert from '/src/touchpoints/live/lib/glsl/shader.vert';
  import frag from '../programing-messaging.frag';

  class Logo extends UniversalLiveTemplate {
    constructor (datas, duration, lang) {
      super(`${datas.type}-${datas.index}`);
      this.duration = duration;
      this.datas = datas;
      this.centeredLogos = [];
      this.timeline = gsap.timeline({ paused: true, repeat: 0 });
      this.setupTimeline();
      this.createVisualLayer({ frag, vert });
      this.renderElements();
      this.generateAnimations();
    }
    
    renderElements() {
      let logoCollectionChapter = [];
      this.rangeFinder = this.addNode({type: 'rangeFinder', freeflow: true, content: {
        size: '55vh',
        maxSize: '500px',
        stroke: 2.5
      }});
      this.setViewfinderMarks(80, 2.5);
      this.datas.assets?.forEach((logo) => {
          logoCollectionChapter.push(this.generateLogo(logo)); 
      })
      this.centeredLogos.push(logoCollectionChapter);
    }

    generateLogo(logo) {
        return this.addNode({type: 'centeredLogo', freeflow: true, content: {
          src: logo?.url
        }}); 
    }

    generateAnimations() {
      let logoChapterCount = 0;
      RangeFinderBlink({
        rangeFinder: this.rangeFinder,
        markers: this.getAllInOverlay('.viewfinder-mark'),
        timeline: this.timeline
      })

    
      if(this.datas.assets?.length > 0) {
        Css3DRotation({ 
          logos: this.centeredLogos[logoChapterCount],
          timeline:  this.timeline
        })
        logoChapterCount+= 1;
      }
    }

    populateTimeline () {
     this.tl.add(this.stop.bind(this), this.duration);
    }
    
    preStart () {
      this.wrapper.classList.add('active');
      this.timeline.restart();
      this.timeline.play();
    }
    preStop () {
      this.wrapper.classList.remove('active');
      this.timeline.pause();
      this.timeline.restart();
    }
    
  }
  export default Logo;
  