import React, { useEffect } from 'react';
import { DirectoryHub } from './views/DirectoryHub';
import { ServicesHub } from './views/ServicesHub';
import { ExperiencesHub } from './views/ExperiencesHub';
import * as Sentry from '@sentry/browser';
import '../../../../init';
import '../../styles/main.scss';
import './styles/hub.scss';

// Hyperlive Utils
import * as Hyperlive from '../../hyperlive/utils.js';

const HUB_ANIMATIONS = {
	SERVICES: 'service',
	DIRECTORY: 'directory',
	EXPERIENCES: 'experiences',
};

const HUB_TEMPLATES = {
	[HUB_ANIMATIONS.SERVICES]: ServicesHub,
	[HUB_ANIMATIONS.DIRECTORY]: DirectoryHub,
	[HUB_ANIMATIONS.EXPERIENCES]: ExperiencesHub,
};

let json_data = Hyperlive.fetchJSON(0);

console.log('HYPERLIVE', json_data);

const { name } = json_data;

console.log('NAME', name);

const Hub = () => {
	let HubComponent = HUB_TEMPLATES[HUB_ANIMATIONS.SERVICES];
	let tag = 'SERVICES';

	switch (name) {
		// case HUB_ANIMATIONS.SERVICES:
		// 	HubComponent = HUB_TEMPLATES[HUB_ANIMATIONS.SERVICES];
		// 	tag = 'SERVICES';
		// 	break;
		case HUB_ANIMATIONS.EXPERIENCES:
		case HUB_ANIMATIONS.SERVICES:
			HubComponent = HUB_TEMPLATES[HUB_ANIMATIONS.EXPERIENCES];
			tag = 'EXPERIENCES';
			break;
		default:
		case HUB_ANIMATIONS.DIRECTORY:
			HubComponent = HUB_TEMPLATES[HUB_ANIMATIONS.DIRECTORY];
			tag = 'DIRECTORY';
			break;
	}

	Sentry.setTag('hub_type', tag);

	return <HubComponent data={json_data} />;
};

export default Hub;
