import {create} from "zustand";
import {NbyDataType, StepType} from "./types";
import {wait} from "./shared";

export interface NbyState {
    currentIndex: number;
    animationData: NbyDataType | null;

    initialize: (data: NbyDataType) => void;
    play: () => Promise<void>;

    stepDuration: number;
    playSteps: () => Promise<void>;
    getCurrentStep: () => StepType | null;

    playPricing: () => Promise<void>;
}

export const useNbyStore = create<NbyState>()((set, get) => ({
    animationData: null,
    currentIndex: 2,

    initialize: (data) => {
        set({animationData: data});
    },
    play: async () => {
        const variant = get().animationData?.variant;
        if (variant === "pricing") {
            return await get().playPricing();
        }

        return await get().playSteps();
    },

    stepDuration: 30_000,
    playSteps: async () => {
        return;
        const STEP_DURATION = get().stepDuration;

        const steps = get().animationData?.steps;

        for (let i = 0; i < steps?.length; i++) {
            set({currentIndex: i});
            await wait(STEP_DURATION);
        }

        await get().playSteps();
    },
    getCurrentStep: () => {
        const steps = get().animationData?.steps;
        const currentIndex = get().currentIndex;

        return currentIndex === null ? null : steps?.[currentIndex];
    },

    playPricing: async () => {
        // don't do anything man
    }
}));