import {create} from "zustand";
import {Chapter, pillarColors, pillars} from "../../shared";

interface AppState {
    chapters: Array<Chapter>;
    chapterIndex: number | null;

    initialize: (chapters: Array<Chapter>) => void;
    getChapter(activeChapter: any): Chapter | null;
    nextChapter: () => void;
}

export const useEcosystemBoardStore = create<AppState>()(
    (set, get) => ({
        chapters: [],
        chapterIndex: null,

        initialize: (chapters: Array<Chapter>) => {
            set({chapters, chapterIndex: 0});
        },
        getChapter: (activeChapter: any) => {
            const chapter = get().chapters?.[activeChapter % get().chapters.length];

            return {
                ...chapter,
                pillar_color: pillarColors?.find(({name}) => name === chapter?.pillar) ?? pillarColors[0],
            }
        },
        nextChapter: () => {
            set({chapterIndex: get().chapterIndex! + 1});
        }
    })
);
