// React
import React from 'react';
import ReactDOM from 'react-dom/client';

// Hyperlive Utils
import * as Hyperlive from '../../hyperlive/utils.js';

// Globals
import '../../../../init';
import '../../styles/main.scss';

// Grid
import { GridRunner } from 'rise-grid-framework';
import * as GridUtils from '../../risegrid/utils';

// Template
import Pod from './Pod';

///////////////////////////////////////////////////////////
// Import Grid JSONs
import intro from './grids/intro.js';
import main from './grids/main.js';
import FPOAssetsVideo from '../../data/gridassets_video.js';
import { GridComposer, GRID_SNIPPETS } from '../../risegrid/GridComposer.js';


let holdFramesMain = [
  { frame: "MainA", hold: 0 },
  { frame: "MainB", hold: 3000 },
  { frame: "MainC", hold: 3000 },
  { frame: "MainD", hold: 3000 },
];

let holdFramesIntro = [
  { frame: 'IntroStart', hold: 0 },
]

///////////////////////////////////////////////////////////
// COMPOSE GRID

// TIME REMAPPING
// If needed, remap frame or wait timing (multiplies current by scale)
// sequence = GridUtils.timeRemap(sequence, .2);
// waitFrames = GridUtils.timeRemap(waitFrames, 0);


let Grid = new GridComposer({
  sequences: [intro, main],
  holdFrames: [holdFramesIntro, holdFramesMain]
});


///////////////////////////////////////////////////////////
// Hyperlive JSON Data
console.log('ANIMATIONS', window.animations)

const { json_data } = Hyperlive.fetchJSON();

console.log('JSON', json_data);

const assets = [Hyperlive.mapTransformedAssets(
  json_data.assets,
  [
    {
      tag: 'background',
      prefix: 'Intro'
    },
    {
      tag: 'product',
      prefix: 'Grid'
    },
    {
      tag: 'logo',
      prefix: 'Logo'
    },
  ],
  'w_1080'
)];

//TESTING
// const assets = FPOAssetsVideo;

const grids = new GridRunner({
  grids: [1],
  assets: assets,
  sequence: Grid.sequence,
  waitFrames: Grid.wait,
  canvasWidth: 1080,
  canvasHeight: 1920,
  gridWidth: 1080,
  gridHeight: 1920,
  backgroundColor: 0xFFFFFF,
  lineColor: 100,
  lineThickness: 1,
  antialias: false,
  languageCount: Hyperlive.getStoreLanguages().length,
});

document.body.appendChild(grids.app.view);


(async () => {

  await grids.loadAssets();

  grids.run(true);

  console.log('RENDER')

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <Pod grids={grids} key={'1'} json_data={json_data} assets={assets} />
  );

})();






