import gsap from 'gsap';
import { ScrambleTextPlugin } from '/src/touchpoints/live/lib/js/vendors/ScrambleTextPlugin.min.js';

gsap.registerPlugin(ScrambleTextPlugin);


export default function createTL (scenes, onComplete, onStart, credits=[], isWide, timingData) {
  console.log(timingData)
  const tl = gsap.timeline({
    onComplete,
    paused: true,
    onStart: onStart,
    defaults: { ease: 'power2.inOut' }
  });

  const uniforms = scenes.map(scene => scene.userData.shader.uniforms);

  const ovl = '.ca-closeup .overlay';
  const ffi = '.ca-closeup .freeflow-item';
  const swi = '.ca-closeup .swatch-item';

  const w = window.innerWidth;
  const h = window.innerHeight;

  // INITIAL STATES
  tl.add(() => {

    gsap.set(uniforms[0].mosaic, { value: 0 });
    gsap.set(uniforms[1].mosaic, { value: 0 });

    gsap.set(uniforms[0].pixels, { value: 60 });
    gsap.set(uniforms[1].pixels, { value: 60 });

    gsap.set(swi, { top: -50, opacity: 0 });

    gsap.set(ovl, { opacity: 0, scale: 0.8 });

    console.log('CA CLOSEUP');
  }, 0);

  // ANIMATIONS

  tl.to(ovl, { scale: 1, duration: 1 }, 0.1);
  tl.to(ovl, { opacity: 1, duration: 0.5, ease: 'steps(1)' }, 0.1);

  tl.to(swi, { top: 0, opacity: 1, stagger: 0.1, duration: 0.5, ease: 'expo.in' }, 0.5);

  tl.to(uniforms[0].mosaic, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.1);
  tl.to(uniforms[0].pixels, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.1);




  function animateCredits (selector, credit, position) {
    tl.to(`${selector} .vt-${position} h2`, {
      duration: 1,
      opacity: 1,
      scrambleText: {
        text: credit || ' ',
        chars: '             nike             ',
      }
    }, 1);
  }

  animateCredits(ovl, credits[0], 'top');
  animateCredits(ovl, credits[1], 'bottom');

  if(!isWide) {
    tl.to(uniforms[1].mosaic, { value: 1, duration: 1, ease: 'power0.easeNone' }, 2.0);
    tl.to(uniforms[1].pixels, { value: 1, duration: 1, ease: 'power0.easeNone' }, 2.0);
  } else {
    let timings = [];
    timingData.filteredAssets.forEach((asset, index) => {
      if(timingData.filteredAssets % 2 == 0 || index !== (timingData.filteredAssets.length - 1)) {
        if(index % 2 == 0) {
          timings.push(timingData.timePerAsset - 1)
        } else {
          timings.push(timingData.timePerAsset + 1)
        }
      } else {
        timings.push(timingData.timePerAsset)
        
      }
    })

    function getPreviusTime(index) {
      let sum = timings.map((elem, index) => timings.slice(0,index + 1).reduce((a, b) => a + b));
      return sum[index - 1] ? sum[index - 1] : 0
    }
    
    timingData.filteredAssets.forEach((asset, index) =>  {
      let assetTime = timingData.iframeIndex % 2 == 0 ? timingData.timePerAsset * (index) : index == 0 ? 0 : getPreviusTime(index);
      tl.to(uniforms[0].mosaic, { value: 0, duration: 0, onComplete:() => {
        timingData.updateTex(timingData.parent, index);
      }}, (index == 0 ? 0 : assetTime - 0.3));
      tl.to(uniforms[0].pixels, { value: 0, duration: 0}, assetTime - 0.3);
      tl.to(uniforms[0].mosaic, { value: 1, duration: 1, ease: 'power0.easeNone' }, assetTime);
      tl.to(uniforms[0].pixels, { value: 1, duration: 1, ease: 'power0.easeNone' },  assetTime);
    })

    tl.to(uniforms[1].mosaic, { value: 1, duration: 1, ease: 'power0.easeNone' },  timingData.timePerAsset * (timingData.filteredAssets.length));
    tl.to(uniforms[1].pixels, { value: 1, duration: 1, ease: 'power0.easeNone' },  timingData.timePerAsset * (timingData.filteredAssets.length));
  }

  tl.add(() => {}, isWide ? timingData.totalTime : 8);

  return tl;
}
