import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import "./NBYPricing.scss";
import NBYMenu from "./components/NBYMenu/NBYMenu";
import * as Hyperlive from '../../hyperlive/utils.js';

const configData = Hyperlive.fetchChannelInfo()?.json_config;

const video = {
  id: 28,
  assetId: "undefined/1570559376438",
  subType: "video",
  assetUrl:
    "https://res.cloudinary.com/jbboerner/video/upload/v1667160492/rise-nby/nby-pricing.mp4",
  duration: 5000,
  previewUrl:
    "https://res.cloudinary.com/jbboerner/video/upload/v1667160492/rise-nby/nby-pricing.mp4"
};

const DURATION = configData?.duration ? Number(configData.duration) * 1000 : 6_000;

// SPORTPULSE PREVIEW OVERRIDES
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

let PREVIEW_CHAPTER = urlParams.get('chapter');
if (PREVIEW_CHAPTER === "null") { PREVIEW_CHAPTER = null }
if (PREVIEW_CHAPTER) { console.warn('FORCE CHAPTER', PREVIEW_CHAPTER); }

const PREVIEW_LANGUAGE = urlParams.get('lang');
if (PREVIEW_LANGUAGE) { console.warn('FORCE LANGUAGE', PREVIEW_LANGUAGE); }

export default function NBYPricing({ data }) {
  const [menu, setMenu] = useState(true);
  const videoRef = useRef(null);
  const { chapters } = data;

  const chapter_length = chapters ? chapters.length : 0;

  const onVideoEnd = () => {
    setMenu(true);
    setTimeout(() => {
      setMenu(false);
      videoRef.current.play();
    }, chapter_length * DURATION);
  }

  return (
    <div className={'NBYPricing'}>

      <div className={'NBYPricing-video'}>
        <video
          ref={videoRef}
          autoPlay={true}
          muted={true}
          controls={false}
          loop={false}
          onEnded={onVideoEnd}
        >
          <source src={video.assetUrl} type="video/mp4"></source>
        </video>
      </div>

      <AnimatePresence>
        {
          menu && chapters && <NBYMenu chapters={chapters} duration={DURATION} preview={PREVIEW_CHAPTER} />
        }
      </AnimatePresence>

    </div>
  );
}
