import QrSection from "./QrSection";
import React from "react";

export default function WidescreenQrSection({qr_code}) {
    return (
        <div
            style={{
                position: 'absolute',
                bottom: 40,
                left: 140,
            }}
        >
            <QrSection
                theme={'dark'}
                qrCode={qr_code}
                preventDefaults
            />
        </div>
    )
}