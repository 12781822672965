import { useFrame } from '@react-three/fiber';
import { storeImageBlob } from "../storage";
import {isCanvasBlank} from "../shared";
  
export default function CacheRenderedCanvasToImage(props) {
    useFrame(({gl}) => {
      	if (isCanvasBlank(gl.domElement)) {
        	return;
    	}
        storeImageBlob(gl.domElement, props.name);
    });
    return <>{props.children}</>;
}


