import {Codification} from "../../../../rise/components";
import React from "react";

interface TypographyProps {
    text: string;
    reverse?: boolean;
    start?: boolean;
    primary?: boolean;
    codificationSpeed?: 'fast' | 'normal';
}

export default function Typography({text, primary = true, reverse = false, start = true, codificationSpeed}: TypographyProps) {
    // ignore typescript errors from this point on
    const Component = Codification as any;

    const characterTimeout = codificationSpeed === 'fast' ? 8 : 20;

    return (
        <Component
            text={text}
            typography={{primary, tag: "span"}}
            characterSwitchAmount={4}
            characterNextTrigger={2}
            characterTimeout={characterTimeout}
            start={start}
            reverse={reverse}
        />
    )
}