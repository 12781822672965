import * as THREE from 'three';
import { Effect } from 'postprocessing';
import { forwardRef, useMemo } from 'react';


const fragmentShader = `
  uniform sampler2D uTexture;
  uniform sampler2D tDiffuse;

  void mainImage(const in vec4 inputColor, const in vec2 uv, out vec4 outputColor) {
    float time_e = time * 0.1;

    vec2 uv_t = vec2(uv.s + .25, uv.t / 2.0);
    vec4 displace = texture2D(uTexture, uv_t);
    float displace_k  = displace.g * .02;
    float displace_j  = displace.g * .05;
    vec2 uv_displaced = vec2(uv.x - displace_k, uv.y + displace_j);

    outputColor = texture2D (tDiffuse, uv_displaced);
  }
`;

export class GlitchEffectImpl extends Effect {
	constructor(props) {
		super('GlitchEffect', fragmentShader, {
			uniforms: new Map([
				['uTexture', new THREE.Uniform(props.texture)],
			]),
		});
	}
}

// Effect component
export const GlitchEffect = forwardRef((props: {texture: THREE.Texture |  THREE.Texture[]}, ref) => {
	const effect = useMemo(() => new GlitchEffectImpl(props), [props]);
	return <primitive ref={ref} object={effect} dispose={null} />;
});
