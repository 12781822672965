[
  {
    "circle": [],
    "delayHorizontal": true,
    "delayVertical": false,
    "drawBorder": true,
    "duration": "1000",
    "horizontal": [
      {
        "drawDirection": 1,
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "endPosition": 12.5,
        "extend": false,
        "name": "Top",
        "startColor": 176,
        "startPosition": 12.5
      },
      {
        "drawDirection": 1,
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "extend": false,
        "name": "Top2",
        "startPosition": 25
      },
      {
        "drawDirection": 1,
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "extend": false,
        "name": "Top3",
        "startPosition": 37.5
      },
      {
        "drawDirection": 1,
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "extend": false,
        "name": "Equator",
        "startPosition": 50
      },
      {
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "name": "Bottom2",
        "startPosition": 62.5
      },
      {
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "name": "Bottom3",
        "startPosition": 75
      },
      {
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "name": "Bottom",
        "startPosition": 87.5
      }
    ],
    "horizontalDelayDirection": 1,
    "id": 0,
    "insetBorder": false,
    "mask": [
      {
        "polyOnly": true,
        "grid": 0,
        "horizontal": [
          "Bottom2",
          "Bottom"
        ],
        "name": "Feature",
        "vertical": [
          "SecondaryLeft",
          "SecondaryRight"
        ]
      },
      {
        "polyOnly": true,
        "grid": 0,
        "horizontal": [
          "Equator",
          "Bottom2"
        ],
        "name": "AppRight",
        "vertical": [
          "SecondaryRight",
          "PrimaryRight"
        ]
      },
      {
        "polyOnly": true,
        "grid": 0,
        "horizontal": [
          "Equator",
          "Bottom2"
        ],
        "name": "AppLeft",
        "vertical": [
          "PrimaryLeft",
          "SecondaryLeft"
        ]
      },
      {
        "grid": 0,
        "polyOnly": true,
        "horizontal": [
          "Bottom2",
          "Bottom3"
        ],
        "name": "AppLeftBottom",
        "vertical": [
          "PrimaryLeft",
          "SecondaryLeft"
        ]
      },
      {
        "grid": 0,
        "polyOnly": true,
        "horizontal": [
          "Bottom2",
          "Bottom3"
        ],
        "name": "AppRightBottom",
        "vertical": [
          "SecondaryRight",
          "PrimaryRight"
        ]
      },
      {
        "polyOnly": true,
        "grid": 0,
        "horizontal": [
          "Top2",
          "Equator"
        ],
        "name": "TextUpper",
        "vertical": [
          "PrimaryLeft",
          "SecondaryRight"
        ]
      },
      {
        "polyOnly": true,
        "grid": 0,
        "horizontal": [
          "Top3",
          "Equator"
        ],
        "name": "TextLower",
        "vertical": [
          "PrimaryLeft",
          "SecondaryRight"
        ]
      }
    ],
    "name": "ChapterStart",
    "startColor": 160,
    "vertical": [
      {
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "name": "Equator",
        "startPosition": 50
      },
      {
        "drawDirection": 1,
        "drawFromLine": "Bottom Border",
        "drawFull": true,
        "drawToLine": "Top Border",
        "extend": false,
        "name": "SecondaryRight",
        "startPosition": 72.2
      },
      {
        "drawDirection": 1,
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "extend": false,
        "name": "PrimaryRight",
        "startPosition": 94.4
      },
      {
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "name": "PrimaryLeft",
        "startPosition": 5.6
      },
      {
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "name": "SecondaryLeft",
        "startPosition": 27.8
      }
    ]
  },
  {
    "circle": [],
    "delayHorizontal": true,
    "delayVertical": false,
    "drawBorder": true,
    "duration": "1000",
    "horizontal": [
      {
        "drawDirection": 1,
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "endPosition": 12.5,
        "extend": false,
        "name": "Top",
        "startColor": 176,
        "startPosition": 12.5
      },
      {
        "drawDirection": 1,
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "extend": false,
        "name": "Top2",
        "startPosition": 25
      },
      {
        "drawDirection": 1,
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "extend": false,
        "name": "Top3",
        "startPosition": 37.5
      },
      {
        "drawDirection": 1,
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "extend": false,
        "name": "Equator",
        "startPosition": 50
      },
      {
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "name": "Bottom2",
        "startPosition": 62.5
      },
      {
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "name": "Bottom3",
        "startPosition": 75
      },
      {
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "name": "Bottom",
        "startPosition": 87.5
      }
    ],
    "horizontalDelayDirection": 1,
    "id": 0,
    "insetBorder": false,
    "mask": [],
    "name": "ChapterEnd",
    "startColor": 160,
    "vertical": [
      {
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "name": "Equator",
        "startPosition": 50
      },
      {
        "drawDirection": 1,
        "drawFromLine": "Bottom Border",
        "drawFull": true,
        "drawToLine": "Top Border",
        "extend": false,
        "name": "SecondaryRight",
        "startPosition": 72.2
      },
      {
        "drawDirection": 1,
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "extend": false,
        "name": "PrimaryRight",
        "startPosition": 94.4
      },
      {
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "name": "PrimaryLeft",
        "startPosition": 5.6
      },
      {
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "name": "SecondaryLeft",
        "startPosition": 27.8
      }
    ]
  }
]