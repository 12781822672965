import React, {useEffect} from "react";
import LeftGrid from "../components/left-grid";
import MainView from "../components/main-view";
import StepsRight from "../components/steps-right";
import StepCounter from "../components/step-counter";
import Split from "../components/split";
import {useRpx} from "../shared";
import {useNbyStore} from "../store";
import Typography from "../components/typography";

export default function Steps() {
    const rpx = useRpx();
    const currentStep = useNbyStore(state => state.getCurrentStep());
    const currentIndex = useNbyStore(state => state.currentIndex);
    const stepDuration = useNbyStore(state => state.stepDuration);
    const [reverseType, setReverseType] = React.useState<boolean>(false);

    useEffect(() => {
        setReverseType(false);
        setTimeout(() => {
            setReverseType(true);
        }, stepDuration - 1000);
    }, [currentIndex]);

    if (!currentStep) return null;

    return (
        <MainView
            left={(
                <LeftGrid
                    content={(
                        <Split
                            left={(<StepCounter step={currentStep.number}/>)}
                            right={(
                                <div
                                    style={{
                                        fontSize: rpx(125),
                                        marginLeft: rpx(50),
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                    }}
                                >
                                    <Typography
                                        key={currentStep.title}
                                        text={currentStep.title}
                                        reverse={reverseType}
                                    />
                                </div>
                            )}
                        />
                    )}
                    description={(
                        <div
                            style={{
                                fontSize: rpx(48),
                                margin: rpx(50),
                                marginRight: rpx(20),
                            }}
                        >
                            <Typography
                                key={currentStep.description}
                                primary={false}
                                text={currentStep.description}
                                reverse={reverseType}
                                codificationSpeed={'fast'}
                            />
                        </div>
                    )}
                />
            )}
            right={(
                <StepsRight/>
            )}
        />
    )
}