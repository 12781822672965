import '../../../../../init';
import React, {useEffect, useState} from "react";
import ReactDOM from 'react-dom/client';
import {getKidsTakeover} from "../shared";
import FastLaneTickers from "./FastLaneTickers";
import {DEFAULT_FASTLANE_CONFIG} from "../../fastlaneticker/FastlaneTicker";
import {pixelMapReady} from "../../../../../utilities";

function Preview() {
  const [playIndex, setPlayIndex] = useState(0);

  useEffect(() => {
    pixelMapReady(() => {
      setPlayIndex(playIndex + 1);
    });
  }, []);


  return (
    <FastLaneTickers
      key={playIndex}
      data={getKidsTakeover()}
      onComplete={() => {
        setPlayIndex(playIndex + 1)
      }}
      info={{
        trackLength: 1_200,
        trackHeight: DEFAULT_FASTLANE_CONFIG.height,
        renderWidth: 1_200,
      }}
    />
  )
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Preview/>);