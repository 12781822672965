export default function InlineArrow({}) {
    return (
        <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_984_7441)">
                <path d="M-3.26656e-07 7.42973L-2.63617e-07 5.98757L10.4447 5.98757C12.0617 5.98757 13.1979 6.38089 14.7712 6.38089L14.7712 6.29349C11.6684 4.67652 10.3136 2.84104 9.26478 1.09297L10.4884 -0.0432742C12.0617 2.75364 13.8535 4.76392 17 6.29349L17 7.08012C13.8535 8.60968 12.0617 10.6637 10.4884 13.4169L9.26478 12.2369C10.3136 10.5326 11.6684 8.74079 14.7712 7.08012L14.7712 6.99272C13.1979 6.99272 12.0617 7.38603 10.4447 7.38603L-3.24745e-07 7.38603L-3.26656e-07 7.42973Z" fill="currentColor"/>
            </g>
            <defs>
                <clipPath id="clip0_984_7441">
                    <rect width="13.4165" height="17" fill="white" transform="translate(17) rotate(90)"/>
                </clipPath>
            </defs>
        </svg>
    );
}