import React, { useEffect, useState } from 'react';
import './GridCard.scss';
import { motion } from 'framer-motion';
import { NIKE_EASE } from '../../constants/nikerise';
import * as Logger from '../../utils/console';
import * as GridUtils from '../../risegrid/utils';

const CONFIG = {
	Top: {
		START: '-102%',
		END: 0,
	},
	Right: {
		START: '102%',
		END: 0,
	},
	Bottom: {
		START: '102%',
		END: 0,
	},
	Left: {
		START: '-102%',
		END: 0,
	},
	START: '-102%',
	END: 0,
	DELAY: 0,
};

export const GridCard = ({
	grids,
	frame,
	children,
	className,
	keyframes,
	startframes,
	mask,
	autoReveal,
	fade,
	frameStatus,
	delay,
}) => {
	const { frameIndex, frameName, frameId, frameStart } = frame;

	const [dims, setDims] = useState({
		width: 0,
		height: 0,
		left: 0,
		top: 0,
	});

	const [start, setStart] = useState(false);

	const [visible, setVisible] = useState(false);

	const updateMask = () => {
		const maskBounds = grids.masks[0].getMaskBounds(mask);
		Logger.alert('MB');
		Logger.log('MB', maskBounds);
		if (maskBounds) {
			setDims({
				top: maskBounds.y,
				left: maskBounds.x,
				width: maskBounds.width,
				height: maskBounds.height,
			});
		}
	};

	useEffect(() => {
		// Logger.highlight(`GRIDCARD`, mask);
		// Logger.log(keyframes);

		// updateMask();

		// grids.masks[0].addCallback(mask, params => {
		//     setDims({
		//         top: params.y,
		//         left: params.x,
		//         width: params.width,
		//         height: params.height,
		//     });
		// });
	}, []);

	useEffect(() => {
		// Logger.highlight(`FRAMENAME ${frameName}`, frameName);
		setVisible(GridUtils.withinKeyframe(keyframes, frameName));

		// setStart(frameStart)
		setStart(GridUtils.withinKeyframe(startframes, frameName));

		updateMask();
	}, [frame, children]);

	// Logger.alert(`MB ${visible}`);
	const initial_X = CONFIG.hasOwnProperty(autoReveal)
		? CONFIG[autoReveal].START
		: CONFIG.START;
	const animate_X = start
		? CONFIG.hasOwnProperty(autoReveal)
			? CONFIG[autoReveal].END
			: CONFIG.END
		: initial_X;

	const style = { ...dims, overflow: 'hidden', position: 'absolute' };
	style.width = style.width - 4;
	style.height = style.height - 4;
	style.margin = '2px';
	const initial = {
		translateX: initial_X,
		// width: dims.width - 4,
		// height: dims.height - 4,
		// margin: '2px'
	};

	const animate = {
		translateX: animate_X,
		// width: dims.width - 4,
		// height: dims.height - 4,
		// margin: '2px'
	};

	const transition = {
		ease: NIKE_EASE,
		duration: 1,
		delay: start ? delay : 0,
	};

	return (
		<div className={`GridCard ${className}`} style={style}>
			{visible && (
				<motion.div
					className={`GridCard-motion `}
					style={{ position: 'absolute' }}
					initial={initial}
					animate={animate}
					transition={transition}
				>
					{React.Children.map(children, child => {
						if (React.isValidElement(child)) {
							return React.cloneElement(child, {
								start: start,
								injected: true,
								test: 'test',
							});
						}
						return child;
					})}
				</motion.div>
			)}
		</div>
	);
};

export default GridCard;
