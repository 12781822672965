import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { transformImage } from '../../../../utils/image';
import Codification from '../../../../components/Codification';
import './MediaBlock.scss';
import { NIKE_EASE } from '../../../../constants/nikerise';


export const BLOCK_TRANSITIONS = {
    Top: 'top',
    Left: 'left',
    Right: 'right',
    Fade: 'fade',
    Scale: 'scale',
}

const DEFAULT_TRANSITION = { ease: NIKE_EASE, duration: 2 }

const TRANSITION_PRESETS = {
    entry: BLOCK_TRANSITIONS.Top,
    exit: BLOCK_TRANSITIONS.Top,
    coverFill: true,
}



const MediaBlock = ({ width, height, top, left, media, delay, duration = 3, inset = false, entry = BLOCK_TRANSITIONS.Top, exit = BLOCK_TRANSITIONS.Top, steps, transition = { ...TRANSITION_PRESETS } }) => {

    if (media === null) {
        return <div />;
    }

    const { resource_type, subType, url } = media

    const [dims, setDims] = useState({
        width: width ? width : '100px',
        height: height ? height : '100px',
        left: left ? left : 0,
        top: top ? top : 0,
        initial: true,
    });

    const animation = useAnimation();
    const initial = {};

    switch (entry) {
        case BLOCK_TRANSITIONS.Top:
            initial.height = '0%';
            initial.opacity = 1;
            initial.left = 0;
            break;
        case BLOCK_TRANSITIONS.Left:
            initial.height = '100%';
            initial.left = '-100%'
            initial.opacity = 1;
            break;
        case BLOCK_TRANSITIONS.Fade:
            initial.height = '100%';
            initial.left = '0';
            initial.opacity = 0;
            break;
    }


    useEffect(() => {
        sequence();
    }, [media]);

    async function sequence() {

        switch (entry) {
            case BLOCK_TRANSITIONS.Top:
                await animation.start({ height: '100%', transition: { ...DEFAULT_TRANSITION, duration: 1, delay: delay } })
                break;
            case BLOCK_TRANSITIONS.Left:
                await animation.start({ left: '0%', transition: { ...DEFAULT_TRANSITION, duration: 1, delay: delay } })
                break;
            case BLOCK_TRANSITIONS.Right:
                await animation.start({ left: '100%', transition: { ...DEFAULT_TRANSITION, duration: 1, delay: delay } })
                break;
            case BLOCK_TRANSITIONS.Fade:
                await animation.start({ opacity: '100%', transition: { ...DEFAULT_TRANSITION, duration: 1, delay: delay } })
                break;

        }

        // console.log('STEPS', steps)

        if (steps) {

            let anim = {}
            for (const step in steps) {
                anim[step] = steps[step];
            }

            await animation.start({ ...anim, transition: { duration: 1, delay: 1 } })
            await animation.start({ scale: 1, transition: { duration: duration, delay: 0 } })

        } else {
            await animation.start({ scale: 1.1, transition: { duration: duration, delay: delay } })
        }

        switch (exit) {
            case BLOCK_TRANSITIONS.Top:
                await animation.start({ height: '0%', transition: { ...DEFAULT_TRANSITION, duration: 1, delay: 2 - delay } })
                break;
            case BLOCK_TRANSITIONS.Left:
                await animation.start({ left: '-100%', transition: { ...DEFAULT_TRANSITION, duration: 1, delay: 2 - delay } })
                break;
            case BLOCK_TRANSITIONS.Right:
                await animation.start({ left: '100%', transition: { ...DEFAULT_TRANSITION, duration: 1, delay: 2 - delay } })
                break;
            case BLOCK_TRANSITIONS.Fade:
                await animation.start({ opacity: 0, transition: { ...DEFAULT_TRANSITION, duration: 1, delay: 2 - delay } })
                break;

        }

        animation.start({ scale: 1 })
    }

    return <div className={`MediaBlock inset-${inset}`} style={{ ...dims, overflow: 'hidden', position: 'absolute' }}>

        <motion.div
            style={{
                width: "100%",
                height: "100%",
                overflow: 'hidden',
                position: 'absolute',
                opacity: 1,
            }}
            initial={initial}
            animate={animation}
            transition={{ ease: NIKE_EASE, duration: 2 }}
        >
            {/* <div className={`inset-${inset}`}> */}
            {
                resource_type === 'video' && url &&
                <video autoPlay={true} muted={true} controls={false} loop={true}>
                    <source src={url} type="video/mp4"></source>
                </video>
            }

            {
                (resource_type === 'image' || subType === 'image') && url &&
                <img src={url} />
            }
            {/* </div> */}

        </motion.div>



    </div>

};

export default MediaBlock;
