const fontsIndexes = [
    ['NIKE_FUTURA', 'NeuePixelGrotesk', 'HelveticaNeue'],
    ['NeuePixelGrotesk', 'NIKE_FUTURA'],
    ['NeuePixelGrotesk', 'NIKE_FUTURA', 'HelveticaNeue']
  ]
  
  const fontSizesRatio = {
    NIKE_FUTURA: 0.943,
    NeuePixelGrotesk: 1,
    HelveticaNeue: 1
  }
  
  export default class Letter {
    constructor (letter, wordIndex, letterIndex, count, fontStyle, isChinese) {
      this.value = letter;
      this.wordIndex = wordIndex;
      this.letterIndex = letterIndex;
      const index = Math.min((count - 1), 2);
      this.fonts = fontsIndexes[index];
      this.fontIndex = this.wordIndex;
      this.fontStyle = fontStyle;
      this.isChinese = isChinese;
    }
  
    setBaseline (baseline) {
      this.baseline = baseline;
    }

     setChinese (isChinese) {
      this.isChinese = isChinese;
    }
  
    setSize (size) {
      this.size = size;
    }
  
    getFont () {
      return this.fonts[this.fontIndex % this.fonts.length];
    }
  
    draw (ctx, cursor) {
      if(this.isChinese && this.fontStyle === 'NIKE_FUTURA') {
        ctx.font = `${this.size * 0.97}px "CHINA_FUTURA"`;
        ctx.fillStyle = "#000000"; 
        ctx.fillText(this.value, cursor * 0.99, this.baseline);
      } else {
        ctx.font = `${this.size}px "${this.fontStyle}"`;
             
        ctx.fillStyle = "#000000"; 
        ctx.fillText(this.value, cursor, this.baseline);
      }

    }
  }
  