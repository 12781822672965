import React, {useState, useEffect, useMemo} from 'react';
import {
    calculateMediaDuration,
    getCoordinates, getForcedDuration,
    MediaType,
    stringFallback,
    stringIsFilled,
    ThemeType,
    timedMedia
} from "../shared";
import Transition from "../components/Transition";
import Background from "../components/Background";
import FamilyTicker from '../components/FamilyTicker';
import Line from '../components/Line';
import ChapterHeader from '../components/ChapterHeader';
import {getColor} from '../styles/color';
import Media from "../components/Media";
import Typography from "../components/Typography";
import Arrow from "../assets/icons/Arrow";
import QrSection, {QrCodeInterface} from "../components/QrSection";
import GlitchTypography from "../components/GlitchText/GlitchTypography";
import VerticalTicker, {TickerType} from "../components/VerticalTicker";
import classes from './Styles.module.scss';
import {JordanConfig} from "../components/ChapterRunner";

function hasForcedDuration(media: Array<MediaType>) {
    return media.some((m) => m?.force_duration);
}

export function calculateDuration({media, ticker}: ProductFramedProps, config: JordanConfig): undefined | number {
    const containsForcedDuration = hasForcedDuration(media);
    if (!containsForcedDuration) {
        return undefined;
    }

    return calculateMediaDuration(media);
}

export interface ProductFramedProps {
    title: string;
    subtitle: string;
    media: Array<MediaType>;
    theme: ThemeType;
    ticker?: TickerType;
    variant: ProductVariant;
    duration?: number;
    membership?: boolean;
    qr_code?: QrCodeInterface;
    product_copy?: string;
    product_subhead?: string;
    hide_footer?: boolean;
    custom_ticker?: MediaType;
}

type ProductVariant = 'framed' | 'full' | 'card';

const TICKER_INNER_FRAME_WIDTH = 860;

export default function Product({
                                    qr_code,
                                    media = [],
                                    title = '',
                                    ticker = 'altitude',
                                    subtitle = '',
                                    duration,
                                    membership = true,
                                    variant = 'card',
                                    product_subhead,
                                    product_copy,
                                    custom_ticker,
                                    hide_footer = false,
                                    ...other
                                }: ProductFramedProps) {
    const containsForcedDuration = useMemo(() => hasForcedDuration(media), [media]);

    const mediaTimed = useMemo(() => {
        if (containsForcedDuration) {
            return media.filter(i => i).map((m) => ({
                ...m,
                force_duration: getForcedDuration(m),
            }));
        }

        return timedMedia(media, duration)
    }, [media]);
    const theme = variant !== 'framed' ? 'dark' : other.theme
    const line = (
        <div style={{width: '80px', display: 'flex', justifyContent: 'center', padding: '36px 30px',}}>
            <Line theme={theme}/>
        </div>
    );


    return (
        <Transition variant={'wipe-x'} cover preventChildExit>
            <Background
                variant={theme === 'dark' ? 'dark-dust' : 'light-dust'}
                hide={variant !== 'framed'}
            >
                <div style={{width: '100%', height: '100%', display: 'flex'}}>
                    {line}

                    <div style={{flexGrow: 1, height: '100%'}}>
                        <CenterContent
                            title={title}
                            subtitle={subtitle}
                            theme={theme}
                            qr_code={qr_code}
                            media={mediaTimed}
                            variant={variant}
                            ticker={ticker}
                            membership={membership}
                            product_subhead={product_subhead}
                            product_copy={product_copy}
                            hide_footer={hide_footer}
                        />
                    </div>

                    <div style={{display: 'flex'}}>
                        {
                            !ticker && (
                                <div
                                    style={{padding: '40px 10px', paddingRight: ticker ? '10px' : '40px', height: '100%',}}>
                                    <Line theme={theme}/>
                                </div>
                            )
                        }


                        {
                            ticker && (
                                <VerticalTicker
                                    type={ticker}
                                    theme={theme}
                                    asset={custom_ticker}
                                />
                            )
                        }
                    </div>
                </div>
            </Background>
        </Transition>
    )
}

function CenterContent({
                           title,
                           qr_code,
                           subtitle,
                           theme,
                           media,
                           variant,
                           membership,
                           ticker,
                           product_copy,
                           product_subhead,
                           custom_ticker,
                           hide_footer = false
                       }: ProductFramedProps) {
    const color = theme === 'dark' ? 'white' : 'near-black';
    const coordinates = useMemo(() => getCoordinates(), []);

    const showArrows = useMemo(() => {
        return stringIsFilled(title) || stringIsFilled(subtitle) || stringIsFilled(product_subhead) || stringIsFilled(product_copy);
    }, [title, subtitle]);

    const isLongTitle = useMemo(() => {
        return title.includes('\n') || title.length > 17;
    }, [title]);

    const contentWidth = ticker ? TICKER_INNER_FRAME_WIDTH : TICKER_INNER_FRAME_WIDTH + 60;

    return (
        <div>
            <ChapterHeader theme={theme}/>
            {
                variant === 'framed' && (
                    <div style={{display: 'flex'}}>
                        <div style={{width: contentWidth, height: 964}}>
                            <Media
                                width={contentWidth}
                                height={964}
                                media={media}
                                theme={'dark'}
                                useInternalTiming={true}
                                preventInitial
                            />
                        </div>
                    </div>
                )
            }

            {
                (variant === 'full' || variant === 'card') && (
                    <div style={{
                        zIndex: -1,
                        position: 'absolute',
                        height: variant === "card" ? '62.5%' : '100%',
                        width: '100%',
                        top: 0,
                        left: 0,
                    }}>
                        <Media
                            media={media}
                            width={'100%'}
                            height={'100%'}
                            preventInitial
                            useInternalTiming={true}
                            theme={'dark'}
                            dim={variant === 'full'}
                        />
                    </div>
                )
            }

            {
                variant === 'card' && (
                    <div style={{zIndex: -1, position: 'absolute', width: '100%', height: '40%', left: 0, top: '62.5%'}}>
                        <Background variant={'dark-dust'}/>
                    </div>
                )
            }

            <div
                style={{
                    top: variant == 'full' ? '60%' : '63.5%',
                    width: contentWidth,
                    overflowX: 'hidden',
                    overflowY: 'visible',
                    minHeight: 600,
                    marginTop: variant === 'framed' ? '36px' : undefined,
                    position: variant === 'framed' ? 'relative' : 'absolute',
                    // paddingTop: membership === false ? '40px' : undefined,
                }}
                className={classes.removeScrollbar}
            >
                <div style={{width: 'inherit'}}>
                    {
                        stringIsFilled(subtitle) && (
                            <Typography
                                color={color}
                                variant={'jordan'}
                                size={32}
                                style={{fontWeight: 600, marginBottom: '30px'}}
                            >
                                {subtitle}
                            </Typography>

                        )
                    }

                    <Transition variant={'wipe-x'} delay={1} duration={1} style={{marginLeft: -11}}>
                        <GlitchTypography
                            color={color === 'near-black' ? 'black' : color}
                            variant={'jordan-condensed'}
                            size={isLongTitle ? 180 : 200}
                            uppercase
                            style={{
                                // maxWidth: 600
                            }}
                        >
                            {title}
                        </GlitchTypography>
                    </Transition>

                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
                    <div style={{display: 'flex', gap: '10px', color: getColor(color)}}>
                        {
                            showArrows && (
                                <>
                                    <Arrow/>
                                    <Arrow/>
                                </>
                            )
                        }
                    </div>

                    {/* Membership */}
                    <div style={{width: '550px'}}>
                        {
                            stringIsFilled(product_subhead) && (
                                <Typography variant={'jordan'} size={32} color={color}>
                                    {product_subhead.toUpperCase()}
                                </Typography>
                            )
                        }

                        {
                            stringIsFilled(product_copy) && (
                                <Typography
                                    variant={'pp-ultralight'}
                                    size={36}
                                    color={color}
                                    style={{marginTop: '20px'}}
                                >
                                    {product_copy}
                                </Typography>
                            )
                        }
                    </div>
                </div>
            </div>
            {
                hide_footer === false && (
                    <div style={{position: 'absolute', bottom: '40px', width: '100%', left: ticker ? '395px' : '450px'}}>
                        <Typography variant={'jordan'} size={24} color={color}>
                            {coordinates}
                        </Typography>
                    </div>
                )
            }

            <div style={{position: 'absolute', bottom: '40px', color: getColor(color)}}>
                <QrSection qrCode={qr_code} theme={theme}/>
            </div>
        </div>
    )
}