import {motion, useMotionValue, useSpring, useTransform} from "framer-motion";
import React, {useEffect, useMemo, useState} from "react";

const DRAW_DURATION = 4_500;

export default function HorizontalLines({lane, lineIndex, totalLanes, animateLines = true}: any) {
    const color = useMemo(() => {
        return 'rgba(255, 255, 255, .3)';
    }, []);
    const lineThickness = useMemo(() => {
        return .3;
    }, []);

    useEffect(() => {
        setTimeout(() => {
            showValue.set(totalLanes);
        }, DRAW_DURATION * .5);

        // const id = setInterval( () => {
        //     showValue.set(0);
        //     setTimeout(() => {
        //         showValue.set(totalLanes);
        //     }, DRAW_DURATION * 2);
        // }, 60 * 1_000);
        //
        // return () => {
        //     clearInterval(id);
        // }
    }, []);

    const showValue = useSpring(0, {
        duration: DRAW_DURATION,
    });

    const path = useTransform(showValue, [lineIndex, lineIndex + 1], ['inset(0% 100% 0% 0%)', 'inset(0% 0% 0% 0%)']);

    return (
        <motion.div
            style={{
                position: 'absolute',
                left: '0',
                top: lineIndex * lane.height,
                height: lane.height,
                width: '100%',
                clipPath: path,
            }}
            // animate={{
            //     clipPath: show ? 'inset(0% 0% 0% 0%)' : 'inset(0% 100% 0% 0%)',
            // }}
            transition={{
                duration: 2,
            }}
        >
            <div style={{width: '100%', position: 'absolute', top: '7px', background: color, height: lineThickness}}>
            </div>

            <div style={{width: '100%', position: 'absolute', top: '50%', background: color, height: lineThickness}}>
            </div>

            <div style={{width: '100%', position: 'absolute', bottom: '7px', background: color, height: lineThickness}}>
            </div>
        </motion.div>
    )
}