import {CSSProperties, ReactNode, useMemo} from "react";
import {useRpx} from "../shared";
import {containsChinese} from "../../../../../utilities";

interface TypographyProps {
    children: ReactNode;
    font?: 'palatino' | 'helvetica-neue';
    style?: CSSProperties;
    className?: string;
    size?: number;
    [key: string]: any;
}

export default function Typography({children, font = 'palatino', size = 16, className, style, ...rest}: TypographyProps) {
    const rpx = useRpx();

    const hasChinese = useMemo(() => {
        return containsChinese(children?.toString());
    }, [children]);

    const fontStyle = useMemo(() => {
            return {
                palatino: {
                    fontFamily: hasChinese ? 'NotoSerif' : 'palatino',
                    fontSize: rpx(size),
                    letterSpacing: '-0.04em',
                },
                'helvetica-neue': {
                    fontFamily: hasChinese ? 'NotoSans' : 'helvetica-neue',
                    letterSpacing: '-0.04em',
                    fontSize: rpx(size),

                }
            }?.[font] || {};
        }, [font, hasChinese]);

    return (
        <div style={{...fontStyle, ...style}} {...rest}>
            {children}
        </div>
    )
}