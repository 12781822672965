import {motion} from 'framer-motion';
import React from 'react';
import {Codification} from '../../../../components';
import {HUB_CODIFICATION_PROPS} from '../../../../constants/transition';
import {useHubContext} from '../../hooks/useHubContext';
import {useReadyCounter} from '../../hooks/useReadyCounter';
import {EMPTY_LINE} from "../../utils/parser";

export const LevelBlockLegend = (
  {
    className,
    fontSize = 'md',
    items = [],
    opacity,
    reverse = false,
    showSubheadings = false,
    start = true,
    subheadingsDelay = 0,
    onReady
  }
) => {
  const {initialized} = useHubContext();
  const {updateCounter} = useReadyCounter(
    reverse || !initialized
      ? items.length
      : items.reduce((acc, curr) => acc + 1 + (curr.subheadings?.length ?? 0), 0),
    onReady
  );

  return (
    <motion.ul
      className={className}
      animate={{opacity}}
    >
      {items.map(({title, subheadings}, i) => (
        <li
          key={`level-block-${title}-${i}`}
          className={`c-level-block__list-item ${title == EMPTY_LINE && 'hidden'}`}
        >
          {
            <Codification
              {...HUB_CODIFICATION_PROPS}
              text={title}
              typography={{size: fontSize, tag: "span"}}
              reverse={reverse}
              start={start}
              onEnded={updateCounter}
              setLanguageClassName
            />
          }
          {showSubheadings && subheadings?.length > 0 && (
            <ul className="c-level-block__sub-list">
              {subheadings.map((label, i) => (
                <li
                  key={`level-block-${label}-${i}`}
                  className={`c-level-block__list-item`}
                >
                  <Codification
                    {...HUB_CODIFICATION_PROPS}
                    text={label}
                    typography={{size: 'md', tag: "div"}}
                    reverse={reverse}
                    start={start}
                    subheadingsDelay={subheadingsDelay}
                    onEnded={updateCounter}
                    setLanguageClassName
                  />
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </motion.ul>
  );
};
