import VerticalTicker, {TickerType} from "./VerticalTicker";
import React, {useMemo} from "react";

export default function CenterCourtVerticalTicker({verticalTicker, customTicker}: {verticalTicker: TickerType, customTicker: any}) {
    const height = useMemo(() => {
        return verticalTicker === 'family' ? '100%' : '1920px';
    }, [verticalTicker]);

    const forceDuration = useMemo(() => verticalTicker === 'custom' ? 30 : undefined, [verticalTicker]);

    return (
        <>
            <div style={{
                position: 'absolute',
                right: '0',
                height,
                width: 100,
                zIndex: 8,
                top: 0,
                display: 'flex'
            }}>
                <VerticalTicker
                    theme={'dark'}
                    duration={forceDuration}
                    type={verticalTicker}
                    asset={customTicker}
                    custom={{
                        forceContentHeight: '1000px',
                    }}
                />
            </div>

            <div style={{
                position: 'absolute',
                left: '0',
                height,
                width: 100,
                zIndex: 8,
                top: 0,
                display: 'flex'
            }}>
                <VerticalTicker
                    reverse
                    duration={forceDuration}
                    theme={'dark'}
                    type={verticalTicker}
                    asset={customTicker}
                    custom={{
                        forceContentHeight: '1000px',
                        lineLocation: 'right'
                    }}
                />
            </div>
        </>
    );
}