import gsap from 'gsap';
import { ScrambleTextPlugin } from '/src/touchpoints/live/lib/js/vendors/ScrambleTextPlugin.min.js';
import {getSeason} from "../../../../lib/js/utils";

gsap.registerPlugin(ScrambleTextPlugin);

export default function presetTL () {
  const tl = this.tl;
  const { uniforms } = this.visualLayer;
  const vf = this.getAllInOverlay('.viewfinder-mark');
  const twoDigityear = new Date().getFullYear().toString().substring(2,2);
  tl.add(gsap.set(uniforms.pixa, { value:  40 }), 0);
  tl.add(gsap.set(uniforms.gridOpacity, { value:  0 }), 0);
  tl.add(gsap.set(vf, { opacity:  0 }), 0);
  tl.add(gsap.set(vf, { scale:  0.8 }), 0);

  tl.add(gsap.to(this.getBottomRightSlot('h2'), {
    duration: .2,
    scrambleText: {
      text: getSeason(),
      chars: '             NIKE             ',
    }
  }), 1.15);
  tl.add(gsap.to(this.getTopRightSlot('h2'), {
    duration: .5,
    scrambleText: {
      text: 'NIKE STYLE',
      chars: '             NIKE             ',
    }
  }), 1.15);
  tl.add(gsap.to(this.getBottomRightSlot('h2'), {
    duration: .1,
    scrambleText: { text: ' ', chars: ' ' }
  }), 2.75);
  tl.add(gsap.to(this.getTopRightSlot('h2'), {
    duration: .1,
    scrambleText: { text: ' ', chars: ' ' }
  }), 2.75);

  tl.add(gsap.to(uniforms.pixa, { value: 10, duration: .2 }), .3);
  tl.add(gsap.to(uniforms.pixa, { value: 30, duration: .3 }), .7);
  tl.add(gsap.to(uniforms.pixa, { value: 1, duration: .3, ease: 'power3.out' }), 1.5);

  if (window.ORIENTATION === 'LANDSCAPE') {
    tl.add(gsap.to(uniforms.gridOpacity, { value: 1, duration: 0.1, ease: 'steps(1)' }), 0.1);
    tl.add(gsap.to(uniforms.gridOpacity, { value: 0, duration: 0.1, ease: 'steps(1)' }), 0.2);
    tl.add(gsap.to(uniforms.gridOpacity, { value: 1, duration: 0.1, ease: 'steps(1)' }), 0.3);
    tl.add(gsap.to(uniforms.gridMosaic, { value: 1, duration: 1, ease: 'power3.out' }), 0.3);
  }

  tl.add(gsap.to(vf, { opacity: 1, duration: 0.1, ease: 'steps(1)' }), 0.11);
  tl.add(gsap.to(vf, { opacity: 0, duration: 0.1, ease: 'steps(1)' }), 0.21);
  tl.add(gsap.to(vf, { opacity: 1, duration: 0.1, ease: 'steps(1)' }), 0.31);

  tl.add(gsap.to(uniforms.gridOpacity, { value: 0, duration: 0.1, ease: 'steps(1)' }), 1.3);

  tl.add(gsap.to(vf, { opacity: 0, duration: 0.1, ease: 'steps(1)' }), 2.15);
  tl.add(gsap.to(uniforms.rfOpacity, { value: 0, duration: 0.1, ease: 'steps(1)' }), 2.15);
  tl.add(gsap.to(uniforms.rfOpacity, { value: 1, duration: 0.1, ease: 'steps(1)' }), 2.5);
  tl.add(gsap.to(vf, { opacity: 1, duration: 0.1, ease: 'steps(1)' }), 2.5);
  tl.add(gsap.to(vf, { opacity: 0, duration: 0.1, ease: 'steps(1)' }), 2.75);
  tl.add(gsap.to(uniforms.rfOpacity, { value: 0, duration: 0.1, ease: 'steps(1)' }), 2.75);
  tl.add(gsap.to(uniforms.rfOpacity, { value: 1, duration: 0.1, ease: 'steps(1)' }), 3);
  tl.add(gsap.to(vf, { opacity: 1, duration: 0.1, ease: 'steps(1)' }), 3);
  tl.add(gsap.to(vf, { opacity: 0, duration: 0.1, ease: 'steps(1)' }), 3.25);
  tl.add(gsap.to(uniforms.rfOpacity, { value: 0, duration: 0.1, ease: 'steps(1)' }), 3.25);

  tl.add(gsap.to(vf, { scale: 1, duration: .3, ease: 'power2.in' }), .7);

  tl.add(gsap.to(uniforms.rfRadius, { value: 2000, duration: .1 }), 16.75);
  tl.add(gsap.to(uniforms.pixa, { value: 10, duration: 1.1, ease: 'power3.out' }), 16.9);
  tl.add(gsap.to(uniforms.mosaicAmount, { value: 1, duration: 1, ease: 'power3.in' }), 17);

  tl.call(() => {}, null, this.tlLength);
}
