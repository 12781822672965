import React from 'react';
import * as PIXI from 'pixi.js-legacy';
import {SIZES} from "./constants";

const START_TEXT = 0.25;
const END_TEXT = 0.75;

export default class FastLane {
  backgroundLength = 0;
  backgroundX = 0;

  constructor(start, end, info, filler = false) {
    this.isFiller = filler;				 // a non text lane - filler blocks
    this.height = info.trackHeight;  		 // height of track
    this.width = 325; 					 // width of 1 blocks
    this.screenWidth = info.renderWidth;

    this.numBlocks = filler ? 6 : 4;

    // Coordinates to place the start of the component
    this.x = start % this.screenWidth;
    this.y = Math.floor(start / this.screenWidth) * this.height;
    this.length = end - start;


    // if(this.isFiller) debugger

    this.fillerOffset = this.isFiller ? this.numBlocks * this.width - this.length : 0;

    // Check if the component will wrap
    this.wrap = this.x + this.length > this.screenWidth;
    // Coordinates to place the wrapped portion
    this.wrapX = this.x - this.screenWidth;
    this.wrapY = this.y + this.height;

    // Coordinates to place text
    this.startTextX = this.x + START_TEXT * this.length;
    this.endTextX = this.x + END_TEXT * this.length;
    this.textWidth = this.endTextX - this.startTextX;

    this.mask1 = new PIXI.Graphics();
    this.mask2 = new PIXI.Graphics();
  }

  setMask1(container) {
    this.mask1.beginFill(0x0000ff);
    let maskWidth = this.wrap ? this.screenWidth - this.x : this.length;
    this.mask1.drawRect(this.x, this.y, maskWidth, this.height);
    this.mask1.endFill();

    container.position.set(this.x - this.fillerOffset, this.y);
    container.mask = this.mask1;
  }

  setMask2(container) {
    this.mask2.beginFill(0xff0000);
    this.mask2.drawRect(
      this.wrapX,
      this.wrapY,
      this.length,
      this.height,
    );

    this.mask2.endFill();
    container.position.set(this.wrapX - this.fillerOffset, this.wrapY);
    container.mask = this.mask2;
  }

  getNumContainers = () => (this.wrap ? 2 : 1);

  setContainers(container1, container2, background) {
    this.setMask1(container1);

    if (container2) this.setMask2(container2, background);
  }
}
