import LeftGrid from "../components/left-grid";
import Split from "../components/split";
import StepCounter from "../components/step-counter";
import Typography from "../components/typography";
import StepsRight from "../components/steps-right";
import MainView from "../components/main-view";
import React from "react";
import {useRpx} from "../shared";
import {useNbyStore} from "../store";
import PricingRight from "../components/pricing-right";

export default function Pricing() {
    const animationData = useNbyStore(state => state.animationData);
    const rpx = useRpx();

    return (
        <MainView
            left={(
                <LeftGrid
                    extended={true}
                    content={(
                        <div
                            style={{
                                fontSize: rpx(125),
                                lineHeight: rpx(115),
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                marginLeft: rpx(50),
                            }}
                        >
                            <Typography
                                text={animationData.pricingTitle}
                                primary={true}
                            />
                        </div>
                    )}
                    description={(
                        <div
                            style={{
                                fontSize: rpx(40),
                                fontWeight: 700,
                                margin: rpx(50),
                                marginRight: rpx(20),
                                height: '45%',
                                display: 'flex',
                                alignItems: 'center',
                                // respect line breaks
                                whiteSpace: 'pre-wrap',
                            }}
                        >
                            {animationData.pricingCallout}
                        </div>
                    )}
                />
            )}
            right={(
                <PricingRight />
            )}
        />
    )
}