export default function placeImg (content) {
  const div = document.createElement('div');
  div.classList.add('placed-img');
  content.class && div.classList.add(content.class);

  div.style.left = content.left;
  div.style.top = content.top;
  div.style.right = content.right;
  div.style.bottom = content.bottom;
  div.style.width = content.width;
  div.style.height = content.height;

  const img = document.createElement('img');
  img.src = content.src;

  div.appendChild(img);
  return div;
}
