import React, {useEffect, useState} from 'react';
import "../../../rise/styles/normalize.css";
import '../../styles/fonts.scss';
import Container from "../../components/Container";
import {ChapterRenderOptions} from "../../components/ChapterRenderer";
import TitleCard from "../../screens/TitleCard";
import Product from "../../screens/Product";
import UgcCard from "../../screens/UgcCard";
import Membership from "../../screens/Membership";
import Intro from "../../screens/Intro";
import Services from "../../screens/Services";
import Lookbook from "../../screens/lookbook";
import StoreDirectory from "../../screens/StoreDirectory";
import ChapterRunner from "../../components/ChapterRunner";
import SnkrsCal from "../../screens/SnkrsCal";
import Media from "../../screens/Media";
import SnkrsTitle from "../../screens/SnkrsTitle";
import StoreTakeover from "../../screens/StoreTakeover";
import {pixelMapReady} from "../../../../utilities";

export const renderOptions: ChapterRenderOptions = {
    title_card: TitleCard,
    product: Product,
    ugc: UgcCard,
    membership: Membership,
    intro: Intro,
    services: Services,
    lookbook: Lookbook,
    store_directory: StoreDirectory,
    snkrs_cal: SnkrsCal,
    snkrs_cal_stories: SnkrsCal,
    media: Media,
    snkrs_title: SnkrsTitle,
    store_takeover: StoreTakeover,
};

export default function App({data = {}, openChapter = null, config}) {
    const [key, setKey] = useState(0);

    useEffect(() => {
        pixelMapReady(() => {
            setKey(i => i + 1);
        });
    }, []);

    return (
        <Container
            framed={true}
            width={1080}
            height={1920}
        >
            <ChapterRunner
                key={key}
                config={config}
                openChapter={openChapter}
                data={data}
                renderOptions={renderOptions}
            />
        </Container>
    )
}