// React
import React from 'react';
import ReactDOM from 'react-dom/client';

// Hyperlive Utils
import * as Hyperlive from '../../hyperlive/utils.js';

// Globals
import '../../../../init';
import '../../styles/main.scss';

// Grid
import { GridRunner } from 'rise-grid-framework';
import * as GridUtils from '../../risegrid/utils';

// Template
import MTZ from './MTZ';

///////////////////////////////////////////////////////////
// Import Grid JSONs
import intro from './grids/intro.js';
import main from './grids/main.js';
import GRID_INTRO from './grids/intro.js';
import GRID_MAIN from './grids/main.js';
import FPOAssetsVideo from '../../data/gridassets_video.js';
import { GridComposer, GRID_SNIPPETS } from '../../risegrid/GridComposer.js';


let holdFramesMain = [
  { frame: "MainA", hold: 0 },
  // { frame: "MainB", hold: 3000 },
  // { frame: "MainC", hold: 3000 },
  // { frame: "MainD", hold: 3000 },
];

let holdFramesIntro = [
  { frame: 'IntroStart', hold: 0 },
]

///////////////////////////////////////////////////////////
// COMPOSE GRID

// TIME REMAPPING
// If needed, remap frame or wait timing (multiplies current by scale)
// sequence = GridUtils.timeRemap(sequence, .2);
// waitFrames = GridUtils.timeRemap(waitFrames, 0);


// let Grid = new GridComposer({
//   sequences: [intro, main, intro, main, main, intro, main, main],
//   // holdFrames: [holdFramesIntro, holdFramesMain]
// });


///////////////////////////////////////////////////////////
// Hyperlive JSON Data
console.log('ANIMATIONS', window.animations)

const { json_data } = Hyperlive.fetchJSON();
const { chapters } = json_data;

console.log('JSON', json_data);

// Set up custom chapter data per animation
let chapter_data = {}
let sequences = [];
let holdFrames = [];

// Assets for Grid Framework
let assets = [[]]

chapters.map((chapter, index) => {

  const { type } = chapter;

  chapter.id = `${type}.${index}`;

  // ADD TO ANIMATION CHAPTERS
  chapter_data[chapter.id] = chapter;


  switch (type) {

    case Hyperlive.ChapterTypes.Cover:

      let cover_assets;
      if (chapter.hasOwnProperty('media')) {
        // console.log(chapter.media)
        cover_assets = Hyperlive.remapAssets(
          chapter.media,
          type,
          index,
        );
        // console.log(cover_assets)
        assets[0] = assets[0].concat(cover_assets);
      }


      // CUSTOMIZE GRID TEMPLATES
      // ADD CUSTOM ID TO EACH KEYFRAME
      sequences.push(GridUtils.remapKeyframe(GRID_INTRO, { append: `#${type}.${index}`, assets: cover_assets }));
      // holdFrames.push(HOLD_INTRO);

      // if (chapter.hasOwnProperty('media')) {
      //   const { hero, grid } = chapter.media;
      //   if (hero) {
      //     assets[0].push({
      //       name: chapter.id,
      //       url: hero[0].url //.split('.mp4').join('.webm')
      //     })
      //   }
      // }


      break;

    case Hyperlive.ChapterTypes.Story:

      let story_assets;
      if (chapter.hasOwnProperty('media')) {
        story_assets = Hyperlive.remapAssets(
          chapter.media,
          type,
          index,
        );
        assets[0] = assets[0].concat(story_assets);
      }


      sequences.push(GridUtils.remapKeyframe(GRID_MAIN, { append: `#${type}.${index}`, assets: story_assets }));



      // if (chapter.hasOwnProperty('media')) {
      //   const { hero, grid } = chapter.media;
      //   if (hero) {
      //     assets[0].push({
      //       name: chapter.id,
      //       url: hero[0].url //.split('.mp4').join('.webm')
      //     })
      //   }
      //   if (grid) {
      //     assets[0].push({
      //       name: chapter.id,
      //       url: grid[0].url //.split('.mp4').join('.webm')
      //     })
      //   }
      // }
      break;
  }

})

// console.log(assets);
// console.log('chapers', chapters)

// COMPOSE GRID

let Grid = new GridComposer({

  // ADD COMPOSED SEQUENCES
  sequences: sequences,

})

// const assets = [Hyperlive.mapAssets(
//   json_data.assets,
//   [
//     {
//       tag: 'hero',
//       prefix: 'Hero'
//     },
//     {
//       tag: 'grid',
//       prefix: 'Grid'
//     }
//   ])];

//TESTING
// const assets = FPOAssetsVideo;

const grids = new GridRunner({
  grids: [1],
  assets: assets,
  sequence: Grid.sequence,
  waitFrames: Grid.wait,
  canvasWidth: 1080,
  canvasHeight: 1920,
  gridWidth: 1080,
  gridHeight: 1920,
  backgroundColor: 0xFFFFFF,
  lineColor: 100,
  lineThickness: 1,
  antialias: false
});

document.body.appendChild(grids.app.view);


(async () => {

  await grids.loadAssets();

  grids.run(true);

  // console.log('RENDER')

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <MTZ grids={grids} key={'1'} json_data={json_data} chapters={chapter_data} assets={assets} />
  );

})();






