{
  "glyphs": {
    "lesserThan": "<",
		"hashtag": "#",
		"ampersand": "&",
		"openRoundParentheses": "(",
		"exclamation": "!",
		"closeRoundParentheses": ")",
		"degrees": "°",
		"hyphen": "-",
		"underscore": "_",
		"circumflex": "^",
		"multiply": "*",
		"percent": "%",
		"colon": ":",
		"forwardSlash": "/",
		"semiColon": ";",
		"dot": ".",
		"comma": ",",
		"questionMark": "?",
		"plus": "+",
		"equal": "=",
		"largerThan": ">",
		"0": "〇",
		"1": "一",
		"2": "二",
		"3": "三",
		"4": "四",
		"5": "五",
		"6": "六",
		"7": "七",
		"8": "八",
		"9": "九"
  }
}
