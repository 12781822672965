export default function centeredTitle (content) {
  const div = document.createElement('div');
  div.classList.add('centered-title');
  div.classList.add('fit-title');

  const fitwrapper = document.createElement('div');

  const h1 = document.createElement('h1');
  h1.innerHTML = content.text.replace('\n', '<br/>').toUpperCase();
  h1.classList.add(content.style);

  fitwrapper.appendChild(h1);
  div.appendChild(fitwrapper);
  return div;
}
