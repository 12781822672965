import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './overlay.module.scss';
import swooshIcon from '../../assets/images/swoosh.svg';
import classNames from "classnames";
import { debounce } from "lodash";

function Overlay() {
  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });

  const updateScreenSize = debounce(() => {
    setScreenSize({ width: window.innerWidth, height: window.innerHeight });
  }, 300);

  useEffect(() => {
    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();

    return () => {
      window.removeEventListener('resize', updateScreenSize);
    };
  }, []);

  const numRows = useMemo(() => Math.ceil(screenSize.height / 200), [screenSize.height]);
  const numCols = useMemo(() => Math.ceil(screenSize.width / 400), [screenSize.width]);

  const getSwooshScale = useCallback(() => {
    if (screenSize.width / 2000 < 1) {
      return 1;
    }

    return screenSize.width / 2000;
  }, [screenSize.width])

  const swooshesPattern = useMemo(() => {
      const rows = [];

      for (let row = 0; row < numRows; row++) {
          const rowSquares = [];

          for (let col = 0; col < numCols; col++) {
              const showSquareStyle = (row + col) % 2 === 0 ? 'display' : 'hide';

              rowSquares.push(
                  <div
                      className={styles.square}
                      key={`${row}-${col}`}
                  >
                      <img
                          className={classNames(styles['overlay-icon'], styles[showSquareStyle])}
                          src={swooshIcon}
                          style={{ scale: getSwooshScale() }}
                          alt='animation-overlay-swoosh-icon'
                      />
                  </div>
              );
          }

          rows.push(
              <div className={styles.row} key={row}>
                  {rowSquares}
              </div>
          );
      }
      return rows;
  }, [numRows, numCols]);

  return (
    <div className={styles.overlay}>
      {swooshesPattern}
    </div>
  );
}

export default Overlay;
