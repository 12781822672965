if (module.hot) {
  module.hot.accept(() => {
    location.reload()
  })
}

import * as THREE from 'three';

import moment from 'moment';

import markup from './styleGrid-markup';
import vert from '/src/touchpoints/live/lib/glsl/shader.vert';
import frag from './styleGrid.frag';
import createTL from './styleGrid-timeline';
import './styleGrid.scss';

import defaultSwoosh from 'url:/src/touchpoints/live/lib/assets/swoosh.png';

import verticalText from '/src/touchpoints/live/lib/js/components/VerticalText/VerticalText';
import placedCanvas from '/src/touchpoints/live/lib/js/components/PlacedCanvas/PlacedCanvas';

class StyleGrid {
  constructor (name, datas, onComplete, index) {
    this.id = `${name}-${index}`;
    this.name = name;
    this.datas = datas;

    this.delayedPlay = false;

    this.onComplete = onComplete;

    const width = window.innerWidth;
    const height = window.innerHeight;

    this.wrapper = document.createElement('div');
    this.wrapper.id = this.id;
    this.wrapper.classList.add('chapter-wrapper');
    this.wrapper.classList.add('ca-stylegrid');
    this.wrapper.classList.add(`is-size-${window.SIZE_ID?.toLowerCase()}`);

    const units = {
      SMALL: '15px',
      MEDIUM: '25px',
      LARGE: '40px',
    }
    document.documentElement.style.setProperty('--lls-ca-stylegrid-unit', units[window.SIZE_ID]);
    const borderThicknesses = {
      SMALL: '1px',
      MEDIUM: '2px',
      LARGE: '2px',
    }
    document.documentElement.style.setProperty('--lls-ca-stylegrid-border-thick', borderThicknesses[window.SIZE_ID]);

    if (width < height) {
      this.isVertical = true;
      this.wrapper.classList.add('is-vertical');
    }
    this.wrapper.innerHTML += markup;
    document.body.appendChild(this.wrapper);

    this.renderer = this.createRenderer('ca-stylegrid-main-canvas');

    this.scenes = [];

    console.log(this.datas);

    this.creditsTexts = [];
    this.credits = [this.datas.credit_0?.EN, this.datas.credit_1?.EN, this.datas.credit_2?.EN];

    this.insertMediaView(`.content-grid .grid-item-1`, this.datas.assets[0]?.url, 0);
    this.createCreditsText(`.content-grid .grid-item-1`, '');

    this.insertMediaView(`.content-grid .grid-item-2`, this.datas.assets[1]?.url, 1);
    this.createCreditsText(`.content-grid .grid-item-2`, '');

    this.insertMediaView(`.content-grid .grid-item-3`, this.datas.assets[2]?.url, 2);
    this.createCreditsText(`.content-grid .grid-item-3`, '');

    this.loadIndex = 3;

    this.running = false;
  }

  createCreditsText (selector, credits) {
    const vt = verticalText({ text: credits, lowercase: true });
    vt.classList.add('vt-bottom');
    this.wrapper.querySelector(`${selector} .corner-bottom-right`).appendChild(vt);
    this.creditsTexts.push(vt);
  }

  createRenderer (className) {
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, });
    this.resizeRendererToDisplaySize(renderer);
    renderer.setClearColor(0xFFFFFF, 0);
    
    renderer.domElement.classList.add('ca-stylegrid-canvas');
    renderer.domElement.classList.add(className);
    this.wrapper.appendChild(renderer.domElement);
    return renderer;
  }

  resizeRendererToDisplaySize (renderer) {
    const canvas = renderer.domElement;
    const pixelRatio = window.devicePixelRatio;
    const width = window.innerWidth;
    const height = window.innerHeight;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
      renderer.setSize(width, height, false);
    }
    return needResize;
  }

  updateTexture (url, index) {
    this.createTexture(url, (texture) => {
      texture.needsUpdate = true;
      this.scenes[index].userData.shader.uniforms.img.value = texture;
      this.scenes[index].userData.shader.uniforms.imgRes.value.x = texture.image.width;
      this.scenes[index].userData.shader.uniforms.imgRes.value.y = texture.image.height;
    });
  }

  onStart () {
  }

  createTexture (url, onLoaded) {
    const img = new Image();
    const texture = new THREE.Texture(img);
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.encoding = THREE.sRGBEncoding;
    texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();

    img.addEventListener('load', () => {
      onLoaded(texture);
    });

    img.crossOrigin = 'anonymous';
    img.src = url?.replace(/(.png)/gmi, '.jpg') || defaultSwoosh;
  }

  insertMediaView (selector, url, index, cutOutURL) {
    const width = window.innerWidth;
    const height = window.innerHeight;

    this.createTexture(url, (texture) => {
      texture.needsUpdate = true;

      const scene = placedCanvas({
        parent: this.wrapper.querySelector(selector),
        uniforms: {
          // GENERAL
          seed: (Math.random() * 1000),
          time: new THREE.Uniform(0),
          opacity: new THREE.Uniform(1),
          pixr: new THREE.Uniform(window.devicePixelRatio),
          res: new THREE.Uniform(new THREE.Vector2(width, height)),
          // IMG
          img: new THREE.Uniform(texture),
          offset: new THREE.Uniform(new THREE.Vector2(0)),
          imgRes: new THREE.Uniform(new THREE.Vector2(
            texture.image.width,
            texture.image.height
          )),
          mosaic: new THREE.Uniform(1),
          pixels: new THREE.Uniform(1),
          zoom: new THREE.Uniform(1),
        },
        renderer: this.renderer,
        shader: { vert, frag },
      });

      this.scenes[index] = scene;

      if (this.scenes.filter(s => s).length === this.loadIndex) {
        this.tl = createTL(this.scenes, this.onComplete, this.onStart.bind(this), this.credits, this.id);
        if (this.delayedPlay) {
          this.tl.play(0);
        }
      }
    });
  }

  renderScene (scene, renderer) {
    const element = scene.userData.parent;
    const rect = element.getBoundingClientRect();

    const width = (rect.right - rect.left);
    const height = (rect.bottom - rect.top);
    const left = rect.left;
    const bottom = (renderer.domElement.clientHeight - rect.bottom);
    const vp = new THREE.Vector4(left, bottom, width, height);

    renderer.setViewport(vp);
    renderer.setScissor(vp);

    scene.userData.shader.uniforms.time.value++;
    scene.userData.shader.uniforms.res.value.x = width;
    scene.userData.shader.uniforms.res.value.y = height;

    renderer.render(scene, scene.userData.camera);
  }

  render () {
    if (this.running) {
      this.renderer.setScissorTest(true);

      this.scenes.forEach((scene) => {
        this.renderScene(scene, this.renderer);
      });

      this.renderer.setScissorTest(false);
    }

    this.loop = requestAnimationFrame(this.render.bind(this));
  }

  start () {
    this.wrapper.style.display = 'block';
    if (this.tl) {
      this.tl.play(0);
    } else {
      this.delayedPlay = true;
    }
    this.running = true;
    this.loop = requestAnimationFrame(this.render.bind(this));
  }
  stop () {
    this.wrapper.style.display = 'none';
    this.running = false;
    this.tl?.pause();
    this.tl?.seek(0);
    cancelAnimationFrame(this.loop);
  }
  pause () {
    this.running = false;
    console.log('pause');
    this.tl?.pause();
  }
  resume () {
    this.tl?.resume();
    this.running = true;
  }
}
export default StyleGrid;
