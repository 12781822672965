import React, { useEffect, useState } from 'react';
import '../../../../init';
import '../../styles/main.scss';
import './styles/CityReplay.scss';
import * as Hyperlive from '../../hyperlive/utils.js';
import * as Logger from '../../utils/console';
import Headline from './components/Headline/Headline';
import GridCard from '../../components/GridCard/GridCard';
import * as GridUtils from '../../risegrid/utils';
import Description from './components/Description/Description';
import BodyCopy from './components/BodyCopy/BodyCopy';
import { chapterData, CR_ANIMATIONS } from './chapterData';

// let animation_json = Hyperlive.fetchJSON(0);
// Logger.alert(`+ CITY REPLAY`);
// const { name, json_data, json_data: { screen_type } } = animation_json;
// Logger.log(`+ JSON`, animation_json, name, screen_type);

// TEMPLATE CONFIG
const CONFIG = {
	cover: {
		start: 'CoverPlay',
		end: 'CoverExit',
		masks: {
			coverHeadingRT: 'CoverHeadingRT',
			coverHeadingLF: 'CoverHeadingLF',
			textRT1: 'TextRT1',
		},
	},
	story: {
		start: 'ChapterEntry',
		end: 'ChapterEnd',
		masks: {
			heading: 'Heading',
		},
	},
	// chapterStart: 'ChapterEntry',
	// chapterEnd: 'ChapterEnd',
	// masks: {
	//     heading: 'Heading',
	//     coverHeadingRT: 'CoverHeadingRT',
	//     coverHeadingLF: 'CoverHeadingLF',
	// },
	layouts: {
		intro: {
			appMask: 'AppRight',
			qrMask: 'AppLeftBottom',
		},
	},
};

const CityReplay = ({ grids, chapter_data, json_data }) => {
	// Logger.alert('* REPLAY');
	const { title, screen_type } = json_data;

	const [lang, setLang] = useState('KO');
	const [frame, setFrame] = useState({});
	const [chapter, setChapter] = useState(0);
	const [layout, setLayout] = useState(CONFIG.layouts.intro);
	const [CHAPTER, setCHAPTER] = useState({});

	// FRAME LISTENER
	useEffect(() => {
		// SETUP GRID FRAME CALLBACK
		// Logger.highlight('* REPLAY: COMPONENT START');

		grids.setCallback(
			0,
			({ name, frameIndex, loop, language, start: frameStart }) => {
				const {
					frameName,
					frameId,
					frameType,
					chapterId,
				} = GridUtils.keyframeInfo(name);
				// Logger.alert(`# Language: ${language} Loop: ${loop}`);
				setLang(Hyperlive.getLang(language));
				setFrame({
					frameIndex,
					frameName,
					frameId,
					frameStart,
					chapterId,
					frameType,
				});
				setChapter(chapterId);

				// Logger.alert(
				// 	`# FRAME ${name} (${frameName}, ${frameType}, ${frameId}) — ${
				// 		frameStart ? 'Start' : 'End'
				// 	}`,
				// );
				// Logger.highlight(`* ${chapterId}`);
				// Logger.log('#', chapter_data[chapterId]);
				// Logger.alert(`# Language: ${lang}`);

				// RESET LOOP
				if (frameIndex === 0) {
					// setIntro(true);
					setLayout(CONFIG.layouts.intro);
				}

				setCHAPTER(
					chapterData(
						frameType,
						screen_type,
						chapter_data,
						chapterId,
						language,
						CONFIG,
					),
				);
			},
		);
	}, []);

	// Logger.highlight(`# ${frame.frameType} — ${frame.frameId}`);
	//
	// Logger.alert(`## COVER`);
	// Logger.log(`##`, CHAPTER);

	return (
		<div className={'CityReplay'}>
			{screen_type === CR_ANIMATIONS.SERVICES && (
				<React.Fragment>
					<GridCard
						grids={grids}
						frame={frame}
						mask={'Headline'}
						autoReveal={'Left'}
						fade={false}
						keyframes={[CONFIG.story.start, CONFIG.story.end]}
						startframes={[CONFIG.story.start]}
					>
						<Headline
							text={CHAPTER.title}
							size={'eyebrow'}
							theme={'dark'}
						/>
						<Headline
							className={'Step-bottom'}
							text={CHAPTER.headline_text}
							size={'super'}
							theme={'dark'}
						/>
					</GridCard>

					<GridCard
						grids={grids}
						frame={frame}
						mask={'Description'}
						autoReveal={'Left'}
						fade={false}
						keyframes={[CONFIG.story.start, CONFIG.story.end]}
						startframes={[CONFIG.story.start]}
					>
						<BodyCopy
							text={CHAPTER.subheading}
							theme={'dark'}
							width={'100%'}
						/>
					</GridCard>
				</React.Fragment>
			)}

			{screen_type === CR_ANIMATIONS.ATTRACT && (
				<React.Fragment>
					<GridCard
						grids={grids}
						frame={frame}
						mask={CHAPTER.headline_mask}
						autoReveal={'Left'}
						fade={false}
						keyframes={[CONFIG.story.start, CONFIG.story.end]}
						startframes={[CONFIG.story.start]}
					>
						<Headline
							text={CHAPTER.headline_text}
							theme={'dark'}
							size={'display'}
						/>
					</GridCard>

					<GridCard
						grids={grids}
						frame={frame}
						mask={CHAPTER.headline_mask}
						autoReveal={'Left'}
						fade={false}
						keyframes={[CONFIG.cover.start, CONFIG.cover.end]}
						startframes={[CONFIG.cover.start]}
					>
						<Headline
							text={CHAPTER.headline_text}
							size={'display'}
							theme={'light'}
						/>
					</GridCard>

					<GridCard
						grids={grids}
						frame={frame}
						mask={CHAPTER.description_mask}
						autoReveal={'Left'}
						fade={false}
						keyframes={[CONFIG.cover.start, CONFIG.cover.end]}
						startframes={[CONFIG.cover.start]}
					>
						<Description
							text={CHAPTER.description}
							theme={'light'}
						/>
					</GridCard>
				</React.Fragment>
			)}

			{screen_type === CR_ANIMATIONS.REPAIR && (
				<React.Fragment>
					<GridCard
						grids={grids}
						frame={frame}
						mask={'CoverHeadingRT'}
						autoReveal={'Left'}
						fade={false}
						keyframes={[CONFIG.cover.start, CONFIG.cover.end]}
						startframes={[CONFIG.cover.start]}
					>
						<Headline
							text={CHAPTER.cover_text}
							size={'grid_two'}
							theme={'light'}
						/>
					</GridCard>

					<GridCard
						grids={grids}
						frame={frame}
						mask={'TextTop'}
						autoReveal={'Left'}
						fade={false}
						keyframes={[CONFIG.story.start, CONFIG.story.end]}
						startframes={[CONFIG.story.start]}
					>
						<Headline
							text={CHAPTER.headline_text}
							size={'display'}
							theme={'dark'}
						/>
					</GridCard>

					<GridCard
						grids={grids}
						frame={frame}
						mask={'TextBottom'}
						autoReveal={'Left'}
						fade={false}
						keyframes={[CONFIG.story.start, CONFIG.story.end]}
						startframes={[CONFIG.story.start]}
					>
						<BodyCopy
							text={CHAPTER.description}
							theme={'dark'}
							width={'70%'}
						/>
					</GridCard>
				</React.Fragment>
			)}
		</div>
	);
};

export default CityReplay;
