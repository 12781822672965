import React, { useEffect, useState } from 'react';
import './RiseVerticalFS.scss';
import { NIKE_EASE } from '../../../../constants/nikerise';
import _ from 'lodash';
import MediaBlock, { BLOCK_TRANSITIONS } from '../../components/MediaBlock/MediaBlock.js';
import LottiePlayer, { LottieModes } from '../../components/LottiePlayer/LottiePlayer.js';
import ColorBlock, { COLOR_TRANSITIONS } from '../../components/ColorBlock/ColorBlock.js';
import TextBlock, { TEXT_SIZES } from '../../components/TextBlock/TextBlock.js';
const DEFAULT_TRANSITION = { ease: NIKE_EASE, duration: 2 }

const TIMINGS = {
    mediaDuration: 8,
}

const KEYFRAMES = {
    Intro: 0,
    Text: 1,
    Media1: 2,
    Blocks: 3,
    Media2: 4,
}

const RiseVerticalFS = ({ headline, body, media, width, height, top, left, grid, onEvent }) => {

    const [activeMedia, setActiveMedia] = useState(null);
    const [mediaIndex, setMediaIndex] = useState(0);
    const [keyframe, setKeyframe] = useState(KEYFRAMES.Intro);

    const [dims, setDims] = useState({
        width: width ? width : '100%',
        height: height ? height : '100%',
        left: left ? left : 0,
        top: top ? top : 0,
        initial: true,
    });

    const media0 = _.get(media, '[0]');
    const media1 = _.get(media, '[1]');

    useEffect(() => {
        setActiveMedia(media0);
        setTimeout(() => { advanceToNextKeyframe(KEYFRAMES.Text) }, 1000);
        // if (!headline) { setTimeout(() => { advanceToNextKeyframe(KEYFRAMES.Media1) }, 2800); }
        setTimeout(() => { advanceToNextKeyframe(KEYFRAMES.Media1) }, 4000);
        setTimeout(() => { advanceToNextKeyframe(KEYFRAMES.Blocks) }, 3000);
    }, []);

    const advanceToNextKeyframe = (k) => {
        switch (k) {
            case KEYFRAMES.Text:
                setKeyframe(KEYFRAMES.Text);
                break
            case KEYFRAMES.Media1:
                setKeyframe(KEYFRAMES.Media1);
                if (media1) {
                    setActiveMedia(media1)
                    setMediaIndex(1);
                }
                break;
            default:
                setKeyframe(k);
                break
        }
    }

    return <div className="RiseVerticalFS" style={{ ...dims, overflow: 'hidden', position: 'absolute' }}>

        {grid && <LottiePlayer grid={grid} onEvent={onEvent} mode={LottieModes.Chapter} />}


        {

            activeMedia && !headline && <MediaBlock
                media={activeMedia}
                key={`media${mediaIndex}`}
                left={"0"}
                width={"100%"}
                height={"100%"}
                duration={TIMINGS.mediaDuration}
                entry={mediaIndex === 0 ? BLOCK_TRANSITIONS.Left : BLOCK_TRANSITIONS.Fade}
                exit={BLOCK_TRANSITIONS.Fade}
                delay={0} />

        }


        {

            activeMedia && headline && <MediaBlock
                media={media0}
                key={`media${0}`}
                left={"0"}
                width={"100%"}
                height={"100%"}
                duration={media1 ? TIMINGS.mediaDuration - 7 : TIMINGS.mediaDuration - 3}
                entry={BLOCK_TRANSITIONS.Left}
                // steps={{ left: "27.8%" }}
                // steps={{ left: "27.8%", height: '75%' }}
                steps={{ left: "50%", height: '100%' }}
                exit={BLOCK_TRANSITIONS.Right}
                delay={0} />

        }

        {

            activeMedia && headline && mediaIndex === 1 && <MediaBlock
                media={activeMedia}
                key={`media${mediaIndex}`}
                top={'0'}
                left={"0"}
                width={"100%"}
                height={"100%"}
                duration={TIMINGS.mediaDuration - 7}
                entry={BLOCK_TRANSITIONS.Fade}
                // steps={{ left: "27.8%" }}
                // steps={{ left: "27.8%", height: '75%' }}
                steps={{ nothing: '-25%' }}
                exit={BLOCK_TRANSITIONS.Fade}
                delay={2.5} />

        }

        {
            
            keyframe === KEYFRAMES.Media1 && !headline && <React.Fragment>

                {
                    !headline && <ColorBlock
                        key={`color-1-${keyframe}`}
                        top={"100%"}
                        left={"5.6%"}
                        width={"44.4%"}
                        height={"50%"}
                        duration={TIMINGS.mediaDuration / 3}
                        delay={0}
                        direction={COLOR_TRANSITIONS.BottomToTop}
                    />
                }


                {!media1 &&
                    <ColorBlock
                        key={`color-2-${keyframe}`}
                        top={'-50%'}
                        left={"50%"}
                        width={"44.4%"}
                        height={"50%"}
                        duration={TIMINGS.mediaDuration / 3}
                        delay={TIMINGS.mediaDuration / 4}
                        direction={COLOR_TRANSITIONS.TopToBottom}
                    />}

                {media1 &&
                    <ColorBlock
                        key={`color-2-${keyframe}`}
                        top={'-50%'}
                        left={"50%"}
                        width={"44.4%"}
                        height={"50%"}
                        duration={TIMINGS.mediaDuration / 5}
                        delay={TIMINGS.mediaDuration / 4}
                        direction={COLOR_TRANSITIONS.Top}
                    />}



            </React.Fragment>
        }

        {
            keyframe >= KEYFRAMES.Text && headline &&
            <TextBlock
                text={headline}
                left={"4%"}
                // top={"100%"}
                top={"97%"}
                width={"100%"}
                rotate={true}
                textMode={'dark'}
                delay={1}
                duration={3}
                orientation={'portrait'}
                type={TEXT_SIZES.Super}
            />

        }

    </div>

};

export default RiseVerticalFS;
