import {ReactNode} from "react";
import {motion} from "framer-motion";
import {DEFAULT_TRANSITION} from "../../../../rise/constants/transition";

interface CollapsableProps {
    height?: number | 'auto';
    show: boolean;
    children: ReactNode;
}

export default function Collapsable({height = 'auto', show, children}: CollapsableProps) {
    return (
        <motion.div
            initial={false}
            animate={{
                height: show ? height : 0,
            }}
            transition={{
                ...DEFAULT_TRANSITION,
                duration: .5,
            }}
            style={{
                overflow: 'hidden',
            }}
        >
            {children}
        </motion.div>
    )
}