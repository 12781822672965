export const makeHeartbeatRequest = async (config) => {
	const {
		endpoint,
		channel,
		pixelmap_slug,
		label,
		session_id,
		json_data,
		device_uuid,
	} = config;

	const response = await fetch(endpoint, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			channel,
			pixelmap_slug,
			device_uuid,
			label,
			session_id,
			json_data,
			sent_at: new Date() / 1000,
		}),
	});

	return response.json();
};
