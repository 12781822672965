// React
import React from 'react';
import ReactDOM from 'react-dom/client';

// Hyperlive Utils
import * as Hyperlive from '../../hyperlive/utils.js';
import NBYPricing from './NBYPricing';
const { json_data } = Hyperlive.fetchJSON();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<NBYPricing data={json_data} />);
