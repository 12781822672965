import {DEFAULT_BORDER, useRpx} from "../shared";
import {useNbyStore} from "../store";
import {PricingEntryType} from "../types";
import {useRef} from "react";
import Media from "../../../../jordan/components/Media";

export default function PricingRight() {
    const rpx = useRpx();

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    width: '100%',
                    flex: 1,
                    height: '100%'
                }}
            >
                <PricingItems />
            </div>
            <div
                style={{
                    borderTop: DEFAULT_BORDER,
                    height: rpx(311),
                    flexShrink: 0,
                    position: 'relative',
                }}
            >
                {/* Mask the border*/}
                <div
                    style={{
                        width: 2,
                        height: '100%',
                        position: 'absolute',
                        left: -2,
                        top: 0,
                        backgroundColor: '#fff',
                        zIndex: 34,
                    }}>

                </div>

                <Images />

            </div>
        </div>
    )
}

function PricingItems() {
    const items = useNbyStore(state => state.animationData.pricingEntries);
    const rpx = useRpx();

    return (
        <div
            style={{
                marginTop: rpx(56),
            }}
        >
            {
                items?.map((item, index) => (
                    <PricingItem item={item} key={index} />
                ))
            }
        </div>
    )
}

function PricingItem({item}: {item: PricingEntryType}) {
    const rpx = useRpx();

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: `${rpx(50)} ${rpx(50)}`,
            }}
        >
            <div>
                <div
                    style={{
                        fontFamily: 'HelveticaNeue',
                        fontWeight: 700,
                        fontSize: rpx(40),
                    }}
                >
                    {item.title}
                </div>
                <div
                    style={{
                        fontFamily: 'HelveticaNeue',
                        fontWeight: 400,
                        fontSize: rpx(40),
                    }}
                >
                    {item.description}
                </div>
            </div>

            <div
                style={{
                    fontFamily: 'HelveticaNeue',
                    fontWeight: 700,
                    fontSize: rpx(40),
                }}
            >
                {item.price}
            </div>
        </div>
    )
}

function Images() {
    const rpx = useRpx();
    const ref = useRef<HTMLDivElement>(null);
    const media = useNbyStore(state => state.animationData?.pricingMedia ?? []);

    return (
        <div
            ref={ref}
            style={{
                height: '100%',
                width: '100%',
                display: 'flex',
            }}
        >
            {media.map((item, index) => (
                <Media
                    key={`mediascroller-${index}`}
                    media={[item]}
                    width={`${100 / media.filter(i => i?.url).length}%`}
                    height={'100%'}
                    cover={'fit'}
                    preventExit
                    preventInitial
                    preventTransitions
                    preventInitialSlide
                    style={{
                        // borderTop: index === 0 ? DEFAULT_BORDER : 'none',
                        // borderBottom: DEFAULT_BORDER,
                        flexShrink: 0,
                    }}
                />
            ))}
        </div>
    );
}