// React
import React, { useEffect, useMemo, useState, Suspense, useCallback } from 'react';
import ReactDOM from "react-dom/client";
import { Canvas } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import FlipTicker from './Flip/FlipTicker';
import { canvasRowHeight, characterFlipSpeed } from './Flip/shared';
import { Perf } from "r3f-perf";
import '../../../../init';
// Hyperlive Utils
import * as Hyperlive from '../../../rise/hyperlive/utils.js';

const { json_data } = Hyperlive.fetchJSON();
console.log(JSON.stringify(json_data));
// @Harley this is in seconds.
const { 
    page_duration = 8, 
    row_flip = 'auto', 
    character_split = 0.4,
    character_flip_speed = characterFlipSpeed
} = window.channel?.channels?.[0]?.json_config ?? {};

console.log('page_duration', page_duration);

const cameraZPosition = 480;

export function App() {
    const [chapterNumber, setChapterNumber] = useState(0);
    const [showPerf, setShowPerf] = useState(false);
    const [forceChapterFlip, setForceChapterFlip] = useState(false);

    const onPagesFinished = useCallback(() => {
        if(json_data?.chapters.length === 1){
            setForceChapterFlip(forceChapterFlip=>{
                return !forceChapterFlip;
            });
        }else {
            setChapterNumber(chapterNumber => {
                let newChapterNumber = chapterNumber;
                newChapterNumber++;
                if (newChapterNumber >= json_data?.chapters.length) {
                    newChapterNumber = 0;
                }
                return newChapterNumber;
            });
        }
       
    }, [setChapterNumber, setForceChapterFlip]);

    const perf = useMemo(() => <Perf />, []);
    const config = useMemo(() => {
        return {
            row_flip,
            page_duration: page_duration * 1000,
            character_split,
            character_flip_speed
        };
    }, []);

    const ticker = useMemo(() => {
        const screens = json_data?.chapters[chapterNumber]?.screens;
        return <FlipTicker screens={screens} onPagesFinished={onPagesFinished} config={config} forceChapterFlip={forceChapterFlip}/>
    }, [chapterNumber, onPagesFinished, config, forceChapterFlip]);

    useEffect(() => {
        window.addEventListener('keypress', e => {
            setShowPerf(showPerf => !showPerf);
        });

        return () => {
            window.removeEventListener('keypress');
        }
    }, []);

    const isReady = () => !!json_data;

    return (
        <div>
            {showPerf && <div style={{ position: 'absolute', right: '0', zIndex: 99 }}>
                <select defaultValue={0}
                    onChange={({ target }) => {
                        if (parseInt(target.value) === -1) {
                            setChapterNumber(0);
                        } else {
                            setChapterNumber(parseInt(target.value));
                        }
                    }}
                >
                    {
                        Array.from({ length: json_data?.chapters.length }).map((j, i) => (
                            <option value={i}>Chapter {i + 1}</option>
                        ))
                    }
                </select>
            </div>}
            <pre>
                {/* {JSON.stringify(text, null, 2)} */}
            </pre>
            {isReady() &&
                <div>
                    <Suspense>
                        <Canvas
                            gl={{ antialias: false}}
                            flat={true}
                            dpr={[1, 2]}
                            style={{
                                background: 'black',
                                position: "fixed",
                                top: 0,
                                left: 0,
                                width: 640,
                                height: canvasRowHeight
                            }}
                        >
                            <PerspectiveCamera makeDefault
                                fov={5} position={[0, 0, cameraZPosition]} near={0.1} far={20000} >
                            </PerspectiveCamera>
                            {showPerf && <OrbitControls />}
                            {showPerf && perf}
                            {ticker}
                        </Canvas>
                    </Suspense>
                </div>
            }
        </div>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
