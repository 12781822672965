export const BASE_WIDTH = 1080;
export const BASE_HEIGHT = 1920;

export const GRADIENTS = {
  orange: 'linear-gradient(90deg, #F2A23A 0%, #EA3323 68.33%)',
  white: 'linear-gradient(90deg, #FFF 0%, #FFF 68.33%)',
  black: 'linear-gradient(90deg, #000 0%, #000 68.33%)',
};

export const TRANSITION_WAIT = 5000;

export const DEFAULT_LANGUAGE = 'EN';
