import gsap from 'gsap';
import { ScrambleTextPlugin } from '/src/touchpoints/live/lib/js/vendors/ScrambleTextPlugin.min.js';
import {getSeason} from '/src/touchpoints/live/lib/js/utils.js';
gsap.registerPlugin(ScrambleTextPlugin);

export default function createTL (scenes, onComplete, onStart, onKeyframe3Front, onKeyframe3Profile, isVertical) {
  const tl = gsap.timeline({
    onComplete,
    paused: true,
    onStart: onStart,
    defaults: { ease: 'power2.inOut' }
  });

  const uniforms = scenes.map(scene => scene.userData.shader.uniforms);

  const cg = '.gridded-imagery .content-grid';
  const bgg = '.gridded-imagery .background-grid';
  const ffi = '.gridded-imagery .freeflow-item';
  const swi = '.gridded-imagery .freeflow-item .swatch-item';
  const cgi = '.gridded-imagery .content-grid .grid-item';

  const cgi1 = '.gridded-imagery .content-grid .grid-item-1';
  const cgi2 = '.gridded-imagery .content-grid .grid-item-2';
  const cgi3 = '.gridded-imagery .content-grid .grid-item-3';

  const w = window.innerWidth;
  const h = window.innerHeight;

  // INITIAL STATES
  tl.add(() => {
    gsap.set(bgg, { x: 0, scale: 1.0 });
    gsap.set(cg, { x: 0, scale: 1.0 });

    gsap.set(uniforms[0].mosaic, { value: 0 });
    gsap.set(uniforms[1].mosaic, { value: 0 });
    gsap.set(uniforms[2].mosaic, { value: 0 });
    gsap.set(uniforms[3].mosaic, { value: 0 });

    gsap.set(uniforms[0].pixels, { value: 60 });
    gsap.set(uniforms[1].pixels, { value: 60 });
    gsap.set(uniforms[2].pixels, { value: 60 });
    gsap.set(uniforms[3].pixels, { value: 60 });

    gsap.set(`${cgi1} .overlay`, { scale: 0.8 });
    gsap.set(`${cgi2} .overlay`, { scale: 0.8 });
    gsap.set(`${cgi3} .overlay`, { scale: 0.8 });

    gsap.set(ffi, { width: 0, height: 0 });
    gsap.set(ffi, { opacity: 0 });
    gsap.set(swi, { top: -50, opacity: 0 });
    document.querySelector(ffi).classList.remove('wide');
    document.querySelector(`${ffi} .overlay`).classList.remove('wide');
    console.log('CS GRIDDED-IMAGERY');
  }, 0);

  // ANIMATIONS

  tl.to(`${cgi1} .overlay`, { scale: 1, duration: 1 }, 0.);
  tl.to(`${cgi2} .overlay`, { scale: 1, duration: 1 }, 0.1);
  tl.to(`${cgi3} .overlay`, { scale: 1, duration: 1 }, 0.15);

  tl.to(uniforms[0].mosaic, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.1);
  tl.to(uniforms[1].mosaic, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.2);
  tl.to(uniforms[2].mosaic, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.3);

  tl.to(uniforms[0].pixels, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.1);
  tl.to(uniforms[1].pixels, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.2);
  tl.to(uniforms[2].pixels, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.3);

  tl.to(uniforms[0].pixRF, { value: 60, duration: 0.5, repeat: 1, yoyo: true, ease: 'power0.easeNone' }, 1.5);
  tl.to(uniforms[0].zoomRF, { value: 2, duration: 0.5 }, 1.75);

  tl.to(uniforms[2].zoom, { value: 1.25, duration: 1 }, 2);
  tl.to(uniforms[2].pixRF, { value: 60, duration: 0.35, repeat: 1, yoyo: true, ease: 'power0.easeNone' }, 2.5);
  tl.to(uniforms[2].zoomRF, { value: 1.333, duration: 0.5 }, 2.5);
  tl.to(uniforms[2].offset.value, { x: 1, y: 0, duration: 0.5 }, 3);
  tl.to(uniforms[2].offset.value, { x: 0.5, duration: 0.5 }, 3.5);

  tl.to(uniforms[1].zoom, { value: 1.125, duration: 1 }, 2.333);

  tl.to(uniforms[0].pixels, { value: 60, duration: 1, ease: 'power0.easeNone' }, 4);
  tl.to(uniforms[1].pixels, { value: 60, duration: 1, ease: 'power0.easeNone' }, 4);
  tl.to(uniforms[2].pixels, { value: 60, duration: 1, ease: 'power0.easeNone' }, 4);

  tl.to(uniforms[0].mosaic, { value: 0, duration: 1, ease: 'power0.easeNone' }, 4);
  tl.to(uniforms[1].mosaic, { value: 0, duration: 0.8, ease: 'power0.easeNone' }, 4.2);
  tl.to(uniforms[2].mosaic, { value: 0, duration: 0.5, ease: 'power0.easeNone' }, 4.33);

  tl.to(`${cgi1} .overlay`, { scale: 0.9, duration: 0.2 }, 5);
  tl.to(`${cgi2} .overlay`, { scale: 0.9, duration: 0.2 }, 5);
  tl.to(`${cgi3} .overlay`, { scale: 0.9, duration: 0.2 }, 5);
  tl.to(cgi, { opacity: 0, duration: 0.2 }, 5);
  tl.to(ffi, { opacity: 1, duration: 0.2 }, 5);

  const freeflowDivider = {
    x: isVertical ? 2 : 3,
    y: isVertical ? 1.5 : 2,
  };
  tl.to(ffi, { width: (w / freeflowDivider.x), height: (h / freeflowDivider.y), duration: 0.1 }, 5);

  tl.to(uniforms[3].mosaic, { value: 1, duration: 1, ease: 'power0.easeNone' }, 5);
  tl.to(uniforms[3].pixels, { value: 1, duration: 1, ease: 'power0.easeNone' }, 5);

  const wideBGDivider = isVertical ? 2.5 : 7;
  const wideFFDivider = isVertical ? 5 : 7;
  const wideScale = isVertical ? 3.2 : 1.7125;
  tl.to(bgg, { x: (w / wideBGDivider), scale: wideScale, duration: 1 }, 7);
  tl.to(ffi, { height: (h - (h / wideFFDivider)), duration: 1, onStart () {
    document.querySelector(ffi).classList.add('wide');
    document.querySelector(`${ffi} .overlay`).classList.add('wide');

    gsap.to(swi, { top: 0, opacity: 1, stagger: 0.1, duration: 0.5, ease: 'expo.in', delay: 0.75 });

    gsap.to(document.querySelector(`${ffi} .vt-top h2`), {
      delay: 1,
      duration: 1,
      scrambleText: {
        text: 'NIKE STYLE',
        chars: '             NIKE             ',
      }
    });
    gsap.to(document.querySelector(`${ffi} .vt-bottom h2`), {
      delay: 1,
      duration: 0.33,
      scrambleText: {
        text: getSeason(),
        chars: '             NIKE             ',
      }
    });

  } }, 7);
  tl.to(ffi, { width: (w - (w / wideFFDivider)), duration: 1 }, 7.33);

  tl.to(uniforms[3].pixels, { value: 90, duration: 0.5, repeat: 1, yoyo: true, ease: 'power0.easeNone' }, 8);
  tl.to(uniforms[3].pixRF, { value: 90, duration: 0.5, repeat: 1, yoyo: true, ease: 'power0.easeNone' }, 8.333);
  tl.to(uniforms[3].zoomRF, { value: 1.5, duration: 0.5, repeat: 1, yoyo: true, ease: 'power0.easeNone' }, 8.5);
  tl.add(onKeyframe3Front, 8.5);

  tl.to(uniforms[3].pixels, { value: 120, duration: 0.5, repeat: 1, yoyo: true, ease: 'power0.easeNone' }, 10);
  tl.to(uniforms[3].pixRF, { value: 120, duration: 0.5, repeat: 1, yoyo: true, ease: 'power0.easeNone' }, 10.333);
  tl.to(uniforms[3].zoomRF, { value: 1.5, duration: 0.5, repeat: 1, yoyo: true, ease: 'power0.easeNone' }, 10.5);
  tl.add(gsap.to(document.querySelector(`${ffi} .vt-top h2`), {
    duration: 1,
    scrambleText: {
      text: 'NIKE STYLE',
      chars: '             NIKE             ',
    }
  }), 10.5);
  tl.add(onKeyframe3Profile, 10.5);

  tl.to(uniforms[3].mosaic, { value: 0, duration: 1, ease: 'power0.easeNone' }, 12);
  tl.to(bgg, { opacity: 0, duration: 0.7 }, 12.3);
  tl.to(ffi, { opacity: 0, duration: 0.7 }, 12.3);
  tl.to(cg, { opacity: 0, duration: 0.7 }, 12.3);

  tl.add(() => {}, 13);

  return tl;
}
