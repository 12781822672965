
import React, { useEffect, useState, useRef, createRef } from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { motion, useAnimation } from 'framer-motion';
import './LottiePlayer.scss';

export const LottieModes = {
    Global: 'global',
    Chapter: 'chapter'
}

const LottiePlayer = ({ grid, onEvent, mode = LottieModes.Global }) => {

    const LT = React.createRef();

    useEffect(() => {
        return () => {
            
        }
    }, []);

    const onLocalEvent = (event) => {
        
        // if (event === "load") {
        //     LT.current.setSeeker(5000, true)
        // }

        onEvent(event);
    }

    return (<div className={'LottiePlayer'}>
        <Player
            ref={LT}
            autoplay
            loop={mode === LottieModes.Global}
            src={grid}
            key={`lottie`}
            style={{ height: '100%', width: '100%' }}
            rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
            onEvent={onLocalEvent}
        />
    </div >)

};

export default LottiePlayer;
