import { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from 'framer-motion';
import "./NBYMenuItem.scss";
import _ from 'lodash';
import { NIKE_EASE } from '../../../../constants/nikerise';
const DEFAULT_TRANSITION = { ease: NIKE_EASE, duration: 1 }

export default function NBYMenuItem({ entry, index }) {

  const { headline, body, price } = entry

  return (
    <div className={'NBYMenuItem'}>

      {
        entry &&
        <motion.div
          className={'NBYMenuItem-content'}
          initial={{ opacity: 0, marginTop: '0' }}
          animate={{ opacity: 1, marginTop: 0, transition: { ...DEFAULT_TRANSITION, delay: index * .5 } }}
          exit={{ opacity: 0, transition: { ...DEFAULT_TRANSITION, delay: index * .5 } }}>
          <div>
            {headline && <h1>{headline}</h1>}
            {body && <h2>{body.split('\n').map((line, index) => { return <span key={`line-${index}`}>{line}</span> })}</h2>}
          </div>
          {price && <em>{price}</em>}
        </motion.div>
      }

    </div >
  );
}
