import {createContext, useContext} from 'react';
import {ChapterType} from "../shared";

interface ShowRunnerContextType {
    activeIndex?: number,
    chapters: Array<ChapterType>
    totalDuration?: number,
    pause?: Function,
    speedFactor?: number,
    getActive: Function,
}

export const ShowRunnerContext = createContext<ShowRunnerContextType>({
    chapters: null,
    speedFactor: 1,
    activeIndex: 0,
    totalDuration: 0,
    pause: () => null,
    getActive: () => null,
});

export const useShowRunnerContext = () => {
    return useContext(ShowRunnerContext);
};