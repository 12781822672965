import { MotionValue } from "framer-motion";
import { createContext } from "react";

type Type = {
    text: string,
    lines: string[][][],
    words: string[][],
    charIndexMotion: MotionValue<number>,
    repeat: number,
    trigger: number,
    timeout: number,
    reverse: boolean,
};

export default createContext<Type>({
    text: '',
    lines: [],
    words: [],
    charIndexMotion: new MotionValue(),
    repeat: 12,
    trigger: 6,
    timeout: 0.03,
    reverse: false,
});
