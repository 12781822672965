import partial from './partial';
import imgLogo from './imgLogo';
import swatchList from './SwatchList/SwatchList';
import verticalText from './VerticalText/VerticalText';
import centeredTitle from './CenteredTitle';
import imgBackground from './ImgBackground';
import mixedTitle from './mixedTitle';
import mixedTitleCanvas from './MixedTitleCanvas';
import placeText from './placeText';
import placeImg from './placeImg';
import grid from './grid';
import rangeFinder from './RangeFinder';
import centeredLogo from './CenteredLogo';
import mixedText from './MixedText';
import overlayWrapper from './OverlayWrapper';
import viewFinderMarks from './ViewFinderMarks';

export default {
  partial,
  verticalText,
  imgLogo,
  swatchList,
  centeredTitle,
  imgBackground,
  mixedTitle,
  placeText,
  placeImg,
  grid,
  rangeFinder,
  centeredLogo,
  mixedText,
  overlayWrapper,
  viewFinderMarks,
  mixedTitleCanvas
}
