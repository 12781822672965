import gsap from 'gsap';

import Letter from './letter';

export default class Word {
    constructor (word, index, count, ctx, fontStyle) {
        this.ctx = ctx;
        this.word = word;
        this.count = count;
        this.index = index;
        this.direction = (this.index % 2);
        this.letters = word.split('').map((l, i) => new Letter(l, this.index, i, count, fontStyle));
        this.createTween(.8, 0);
        this.timing = 0;
        this.currentTickerIndex = this.letters.length - 1;
        this.firstAnimation = true;
    }

    createTween (duration, delay) {
        if (this.tween) {
            this.tween.kill();
            this.tween = null;
        }
        const steps = (this.count > 1) ? 1 : 3;
        const stagger = (duration / this.letters.length).toFixed(2);
        this.tween = gsap.to(this.letters, {
            duration,
            paused: true,
            fontIndex: (this.index + steps),
            ease: `steps(${steps})`,
            stagger: {
              each: stagger,
              from: this.direction ? 'start' : 'end'
            },
            yoyo: true,
            repeatDelay: delay,
            repeat:true,
            delay
          })
    }
    
    setBaseline (baseline) {
        this.letters.forEach(letter => {
            letter.setBaseline(baseline);
        });
    }

    setSize (size) {
        this.letters.forEach(letter => {
            letter.setSize(size);
        });
    }

    draw (cursor, lineIndex) {
        // if(this.currentTickerIndex > 0) {
        //     this.timing += 1 - (lineIndex * 0.32);
        //     if(this.timing > 2) {
        //         this.timing = 0;
        //         this.currentTickerIndex = this.currentTickerIndex > 0 ? this.currentTickerIndex -= 1 : this.letters.length;
        //     }
        //     for (let index = 0; index < this.letters.length - this.currentTickerIndex; index++) {
        //         this.letters[index].draw(this.ctx, cursor);
        //         cursor += this.ctx.measureText(this.letters[index].value).width;
        //     }
        // } 
        // else {
            this.letters.forEach(letter => {
                letter.draw(this.ctx, cursor);
                cursor += this.ctx.measureText(letter.value).width;
               
            })
        // }
        return cursor;
       
    }
}
