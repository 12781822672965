import {Chapter, EventType, isDuration, PillarType} from "./shared";
import axios from "axios";
import {getChannel} from "../rise/api";
import {get} from "lodash";

const BASE_URL = 'https://federate-api-rise.nikehyperlive.com/api/private/app_post';

const POSSIBLE_TYPES = {
    NRC_EDITORIAL: "NRC_EDITORIAL",
    NRC_EXPERT_TIP_MINDSET: "NRC_EXPERT_TIP_MINDSET",
    NRC_EXPERT_TIP_MOVEMENT: "NRC_EXPERT_TIP_MOVEMENT",
    NRC_EXPERT_TIP_NUTRITION: "NRC_EXPERT_TIP_NUTRITION",
    NRC_EXPERT_TIP_RECOVERY: "NRC_EXPERT_TIP_RECOVERY",
    NRC_EXPERT_TIP_SLEEP: "NRC_EXPERT_TIP_SLEEP",
    NRC_PRODUCT_MARKETING: "NRC_PRODUCT_MARKETING",
    NTC_EDITORIAL: "NTC_EDITORIAL",
    NTC_EXPERT_TIP_MINDSET: "NTC_EXPERT_TIP_MINDSET",
    NTC_EXPERT_TIP_MOVEMENT: "NTC_EXPERT_TIP_MOVEMENT",
    NTC_EXPERT_TIP_NUTRITION: "NTC_EXPERT_TIP_NUTRITION",
    NTC_EXPERT_TIP_RECOVERY: "NTC_EXPERT_TIP_RECOVERY",
    NTC_EXPERT_TIP_SLEEP: "NTC_EXPERT_TIP_SLEEP",
    NTC_PRODUCT_MARKETING: "NTC_PRODUCT_MARKETING",
    NTC_TRAINER_PROFILE: "NTC_TRAINER_PROFILE",
    NTC_WORKOUT: "NTC_WORKOUT",
} as const;

type PostType = typeof POSSIBLE_TYPES[keyof typeof POSSIBLE_TYPES];

interface Entry {
    id: string;

    title: string;
    subtitle: string;

    image_url: string;
    video_start_image_url?: string;
    video_url?: string;

    post_type: PostType;
    marketplace: string;

    created_at: number;
    updated_at: number;
}

function apiUrl(postType: PostType) {
    const url = new URL(BASE_URL);

    url.searchParams.append('marketplace', 'US');
    url.searchParams.append('lang', 'en');
    url.searchParams.append('post_type', postType);

    return url.toString();
}

async function getContent(postType: PostType): Promise<Array<Entry>> {
    try {
        const data = await axios.get(apiUrl(postType));

        return data?.data?.sort((a, b) => b?.created_at - a?.created_at) ?? [];
    } catch (e) {
        return [];
    }
}

interface ChapterType {eventType: EventType, pillar: PillarType}

export function postToChapter(postType: PostType): ChapterType {
    const BASIC_MAPPING: Partial<Record<PostType, ChapterType>> = {
        NTC_WORKOUT: {
            pillar: 'movement',
            eventType: 'ntc',
        },
        NTC_EXPERT_TIP_NUTRITION: {
            pillar: 'nutrition',
            eventType: 'ntc',
        },
        NTC_EXPERT_TIP_RECOVERY: {
            pillar: 'rest',
            eventType: 'ntc',
        },
        NTC_EXPERT_TIP_SLEEP: {
            pillar: 'rest',
            eventType: 'ntc',
        },
        NTC_EXPERT_TIP_MINDSET: {
            pillar: 'mindfulness',
            eventType: 'ntc',
        },
    }

    return BASIC_MAPPING?.[postType] ?? BASIC_MAPPING.NTC_WORKOUT;
}

export async function getGlobalChapters(channelSlug: string): Promise<Array<Chapter>> {
    return new Promise((resolve, reject) => {
        getChannel(channelSlug).then((channels) => {
            const chapters = get(channels, 'channels[0].schedule[0].animation.json_data.chapters', []);
            resolve(chapters);
        }).catch(reject);
    });
}

export async function getCombined(app?: string, apiLimit = 100): Promise<Array<Chapter>> {
    try {
        let selectedTypes: Array<PostType> = [
            "NTC_WORKOUT",
            "NTC_EXPERT_TIP_RECOVERY",
            "NTC_EXPERT_TIP_SLEEP",
            "NRC_EXPERT_TIP_MINDSET",
            "NTC_EXPERT_TIP_NUTRITION",
        ]

        if (app === 'none') {
            selectedTypes = [];
        } else if (app) {
            selectedTypes = app.split(',') as Array<PostType>
        }

        const allEntries = await Promise.all(selectedTypes.map(getContent));

        // interleave workouts and trainings
        const combined: Array<Entry> = [];
        const maxArrayLength = Math.max(...allEntries.map(i => i.length));
        for (let i = 0; i < maxArrayLength; i++) {
            combined.push(...allEntries.map(j => j[i]).filter(j => j));
        }

        let dupes = [];

        const filtered = combined
            // .filter(i => i.video_url)
            .filter((entry) => {
                if (dupes.includes(entry.subtitle?.toLowerCase())) {
                    return false;
                }

                dupes.push(entry.subtitle?.toLowerCase());
                return true;
            })
            .filter((entry) => {
                return entry.title.length < 28 && entry.subtitle.length < 35;
            })

        return filtered.map((i, index) => {
            const ch = postToChapter(i.post_type);
            const chapter: Chapter = {
                pre_index: index,
                template: 'raw_event',
                title: i.title,
                subtitle: isDuration(i.subtitle) ? '' : i.subtitle,
                event_type: ch.eventType,
                pillar: ch.pillar,
                source: 'api',
                details: [
                    `${ch.eventType.toUpperCase()} APP`,
                    isDuration(i.subtitle) ? i.subtitle : '',
                ],
                media: [
                    {
                        resource_type: i.video_url ? 'm3u8-video' : 'image',
                        url: i?.video_url ?? i?.image_url,
                    }
                ]
            }

            return chapter;
        }).slice(0, apiLimit);
    } catch (e) {
        return [];
    }
}