import {useRpx} from "../shared";
import {ReactNode} from "react";

interface MainViewProps {
    left: ReactNode;
    right: ReactNode;
}

export default function MainView({left, right}: MainViewProps) {
    const rpx = useRpx();

    return (
        <div
            style={{
                width: rpx(1920),
                height: rpx(1080),
                display: 'flex',
                position: 'relative',
            }}
        >
            <div
                style={{
                    width: '50%',
                    borderRight: `1px solid #222`,
                }}
            >
                {left}
            </div>

            <div
                style={{
                    width: '50%',
                    borderRight: `1px solid #222`,
                }}
            >
                {right}
            </div>
        </div>
    )
}