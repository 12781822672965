// React
import React from 'react';
import ReactDOM from 'react-dom/client';

// Hyperlive Utils
import * as Hyperlive from '../../hyperlive/utils.js';

// Logger
import * as Logger from '../../utils/console';

// Globals
import '../../../../init';
import '../../styles/main.scss';

// Grid
import { GridRunner } from 'rise-grid-framework';
import * as GridUtils from '../../risegrid/utils';
import { GridComposer, GRID_SNIPPETS } from '../../risegrid/GridComposer.js';

// Template
import Huddle from './Huddle';
import Preloader from '../../components/Preloader/Preloader.js';
///////////////////////////////////////////////////////////
// Import Grid JSONs
// const GRID_INTRO = './grids/introGrid.json';
// const GRID_CHAPTER = './grids/huddleChapter.json';

import GRID_INTRO from './grids/introGrid.json';
import GRID_CHAPTER from './grids/huddleChapter.json';

// FPO ASSSETS
import FPOAssetsVideo from '../../data/gridassets_video.js';
import { assetUrlFormatter } from "../../utils/assetUrlFormatter";


///////////////////////////////////////////////////////////
// Hyperlive JSON Data
// 1. Set up data

// Single animations just need first JSON data
const { json_data } = Hyperlive.fetchJSON();
const { chapters } = json_data;

// Set up custom chapter data per animation
let chapter_data = {}

// Assets for Grid Framework
let assets = [[]]


Logger.highlight('* JSON')
Logger.log('ANIMATIONS', window.animations)
Logger.highlight('* JSON')
Logger.log('* JSON', chapters);


///////////////////////////////////////////////////////////
// COMPOSE GRID & CHAPTERS

let sequences = [];
let holdFrames = [];

// Set up frame timing/holds
const HOLD_INTRO = [
  { frame: 'IntroStart', hold: 1000 },
  { frame: 'IntroTransition', hold: 5000 },
]

const HOLD_CHAPTER = [
  { frame: "ChapterStart", hold: 13000 },
  { frame: "ChapterEnd", hold: 0 },
  { frame: "ChapterStart.story.1", hold: 13000 },
];

const root = ReactDOM.createRoot(document.getElementById('root'));

// Map Chapters

chapters.map((chapter, index) => {

  const { type } = chapter;

  chapter.id = `${type}.${index}`;

  switch (type) {

    case Hyperlive.ChapterTypes.Cover:

      // MAP ASSETS FROM HYPERLIVE TO GRID FRAMEWORK
      // let cover_assets;
      // if (chapter.hasOwnProperty('media')) {
      //   cover_assets = Hyperlive.remapAssets(chapter.media, type, index)
      //   assets[0] = assets[0].concat(cover_assets);
      // }

      // CUSTOMIZE GRID TEMPLATES
      // ADD CUSTOM ID TO EACH KEYFRAME
      sequences.push(GridUtils.remapKeyframe(GRID_INTRO, { append: `#${type}.${index}` }));
      holdFrames.push(HOLD_INTRO);

      // ADD TO ANIMATION CHAPTERS
      chapter_data[chapter.id] = chapter;

      // MAP MEDIA TO GRID FRAMEWORK
      Logger.alert(`»» CHAPTER`)
      Logger.log('»» MEDIA', chapter.media)

      if (chapter.hasOwnProperty('media')) {
        const { background, secondary } = chapter.media;
        if (Array.isArray(background) && background.length > 0) {
          assets[0].push({
            name: 'Intro1',
            url: background[0].url //.split('.mp4').join('.webm')
          })
        }
      }


      break;

    case Hyperlive.ChapterTypes.Story:

      // MAP ASSETS FROM HYPERLIVE TO GRID FRAMEWORK
      // let story_assets;
      // if (chapter.hasOwnProperty('media')) {
      //   story_assets = Hyperlive.remapAssets(chapter.media, type, index)
      //   assets[0] = assets[0].concat(story_assets);
      // }

      // CUSTOMIZE GRID TEMPLATES
      // ADD CUSTOM ID TO EACH KEYFRAME
      sequences.push(GridUtils.remapKeyframe(GRID_CHAPTER, { append: `#${type}.${index}` }));
      holdFrames.push(HOLD_CHAPTER);

      // ADD TO ANIMATION CHAPTERS
      chapter_data[chapter.id] = chapter;


      // MAP MEDIA TO GRID FRAMEWORK
      Logger.alert(`»» CHAPTER`)
      Logger.log('»» MEDIA', chapter.media)

      break;

    default:
      // DO NOT ADD
      break;
  }

  Logger.highlight('* ASSET')
  Logger.log('*', chapter.media)

});


// FPO ASSETS

Logger.alert('$$ ASSETS $$')
Logger.log(assets)
if (assets[0].length < 1) {
  // NEED ASSETS FOR GRID FRAMEWORK??
  assets = FPOAssetsVideo;
}

// COMPOSE GRID

let Grid = new GridComposer({

  // ADD COMPOSED SEQUENCES
  sequences: sequences,

  // ADD HOLD FRAMES
  holdFrames: holdFrames,

  // SLOW DOWN FOR DEBUGGING
  // waitAll: 5000,

  // INSERT GRID SNIPPETS
  inserts: [
    {
      at: "IntroEntry",
      snippetId: GRID_SNIPPETS.STACK_ENTRY,
      continueLastFrameTo: 'IntroEnd',
      // remapAsset: "Intro2"
    },
    // {
    //   at: `ChapterStart`,
    //   snippetId: GRID_SNIPPETS.STACK_ENTRY,
    //   continueLastFrameTo: 'ChapterEnd',
    //   remapAsset: "Intro1"
    // }
  ]
});


// FINAL GRID
Logger.alert('* GRID')
Logger.log('*', Grid.sequence);

assets[0].forEach(asset => {
  asset.url = assetUrlFormatter(asset.url, { height: 1920 });
});

// INITIALIZE GRID RUNNER
const grids = new GridRunner({
  grids: [1],
  assets: assets,
  sequence: Grid.sequence,
  waitFrames: Grid.wait,
  canvasWidth: 1080,
  canvasHeight: 1920,
  gridWidth: 1080,
  gridHeight: 1920,
  backgroundColor: 0xFFFFFF,
  lineColor: 100,
  lineThickness: 1,
  antialias: false
});

// ADD TO DOM
document.body.appendChild(grids.app.view);

// setInterval(async () => {

//   window.location.href =
//     window.location.pathname +
//     window.location.search +
//     window.location.hash;

// }, 60 * 1000 * 15); // 15 min

// Preload
root.render(<Preloader on={true} />);

// STARTUP

(async () => {

  if (assets[0].length > 0) {
    await grids.loadAssets();
  }

  grids.run(true);

  root.render(<Huddle grids={grids} key={'1'} chapter_data={chapter_data} json_data={json_data} assets={assets} />);
})();
