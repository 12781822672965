import React, {
    useEffect,
    useMemo,
    useState
} from 'react';
import {
    AnimatePresence,
    usePresence
} from 'framer-motion';

import {
    getLangText,
    secToMs
} from "../shared";
import { DEFAULT_DURATION_S } from '../constants/transition';
import { BaseChapterProps } from '../components/ChapterRenderer';
import ServiceLang, { ServiceInfo } from '../components/Services/ServiceLang';
import Grid from '../components/Grid';

import '../styles/screens/services.scss';


export type ServicesProps = BaseChapterProps & {
    list: ServiceInfo[],
};

const gridLineThickness = 1;
const cellHeight = 216;

const gridAnimationDuration = DEFAULT_DURATION_S;
const itemAnimationDuration = DEFAULT_DURATION_S;
const nextItemDelay = 0.25;
const activeItemDisplayDuration = 4;
const codificationCharacterDuration = 0.03;

export default function Services(props: ServicesProps) {
    const {
        list,
        languages,
        width,
        height,
    } = props;

    const cols = Math.ceil(width / cellHeight);
    const rows = Math.ceil(height / cellHeight);
    const gridWidth = cols * cellHeight;
    const gridHeight = rows * cellHeight;

    const [isPresent, safeToRemove] = usePresence();
    const [remove, setRemove] = useState(false);
    const [showGrid, setShowGrid] = useState(true);
    const [currentLangIndexToShow, setCurrentLangIndexToShow] = useState(0);
    const [langIndexToShow, setLangIndexToShow] = useState(0);

    const serviceLangs = useMemo(() => languages.map((lang, index) => (
        <ServiceLang
            key={index}
            list={list}
            language={lang}
            width={width}
            cellHeight={cellHeight}
            timingConfig={{
                activeItemDisplayDuration,
                nextItemDelay,
            }}
            itemTimingConfig={{
                animationDuration: itemAnimationDuration,
            }}
            onListDisplayFinished={() => {
                setLangIndexToShow(i => i + 1);
            }}
        />
    )), [list]);

    useEffect(() => {
        if (isPresent || !remove) {
            return;
        }

        safeToRemove();
    }, [isPresent, remove]);

    const onExitComplete = () => {
        if (isPresent) {
            setCurrentLangIndexToShow(i => i + 1)
            return;
        }

        setShowGrid(false);
    };

    return (
        <div className='services'>
            <AnimatePresence onExitComplete={() => {
                setRemove(true);
            }}>
                {showGrid ? (
                    <Grid
                        className='service-lang-grid'
                        width={gridWidth}
                        height={gridHeight}
                        cols={cols}
                        rows={rows}
                        minDuration={gridAnimationDuration / 2}
                        maxDuration={gridAnimationDuration}
                        style={{
                            stroke: `${gridLineThickness}px`
                        }}
                        initialAnimation='visible'
                        exitAnimation='out-top-left'
                    />
                ) : null}
            </AnimatePresence>
            <AnimatePresence onExitComplete={onExitComplete}>
                {langIndexToShow === currentLangIndexToShow ? serviceLangs[langIndexToShow] : null}
            </AnimatePresence>
        </div>
    );
}


export function calculateDuration(data: ServicesProps) {
    const {
        list,
        languages
    } = data;

    const entryDuration = 0;

    const allLangsDuration = languages.reduce((acc, lang) => acc + calculateSingleLangDuration(list, lang), 0);

    const result = entryDuration + allLangsDuration + 0.25;

    const exitDuration = calculateExitDuration(data);

    return secToMs(result) + exitDuration;
}

export function calculateExitDuration(data: ServicesProps) {
    const {
        list,
    } = data;

    const lastItemDelay = (list.length - 1) * nextItemDelay;

    const duration = lastItemDelay + itemAnimationDuration + gridAnimationDuration;

    return secToMs(duration);
}

function calculateSingleLangDuration(list: ServiceInfo[], lang: string) {
    const lastTitleText = getLangText(list.at(-1).title, lang);
    const lastItemTitleLength = lastTitleText.replace('\s', '').length;
    const lastItemCodificationDuration = lastItemTitleLength * codificationCharacterDuration * 2;

    const lastItemDelay = (list.length - 1) * nextItemDelay;
    const entryDuration = lastItemDelay + lastItemCodificationDuration;

    // 1) slide in
    // 2) grow
    // 3) shrink
    // 4) slide out
    const itemSwitchingDuration = 4 * itemAnimationDuration;

    const firtItemShowDetails = 3 * itemAnimationDuration + activeItemDisplayDuration;
    const showDetailsForItems = firtItemShowDetails + (itemSwitchingDuration + activeItemDisplayDuration) * (list.length - 1);
    const exitDuration = lastItemDelay + itemAnimationDuration;

    const duration = entryDuration + showDetailsForItems + exitDuration;

    return duration;
}
