import { useCallback, useEffect, useRef, useState } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { Text } from '@react-three/drei';
import * as THREE from 'three';
import Svg from './components/Svg';
import { TickerProps, SolarizeEffect } from './type';
import { fonts, getNumber, svgs } from './shared';
import { ColorShiftMaterial, getGradientMaterial } from './materials';
import { MediaType } from '../../../../jordan/shared';
import { TickerImage } from './components/TickerImage';
import { animate, clearAnimate, vDirection } from '../CoreTicker';

const spans = 10;

const Ticker = ({
  data,
  rows,
  content,
  vScrollDirection,
  multiLanguage,
}: TickerProps) => {
  const { size } = useThree();
  const groupRef = useRef<THREE.Group>();
  const run = useRef<boolean>(false);
  const isSolarized = useRef<boolean>(data?.effects?.[0]?.type === 'solarized');
  let totalLength = useRef<number>(0);
  const [ticker, setTicker] = useState<JSX.Element>(null);
  const direction = useRef(data.direction === 'right' ? -1 : 1);
  const speed = useRef(data.speed);
  const offsetLimitRef = useRef(0);
  const meshesToAnimate = useRef<THREE.Mesh[]>([]);
  const animMeshes = useRef<THREE.Mesh[]>([]);
  const textMeshes = useRef<any[]>([]);
  const iconMeshes = useRef<THREE.Mesh[]>([]);
  const textsPerRow = useRef(0);
  const numOfTexts = useRef(0);

  const currentOccurence = useRef(0);
  const id = useRef<NodeJS.Timeout>(null);

  const solarizedEffect = useRef<SolarizeEffect>(data?.effects?.[0]);

  useEffect(() => {
    if (animMeshes.current.length && isSolarized.current && ticker) {
      if (solarizedEffect.current) {
        const occurence = solarizedEffect.current.occurence;
        meshesToAnimate.current = [];
        // set up position for all meshes
        animMeshes.current.forEach((mesh, i) => {
          mesh.geometry.computeBoundingBox();
          const bBox = mesh.geometry.boundingBox;
          const width = bBox.max.x - bBox.min.x;
          mesh.position.x -= width / 2;
          mesh.geometry.translate(width / 2, 0, 0);
          const attr = mesh.geometry.getAttribute('position');
          attr.setX(1, 0);
          attr.setX(3, 0);
          attr.needsUpdate = true;

          if (animMeshes.current.length - 1 === i) run.current = true;
        });

        //start animation for first occurence
        const id = setTimeout(() => {
          clearTimeout(id);
          animMeshes.current.forEach((mesh, i) => {
            const bBox = mesh.geometry.boundingBox;
            const width = bBox.max.x - bBox.min.x;
            if (!(i % occurence)) {
              mesh.userData = {
                ...mesh.userData,
                width,
                running: false,
                direction: 1,
              };
              meshesToAnimate.current.push(mesh);
            } else {
              mesh.userData = {
                ...mesh.userData,
                width,
                running: true,
                direction: 0,
              };
              meshesToAnimate.current.push(mesh);
            }
          });
          let i = 0;
          const intervalID = setInterval(() => {
            for (let row = 0; row < textsPerRow.current; row++) {
              if (i === meshesToAnimate.current.length - 1) {
                clearInterval(intervalID);
                return;
              }
              if (!(i % occurence)) {
                meshesToAnimate.current[i].userData.running = true;
              }

              i++;
            }
          }, 100);
        }, solarizedEffect.current.startDelay * 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticker]);

  useEffect(() => {
    const setupTicker = () => {
      let textTotalOffset = 0;
      const width = data.canvas_width || data.width;
      const xOffset = width % data.width;
      const topMargin = getNumber(data.margin?.top);
      const bottomMargin = getNumber(data.margin?.bottom);
      const lineHeight = getNumber(data.height, 1);
      offsetLimitRef.current = lineHeight;
      const squash = data.squash || 1;
      let wordSpacing = getNumber(data.spacing);
      const preFill = !!data.preFill;
      const totalMargin = topMargin + bottomMargin;
      const marginOffset = topMargin - bottomMargin;
      const letterSpacing = data.letterspacing;
      const fontSize = data.height - totalMargin;

      let totalSectorSize = content.reduce(
        (acc, content) => acc + Number(content.width) + wordSpacing,
        0,
      );

      // if linear calc the difference and adjust spacing
      if (data.type === 'linear') {
          const maxWidth = Math.max(data.width, data.canvas_width);
          const mod = maxWidth % totalSectorSize;
          const numSectors = Math.floor(maxWidth / totalSectorSize);
          wordSpacing += mod / (content.length * numSectors);
      }

      //get total content length and number of texts
      content.forEach(c => {
        textTotalOffset += getNumber(c.width) + wordSpacing;
        if (c.type === 'text') {
          numOfTexts.current++;
        }
      });

      let numberOfTexts = 1 / data.squash;
      if (data.type === 'normal' || isSolarized.current) {
        numberOfTexts = Math.ceil(data.width / textTotalOffset);
      }

      let x = preFill ? -data.width : data.width / 2;
      if (direction.current < 0) {
        x = preFill ? data.width : -data.width / 2;
      }

      //create ticker
      const ticker = (
        <group
          position-x={x}
          key={`wrap-group`}
          ref={ref => (groupRef.current = ref)}
        >
          <>
            {Array.from({
              length: rows + 1, //added extra row for
            }).map((_, rowidx: number) => {
              const x =
                direction.current * -(data.width * (rowidx + 1) + xOffset);
              const y = -(
                rowidx * data.height -
                ((rows - 1) * data.height) / 2
              );
              textsPerRow.current = (numberOfTexts * spans);
              return (
                <group key={`wrap-${rowidx}`} position-x={x} position-y={y}>
                  {Array.from({
                    length: textsPerRow.current,
                  }).map((_, outerIdx: number) => {
                    let textOffset = 0;
                    return (
                      <group
                        key={`outer-${outerIdx}`}
                        position-x={
                          textTotalOffset * outerIdx * direction.current
                        }
                      >
                        {content.map((c, idx) => {
                          if (c.type === 'icon') {
                            const width = getNumber(c.width);
                            const scaleRatio = fontSize / c.size.height;
                            const svgProps = {
                              x: textOffset,
                              y: marginOffset,
                              size: c.size,
                              scaleRatio: scaleRatio,
                              url: svgs[c.content.toString()],
                              color: data.color,
                            };
                            if (multiLanguage) {
                              svgProps.material = new ColorShiftMaterial({
                                uniforms: {
                                  resolution: {
                                    value: [size.width, data.height, 1],
                                  },
                                  offset: { value: 0.0 },
                                  direction: { value: idx === 0 ? 1 : -1 },
                                  vertical: { value: true },
                                  invert: {
                                    value: data.background === 'black',
                                  },
                                },
                              });
                            }
                            const svg = (
                              <Svg
                                ref={el => {
                                  iconMeshes.current = [
                                    ...iconMeshes.current,
                                    el,
                                  ];
                                }}
                                {...svgProps}
                              />
                            );
                            textOffset += width + wordSpacing;
                            return svg;
                          } else if (c.type === 'text') {
                            if (isSolarized.current) {
                              //textOffset += solarizedEffect.current.padding;
                            }
                            const topSolarMargin = getNumber(
                              solarizedEffect.current?.margin?.top,
                            );
                            const bottomSolarMargin = getNumber(
                              solarizedEffect.current?.margin?.bottom,
                            );
                            const width = getNumber(c.width);
                            const fontProps = {
                              lineHeight,
                              fontSize: c.newFontSize,
                              letterSpacing,
                              font: fonts[c.font],
                              color: data.color || 'white',
                              children: c.content,
                              whiteSpace: 'nowrap',
                              anchorX: 'left',
                              anchorY: 'middle',
                              clipRect: [
                                0,
                                -lineHeight / 2 + 1,
                                width / squash + 10,
                                lineHeight / 2 - 1,
                              ],
                            } as any;

                            if (isSolarized.current) {
                              fontProps.material = new ColorShiftMaterial({
                                uniforms: {
                                  resolution: {
                                    value: [size.width, size.height, 1],
                                  },
                                  offset: { value: 0 },
                                  direction: { value: 1 },
                                  invert: {
                                    value: data.background === 'black',
                                  },
                                },
                              });
                            }

                            const textWidth = getNumber(c.width);
                            const text = (
                              <group
                                key={`outer-${outerIdx}-group-text$-${idx}`}
                                position={[
                                  textOffset,
                                  -marginOffset / 2 - c.fontYOffset,
                                  0,
                                ]}
                              >
                                <Text
                                  ref={el => {
                                      textMeshes.current = [
                                        ...textMeshes.current,
                                        el,
                                      ];
                                  }}
                                  scale-x={squash}
                                  {...fontProps}
                                />
                                {isSolarized.current && (
                                  <mesh
                                    ref={el => {
                                      animMeshes.current = [
                                        ...animMeshes.current,
                                        el,
                                      ];
                                    }}
                                    userData={{ row: rowidx }}
                                    position={[
                                      textWidth / 2,
                                      -marginOffset / 2 + 0 / 2,
                                      0,
                                    ]}
                                  >
                                    <planeGeometry
                                      args={[
                                        textWidth * squash +
                                          solarizedEffect.current.padding,
                                        lineHeight -
                                          (topSolarMargin + bottomSolarMargin),
                                      ]}
                                    />
                                    <meshBasicMaterial
                                      map={getGradientMaterial(
                                        fontProps.children,
                                        textWidth * squash,
                                        lineHeight,
                                        solarizedEffect.current.startColor,
                                        solarizedEffect.current.endColor,
                                      )}
                                    />
                                  </mesh>
                                )}
                              </group>
                            );

                            if (isSolarized.current) {
                              //textOffset += solarizedEffect.current.padding;
                            }
                            textOffset += textWidth + wordSpacing;
                            return text;
                          } else if (c.type === 'image') {
                            const width = getNumber(c.width);
                            const image = (
                              <TickerImage
                                width={width}
                                media={c.content as MediaType}
                                color={c.preserve_colors ? null : data.color}
                                textOffset={textOffset}
                                marginOffset={marginOffset}
                                fontSize={fontSize}
                              />
                            );

                            textOffset += width + wordSpacing;
                            return image;
                          } else {
                            return <></>;
                          }
                        })}
                      </group>
                    );
                  })}
                </group>
              );
            })}
          </>
        </group>
      );

      totalLength.current = textTotalOffset;

      setTicker(ticker);
    };

    setupTicker();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //animates multi lang up and down
  const runTransition = useCallback(() => {
    const duration = data?.multi_lang?.duration || 5;
    //move up
    if (vDirection === 1) {
      groupRef.current.position.y += duration;
      if (groupRef.current.position.y > offsetLimitRef.current) {
        groupRef.current.position.y = offsetLimitRef.current;
        clearAnimate();
      }
    } else if (vDirection === -1) {
      //move down
      groupRef.current.position.y -= duration;
      if (groupRef.current.position.y < 0) {
        groupRef.current.position.y = 0;
        clearAnimate();
      }
    }
    //only if solarised
    if (isSolarized.current && solarizedEffect.current) {
      const topSolarMargin = getNumber(solarizedEffect.current?.margin?.top);
      const bottomSolarMargin = getNumber(
        solarizedEffect.current?.margin?.bottom,
      );
      // adjust solar meshes based on direction
      animMeshes.current.forEach((mesh: THREE.Mesh, index) => {
        let attr = mesh.geometry.getAttribute('position');

        if (vScrollDirection === -1) {
          const bottomY =
            offsetLimitRef.current / 2 +
            (topSolarMargin + bottomSolarMargin) / 2;
          attr.setY(2, bottomY - groupRef.current.position.y);
          attr.setY(3, bottomY - groupRef.current.position.y);
        } else {
          const bottomY =
            offsetLimitRef.current / 2 -
            (topSolarMargin + bottomSolarMargin) / 2;
          attr.setY(0, bottomY - groupRef.current.position.y);
          attr.setY(1, bottomY - groupRef.current.position.y);
        }
        attr.needsUpdate = true;
      });      
    }
    // adjust icon meshes based on direction
    iconMeshes.current.forEach((mesh: THREE.Mesh, index) => {
      const material = mesh.material as THREE.ShaderMaterial;
      if (vScrollDirection === 1) {
        material.uniforms.offset.value =
          1 - groupRef.current.position.y / offsetLimitRef.current;
        material.uniforms.direction.value = vScrollDirection;
      } else {
        material.uniforms.offset.value =
          groupRef.current.position.y / offsetLimitRef.current;
        material.uniforms.direction.value = vScrollDirection;
      }
    });
  }, [data?.multi_lang?.duration, vScrollDirection]);

  useFrame(() => {
    if (totalLength.current === 0 || !groupRef.current) return;

    if (animate && multiLanguage) {
      textMeshes.current.forEach((mesh: THREE.Mesh, index) => {
        const text = textMeshes.current[index];
        const offset = groupRef.current.position.y;
        //clip text based on position
        if (vScrollDirection === -1) {
          text.clipRect[1] = offsetLimitRef.current / 2 - offset + 1;
        } else {
          text.clipRect[3] = offsetLimitRef.current / 2 - offset - 1;
        }
      });
      runTransition();
    }

    if (run.current && isSolarized.current && solarizedEffect.current) {
      const occurence = solarizedEffect.current.occurence;
      // adjust all meshes
      animMeshes.current.forEach((mesh: THREE.Mesh, index) => {
        const { width, direction, running } = mesh.userData;
        const text = textMeshes.current[index];
        
        if (direction === 1 && running) {
          //move solar background geometry
          let attr = mesh.geometry.getAttribute('position');
          let endX = attr.getX(1);
          endX += solarizedEffect.current.speed;
          if (endX >= width) endX = width;
          attr.setX(1, endX);
          attr.setX(3, endX);
          attr.needsUpdate = true;
          let repeat = endX / width;
          if (repeat >= 1) {
            repeat = 1;
            mesh.userData.direction = 0;
          }

          //adjust text solar wipe to avoid stretching
          if (text) {
            const lag = solarizedEffect.current.padding / width;
            const normalizedRepeat = repeat - 0.5;
            const newRepeat = lag * normalizedRepeat + repeat;
            const material = ((text as unknown) as THREE.Mesh).material as any;
            material.uniforms.offset.value = newRepeat;
            material.uniforms.direction.value = direction;
          }

          if (!id.current) {
            id.current = setTimeout(() => {
              clearTimeout(id.current);
              id.current = null;
              let i = 0;
              const intervalID = setInterval(() => {
                // Start all texts in a row so solar text matches when its reset
                for (let row = 0; row < textsPerRow.current; row++) {
                  if (i === meshesToAnimate.current.length - 1) {
                    clearInterval(intervalID);
                    return;
                  }
                  // set direction to wipe left based on occurence
                  if ((i + currentOccurence.current) % occurence) {
                    meshesToAnimate.current[i].userData.direction = 0;
                  } else {
                    meshesToAnimate.current[i].userData.direction = -1;
                  }

                  i++;
                }
              }, 100);
            }, solarizedEffect.current.wipeDelay * 1000);
          }
        } else if (direction === -1 && running) {
          //move solar background geometry
          let attr = mesh.geometry.getAttribute('position');
          let startX = attr.getX(0);
          startX += solarizedEffect.current.speed;
          if (startX >= width) startX = width;
          attr.setX(0, startX);
          attr.setX(2, startX);
          let repeat = startX / width;
          if (repeat >= 1) {
            repeat = 1;
            attr.setX(0, 0);
            attr.setX(1, 0);
            attr.setX(2, 0);
            attr.setX(3, 0);
            mesh.userData.direction = 0;
          }
          attr.needsUpdate = true;

          //adjust text solar wipe to avoid stretching
          if (text) {
            const lag = solarizedEffect.current.padding / width;
            const normalizedRepeat = repeat - 0.5;
            const newRepeat = lag * normalizedRepeat + repeat;
            const material = ((text as unknown) as THREE.Mesh).material as any;
            material.uniforms.offset.value = newRepeat;
            material.uniforms.direction.value = direction;
          }

          if (!id.current) {
            //start timeout to restart solor wipe
            id.current = setTimeout(() => {
              clearTimeout(id.current);
              id.current = null;
              let i = 0;
              // change occurence
              currentOccurence.current += 1;
              if (currentOccurence.current === occurence) {
                currentOccurence.current = 0;
              }
              const intervalID = setInterval(() => {
                // Start all texts in a row so solar text matches when its reset
                for (let row = 0; row < textsPerRow.current; row++) {
                  if (i === meshesToAnimate.current.length - 1) {
                    clearInterval(intervalID);
                    return;
                  }
                  // set direction to wipe right based on occurence
                  if ((i + currentOccurence.current) % occurence) {
                    meshesToAnimate.current[i].userData.direction = 0;
                  } else {
                    meshesToAnimate.current[i].userData.direction = 1;
                  }
                  i++;
                }
              }, 100);
            }, solarizedEffect.current.wipeDelay * 1000);
          }
        }
      });
    }
    // Move whole group
    if (groupRef.current) {
      if (direction.current > 0) {
        groupRef.current.position.x -= speed.current;
        if (
          groupRef.current.position.x <
          -(
            data.width / 2 +
            totalLength.current * (1 / data.squash) * numOfTexts.current
          )
        ) {
          // set the group back, numOfTexts.current must match solor occurnce
          groupRef.current.position.x +=
            totalLength.current * (numOfTexts.current || 1);
        }
      } else {
        groupRef.current.position.x += speed.current;
        if (
          groupRef.current.position.x >
          data.width / 2 + totalLength.current * (1 / data.squash)
        ) {
          groupRef.current.position.x -= totalLength.current;
        }
      }
    }
  });

  return ticker;
};

export default Ticker;
