if (module.hot) {
  module.hot.accept(() => {
    location.reload()
  })
}

import {
  Uniform,
  Vector2,
  Color
} from 'three';

import UniversalLiveTemplate from '/src/touchpoints/live/lib/js/UniversalLiveTemplate';

import vert from '/src/touchpoints/live/lib/glsl/shader.vert';
import frag from './pdp-full-body.frag';
import presetTL from './pdp-full-body-timeline';
import './pdp-full-body.scss'

class PDPFullBody extends UniversalLiveTemplate {
  constructor (name, datas, onComplete) {
    super(name);
    const width = window.innerWidth;
    const height = window.innerHeight;

    this.setCustomSize(width, height);

    this.datas = datas;
    this.overlayWrapper = this.addNode({
      type: 'overlayWrapper', 
      freeflow:true
    });
    this.viewFinderMarks = this.addNode({
      type: 'viewFinderMarks', 
      freeflow:true,
      content: { stroke: '2px', size: 80 }
    });

    this.verticalTextTop = this.addNode({
      type: 'verticalText', 
      parent: true, 
      className:"top-right",
      content: { text: '' }
    });

    this.verticalTextBottom = this.addNode({
      type: 'verticalText', 
      parent: true, 
      className:"bottom-right",
      content: { text: '' }
    });

    const swatchSizes = {
      SMALL: '20',
      MEDIUM: '30',
      LARGE: '50',
    }
    if (this.datas.assets[0]?.swatches?.length) {
      const offsets = [0, 28.5, 0, 0]; // quick and dirty
      const swatches = new Array(4).fill({}).map((e, i) => {
        return {
          color: this.datas.assets[0].swatches[i % this.datas.assets[0].swatches.length],
          offset: offsets[i] || 0
        };
      });
      this.addNode({type: 'swatchList', parent: true,
      content: { swatches, size: swatchSizes[window.SIZE_ID] }})
    }

    this.mediasCount = this.datas.assets.filter(a => a?.url).length;

    this.registerTextureURL({
      src: this.datas.assets[0].url,
      key: 'img1',
      pos: { x: (this.width / 2), y: (this.height / 2) },
      dim: { x: this.width, y: this.height },
      offset: { x: 0, y: 0 },
      pixel: 1,
      zoom: 1,
      forceJPG: true,
    });

    const center = new Vector2(
      (this.width / 2),
      (this.height / 2)
    );

    const uniforms = {
      time: new Uniform(0),
      res: new Uniform(center),
      pixa: new Uniform(1),
      pixr: new Uniform(window.devicePixelRatio),
      stroke: new Uniform(1),
      zoom: new Uniform(0.25),
      mosaicAmount: new Uniform(0),
      mosaicColor: new Uniform(1),
    }

    this.createVisualLayer({ frag, vert }, uniforms);
    this.setupTimeline(onComplete);
  }

  populateTimeline () {
    const switches = [];
    for (let i = 1; i < this.mediasCount; i++) {
      switches.push({
        time: 2 * i,
        text: this.datas.metaText?.EN,
        onPixelEffect: () => {
          this.visualLayer.createTexture({
            src: this.datas.assets[i].url,
            key: 'img1',
            forceJPG: true,
          }, false);
        }
      });
    }

    presetTL.bind(this)(switches);
  }

  preStart () {
    this.visualLayer.createTexture({
      src: this.datas.assets[0].url,
      key: 'img1',
      forceJPG: true,
    }, false);
    this.wrapper.classList.add('active');
  }
}
export default PDPFullBody;
