export default function imgLogo (content) {
  const div = document.createElement('div');
  div.classList.add('img-logo');

  const img = document.createElement('img');
  img.src = content.src;

  div.appendChild(img);
  return div;
}
