const enableLogs = false;
const logLevel = 2;
let _ISO_ = false;

export const highlight = (output, extra) => {
	if (logLevel >= 1 && enableLogs && !_ISO_) {
		console.log(
			`%c${output}`,
			'color: white; background: #0000ff;padding: 10px;',
		);
		if (extra) {
			console.log('»', extra);
		}
	}
};

export const log = (...args) => {
	if (logLevel >= 2 && enableLogs && !_ISO_) {
		console.log(...args);
	}
};

export const alert = (output, extra) => {
	// Alway Allow unless logs disabled
	if (enableLogs && !_ISO_) {
		console.log(
			`%c${output}`,
			'color: white; background: #ff0000;padding: 10px;',
		);
		if (extra) {
			console.log('»', extra);
		}
	}
};

export const force = (...args) => {
	// With great power...
	console.log(...args);
};

export const isolate = (...args) => {
	if (_ISO_) {
		console.log(
			`%cISOLATE`,
			'color: white; background: #0000ff;padding: 10px;',
		);
		console.log(...args);
	}
};
