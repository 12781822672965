import React, { useEffect, useState } from 'react';
import MediaBlock, { BLOCK_TRANSITIONS } from '../../components/MediaBlock/MediaBlock.js';
import TextBlock from '../../components/TextBlock/TextBlock.js';
import './RiseVerticalGrid.scss';
import { NIKE_EASE } from '../../../../constants/nikerise';
import LottiePlayer, { LottieModes } from '../../components/LottiePlayer/LottiePlayer.js';
const DEFAULT_TRANSITION = { ease: NIKE_EASE, duration: 2 }

const TIMINGS = {
    mediaDuration: 5,
    introDelay: 1,
    contentDuration: 2,
    headlineDelay: 2,
    headlineDuration: 5.75
}

const RiseVerticalGrid = ({ headline, body, media, width, height, top, left, grid, onEvent }) => {

    const [dims, setDims] = useState({
        width: width ? width : '100%',
        height: height ? height : '100%',
        left: left ? left : 0,
        top: top ? top : 0,
        initial: true,
    });

    return <div className="RiseVerticalGrid" style={{ ...dims, overflow: 'hidden', position: 'absolute' }}>

        {grid && <LottiePlayer grid={grid} onEvent={onEvent} mode={LottieModes.Chapter} />}

        {
            headline &&
            <TextBlock
                text={headline}
                // key={headline}
                left={"5.6%"}
                top={"49.7%"}
                width={"88.8%"}
                height={"50%"}
                delay={TIMINGS.headlineDelay}
                orientation={'portrait'}
                duration={TIMINGS.headlineDuration}
            />
        }


        {

            media.length > 0 &&
            <MediaBlock
                media={media[0]}
                // key={media[0]}
                left={"5.6%"}
                width={"88.8%"}
                height={"50%"}
                delay={TIMINGS.introDelay}
                duration={TIMINGS.mediaDuration}
                entry={BLOCK_TRANSITIONS.Top}
                exit={BLOCK_TRANSITIONS.Top}
                inset={true}
            />

        }

        {

            media.length > 1 &&
            <MediaBlock
                media={media[1]}
                // key={media[1]}
                left={"5.6%"}
                width={"44.4%"}
                height={"25%"}
                top={"75%"}
                delay={TIMINGS.introDelay}
                duration={TIMINGS.mediaDuration}
                entry={BLOCK_TRANSITIONS.Top}
                exit={BLOCK_TRANSITIONS.Top}
                inset={true}
            />
        }

        {

            media.length > 2 &&
            <MediaBlock
                media={media[2]}
                // key={media[2]}
                left={"72.2%"}
                width={"22.2%"}
                height={"12.5%"}
                top={"75%"}
                duration={TIMINGS.mediaDuration}
                delay={TIMINGS.introDelay}
                entry={BLOCK_TRANSITIONS.Top}
                exit={BLOCK_TRANSITIONS.Top}
                inset={true}
            />
        }

    </div>

};

export default RiseVerticalGrid;
