import React, { useRef, useState, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { Text } from '@react-three/drei';
import { fontProps, tileGeometry, isDigit, isLetter, ascii } from './shared';

function FlipLetter(props) {
  const bottomRef = useRef();
  const topRef = useRef();
  const flipBottomRef = useRef();
  const flipTopRef = useRef();
  const groupRef = useRef();
  const tileRef = useRef();
  const meshRef = useRef();
  const flip = useRef();
  const characters = useRef(ascii.characters);
  const [startChar, setStartChar] = useState(-1);
  const endChar = useRef(-1);
  const sync = useRef(false);
  const noRender = useRef(true);
  const characterSplitSize = useRef(props.characterSplit);

  useEffect(() => {
    flipTopRef.current.rotation.x = 0;
    flipBottomRef.current.visible = false;

    const letter = props.state.to;
    if (isLetter(letter)) {
      characters.current = ascii.characters;
    } else if (isDigit(letter)) {
      characters.current = ascii.numbers;
    } else {
      characters.current = ascii.symbols;
    }
    let lastChar = endChar.current;
    endChar.current = characters.current.indexOf(props.state.to);
    if (props.state.forceChapterFlip || props.state.newChapter) {
      setStartChar(0);
    } else {
      if (props.state.loop) {
        lastChar++;
        if (lastChar > characters.current.length || lastChar < 0) {
          lastChar = 0;
        }
        setStartChar(lastChar);
      } else {
        setStartChar(0);
      }
    }
    sync.current = false;
    noRender.current = false;
  }, [props.state]);

  useEffect(() => {
    const t = topRef.current;
    const b = bottomRef.current;
    const fr = flipTopRef.current;
    const fb = flipBottomRef.current;
    const mesh = meshRef.current;
    sync.current = false;
    flipTopRef.current.rotation.x = 0;
    flipBottomRef.current.visible = false;
    return () => {
      t.dispose();
      b.dispose();
      fr.dispose();
      fb.dispose();
      mesh.material.dispose();
      mesh.geometry.dispose();
      console.log("Letter unloaded");
    }
  }, []);

  useFrame(() => {
    if (noRender.current || props.state.to === ' ') return;

    if (groupRef.current.parent.rotation.x >= Math.PI && !sync.current) {
      flipBottomRef.current.visible = false;
      bottomRef.current.material = props.fontColor;
      flipTopRef.current.material = props.fontColor;
      flipBottomRef.current.material = props.fontColor;
      topRef.current.material = props.fontColor;
      tileRef.current.children[0].material = props.bkColor;
    }

    if (groupRef.current.parent.rotation.x >= Math.PI * 2 && !sync.current) {
      topRef.current.visible = true;
      flip.current = true;
      sync.current = true;
    }

    if (flip.current) {
      let char = startChar;
      if (char !== endChar.current) {
        tileRef.current.rotation.x += Math.PI / props.characterFlipSpeed;
        flipTopRef.current.rotation.x = tileRef.current.rotation.x;

        if (tileRef.current.rotation.x >= Math.PI / 2) {
          flipTopRef.current.visible = false;
          flipBottomRef.current.visible = true;
          flipBottomRef.current.rotation.x = Math.PI + tileRef.current.rotation.x;
        }
        if (tileRef.current.rotation.x >= Math.PI) {
          tileRef.current.rotation.x = 0;
          flipTopRef.current.rotation.x = 0;
          flipBottomRef.current.rotation.x = 2 * Math.PI;
          flipBottomRef.current.visible = false;

          flipTopRef.current.visible = true;
          tileRef.current.visible = true;
          char++;
          if (char > characters.current.length - 1) {
            char = 0;
          }
          setStartChar(char);
        }
      } else {
        topRef.current.text = characters.current[char + 1];
        bottomRef.current.text = characters.current[char];
        flipTopRef.current.text = characters.current[char];
        flipBottomRef.current.text = characters.current[char + 1];
        tileRef.current.children[0].material = props.bkColor;
        topRef.current.visible = false;
        flip.current = false;
        noRender.current = true;
      }
    }
  })

  return (
    <group visible={props.state.to!==' '} ref={groupRef} position={props.position} >
      <group ref={tileRef} >
        <mesh ref={meshRef} visible={props.hideTile} geometry={tileGeometry} material={props.bkColor} position={[0, 5, 0]} />
      </group>
      <Text
        key={1}
        ref={topRef}
        visible={false}
        depthOffset={0}
        {...fontProps}
        clipRect={[-24, 0, 24, 24]}
        position={[0, characterSplitSize.current, 0]}
      >
        {characters.current[startChar + 1]}
      </Text>
      <Text
        key={2}
        ref={bottomRef}
        {...fontProps}
        depthOffset={-1}
        clipRect={[-24, -24, 24, 0]}
        position={[0, -characterSplitSize.current, 0]}
      >
        {characters.current[startChar]}
      </Text>
      <Text
        key={3}
        ref={flipTopRef}
        {...fontProps}
        depthOffset={-10}
        clipRect={[-24, 0, 24, 24]}
        position={[0, characterSplitSize.current, 0]}
      >
        {characters.current[startChar]}
      </Text>
      <Text
        key={4}
        ref={flipBottomRef}
        {...fontProps}
        depthOffset={-10}
        clipRect={[-24, -24, 24, 0]}
        position={[0, -characterSplitSize.current, 0]}
      >
        {characters.current[startChar + 1]}
      </Text>
    </group>
  )
}

export default FlipLetter;