import './App.scss';

import React, { Component } from 'react';
import WebSocketClient from './WebSocketClient';
import Template from './Template';
import { VisibleHeatTest } from './VisibleHeatTest';

import { WSS_HOST } from '../../config';

const WAIT_BEFORE_LOAD = 500;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      loading: true,
    }

    // this.wsc = new WebSocketClient({ host: WSS_HOST, receive: this.receiveMessage });
    window.addEventListener("hashchange", this.updateDisplayId, false);
  }

  componentDidMount() {
    this.updateDisplayId();
  }

  updateDisplayId = () => {
    console.log('[App]', 'updateDisplayId()');
    const params = new URLSearchParams(window.location.hash.replace("#", "?"));
    const display = params.get('display');

    console.log('[App]', 'updateDisplayId()', { display });
    this.setState({ display: !isNaN(display) ? parseInt(display) : null }, () => {
      setTimeout(() => { this.setState({ loading: false }) }, WAIT_BEFORE_LOAD)
    });
  }

  receiveMessage = ({ type, payload }) => {
    console.log('[App]', 'receiveMessage()', { payload });
    const { display } = this.state;
    if (type !== 9999 && payload) {
      if (payload.highlight && payload.highlight.display && payload.highlight.display === display) {
        this.highlight();
      } else if (payload.reload && payload.reload.display && payload.reload.display === display) {
        window.location.reload(true);
      }
    }
  }

  highlight = () => {
    console.log('[App]', 'highlight()');
    this.setState({ active: true }, () => {
      setTimeout(() => {
        this.setState({ active: false })
      }, 5000)
    })
  }


  render() {
    console.log('[App]', 'render()', { state: this.state, props: this.props });

    const { active, display, loading } = this.state;

    return (
      <div className={`App ${active ? 'active' : ''}`}>
        {display && !loading && (
          <Template displayId={display} />
        )}
      </div>
    );
  }
}

export default App;
