import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import Codification from '../../../../components/Codification';
import './PodDiD.scss';
import { NIKE_EASE } from '../../../../constants/nikerise';
import { KeyframeManager, KEYFRAME_STATUS } from '../../../../risegrid/utils';

const cornerImage = require('../../assets/did-corner.svg');

const VisibleKeyframes = [ 'MainB', 'MainC'];
const Keyframe = new KeyframeManager(VisibleKeyframes);

const PodDiD = ({ text, chapter }) => {

    const dims = {
        height: '50vh',
        width: '800px',
        top: 100,
        left: 100,
    }
    const start = chapter === 'story.2';
    const show = chapter === 'story.2' || chapter === 'story.3' //true //VisibleKeyframes.includes(frameName) //keyframe_status ==  KEYFRAME_STATUS.BEGIN

    return text ? (

        <div className="HuddleDid" style={{ ...dims, overflow: 'hidden', position: 'absolute', zIndex: 999 }}>

            { show &&

                <motion.div
                    initial={{
                        translateX: '10%',
                        translateY: '-100%',
                    }}
                    animate={{
                        translateY: start ? '55%' : '-120%',
                    }}
                    transition={{
                        ease: NIKE_EASE,
                        duration: 1,
                        delay: start ? 0 : 0
                    }}
                >
                    <div className="PodDiD-container">
                        <div className="PodDiD-corner">
                            <img src={cornerImage} />
                        </div>
                        <div className="PodDiD-text">
                            <div className="PodDiD-top">

                                <Codification
                                    text={'NTC'}
                                    typography={{
                                        primary: false,
                                        typeStyle: { fontSize: '25px', lineHeight: '25px' },
                                        tag: 'span',
                                    }}
                                    characterSwitchAmount={6}
                                    characterNextTrigger={2}
                                    characterTimeout={30}
                                    start={start}

                                />
                            </div>
                            <div className="PodDiD-bottom">
                                <Codification
                                    text={text}
                                    typography={{
                                        primary: true,
                                        typeStyle: { fontSize: '44px', lineHeight: '44px' },
                                        tag: 'span',
                                    }}
                                    characterSwitchAmount={6}
                                    characterNextTrigger={2}
                                    characterTimeout={0.03}
                                    start={start}
                                    reverse={!start}

                                />
                            </div>
                        </div>
                    </div>
                </motion.div>

            }



        </div>


    ) : <div />;
};

export default PodDiD;
