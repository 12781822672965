import './multilineTicker.css';

import Line from './line';

export default function MultilineTicker (content) {
    const that = {};
    that.time = 0;
	that.speed = content.speed || 1.5;
	that.duration = 0.5;
    that.text = content.text;
    that.fontSize = window.innerWidth > window.innerHeight ? window.innerWidth * 0.055 : window.innerHeight * 0.06;
    that.delay = 1;
    that.lineHeight = content.lineHeight || 1;
    that.width = content.width;
    that.height = content.height;
    that.image = content.image || null;
    that.baseline = null;
    that.fontStyle = content.style || 'mixed';
    that.elementId = content.id 

    that.emptyIndex = [];

    if(that.image) {
        that.text.split(' ').forEach((word, index) => {
          if(word.length < 1) {
            that.emptyIndex.push(index);
          }
        })
    }

    const createCanvas = () => {
        that.element = document.createElement('div');
        that.element.classList.add('multilineTicker');
        that.canvas = document.createElement('canvas');
        that.element.appendChild(that.canvas);
        let parent = document.querySelector(`#${that.elementId}`);
        parent.querySelector('.main-container').appendChild(that.element);
    };

    const setCanvasSize = () => {
        that.canvas.width = that.width;
        that.canvas.height = that.height;
    }

    const setCanvasStyle = () => {
        that.ctx = that.canvas.getContext('2d');
        that.ctx.fillStyle = 'white';
        that.ctx.fillRect(0, 0, that.width, that.height);
    }

    const createLines = () => {
        const lines = that.text.split('\n');
        let lineDistance = that.fontSize * that.lineHeight;
        let size = that.fontSize + (lines.length * lineDistance);
        that.lines = [];
        lines.forEach((line, index) => {
            let baseline = that.fontSize + (index*lineDistance) + ((that.height / 2) - size/2);
            that.lines.push(new Line(that.ctx, line.trim(), that.fontSize, baseline + (lineDistance / 2.7), that.width, that.fontStyle));
        })
    }

    const play = () => {
        that.lines.forEach((line) => {
            line.play();
        })
        that.element.style.display = 'block'
    }

    const stop = () => {
        that.element.style.display = 'none'
        that.lines.forEach((line) => {
            line.stop();
        })
    }
    
    const createImage = () => {
        that.img = new Image();
        that.img.crossOrigin = 'anonymous';
        that.img.onload =  () => {
            that.imageisLoaded = true;
            let scalingRatio = (that.fontSize * 1.28) / that.img.width;
            that.imageWidth = that.img.width * scalingRatio;
            that.imageHeight = that.img.height * scalingRatio;
        };
        that.img.src = that.image;
    }

    const render = () => {
        setCanvasStyle(); 
        that.lines.forEach((line) => {
            line.render();
        })
        if(that.image && that.imageisLoaded) {
            that.ctx.drawImage(that.img, that.lines[0].words[that.emptyIndex[0]].cursorStart + (that.fontSize * 0.01), that.lines[0].baseline - (that.imageHeight * 0.9), that.imageWidth, that.imageHeight);
        }
        requestAnimationFrame(render);
    }

    const generateTexture = () => {
        return that.canvas.toDataURL("image/png");
    }

    if(that.image) {
        createImage();
    }
        createCanvas();
        setCanvasSize();
        setCanvasStyle();
        createLines();
        render();
    

    return {MultilineTicker, generateTexture, play, stop};
}
    
