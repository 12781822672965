#pragma glslify: mosaicMask = require('../../../../lib/glsl/mosaic.glsl')
#pragma glslify: containUV = require('../../../../lib/glsl/containUV.glsl')
#pragma glslify: pixelateUV = require('../../../../lib/glsl/pixelateUV.glsl')
#pragma glslify: zoomedUV = require('../../../../lib/glsl/zoomedUV.glsl')
#pragma glslify: circle = require('../../../../lib/glsl/circle.glsl')
#pragma glslify: gammaCorrect = require('../../../../lib/glsl/gammaCorrect.glsl')

varying vec2 vUv;

uniform float time;

// general stuffs
uniform vec2 res; // res
uniform float pixr; // pixel ratio
uniform float opacity;

uniform sampler2D img;
uniform vec2 imgRes;
uniform vec2 offset;
uniform float mosaic;
uniform float pixels;
uniform float zoom;

vec2 centeredOffset () {
  float ar = (res.x / res.y);
  return ((offset - 0.5) * vec2(1.0, (1.0 / ar))) + 0.5;
}

void main() {
  vec4 background = vec4(1.0, 1.0, 1.0, 0.0); // transparent white

  float ar = (res.x / res.y);
  float iar = (imgRes.x / imgRes.y);
  float px = ((res.x * pixr) / pixels);

  vec2 uv = containUV(vUv, res, imgRes);
  uv = zoomedUV(uv, imgRes, (zoom * 0.85 * iar), centeredOffset());
  uv = pixelateUV(uv, px, ar);

  vec4 color = vec4(texture2D(img, uv, -10.0).rgb, 1.0);

  float o = opacity * (1.0 - mosaicMask(vUv, ar, (time * 0.01), mosaic, 8.));
  if ((uv.x > 1.0 || uv.x < 0.0) || (uv.y > 1.0 || uv.y < 0.0)) {
    color = vec4(1.0);
  }

  gl_FragColor = mix(vec4(gammaCorrect(color.rgb,2.2),1.0), background, o);
}