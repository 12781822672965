#pragma glslify: mosaicMask = require('../../../../lib/glsl/mosaic.glsl')
#pragma glslify: coverUV = require('../../../../lib/glsl/coverUV.glsl')
#pragma glslify: pixelateUV = require('../../../../lib/glsl/pixelateUV.glsl')
#pragma glslify: zoomedUV = require('../../../../lib/glsl/zoomedUV.glsl')
#pragma glslify: circle = require('../../../../lib/glsl/circle.glsl')

varying vec2 vUv;

uniform float time;

// general stuffs
uniform vec2 res; // res
uniform float pixr; // pixel ratio
uniform float opacity;

uniform vec2 rangeFinder;
uniform float pixRF;
uniform float zoomRF;

uniform sampler2D img;
uniform vec2 imgRes;
uniform vec2 offset;
uniform float mosaic;
uniform float pixels;
uniform float zoom;

vec2 centeredOffset () {
  float ar = (res.x / res.y);
  return ((offset - 0.5) * vec2(1.0, (1.0 / ar))) + 0.5;
}

void main() {
  vec4 background = vec4(1.0, 1.0, 1.0, 0.0); // transparent white

  float ar = (res.x / res.y);
  float iar = (imgRes.x / imgRes.y);
  float px = ((res.x * pixr) / pixels);

  vec2 uv = coverUV(vUv, res, imgRes);
  uv = zoomedUV(uv, imgRes, zoom * iar, centeredOffset());
  uv = pixelateUV(uv, px, ar);

  vec4 color = vec4(texture2D(img, uv, -10.0).rgb, 1.0);

  float o = opacity * (1. - mosaicMask(vUv, ar, (time * 0.01), mosaic, 8.));

  gl_FragColor = mix(color, background, o);
}