export function indexedColor(index) {
	return [
		'#75B36C',
		'#CFCEA4',
		'#F3D9AC',
		'#62A6BF',
		'#E49FB1',
		'#D5C4B9',
		'#CBA9DE',
		'#9C9EA4',
		'#7CD4BB',
	][index];
}
