import React from 'react';
import * as PIXI from 'pixi.js-legacy';
import ReactDOM from 'react-dom/client';

import '../../../../init';
import {GridRunner2} from 'rise-grid-framework';
import sequence from './grids/mainv2';
import FastLane from './_fastlane';
import laneConstructor from './laneConstructor';
import FastLaneViewer from './index';
import Background from './_background';
import {getStoreLanguages} from '../../hyperlive/utils';
import {configOverride} from "./utils";
import * as Hyperlive from "../../hyperlive/utils";

let infoJson = require('./data/fastlane_1.json');
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

if (window.animations && window.animations?.length > 0) {
  infoJson = window.animations[0];
  // console.log(infoJson);
}

let timeRemap = 1;

if (params.timeRemap) {
  timeRemap = parseFloat(params.timeRemap);
}

const info = {
  lanes: [
    {
      pole: 0,
    },
  ],
  numPoles: 4,
  staggerDelay: 0,
  renderWidth: 1300,
};

const waitFrames = [
  {frame: 1, wait: 30000},
  {frame: 2, wait: 30000},
  {frame: 3, wait: 30000},
];

waitFrames.forEach(frame => {
  frame.wait *= timeRemap;
});

const lanes = laneConstructor(info);

// Fast lanes control placement in pixel map and masking  of the grids to constrain them in the renderWidth
const fastLanes = lanes.map(({pole, length, isFiller}, i) => {
  return new FastLane(pole, info.renderWidth, length, i, isFiller);
});

// Figure out how many grids we need especially if wrapping is necessary
const gridNumArr = fastLanes.map(lane => lane.getNumContainers());

const overrides = configOverride();
const trackHeight = window.innerHeight > 240 ? 240 : window.innerHeight;

// Initialize grid runner class
const grids = new GridRunner2({
  grids: gridNumArr,
  sequence: sequence(trackHeight, {size: overrides?.size, showCircle: false}),
  canvasWidth: 1300,
  canvasHeight: trackHeight,
  gridWidth: 1300,
  gridHeight: trackHeight,
  backgroundColor: 0x000000,
  lineColor: 100,
  lineThickness: 1,
  waitFrames,
  languageCount: getStoreLanguages().length,
  ...overrides,
});

const background = new Background(grids.app, info.numPoles, overrides?.lineThickness ?? 1, overrides?.lineColor ?? 100, trackHeight, overrides?.size);

const container = new PIXI.Container();
grids.app.stage.addChild(container);

// Tell the fastlane class which grid container is used for each lane
fastLanes.forEach((lane, i) => {
  lane.setBackground(background, grids.app)
  const num = lane.getNumContainers();
  const container1 = grids.getContainer(i);

  if (num === 1) {
    lane.setContainers(container1);
  } else {
    const container2 = grids.getContainer(i, 1);
    lane.setContainers(container1, container2);
  }
});

document.body.appendChild(grids.app.view);

const loop = () => {
  background.run().then(async () => {
    await grids.run(false);
    grids.loop++;
    background.rewind().then(() => {
      grids.destroy();
      grids.fresh();
      loop();
    });
  });
};

document.body.appendChild(grids.app.view);

loop();

// Pass the grid runner into the react component
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<FastLaneViewer textJson={[infoJson]} runner={grids} lanes={fastLanes} size={overrides?.size}
                            qrWidth={overrides?.qrWidth} svgCircle={overrides.circle === 'svg'}/>);