[
    {
      "circle": [],
      "delayHorizontal": true,
      "delayVertical": true,
      "drawBorder": true,
      "duration": "1000",
      "horizontal": [
        {
          "drawFromPosition": 0,
          "drawFull": true,
          "drawToPosition": 100,
          "name": "PrimaryTop",
          "startPosition": 5.6
        },
        {
          "delay": 100,
          "drawFromPosition": 0,
          "drawFull": true,
          "drawToPosition": 100,
          "endPosition": 22.2,
          "name": "Equator",
          "startPosition": 50
        },
        {
          "delay": 200,
          "drawFromPosition": 0,
          "drawFull": true,
          "drawToPosition": 100,
          "endPosition": 50,
          "name": "PrimaryBottom",
          "startPosition": 94.4
        },
        {
          "delay": 100,
          "drawFromPosition": 0,
          "drawFull": true,
          "drawToPosition": 100,
          "endPosition": 94.4,
          "name": "NewPrimaryBottom",
          "startPosition": 101
        }
      ],
      "id": 0,
      "mask": [
        {
          "asset": 0,
          "container": "canvas",
          "coverFill": true,
          "fadeIn": true,
          "grid": 0,
          "horizontal": [
            "Top Border",
            "Bottom Border"
          ],
          "name": "BG",
          "vertical": [
            "Left Border",
            "Right Border"
          ]
        }
      ],
      "name": "CoverStart",
      "vertical": [
        {
          "delay": 1000,
          "drawFromPosition": 100,
          "drawFull": true,
          "drawToPosition": 0,
          "name": "PrimaryLeft",
          "startPosition": 3.1
        },
        {
          "drawFromPosition": 0,
          "drawFull": true,
          "drawToPosition": 100,
          "endPosition": 25,
          "name": "Left1",
          "startPosition": 26
        },
        {
          "delay": 1000,
          "drawFromPosition": 0,
          "drawFull": true,
          "drawToPosition": 100,
          "name": "Equator",
          "startPosition": 50
        },
        {
          "drawFromPosition": 0,
          "drawFull": true,
          "drawToPosition": 100,
          "endPosition": 75,
          "name": "Right1",
          "startPosition": 74
        },
        {
          "delay": 1000,
          "drawFromPosition": 100,
          "drawFull": true,
          "drawToPosition": 0,
          "name": "PrimaryRight",
          "startPosition": 96.9
        }
      ],
      "verticalDelayDirection": -1
    },
    {
      "circle": [
        {
          "clockwise": true,
          "delay": 1000,
          "drawAngle": 360,
          "drawFromAngle": 180,
          "drawFull": false,
          "duration": 1500,
          "startBoundaryX": 25,
          "startPositionX": 50,
          "startPositionY": 50
        },
        {
          "clockwise": false,
          "delay": 1000,
          "drawAngle": 360,
          "drawFromAngle": 180,
          "drawFull": false,
          "duration": 1500,
          "startBoundaryX": 25,
          "startPositionX": 0,
          "startPositionY": 50
        },
        {
          "clockwise": false,
          "delay": 1000,
          "drawAngle": 360,
          "drawFromAngle": 180,
          "drawFull": false,
          "duration": 1500,
          "startBoundaryX": 25,
          "startPositionX": 100,
          "startPositionY": 50
        }
      ],
      "delayHorizontal": true,
      "delayVertical": true,
      "drawBorder": true,
      "duration": "1000",
      "horizontal": [
        {
          "drawFromPosition": 0,
          "drawFull": true,
          "drawToPosition": 100,
          "name": "PrimaryTop",
          "startPosition": 5.6
        },
        {
          "delay": 100,
          "drawFromPosition": 0,
          "drawFull": true,
          "drawToPosition": 100,
          "name": "Equator",
          "startPosition": 22.2
        },
        {
          "delay": 200,
          "drawFromPosition": 0,
          "drawFull": true,
          "drawToPosition": 100,
          "name": "PrimaryBottom",
          "startPosition": 50
        },
        {
          "delay": 100,
          "drawFromPosition": 0,
          "drawFull": true,
          "drawToPosition": 100,
          "name": "NewPrimaryBottom",
          "startPosition": 94.4
        }
      ],
      "id": 0,
      "mask": [
        {
          "asset": 0,
          "container": "canvas",
          "coverFill": true,
          "grid": 0,
          "horizontal": [
            "Top Border",
            "Bottom Border"
          ],
          "name": "BG",
          "vertical": [
            "Left Border",
            "Right Border"
          ]
        },
        {
          "asset": 0,
          "grid": 0,
          "horizontal": [
            "Equator",
            "PrimaryBottom"
          ],
          "name": "CoverHeadingRT",
          "polyOnly": true,
          "vertical": [
            "Equator",
            "Right1"
          ]
        },
        {
          "grid": 0,
          "horizontal": [
            "Equator",
            "PrimaryBottom"
          ],
          "name": "CoverHeadingLF",
          "polyOnly": true,
          "vertical": [
            "Left1",
            "Equator"
          ]
        },
        {
          "asset": 0,
          "grid": 0,
          "horizontal": [
            "PrimaryBottom",
            "NewPrimaryBottom"
          ],
          "name": "TextRT1",
          "polyOnly": true,
          "vertical": [
            "Equator",
            "PrimaryRight"
          ]
        },
        {
          "asset": 0,
          "grid": 0,
          "horizontal": [
            "PrimaryBottom",
            "NewPrimaryBottom"
          ],
          "name": "TextRT2",
          "polyOnly": true,
          "vertical": [
            "Right1",
            "PrimaryRight"
          ]
        }
      ],
      "name": "CoverPlay",
      "vertical": [
        {
          "delay": 1000,
          "drawFromPosition": 100,
          "drawFull": true,
          "drawToPosition": 0,
          "name": "PrimaryLeft",
          "startPosition": 3.1
        },
        {
          "delay": 1000,
          "drawFromPosition": 0,
          "drawFull": true,
          "drawToPosition": 100,
          "name": "Left1",
          "startPosition": 25
        },
        {
          "delay": 1000,
          "drawFromPosition": 0,
          "drawFull": true,
          "drawToPosition": 100,
          "name": "Equator",
          "startPosition": 50
        },
        {
          "delay": 1000,
          "drawFromPosition": 0,
          "drawFull": true,
          "drawToPosition": 100,
          "name": "Right1",
          "startPosition": 75
        },
        {
          "delay": 1000,
          "drawFromPosition": 100,
          "drawFull": true,
          "drawToPosition": 0,
          "name": "PrimaryRight",
          "startPosition": 96.9
        }
      ],
      "verticalDelayDirection": -1
    },
    {
      "circle": [
        {
          "clockwise": true,
          "collapse": true,
          "drawAngle": 360,
          "drawFromAngle": 180,
          "drawFull": true,
          "duration": 1500,
          "startBoundaryX": 25,
          "startPositionX": 50,
          "startPositionY": 50
        },
        {
          "clockwise": false,
          "collapse": true,
          "drawAngle": 360,
          "drawFromAngle": 180,
          "drawFull": true,
          "duration": 1500,
          "startBoundaryX": 25,
          "startPositionX": 0,
          "startPositionY": 50
        },
        {
          "clockwise": false,
          "collapse": true,
          "drawAngle": 360,
          "drawFromAngle": 180,
          "drawFull": true,
          "duration": 1500,
          "startBoundaryX": 25,
          "startPositionX": 100,
          "startPositionY": 50
        }
      ],
      "delayHorizontal": true,
      "delayVertical": true,
      "drawBorder": true,
      "duration": "1000",
      "horizontal": [
        {
          "drawFromPosition": 0,
          "drawFull": true,
          "drawToPosition": 100,
          "name": "PrimaryTop",
          "startPosition": 5.6
        },
        {
          "delay": 100,
          "drawFromPosition": 0,
          "drawFull": true,
          "drawToPosition": 100,
          "endPosition": 50,
          "name": "Equator",
          "startPosition": 22.2
        },
        {
          "delay": 200,
          "drawFromPosition": 0,
          "drawFull": true,
          "drawToPosition": 100,
          "endPosition": 94.4,
          "name": "PrimaryBottom",
          "startPosition": 50
        },
        {
          "delay": 100,
          "drawFromPosition": 0,
          "drawFull": true,
          "drawToPosition": 100,
          "endPosition": 101,
          "name": "NewPrimaryBottom",
          "startPosition": 94.4
        }
      ],
      "id": 0,
      "mask": [
        {
          "asset": 0,
          "container": "canvas",
          "coverFill": true,
          "fadeOut": true,
          "grid": 0,
          "horizontal": [
            "Top Border",
            "Bottom Border"
          ],
          "name": "BG",
          "vertical": [
            "Left Border",
            "Right Border"
          ]
        }
      ],
      "name": "CoverExit",
      "vertical": [
        {
          "delay": 1000,
          "drawFromPosition": 100,
          "drawFull": true,
          "drawToPosition": 0,
          "name": "PrimaryLeft",
          "startPosition": 3.1
        },
        {
          "drawFromPosition": 0,
          "drawFull": true,
          "drawToPosition": 100,
          "endPosition": 26,
          "name": "Left1",
          "startPosition": 25
        },
        {
          "delay": 1000,
          "drawFromPosition": 0,
          "drawFull": true,
          "drawToPosition": 100,
          "name": "Equator",
          "startPosition": 50
        },
        {
          "drawFromPosition": 0,
          "drawFull": true,
          "drawToPosition": 100,
          "endPosition": 74,
          "name": "Right1",
          "startPosition": 75
        },
        {
          "delay": 1000,
          "drawFromPosition": 100,
          "drawFull": true,
          "drawToPosition": 0,
          "name": "PrimaryRight",
          "startPosition": 96.9
        }
      ],
      "verticalDelayDirection": -1
    }
  ]