import gsap from 'gsap';

import Letter from './letter';

export default class Word {
    constructor (word, index, count, ctx, style) {
        this.ctx = ctx;
        this.word = word;
        this.count = count;
        this.index = index;
        this.direction = (this.index % 2);
        this.letters = word.split('').map((l, i) => new Letter(l, this.index, i, count));
        this.cursorStart = 0
        this.fontStyle = style
        this.createTween(.5, 0);
    }

    createTween (duration, delay, repeat=-1) {
        if (this.tween) {
          this.tween.kill();
          this.tween = null;
        }

        this.repeat = repeat;
        const steps = (this.count > 1) ? 1 : 3;
        this.tween = gsap.timeline({
            repeat:-1,
            repeatDelay: 0,
            paused:true
          });
          this.tween.to(this.letters, {
            duration,
            fontIndex: (this.index + steps),
            ease: `steps(${steps})`,
            stagger: {
              amount: duration,
              // each: stagger,
              from: this.direction ? 'start' : 'end'
            }
          });
          this.tween.to(this.letters, {
            duration,
            fontIndex: this.index,
            ease: `steps(${steps})`,
            stagger: {
              amount: duration,
              // each: stagger,
              from: this.direction ? 'end' : 'start'
            }
          });
    }

    play() {
      this.tween.play()
    }

    stop() {
      this.tween.pause();
      this.tween.restart();
    }
    
    setBaseline (baseline) {
        this.letters.forEach(letter => {
            letter.setBaseline(baseline);
        });
    }

    setSize (size) {
        this.letters.forEach(letter => {
            letter.setSize(size);
        });
    }

    draw (cursor, style) {
        this.cursorStart = cursor
        this.letters.forEach(letter => {
            letter.draw(this.ctx, cursor, this.fontStyle);
            cursor += this.ctx.measureText(letter.value).width + 0.3;
        })
        return cursor;
    }
}
