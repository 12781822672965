import React, {
    CSSProperties,
    useEffect,
    useState
} from 'react';
import {
    AnimatePresence,
    usePresence
} from 'framer-motion';

import {
    MultiLangText,
    secToMs,
    setAnimationTimeout
} from '../../shared';
import ServiceItem, { ServiceItemProps } from './ServiceItem';
import { DEFAULT_DURATION_S } from '../../constants/transition';


export type ServiceInfo = {
    title: MultiLangText,
    description: MultiLangText,
};

export type ServiceLangTimingCnonfig = {
    gridDuration?: number,
    activeItemDisplayDuration?: number,
    nextItemDelay?: number,
};

export type ServiceItemTimingConfig = {
    animationDuration?: number,
}

export type ServiceLangProps = {
    list: ServiceInfo[],
    language: string,
    width: number,
    cellHeight: number,
    timingConfig?: ServiceLangTimingCnonfig,
    itemTimingConfig?: ServiceItemTimingConfig,
    onListDisplayFinished?: () => void,
};

const gridLineThickness = 1;

export default function ServiceLang(props: ServiceLangProps) {
    const {
        list,
        language,
        width,
        cellHeight,
        timingConfig = {},
        itemTimingConfig = {},
        onListDisplayFinished,
    } = props;
    const {
        activeItemDisplayDuration = DEFAULT_DURATION_S,
        nextItemDelay = 0,
    } = timingConfig;
    const {
        animationDuration,
    } = itemTimingConfig;

    const halfGridLineThickness = gridLineThickness / 2;

    const [isPresent, safeToRemove] = usePresence();
    const [showDetailsFor, setShowDetailsFor] = useState(-1);
    const [hideDetailsFor, setHideDetailsFor] = useState(-1);
    const [currentActiveIndex, setCurrentActiveIndex] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [serviceItems, setServiceItems] = useState([]);

    useEffect(() => {
        const items = new Array(list.length + 1);
        for (
            let listIndex = 0, itemIndex = 0;
            listIndex < list.length;
            listIndex++, itemIndex++
        ) {
            const {title, description} = list[listIndex];
            const style: CSSProperties = {
                width,
                borderBottomWidth: `${gridLineThickness}px`,
            };

            let animate = 'show';
            let onAnimationComplete: () => void;
            if (listIndex === hideDetailsFor) {
                animate = 'shrink';
                onAnimationComplete = () => {
                    setActiveIndex(hideDetailsFor + 1);
                };
            } else if (listIndex === showDetailsFor) {
                animate = 'grow';
                onAnimationComplete = () => {
                    setAnimationTimeout(() => {
                        setHideDetailsFor(showDetailsFor);
                    }, secToMs(activeItemDisplayDuration));
                };
            }

            const isLast = listIndex === list.length - 1;
            const onEntryAnimationComplete = isLast ? () => setShowDetailsFor(i => i + 1) : undefined;

            const toDelayItemAmination = showDetailsFor === -1 || !isPresent;
            const serviceItemProps: ServiceItemProps = {
                title,
                description,
                language,
                rowHeight: cellHeight,
                toCodifyTitle: true,
                animationDuration,
            };
            items[itemIndex] = (
                <ServiceItem
                    {...{
                        ...serviceItemProps,
                        key: listIndex,
                        containerProps: {
                            className: 'service-lang-item',
                            style,
                            animate,
                            exit: 'hide',
                        },
                        delay: toDelayItemAmination ? nextItemDelay * listIndex : 0,
                        onAnimationComplete,
                        onEntryAnimationComplete,
                    }}
                />
            );

            if (listIndex === currentActiveIndex) {
                itemIndex++;

                const onActiveEntryComplete = listIndex !== 0 ? () => setShowDetailsFor(currentActiveIndex) : undefined;
                const onExitActiveComplete = isLast ? onListDisplayFinished : () => setCurrentActiveIndex(i => i + 1);

                items[itemIndex] = listIndex === activeIndex ? (
                    <ServiceItem
                        {...{
                            ...serviceItemProps,
                            key: `${listIndex}-active`,
                            toCodifyTitle: listIndex === 0,
                            containerProps: {
                                className: 'service-lang-item active',
                                style: {
                                    ...style,
                                    gridRowStart: itemIndex,
                                },
                                initial: 'hide',
                                animate,
                                exit: 'hide',
                            },
                            onEntryAnimationComplete: onActiveEntryComplete,
                            onExitComplete: onExitActiveComplete
                        }}
                    />
                ) : null;
            }
        }

        setServiceItems(items);
    }, [list, showDetailsFor, hideDetailsFor, currentActiveIndex, activeIndex]);

    useEffect(() => {
        if (isPresent) {
            return;
        }

        const cancelExitAnimations = [];
        for (let index = 0; index <= serviceItems.length - 1; index++) {
            cancelExitAnimations.push(setAnimationTimeout(() => {
                serviceItems.pop();
                setServiceItems([...serviceItems]);
            }, secToMs(nextItemDelay * index)));
        }

        return () => {
            cancelExitAnimations.forEach(cancel => cancel());
        }
    }, [isPresent]);


    return (
        <div
            className='service-list'
            style={{
                top: `${cellHeight + halfGridLineThickness}px`,
                gridTemplateRows: new Array(serviceItems.length).fill('auto').join(' ')
            }}
        >
            <AnimatePresence onExitComplete={() => {
                !isPresent && safeToRemove();
            }}>
                {serviceItems}
            </AnimatePresence>
        </div>
    );
}
