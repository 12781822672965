import cls from 'classnames';
import React, { forwardRef } from 'react';

export const Container = forwardRef(({ children, className, style }, ref) => {
	return (
		<div className={cls(className, 'c-container')} ref={ref} style={style}>
			{/* DELETE: lines not necessary once grid is available */}
			<div className="c-container__vr c-container__vr--l" />
			<div className="c-container__vr c-container__vr--r" />
			<div className="c-container__hr c-container__hr--t" />
			{/* END DELETE */}
			{children}
		</div>
	);
});
