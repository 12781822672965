import { BASE_HEIGHT, BASE_WIDTH } from '../constants/hub';

export const map = (value, low1, high1, low2, high2) =>
  low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);

export const random = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

/**
 * Returns the vw value for any particular figma px value.
 *
 * @param {Number} px
 * @param {Boolean} returnInt
 * @returns {Number|String}
 */
export const figmaToVw = (px, returnInt = false) => {
  const val = px * 0.0521;

  return returnInt ? val : `${val}vw`;
};

export const figmaToPx = ({
  figmaPx,
  actualDimension,
  dimension = 'height',
  baseHeight = BASE_HEIGHT,
  baseWidth = BASE_WIDTH,
}) => {
  const modifier = dimension === 'height' ? baseHeight : baseWidth;

  return figmaPx * (actualDimension / modifier);
};
