import {SIZES} from "../constants";

export default (trackHeight, size) => {
  const circleLinesPositions =
    trackHeight === 225 ? [1.5, 63.3, 90.65] : [1.18, 63, 90.85];
  const top = size === SIZES.COMPACT ? 97 : 87.5;
  const bottom = size === SIZES.COMPACT ? 3 : 12.5;

  return [
    {
      drawBorder: false,
      mask: [],
      insetBorder: true,
      duration: 1000,
      circle: [
        {
          clockwise: false,
          drawFull: false,
          name: 'large left',
          drawAngle: 360,
          startPositionX: 6.7,
          startBoundaryY: 37.5,
          delay: 1100,
          drawFromAngle: 0,
          startPositionY: 50,
        },
        {
          name: 'small left',
          startBoundaryY: 18,
          drawFromAngle: 0,
          clockwise: false,
          drawAngle: 360,
          drawFull: false,
          delay: 1100,
          startPositionY: 50,
          startPositionX: 6.7,
        },
        {
          startBoundaryY: 18,
          startPositionX: 49.4,
          drawFull: false,
          startPositionY: 50,
          name: 'small center',
          delay: 1100,
          drawFromAngle: 90,
          drawAngle: 180,
          clockwise: false,
        },
        {
          delay: 1100,
          startBoundaryY: 37.5,
          name: 'large center',
          drawAngle: 180,
          drawFromAngle: 90,
          startPositionY: 50,
          clockwise: false,
          startPositionX: 49.4,
          drawFull: false,
        },
        {
          drawFull: false,
          delay: 1100,
          startPositionY: bottom,
          drawFromAngle: 90,
          name: 'left outer track',
          clockwise: true,
          drawAngle: 90,
          startPositionX: 68.5,
          startBoundaryY: 37.5,
        },
        {
          delay: 1100,
          drawAngle: 90,
          drawFromAngle: 90,
          clockwise: true,
          startPositionX: 68.5,
          drawFull: false,
          startPositionY: bottom,
          name: 'left inner track',
          startBoundaryY: 19,
        },
        {
          startPositionY: top,
          name: 'Right outer track',
          clockwise: true,
          startBoundaryY: 37.5,
          delay: 1100,
          startPositionX: 88,
          drawFromAngle: 270,
          drawFull: false,
          drawAngle: 90,
        },
        {
          startBoundaryY: 19,
          delay: 1100,
          drawAngle: 90,
          startPositionY: top,
          drawFromAngle: 270,
          clockwise: true,
          name: 'right inner track',
          startPositionX: 88,
          drawFull: false,
        },
      ],
      horizontal: [
        {
          startPosition: 31.5,
          drawFull: false,
          drawFromPosition: circleLinesPositions[1],
          name: 'top track line',
          delay: 1100,
          drawToPosition: 74.6,
        },
        {
          delay: 1100,
          drawFromPosition: 82,
          name: 'bottom track line',
          drawFull: false,
          startPosition: 68.5,
          drawToPosition: 88,
        },
      ],
      vertical: [
        {
          drawFromLine: null,
          name: 'left',
          drawToPosition: 0,
          drawToLine: null,
          startPosition: circleLinesPositions[0],
          drawFromPosition: 100,
          drawFull: false,
        },
        {
          drawFull: false,
          name: 'left seg',
          delay: 100,
          drawToLine: null,
          drawFromPosition: 100,
          startPosition: 21,
          drawToPosition: 0,
          drawFromLine: null,
          segmentGroup: {
            numLines: '3',
            segmentPositionEnd: 26.9,
          },
        },
        {
          drawFromPosition: 100,
          startPosition: 32.9,
          segmentGroup: {
            numLines: '2',
            segmentPositionEnd: 35.9,
          },
          name: 'left track',
          drawToPosition: 0,
          drawFromLine: null,
          drawToLine: null,
          delay: 400,
          drawFull: false,
        },
        {
          name: 'center track',
          delay: 600,
          drawFromLine: null,
          drawFull: false,
          drawFromPosition: 100,
          drawToPosition: 0,
          startPosition: 43.5,
          drawToLine: null,
          segmentGroup: {
            numLines: '2',
            segmentPositionEnd: 49.37,
          },
        },
        {
          name: 'center right',
          drawToLine: null,
          drawFromPosition: 100,
          drawToPosition: 0,
          delay: 700,
          startPosition: circleLinesPositions[1],
          drawFromLine: null,
          drawFull: false,
        },
        {
          drawFull: false,
          drawFromPosition: 100,
          segmentGroup: {
            segmentPositionEnd: 74.6,
            numLines: '3',
          },
          delay: 800,
          drawToPosition: 0,
          name: 'right seg',
          drawFromLine: null,
          drawToLine: null,
          startPosition: 68.6,
        },
        {
          drawFull: false,
          name: 'right',
          drawToPosition: 0,
          drawToLine: null,
          delay: 1100,
          drawFromPosition: 100,
          startPosition: 82,
          drawFromLine: null,
        },
        {
          startPosition: 88,
          drawFromPosition: 100,
          delay: 1200,
          drawFull: false,
          drawToPosition: 0,
          drawToLine: null,
          drawFromLine: null,
          name: 'right track',
          segmentGroup: {
            segmentPositionEnd: circleLinesPositions[2],
            numLines: '2',
          },
        },
      ],
    },
    {
      circle: [
        {
          name: 'large left',
          delay: 1000,
          startPositionX: 6.7,
          drawFromAngle: 0,
          startPositionY: 50,
          drawAngle: 360,
          clockwise: false,
          startBoundaryY: 37.5,
          drawFull: false,
        },
        {
          drawFromAngle: 0,
          startPositionX: 6.7,
          drawFull: true,
          clockwise: true,
          collapse: true,
          name: 'left large off',
          startBoundaryY: 37.5,
          drawAngle: 360,
          startPositionY: 50,
        },
        {
          drawFromAngle: 0,
          startBoundaryY: 18,
          clockwise: false,
          startPositionX: 6.7,
          name: 'small left',
          drawFull: false,
          startPositionY: 50,
          delay: 1000,
          drawAngle: 360,
        },
        {
          startPositionX: 6.7,
          startBoundaryY: 18,
          name: 'Left Small Off',
          collapse: true,
          clockwise: true,
          drawFull: true,
          startPositionY: 50,
          drawAngle: 360,
          drawFromAngle: 0,
        },
        {
          drawAngle: 180,
          startPositionY: 50,
          drawFull: false,
          clockwise: false,
          drawFromAngle: 90,
          name: 'small center',
          startBoundaryY: 18,
          startPositionX: 49.4,
          delay: 1000,
        },
        {
          startPositionY: 50,
          startBoundaryY: 18,
          name: 'small center off',
          collapse: true,
          drawAngle: 180,
          clockwise: true,
          startPositionX: 49.4,
          drawFromAngle: 270,
          drawFull: true,
        },
        {
          drawFull: false,
          startPositionY: 50,
          drawAngle: 180,
          name: 'large center',
          clockwise: false,
          startBoundaryY: 37.5,
          delay: 1000,
          drawFromAngle: 90,
          startPositionX: 49.4,
        },
        {
          startPositionY: 50,
          drawFromAngle: 270,
          startPositionX: 49.4,
          clockwise: true,
          collapse: true,
          drawAngle: 180,
          drawFull: true,
          startBoundaryY: 37.5,
          name: 'large center off',
        },
        {
          name: 'left outer track',
          drawFull: false,
          startPositionX: 68.5,
          delay: 1000,
          clockwise: true,
          drawFromAngle: 90,
          startPositionY: bottom,
          startBoundaryY: 37.5,
          drawAngle: 90,
        },
        {
          drawFull: true,
          clockwise: false,
          name: 'left outer track off',
          startPositionX: 68.5,
          startPositionY: bottom,
          startBoundaryY: 37.5,
          drawAngle: 90,
          collapse: true,
          drawFromAngle: 180,
        },
        {
          startPositionY: bottom,
          startBoundaryY: 19,
          drawFromAngle: 90,
          delay: 1000,
          clockwise: true,
          startPositionX: 68.5,
          drawFull: false,
          drawAngle: 90,
          name: 'left inner track',
        },
        {
          drawFull: true,
          collapse: true,
          drawFromAngle: 180,
          drawAngle: 90,
          startPositionX: 68.5,
          name: 'left inner track off',
          clockwise: false,
          startPositionY: bottom,
          startBoundaryY: 19,
        },
        {
          drawFromAngle: 270,
          startPositionX: 88,
          drawFull: false,
          name: 'Right outer track',
          startBoundaryY: 37.5,
          drawAngle: 90,
          clockwise: true,
          delay: 1000,
          startPositionY: top,
        },
        {
          clockwise: false,
          drawFull: true,
          drawFromAngle: 0,
          startBoundaryY: 37.5,
          startPositionX: 88,
          name: 'Right outer track off',
          collapse: true,
          drawAngle: 90,
          startPositionY: top,
        },
        {
          collapse: false,
          startPositionY: top,
          startBoundaryY: 19,
          delay: 1000,
          drawFromAngle: 270,
          startPositionX: 88,
          clockwise: true,
          name: 'right inner track',
          drawAngle: 90,
          drawFull: false,
        },
        {
          name: 'Right inner track off',
          startPositionY: top,
          drawFromAngle: 0,
          clockwise: false,
          drawFull: true,
          collapse: true,
          startPositionX: 88,
          startBoundaryY: 19,
          drawAngle: 90,
        },
      ],
      duration: 1000,
      mask: [],
      drawBorder: false,
      vertical: [
        {
          drawFromPosition: 100,
          drawToPosition: 0,
          drawFull: true,
          drawToLine: null,
          startPosition: circleLinesPositions[0],
          name: 'left',
          drawFromLine: null,
        },
        {
          drawToLine: null,
          delay: 100,
          name: 'left seg',
          drawFromPosition: 100,
          startPosition: 21,
          drawFromLine: null,
          segmentGroup: {
            segmentPositionEnd: 26.9,
            numLines: '3',
          },
          drawFull: true,
          drawToPosition: 0,
        },
        {
          drawFromLine: null,
          startPosition: 32.9,
          drawFromPosition: 100,
          drawToLine: null,
          drawFull: true,
          name: 'left track',
          drawToPosition: 0,
          delay: 400,
          segmentGroup: {
            segmentPositionEnd: 35.9,
            numLines: '2',
          },
        },
        {
          name: 'center track',
          segmentGroup: {
            segmentPositionEnd: 49.37,
            numLines: '2',
          },
          delay: 600,
          drawFull: true,
          drawToLine: null,
          drawFromLine: null,
          drawToPosition: 0,
          startPosition: 43.5,
          drawFromPosition: 100,
        },
        {
          drawFull: true,
          delay: 700,
          name: 'center right',
          drawToLine: null,
          drawToPosition: 0,
          drawFromPosition: 100,
          drawFromLine: null,
          startPosition: circleLinesPositions[1],
        },
        {
          delay: 800,
          drawToPosition: 0,
          drawFromPosition: 100,
          segmentGroup: {
            segmentPositionEnd: 74.6,
            numLines: '3',
          },
          drawFull: true,
          drawFromLine: null,
          startPosition: 68.6,
          name: 'right seg',
          drawToLine: null,
        },
        {
          drawFromPosition: 100,
          drawToLine: null,
          drawFull: true,
          drawFromLine: null,
          startPosition: 82,
          delay: 1100,
          name: 'right',
          drawToPosition: 0,
        },
        {
          startPosition: 88,
          segmentGroup: {
            numLines: '2',
            segmentPositionEnd: circleLinesPositions[2],
          },
          delay: 1200,
          drawToPosition: 0,
          drawFull: true,
          drawToLine: null,
          drawFromLine: null,
          drawFromPosition: 100,
          name: 'right track',
        },
      ],
      insetBorder: true,
      horizontal: [
        {
          drawFromPosition: circleLinesPositions[1],
          delay: 1100,
          name: 'top track line',
          drawToPosition: 74.6,
          drawFull: true,
          startPosition: 31.5,
        },
        {
          drawToPosition: 88,
          delay: 1100,
          startPosition: 68.5,
          drawFromPosition: 82,
          name: 'bottom track line',
          drawFull: true,
        },
      ],
    },
    {
      circle: [
        {
          name: 'large left',
          delay: 1000,
          startPositionX: 6.7,
          drawFromAngle: 0,
          startPositionY: 50,
          drawAngle: 360,
          clockwise: false,
          startBoundaryY: 37.5,
          drawFull: false,
        },
        {
          drawFromAngle: 0,
          startPositionX: 6.7,
          drawFull: true,
          clockwise: true,
          collapse: true,
          name: 'left large off',
          startBoundaryY: 37.5,
          drawAngle: 360,
          startPositionY: 50,
        },
        {
          drawFromAngle: 0,
          startBoundaryY: 18,
          clockwise: false,
          startPositionX: 6.7,
          name: 'small left',
          drawFull: false,
          startPositionY: 50,
          delay: 1000,
          drawAngle: 360,
        },
        {
          startPositionX: 6.7,
          startBoundaryY: 18,
          name: 'Left Small Off',
          collapse: true,
          clockwise: true,
          drawFull: true,
          startPositionY: 50,
          drawAngle: 360,
          drawFromAngle: 0,
        },
        {
          drawAngle: 180,
          startPositionY: 50,
          drawFull: false,
          clockwise: false,
          drawFromAngle: 90,
          name: 'small center',
          startBoundaryY: 18,
          startPositionX: 49.4,
          delay: 1000,
        },
        {
          startPositionY: 50,
          startBoundaryY: 18,
          name: 'small center off',
          collapse: true,
          drawAngle: 180,
          clockwise: true,
          startPositionX: 49.4,
          drawFromAngle: 270,
          drawFull: true,
        },
        {
          drawFull: false,
          startPositionY: 50,
          drawAngle: 180,
          name: 'large center',
          clockwise: false,
          startBoundaryY: 37.5,
          delay: 1000,
          drawFromAngle: 90,
          startPositionX: 49.4,
        },
        {
          startPositionY: 50,
          drawFromAngle: 270,
          startPositionX: 49.4,
          clockwise: true,
          collapse: true,
          drawAngle: 180,
          drawFull: true,
          startBoundaryY: 37.5,
          name: 'large center off',
        },
        {
          name: 'left outer track',
          drawFull: false,
          startPositionX: 68.5,
          delay: 1000,
          clockwise: true,
          drawFromAngle: 90,
          startPositionY: bottom,
          startBoundaryY: 37.5,
          drawAngle: 90,
        },
        {
          drawFull: true,
          clockwise: false,
          name: 'left outer track off',
          startPositionX: 68.5,
          startPositionY: bottom,
          startBoundaryY: 37.5,
          drawAngle: 90,
          collapse: true,
          drawFromAngle: 180,
        },
        {
          startPositionY: bottom,
          startBoundaryY: 19,
          drawFromAngle: 90,
          delay: 1000,
          clockwise: true,
          startPositionX: 68.5,
          drawFull: false,
          drawAngle: 90,
          name: 'left inner track',
        },
        {
          drawFull: true,
          collapse: true,
          drawFromAngle: 180,
          drawAngle: 90,
          startPositionX: 68.5,
          name: 'left inner track off',
          clockwise: false,
          startPositionY: bottom,
          startBoundaryY: 19,
        },
        {
          drawFromAngle: 270,
          startPositionX: 88,
          drawFull: false,
          name: 'Right outer track',
          startBoundaryY: 37.5,
          drawAngle: 90,
          clockwise: true,
          delay: 1000,
          startPositionY: top,
        },
        {
          clockwise: false,
          drawFull: true,
          drawFromAngle: 0,
          startBoundaryY: 37.5,
          startPositionX: 88,
          name: 'Right outer track off',
          collapse: true,
          drawAngle: 90,
          startPositionY: top,
        },
        {
          collapse: false,
          startPositionY: top,
          startBoundaryY: 19,
          delay: 1000,
          drawFromAngle: 270,
          startPositionX: 88,
          clockwise: true,
          name: 'right inner track',
          drawAngle: 90,
          drawFull: false,
        },
        {
          name: 'Right inner track off',
          startPositionY: top,
          drawFromAngle: 0,
          clockwise: false,
          drawFull: true,
          collapse: true,
          startPositionX: 88,
          startBoundaryY: 19,
          drawAngle: 90,
        },
      ],
      duration: 1000,
      mask: [],
      drawBorder: false,
      vertical: [
        {
          drawFromPosition: 100,
          drawToPosition: 0,
          drawFull: true,
          drawToLine: null,
          startPosition: circleLinesPositions[0],
          name: 'left',
          drawFromLine: null,
        },
        {
          drawToLine: null,
          delay: 100,
          name: 'left seg',
          drawFromPosition: 100,
          startPosition: 21,
          drawFromLine: null,
          segmentGroup: {
            segmentPositionEnd: 26.9,
            numLines: '3',
          },
          drawFull: true,
          drawToPosition: 0,
        },
        {
          drawFromLine: null,
          startPosition: 32.9,
          drawFromPosition: 100,
          drawToLine: null,
          drawFull: true,
          name: 'left track',
          drawToPosition: 0,
          delay: 400,
          segmentGroup: {
            segmentPositionEnd: 35.9,
            numLines: '2',
          },
        },
        {
          name: 'center track',
          segmentGroup: {
            segmentPositionEnd: 49.37,
            numLines: '2',
          },
          delay: 600,
          drawFull: true,
          drawToLine: null,
          drawFromLine: null,
          drawToPosition: 0,
          startPosition: 43.5,
          drawFromPosition: 100,
        },
        {
          drawFull: true,
          delay: 700,
          name: 'center right',
          drawToLine: null,
          drawToPosition: 0,
          drawFromPosition: 100,
          drawFromLine: null,
          startPosition: circleLinesPositions[1],
        },
        {
          delay: 800,
          drawToPosition: 0,
          drawFromPosition: 100,
          segmentGroup: {
            segmentPositionEnd: 74.6,
            numLines: '3',
          },
          drawFull: true,
          drawFromLine: null,
          startPosition: 68.6,
          name: 'right seg',
          drawToLine: null,
        },
        {
          drawFromPosition: 100,
          drawToLine: null,
          drawFull: true,
          drawFromLine: null,
          startPosition: 82,
          delay: 1100,
          name: 'right',
          drawToPosition: 0,
        },
        {
          startPosition: 88,
          segmentGroup: {
            numLines: '2',
            segmentPositionEnd: circleLinesPositions[2],
          },
          delay: 1200,
          drawToPosition: 0,
          drawFull: true,
          drawToLine: null,
          drawFromLine: null,
          drawFromPosition: 100,
          name: 'right track',
        },
      ],
      insetBorder: true,
      horizontal: [
        {
          drawFromPosition: circleLinesPositions[1],
          delay: 1100,
          name: 'top track line',
          drawToPosition: 74.6,
          drawFull: true,
          startPosition: 31.5,
        },
        {
          drawToPosition: 88,
          delay: 1100,
          startPosition: 68.5,
          drawFromPosition: 82,
          name: 'bottom track line',
          drawFull: true,
        },
      ],
    },
    {
      horizontal: [
        {
          startPosition: 31.5,
          drawFromPosition: 74.6,
          drawToPosition: circleLinesPositions[1],
          drawDirection: -1,
          drawFull: true,
          name: 'top track line',
        },
        {
          startPosition: 68.5,
          drawDirection: -1,
          drawFromPosition: 88,
          drawFull: true,
          name: 'bottom track line',
          drawToPosition: 82,
        },
      ],
      circle: [
        {
          drawFull: true,
          collapse: true,
          name: 'left large off',
          clockwise: true,
          startPositionX: 6.7,
          startPositionY: 50,
          drawAngle: 360,
          startBoundaryY: 37.5,
          drawFromAngle: 0,
        },
        {
          drawFromAngle: 0,
          startBoundaryY: 18,
          startPositionX: 6.7,
          startPositionY: 50,
          collapse: true,
          clockwise: true,
          drawFull: true,
          drawAngle: 360,
          name: 'Left Small Off',
        },
        {
          drawAngle: 180,
          name: 'small center off',
          collapse: true,
          startBoundaryY: 18,
          clockwise: true,
          startPositionY: 50,
          startPositionX: 49.4,
          drawFull: true,
          drawFromAngle: 270,
        },
        {
          startPositionY: 50,
          drawAngle: 180,
          collapse: true,
          drawFromAngle: 270,
          startPositionX: 49.4,
          startBoundaryY: 37.5,
          clockwise: true,
          name: 'large center off',
          drawFull: true,
        },
        {
          clockwise: false,
          drawFromAngle: 180,
          collapse: true,
          startPositionX: 68.5,
          startBoundaryY: 37.5,
          name: 'left outer track off',
          startPositionY: bottom,
          drawAngle: 90,
          drawFull: true,
        },
        {
          collapse: true,
          clockwise: false,
          startPositionX: 68.5,
          drawAngle: 90,
          startPositionY: bottom,
          name: 'left inner track off',
          startBoundaryY: 19,
          drawFromAngle: 180,
          drawFull: true,
        },
        {
          collapse: true,
          drawFromAngle: 0,
          drawAngle: 90,
          startPositionX: 88,
          startBoundaryY: 37.5,
          name: 'Right outer track off',
          clockwise: false,
          startPositionY: top,
          drawFull: true,
        },
        {
          drawFromAngle: 0,
          startBoundaryY: 19,
          startPositionY: top,
          collapse: true,
          name: 'Right inner track off',
          drawAngle: 90,
          startPositionX: 88,
          clockwise: false,
          drawFull: true,
        },
      ],
      insetBorder: true,
      drawBorder: false,
      vertical: [
        {
          drawFull: true,
          name: 'left',
          drawToPosition: 100,
          drawToLine: null,
          delay: 1400,
          startPosition: circleLinesPositions[0],
          drawDirection: -1,
          drawFromPosition: 0,
        },
        {
          delay: 1100,
          segmentGroup: {
            segmentPositionEnd: 26.9,
            numLines: '3',
          },
          drawFull: true,
          startPosition: 21,
          drawToPosition: 100,
          drawFromPosition: 0,
          name: 'left seg',
          drawFromLine: null,
          drawDirection: -1,
        },
        {
          drawFull: true,
          drawFromLine: null,
          name: 'left track',
          drawToPosition: 100,
          startPosition: 32.9,
          drawDirection: -1,
          drawFromPosition: 0,
          delay: 900,
          segmentGroup: {
            segmentPositionEnd: 35.9,
            numLines: '2',
          },
        },
        {
          drawFull: true,
          drawDirection: -1,
          segmentGroup: {
            numLines: '2',
            segmentPositionEnd: 49.37,
          },
          delay: 700,
          startPosition: 43.5,
          drawToLine: null,
          drawFromLine: null,
          name: 'center track',
          drawToPosition: 100,
          drawFromPosition: 0,
        },
        {
          drawFull: true,
          name: 'center right',
          drawToPosition: 100,
          startPosition: circleLinesPositions[1],
          drawFromPosition: 0,
          delay: 600,
          drawDirection: -1,
          drawToLine: null,
        },
        {
          startPosition: 68.6,
          drawDirection: -1,
          name: 'right seg',
          drawFull: true,
          delay: 300,
          drawFromPosition: 0,
          drawToPosition: 100,
          segmentGroup: {
            numLines: '3',
            segmentPositionEnd: 74.6,
          },
        },
        {
          drawFromLine: null,
          name: 'right',
          drawFromPosition: 0,
          drawFull: true,
          drawDirection: -1,
          drawToPosition: 100,
          delay: 200,
          startPosition: 82,
        },
        {
          drawDirection: -1,
          drawFromLine: null,
          segmentGroup: {
            numLines: '2',
            segmentPositionEnd: circleLinesPositions[2],
          },
          startPosition: 88,
          drawFull: true,
          drawToPosition: 100,
          name: 'right track',
          drawFromPosition: 0,
          drawToLine: null,
        },
      ],
      verticalDelayDirection: -1,
      duration: 1000,
      mask: [],
    },
  ];
};
