#pragma glslify: circle = require('../../../../lib/glsl/circle.glsl')
#pragma glslify: ring = require('../../../../lib/glsl/ring.glsl')
#pragma glslify: box = require('../../../../lib/glsl/box.glsl')
#pragma glslify: grid = require('../../../../lib/glsl/grid.glsl')
#pragma glslify: mosaic = require('../../../../lib/glsl/mosaic.glsl')
#pragma glslify: viewfinder = require('../../../../lib/glsl/viewfinder.glsl')

#pragma glslify: containUV = require('../../../../lib/glsl/containUV.glsl')
#pragma glslify: coverUV = require('../../../../lib/glsl/coverUV.glsl')
#pragma glslify: pixelateUV = require('../../../../lib/glsl/pixelateUV.glsl')
#pragma glslify: placedUV = require('../../../../lib/glsl/placedUV.glsl')
#pragma glslify: gammaCorrect = require('../../../../lib/glsl/gammaCorrect.glsl')

#ifdef GL_ES
precision mediump float;
#endif

varying vec2 vUv;

uniform float time;

// general stuffs
uniform vec2 res; // resolution
uniform float pixa; // pixelization amount (size of each cell/pixel)
uniform float pixr; // pixel ratio
uniform float stroke; // stroke width

uniform float useCover;

// rangefinder
uniform vec2 rfPos; // position (in pixels)
uniform float rfRadius; // radius of the ring
uniform float rfOpacity; // opacity (0-1)

// mosaic
uniform float mosaicAmount; // how much mosaic is covering the screen (0-1)
// uniform vec3 mosaicColor; // rgb color
uniform float mosaicColor; // greyscale colo

// background (video) texture
uniform sampler2D tex0; // sampler for the texture
uniform vec2 tex0Res; // size of the image (in pixels)

// grid
uniform vec2 gridSteps;
uniform float gridMosaic;
uniform float gridOpacity;

void main() {
  vec3 color = vec3(1.);

  vec2 st = (gl_FragCoord.xy / res) / pixr;
  float ar = (res.x / res.y);
  float px = ((res.x * pixr) / pixa);

  float thickness = (stroke * pixr);
  float thickdiff = (thickness / pixr / 100.);

  float b = 1.0;
  vec2 tex0UV = mix(containUV(vUv, res, tex0Res), coverUV(vUv, res, tex0Res), useCover); // yew...
  if ((tex0UV.x > 1. || tex0UV.x < 0.) || (tex0UV.y > 1. || tex0UV.y < 0.)) {
    b = 0.0;
  }
  float tex0AR = (tex0Res.x / tex0Res.y);

  color = mix(color, texture2D(tex0, tex0UV).rgb, b);

  vec2 cpos = (rfPos * pixr);
  float crad = (rfRadius  * pixr);

  // rangefinder
  float c1 = circle((gl_FragCoord.xy - cpos), crad);
  vec2 puv = pixelateUV(tex0UV, px, tex0AR);
  color = mix(color, texture2D(tex0, puv).rgb, step(c1, .5));
  float r1 = ring(c1, thickness);
  color = mix(color, vec3(r1), (1. - r1) * rfOpacity);

  // grid
  float g1 = grid(st, gridSteps, thickdiff);
  float go = gridOpacity * (1. - mosaic((st * 1.2), ar, time, gridMosaic));
  color = mix(color, vec3(g1), (1. - g1) * go);

  gl_FragColor = vec4(gammaCorrect(color.rgb, 2.2), 1);
}
