import React, {useEffect, useState} from 'react';
import "../../../rise/styles/normalize.css";
import '../../../jordan/styles/fonts.scss';
import Container from "../../../jordan/components/Container";
import Intro from "../../screens/intro";
import Event from "../../screens/event";
import {pixelMapReady} from "../../../../utilities";
import WrapAnimatePresence from "../../../jordan/components/WrapAnimatePresence";
import {useEcosystemBoardStore} from "./store";
import {ChapterRenderOptions} from "../../../jordan/components/ChapterRenderer";
import ChapterRenderer from "../../components/chapter-renderer";
import {AnimationData, prepareData} from "../../shared";
import {MotionConfig} from "framer-motion";
import {DEFAULT_TRANSITION} from "../../../rise/constants/transition";
import {get} from "lodash";

const chapterTypes: ChapterRenderOptions = {
    intro: Intro,
    raw_event: Event
};

export default function App({data = {}, openChapter = null, globalChannelSlug}) {
    const activeChapter = useEcosystemBoardStore(s => s.chapterIndex);
    const getChapter = useEcosystemBoardStore(s => s.getChapter);
    const nextChapter = useEcosystemBoardStore(s => s.nextChapter);
    const initialize = useEcosystemBoardStore(s => s.initialize);
    const chapters = useEcosystemBoardStore(s => s.chapters);

    const [key, setKey] = useState(0);

    useEffect(() => {
        prepareData(data as AnimationData, {
            excludeIntros: openChapter !== null,
            globalChannel: globalChannelSlug,
        }).then((data) => {
            const ch = openChapter ? [data.chapters[openChapter]] : data.chapters;
            initialize(ch);
        });

        pixelMapReady(() => {
            setKey(i => i + 1);
        });
    }, []);

    useEffect(() => {
        if (openChapter !== null || activeChapter === null) {
            return;
        }

        const duration = getChapter(activeChapter)?.duration ?? 8;
        setTimeout(nextChapter, duration * 1_000);
    }, [activeChapter, openChapter]);

    if (!chapters?.length) {
        return null;
    }

    return (
        <MotionConfig
            transition={{
                ...DEFAULT_TRANSITION
            }}
        >
            <Container
                framed={true}
                width={1080}
                height={1920}
            >
                <WrapAnimatePresence>
                    <ChapterRenderer key={activeChapter} activeChapter={activeChapter} chapterTypes={chapterTypes}/>
                </WrapAnimatePresence>
            </Container>
        </MotionConfig>
    )
}