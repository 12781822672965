import * as Hyperlive from "../../hyperlive/utils";
import * as GridUtils from "../../risegrid/utils";
import GRID_UNIVERSAL_COVER from "./grids/cityreplay.universal.cover.json";
import * as Logger from "../../utils/console";
import GRID_INTRO from './grids/cityreplay.intro.json';
import GRID_CHAPTER_SIZZLE from "./grids/cityreplay.sizzle.story.json";
import GRID_CHAPTER_REPAIR from "./grids/cityreplay.repair.chapter.1.json";
import GRID_CHAPTER_SERVICES_1 from "./grids/cityreplay.services.chapter.1.json";
import GRID_CHAPTER_SERVICES_2 from "./grids/cityreplay.services.chapter.2.json";
import GRID_CHAPTER_SERVICES_3 from "./grids/cityreplay.services.chapter.3.json";
import FPOAssetsVideo from "../../data/gridassets_video.js";

const SERVICES_GRIDS = [
	GRID_CHAPTER_SERVICES_1,
	GRID_CHAPTER_SERVICES_2,
	GRID_CHAPTER_SERVICES_3,
];

export const chapterSelector = (chapters, screen_type, HOLD_INTRO, HOLD_CHAPTER) => {
	const chapter_data = {};
	let assets = [[]];
	const holdFrames = [];
	const sequences = [GRID_INTRO]; //start with initial intro

	chapters.forEach((chapter, index) => {
		const { type } = chapter;
		chapter.id = `${type}.${index}`;
		let assetIndex = 0;

		switch (type) {
			case Hyperlive.ChapterTypes.Cover:
				// MAP ASSETS FROM HYPERLIVE TO GRID FRAMEWORK
				let cover_assets;
				if (chapter.hasOwnProperty('media')) {
					cover_assets = Hyperlive.remapAssets(
						chapter.media,
						type,
						index,
					);
					assets[0] = assets[0].concat(cover_assets);
				}

				// CUSTOMIZE GRID TEMPLATES
				// ADD CUSTOM ID TO EACH KEYFRAME
				sequences.push(
					GridUtils.remapKeyframe(GRID_UNIVERSAL_COVER, {
						append: `#${type}.${index}`,
						assets: cover_assets,
					}),
				);
				holdFrames.push(HOLD_INTRO);

				// ADD TO ANIMATION CHAPTERS
				chapter_data[chapter.id] = chapter;

				// MAP MEDIA TO GRID FRAMEWORK
				Logger.alert(`»» CHAPTER`);
				Logger.log('»» MEDIA', chapter.media);

				break;

			case Hyperlive.ChapterTypes.Story:
				// MAP ASSETS FROM HYPERLIVE TO GRID FRAMEWORK
				let story_assets;
				if (chapter.hasOwnProperty('media')) {
					story_assets = Hyperlive.remapAssets(
						chapter.media,
						type,
						index,
					);
					assets[0] = assets[0].concat(story_assets);
				}

				// CUSTOMIZE GRID TEMPLATES
				// ADD CUSTOM ID TO EACH KEYFRAME
				let CHAPTER = GRID_CHAPTER_SIZZLE;

				switch (screen_type) {
					case 'services':
						const arry_index = index % 3;
						Logger.alert(`$ services ${arry_index}`);
						CHAPTER = SERVICES_GRIDS[arry_index];
						break;

					case 'sizzle':
						CHAPTER = GRID_CHAPTER_SIZZLE;
						break;

					case 'repair':
						CHAPTER = GRID_CHAPTER_REPAIR;
						break;
				}

				sequences.push(
					GridUtils.remapKeyframe(CHAPTER, {
						append: `#${type}.${index}`,
						assets: story_assets,
					}),
				);
				holdFrames.push(HOLD_CHAPTER);

				// ADD TO ANIMATION CHAPTERS
				chapter_data[chapter.id] = chapter;

				// MAP MEDIA TO GRID FRAMEWORK
				Logger.alert(`»» CHAPTER`);
				Logger.log('»» MEDIA', chapter.media);

				break;

			default:
				// DO NOT ADD
				break;
		}

		Logger.highlight('* ASSET');
		Logger.log('*', chapter.media);
	});

	// FPO ASSETS
	Logger.alert('$$ ASSETS $$');
	Logger.log(assets);
	if (assets[0].length < 1) {
		// NEED ASSETS FOR GRID FRAMEWORK??
		assets = FPOAssetsVideo;
	}

	return {
		chapter_data,
		assets,
		holdFrames,
		sequences
	}
}
