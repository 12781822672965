import BaseTicker from "./BaseTicker";
import {useMemo} from "react";
import {MediaType, ThemeType} from "../shared";

export default function CustomTicker({duration, asset, theme, reverse = false}: {duration?: number, asset?: MediaType, theme: ThemeType, reverse?: boolean}) {
    const source = useMemo(() => {
        if (asset?.blob) {
            return URL.createObjectURL(asset.blob);
        }
        return asset?.url;
    }, [asset]);

    const filter = useMemo(() => {
        if (theme === 'light') {
            return 'invert(1)'
        }
    }, []);

    if (!asset?.url) {
        return null;
    }

    return (
        <div style={{height: '100%', flex: 1, marginRight: 0}}>
            <BaseTicker rotation={'90deg'} duration={duration} direction={reverse ? 'forwards' : 'backwards'}>
                <div style={{ width: '100%', height: '100%'}}>
                    <img style={{filter, width: 1920, height: 100, objectFit: 'scale-down'}} src={source} alt=""/>
                </div>
            </BaseTicker>
        </div>
    )
}