.chapter {
    height: 100%;
    width: 100%;
    position: relative;
    background: white;

    &:not(:first-child) {
        position: absolute;
        top: 0;
    }
}
