import React, {
    forwardRef,
    useEffect,
    CSSProperties,
    useMemo,
} from "react";
import {
    animate,
    Easing,
    MotionValue,
    useMotionValue,
} from "framer-motion";
import cn from "classnames";

import { EXCEPTIONS } from "./glyphs";
import Line from "./CodificationLine";
import Context from "./Codification.context";

import '../../styles/components/codification.scss';


const generateLines = (text = "") => {
    return text
        .replace("\t", "")
        .split("\n")
        .map((line) => {
            if (line === '') {
                return [];
            }

            return line.split(" ").map(word => {
                const characters = word.split("");
                let specialChar = "";

                return characters.reduce((result, char, index) => {
                    const nextChar = characters[index + 1];
                    const isException = EXCEPTIONS.includes(char);

                    if (nextChar && isException) {
                        specialChar = `${specialChar}${char}`;
                    } else {
                        result.push(`${specialChar}${char}`);
                        specialChar = "";
                    }

                    return result;
                }, []);
            });
        });
};

type TimingConfig = {
    control: 'character' | 'total',
    duration: number,
};

export type CodificationProps = {
    text: string,
    className?: string,
    style?: CSSProperties,
    characterSwitchAmount?: number,
    characterNextTrigger?: number,
    reverse?: boolean,
    delay?: number,
    ease?: Easing,
    timingConfig?: TimingConfig,
    externalAnimationTimeLine?: MotionValue<number>,
    onEnded?: () => void,
};


function Codification(props: CodificationProps, ref) {
    const {
        text,
        className = '',
        style,
        characterSwitchAmount = 12,
        characterNextTrigger = 6,
        delay = 0,
        ease = 'linear',
        reverse = false,
        timingConfig = {
            control: 'character',
            duration: 0.03,
        },
        externalAnimationTimeLine,
        onEnded,
    } = props;

    const lines = useMemo<string[][][]>(() => generateLines(text), [text]);
    const words = (lines && lines.flat()) || [];
    const characters = words.flat();
    const characterDuration = calcCharacterDuration(timingConfig, characters, characterSwitchAmount);

    const charIndexMotion = useMotionValue(0);

    useEffect(() => {
        if (!externalAnimationTimeLine) {
            return;
        }

        return externalAnimationTimeLine.onChange(value => {
            charIndexMotion.set(value);
        });
    }, []);

    useEffect(() => {
        if (externalAnimationTimeLine) {
            return;
        }

        const range = [0, characters.length];
        if (reverse) {
            range.reverse();
        }
        charIndexMotion.set(range[0]);

        const anim = animate(charIndexMotion, range[1], {
            delay,
            duration: calcTotalDuration(props, characters.length),
            ease,
            onComplete: onEnded,
        });

        return () => anim.stop();
    }, [text, reverse]);

    return (
        <Context.Provider
            value={{
                text,
                lines,
                words,
                repeat: characterSwitchAmount,
                trigger: characterNextTrigger,
                timeout: characterDuration,
                reverse,
                charIndexMotion,
            }}
        >
            <div
                ref={ref}
                className={cn("hoi-codification", className)}
                style={{
                    ...style
                }}
            >
                {lines.map((words, index) => (
                    words.length > 0 ? (
                        <Line
                            key={index}
                            words={words}
                            index={index}
                        />
                    ) : (
                        // if empty line then use this to give a height to the line
                        <span key={index}>&nbsp;</span>
                    )
                ))}
            </div>
        </Context.Provider>
    );
}

export default forwardRef(Codification);

function calcCharacterDuration(timingConfig: TimingConfig, textLength: number, characterSwitchAmount: number): number {
    if (!timingConfig?.duration) {
        return 0.03;
    }

    switch (timingConfig.control) {
        case 'character':
            return timingConfig.duration;

        case 'total':
            return timingConfig.duration / textLength / characterSwitchAmount;
    }
}

export function calcTotalDuration(props: CodificationProps, textLenght?: number): number {
    const {
        text,
        timingConfig = {
            control: 'character',
            duration: 0.03,
        },
        characterSwitchAmount,
    } = props;

    if (!textLenght) {
        const lines = generateLines(text);
        const words = (lines && lines.flat()) || [];
        textLenght = words.flat().length;
    }

    const charDuration = calcCharacterDuration(timingConfig, textLenght, characterSwitchAmount);

    const duration = charDuration * characterSwitchAmount * textLenght;

    return duration;
}
