import React, { useMemo, useState, useEffect } from 'react';
import FlipCard from './FlipCard';
import { generateFlipcardProps } from './shared';

const tickerColumns = 37;
const tickerRows = 1;

function FlipTicker({ screens, onPagesFinished, config, forceChapterFlip }) {
    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setPageNumber(pageNumber => {
                let newPageNumber = pageNumber;
                newPageNumber++;
                if (newPageNumber > screens[0].pages.length - 1) {
                    onPagesFinished();
                    newPageNumber = 0;
                }
                return newPageNumber;
            })
        }, config.page_duration);

        return () => {
            clearInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screens[0].pages.length, config.page_duration]);

    const flipcards = useMemo(() => {
        config.numPages = screens?.[0]?.pages?.length;
        return <FlipCard
            {...generateFlipcardProps(0, null, screens[0].pages[pageNumber].text, null, tickerColumns, tickerRows, pageNumber, config, forceChapterFlip)}
        />
    }, [screens, pageNumber, config, forceChapterFlip]);

    return (
        <>{flipcards}</>
    )
}

export default FlipTicker;