import { fetchJSON } from '/src/touchpoints/rise/hyperlive/utils';

import '../../styles/main.css';
import createApp from './app'

import presentation from './presentation';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const demo = urlParams.get('demo_mode');
console.log('hello', demo)
const { json_data } = !!demo ? presentation.animation : fetchJSON();
console.log(json_data);

const canvas = document.createElement('canvas');
canvas.classList.add('LLS-canvas');
canvas.classList.add('vertical');
document.body.appendChild(canvas);

// create logging div
const div = document.createElement('div');
div.id = 'modal';
div.style.zIndex = 100;
document.body.appendChild(div);
div.classList.add('LLS-DebuggingModal');

document.addEventListener('keyup', (event) => {
    const display = document.getElementById('modal').style.display;
    if(event.key === ' ') document.getElementById('modal').style.display = display === 'block' ? "none" : 'block';
});

createApp(json_data, !!demo);
