.container {
  height: 100vh;
  width: 100vw;
}

.attract {
  background-color: black;
  min-height: 100vh;
  width: 100vw;
  z-index: -2;
}

.imageContainer {
  position: relative;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  video {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
  }
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  img {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
  }
}

.content {
  position: absolute;
  height: 60vh;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 1.35%, #000000 24%);
  width: 100vw;
  top: 40vh;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  //color: white;
  //text-align: center;
  //margin-bottom: 60px;
}

.body {
  line-height: 72px;
  text-align: center;
  letter-spacing: 0.2px;
  color: white;
  margin-bottom: 17vh;
}

.beaconIcon {
  position: absolute;
  color: white;
  z-index: 3;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100vw;
}


.videoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background: linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0) 65%);
}

.title {
  margin-top: 2vh;
  margin-left: 2vh;
  height: 100vh;
  width: 100vw;
}

//.grid {
//  position: absolute;
//  width: 100vw;
//  height: 100vh;
//}
//
//.gridLeft {
//
//}