import PropTypes from "prop-types";

export default {
	tag: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p", "span", "div"]),
	primary: PropTypes.bool,
	weight: PropTypes.oneOf(["bold", "medium", "normal"]),
	size: PropTypes.oneOf([
		"7xl",
		"6xl",
		"5xl",
		"4xl",
		"3xl",
		"2xl",
		"xl",
		"lg",
		"md",
		"sm",
		"xs",
		"2xs",
		"3xs",
		"4xs",
	]),
	uppercase: PropTypes.bool,
	lineHeight: PropTypes.bool,
	typeStyle: PropTypes.object,
};
