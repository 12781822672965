import React, {useEffect, useState} from "react";
import {Codification} from "../../../components";
import {motion} from "framer-motion";
import {NIKE_EASE} from "../../../constants/nikerise";
import Content from "./Content";
import {DURATIONS} from "../shared";

export default function Activity({action, repeat, info, triggerNext}) {
  const [count, setCount] = useState(null);
  const actions = [...new Array(repeat)].map(() => action);

  const flashTimes = (times = 1, delay = 400) => {
    [...new Array(times)].forEach((a, i) => {
      setTimeout(() => {
        dispatchEvent(new CustomEvent('flash'));
      }, delay * i);
    });
  }

  const initiateNext = () => {
    setTimeout(() => {
      setCount(false);
    }, 500);

    setTimeout(triggerNext, DURATIONS.ACTIVITY_TRIGGER_NEXT);
  }

  const countDown = () => {
    if (count === 0) {
      flashTimes(2, 200)

      initiateNext();
      return;
    }

    if (count === 3) {
      flashTimes();
    }

    if (count === 2) {
      flashTimes();
    }

    if (count === 1) {
      flashTimes(1);
    }

    const duration = action.count === count ? action.duration + 1_000 : action.duration;

    setTimeout(() => {
      setCount(count - 1);
    }, duration)
  }

  useEffect(() => {
    if (action?.intro) {
      setTimeout(initiateNext, DURATIONS.INTRO)
      return;
    }

    if (count === null) {
      setTimeout(() => {
        setCount(action?.count);
      }, DURATIONS.ACTIVITY_INTRO);
      return;
    }

    if (count === false) {
      return;
    }

    countDown(count);
  }, [count]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        fontFamily: 'COMP_FUTURA',
        alignItems: 'center',
        color: 'white',
        zIndex: 99,
      }}
    >
      {
        actions.map((a, i) => (
          <React.Fragment key={i}>
            <Content
              action={action}
              index={i}
              count={count}
              intro={action?.intro}
              info={info}
            />

            {
              repeat !== (i + 1) && (
                <motion.div
                  key={`count_${i}`}
                  style={{
                    fontSize: info.trackHeight / 1.5,
                    width: info.trackHeight,
                    textAlign: 'center'
                  }}
                  animate={{
                    opacity: (count === null || count === false) ? 0 : 1,
                  }}
                  transition={{
                    duration: .4,
                    ease: NIKE_EASE,
                  }}
                >
                  {count === false ? 0 : ''}
                  {count === null ? '' : count}
                </motion.div>
              )
            }
          </React.Fragment>
        ))
      }
    </div>
  )
}