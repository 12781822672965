import React, { CSSProperties, useMemo } from 'react';

import Ticker from './Ticker';
import { JumpMan, Swoosh } from './Icons';

export type TickerIconAliases = 'jumpman' | 'swoosh';

export type TickerContentType = 'text' | 'icon' |  'image';

export type TickerMediaContent = {
    url: string,
    etag: string,
    width: number,
    format: string,
    height: number,
    resource_type: string,
}

export type TickerRecordContent = string | TickerIconAliases | TickerMediaContent;

export type TickerRecord = {
    type: TickerContentType;
    content: TickerRecordContent;
    uid: string;
}

export type TickerRunnerProps = {
    duration?: number,
    style?: CSSProperties,
    background?: string,
    content?: TickerRecord[],
};

export default function TickerRunner(props: TickerRunnerProps) {
    const {
        duration = 30000,
        content = [],
    } = props;

    const style = {
        ...props.style,
        background: props.background || '',
    };

    return (
        <Ticker
            direction='backwards'
            duration={duration}
            style={style}
        >
            {content.map((item) => (
                <TickerItem
                    key={item.uid}
                    content={item.content}
                    type={item.type}
                />
            ))}
        </Ticker>
    );
}

type TickerItemProps = {
    type: TickerContentType;
    content: TickerRecordContent;
}

function TickerItem(props: TickerItemProps) {
    switch (props.type) {
        case 'text':
            return <TickerTextRecord content={props.content} />;
        case 'icon':
            return <TickerIconRecord content={props.content} />;
        case 'image':
            return <TickerImageRecord content={props.content} />;
        default:
            return <TickerTextRecord content={props.content} />;
    }
}

type TickerItemRecordProps<T> = {
    content: T;
};

function TickerTextRecord(props: TickerItemRecordProps<string>) {
    return (
        <div className='ticker-item'>
            <span>{props.content}</span>
        </div>
    )
}

function TickerIconRecord(props: TickerItemRecordProps<TickerIconAliases>) {
    const icon = useMemo(() => {
        switch (props.content) {
            case 'swoosh':
                return <Swoosh />;
            case 'jumpman':
                return <JumpMan />;
            default:
                return <Swoosh />;
        }
    }, [props.content]);

    return (
        <div className='ticker-item ticker-icon'>
            {icon}
        </div>
    )
}

function TickerImageRecord(props: TickerItemRecordProps<TickerMediaContent>) {
    const imageUrl = props.content?.url;

    if (!imageUrl) {
        return;
    }

    return (
        <div className='ticker-item ticker-media' >
            <img className='ticker-media-image' src={imageUrl} alt='image' />
        </div>
    )
}
