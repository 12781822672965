import * as React from 'react';
import Swoosh from './Swoosh';
import Text from './Text';

const dynamicComponents = {
	text: Text,
	swoosh: Swoosh,
};

export const DynamicComponent = ({ type, content, ...props }) => {
	let Component = Text;

	if (type === 'divider') {
		if (content !== 'swoosh') {
			return;
		}

		Component = Swoosh;
	}

	// @ts-ignore
	return <Component content={content} {...props} />;
};
