import Line from './line';

export default function MixedTitleCanvas (content) {
    this.element = document.createElement('div');
    this.element.classList.add('mixed-title-canvas');
    this.text = content.text;
    this.width = content.width;
    this.height = content.height;

    this.fontStyle = content.style || 'HelveticaNeue';
    this.lineHeight = content.lineHeight || this.fontStyle == 'helvetica' ? 0.9 : 0.82;
    this.minFontSize = content.minFontSize || this.fontStyle == 'helvetica' ? 30 : 35;
    this.maxFontSize = content.maxFontSize || this.fontStyle == 'helvetica' ? 80 : 110;
    this.maxLineLength = content.maxLineLength || 15;
  
    const calculateFontSize = () => {
        const lerp = (x, y, a) => x * (1 - a) + y * a;
        const textArray = this.text.split('\n');
        const longest = textArray.reduce((a, b) => a.length < b.length ? b : a, "");
        this.fontSize = lerp(this.maxFontSize, this.minFontSize, longest.length/this.maxLineLength);
    }

    const createCanvas = () => {
        this.canvas = document.createElement('canvas');
        this.element.appendChild(this.canvas);
    };

    const setCanvasSize = () => {
        this.canvas.width = this.width;
        this.canvas.height = this.height;
    }

    const setCanvasStyle = () => {
        this.ctx = this.canvas.getContext('2d');
        this.ctx.fillStyle = 'white';
        this.ctx.fillRect(0, 0, this.width, this.height);
    }

    const createLines = (text, fontSize) => {
        const lines = this.text.split('\n');
        let lineDistance = this.fontSize * this.lineHeight;
        let size = this.fontSize + (lines.length * lineDistance);
    
        this.lines = [];
        console.log(this.lines)
        lines.forEach((line, index) => {
            let baseline = this.fontSize + (index*lineDistance) + ((this.height / 2) - size/2);
            this.lines.push(new Line(this.ctx, line.trim(), this.fontSize, baseline + (lineDistance / this.langHeight), this.width, this.fontStyle));
        })
        this.currentTickerIndex = this.lines.length - 1;
    }

    const render = () => {
        setCanvasStyle();
        this.lines.forEach((line, index) => {
            line.render(index);
        })
        // console.log('rendering')
        requestAnimationFrame(render);
    }

    calculateFontSize();
    createCanvas();
    setCanvasSize();
    setCanvasStyle();
    createLines();
    render();

    return this.element;
}
    
