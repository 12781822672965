// Todo: Separate lines more content manager friendly
import { SyllableSeparation } from './../../utils.js';

export default function CanvasCenteredText (content) {
    let canvasImage = '';
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const lineHeight = content.lineheight || 1.3;
    const fontColor = content.color || 'black';
    const fontStyle = content.font || 'HelveticaNeue'
    const width = content.width;
    const height = content.height;
    const text = content.text?.split('\n') ?? [];
    canvas.style.display = 'none';

    const createCanvas = () => {
        ctx.canvas.width  = width * 2;
        ctx.canvas.height = height * 2;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    };

    const createText = () => {
        const fontSize = width > height ? width * 0.06 : height * 0.06;
        ctx.font = `400 ${fontSize}px ${fontStyle}`;
        let textWidth = ctx.measureText(text).width;
        ctx.textBaseline = "middle";
        ctx.textAlign = "center";
        ctx.fillStyle = fontColor;
        let y = fontSize;
        let lineDistance = fontSize * lineHeight;
        let lines = text;
        let size = fontSize + (lines.length * lineDistance);


            for (let i = 0; i<lines.length; i++)
            ctx.fillText(lines[i].trim(), canvas.width/2, y + (i*lineDistance) + ( (canvas.height / 2) - size/2.3)  );
        }

    const generateTexture = () => {
        canvasImage = canvas.toDataURL("image/png");
    }

  createCanvas();
  createText();
  generateTexture();

  return canvasImage;
}
