import React, {useCallback, useMemo} from 'react';
import {MediaType, qrUrlTrack, ThemeType} from "../shared";
import Typography from "./Typography";
import { get } from 'lodash';

export const QrCodeVariants = {
	withLogo: 'with_logo',
	large: 'large',
	text: 'text',
} as const;

export type QrCodeVariant = typeof QrCodeVariants[keyof typeof QrCodeVariants];

interface QrSectionProps {
    theme: ThemeType;
    qrCode: QrCodeInterface;
    preventDefaults?: boolean;
}

export interface QrCodeInterface {
    label?: string;
    disable?: boolean;
    assets: Array<MediaType>;
    hide_images?: boolean;
    variant?: QrCodeVariant;
}

const DEFAULT_ASSETS = [
    {
        "url": "https://static.nike.com.cn/sportpulse/image/upload/c_fill,h_200,w_200/b_transparent,c_mpad,h_201,w_201/v1666708293/nikehyperlive-staging/1666708293796.png?_a=ATO2BAA0",
        "width": 1000,
        "format": "png",
        "height": 1000,
        "original_url": "https://static.nike.com.cn/sportpulse/image/upload/v1666708293/nikehyperlive-staging/1666708293796.png",
        "resource_type": "image",
        "destination_url": "https://nike.com"
    },
    {
        'url': 'https://res.cloudinary.com/jbboerner/image/upload/v1670527810/nikehyperlive-staging/1670527810817.png?_a=ATO2BAA0',
        'etag': '78cd24d99d74f345e4baf320db9e049c',
        'width': 180,
        'format': 'png',
        'height': 180,
        'original_url': 'https://res.cloudinary.com/jbboerner/image/upload/v1670527810/nikehyperlive-staging/1670527810817.png',
        'resource_type': 'image',
        'name': 'Nike Swoosh Logo',
    }
    // {
    //     "url": "https://static.nike.com.cn/sportpulse/image/upload/c_fill,h_200,w_200/b_transparent,c_mpad,h_201,w_201/v1666708309/nikehyperlive-staging/1666708309038.svg?_a=ATO2BAA0",
    //     "width": 86,
    //     "format": "svg",
    //     "height": 30,
    //     "original_url": "https://static.nike.com.cn/sportpulse/image/upload/v1666708309/nikehyperlive-staging/1666708309038.svg",
    //     "resource_type": "image"
    // }
]

export default function QrSection({qrCode, theme, preventDefaults = false}: QrSectionProps) {
    if (Array.isArray(qrCode)) {
        return null;
    }

	const {
		assets,
		label,
		disable = false,
		variant = QrCodeVariants.withLogo,
		hide_images = false
	} = qrCode ?? {};
	const color = theme === 'dark' ? 'white' : 'black';

    const getUrl = useCallback((media: MediaType) => {
        if (!media?.destination_url) {
            return media?.original_url ?? media.url
        }

        return qrUrlTrack(media.destination_url);
    }, [assets]);

    // fallback to default assets when the uploaded assets are not set.
    const minAssets = useMemo(() => {
        return [...new Array(2)].map((asset, i) => {
            if (preventDefaults) {
                return assets?.[i];
            }
            return assets?.[i]?.url ? assets?.[i] : DEFAULT_ASSETS?.[i];
        }).filter(a => a);
    }, [assets]);

	const isQrWithLogo = useMemo(() => {
		if (!qrCode?.variant) {
			return true;
		}

		return qrCode?.variant === QrCodeVariants.withLogo;
	}, [qrCode]);

	const assetsToRender = useMemo(() => {
		if (isQrWithLogo) {
			return minAssets;
		}

		const qrAsset = get(minAssets, '[0]');
		return [qrAsset];
	}, [minAssets]);

    if (disable || (preventDefaults && !qrCode?.assets?.length)) {
        return null;
    }

    return (
        <div className={variant}>
            <Typography size={18} variant={'jordan'} color={color} style={{lineHeight: '130%'}}>
                {label}
            </Typography>
            {
                hide_images === true ? null : (
					<div style={{display: 'flex', gap: '10px', marginTop: '10px'}}>
						{
							assetsToRender?.map((media: MediaType, i) => (
								<div
									key={`graphic_${i}`}
									style={{
										height: isQrWithLogo ? 72 : 150,
										width: isQrWithLogo ? 72 : 150,
									}}
								>
									<img
										style={{
											width: '100%',
											height: '100%',
											borderRadius: '8px',
											background: 'white',
										}}
										src={getUrl(media)}
									/>
								</div>
							))
						}
					</div>
                )
            }
        </div>
    )
}
