const laneConstructor = (info, breakpoint) => {
	const emptySpace = info.lanes
		.map(({ pole }, i) => {
			if (i === 0 && pole !== 0) {
				return { pole: 0, length: pole, isFiller: true };
			}

			if (i === 0) return null;

			const lastPole = info.lanes[i - 1].pole;

			if (lastPole >= pole - 4) return null;

			return {
				pole: lastPole + 4,
				length: pole - (lastPole + 4),
				isFiller: true,
			};
		})
		.filter(Boolean);

	const lastPole = info.lanes[info.lanes.length - 1].pole;
	const endPole = lastPole + 4;
	if (endPole < info.numPoles)
		emptySpace.push({
			pole: endPole,
			length: info.numPoles - endPole,
			isFiller: true,
		});

	const blocks = emptySpace.reduce((total, next) => {
		let _next = next;
		if (next.length > 6) {
			const polesToAdd = next.length / 6;
			_next = new Array(parseInt(polesToAdd))
				.fill(0)
				.map((val, index) => {
					return {
						pole: next.pole + index * 6,
						length: 6,
						isFiller: true,
					};
				});

			if (!Number.isInteger(polesToAdd)) {
				_next.push({
					pole: _next[_next.length - 1].pole + 6,
					length: next.length % 6,
					isFiller: true,
				});
			}
			return [...total, ..._next];
		}

		return [...total, next];
	}, []);

	const all = [...info.lanes, ...blocks]
		.map(lane => {
			if (!lane.length) lane.length = 4;
			return lane;
		})
		.sort((a, b) => (a.pole > b.pole ? 1 : -1));

	const breakpointIndex = all.findIndex(
		lane => lane.pole < breakpoint && lane.length + lane.pole > breakpoint,
	);

	if (breakpointIndex !== -1) {
		const lane = all[breakpointIndex];
		const l1 = { ...lane };
		l1.length = breakpoint - lane.pole;
		const l2 = { ...lane };
		l2.pole = l1.pole + l1.length;
		l2.length = lane.length - l1.length;

		if(!lane.isFiller) l2.isFiller = true;

		all.splice(breakpointIndex, 1, l1, l2);
	}
	return all;
};

export default laneConstructor;
