#pragma glslify: circle = require('../../../../lib/glsl/circle.glsl')
#pragma glslify: ring = require('../../../../lib/glsl/ring.glsl')
#pragma glslify: box = require('../../../../lib/glsl/box.glsl')
#pragma glslify: grid = require('../../../../lib/glsl/grid.glsl')
#pragma glslify: mosaic = require('../../../../lib/glsl/mosaic.glsl')

#pragma glslify: coverUV = require('../../../../lib/glsl/coverUV.glsl')
#pragma glslify: pixelateUV = require('../../../../lib/glsl/pixelateUV.glsl')
#pragma glslify: placedUV = require('../../../../lib/glsl/placedUV.glsl')
#pragma glslify: zoomedUV = require('../../../../lib/glsl/zoomedUV.glsl')
#pragma glslify: gammaCorrect = require('../../../../lib/glsl/gammaCorrect.glsl')

#ifdef GL_ES
precision mediump float;
#endif

varying vec2 vUv;

uniform float time;

// general stuffs
uniform vec2 res; // res
uniform vec2 screenRatio; // screen ratio
uniform float pixa; // pixelization amount (size of each cell/pixel)
uniform float pixr; // pixel ratio
uniform float stroke; // stroke width
uniform float transitionScale;

// viewfinder
uniform vec2 vfPos; // position (in pixels)
uniform vec2 vfDim; // dimensions (in pixels)
uniform vec2 vfSeg; // segment size (in pixels)
uniform float vfScale; // scale from dim (0-1)
uniform float vfOpacity; // opacity (0-1)

// rangefinder
uniform vec2 rfPos; // position (in pixels)
uniform float rfRadius; // radius of the ring
uniform float rfOpacity; // opacity (0-1)

// mosaic
uniform float mosaicAmount; // how much mosaic is covering the screen (0-1)
// uniform vec3 mosaicColor; // rgb color
uniform float mosaicColor; // greyscale colo

// text overlay texture
uniform sampler2D txtTex; // sampler for the texture
uniform vec2 txtRes; // size of the image (in pixels)
uniform vec2 txtPos; // position (in pixels)

// grid
uniform vec2 gridSteps;
uniform float gridMosaic;
uniform float gridOpacity;

uniform sampler2D img1;
uniform vec2 img1Res;
uniform vec2 img1Pos;
uniform vec2 img1Dim;
uniform vec2 img1Offset;
uniform float img1Pix;
uniform float img1Zoom;
uniform float img1ZoomRF;

uniform sampler2D img2;
uniform vec2 img2Res;
uniform vec2 img2Pos;
uniform vec2 img2Dim;
uniform vec2 img2Offset;
uniform float img2Pix;
uniform float img2Zoom;
uniform float img2ZoomRF;

vec3 griddedImage (vec3 color, sampler2D img, vec2 imgRes, vec2 pos, vec2 dim, vec2 offset, float zoom, float zoomRF, float pr, float px, float imgpx, float thickness) {
  vec2 center = (gl_FragCoord.xy - pos);

  float c = circle(center, (rfRadius * pr));

  vec2 uv = coverUV(vUv, res, imgRes);
  vec2 puv = uv;

  uv = zoomedUV(uv, imgRes, zoom, offset);
  uv -= offset;
  uv = pixelateUV(uv, px, (imgRes.x / imgRes.y));
  float b = box(center, dim);
  color = mix(color, texture2D(img, uv).rgb, b);

  puv = zoomedUV(puv, imgRes, zoomRF, offset);
  puv -= offset;
  puv = pixelateUV(puv, imgpx, (imgRes.x / imgRes.y));
  color = mix(color, texture2D(img, puv).rgb, step(c, .5));

  float r = ring(c, thickness);
  color = min(color, vec3(r));

  return color;
}

void main() {
  vec3 color = vec3(1.);

  vec2 st = (gl_FragCoord.xy / res) / pixr;
  float ar = (res.x / res.y);
  float px = ((res.x * pixr) / pixa);

  float thickness = (stroke * pixr);
  float thickdiff = 0.008;

  vec3 color1 = griddedImage(color, img1, img1Res, (img1Pos * pixr), ((img1Dim + thickness) * pixr), img1Offset, img1Zoom, img1ZoomRF, pixr, px, (res.x / img1Pix * pixr), thickness);

  vec3 color2 = griddedImage(color, img2, img2Res, (img2Pos * pixr), ((img2Dim + thickness) * pixr), img2Offset, img2Zoom, img2ZoomRF, pixr, px, (res.x / img2Pix * pixr), thickness);

  vec2 center = (gl_FragCoord.xy - (img2Pos * pixr));
  float c = circle(center, (rfRadius * transitionScale * pixr));

  vec3 clr = mix(color1, color2, step(c, .5));

  float r = ring(c, thickness * clamp(transitionScale, 0., 1.));
  clr = min(clr, vec3(r));

  float g1 = grid(vUv, gridSteps, thickdiff);
  float go = mosaic((st * 1.2), ar, time, gridMosaic);
  clr = mix(clr, vec3(g1), (1. - g1) * go);

  clr = mix(clr, vec3(mosaicColor), mosaic(st, ar, time, mosaicAmount));

  gl_FragColor = vec4(gammaCorrect(clr.rgb,2.2), 1.0);
}
