if (module.hot) {
    module.hot.accept(() => {
      location.reload()
    })
  }

  import {
    Texture
  } from 'three';
  import gsap from 'gsap';
  import UniversalLiveTemplate from '/src/touchpoints/live/lib/js/UniversalLiveTemplate';
  import RangeFinderBlink from '/src/touchpoints/live/lib/js/animations/RangeFinderBlink';
  import Css3DRotation from '../../../lib/js/animations/Css3DRotation';
  import MultilineTicker from '../../../lib/js/components/MultilineTicker';
  import vert from '/src/touchpoints/live/lib/glsl/shader.vert';
  import frag from '../programing-messaging.frag';

  class Text extends UniversalLiveTemplate {
    constructor (datas, duration, lan) {
      super(`${datas.type}-${datas.index}`);
      this.duration = duration;
      this.datas = datas;
      this.timeline = gsap.timeline({ paused: true, repeat: false });
      this.createVisualLayer({ frag, vert });
      this.visualLayer.noResUpdate = true;
      this.setupTimeline();
      this.renderElements();
      this.generateAnimations();
    }

    containsKorean(text) {
      const re = /[\u3131-\uD79D]/ugi;
      return text?.match(re)?.length > 0;
    }
    
    renderElements() {
      this.rangeFinder = this.addNode({type: 'rangeFinder', freeflow: true, content: {
        size: '55vh',
        maxSize: '500px',
        stroke: 2.5
      }});
      this.setViewfinderMarks(80, 2.5);   
      this.multilineTicker =  MultilineTicker({
        text: this.datas.event_type == 'Collab'? `${this.datas.left.EN}       ${this.datas.right.EN}` : this.datas.body?.EN,
        width: window.innerWidth,
        height: window.innerHeight,
        style: 'Mixed',
        image:  this.datas.event_type == 'Collab'? this.datas.asset?.url : null,
        id:`${this.datas.type}-${this.datas.index}`
      });
      
      this.setGLUni('textOverlay', new Texture());
        const img = new Image();
        img.crossOrigin = 'anonymous';
        setTimeout(() => {
          img.src = this.multilineTicker.generateTexture();
          img.addEventListener('load', (e) => {
            this.setGLUni('textOverlay', new Texture(img), true);
          });    
        }, 300);
      }

    generateAnimations() { 
      this.timeline.set(this.wrapper.querySelector('.LLS-canvas'), { autoAlpha: 0 });
      this.timeline.set(this.visualLayer.uniforms.pixa, { value: 120 });
      this.timeline.set(this.visualLayer.uniforms.mosaicAmount, { value: 2 });
      RangeFinderBlink({
        rangeFinder: this.rangeFinder,
        markers: this.getAllInOverlay('.viewfinder-mark'),
        timeline: this.timeline
      })
      this.timeline.set(this.wrapper.querySelector('.LLS-canvas'), { autoAlpha: 1 }, '0');
      this.timeline.to(this.visualLayer.uniforms.pixa, { value: 1, duration: 1.0, ease: 'power3.out' }, '0');
      this.timeline.to(this.visualLayer.uniforms.mosaicAmount, { value: 0, duration: 1, ease: 'power3.out', onComplete: () => {
        this.multilineTicker.play();  
      } });
      this.timeline.to(this.visualLayer.uniforms.pixa, { value: 120, duration: 1.0, ease: 'power3.out' }, '+=3.0');
      this.timeline.to(this.visualLayer.uniforms.mosaicAmount, { value: 1, duration: 1.5, ease: 'power3.out' });
   
    }


    populateTimeline () {
      this.tl.add(this.stop.bind(this), this.duration);
     }
     
     preStart () {
      this.timeline.restart();
       this.wrapper.classList.add('active');
       this.timeline.play();
       this.multilineTicker.stop();  
     }
     preStop () {
        this.wrapper.classList.remove('active');
        this.timeline.pause();
        this.timeline.restart();
        this.multilineTicker.stop();  
     }
    
  }
  export default Text;
  