import Vitals from "./src/vitals";
import {makeHeartbeatRequest} from "./src/api";

const DEFAULT_INTERVAL = 20_000;

export default function createAutoVitalsService(config, interval = DEFAULT_INTERVAL) {
	const caller = createManualVitalsService(config)
	const intervalId = setInterval(caller, interval);

	return () => {
		clearInterval(intervalId);
	};
}

export function createManualVitalsService({endpoint, channel, pixelmap_slug, session, label, device_uuid}) {
	const vitals = new Vitals();

	return sendHeartbeat({endpoint, channel, pixelmap_slug, session, label, vitals, device_uuid});
}

const sendHeartbeat = ({endpoint, channel, pixelmap_slug, session, label, vitals, device_uuid}) => {
	return async () => {
		let data = {};
		try {
			data = vitals.snapshot()
		} catch (err) {
			console.warn(`Could not create vitals snapshot`, err);
			return;
		}

		try {
			return await makeHeartbeatRequest({
				endpoint,
				channel,
				pixelmap_slug,
				device_uuid,
				session_id: session,
				json_data: data,
				label: label ?? session,
			});
		} catch (err) {
			console.warn(`Could not send heartbeat`, err);
		}
	}
}
