import React, { forwardRef, memo } from "react";
import cn from "classnames";

import propTypes from "./Typography.propTypes";
import defaultProps from "./Typography.defaultProps";

const Typography = forwardRef(
	({ className, children, tag: Tag, primary, weight, size, uppercase, lineHeight, typeStyle }, ref) => {
		const lineHeightClassName = lineHeight ? `u-line-height-${size}` : "u-no-line-height";
		const weightClassName = `u-font-w-${weight}`;
		const sizeClassName = `u-font-s-${size}`;
		const fontFamilyClassName = primary ? "u-font-f-primary" : "u-font-f-secondary u-font-normalize";
		const uppercaseClassName = uppercase || primary ? "u-text-up" : "";
		const rootClassName = cn(
			className,
			lineHeightClassName,
			fontFamilyClassName,
			uppercaseClassName,
			weightClassName,
			sizeClassName
		);
		const style = typeStyle || {};

		return (
			<Tag ref={ref} className={rootClassName} style={style}>
				{children}
			</Tag>
		);
	}
);

Typography.propTypes = propTypes;
Typography.defaultProps = defaultProps;

export default memo(Typography);
