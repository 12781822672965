import ReactDOM from 'react-dom/client';
import App from './app';
import { fetchJSON } from '/src/touchpoints/rise/hyperlive/utils';

const { json_data } = fetchJSON();
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App
    data={json_data}
    channel={params.zchannel ?? params.channel}
    testing={params.testing}
  />,
);
