import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TRANSITION_WAIT } from '../../../constants/hub';
import i18n from '../../../i18n';
import { parseHubData } from '../utils/parser';
import { useReadyCounter } from './useReadyCounter';

const logger = (...params) => {
	if (process.env.NODE_ENV === 'development') {
		console.info(...params);
	}
};

const DEV_CONTROLS = {
	// enabled: true,
	pause: true,
	lang: 0,
	item: 1,
	// theme: 'dark',
}

const devSetting = (key, defaultValue) => {
	return DEV_CONTROLS?.enabled ? DEV_CONTROLS?.[key] : defaultValue;
}

export const useHubGrid = ({
	rawData,
	initialPlay = true,
	fallback = null,
	addImages = false,
	template
}) => {
	const parsedData = useMemo(
		() => (rawData ? parseHubData(rawData, addImages, template) : fallback),
		[],
	);
	const langIndex = useRef(devSetting('lang', 0));
	const globalIndex = useRef(0);
	const [initialized, setInitialized] = useState(false);
	const [active, setActive] = useState(devSetting('item', 0));
	const [data, setData] = useState(null);
	const [reverse, setReverse] = useState(false);
	const [theme, setTheme] = useState(devSetting('theme', 'light'));

	const langKeys = Object.keys(parsedData);

	const onReverseDone = async resetCounter => {
		if (langIndex.current + 1 > langKeys.length - 1) {
			logger('Animation loop done');
		}

		await new Promise(resolve => {
			setTimeout(() => {
				logger('Reverse back with new data');
				globalIndex.current = globalIndex.current + 1;

				if (langKeys.length > 1) {
					setTheme(prevTheme =>
						prevTheme === 'light' ? 'dark' : 'light',
					);

					const newIndex = langIndex.current + 1;
					langIndex.current =
						newIndex > langKeys.length - 1 ? 0 : newIndex;

					const newLang = langKeys[langIndex.current];
					i18n.changeLanguage(newLang.toLowerCase());

					setData(parsedData[newLang]);
				}

				setReverse(false);
				resetCounter();
				resolve();
			}, 500);
		});
	};

	const total =
		reverse || (data && !initialized) ? data?.blocks?.length + 1 : -1;
	const { updateCounter: onInitBlock } = useReadyCounter(total, () =>
		setInitialized(true),
	);
	const { updateCounter: onReverseBlock } = useReadyCounter(
		total,
		onReverseDone,
	);

	// Set localized data
	useEffect(() => {
		if (parsedData) {
			setData(parsedData[langKeys[langIndex.current]]);
		}
	}, [parsedData]);

	const onNext = useCallback(async () => {
		if (reverse) {
			return;
		}

		const lastIndex = data.blocks.length;
		const newActive = active + 1 > lastIndex ? 0 : active + 1;

		if (active + 1 > lastIndex) {
			await new Promise(resolve => {
				setTimeout(() => {
					logger('Final step', active, newActive);
					setActive(newActive);
					resolve();
				}, TRANSITION_WAIT);
			});

			await new Promise(resolve => {
				setTimeout(() => {
					logger('Reverse text');
					setReverse(true);
					resolve();
				}, 1500);
			});

			return;
		}

		setTimeout(() => {
			logger('Next step', active, newActive);
			if (devSetting('pause')) {
			  return;
			}
			setActive(newActive);
		}, TRANSITION_WAIT);
	}, [active, reverse, data]);

	// Start play when everything has initialized
	useEffect(() => {
		if (initialized && initialPlay) {
			onNext();
		}
	}, [initialized, initialPlay]);

	return {
		...data,
		active,
		initialized,
		langIndex: langIndex.current,
		globalIndex: globalIndex.current,
		reverse,
		theme,
		onInitBlock,
		onReverseBlock,
		onNext,
	};
};
