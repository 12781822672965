// React
import React from 'react';
import ReactDOM from 'react-dom/client';

// Hyperlive Utils
import * as Hyperlive from '../../hyperlive/utils.js';

// preload
import { preload, preloadImages, preloadVideos } from '../../utils/image.js';
// lodash
import _ , { debounce } from 'lodash';

// Globals
import '../../../../init';
import '../../styles/main.scss';

// Template
import RiseStorytelling from './RiseStorytelling';

console.log('ANIMATIONS', window.animations)
const { json_data } = Hyperlive.fetchJSON();

// PRECACHE ASSETS
const chapters = _.get(json_data, 'chapters');
let precache_media = [];
let preload_images = [];
let preload_videos = [];
chapters.map((chapter, index) => {
  const media = _.get(json_data, `chapters[${index}].assets`);
  precache_media = precache_media.concat(media);
});
precache_media.map((media) => {
  if (media != null) {
    const { resource_type, subType } = media;
    if ((resource_type === 'image' || subType === 'image')) {
      preload_images.push(media.url);
    };
    if ((resource_type === 'video' || subType === 'video')) {
      preload_videos.push(media.url);
    }
    // console.log(media.url);
  }
});

function resetLayout() {
  location.reload();
}

window.addEventListener('resize', debounce(resetLayout, 500, { trailing: true, leading: false }), false);

(async () => {
  console.log('PRELOADING...');
  const images = await preloadImages(preload_images);
  const videos = await preloadVideos(preload_videos);
  console.log('PRELOADED');

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(<RiseStorytelling key={'1'} json_data={json_data} />);

})();






