import { v4 as uuidv4 } from 'uuid';

export function generateDeviceUUID() {
	const deviceUUID = uuidv4();
	localStorage.setItem('device_uuid', deviceUUID);

	return deviceUUID;
}

export function retrieveDeviceUUID() {
	let deviceUUID = localStorage.getItem('device_uuid');

	if (!deviceUUID) {
		deviceUUID = generateDeviceUUID();
	}

	return deviceUUID;
}
