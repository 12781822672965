const fontsIndexes = [
  ['NIKE_FUTURA', 'NeuePixelGrotesk', 'HelveticaNeue'],
  ['NeuePixelGrotesk', 'NIKE_FUTURA'],
  ['NeuePixelGrotesk', 'NIKE_FUTURA', 'HelveticaNeue']
]

const fontsIndexesChina = [
  ['CHINA_FUTURA', 'NIKE_FUTURA', 'CHINA_FUTURA'],
  ['CHINA_FUTURA', 'NIKE_FUTURA', 'CHINA_FUTURA'],
  ['NIKE_FUTURA', 'CHINA_FUTURA', 'CHINA_FUTURA']
]

const fontSizesRatio = {
  NIKE_FUTURA: 0.943,
  NeuePixelGrotesk: 1,
  HelveticaNeue: 1,
  noto: 1,
  CHINA_FUTURA: 1

}

export default class Letter {
  constructor(letter, wordIndex, letterIndex, count, nonEN) {
    this.value = letter;
    this.wordIndex = wordIndex;
    this.letterIndex = letterIndex;
    this.nonEN = nonEN;

    const index = Math.min((count - 1), 2);
    this.isChinese = this.setChinese(this.value);
    this.fonts = this.isChinese ? this.fontsChina[this.fontIndex % this.fonts.length] : this.nonEN ? ['noto'] : fontsIndexes[index];
    this.fontsChina = fontsIndexesChina[index];
    this.fontIndex = this.wordIndex;

  }

  setBaseline(baseline) {
    this.baseline = baseline;
  }

  setSize(size) {
    this.size = size;
  }

  getSize(ctx) {
    return this.width;
  }

  setChinese(isChinese) {
    this.isChinese = isChinese;
  }

  getFont() {
    return this.isChinese ? this.fontsChina[this.fontIndex % this.fonts.length] : this.nonEN ? 'noto' : this.fonts[this.fontIndex % this.fonts.length];
  }

  draw(ctx, cursor) {


    if (this.getFont() === 'CHINA_FUTURA') {
      ctx.font = `${this.size * 0.87}px "${this.getFont()}"`;
      ctx.fillText(this.value, cursor, this.isChinese ? this.baseline * 1.0 : this.baseline);
    } else {
      ctx.font = this.isChinese ? `${this.size * 0.80}px "${this.getFont()}"` : `${this.size * fontSizesRatio[this.getFont()]}px "${this.getFont()}"`;
      ctx.fillText(this.value, cursor, this.isChinese ? this.baseline * 0.91 : this.baseline * 1.02);
    }

    const tm = ctx.measureText(this.value);
    this.width = tm.width;
  }
}
