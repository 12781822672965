import TitleCard, {TitleCardProps} from "./TitleCard";
import JumpMan from "../assets/icons/JumpMan";
import Background from "../components/Background";
import {motion} from "framer-motion";
import Snkrs from "../assets/icons/Snkrs";
import {useState} from "react";
import Transition from "../components/Transition";

interface SnkrsTitleProps extends TitleCardProps {
    hide_title?: boolean;
}

export default function SnkrsTitle({title, theme, icon, hide_title = false}: SnkrsTitleProps) {
    const [stage, setStage] = useState(0);

    const nextStage = () => {
        if (hide_title) {
            return
        }
        setInterval(() => {
            setStage(1)
        }, 750);
    }

    return (
        <>
            {
                stage >= 0 && (
                    <Transition key={'snkrs-intro'} variant={'wipe-x'} style={{position: 'absolute'}}>
                        <Background variant={'black-print-wash'}>
                            <div style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>

                                <motion.div
                                    initial={{
                                        scale: 22,
                                    }}
                                    animate={{
                                        scale: 1,
                                    }}
                                    transition={{
                                        duration: 2,
                                        ease: 'easeInOut',
                                        delay: 3,
                                    }}
                                    style={{
                                        // color: '#E9332B',
                                        color: '#ff0000',
                                        position: 'relative',
                                    }}
                                    onAnimationComplete={nextStage}
                                >
                                    <JumpMan/>
                                    <motion.div
                                        style={{
                                            color: 'white',
                                            position: 'absolute',
                                            left: '50%',
                                            top: '50%',
                                            scale: .05,
                                            x: '-50%',
                                            y: '-50%'
                                        }}
                                        initial={{
                                            opacity: 1,
                                        }}
                                        animate={{
                                            opacity: 0,
                                        }}
                                        transition={{
                                            duration: 1,
                                            ease: 'easeIn',
                                            delay: 4,
                                        }}
                                    >
                                        <Snkrs/>
                                    </motion.div>
                                </motion.div>
                            </div>
                        </Background>
                    </Transition>
                )
            }
            {
                stage === 1 && <TitleCard title={title} icon={icon} theme={theme}/>
            }
        </>
    )
}