

if (module.hot) {
  module.hot.accept(() => {
    location.reload()
  })
}

import {
  Uniform,
  Vector2,
  Texture,
  Color
} from 'three';
import * as THREE from 'three';
import gsap from 'gsap';
import {containsKorean} from "../../../../utilities";
import { ScrambleTextPlugin } from '/src/touchpoints/live/lib/js/vendors/ScrambleTextPlugin.min.js';
import fitty from 'fitty';

import UniversalLiveTemplate from '/src/touchpoints/live/lib/js/UniversalLiveTemplate';
import MixedText from '/src/touchpoints/live/lib/js/components/MixedText';
import CanvasGrid from '/src/touchpoints/live/lib/js/components/CanvasGrid';
import vert from '/src/touchpoints/live/lib/glsl/shader.vert';
import frag from './life365Text.frag';
import presetTL from './life365-text-timeline';
import './L365.scss'

gsap.registerPlugin(ScrambleTextPlugin);

class L365Text extends UniversalLiveTemplate {
  constructor (datas, duration, lang, hasRangeFinder) {
    console.log('datas', datas)
    super(`${datas.type}-${datas.index}`);
    this.duration = duration;
    this.datas = datas;
    this.lang = this.datas.heading?.[`${lang}`] ? lang : 'EN';
    this.wrapper.classList.add('chapter-L365');
    this.previousTexture = null;
    this.transition_type = datas.transition_type;

    this.index = datas.index;
    this.isCatalog = datas.isCatalog;
    this.overlayWrapper = this.addNode({
      type: 'overlayWrapper', 
      freeflow:true
    });

    if (this.datas.transition_type === null || this.datas.transition_type === undefined) {
      this.showViewFinder = hasRangeFinder;
    } else {
      this.showViewFinder = this.datas.transition_type == 'range';
    }

    
    const center = new Vector2(
      (window.innerWidth),
      (window.innerHeight)
    );

    if(this.isCatalog) {
      this.wrapper.classList.add('catalog-apparel');
    }

    const uniforms = {
      seed: (Math.random() * 1000),
      res: new THREE.Uniform(new THREE.Vector2(window.innerWidth, window.innerHeight)),
      offset: new THREE.Uniform(new THREE.Vector2(0)),
      mosaic: new THREE.Uniform(1),
      pixels: new THREE.Uniform(10),
      zoom: new THREE.Uniform(1),
      opacity: new THREE.Uniform(1),
      time: new THREE.Uniform(0),
      pixr: new THREE.Uniform(window.devicePixelRatio),
      rangeFinder: new THREE.Uniform(new THREE.Vector2(0, 0)),
      pixRF: new THREE.Uniform(0),
      isCutAnim: new THREE.Uniform(this.transition_type && this.transition_type == 'cut'? false : true),
      zoomRF: new THREE.Uniform(1),
      hasViewFinder: new THREE.Uniform(this.showViewFinder),
      textOverlayRes: new THREE.Uniform(new THREE.Vector2(window.innerWidth, window.innerHeight)),
      previousTextureRes: new THREE.Uniform(new THREE.Vector2(window.innerWidth, window.innerHeight)),
      gridImageRes: new THREE.Uniform(new THREE.Vector2(window.innerWidth, window.innerHeight)),
      index: new THREE.Uniform(this.index),
    }

    this.createVisualLayer({ frag, vert }, uniforms);


    this.visualLayer.noResUpdate = true;
    this.visualLayer.renderer.setPixelRatio(window.devicePixelRatio);

    if (this.datas.color === 'white') {
      this.app.classList.add('light-mode');
    }


    if (this.datas.show_view_finder) {
      this.viewFinderMarks = this.addNode({
        type: 'viewFinderMarks',
        freeflow:true,
        content: { stroke: '2px', size: 100 }
      });
    }

    if (this.datas.heading_style === 'mixed') {
      let subheadings = [];
      
      if(this.datas.content_1?.EN) {
        subheadings.push(this.datas.content_1[`${this.lang}`] ? this.datas.content_1[`${this.lang}`] : this.datas.content_1?.EN );
      } 
      if(this.datas.content_2?.EN) {
        subheadings.push(this.datas.content_2[`${this.lang}`] ? this.datas.content_2[`${this.lang}`] : this.datas.content_1?.EN );
      } 
      const isTicker = subheadings.filter(i => i && Object.keys(i).length).length === 0;
       
        const text = this.datas.heading?.[`${this.lang}`] ?? '';
       
        let mixedTextStyle = this.datas.heading_style;
        let containsKoreanGlyph = containsKorean(text);

        this.mixedText = MixedText({
          text,
          width: window.innerWidth,
          height: window.innerHeight,
          subheadings: subheadings?.length > 0 ? subheadings : null,
          style: mixedTextStyle,
          isDirectory: this.datas.is_directory,
          langHeight: this.datas?.is_directory ? 20 : 0,
          constainsKorean: containsKoreanGlyph
        });
      } else {
        const text = this.datas.heading?.[`${this.lang}`] ?? '';
        let mixedTextStyle = this.datas.heading_style;
        let containsKoreanGlyph = containsKorean(text);
        this.mixedText = MixedText({
          text,
          width: window.innerWidth,
          height: window.innerHeight,
          subheadings: null,
          style: mixedTextStyle,
          isDirectory: this.datas?.is_directory,
          langHeight: this.datas?.is_directory ? 20 : 0,
          constainsKorean: containsKoreanGlyph
        });
    }
    
  this.canvasGrid = CanvasGrid({
    width: window.innerWidth,
    height: window.innerHeight,
    size: 275
  })

  this.setGLUni('gridImage', new Texture());
  this.imgGrid = new Image();
   this.imgGrid.crossOrigin = 'anonymous';
   this.imgGrid.src = this.canvasGrid;
   this.imgGrid.addEventListener('load', (e) => {
    this.setGLUni('gridImage', new Texture( this.showViewFinder ? this.imgGrid : this.img), true);
  });

  this.setGLUni('textOverlay', new Texture());
    this.img = new Image();
    this.img.crossOrigin = 'anonymous';
    this.img.src = this.mixedText;
    this.img.addEventListener('load', (e) => {
        this.setGLUni('textOverlay', new Texture(this.img), true);
   });
    
  if (this.datas.logo && this.datas.heading_style !== 'mixed') {
    this.setTopLeftSlot({
      type: 'imgLogo',
      content: { src: this.datas.logo.url }
    });
  }

  this.setTopRightSlot({
      type: 'verticalText',
      content: { text: this.datas.top_right?.[`${lang}`] ? this.datas.top_right?.[`${lang}`] : this.datas.top_right?.['EN'] || ' ' }
    });


    this.setBottomRightSlot({
      type: 'verticalText',
      content: { text: this.datas.bottom_right?.[`${lang}`] ? this.datas.bottom_right?.[`${lang}`] : this.datas.bottom_right?.['EN'] || ' ' }
    });
    
    // this.wrapper.querySelector('.vertical-text.top-right').style.minHeight = `${(this.wrapper.querySelectorAll('h2')[0].innerText.length) * (this.isCatalog? 14.5 : 25.8)}px` 
    if (this.datas.use_swatches) {
      this.addNode({
        type: 'swatchList',
        parent: true,
        content: {
          size: this.isCatalog ? 20 : 40,
          groupSize: 3,
          swatches: [
            {
              color: this.datas.color_0 || 'black'
            }, {
              color: this.datas.color_1 || 'white'
            }, {
              color: this.datas.color_2 || 'black'
            }
          ]
        }
      });
    }

    this.setupTimeline();

    window.addEventListener('keypress', (e) => {
      if (e.code === 'Space') {
        this.tl.paused(!this.tl.paused());
      }
    });
  }

  returnTexture() {
    return this.mixedText;
  }

  returnSize() {
    return [window.innerWidth, window.innerHeight];
  }

  setPreTextureSize(size) {
    this.setGLUni('previousTextureRes', new THREE.Vector2(size[0], size[1]), true);
  }

  returnType() {
    return false;
  }

  preTexture(texture, isVideo) {
    if(isVideo) {
      this.previousTexture = texture;
      this.setGLUni('previousTexture', texture);
      // this.setGLUni('previousTexture', texture, true);
    } else {
      this.previousTexture = texture == 'grid'? this.canvasGrid : texture;
      this.setGLUni('previousTexture', new Texture());
      const img02 = new Image();
      img02.crossOrigin = 'anonymous';
      img02.src = this.previousTexture;
      img02.addEventListener('load', (e) => {
        this.setGLUni('previousTexture', new Texture(img02), true);
      });
    }
    
    if(this.showViewFinder) {
      this.rangeFinder = this.addNode({type: 'rangeFinder', freeflow: true, content: {
        size: `${window.innerHeight * 2}`,
        stroke: 5
      }});

      this.rangeFinder.style.width = `${window.innerHeight * 2}px`
      this.rangeFinder.style.height = `${window.innerHeight * 2}px`
      presetTL.bind(this)(this.lang,   () => {});
     
    }
  }

  populateTimeline () {
    this.tl.add(gsap.set(this.visualLayer.uniforms.pixa, { value: 500 }), 0);
    this.tl.add(gsap.set(this.visualLayer.uniforms.mosaicAmount, { value: 1 }), 0);
    this.tl.add(gsap.to(this.visualLayer.uniforms.pixa, { value: 1, duration: 1, ease: 'power3.out' }), 0);
    this.tl.add(gsap.to(this.visualLayer.uniforms.mosaicAmount, { value: 0, duration: 1, ease: 'power3.out' }), 0);
    if(!this.showViewFinder) {
      presetTL.bind(this)(this.lang, this.showViewFinder,   () => {});
      
    }

    if(this.index == 0) {
      this.wrapper.style.zIndex = 0;
    }
  
    this.tl.add(this.stop.bind(this), this.duration);
  }

  preStop () {
    setTimeout(() => {
      this.wrapper.classList.remove('active');
    }, 2000);
  }

  preStart () {
    if(this.index == 0) {
      this.wrapper.style.zIndex = 10;
    }
    if(this.showViewFinder) {
      if(this.index == 0) {
        this.setGLUni('gridImage', new Texture(this.imgGrid), true);
        setTimeout(() => {
          this.setGLUni('gridImage', new Texture(this.img), true);
        }, 1195)
      } else {
        this.setGLUni('gridImage', new Texture(this.img), true);
      }
    } else {
      this.setGLUni('gridImage', new Texture(this.img), true);
    }
    setTimeout(() => {
 
      this.wrapper.classList.add('active');
    }, 150);

    setTimeout(() => {
      if(this.index == 0) {
        this.wrapper.style.zIndex = 0;
      }
    }, 5000);
    
  }
}
export default L365Text;
