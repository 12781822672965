{
  "created_by_app_user_id": 4,
  "animation_type_id": 9,
  "store_id": 1,
  "id": 523,
  "name": "Nike React Infinity Run Flyknit 2",
  "layout": "1",
  "json_data": {
    "cta": {
      "app": "NRC",
      "text": {
        "EN": "Scan to download",
        "KO": "스캔하여 다운로드"
      }
    },
    "subcopy": {
      "EN": "Engineered to help reduce injury and keep you on the run.",
      "KO": "부상을 줄이고 계속 달릴 수 있도록 설계되었습니다."
    },
    "supertitle": {
      "EN": "Designed For High Intensity Training",
      "KO": "고강도 훈련을 위해 설계됨"
    },
    "static_pulse_story": {
      "EN": "Seoul's favorite men's shoe for 10K runs",
      "KO": "Seoul's favorite men's shoe for 10K runs",
      "KO_": "10K 실행을위한 서울의 좋아하는."
    }
  },
  "approved_by_app_user_id": null,
  "approved_at": null,
  "created_at": 1625795251000,
  "updated_at": 1625795251000,
  "deleted_at": null,
  "assigned_to_displays": null
}