@use '../../variables' as vars;

.product_intro_v2 {
    height: 100%;
    position: relative;

    & > * {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.primary_media_container {
    height: 1040px;
    width: 760px;
    top: unset;
    bottom: 240px;
    left: unset;
    right: 0;
    overflow: hidden;

    > * {
        height: 100%;
        width: 100%;
    }
}

.secondary_media_container {
    height: 412px;
    width: 398px;
    top: unset;
    bottom: 400px;
}

.grid_container {
    left: -1px;

    .grid svg line {
        stroke: #dadada;
    }
}

.text_container {
    @include vars.hoi-font;

    display: flex;
    align-items: center;
    height: 33.3%;
    width: 100%;
    font-size: 130px;
    line-height: 112px;

    > * {
        width: 100%;
        padding: 68px;
    }
}
