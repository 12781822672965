import gsap from 'gsap';
import { ScrambleTextPlugin } from '/src/touchpoints/live/lib/js/vendors/ScrambleTextPlugin.min.js';

gsap.registerPlugin(ScrambleTextPlugin);

export default function presetTL (onPixelEffect) {
  const tl = this.tl;
  const { uniforms } = this.visualLayer;

  tl.add(gsap.set(uniforms.row0Width, { value: 0 }), 0);
  tl.add(gsap.set(uniforms.row1Width, { value: 0 }), 0);
  tl.add(gsap.set(uniforms.row2Width, { value: 0 }), 0);
  tl.add(gsap.set(uniforms.row3Width, { value: 0 }), 0);
  tl.add(gsap.set(uniforms.row0Offset, { value: 0 }), 0);
  tl.add(gsap.set(uniforms.row1Offset, { value: 0 }), 0);
  tl.add(gsap.set(uniforms.row2Offset, { value: 0 }), 0);
  tl.add(gsap.set(uniforms.row3Offset, { value: 0 }), 0);
  tl.add(gsap.set(uniforms.mosaicAmount, { value: 1 }), 0);
  tl.add(gsap.set(uniforms.pixa, { value: 120 }), 0);

  tl.add(gsap.to(uniforms.mosaicAmount, { value: 0, duration: 1.5, ease: 'power2.in' }), 0.25);

  tl.add(gsap.to(uniforms.pixa, { value: 1, duration: 1.3, ease: 'power2.out' }), 0.5);

  tl.add(gsap.to(uniforms.row0Width, { value: 1, duration: 1.3, ease: 'power0.none' }), 0.2);
  tl.add(gsap.to(uniforms.row0Offset, { value: -0.5, duration: 6.3, ease: 'power0.none' }), 0.7);

  tl.add(gsap.to(uniforms.row1Width, { value: 1, duration: 2, ease: 'power0.none' }), 0.2);
  tl.add(gsap.to(uniforms.row1Offset, { value: 0.075, duration: 6.5, ease: 'power0.none' }), 0.7);

  tl.add(gsap.to(uniforms.row2Width, { value: 1, duration: 1.5, ease: 'power0.none' }), 0.5);
  tl.add(gsap.to(uniforms.row2Offset, { value: -0.075, duration: 6.5, ease: 'power0.none' }), 0.7);

  tl.add(gsap.to(uniforms.row3Width, { value: 1, duration: 1.3, ease: 'power0.none' }), 0.3);
  tl.add(gsap.to(uniforms.row3Offset, { value: 0.05, duration: 4, ease: 'power0.none' }), 0.7);
  tl.add(gsap.to(uniforms.row3Offset, { value: -0.01, duration: 2.3, ease: 'power0.none' }), 4.7);

  tl.add(gsap.to(uniforms.pixa, { value: 120, duration: 1 }), 7);

  tl.add(gsap.to(uniforms.mosaicAmount, { value: 1, duration: 1 }), 7);

  tl.call(() => {}, null, 8);
}
