[
    {
        "vertical": [
            {
                "name": "Left",
                "drawToPosition": 100,
                "drawFull": true,
                "drawFromPosition": 0,
                "startPosition": 50,
                "endPosition": 5.6
            },
            {
                "drawFromLine": "Bottom Border",
                "startPosition": 50,
                "drawDirection": 1,
                "drawToLine": "Top Border",
                "drawFull": true,
                "name": "Equator",
                "extend": false
            },
            {
                "drawFromPosition": 0,
                "endPosition": 94.4,
                "startPosition": 50,
                "drawDirection": 1,
                "name": "Right",
                "drawToPosition": 100,
                "extend": false,
                "drawFull": true
            }
        ],
        "duration": "1000",
        "startColor": 160,
        "horizontalDelayDirection": 1,
        "drawBorder": true,
        "id": 0,
        "delayHorizontal": true,
        "horizontal": [
            {
                "drawDirection": 1,
                "drawFull": true,
                "drawFromPosition": 0,
                "extend": false,
                "startPosition": 101,
                "name": "Top",
                "endPosition": 12.5,
                "startColor": 176,
                "drawToPosition": 100
            },
            {
                "extend": false,
                "name": "Equator",
                "drawFromPosition": 0,
                "endPosition": 50,
                "startPosition": 101,
                "drawDirection": 1,
                "drawFull": true,
                "drawToPosition": 100
            },
            {
                "drawToPosition": 100,
                "endPosition": 75,
                "name": "Bottom",
                "drawFromPosition": 0,
                "drawFull": true,
                "startPosition": 101,
                "extend": false,
                "drawDirection": 1
            }
        ],
        "circle": [],
        "mask": [
            {
                "name": "Logo",
                "grid": 0,
                "vertical": [
                    "Left Border",
                    "Right Border"
                ],
                "horizontal": [
                    "Equator",
                    "Bottom"
                ],
                "polyOnly": true
            }
        ],
        "insetBorder": false,
        "name": "SnippetIntro",
        "delayVertical": false
    },
    {
        "vertical": [
            {
                "name": "Left",
                "drawToPosition": 100,
                "drawFull": true,
                "drawFromPosition": 0,
                "startPosition": 50,
                "endPosition": 25
            },
            {
                "drawFromLine": "Bottom Border",
                "startPosition": 50,
                "drawDirection": 1,
                "drawToLine": "Top Border",
                "drawFull": true,
                "name": "Equator",
                "extend": false
            },
            {
                "drawFromPosition": 0,
                "endPosition": 75,
                "startPosition": 50,
                "drawDirection": 1,
                "name": "Right",
                "drawToPosition": 100,
                "extend": false,
                "drawFull": true
            }
        ],
        "duration": "1000",
        "startColor": 160,
        "horizontalDelayDirection": 1,
        "drawBorder": true,
        "id": 0,
        "delayHorizontal": true,
        "horizontal": [
            {
                "drawDirection": 1,
                "drawFull": true,
                "drawFromPosition": 0,
                "extend": false,
                "startPosition": 101,
                "name": "Top",
                "endPosition": 12.5,
                "startColor": 176,
                "drawToPosition": 100
            },
            {
                "extend": false,
                "name": "Equator",
                "drawFromPosition": 0,
                "endPosition": 50,
                "startPosition": 101,
                "drawDirection": 1,
                "drawFull": true,
                "drawToPosition": 100
            },
            {
                "drawToPosition": 100,
                "endPosition": 75,
                "name": "Bottom",
                "drawFromPosition": 0,
                "drawFull": true,
                "startPosition": 101,
                "extend": false,
                "drawDirection": 1
            }
        ],
        "circle": [],
        "mask": [
            {
                "name": "Logo",
                "grid": 0,
                "vertical": [
                    "Left Border",
                    "Right Border"
                ],
                "horizontal": [
                    "Equator",
                    "Bottom"
                ],
                "polyOnly": true
            }
        ],
        "insetBorder": false,
        "name": "SnippetIntro",
        "delayVertical": false
    }
]