import * as React from 'react';

const Swoosh = ({ elementSize }) => (
	<div className="icon" style={{ height: `${elementSize}px` }}>
		<svg
			style={{ height: `${elementSize}px` }}
			width="90"
			height="32"
			viewBox="0 0 90 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M24.671 28.196C17.641 31.1607 6.60893 34.5747 2.00723 27.1927C-0.555939 23.1049 1.0629 16.7262 4.85519 10.9014C7.25347 7.18799 10.3563 3.77401 13.444 0.375C11.7353 3.16009 6.87874 12.4737 13.2192 17.1305C15.6924 18.9123 20.0393 19.092 25.4355 17.6995L89.9493 0.659499L24.671 28.196Z"
				fill="black"
			/>
		</svg>
	</div>
);

export default Swoosh;
