import { useEffect } from 'react';
import { VideoWallProps } from './type';
import * as THREE from 'three';
import { useVideoTexture } from '@react-three/drei';

function VideoMaterial({ texture }) {
    return <meshBasicMaterial map={texture} toneMapped={true} />
}

export const VideoWall = ({ layout, url, onVideoEnded, loop, muted }: VideoWallProps) => {
    const texture = useVideoTexture(url, { loop, muted });
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    texture.name = url;
    texture.encoding = THREE.sRGBEncoding;
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;

    useEffect(() => {
        texture.image.onended = () => {
            if (!loop) onVideoEnded();
        };
    }, [loop, onVideoEnded, texture.image]);
    const meshes = Array.from({ length: layout.landscape ? layout.rows : layout.columns }).map((_, i) => {
        let newTexture = texture.clone();

        if (layout.landscape) {
            newTexture.offset.x = layout.canvas_width * i / layout.width;
            newTexture.offset.y = 0;
            newTexture.needsUpdate = true;
            const yOffset = (layout.rows * layout.height) / 2 - (layout.height / 2);
            const portion = layout.width % layout.canvas_width;
            const xOffset = (layout.canvas_width / 2 - portion / 2);
            if (((layout.rows - 1) !== i || !portion) || layout.rows === 1) {
                newTexture.repeat.set(layout.canvas_width / layout.width, 1);
                return <mesh key={i} position={[0, yOffset + (-layout.height * i), 0]}>
                    <planeGeometry args={[layout.canvas_width, layout.height]} />
                    <VideoMaterial texture={newTexture} />
                </mesh>
            } else {
                newTexture.repeat.set(portion / layout.width, 1);
                return (
                    <mesh key={i} position={[-xOffset, yOffset - layout.height * i, 0]}>
                        <planeGeometry args={[portion, layout.height]} />
                        <VideoMaterial texture={newTexture} />
                    </mesh>
                )
            }
        } else {
            newTexture.offset.x = 0;
            const xOffset = (layout.columns * layout.width) / 2 - (layout.width / 2);
            const portion = layout.height % layout.canvas_height;
            if (((layout.columns - 1) !== i || !portion) || layout.columns === 1) {
                newTexture.repeat.set(1, layout.canvas_height / layout.height);
                newTexture.offset.y = 1 - ((layout.canvas_height / layout.height) * (i + 1));
                newTexture.needsUpdate = true;
                return <mesh key={i} position={[-xOffset + layout.width * i, 0, 0]}>
                    <planeGeometry args={[layout.width, layout.canvas_height]} />
                    <VideoMaterial texture={newTexture} />
                </mesh>
            } else {
                const yOffset = (layout.canvas_height / 2 - portion / 2);
                newTexture.repeat.set(1, portion / layout.height);
                newTexture.needsUpdate = true;
                return (
                    <mesh key={i} position={[-xOffset + layout.width * i, yOffset, 0]}>
                        <planeGeometry args={[layout.width, portion]} />
                        <VideoMaterial texture={newTexture} />
                    </mesh>
                )
            }
        }
    })
    return (
        <group position={[0, 0, 0]}>
            {meshes}
        </group>
    );
};
