
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import FlipLetter from './FlipLetter';
import { rotateSpeed, getColorMaterial, fontColumnWidth, columnWidth, padding } from './shared';

function FlipCard(props) {
  const groupRef = useRef<THREE.Group>();
  const lastColor = useRef();
  const newColor = useRef();
  const lastWord = useRef<string>();
  const newWord = useRef<string>();
  const lastTexture = useRef();
  const noChange = useRef(false);
  const someChange = useRef(false);
  const newTexture = useRef();
  const noRender = useRef<boolean>();
  const sync = useRef(false);
  const isChapterFlip = useRef(false);
  const [flipLetters, setFlipLetters] = useState<JSX.Element>();
  const hasTexture = !!props.state.texture;
  const isFront = props.staging === 'front';

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (groupRef.current) {
        groupRef.current.rotation.x = Math.PI;
        groupRef.current.scale.y = -1;
        groupRef.current.visible = true;
      }
      sync.current = false;
      newWord.current = props.state.word;
      newTexture.current = props.state.texture?.name;
      if (props.state.colors.backgroundColor || props.state.newChapter) {
        newColor.current = props.state.colors.backgroundColor ? props.state.colors.backgroundColor.color.getHex() : lastColor.current;
      }
      else {
        newColor.current = lastColor.current;
      }
      isChapterFlip.current = props.state.newChapter && (props.state.rowFlip === 'chapter' || props.state.rowFlip === 'auto');
      noChange.current = newTexture.current === lastTexture.current && newWord.current === lastWord.current && lastColor.current === newColor.current;
      someChange.current = newTexture.current !== lastTexture.current || lastColor.current !== newColor.current;
      noRender.current = false;
    }, props.state.delay);
    return () => clearTimeout(timeout);
  }, [props.state]);

  useEffect(() => {
    groupRef.current.rotation.x = Math.PI;
    return () => {
      console.log('Card disposed');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const newLetters = useMemo(()=>flipLetters,[flipLetters]);

  useFrame(() => {
    if (noRender.current) return;

    //no Flip
    if (isChapterFlip.current || someChange.current || (props.state.isColorMedia && (lastColor.current !== newColor.current))) {
      groupRef.current.rotation.x += Math.PI / rotateSpeed;
      if (groupRef.current.rotation.x >= Math.PI * 2) {
        lastTexture.current = newTexture.current;
        lastColor.current = newColor.current;
        lastWord.current = newWord.current;
        groupRef.current.rotation.x = Math.PI * 2;
        noRender.current = true;
      }
    } else {
      lastTexture.current = newTexture.current;
      lastColor.current = newColor.current;
      lastWord.current = newWord.current;
      groupRef.current.rotation.x = Math.PI * 2;
      noRender.current = true;
    }

    if (groupRef.current.rotation.x >= (Math.PI * 1.5) && !sync.current) {
      const xOffset = ((columnWidth - fontColumnWidth) * props.columns / 2);
      let newWordArray = [];
      if (newWord.current) {
        newWordArray = newWord.current.length > props.columns ? newWord.current.slice(0, props.columns).split('') : newWord.current.padEnd(props.columns).split('');
      } else {
        newWordArray = padding(props.columns);
      }
      const renderLetters = <>
        <mesh visible={!hasTexture} material={getColorMaterial(newColor.current)} position-z={isFront ? -0.1 : -0.3} position-x={(props.x + (props.width * props.columns) / 2)}>
          <planeGeometry attach="geometry" args={[props.columns * props.width, props.height]} />
        </mesh>
        <mesh position={[(props.x + (props.width * props.columns) / 2), 0, isFront ? -0.1 : -0.3]} visible={hasTexture}>
          <planeGeometry attach="geometry" args={[props.columns * props.width, props.height]} />
          <meshBasicMaterial toneMapped={false} side={THREE.DoubleSide} attach="material" map={props.state.texture} />
        </mesh>
        {newWordArray?.map((letter, idx) => {
          const letterProps = {
            key: idx,
            position: [props.x + xOffset + fontColumnWidth * idx + fontColumnWidth / 2, 0, 0],
            characterSplit: props.characterSplit,
            characterFlipSpeed: props.characterFlipSpeed,
            fontColor: newColor.current === 0 || hasTexture ? getColorMaterial('white') : getColorMaterial('black'),
            hideTile: props.state.texture === undefined,
            bkColor: getColorMaterial(newColor.current),
            state: {
              to: letter,
              loop: lastColor.current === newColor.current,
              forceChapterFlip: props.forceChapterFlip,
              delay: props.state.delay,
              newChapter: props.state.newChapter
            }
          };
          return <FlipLetter {...letterProps} />
        })}
      </>
      setFlipLetters(renderLetters);
      sync.current = true;
      groupRef.current.scale.y = 1;
    }
  });

  return (
    <group key={props.row} ref={groupRef} position-y={props.y}>
      {newLetters}
    </group>
  )
}


export default FlipCard;