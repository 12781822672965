import {useRpx, useTranslatable} from "../shared";
import {EntryType} from "../types";
import Media from "../../../../jordan/components/Media";
import Collapsable from "./collapsable";
import Typography from "./typography";

export default function Entry({entry, isActive}: { entry: EntryType, isActive: boolean }) {
    const {t} = useTranslatable();
    const rpx = useRpx();

    return (
        <div style={{minHeight: rpx(140)}}>
            <Typography
                size={72}
                font={'palatino'}
            >
                {t(entry?.title)}
            </Typography>

            <Collapsable show={isActive}>
                <Typography
                    font={'helvetica-neue'}
                    size={30}
                    style={{
                        marginBottom: rpx(40),
                        marginTop: rpx(10),
                        maxWidth: '80%',
                        lineHeight: '120%',
                    }}
                >
                    {t(entry?.description)}
                </Typography>

                <Media media={entry?.media} width={'100%'} height={'40vh'}/>
            </Collapsable>
        </div>
    )
}