import React, {
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    animate,
    AnimatePresence,
    clamp,
    Easing,
    MotionValue,
    useMotionValue,
    usePresence,
} from 'framer-motion';
import classNames from 'classnames';

import cssClassNames from '../../styles/components/CodificationWithTranslations.module.scss';

import {
    getLangText,
    MultiLangText,
    secToMs,
    setAnimationTimeout,
} from '../../shared';
import useAreFontsLoaded from '../../hooks/useAreFontsLoaded';
import Codification, {
    calcTotalDuration,
    CodificationProps,
} from './Codification';
import PhantomTranslation from './PhantomTranslation';
import rewrapText from './rewrapText';


type Size = {
    width: number,
    height: number,
};

export type TranslationCodificationProps = Partial<Omit<CodificationProps, 'text' | 'delay'>>;

export type CodificationWithTranslationsProps = {
    text: MultiLangText,
    languages: string[],
    codificationProps: TranslationCodificationProps,
    langIndex: number,
    languageTransitionDelay: number,
    duration?: number,
    delay?: number,
    ease?: Easing,
    className?: string,
    runFinalTextAnimation?: boolean,
    onLanguageContainerMeassuerd?: (sizes: Size[]) => void,
    onTyped?: () => void,
};

export default function CodificationWithTranslations(props: CodificationWithTranslationsProps) {
    const {
        text,
        languages,
        codificationProps,
        langIndex,
        languageTransitionDelay,
        duration,
        delay = 0,
        ease = 'linear',
        className,
        runFinalTextAnimation = true,
        onLanguageContainerMeassuerd,
        onTyped,
    } = props;

    const textRef = useRef<HTMLElement>();
    const [processedLines, setProcessedLines] = useState<string[][]>([]);
    const [toShowText, setToShowText] = useState(false);
    const [scale, setScale] = useState(1);
    const areFontsLoaded = useAreFontsLoaded();
    const translations = useMemo(() => orderMultiLanguageText(text, languages), [text, languages]);
    const [isPresent, safeToRemove] = usePresence();

    useEffect(() => {
        if (!textRef.current || !areFontsLoaded) {
            return;
        }

        const {
            lines,
            scale,
        } = rewrapText(translations, textRef.current);

        if (onLanguageContainerMeassuerd) {
            const sizes = [];
            for (let i = 0; i < textRef.current.children.length; i++) {
                const element = textRef.current.children[i];
                const {width, height} = element.getBoundingClientRect();
                sizes[i] = {
                    width,
                    height,
                };
            }
            onLanguageContainerMeassuerd(sizes);
        }

        setProcessedLines(lines);
        setScale(scale);
    }, [textRef.current, areFontsLoaded]);

    const textStyle = useMemo(() => {
        if (scale === 1) {
            return undefined;
        }

        const style = window.getComputedStyle(textRef.current);
        return {
            fontSize: style.fontSize,
            lineHeight: style.lineHeight,
        };
    }, [scale]);

    useEffect(() => {
        if (processedLines.length === 0) {
            return;
        }

        return setAnimationTimeout(() => setToShowText(true), secToMs(delay));
    }, [processedLines]);

    const translationComponents = useMemo(() => languages.map((lang, index) => {
        const isLastLang = index === languages.length - 1;
        return (
            <LineWithTranslations
                key={index}
                lines={processedLines.map(line => line[index])}
                delay={index === 0 ? delay : languageTransitionDelay}
                duration={duration}
                enterProgressScales={processedLines.map(translations => {
                   return Math.max(translations[index].length, (translations[index-1] || '').length)
                })}
                exitProgressScales={processedLines.map(translations => {
                   return Math.max(translations[index].length, (translations[index+1] || '').length)
                })}
                ease={ease}
                codificationProps={codificationProps}
                toNotReverseExit={!isLastLang}
                toExit={!isLastLang || runFinalTextAnimation}
                toExitAllLines={isLastLang}
                onTyped={onTyped}
            />
        )
    }), [languages, processedLines]);

    if (processedLines.length === 0) {
        return (
            <PhantomTranslation
                ref={textRef}
                translations={translations}
                className={className}
            />
        );
    }

    return toShowText ? (
        <div
            className={classNames(cssClassNames.language_container, className)}
            style={textStyle}
        >
            <AnimatePresence onExitComplete={isPresent ? undefined : safeToRemove}>
                {isPresent ? translationComponents[langIndex] : null}
            </AnimatePresence>
        </div>
    ) : null;
}

function orderMultiLanguageText(text: MultiLangText, languages: string[]) {
    return languages.map(lang => getLangText(text, lang));
}

type LineWithTranslationsType = {
    codificationProps: TranslationCodificationProps,
    lines: string[],
    delay: number,
    duration: number,
    enterProgressScales: number[],
    exitProgressScales: number[],
    ease?: Easing,
    toExit: boolean,
    toExitAllLines: boolean,
    toNotReverseExit: boolean,
    onTyped?: () => void,
};

function LineWithTranslations(props: LineWithTranslationsType) {
    const {
        codificationProps,
        lines,
        delay,
        duration,
        enterProgressScales,
        exitProgressScales,
        ease = 'linear',
        toExit,
        toExitAllLines,
        toNotReverseExit,
        onTyped,
    } = props;

    const [toShow, setToShow] = useState(false);
    const [isPresent, safeToRemove] = usePresence();
    const lineMotionValues: MotionValue[] = useMemo(() => lines.map(() => new MotionValue()), [lines]);
    const codificationMotionValue = useMotionValue(0);
    const isPresentRef = useRef(isPresent);

    useEffect(() => {
        const cleanUp = [
            setAnimationTimeout(() => setToShow(true), secToMs(delay)),
            codificationMotionValue.onChange(value => {
                const scales = isPresentRef.current ? enterProgressScales : exitProgressScales;
                lineMotionValues.forEach((mv, index) => {
                    const progress = !isPresentRef.current && toExitAllLines ? value : clamp(0, 1, value - index);
                    const scale = scales[index];
                    mv.set(progress * scale);
                });
            }),
        ];

        return () => {
            while (cleanUp.length > 0) {
                cleanUp.pop()();
            }
        };
    }, []);

    useEffect(() => {
        if (!toShow) {
            return;
        }

        if (!isPresent && !toExit) {
            safeToRemove();
            return;
        }

        isPresentRef.current = isPresent;

        const toNotReverse = isPresent || toNotReverseExit;
        if (toNotReverse) {
            codificationMotionValue.set(0);
        }

        let totalDuration = duration;
        let amountOfLinesToUpdate = 1;
        if (!isPresent && toExitAllLines) {
            if (duration === undefined) {
                const chars = lines.join('').replace(' ', '').split('');
                totalDuration = calcTotalDuration(codificationProps, chars.length);
            }
        } else {
            const scales = isPresent ? enterProgressScales : exitProgressScales;
            amountOfLinesToUpdate = scales.reduce((result, current, index) => current > 0 ? index : result, 0) + 1;

            if (duration === undefined) {
                totalDuration = calcTotalDuration(codificationProps, scales.reduce((res, amount) => res + amount));
            }
        }

        const anim = animate(codificationMotionValue, toNotReverse ? amountOfLinesToUpdate : 0, {
            duration: totalDuration * amountOfLinesToUpdate / lines.length,
            ease,
            onComplete: isPresent ? onTyped : safeToRemove,
        });

        return () => anim.stop();
    }, [toShow, isPresent]);

    return toShow ? (
        <div>
            {lines.map((text, index) => (
                <Codification
                    {...{
                        ...codificationProps,
                        key: index,
                        text,
                        reverse: !isPresent && toNotReverseExit,
                        externalAnimationTimeLine: lineMotionValues[index],
                    }}
                />
            ))}
        </div>
    ) : null;
}


type CodificationDurationProps = {
    text: MultiLangText,
    languages: string[],
    codificationCharDuration: number,
    characterNextTrigger: number,
    characterSwitchAmount: number,
    languageTransitionDelay: number,
};
export function calculateDuration(props: CodificationDurationProps) {
    const {
        text,
        languages,
        codificationCharDuration,
        characterNextTrigger,
        characterSwitchAmount,
        languageTransitionDelay,
    } = props;

    const codificationDurations = languages.map(lang => {
        const string = getLangText(text, lang).replace(/[\s\n]/g, '');

        const mainDuration = string.length * codificationCharDuration * characterNextTrigger;
        const lastCharCodificationAnimaition = codificationCharDuration * (characterSwitchAmount - characterNextTrigger);

        return mainDuration + lastCharCodificationAnimaition;
    }, 0);

    let duration = 0;
    for (let i = 0; i < languages.length; i++) {
        duration += Math.max(
            codificationDurations[i - 1] || 0,
            codificationDurations[i]
        );
        duration += languageTransitionDelay;
    }

    return duration;
}
