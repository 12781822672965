import './rangeFinder.css';

export default function RangeFinder (content) {
    const element = document.createElement('div');
    element.classList.add('rangeFinder');
    
    const setSize = (size) => {
        element.style.width = size;
        element.style.height = size;
    };

    const setStroke = (stroke, color) => {
        element.style.border = `${stroke}px solid ${color?color:'black'}`;
    }

    const setMaxSize = (size) => {
        element.style.maxWidth = size;
        element.style.maxHeight = size;
    };

    if(content.size) {
        setSize(content.size);
    }

    if(content.maxSize) {
        setMaxSize(content.maxSize);
    } 

    if(content.stroke) {
        setStroke(content.stroke, content.color)
    }

    return element;
}
    
