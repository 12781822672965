import Typography from "../../jordan/components/Typography";
import {Chapter, EventType, getFullEventType} from "../shared";
import MoveWithUsLogo from "../components/move-with-us-logo";
import ChapterContainer from "../components/chapter-container";
import {motion} from "framer-motion";
import {DEFAULT_TRANSITION} from "../../rise/constants/transition";
import {stringFallback} from "../../jordan/shared";
import {useCallback} from "react";
import QrCode from "../components/qr-code";

export default function Intro({chapter}: { chapter: Chapter }) {
    const clean = useCallback((str: string) => {
        const remove = [
            'ntc app',
            'nrc app',
        ]

        return remove.includes(str?.toLowerCase()) ? '' : str;
    }, []);

    return (
        <ChapterContainer chapter={chapter}>
            <div style={{position: 'absolute', top: 365, left: 50}}>
                <MoveWithUsLogo/>
            </div>

            <div style={{position: 'absolute', left: 197, width: '50%', top: 763}}>
                {
                    chapter.entries?.map(({short_title, title, event_type, details}) => {
                        const detail2 = stringFallback(details?.[1], details?.[0]);

                        return (
                            <motion.div
                                style={{marginBottom: 129}}
                                transition={{
                                    ...DEFAULT_TRANSITION,
                                    delay: 1,
                                }}
                            >
                                <Typography
                                    variant={'brut'}
                                    color={'currentColor'}
                                    size={75}
                                    style={{
                                        textWrap: 'balance',
                                        whiteSpace: '',
                                        maxWidth: '90%',
                                        textTransform: 'lowercase',
                                        lineHeight: '.95em'
                                    }}
                                >
                                    {short_title ?? title}
                                </Typography>
                                <div style={{display: 'flex', justifyContent: 'space-between', marginRight: 40}}>
                                    <Typography
                                        size={42}
                                        style={{
                                            marginTop: 20,
                                            textTransform: 'lowercase',
                                        }}
                                        variant={'pp-ultralight-italic'} color={'currentColor'}>
                                        {getFullEventType(event_type as EventType)}
                                    </Typography>
                                    <Typography
                                        size={42}
                                        style={{
                                            marginTop: 20,
                                            textTransform: 'lowercase',
                                        }}
                                        variant={'pp-ultralight-italic'} color={'currentColor'}>
                                        {clean(detail2)}
                                    </Typography>

                                </div>

                            </motion.div>
                        )
                    })
                }
            </div>

            <QrCode
                cta={'move with us'}
                hideSwoosh={true}
            />
            {/*<div style={{position: 'absolute', bottom: 0, width: '100%'}}>*/}
            {/*    <div style={{margin: '50px', display: 'flex', justifyContent: 'space-between'}}>*/}
            {/*        <Typography size={36} variant={'brut'} color={'currentColor'}>*/}
            {/*            wellness isn't a win*/}
            {/*        </Typography>*/}
            {/*        <Typography size={36} variant={'brut'} color={'currentColor'}>*/}
            {/*            it's a way*/}
            {/*        </Typography>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </ChapterContainer>
    )
}

