import React, { useMemo, useRef, useState } from 'react';
import cls from 'classnames';
import { BASE_HEIGHT, BASE_WIDTH } from '../../../constants/hub';
import i18n from '../../../i18n';
import { HubContext } from '../context/HubContext';
import { Container } from './Container';
import { LevelBlock } from './LevelBlock';
import { TitleBlock } from './TitleBlock';
import {containsChinese, containsKorean} from "../../../../../utilities";

export const HubGrid = ({
	active = 0,
	blocks = [],
	initialized,
	langIndex,
	globalIndex,
	reverse = false,
	subtitle = '',
	theme,
	title = '',
	titleImage,
	titleRatio = 0.48,
	onInitBlock,
	onNext,
	onReverseBlock
}) => {
	const containerRef = useRef();
	const titleRef = useRef();
	const contextValue = useMemo(() => ({
		initialized,
		onInitBlock,
		onNext,
		onReverseBlock,
	}), [initialized, onInitBlock, onNext, onReverseBlock]);
	const [minTitleHeight, setMinTitleHeight] = useState(0);

	const rect = containerRef.current?.getBoundingClientRect();
	const dimensions = {
		width: rect?.width || BASE_WIDTH ,
		height: rect?.height || BASE_HEIGHT,
	};
	const levelRatio = (1 - titleRatio) / blocks.length

	const getMaxLevelHeight = () => {
		const containerHeight = dimensions.height;

		if (!containerHeight || !minTitleHeight) {
			return 0;
		}

		const levelsLength = blocks.length
		const { paddingTop, paddingBottom } = window.getComputedStyle(containerRef.current);
		const paddingY = parseFloat(paddingTop) + parseFloat(paddingBottom);

		const availableLevelsSpace = (containerHeight - paddingY) - minTitleHeight;
		const initialLevelHeight = (containerHeight - paddingY) * levelRatio
		const maxHeight = availableLevelsSpace - (initialLevelHeight * (levelsLength - 1));

		return maxHeight;
	};

	const isDarkTheme = theme === 'dark';
	const rootClassName = cls({
		's-dark-theme': isDarkTheme,
	});

	return (
		<HubContext.Provider value={contextValue}>
			<Container
				ref={containerRef}
				className={rootClassName}
				style={{ height: '100vh' }}
			>
				<TitleBlock
					ref={titleRef}
					key={`title-${langIndex}-${globalIndex}`}
					active={active === 0}
					heightRatio={titleRatio}
					title={title}
					titleImage={titleImage}
					subtitle={subtitle}
					reverse={reverse}
					rootDimensions={dimensions}
					onMinTitleHeight={setMinTitleHeight}
					blocksAmount={blocks.length}
				/>
				{blocks.map((block, index) => (
					<LevelBlock
						{...block}
						key={`${block.title}-${index}-${globalIndex}`}
						active={active === index + 1}
						dark={isDarkTheme}
						heightRatio={levelRatio}
						maxHeight={getMaxLevelHeight()}
						reverse={reverse}
						rootDimensions={dimensions}
						wide={blocks.some(({title = ''}) => title?.length > 1)}
					/>
				))}
			</Container>
		</HubContext.Provider>
	);
};
