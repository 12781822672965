import React, { useEffect, useMemo, useRef, useState } from 'react';
import { motion, TargetAndTransition  } from 'framer-motion';

import { DEFAULT_EASE, MultiLangText, themeToCSS, ThemeType } from '../shared';
import CodificationWithTranslations from './Codification/CodificationWithTranslations';
import Grid from './Grid';
import { DEFAULT_DURATION_S } from '../constants/transition';


const codificationCharDuration = 0.03;
const characterNextTrigger = 2;
const characterSwitchAmount = 4;
const languageTransitionDelay = 0.25;

type TitleTextProps = {
    text: MultiLangText,
    languages: string[],
    langIndex: number,
    cellLength: number,
    cols: number,
    rows: number,
    gridLineThickness: number,
    theme: ThemeType,
    exit?: TargetAndTransition,
    delay?: number,
    onTyped?: () => void,
};

export default function TitleText(props: TitleTextProps) {
    const {
        text,
        languages,
        langIndex,
        cellLength,
        cols,
        rows,
        gridLineThickness,
        theme,
        exit,
        delay = 0,
        onTyped,
    } = props;

    const titleContainerRef = useRef<HTMLDivElement>();
    const [maxSize, setMaxSize] = useState(null);
    const [titleBoundingRect, setTitleBoundingRect] = useState(null);
    const [titleStyle, setTitleStyle] = useState(null);
    const themeStyle = themeToCSS(theme);

    const titleComponent = useMemo(() => (
        <CodificationWithTranslations
            text={text}
            languages={languages}
            langIndex={langIndex}
            className='title-text'
            codificationProps={{
                characterSwitchAmount,
                characterNextTrigger,
                timingConfig: {
                    control: 'character',
                    duration: codificationCharDuration,
                },
            }}
            delay={delay}
            runFinalTextAnimation={false}
            languageTransitionDelay={languageTransitionDelay}
            onLanguageContainerMeassuerd={sizes => {
                let maxWidth = 0;
                let maxHeight = 0;

                sizes.forEach(({width, height}) => {
                    maxWidth = Math.max(maxWidth, width);
                    maxHeight = Math.max(maxHeight, height);
                });

                setMaxSize({
                    width: maxWidth,
                    height: maxHeight,
                });
            }}
            onTyped={onTyped}
        />
    ), [text, languages, langIndex]);

    useEffect(() => {
        if (!maxSize) {
            return;
        }

        const titlePadding = titleContainerRef.current ? parseFloat(window.getComputedStyle(titleContainerRef.current).paddingTop) : 0;
        const rect = {
            x: 0,
            y: 1,
            rows: Math.min(Math.ceil((maxSize.height + titlePadding * 2) / cellLength), rows),
            cols: Math.min(Math.ceil(maxSize.width / cellLength), cols),
        };
        setTitleBoundingRect(rect);

        const halfGridLineThickness = gridLineThickness / 2;
        setTitleStyle({
            ...themeStyle,
            marginTop: -halfGridLineThickness,
            marginLeft: -halfGridLineThickness,
            borderWidth: gridLineThickness,
            borderBottomStyle: 'solid',
            borderTopStyle: 'solid',
            borderRightStyle: rect.cols === cols ? 'none' : 'solid',
            top: rect.y * cellLength,
            left: rect.x * cellLength,
            height: rect.rows * cellLength - gridLineThickness,
            width: rect.cols * cellLength,
        });
    }, [maxSize]);

    return (
        <motion.div
            className='title-animation-container'
            style={titleStyle}
            initial={{
                clipPath: 'inset(0% 0% 0% 0%)'
            }}
            transition={{
                duration: DEFAULT_DURATION_S,
                delay: DEFAULT_DURATION_S,
                ease: DEFAULT_EASE
            }}
            exit={exit}
        >
            {titleStyle ? (
                <Grid
                    className='title-grid'
                    width={titleStyle.width}
                    height={titleStyle.height}
                    cols={titleBoundingRect.cols}
                    rows={titleBoundingRect.rows}
                    initialAnimation='visible'
                    animation='outAlternating'
                    lineStyle={{
                        strokeWidth: gridLineThickness,
                        stroke: themeStyle.color
                    }}
                />
            ) : null}
            <div
                ref={titleContainerRef}
                className='title-container'
            >
                <div style={{
                    width: maxSize?.width,
                    height: maxSize?.height,
                }}>
                    {titleComponent}
                </div>
            </div>
        </motion.div>
    )
}
