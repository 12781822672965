if (module.hot) {
  module.hot.accept(() => {
    location.reload()
  })
}

import {
  Uniform,
  Vector2,
  Color
} from 'three';

import UniversalLiveTemplate from '/src/touchpoints/live/lib/js/UniversalLiveTemplate';

import defaultSwoosh from 'url:/src/touchpoints/live/lib/assets/swoosh.png';
import {getSeason} from '/src/touchpoints/live/lib/js/utils.js';
import vert from '/src/touchpoints/live/lib/glsl/shader.vert';
import frag from './mannequin.frag';
import presetTL from './mannequin-timeline';

import './mannequin.scss'

class Mannequin extends UniversalLiveTemplate {
  constructor (name, datas, onComplete) {
    super(name);
    const width = window.ORIENTATION === 'PORTRAIT'
      ? Math.min(window.innerWidth, Math.min(1080, (window.innerHeight * (1080/1920))))
      : Math.min(window.innerWidth, Math.min(590, (window.innerHeight * (590/890))));
    const height = window.ORIENTATION === 'PORTRAIT'
    ? Math.min(window.innerHeight, Math.min(1920, (window.innerWidth * (1920/1080))))
    : Math.min(window.innerHeight, Math.min(890, (window.innerWidth * (890/590))));

    this.setCustomSize(width, height);
    this.setReferencesDimensions(590, 890);

    const borderThicknesses = {
      SMALL: '1px',
      MEDIUM: '2px',
      LARGE: '2px',
    }
    document.documentElement.style.setProperty('--lls-cs-mannequin-border-thick', borderThicknesses[window.SIZE_ID]);

    this.datas = datas;

    this.overlayWrapper = this.addNode({type: 'overlayWrapper', freeflow:true});

    this.viewFinderMarks = this.addNode({type: 'viewFinderMarks', freeflow:true,
    content: { stroke: '2px', size: 50 }})
    this.verticalTextTop = this.addNode({type: 'verticalText', parent: true, className:"top-right",
        content: { text: datas.assets[0]?.meta ? datas.assets[0]?.meta : 'NIKE STYLE' }})
    this.verticalTextBottom = this.addNode({type: 'verticalText', parent: true, className:"bottom-right",
        content: { text: getSeason() }})


    if (this.datas.assets[0]?.swatches?.length) {
      const offsets = [0, 8, 0, 8, 0, 0]; // quick and dirty
      const swatches = new Array(6).fill({}).map((e, i) => {
        return {
          color: this.datas.assets[0].swatches[i % this.datas.assets[0].swatches.length],
          offset: offsets[i] || 0
        };
      });
      this.addNode({type: 'swatchList', parent: true,
      content: { swatches }})
    }

    const res = new Vector2(width - 100, height - 100);
    const center = new Vector2(
      (width / 2),
      (height / 2)
    );
    const scale = ((height - 300) / height);

    this.registerTextureURL({
      src: this.datas.assets[0]?.url ?? defaultSwoosh,
      key: 'img1',
      pos: { x: center.x, y: center.y },
      dim: { x: res.x, y: res.y },
      pixel: 1,
      zoom: (scale),
      forceJPG: true,
    });

    const uniforms = {
      time: new Uniform(0),
      res: new Uniform(res),
      pixa: new Uniform(1),
      pixr: new Uniform(window.devicePixelRatio),
      stroke: new Uniform(1),
      mosaicAmount: new Uniform(0),
      mosaicColor: new Uniform(1., 1., 1.),
    }

    this.createVisualLayer({ frag, vert }, uniforms);
    this.setupTimeline(onComplete);
  }

  populateTimeline () {
    presetTL.bind(this)(() => {
      this.visualLayer.createTexture({
        src: this.datas.assets[1]?.url ?? defaultSwoosh,
        key: 'img1',
        forceJPG: true,
      }, false);
    }, this.datas.assets[0]?.meta);
  }

  preStart () {
    this.visualLayer.createTexture({
      src: this.datas.assets[0]?.url ?? defaultSwoosh,
      key: 'img1',
      forceJPG: true,
    }, false);
    this.wrapper.classList.add('active');
  }
}
export default Mannequin;
