import gsap from 'gsap';
import { ScrambleTextPlugin } from '/src/touchpoints/live/lib/js/vendors/ScrambleTextPlugin.min.js';
import {getSeason} from '/src/touchpoints/live/lib/js/utils.js';
gsap.registerPlugin(ScrambleTextPlugin);

export default function presetTL () {
  const tl = this.tl;
  const { uniforms } = this.visualLayer;

  tl.add(gsap.set(this.viewFinderMarks, { scale: 0 }), 0);
  tl.add(gsap.set(uniforms.img1ZoomRF, { value: 1.75 }), 0);
  tl.add(gsap.set(uniforms.img1Pix, { value: 20, ease: 'power2.out' }), 0);
  tl.add(gsap.set(uniforms.transitionScale, { value: 0 }), 0);

  tl.add(gsap.set(uniforms.gridMosaic, { value: 1 }), 0);
  tl.add(gsap.to(this.viewFinderMarks, { scale: 0.5, duration: 0.1 }), 0.15);

  tl.add(gsap.to(this.viewFinderMarks, { scale: 1, duration: 0.3, ease: 'power3.out' }), 0.3);
  tl.add(gsap.to(uniforms.img1Pix, { value: 8, duration: 0.2, ease: 'power2.out' }), 0.3);

  tl.add(gsap.to(uniforms.gridMosaic, { value: 0, duration: .01, ease: 'steps(1)' }), .15);
  tl.add(gsap.to(uniforms.gridMosaic, { value: 1, duration: .01, ease: 'steps(1)' }), .2);
  tl.add(gsap.to(uniforms.gridMosaic, { value: 0, duration: .3, ease: 'power0.none' }), .3);

  tl.add(gsap.to(this.verticalTextBottom.querySelector('h2'), {
    duration: .33,
    scrambleText: {
      text: getSeason(),
      chars: '             NIKE             ',
    }
  }), 0.7);
  tl.add(gsap.to(this.verticalTextTop.querySelector('h2'), {
    duration: 1,
    scrambleText: {
      text: 'NIKE STYLE',
      chars: '             NIKE             ',
    }
  }), 0.7);

  tl.add(gsap.to(uniforms.img1Pix, { value: 16, duration: 0.1, ease: 'power2.out' }), 0.6);
  tl.add(gsap.to(uniforms.img1Pix, { value: 1.0, duration: 0.3, ease: 'power2.out' }), 0.7);
  tl.add(gsap.to(uniforms.img1ZoomRF, { value: 1.5, duration: 0.3 }), 0.7);

  tl.add(gsap.set(uniforms.transitionScale, { value: 1 }), 3.5);
  tl.add(gsap.set(uniforms.img2ZoomRF, { value: 1.4 }), 3.5);
  tl.add(gsap.set(uniforms.img2Pix, { value: 40 }), 3.5);

  tl.add(gsap.to(uniforms.img2Pix, { value: 1.0, duration: 1.0, ease: 'power2.out' }), 3.6);

  tl.add(gsap.set(this.viewFinderMarks, { scale: 0.5 }), 4.5);

  tl.add(gsap.to(uniforms.img2Zoom, { value: 1.65, duration: 1.0, ease: 'power2.in'}), 4.2);
  tl.add(gsap.to(uniforms.img2ZoomRF, { value: 1.65, duration: 1.0, ease: 'power2.in'}), 4.2);
  tl.add(gsap.to(uniforms.transitionScale, { value: 10, duration: 1.0, ease: 'power3.in' }), 4.6);
  tl.add(gsap.to(this.viewFinderMarks, { scale: 1, duration: 0.3, ease: 'power3.out' }), 4.6);

  tl.add(gsap.to(uniforms.mosaicAmount, { value: 1, duration: 2.0, ease: 'power3.out' }), 7);
}
