import './touchpoints/rise/styles/normalize.css';
import i18next from 'i18next';
import { isReady } from './touchpoints/rise/i18n';

export const onReady = async () => {
	return new Promise(resolve => {
		if (isReady) {
			resolve();
			return;
		}
		i18next.on('initialized', () => resolve());
	});
};
