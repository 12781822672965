import React, { useEffect } from 'react';
import {
    animate,
    AnimatePresence,
    Easing,
    motion,
    useMotionTemplate,
    useMotionValue,
    usePresence,
} from 'framer-motion';

import cssClassNames from '../../styles/components/transitions/TwoColumns.module.scss';

import { DEFAULT_EASE, secToMs } from '../../shared';
import { DEFAULT_DURATION_S } from '../../constants/transition';


export type TwoColumnsProps = {
    children: any,
    start?: number,
    stop?: number,
    duration?: number,
    ease?: Easing,
}

export default function TwoColumns(props: TwoColumnsProps) {
    const {
        start = 100,
        stop = 0,
        duration = DEFAULT_DURATION_S,
        ease = DEFAULT_EASE,
    } = props;

    const [isPresent, safeToRemove] = usePresence();
    const startValue = useMotionValue(start);
    const column1 = useMotionValue(start);
    const column2 = useMotionValue(start);

    const clipPath = useMotionTemplate`polygon(
        0% ${startValue}%,
        0% ${column1}%,
        50% ${column1}%,
        50% ${column2}%,
        100% ${column2}%,
        100% ${startValue}%
    )`;

    useEffect(() => {
        const secondColumnDelay = 0.15;
        const transition = {
            duration: duration - secondColumnDelay,
            ease,
        };

        const animations = [
            animate(column1, stop, transition),
            animate(column2, stop, {
                ...transition,
                delay: secondColumnDelay,
                onComplete() {
                    animations.length = 0;
                }
            }),
        ];

        return () => {
            animations.forEach(a => a.stop());
        }
    }, []);

    return (
        <motion.div
            className={cssClassNames.two_columns_transition}
            style={{
                clipPath,
            }}
        >
            <AnimatePresence onExitComplete={isPresent ? undefined : safeToRemove}>
                {isPresent ? props.children : null}
            </AnimatePresence>
        </motion.div>
    );
}

export function calculateDuration(props: TwoColumnsProps) {
    const {
        duration = DEFAULT_DURATION_S,
    } = props;

    return secToMs(duration);
}
