import {pixelMapReady} from "../../../../utilities";

if (module.hot) {
  module.hot.accept(() => {
    location.reload()
  })
}

import * as THREE from 'three';
import { Pane } from 'tweakpane';
import { debounce } from 'lodash';

import PDPCloseUp from './chapters/pdp-closeup/pdp-closeup-app';
import Grid from './chapters/grid/grid-app';
import StyleGrid from './chapters/styleGrid/styleGrid-app';
import Messaging from './chapters/messaging/messaging-app';
import MessagingGrid from './chapters/messagingGrid/messagingGrid-app';
import LTCC from './chapters/LTCC/LTCC-app';

export default function (datas, demo=false) {
  console.log(datas);

  window.SIZE_ID = (Math.min(window.innerWidth, window.innerHeight) > 719)
    ? 'LARGE'
    : (Math.min(window.innerWidth, window.innerHeight) > 479)
      ? 'MEDIUM'
      : 'SMALL'

  const apps = [];
  let appIndex = 0;
  let running = false;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const showGUI = urlParams.get('showGUI');
  const noAutoStart = urlParams.get('noAutoStart');
  const chapterName = urlParams.get('chapterName');
  const chapterIndex = urlParams.get('chapter') || 'null';
  const keyframe = urlParams.get('keyframe');
  const iframeIndex = urlParams.get('index');
  let renderers = {};
  let canvasWrapperFront = document.createElement('div');
  let canvasWrapperCutout = document.createElement('div');

  if (Number.isInteger(Number(chapterIndex))) {
    appIndex = Number(chapterIndex);
  }

  document.body.style.background = 'black';

  const activeApps = {
    'LTCC': !chapterName || (chapterName === 'LTCC'),
    'messagingGrid': !chapterName || (chapterName === 'messagingGrid'),
    'styleGrid': !chapterName || (chapterName === 'styleGrid'),
    'grid': !chapterName || (chapterName === 'grid'),
    'pdp-closeup': !chapterName || (chapterName === 'pdp-closeup'),
    'messaging': !chapterName || (chapterName === 'messaging'),
  };
  const gui = new Pane({
    title: 'LLS Catalog Apparel',
    container: document.querySelector('.LLS-gui')
  });
  gui.hidden = !showGUI;

  gui.addButton({ title: 'restart', label: 'start (shift + space)' }).on('click', (e) => {
    appIndex = 0;
    apps[appIndex].start(keyframe);
    if (apps[appIndex].gui) {
      apps[appIndex].gui.hidden = !showGUI;
    }
  });
  gui.addButton({ title: 'play', label: 'play (space)' }).on('click', (e) => {
    apps[appIndex].resume();
  });
  gui.addButton({ title: 'pause', label: 'pause (space)' }).on('click', (e) => {
    apps[appIndex].pause();
  });

  const guiF1 = gui.addFolder({
    title: 'chapters list',
    expanded: demo
  });

  function addApp (name, app) {
    activeApps[name] && apps.push(app);
    guiF1.addInput(activeApps, name).on('change', (e) => {
      if (e.value) {
        apps.push(app);
      } else {
        const index = apps.indexOf(app);
        if (index !== -1) {
          apps.splice(index, 1);
        }
      }
    });
  }

  function nextApp () {
    if (apps[appIndex]) {
      apps[appIndex].stop();
      if (apps[appIndex].gui) {
        apps[appIndex].gui.hidden = true;
      }
    }
    if (chapterIndex !== 'null' ) {
      appIndex = (appIndex % apps.length);
    } else {
      appIndex = ((appIndex + 1) % apps.length);
    }

    apps[appIndex].start(keyframe);
    if(apps[appIndex].name == 'MESSAGING' || apps[appIndex].name == 'LTCC' || apps[appIndex].name == 'MESSAGE_GRID' ) {
      updateRenderersVisibility(false);
    } else {
      updateRenderersVisibility(true);
    }
    
    if (apps[appIndex].gui) {
      apps[appIndex].gui.hidden = !apps[appIndex].gui.hidden;
    }
  }

  function createRenderers (preventCutout = false) {
    const rendererBack = new THREE.WebGLRenderer({ antialias: true, alpha: true, });
    const rendererCutout = new THREE.WebGLRenderer({ antialias: true, alpha: true, });
    renderers = { front: rendererBack, cutout: rendererCutout}
 
    canvasWrapperFront.classList.add('canvas-wrapper-front');
    canvasWrapperFront.style.position = 'absolute';
    canvasWrapperFront.style.top = 0;
    canvasWrapperFront.style.zIndex = 3;
  

    canvasWrapperCutout.classList.add('canvas-wrapper-cutout');
    canvasWrapperCutout.style.position = 'absolute';
    canvasWrapperCutout.style.top = 0;
    canvasWrapperCutout.style.zIndex = 10;
  
    canvasWrapperFront.appendChild(rendererBack.domElement);
    document.body.appendChild(canvasWrapperFront);

    if (preventCutout === false) {
      canvasWrapperCutout.appendChild(rendererCutout.domElement);
      document.body.appendChild(canvasWrapperCutout);
    }
  }

  function updateRenderersVisibility(shouldShow) {
    if(shouldShow) {
      canvasWrapperFront.style.display = 'block';
      canvasWrapperCutout.style.display = 'block';
    } else {
      canvasWrapperFront.style.display = 'none';
      canvasWrapperCutout.style.display = 'none';
    }
  }

  createRenderers();

  datas.chapters.forEach(chapter => {
    if (!chapter.disabled && chapter.type === 'GRID' && chapter.assets?.length) {
      if (chapter.grid_type === 'c') {
        const styleGrid = new StyleGrid('STYLEGRID', chapter, nextApp, apps.length, gui);
        addApp('styleGrid', styleGrid);
      } else if (chapter.grid_type === 'a') {
  
        const grid = new Grid('GRID', chapter, nextApp, apps.length, renderers);
       
        addApp('grid', grid);
      }
    }
    if (!chapter.disabled && chapter.type === 'PDP_CLOSEUP' && chapter.assets?.length) {
  
      const pdpCloseUp = new PDPCloseUp('PDP_CLOSEUP', chapter, nextApp, apps.length, renderers, iframeIndex);
      addApp('pdp-closeup', pdpCloseUp);
    }

    if (!chapter.disabled && chapter.type === 'MESSAGING') {
      const messaging = new Messaging('MESSAGING', chapter, nextApp, apps.length, gui);
      addApp('messaging', messaging);
    }

    if (!chapter.disabled && chapter.type === "MESSAGE_GRID" && chapter.assets?.length) {
      const messagingGrid = new MessagingGrid('MESSAGE_GRID', chapter, nextApp, apps.length, gui);
      addApp('messagingGrid', messagingGrid);
    }
    if (!chapter.disabled && chapter.type === "LTCC" && chapter.assets?.length) {
     
      const ltcc = new LTCC('LTCC', chapter, nextApp, apps.length, gui);
      addApp('LTCC', ltcc);
    }
  });

  window.addEventListener('keypress', (e) => {
    if (e.code === 'Space') {
      if (e.shiftKey) {
        if (apps[appIndex].running) {
          apps[appIndex].stop();
        }
        appIndex = 0;
        if (apps[appIndex].gui) {
          apps[appIndex].gui.hidden = !apps[appIndex].gui.hidden;
        }
        apps[appIndex].start(keyframe);
      } else {
        if (apps[appIndex].running) {
          apps[appIndex].pause();
        } else {
          apps[appIndex].resume();
        }
      }
    } else if (e.code === 'KeyH') {
      gui.hidden = !gui.hidden;
      if (apps[appIndex].gui) {
        apps[appIndex].gui.hidden = !apps[appIndex].gui.hidden;
      }
    }
  }, false);

  function resetApps () {
    location.reload();
  }

  window.addEventListener('resize', debounce(resetApps, 500, { trailing: true, leading: false }), false);

  // window.dispatchEvent(new CustomEvent('animation_ready'));
  pixelMapReady(() => {
    appIndex = 0;
    apps[appIndex].start(keyframe);
   
    if(apps[appIndex].name == 'PDP_CLOSEUP' && apps[appIndex].isWide){
      shouldPlayTakeover(1.0);
    }
  })

  function shouldPlayTakeover(frequency,  precision = 3) {
    const getMinute = () => Number(new Date() / 1_000 / 60).toFixed(precision)
    const check = () => {
      const isDue = getMinute() % frequency === 0;
      if (isDue) {
        apps[0].start(0);
        console.log(`MinuteSync Iframe Index: ${iframeIndex}`);
      }
      requestAnimationFrame(check)
    }
    check();
  }

  if (!noAutoStart) apps[appIndex]?.start(keyframe);
};
