import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import './QRCode.scss';


const FALLBACK = 'https://res.cloudinary.com/jbboerner/image/upload/v1678584556/nikehyperlive/1678584556253.png';

const QRCode = ({ qrcode }) => {

    return <div className={`QRCode`}>

        <div className={'QRCode-icons'}>
            <div className={'QRCode-icon'}>
                <img src={qrcode} />
            </div>
            <div className={'QRCode-cta'}>
                Scan to<br />
                Download
            </div>
        </div>

    </div>

};

export default QRCode;
