import React from "react";
import '../../styles/fullscreen.scss';
import './Demo.scss';

import storeDemoVideos from '../../data/storedemo';

const Demo = ({ channel }) => {

    const source = storeDemoVideos[channel].url;

    console.log('[DEMO]', channel);

    return (
        <div className={'Demo'}>
            <video autoPlay={true} muted={true} controls={false} loop={true}>
                <source src={source} type="video/mp4"></source>
            </video>
        </div>
    );
};

export default Demo;
