@use 'sass:math';
@use '../variables' as vars;

.Carousel {
    background: white;
    height: 100%;

    > * {
        position: absolute;
        top: 0;
    }
}

.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 102px;
    width: 100%;
}

.CarouselItem {
    display: grid;

    > * {
        height: 100%;
    }
}

.description {
    @include vars.hoi-font;

    padding: vars.to-vh(110, 1920) vars.to-vw(75, 1080);
    font-size: vars.to-vh(50, 1920);
    letter-spacing: math.div(-2em, 30);
}
