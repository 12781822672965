import React from 'react';
import * as PIXI from 'pixi.js-legacy';

const START_TEXT = 0.25;
const END_TEXT = 0.75;

export default class FastLane {
	backgroundLength = 0;
	backgroundX = 0;
	backgroundEndX = 0;

	constructor(
		pole,
		renderWidth,
		length = 4,
		index,
		previous,
		isFiller = false,
		offset1 = 0,
		offset2 = 0,
		left,
		negOffset,
	) {
		this.isFiller = isFiller;
		this.index = index;
		this.pole = pole;
		this.previous = previous;
		this.negOffset = negOffset;

		this.height = 225; // height of one track
		this.width = 325; // width of 1 blocks
		this.screenWidth = renderWidth;
		this.numBlocks = isFiller ? 6 : 4;
		this.length = length;
		this.totalWidth = this.numBlocks * this.width;
		this.offset = pole < left ? offset1 : offset1 + offset2;

		this.x = (pole * this.width + this.offset) % this.screenWidth;
		this.y =
			Math.floor((pole * this.width + this.offset) / this.screenWidth) *
			this.height;

		if (previous) {
			if (pole >= left) {
				const wrapOffset = pole === left ? offset2 : 0;
				const otherOffset = pole === left ? this.offset : 0;
				this.x = previous.wrap
					? previous.endWrapX + wrapOffset
					: previous.endX + otherOffset;
			} else {
				this.x = previous.wrap ? previous.endWrapX : previous.endX;
			}
		}

		if (isFiller) {
			this.wrap = this.x + length * this.width > this.screenWidth;
		} else {
			this.wrap = this.x + this.totalWidth > this.screenWidth;
		}

		this.endX =
			this.x + this.width * length > this.screenWidth
				? this.screenWidth
				: this.x + this.width * length;

		this.wrapX = this.wrap
			? Math.abs(this.screenWidth - (this.x + this.totalWidth)) -
			  this.totalWidth
			: 0;

		this.endWrapX = this.wrapX + length * this.width;

		this.startTextX = this.x + START_TEXT * this.totalWidth;
		this.endTextX = this.x + END_TEXT * this.totalWidth;
		this.textWidth = this.endTextX - this.startTextX;

		this.mask1 = new PIXI.Graphics();
		this.mask2 = new PIXI.Graphics();
	}

	addRulerLines(app, x, y) {
			const pole = new PIXI.Graphics();
			pole.lineStyle({
				width: 1,
				color: 0xf1d61a,
			});
			pole.moveTo(x, y);
			pole.lineTo(x, y + this.height - 50);

		const style = new PIXI.TextStyle({
			fill: ['#f1d61a'],
			fontSize: 8
		});

		const basicText = new PIXI.Text(x, style);
		basicText.position.set(x - 10, y + this.height - 50);

		app.stage.addChild(pole);
		app.stage.addChild(basicText);
	}

	showRulers(app) {
		const length1 = Math.ceil((this.endX - this.x) / 25);

		for (let i = 0; i < length1; i++) {
			const x = this.x + this.negOffset + i * 25;
			this.addRulerLines(app, x, this.y);
		}

		if(this.wrap){
			for(let i = length1; i < this.totalWidth / 25; i++){
				const x = this.wrapX + this.negOffset + i * 25;
				const y = this.y + this.height;
				this.addRulerLines(app, x, y)
			}
		}
	}

	addPole(app, x, y) {
		const pole = new PIXI.Graphics();
		pole.lineStyle({
			width: 6,
			color: 0xda5df6,
		});
		pole.moveTo(x, y);
		pole.lineTo(x, y + this.height);

		app.stage.addChild(pole);
	}

	addPoleInfo(app, x, y, index) {
		const style = new PIXI.TextStyle({
			fill: ['#da5df6'],
		});

		const basicText = new PIXI.Text(index, style);
		basicText.position.set(x, y);
		app.stage.addChild(basicText);
	}

	showPole(app) {
		const length1 = Math.ceil((this.endX - this.x) / this.width);

		for (let i = 0; i < length1; i++) {
			const x = this.x + this.negOffset + i * this.width;
			this.addPole(app, x, this.y);
			this.addPoleInfo(
				app,
				x + 10,
				this.y + this.height / 2,
				this.pole + i,
			);
		}

		if (this.wrap) {
			for (let i = length1; i < this.length; i++) {
				const x = this.wrapX + this.negOffset + i * this.width;
				const y = this.y + this.height;
				this.addPole(app, x, y);
				this.addPoleInfo(
					app,
					x + 10,
					y + this.height / 2,
					this.pole + i,
				);
			}
		}
	}

	setMask1(container) {
		this.mask1.beginFill(0x000000);
		const originalWidth = this.length * this.width;
		let maskWidth = this.wrap
			? Math.abs(this.screenWidth - this.x)
			: originalWidth;
		let containerX = this.x;
		if (this.isFiller) {
			const maxWidth = this.length * this.width;
			const maskWrap = maxWidth + this.x > this.screenWidth;
			maskWidth = maskWrap
				? Math.abs(this.screenWidth - this.x)
				: maxWidth;

			containerX -= (6 - this.length) * this.width;
		}
		this.mask1.drawRect(this.x, this.y, maskWidth, this.height);
		this.mask1.endFill();
		container.position.set(containerX, this.y);
		container.mask = this.mask1;
	}

	setMask2(container) {
		this.mask2.beginFill(0x000000);
		this.mask2.drawRect(
			this.wrapX,
			this.y + this.height,
			this.length * this.width,
			this.height,
		);
		this.mask2.endFill();
		container.position.set(this.wrapX, this.y + this.height);
		container.mask = this.mask2;
	}

	getNumContainers = () => (this.wrap ? 2 : 1);

	setContainers(container1, container2, background) {
		this.setMask1(container1);

		if (container2) this.setMask2(container2, background);
	}

	setBackground(background, app) {
		this.background1 = new PIXI.Sprite(background.texture);
		const mask1 = new PIXI.Graphics();
		mask1
			.beginFill(0xff0000)
			.drawRect(0, 0, this.length * this.width, this.height)
			.endFill();

		mask1.position.set(this.x, this.y);

		this.backgroundX = this.offset;

		if (this.y !== 0) {
			this.backgroundX = -(this.pole * this.width - this.x);
		}

		this.backgroundLength = this.previous
			? this.previous.backgroundLength + this.length * this.width
			: this.length * this.width;

		this.background1.position.set(this.backgroundX, this.y);

		this.background1.mask = mask1;
		app.stage.addChild(mask1, this.background1);

		if (this.wrap) {
			const mask2 = new PIXI.Graphics();
			mask2
				.beginFill(0x00ff00)
				.drawRect(
					this.wrapX,
					this.y + this.height,
					this.length * this.width,
					this.height,
				)
				.endFill();

			const overlap = this.length * this.width + this.wrapX;
			const backgroundMaskX = -this.backgroundLength + overlap;

			this.background2 = new PIXI.Sprite(background.texture);
			this.background2.mask = mask2;
			this.background2.position.set(
				backgroundMaskX,
				this.y + this.height,
			);
			app.stage.addChild(mask2, this.background2);
		}
	}
}
