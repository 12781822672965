import {
  addDays,
  addHours,
  addMinutes,
  addSeconds,
  printTimeBetween,
  treatAsUTC,
} from '../../tsunami/utils/date';
import { isNil, isEmpty } from "lodash";
import { app } from '..';
import { StoryImage, StoryImages } from './StoryImage';

const factory = document.createElement('div');

export function getTextRecursively(element, text) {
  for (let i = 0; i < element.children.length; i++) {
    let textContent = element.children[i].textContent;
    text = text + textContent;
    getTextRecursively(element.children[i], text);
  }
}

export function parseHTMLText(value) {
  factory.innerHTML = value;
  return factory.textContent;
}

export class Stories {
  static fromJSON(array, includes, DropClass = Story) {
    const drops = [];
    if (array) {
      // const yesterday = addDays(app.model.nowDate, -1);
      // const nextMonth = addDays(app.model.nowDate, 30);
      array.forEach((data, i) => {
        // Filter out publishedContent subType other than thread
        // console.log(data)
        if (
          data.publishedContent.nodes.length > 0
        ) {
          const drop = new DropClass();
          drop.fromJSON(data, drops.length, includes);
          // filter out drops with less than 3 images
          if (drop.images.length > 2) {
            drops.push(drop);
          }
        }
      });
    }
    // Uncomment this line to test the grid with 12 shoes
    // return drops.slice(0, 12);
    return drops;
  }

  static loadImages(drops) {
    let promises = [];
    drops.forEach((drop, i) => {
      // const imagesLength = (i < 12) ? drop.images.length : 1;
      // const imagesLength = drop.images.length;
      const imagesLength = drop.isDroppingToday ? drop.images.length : 1;
      for (let j = 0; j < imagesLength; j++) {
        let image = drop.images[j];
        const imagePromise = image.preload();
        promises.push(imagePromise);
      }
    });
    return Promise.all(promises);
  }
}

export default class Story {
  constructor() {
    this.images = [new StoryImage()];
    this.index = NaN;
    this.id = '';
    this.key = '';
    this.startEntryDate = null;
    this.title = '';
    this.subtitle = '';
    this.description = '';
    this.fulltitle = '';
    this.slug = '';
    this.productType = '';
    this.inStock = false;
    this.isJordan = false;
  }

  //   get isDroppingToday() {
  //     const now = this.now();
  //     return (
  //       now.getDate() == this.startEntryDate.getDate() &&
  //       now.getMonth() == this.startEntryDate.getMonth()
  //     );
  //   }

  //   get isDroppingWithin24Hours() {
  //     const previousDayDate = addDays(this.now(), -1);
  //     const nextDayDate = addDays(this.now(), 1);
  //     return (
  //       this.startEntryDate > previousDayDate && this.startEntryDate < nextDayDate
  //     );
  //   }

  //   get isDroppingThisWeek() {
  //     const nextWeekDate = addDays(this.now(), 7);
  //     return this.startEntryDate < nextWeekDate;
  //   }

  //   get hasImageGallery() {
  //     return this.images.length > 1;
  //   }

  now() {
    return app?.model?.nowDate ?? new Date();
  }

  getDropTime() {
    return printTimeBetween(this.startEntryDate, this.now());
  }

  fromJSON(obj, index, includes) {
    this.id = obj.id;
    this.key = obj.id;
    this.index = index;
    this.title = obj.publishedContent?.properties?.title;
    this.subtitle = obj.publishedContent?.properties?.subtitle;
    this.slug = obj.publishedContent?.properties.seo?.slug;
    this.isJordan = isNil(obj.publishedContent.properties.seo.description.toUpperCase().match(/JORDAN/gm));
    this.cover = {
      resource_type: 'image',
      url: obj.publishedContent?.properties?.coverCard?.properties?.portraitURL
    };
    let nodes = [];
    if (!isEmpty(includes[0])) nodes = obj.publishedContent?.nodes?.filter(chapter => includes?.some(v => chapter.properties?.body?.toUpperCase().includes(v)));
    else nodes = obj.publishedContent.nodes;
    this.images = StoryImages.fromJSON(nodes, index);
    return this;
  }
}
