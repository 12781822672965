import { CSSProperties } from 'react';
import { cloneDeep, get } from 'lodash';
import { preloadFont } from 'troika-three-text'
import { Easing } from 'framer-motion';

import { clearStorageIfNeeded, createStorage, preloadAllAssets } from './storage';
import { HoiConfig } from './components/ChapterRunner';
import { fonts } from '../core/animations/ticker/ticker/shared';
import { NIKE_QR_BASE_URL } from '../../config';
import * as TitleCard from './screens/TitleCard';
import { calculateDuration as mediaCalculateDuration } from './screens/Media';
import * as Services from './screens/Services';
import * as Wayfinding from './screens/Wayfinding';
import * as ProductIntro from './screens/ProductIntro';
import * as CampaignIntro from './screens/CampaignIntro';
import * as Carousel from './screens/Carousel';
import * as SnkrsTitle from './screens/SnkrsTitle';
import * as SnkrsCal from './screens/SnkrsCal';
import { loadDrops } from '../jordan/screens/SnkrsCal';
import { loadStories } from '../jordan/screens/SnkrsStories';

export type ThemeType = 'dark' | 'light';

export function themeToCSS(theme: ThemeType): CSSProperties {
    switch (theme) {
        case 'dark':
            return {
                background: 'black',
                color: 'white',
            };

        case 'light':
        default:
            return {
                background: 'white',
                color: 'black',
            }
    }
}

export type MultiLangText = { [lang: string]: string };

export interface MediaType {
    blob?: Blob;
    etag?: string;
    url: string;
    original_url?: string;
    resource_type: 'image' | 'video' | 'm3u8-video';
    fit?: 'fit' | 'cover';
    force_duration?: string | number | 'full';
    duration?: number;
    width?: number;
    height?: number;
    format?: string;
    dontTransform?: boolean;
    destination_url?: string;
}

export type CoverType = 'fill' | 'fit';

export type ChapterTemplateType =
    'title_card'
    | 'wayfinding'
    | 'media'
    | 'services'
    | 'product_intro'
    | 'campaign_intro'
    | 'carousel'
    | 'snkrs_title'
    | 'snkrs_cal'
    | 'snkrs_cal_blend'
    | 'snkrs_cal_stories';

export interface ChapterType {
    template: ChapterTemplateType;
    duration: number;
    exitDuration: number;
    data: any;
    skip?: boolean;
    fullScreen?: boolean;
    transition?: TransitionConfig;
}

export type TransitionVariant = 'two-columns';

export type TransitionConfig = {
    variant: TransitionVariant,
};

export interface CenterCourtGradient {
    type: 'linear' | 'radial' | undefined;
    colors: Array<Array<string>>
}

async function initialize(): Promise<void> {
    createStorage();
    await clearStorageIfNeeded();

    const characters = '01234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    return new Promise((resolve, reject) => {
        preloadFont({ font: fonts['jordan-condensed'], characters }, resolve);
    });
}

function expandChapterMacros(rawData: any): any {
    let chapters = rawData?.chapters?.map((a, chapterIndex) => ({ ...a, chapterIndex })).reduce((acc, curr, i) => {
        let chapters = [curr];
        const templateType: ChapterTemplateType = curr.template;
        const marketplace = curr.marketplace;
        const hideDates = curr?.hide_dates;
        const ctaLabel = curr?.cta_label;
        const interleave = true;
        const locale = curr?.locale;
        const available_copy = curr?.available_copy;
        const qr_drops = curr?.qr_drops;
        const qr_stories = curr?.qr_stories;

        if (curr?.template === 'snkrs_cal_blend') {
            const MAX_DROPS = 6;
            const rawBlend = curr?.blend ?? '2,2';
            const [drops, stories] = rawBlend.split(',');
            const repeats = Math.floor(MAX_DROPS / drops);

            const createChapters = (acc, ucurr, i) => {
                const drop = {
                    template: 'snkrs_cal',
                    chapterIndex: curr?.chapterIndex,
                    marketplace,
                    locale,
                    available_copy,
                    hide_dates: hideDates,
                    play_range: `${i * drops},${((i + 1) * drops) - 1}`,
                    cta_label: stringIsFilled(curr?.cta_drops) ? curr.cta_drops : ctaLabel,
                    qr_url: qr_drops,
                };
                const story = {
                    template: 'snkrs_cal_stories',
                    locale,
                    marketplace,
                    available_copy,
                    search_includes: curr?.search_includes,
                    play_range: `${i * stories},${((i + 1) * stories) - 1}`,
                    cta_label: stringIsFilled(curr?.cta_stories) ? curr.cta_stories : ctaLabel,
                    qr_url: qr_stories,
                };

                const proceeding = interleave ? ['mark-for-replacement'] : [];
                const markEnd = repeats === (i + 1) ? ['mark-end'] : [];

                return [
                    ...acc,
                    drop,
                    story,
                    ...proceeding,
                    ...markEnd,
                ]
            };


            chapters = [...new Array(repeats)].reduce(createChapters, []);
        }

        return [
            ...acc,
            ...chapters
        ]
    }, []);

    const interleaveProceeding = (ch) => {
        const getRemainingChapters = () => {
            return ch.reduce((acc, curr) => {

                if (acc.start) {
                    return {
                        start: true,
                        chapters: [
                            ...acc.chapters,
                            curr,
                        ]
                    }
                }

                if (curr === 'mark-end') {
                    return { start: true, chapters: [] };
                }

                return acc;

            }, { start: false, chapters: [] }).chapters;
        }

        return ch?.reduce((acc, curr) => {
            let append = [curr];

            if (curr === 'mark-end' || acc.ended) {
                return {
                    ended: true,
                    chapters: acc.chapters,
                };
            }

            if (curr === 'mark-for-replacement') {
                append = getRemainingChapters();
            }

            return {
                ended: false,
                chapters: [
                    ...acc.chapters,
                    ...append,
                ]
            }
        }, { ended: false, chapters: [] }).chapters;
    }

    chapters = interleaveProceeding(chapters);

    return {
        ...rawData,
        chapters,
    }
}

function calculateDuration(chapterData, config): number {
    const templateType: ChapterTemplateType = chapterData.template;
    let duration = chapterData?.duration ?? config?.duration?.default;

    let customHandler;
    switch (templateType) {
        case 'title_card':
            customHandler = TitleCard;
            break;

        case 'media':
            return mediaCalculateDuration(chapterData, config);

        case 'services':
            customHandler = Services;
            break;

        case 'wayfinding':
            customHandler = Wayfinding;
            break;

        case 'product_intro':
            customHandler = ProductIntro;
            break;

        case 'campaign_intro':
            customHandler = CampaignIntro;
            break;

        case 'carousel':
            customHandler = Carousel;
            break;

        case 'snkrs_title':
            customHandler = SnkrsTitle;
            break;

        case 'snkrs_cal':
        case 'snkrs_cal_stories':
            customHandler = SnkrsCal;
            break;
    }

    return customHandler?.calculateDuration(chapterData, config) || duration;
};

function calculateExitDuration(chapterData) {
    const templateType: ChapterTemplateType = chapterData.template;
    let duration = 0;

    let customHandler;
    switch (templateType) {
        case 'title_card':
            customHandler = TitleCard;
            break;

        case 'services':
            customHandler = Services;
            break;

        case 'wayfinding':
            customHandler = Wayfinding;
            break;

        case 'product_intro':
            customHandler = ProductIntro;
            break;

        case 'campaign_intro':
            customHandler = CampaignIntro;
            break;

        case 'carousel':
            customHandler = Carousel;
            break;
    }

    return customHandler?.calculateExitDuration(chapterData) || duration;
}

export async function prepareShowRunner(rawData: any, config?: HoiConfig): Promise<Array<ChapterType>> {
    await initialize();

    // inflating the data
    const hydratedData = expandChapterMacros(rawData);


    const filterDisabled = ({ disabled = false }) => {
        return disabled === false;
    }

    const promises = hydratedData?.chapters?.filter(filterDisabled).map(async (initialChapterData): Promise<ChapterType> => {
        let { template, ...data } = initialChapterData;

        // todo: refactore
        if (template === 'snkrs_cal') {
            data.drops = await loadDrops(initialChapterData.marketplace);
            data.mode = 'drops';
        }

        if (template === 'snkrs_cal_stories') {
            //TODO: Remove hardcoded includes when editor is sending field data
            initialChapterData.includes = initialChapterData.search_includes ?? '';
            data.drops = await loadStories({
                marketplace: initialChapterData?.marketplace,
                include_videos: initialChapterData?.include_videos,
                search: {
                    includes: initialChapterData?.includes?.split?.(",")
                }
            });
            data.mode = 'stories';
        }

        const insideEditor = !get(window, 'channel');

        if (insideEditor) {
            console.log('dont preload in editor');
        }

        data = await preloadAllAssets(data, {
            ...config,
            dontCache: insideEditor
        });

        const chapterData = {
            template,
            ...data,
        }

        return {
            data,
            template,
            skip: false,
            duration: calculateDuration(chapterData, config),
            exitDuration: calculateExitDuration(chapterData),
        };
    }) ?? [];

    return Promise.all(promises);
}

export function stringIsFilled(str?: string) {
    return str && str.length > 0 && str.replaceAll(' ', '') !== '';
}

export function stringFallback(content: any, defaultValue?: string) {
    return ['', null, undefined, ' '].includes(content) ? defaultValue : content;
}

export const SECOND = 1_000;

export const keyframe = (initialDelay, config: { speed: number } = { speed: 1 }) => {
    let timeouts = [];
    let clears = [];
    const { speed = 1 } = config;

    const trigger = (after: number, action: TimerHandler) => {
        const id = setTimeout(action, ((after + initialDelay) * SECOND) * speed);
        timeouts.push(id);

        const { trigger, clear } = keyframe(initialDelay + after, config);
        clears.push(clear);
        return trigger;
    }

    return {
        trigger,
        clear: () => {
            timeouts.forEach(i => clearTimeout(i));
            clears.forEach(i => i());
        }
    }
}

/**
 * Gets the preferred item from array falls back in the order of indexes.
 *
 * @param array
 * @param indexes
 */
export const preferredAsset = (array: Array<MediaType>, ...indexes: Array<number>): MediaType | null => {
    const index = indexes.findIndex((i) => {
        return array?.[i]?.url;
    });
    return array?.[indexes[index]];
}

export function countDown(date) {
    const now = new Date().getTime();
    // Find the distance between now and the count down date
    var distance = date - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
}

export const DEFAULT_EASE: Easing = [0.4, 0, 0, 1];

export const fetchChannelInfo = () => {
	return cloneDeep(window?.channel?.channels?.[0])
}

const calculateSize = () => {
    const MAX_HEIGHT = 3_840;
    const height = window.innerHeight;

    return Math.min(height, MAX_HEIGHT );
}

export function channelConfig({ forceAssetHeight }: { forceAssetHeight?: boolean } = {}): HoiConfig {
    const { json_config = {} } = fetchChannelInfo() ?? {};

    return {
        duration: {
            default: get(json_config, 'duration.default', 8) * SECOND,
            interstitial: get(json_config, 'duration.interstitial', 3) * SECOND,
            snkrs: {
                overview: get(json_config, 'duration.snkrs.overview', 3) * SECOND,
                drop: get(json_config, 'duration.snkrs.drop', 10) * SECOND,
                drop_media_count: get(json_config, 'duration.snkrs.drop_media_count', 3),
                story: get(json_config, 'duration.snkrs.story', 15) * SECOND,
                story_page_count: get(json_config, 'duration.snkrs.story_page_count', 3),
            },
        },
        forceAssetHeight: forceAssetHeight ? calculateSize() : undefined,
    }
}

export function timedMedia(media: Array<MediaType>, totalDuration): Array<MediaType> {
    const filtered = media.filter(i => i?.url);
    const duration = (totalDuration / SECOND) / filtered.length;

    return filtered.map((m, i) => {
        const isLast = (i + 1) === media.length;

        return {
            ...m,
            force_duration: isLast ? duration + 1 : duration
        }
    });
}

/**
 * Checks if the canvas is blank.
 *
 * @note May be performance issue.
 *
 * @param canvas
 */
export function isCanvasBlank(canvas): boolean {
    const blank = document.createElement('canvas');

    blank.width = canvas.width;
    blank.height = canvas.height;

    return canvas.toDataURL() === blank.toDataURL();
}

export function toTitleCase(str: string): string {
    return str.split(' ')
        .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
        .join(' ');
}

export function makeDynamicFontSize(factor): Function {
    return (size) => {
        return size * factor;
    }
}

export function getCoordinates(): string {
    const meta = get(window, 'animation_store.json_meta', {});

    const {
        n = "9.1873",
        e = "45.4628",
    } = meta?.coordinates ?? {};

    return `${n}°N ${e}°E`;
}

interface QrUrlConfig {
    width?: number;
    trackChannel?: boolean;
}

export function qrUrlTrack(destination: string, config: QrUrlConfig = {}): string {
    const { width = 140, trackChannel = true } = config;
    const channelSlug = trackChannel ? get(window, 'channel.channels[0].slug', null) : null;

    const url = new URL(NIKE_QR_BASE_URL);

    url.searchParams.set('width', width.toString());
    url.searchParams.set('url', destination);

    if (channelSlug) {
        url.searchParams.set('channel-slug', channelSlug);
    }

    return url.toString();
}

export function truncate(content: string, maxLength = 20) {
    return content?.length > maxLength ? `${content?.substring(0, maxLength)}...` : content;
}

export function longestWordInString(content: string): number {
    const words = content.match(/\b(\w+)\b/g);

    return words?.reduce((acc, curr) => {
        const wordLength = curr?.length ?? 0;

        return acc > wordLength ? acc : wordLength;
    }, 0) ?? 0;
}

export function secToMs(seconds: number) {
    return seconds * SECOND;
}


export function setAnimationTimeout(fn: Function, timeOut: number) {
    let request: number;
    let start: number;

    const step = (timeStamp: number) => {
        start = start || timeStamp;
        const ellapsed = timeStamp - start;

        if (ellapsed < timeOut) {
            request = window.requestAnimationFrame(step);
        } else {
            request = undefined;
            fn();
        }
    };

    request = window.requestAnimationFrame(step);

    return () => {
        if (!request) {
            return;
        }

        window.cancelAnimationFrame(request);
        request = undefined;
    }
}

export function getLangText(multiLangText: MultiLangText, lang: string) {
    return multiLangText[lang] || '';
}

export function hasTextToDisplay(multiLangText: MultiLangText, languages: string[]) {
    return languages.find(lang => getLangText(multiLangText, lang).length > 0);
}

export function getMediaDuration(media: MediaType) {
    const {
        resource_type,
        force_duration,
        duration,
    } = media;
    const toShowFullVideo = resource_type === 'video' && force_duration === 'full';

    return toShowFullVideo ? duration : Number(force_duration);
}

export function scaleText(container: HTMLElement, partSelector: string) {
    const {width} = container.getBoundingClientRect();
    let maxNeededWidth = width;

    const parts = container.querySelectorAll<HTMLSpanElement>(partSelector);
    for (const part of parts) {
        const wordWidth = part.getBoundingClientRect().width;
        maxNeededWidth = Math.max(maxNeededWidth, wordWidth);
    }

    const scale = width / maxNeededWidth;

    const style = window.getComputedStyle(container);
    container.style.fontSize = `calc(${style.fontSize} * ${scale})`;
    container.style.lineHeight = `calc(${style.lineHeight} * ${scale})`;

    return scale;
}
