import * as mime from 'mime';
import { get, isEmpty } from "lodash";

export const assetUrlFormatter = (url, config = {}) => {
	try {
		const ext = url.split('.').pop()
		const mimeType = mime.getType(ext);
		if (!mimeType.startsWith('video')) return url;

		const regex = /upload\/(.*)\/rise\/(.*)/g
		const parts = regex.exec(url)
		const fn = parts[2];
		const version = parts[1]

		const filename = fn.split('.').slice(0, -1).join('.')

		let size = '';

		if (config.width) {
			size = `w_${config.width}/`
		}
		if (config.height) {
			size = `h_${config.height}/`
		}

		const urlConfig = new URL(url);
		const urlStart = get(urlConfig.href.match(/.*(video\/upload)/g), '[0]', null);

		if (isEmpty(urlStart)) {
			return url;
		}

		return `${urlStart}/${size}${version}/rise/${filename}.webm`
	} catch (e){
		return url
	}
}
