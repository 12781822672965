import {AnimatePresence, motion} from "framer-motion";
import {GRADIENTS} from "../../../../rise/constants/hub";
import {DEFAULT_GRADIENT, wait} from "../shared";
import Transition from "../../../../jordan/components/Transition";
import {CSSProperties, useEffect, useState} from "react";

const ANIMATION_STATE = {
    INITIAL: 'initial',
    WIPE_ON_COLOR: 'wipe-on-color',
    BLACK_VISIBLE: 'black-visible',
    WIPE_ON_COLOR_REVERSE: 'wipe-on-color-reverse',
    WAIT: 'wait',
} as const;

type animationStateType = (typeof ANIMATION_STATE)[keyof typeof ANIMATION_STATE];

export default function StepCounter({step}: { step: string }) {
    const totalDuration = 8;

    const duration = 1;
    const [animationState, setAnimationState] = useState<animationStateType>(ANIMATION_STATE.INITIAL);

    async function start() {
        setAnimationState(ANIMATION_STATE.WIPE_ON_COLOR);
        await wait(1000)
        setAnimationState(ANIMATION_STATE.BLACK_VISIBLE);
        await wait(4000)
        setAnimationState(ANIMATION_STATE.WIPE_ON_COLOR_REVERSE);
        await wait(880)
        setAnimationState(ANIMATION_STATE.WAIT);
        await wait(20)
        setAnimationState(ANIMATION_STATE.INITIAL);
    }

    const position: CSSProperties = {
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
    };

    useEffect(() => {
        start().then();
    }, [step]);

    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                position: 'relative',
            }}
        >

            <AnimatePresence>
                {
                    ['wipe-on-color'].includes(animationState) && (
                        <Transition
                            key={'wipe-on-color'}
                            variant={'wipe-x'}
                            duration={1}
                            style={{
                                ...position,
                                background: DEFAULT_GRADIENT,
                                zIndex: 2,
                            }}
                        >
                            <Outlined value={step} color={'#fff'}/>
                        </Transition>
                    )
                }

                {
                    ['black-visible', 'wipe-on-color-reverse'].includes(animationState) && (
                        <div
                            key={'black-visible'}
                            style={{
                                ...position,
                                zIndex: 1
                            }}
                        >
                            <Outlined value={step} color={'#000'}/>
                        </div>
                    )
                }

                {
                    ['wipe-on-color-reverse', 'wait'].includes(animationState) && (
                        <Transition
                            key={'wipe-on-color-reverse'}
                            variant={'wipe-x-reverse'}
                            duration={1}
                            style={{
                                ...position,
                                background: DEFAULT_GRADIENT,
                                zIndex: 2,
                            }}
                        >
                            <Outlined value={step} color={'#fff'}/>
                        </Transition>
                    )
                }
            </AnimatePresence>


        </div>
    )
}

function Outlined({value, color = '#fff'}: { value: string, color?: string }) {
    return (
        <svg
            style={{
                height: '100%',
                width: '100%',
            }}
            viewBox="0 0 10 10"
        >
            <text
                x="5"
                y="6"
                textAnchor="middle"
                alignmentBaseline="middle"
                fontSize="9"
                fill="none"
                strokeWidth=".12"
                stroke={color}
                fontFamily="FUTURA"
            >
                {value}
            </text>
        </svg>
    )
}