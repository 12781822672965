import React from 'react';
import * as THREE from 'three';

export const TickerImage = ({ media, width, textOffset, marginOffset, fontSize, color }) => {
    return (
        <mesh
            position={[
                textOffset + width / 2,
                -marginOffset / 2,
                0,
            ]}
        >
            <planeGeometry
                attach="geometry"
                args={[width, fontSize]}
            />
            <meshBasicMaterial
                attach="material"
                transparent={true}
                color={
                    new THREE.Color(
                        color,
                    )
                }
                map={new THREE.TextureLoader().load(
                    media.url,
                )}
            />
        </mesh>
    );

} 