import './swatchList.scss';

const defaults = {
  size: {
    SMALL: 10,
    MEDIUM: 20,
    LARGE: 30,
  }
}

export default function swatchList (content, repeatLength = 0) {
  const fullLength = repeatLength >= content.swatches.length ? repeatLength : content.swatches.length
  const div = document.createElement('div');
  div.classList.add('swatch-list');

  const ul = document.createElement('ul');

  const size = content.size || defaults.size[window.SIZE_ID];
  const groupSize = content.groupSize || 2;
  let wrappers = [];

  function addItemInGroup (swatch, group) {
    const swatchItem = document.createElement('div');
    swatchItem.classList.add('swatch-item');
    swatchItem.style.backgroundColor = swatch.color;
    swatchItem.style.width = `${size}px`;
    swatchItem.style.height = `${size}px`;
    group.appendChild(swatchItem);
  }

  let count = 0;
  for (let i = 0; i < Math.floor(fullLength / groupSize); i++) {
    const li = document.createElement('li');
    li.classList.add('swatch-group');
    ul.appendChild(li);
    wrappers.push(li);

    for (let j = 0; j < groupSize; j++) {
      const swatchIndex = (count % content.swatches.length);
      const swatch = content.swatches[swatchIndex];
      addItemInGroup(swatch, li);
      count++;
    }
  }
  
  div.appendChild(ul);
  return div;
}
