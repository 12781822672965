import React, { Component } from 'react';
import './NSILVideo.scss';
import { get } from 'lodash';

class NSILVideo extends Component {
  render() {
    const source = get(this.props.data, 'asset.url');
    const orientation = get(this.props.data, 'orientation', 'portrait');
    const isLandscape = orientation === 'landscape';
    return (
      <div className={`NSILVideo`}>
        <video
          autoPlay={true}
          muted={true}
          controls={false}
          loop={true}
          className={`${isLandscape ? 'landscape' : ''}`}
        >
          <source
            src={source}
            type="video/mp4"
          />
        </video>
      </div>
    );
  }
}

export default NSILVideo;
