{
  "en": {
    "title": "Store\ndirectory",
    "subtitle": "",
    "blocks": [
      {
        "title": "3",
        "description": "",
        "icons": [],
        "legend": [
          {
            "title": "Mens"
          },
          {
            "title": "Jordan"
          },
          {
            "title": "Nike Service Hub",
            "subheadings": [
              "Returns & Exchanges",
              "Launch Pickup"
            ]
          },
          {
            "title": "Fitting Rooms"
          }
        ]
      },
      {
        "title": "2",
        "description": "",
        "icons": [],
        "legend": [
          {
            "title": "Women"
          },
          {
            "title": "Kids"
          },
          {
            "title": "Fitting Rooms"
          }
        ]
      },
      {
        "title": "1",
        "description": "",
        "icons": [],
        "legend": [
          {
            "title": "Nike Service Hub",
            "subheadings": [
              "Order Pickup",
              "Returns & Exchanges",
              "Nike Recycling & Donation",
              "Check-in"
            ]
          },
          {
            "title": "Nike By You"
          },
          {
            "title": "Nike Repair"
          },
          {
            "title": "Self-Checkout"
          },
          {
            "title": "Fitting Rooms"
          }
        ]
      }
    ]
  },
  "ko": {
    "title": "매장 디렉토리",
    "subtitle": "",
    "blocks": [
      {
        "title": "3",
        "description": "",
        "icons": [],
        "legend": [
          {
            "title": "남성"
          },
          {
            "title": "조던"
          },
          {
            "title": "나이키 서비스 허브",
            "subheadings": [
              "교환 및 환불",
              "드로우 픽업"
            ]
          },
          {
            "title": "피팅룸"
          }
        ]
      },
      {
        "title": "2",
        "description": "",
        "icons": [],
        "legend": [
          {
            "title": "여성"
          },
          {
            "title": "키즈"
          },
          {
            "title": "피팅룸"
          }
        ]
      },
      {
        "title": "1",
        "description": "",
        "icons": [],
        "legend": [
          {
            "title": "나이키 서비스 허브",
            "subheadings": [
              "주문 픽업",
              "교환 및 환불",
              "나이키 리사이클링 & 기부",
              "체크인"
            ]
          },
          {
            "title": "나이키 바이 유"
          },
          {
            "title": "나이키 리페어"
          },
          {
            "title": "셀프 결제 서비스"
          },
          {
            "title": "피팅룸"
          }
        ]
      }
    ]
  }
}
