import React, { CSSProperties, useMemo } from 'react';
import { Variant, Variants, motion } from 'framer-motion';
import classNames from 'classnames';

import { DEFAULT_DURATION_S, DEFAULT_EASE } from '../constants/transition';

import '../styles/components/grid.scss';


export type GridVariants = {
    hidden: Variant,
    in: Variant,
    visible: Variant,
    out: Variant,
    outAlternating: Variant,
    'out-top-left': Variant,
};

export type GridAnimationType = keyof GridVariants;

function generateVariants(coordinate: 'x' | 'y'): GridVariants {
    const prop1 = `${coordinate}1`;
    const prop2 = `${coordinate}2`;

    return {
        hidden: ({delay}) => ({
            [prop1]: 0,
            [prop2]: 0,
            transition: {
                [prop1]: {
                    duration: 0
                },
                [prop2]: {
                    delay,
                    duration: 0,
                },
            }
        }),

        visible: ({length, delay}) => ({
            [prop1]: 0,
            [prop2]: length,
            transition: {
                [prop1]: {
                    duration: 0
                },
                [prop2]: {
                    delay,
                    duration: 0,
                },
            }
        }),

        in: ({minDuration, floatingDuration, ease, delay, length}) => ({
            [prop1]: 0,
            [prop2]: length,
            transition: {
                [prop1]: {
                    duration: 0
                },
                [prop2]: {
                    duration: minDuration + floatingDuration * Math.random(),
                    delay,
                    ease
                }
            },
        }),

        out: ({minDuration, floatingDuration, ease, delay, length}) => ({
            [prop1]: length,
            [prop2]: length,
            transition: {
                [prop1]: {
                    duration: minDuration + floatingDuration * Math.random(),
                    delay,
                    ease
                },
                [prop2]: {
                    duration: 0
                },
            },
        }),

        outAlternating: ({minDuration, floatingDuration, ease, delay, length, index}) => {
            const duration = minDuration + floatingDuration * Math.random();
            return index % 2 ? {
                [prop1]: length,
                [prop2]: length,
                transition: {
                    [prop1]: {
                        duration,
                        delay,
                        ease
                    },
                    [prop2]: {
                        duration: 0
                    },
                },
            } : {
                [prop1]: 0,
                [prop2]: 0,
                transition: {
                    [prop1]: {
                        duration: 0
                    },
                    [prop2]: {
                        duration,
                        delay,
                        ease
                    },
                },
            }
        },

        'out-top-left': ({minDuration, floatingDuration, ease, delay, length}) => ({
            [prop1]: 0,
            [prop2]: 0,
            transition: {
                [prop1]: {
                    duration: 0
                },
                [prop2]: {
                    duration: minDuration + floatingDuration * Math.random(),
                    delay,
                    ease
                },
            },
        }),
    }
}

const variantsX = generateVariants('x');
const variantsY = generateVariants('y');

export type GridProps = {
    height: number,
    width: number,
    cols: number,
    rows: number,
    toDrawBoundaries?: boolean,
    minDuration?: number,
    maxDuration?: number,
    ease?: number[],
    initialAnimation?: GridAnimationType,
    animation?: GridAnimationType,
    exitAnimation?: GridAnimationType,
    delay?: number,
    className?: string,
    style?: CSSProperties,
    lineStyle?: CSSProperties,
    onAnimationComplete?: () => void,
};

export default function Grid(props: GridProps) {
    const {
        height: h,
        width: w,
        cols,
        rows,
        toDrawBoundaries = false,
        minDuration: _minDuration,
        maxDuration = DEFAULT_DURATION_S,
        ease = DEFAULT_EASE,
        initialAnimation = 'hidden',
        animation = 'in',
        exitAnimation = undefined,
        delay = 0,
        className = '',
        style = {},
        lineStyle = {
            strokeWidth: '1px',
        },
        onAnimationComplete,
    } = props;
    const minDuration = _minDuration || maxDuration / 2;
    const height = h < 0 ? 0 : h;
    const width = w < 0 ? 0 : w;

    const lineProps = useMemo(() => {
        const spacingX = width / cols;
        const spacingY = height / rows;
        const floatingDuration = maxDuration - minDuration;
        const data: {
            x1: number,
            x2: number,
            y1: number,
            y2: number,
            variants: Variants,
            custom: {
                minDuration: number,
                floatingDuration: number,
                ease: number[],
                delay: number,
                length: number,
                index: number,
            }
        }[] = [];

        const startIndex = toDrawBoundaries ? 0 : 1;
        const lastColIndex = toDrawBoundaries ? cols : (cols - 1);
        const lastRowIndex = toDrawBoundaries ? rows : (rows - 1);

        for (let i = startIndex; i <= lastColIndex; i++) {
            const position = i * spacingX;
            data.push({
                x1: position,
                x2: position,
                y1: 0,
                y2: 0,
                variants: variantsY,
                custom: {
                    minDuration,
                    floatingDuration,
                    ease,
                    delay,
                    length: height,
                    index: i
                },
            });
        }

        for (let i = startIndex; i <= lastRowIndex; i++) {
            const position = i * spacingY;
            data.push({
                x1: 0,
                x2: 0,
                y1: position,
                y2: position,
                variants: variantsX,
                custom: {
                    minDuration,
                    floatingDuration,
                    ease,
                    delay,
                    length: width,
                    index: i
                },
            });
        }

        return data;
    }, [width, height, cols, rows, minDuration, maxDuration]);

    return (
        <div
            className={classNames('grid', className)}
            style={{
                height: `${height}px`,
                width: `${width}px`,
            }}
        >
            <motion.svg
                width={width}
                height={height}
                viewBox={`0 0 ${width} ${height}`}
                style={style}
                initial={initialAnimation}
                animate={animation}
                exit={exitAnimation}
                onAnimationComplete={onAnimationComplete}
            >
                {lineProps.map((props, index) => (
                    <motion.line
                        key={index}
                        style={lineStyle}
                        {...props}
                    />
                ))}
            </motion.svg>
        </div>
    );
}
