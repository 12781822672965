export const DEFAULT_EASE = [0.4, 0, 0, 1];
export const DEFAULT_DURATION = 1;
export const DEFAULT_STAGGER = 0.1;

export const DEFAULT_TRANSITION = {
	duration: DEFAULT_DURATION,
	ease: DEFAULT_EASE,
};

export const HUB_CODIFICATION_PROPS = {
	characterSwitchAmount: 4,
	characterNextTrigger: 2,
	characterTimeout: 20,
	start: true,
}
