import {app} from "./snkrs_cal";
import {loadJSON} from "./tsunami/load/loadJSON";
import {Drops} from "./snkrs_cal/model/Drop";
import {Stories} from "./snkrs_cal/model/Story";
import dayjs from "dayjs";

const proxyURL = "https://nike-proxy-api.staging.enjoytheweather.com/";
const apiURLInStock = (marketplace, language) => `https://api.nike.com/product_feed/threads/v2/?anchor=0&count=48&filter=marketplace(${marketplace})&filter=language(${language})&filter=inStock(true)&filter=productInfo.merchPrice.discounted(false)&filter=channelId(010794e5-35fe-4e32-aaff-cd2c74f89d61)&filter=exclusiveAccess(true,false)&fields=active,id,lastFetchTime,productInfo,publishedContent.nodes,publishedContent.subType,publishedContent.properties.coverCard,publishedContent.properties.productCard,publishedContent.properties.products,publishedContent.properties.publish.collections,publishedContent.properties.relatedThreads,publishedContent.properties.seo,publishedContent.properties.threadType,publishedContent.properties.custom,publishedContent.properties.title`;
const apiURLUpcoming = (marketplace, language) => `https://api.nike.com/product_feed/threads/v2/?anchor=0&count=48&filter=marketplace(${marketplace})&filter=language(${language})&filter=upcoming(true)&filter=channelId(010794e5-35fe-4e32-aaff-cd2c74f89d61)&filter=exclusiveAccess(true,false)&sort=effectiveStartSellDateAsc&fields=active,id,lastFetchTime,productInfo,publishedContent.nodes,publishedContent.subType,publishedContent.properties.coverCard,publishedContent.properties.productCard,publishedContent.properties.products,publishedContent.properties.publish.collections,publishedContent.properties.relatedThreads,publishedContent.properties.seo,publishedContent.properties.threadType,publishedContent.properties.custom,publishedContent.properties.title`;
// const apiURLFeed = `https://api.nike.com/product_feed/threads/v2/?anchor=0&count=48&filter=marketplace(${app.model.city.marketplace})&filter=language(${app.model.city.language})&filter=channelId(010794e5-35fe-4e32-aaff-cd2c74f89d61)&filter=exclusiveAccess(true,false)&fields=active,id,lastFetchTime,productInfo,publishedContent.nodes,publishedContent.subType,publishedContent.properties.coverCard,publishedContent.properties.productCard,publishedContent.properties.products,publishedContent.properties.publish.collections,publishedContent.properties.relatedThreads,publishedContent.properties.seo,publishedContent.properties.threadType,publishedContent.properties.custom,publishedContent.properties.title`;
const apiURLStories = (marketplace, language) => `https://api.nike.com/product_feed/threads/v2/?anchor=0&count=48&filter=marketplace(${marketplace})&filter=language(${language})&filter=publishedContent.subType(story_format)&filter=channelId(010794e5-35fe-4e32-aaff-cd2c74f89d61)`;

const dropsURL = (marketplace, language) => `${proxyURL}${encodeURIComponent(apiURLUpcoming(marketplace, language))}`;
const inStockURL = (marketplace, language) => `${proxyURL}${encodeURIComponent(apiURLInStock(marketplace, language))}`;
const storiesURL = (marketplace, language) => `${proxyURL}${encodeURIComponent(apiURLStories(marketplace, language))}`;

/**
 * Easy config based on the country code.
 *
 * @type {{CN: {marketplace: string, language: string}, FR: {marketplace: string, language: string}, US: {marketplace: string, language: string}}}
 */
export const SNKRS_CAL_CONFIG = {
  'FR': {
    marketplace: 'FR',
    language: 'fr',
  },
  'US': {
    marketplace: 'US',
    language: 'en',
    launchUrl: 'https://www.nike.com/launch/t/',
  },
  'IT': {
    marketplace: 'IT',
    language: 'it',
    launchUrl: 'https://www.nike.com/it/launch/t/',
  },
  'JP': {
    marketplace: 'JP',
    language: 'ja',
    launchUrl: 'https://www.nike.com/jp/launch/t/',
    includeInactive: true,
  },
  'CN': {
    marketplace: 'CN',
    language: 'zh-Hans',
  },
  'KR': {
    marketplace: 'KR',
    language: 'ko',
    launchUrl: 'https://www.nike.com/kr/en/launch',
  },
};

/**
 *
 * @param inStock
 * @param marketplace: "US" | "FR" | "CN"
 * @param language
 * @param onComplete
 * @returns {*}
 */
export function fetchDrops({inStock, marketplace, language, includeInactive, onComplete, filterTests = true, onlyJordan = false, onlyFootwear = true}) {
  return loadJSON(dropsURL(marketplace, language)).then((dropsJson) => {
    // console.log(dropsJson.objects);
    let inStockPromise = Promise.resolve();
    let drops = Drops.fromJSON(dropsJson.objects,undefined,  {includeInactive});
    let loadInStock = (drops.length < 1);
    let inStockDrops;
    let jordanDrops;
    let jordanInStockDrops;

    const filterOnlyFootwear = (drops) => onlyFootwear ? drops.filter(({isFootwear}) => isFootwear) : drops;

    if (inStock == "true") loadInStock = true;
    // Add this line to force inStockMode
    // loadInStock = true;
    if (loadInStock && !onlyJordan) {
      inStockPromise = loadJSON(inStockURL(marketplace, language)).then((inStockJson) => {
        // console.log(inStockJson.objects);
        drops = Drops.fromJSON(inStockJson.objects).filter(({title, subtitle, fulltitle}) => {
          if (!filterTests) {
            return true;
          }

          const fields = [title, subtitle, fulltitle];
          // checks if the word "test" is present of the content.
          return !fields.some(i => i?.toLowerCase()?.includes('test'));
        });
        drops = filterOnlyFootwear(drops);
        drops.forEach((drop, k) => {
          drop.inStock = true;
        })
        onComplete({
          drops,
          inStockMode: true,
        })
      });
    } else if (onlyJordan) {
      inStockPromise = loadJSON(inStockURL(marketplace, language)).then((inStockJson) => {
        // console.log(inStockJson.objects);
        inStockDrops = Drops.fromJSON(inStockJson.objects).filter(({title, subtitle, fulltitle}) => {
          if (!filterTests) {
            return true;
          }

          const fields = [title, subtitle, fulltitle];
          // checks if the word "test" is present of the content.
          return !fields.some(i => i?.toLowerCase()?.includes('test'));
        });
        jordanDrops = drops.filter(({brand}) => {
          return brand === "Jordan";
        });
        jordanInStockDrops = inStockDrops.filter(({brand}) => {
          return brand === "Jordan";
        });
        
        if (jordanDrops.length < 6) {
          jordanDrops.push(...jordanInStockDrops);
          drops = jordanDrops;
        } 
        else drops = jordanDrops;
        drops = filterOnlyFootwear(drops);
        drops.forEach((drop, k) => {
          drop.inStock = true;
        })
        onComplete({
          drops,
          inStockMode: true,
        })
      });
    } else {
      onComplete({
        drops: filterOnlyFootwear(drops),
        inStockMode: false,
      })
    }

    // return loadImages promise
    return inStockPromise.then(() => {
      return Drops.loadImages(drops);
    });
  });
};

/**
 *
 * @param inStock
 * @param marketplace: "US" | "FR" | "CN"
 * @param language
 * @param onComplete
 * @returns {*}
 */
 export function fetchStories({marketplace, language, onComplete, onlyJordan = true, include_videos = false, includes}) {
  return loadJSON(storiesURL(marketplace, language)).then((dropsJson) => {
    let inStockPromise = Promise.resolve();
    let drops = Stories.fromJSON(dropsJson.objects, includes);

    if (onlyJordan) drops = drops.filter(({isJordan}) => {return isJordan});
    if (!include_videos) drops = drops.filter((drop) => {
      return drop.images.find(image => image.subType === "image");
    });

    onComplete({
      drops,
      inStockMode: false,
    })

    // return loadImages promise
    return inStockPromise.then(() => {
      return Stories.loadImages(drops);
    });
  });
}

 export function splitByTimeframe(drops) {
   const endOfDay = dayjs().endOf('day');
   const startOfDay = dayjs().startOf('day');
   const split = {
     upcoming: [],
     today: [],
     past: []
   };

   drops.forEach((drop) => {
     console.log('startEntryDate', drop.startEntryDate);
     if (endOfDay.isBefore(dayjs(drop.startEntryDate))) {
       split.upcoming.push({...drop});
     } else if (startOfDay.isBefore(dayjs(drop.startEntryDate))) {
       split.today.push({...drop});
     } else {
       split.past.push({...drop});
     }
   });

   return split;
 }