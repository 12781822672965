import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import './ContentBlock.scss';
import { TRANSITIONS, DEFAULT_TRANSITION } from '../../constants';

const ContentBlock = ({ visible = false, text, width, height, top, left, animationTiming, inset = false, children, theme = 'light', transparent = false }) => {

    const { intro_delay, intro_duration, exit_duration } = animationTiming;

    const [dims, setDims] = useState({
        width: width ? width : '100%',
        height: height ? height : '100',
        left: left ? left : 0,
        top: top ? top : 0,
        initial: true,
    });

    const ANIMATION = TRANSITIONS[animationTiming.transition];
    const INTITIAL = { ...ANIMATION.initial }
    const ANIMATE = { ...ANIMATION.animate, transition: { ...DEFAULT_TRANSITION, duration: intro_duration, delay: intro_delay } }
    const EXIT = { ...ANIMATION.exit, transition: { ...DEFAULT_TRANSITION, duration: exit_duration, delay: 0 } }

    return <AnimatePresence>

        {visible &&
            <div className={`ContentBlock inset-${inset}`} style={{ ...dims, overflow: 'hidden', position: 'absolute' }}>

                <motion.div
                    style={{
                        ...INTITIAL,
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        position: 'absolute',
                        opacity: 1,
                        background: theme === 'dark' ? `rgba(0,0,0,${transparent ? 0 : 100})` : `rgba(255,255,255,${transparent ? 0 : 100})`
                    }}
                    intitial={INTITIAL}
                    animate={ANIMATE}
                    exit={EXIT}
                >

                    <div style={{ color: theme === 'dark' ? '#fff' : '#000' }}>
                        {children}
                    </div>

                </motion.div>

            </div>
        }
    </AnimatePresence>

};

export default ContentBlock;
