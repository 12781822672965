const _ = require('lodash');
import * as GridUtils from './utils';
import * as Logger from '../utils/console';

export const GRID_SNIPPETS = {
	INTRO: 'intro',
	FASTLANE_OVERLAY: 'fastlaneOverlay',
	STACK_ENTRY: 'stackEntry',
	BLUR_VERTICAL: 'blurVertical',
};

const SNIPPET_JSON = {
	[GRID_SNIPPETS.INTRO]: require('./snippets/intro.snippet.json'),
	[GRID_SNIPPETS.FASTLANE_OVERLAY]: require('./snippets/fastlane.overlay.json'),
	[GRID_SNIPPETS.STACK_ENTRY]: require('./snippets/stackentry.snippet.json'),
	[GRID_SNIPPETS.BLUR_VERTICAL]: require('./snippets/blurvertical.snippet.json'),
};

export class GridComposer {
	sequence = [];
	wait = [];

	constructor({ sequences, holdFrames, waitFrames, inserts }) {
		this.sequence = GridUtils.concatSequence(sequences);

		if (waitFrames) {
			this.addWait(waitFrames);
		}

		if (holdFrames) {
			this.addHold(holdFrames);
		}

		if (inserts) {
			inserts.map(insert => {
				this.insertSnippet(insert);
			});
		}

		Logger.alert('GRID COMPOSED', this.sequence);
	}

	waitAll = wait => {
		this.sequence = GridUtils.waitAll(this.sequence, 5000);
	};
	addWait = waitFrames => {
		this.wait = GridUtils.concatSequence(waitFrames);
	};

	addHold = holdFrames => {
		// Overrides addWait
		this.wait = GridUtils.mapHoldsToWaits(this.sequence, holdFrames);
	};

	assetRemap = (frame, remapAsset) => {
		let rFrame = _.cloneDeep(frame);

		if (rFrame.hasOwnProperty('mask')) {
			for (let m of rFrame.mask) {
				if (m.hasOwnProperty('asset')) {
					m.asset = remapAsset;
				}
				if (m.hasOwnProperty('name')) {
					m.name = remapAsset;
				}
			}
		}

		Logger.log('FRAME REMAP', rFrame);

		return rFrame;
	};

	insertSnippet = insert => {
		let { at, snippetId, continueLastFrameTo, remapAsset = null } = insert;

		let snippetSequence = SNIPPET_JSON[snippetId];
		Logger.log('INSERT SNIPPET', at, snippetId, snippetSequence);
		let insertIndex = 0;
		let startInsert = false;
		let continuation = false;

		this.sequence.map(frame => {
			const { name } = frame;

			if (name) {
				if (name.indexOf(at) > -1) {
					Logger.log('INSERT FOUND', at);
					startInsert = true;
				}

				if (startInsert && continueLastFrameTo) {
					continuation = true;
				}
			}

			Logger.log(
				'INSERT',
				snippetSequence[insertIndex],
				insertIndex,
				snippetSequence.length,
			);

			if (startInsert && insertIndex < snippetSequence.length) {
				Logger.log('INSERTING', startInsert, insertIndex);

				let insertFrame = snippetSequence[insertIndex];

				if (remapAsset) {
					insertFrame = this.assetRemap(insertFrame, remapAsset);
				}

				frame = GridUtils.mergeFrame(frame, insertFrame);

				Logger.highlight('INSERTED', frame);

				insertIndex++;
			}

			if (continuation) {
				frame = GridUtils.mergeFrame(
					frame,
					snippetSequence[insertIndex - 1],
				);
			}

			if (name && continuation) {
				if (name === continueLastFrameTo) {
					continuation = false;
					startInsert = false;
				}
			}
		});
	};
}
