import React from 'react';

export const Attract = ({ data }) => {

	return (
		<div className={'Attract'}>
            CITY REPLAY — ATTRACT
        </div>
	);
};
