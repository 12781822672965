import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import Codification from '../../../../components/Codification';
import { TEXT_STYLES, TEXT_SIZES } from '../../constants';
import './AppText.scss';

const AppText = ({ title, supertitle }) => {

    

    return <div className={`AppText`}>

        <Codification
            className={`TextBlock-codify`}
            text={title}
            typography={{
                primary: true,
                typeStyle: TEXT_STYLES[TEXT_SIZES.AppHeading],
                tag: 'span',
            }}
            characterSwitchAmount={3}
            characterNextTrigger={2}
            characterTimeout={30}
            start={true}
            delay={0}
            reverse={false}
        />

        <div className={'AppText-supertitle'}>
            {supertitle}
        </div>

    </div>

};

export default AppText;
