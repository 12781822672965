import cls from 'classnames';
import {AnimatePresence, motion} from 'framer-motion';
import React, {forwardRef, useEffect, useRef} from 'react';
import Codification from '../../../../components/Codification';
import {DEFAULT_DURATION, DEFAULT_TRANSITION, HUB_CODIFICATION_PROPS} from '../../../../constants/transition';
import {useHubContext} from '../../hooks/useHubContext';
import {useReadyCounter} from '../../hooks/useReadyCounter';
import { TRANSITION_WAIT } from '../../../../constants/hub';

export const LevelBlockTitle = forwardRef((
  {
    active,
    className,
    description,
    date,
    reverse,
    showDescription = false,
    start = true,
    title,
    titleSize,
    onReady
  },
  forwardedRef
) => {
  const {updateCounter} = useReadyCounter(
    showDescription && description ? 2 : 1,
    onReady
  );
  const tempEndTimer = useRef(null);

  const rootClassName = cls(className, 'c-level-block__title', {
    'c-level-block__title--active': showDescription,
  });

  useEffect(() => {
    // if (showDescription && description) {
    //   return;
    // }

    if (start === false) {
      return;
    }

    setTimeout(() => {
      updateCounter();
    }, DEFAULT_DURATION / 1.5 * 1000)
  }, [start]);

  useEffect(() => {
    if (reverse) {
      tempEndTimer.current = setTimeout(() => {
        updateCounter();
      }, 500);
    } else {
      tempEndTimer.current = setTimeout(() => {
        updateCounter();
      }, TRANSITION_WAIT);
    }
  }, [reverse]);

  return (
    <motion.div
      ref={forwardedRef}
      className={rootClassName}
      initial={!showDescription}
      animate={showDescription ? {
        opacity: active ? 1 : 0
      } : {
        opacity: active ? 0 : 1,
      }}
      transition={{
        ...DEFAULT_TRANSITION,
        delay: showDescription
          ? active ? 0.2 : 0
          : active ? 0 : 0
      }}
    >
      {
        date && (
          <div className={'c-date'} style={{
            position: 'absolute',
            right: showDescription ? '0px' : '2.6vw',
          }}>
            <Codification
              {...HUB_CODIFICATION_PROPS}
              tag="h2"
              text={date}
              typography={{size: titleSize, tag: "span"}}
              reverse={reverse}
              setLanguageClassName
            />
          </div>
        )
      }
      <Codification
        {...HUB_CODIFICATION_PROPS}
        tag="h2"
        text={title}
        typography={{size: titleSize, tag: "span"}}
        reverse={reverse}
        setLanguageClassName
      />
      {showDescription && description && (
        <div className="c-level-block__description">
          <Codification
            {...HUB_CODIFICATION_PROPS}
            characterTimeout={.8}
            tag="h2"
            text={description}
            typography={{size: "lg", tag: "div"}}
            start={start}
            reverse={reverse}
            delay={DEFAULT_DURATION / 1.5 * 1000}
            setLanguageClassName
          />
        </div>
      )}
    </motion.div>
  );
});
