import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import { Text } from 'troika-three-text';
import jumpman from './jordan.svg';
import swoosh from './swoosh.svg';
import jordancondensed from 'url:../../../../jordan/assets/fonts/jordan/JordanNHGDisp-75Bold.ttf';
import futura from 'url:../../../../core/assets/fonts/COMP_FUTURA.otf';
import noto from 'url:../../../../hoi/animations/ticker/static/res/fonts/NotoSansSC-Medium.otf';
import { Content, Size, SvgSizeResult, TickerData } from './type';
import * as THREE from 'three';
import { MediaType } from '../../../../jordan/shared';

export const fonts = {
  'jordan-condensed': jordancondensed,
  futura: futura,
  noto: noto,
};

export const svgs = { jumpman, swoosh };

export const isText = (data: string) => {
  return typeof data === 'string';
};

export const getNumber = (
  text: string | undefined | number,
  defaultNumber: number = 0,
) => Number(text?.toString().replace(/[^0-9.]/g, '')) || defaultNumber;

export const calcTicker = async (data: TickerData) => {
  const topMargin = getNumber(data.margin?.top);
  const bottomMargin = getNumber(data.margin?.bottom);
  const totalMargin = topMargin + bottomMargin;
  const lineHeight = getNumber(data.height, 1);
  const letterSpacing = data.letterspacing;
  const fontSize = data.height - totalMargin;
  const tickerContent: { [key: string]: Content[] } = {};
  let fontsMap: { [key: string]: string } = {};
  const hasMultiLang =
    data.content.filter(
      c =>
        c.type === 'text' &&
        typeof c.content === 'object' &&
        Object.keys(c.content).length > 1,
    ).length > 0;

  if (typeof data.font === 'string') {
    fontsMap = {
      EN: data.font
    };
    if (hasMultiLang) {
      fontsMap = { ...fontsMap, ...{ CN: data.font } };
    }
  } else {
    if (!hasMultiLang) {
      fontsMap = {
        EN: Object.values(data.font)?.[0],
      };
    } else {
      fontsMap = { ...(data.font as { [key: string]: string }) };
    }
  }
  // for each font
  for await (const font of Object.entries(fontsMap)) {
    tickerContent[font[0]] = [];
    for await (const content of data.content) {
      if (content.type === 'icon') {
        const svgResult = await getSVGSize(svgs[content.content.toString()]);
        const scaleRatio = fontSize / svgResult.size.height;
        tickerContent[font[0]].push({
          type: content.type,
          width: svgResult.size.width * scaleRatio,
          size: svgResult.size,
          scale: scaleRatio,
          content: content.content.toString(),
        });
      } else if (content.type === 'text') {
        let text;
        if (typeof content.content === 'string') {
          text = content.content.toLocaleUpperCase();
        } else {
          text = content.content[font[0]].toLocaleUpperCase();
        }

        //get font without scale
        let visibleBounds = await calcTextBound(
          text,
          fontSize,
          lineHeight,
          letterSpacing,
          fonts[font[1]],
        );
        let textHeight = visibleBounds[3] - visibleBounds[1];
        let fontSizeScale = 1 + (fontSize - textHeight) / fontSize;
        let newFontSize = fontSize * fontSizeScale;
        visibleBounds = await calcTextBound(
          text,
          newFontSize,
          lineHeight,
          letterSpacing,
          fonts[font[1]],
        );
        const textWidth = visibleBounds[2] - visibleBounds[0];
        const fontYOffset = Math.abs(visibleBounds[3] + visibleBounds[1]) / 2;

        tickerContent[font[0]].push({
          type: content.type,
          width: textWidth * data.squash,
          content: text,
          newFontSize,
          fontYOffset,
          font: font[1],
        });
      } else if (content.type === 'image') {
        const media = content.content as MediaType;
        const size = await getImageSize(media.url);
        const scaleRatio = fontSize / size.height;
        tickerContent[font[0]].push({
          type: content.type,
          width: size.width * scaleRatio,
          size,
          content: content.content,
          preserve_colors: content.preserve_colors,
        });
      } else {
        Promise.resolve();
      }
    }
  }
  return tickerContent;
};

export const calcTextBound = (
  text: string,
  fontSize: number,
  lineHeight: number,
  letterSpacing: number,
  font: any,
) =>
  new Promise<number[]>((resolve, _reject) => {
    const myText = new Text();
    myText.anchorX = 'left';
    myText.anchorY = 'middle';
    myText.lineHeight = lineHeight;
    myText.letterSpacing = letterSpacing;
    myText.font = font;
    myText.fontSize = fontSize;
    myText.text = text;
    myText.sync(() => {
      resolve(myText.textRenderInfo.visibleBounds);
    });
  });

export const getSVGSize = (url: string) =>
  new Promise<SvgSizeResult>((resolve, _reject) => {
    const loader = new SVGLoader();
    loader.load(url, result => {
      const rootNode = (result.xml as unknown) as SVGElement;
      resolve({
        size: {
          width: getNumber(rootNode.getAttribute('width') || 0),
          height: getNumber(rootNode.getAttribute('height') || 0),
        },
        svg: result,
      });
    });
  });

export const getImageSize = (url: string) =>
  new Promise<Size>((resolve, _reject) => {
    const loader = new THREE.ImageLoader();
    loader.load(url, image => {
      resolve({
        width: image.width,
        height: image.height,
      });
    });
  });
