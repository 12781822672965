import React, { CSSProperties, useEffect, useState } from 'react';
import { motion, AnimationProps, AnimatePresence, useIsPresent } from 'framer-motion';

import { getLangText, MultiLangText, secToMs, setAnimationTimeout } from '../shared';
import DirectionArrow from './DirectionArrow';
import CodificationWithTranslations from './Codification/CodificationWithTranslations';

import '../styles/components/floor.scss';


export type FloorDirection = 'up' | 'down' | 'current';

export type FloorProps = {
    children: any;
    level: string,
    title: MultiLangText,
    languages: string[],
    delay: number,
    description: MultiLangText,
    languageTransitionDelay?: number,
    languageDisplayDuration: number,
    runFinalTextExitAnimation?: boolean,
    floorGridTemplateColumns: string[],
    gridLineThickness: number,
    direction?: FloorDirection,
    className?: string,
    style?: CSSProperties,
    backgroundAnimationProps?: AnimationProps,
}

export default function FloorComponent(props: FloorProps) {
    const {
        languages,
        languageTransitionDelay = 0,
        direction = 'down',
        runFinalTextExitAnimation = true,
    } = props;
    const [langIndex, setLangIndex] = useState(0);
    const isPresent = useIsPresent();
    const [toShowDescriptionComponent, setToShowDescriptionComponent] = useState(false);

    const text: MultiLangText = {};
    languages.forEach(lang => {
        const title = getLangText(props.title, lang) ;
        const descr = getLangText(props.description, lang);

        text[lang] = `${title}\n${descr}`;
    });

    const descriptionComponent = (
        <CodificationWithTranslations
            text={text}
            languages={languages}
            langIndex={langIndex}
            languageTransitionDelay={languageTransitionDelay}
            runFinalTextAnimation={runFinalTextExitAnimation}
            codificationProps={{
                characterNextTrigger: 2,
                characterSwitchAmount: 3,
            }}
        />
    );

    useEffect(() => {
        if (!toShowDescriptionComponent) {
            return;
        }

        const cancelTimeout = setAnimationTimeout(() => {
            if (langIndex >= languages.length - 1) {
                return;
            }

            setLangIndex(langIndex + 1);
        }, secToMs(props.languageDisplayDuration));

        return () => {
            cancelTimeout();
        };
    }, [toShowDescriptionComponent, langIndex]);

    useEffect(() => {
        const cancelTimeout = setAnimationTimeout(() => {
            setToShowDescriptionComponent(true);
        }, secToMs(props.delay));

        return () => {
            cancelTimeout();
        };
    }, []);

    return (
        <motion.div
            key={props.level}
            className={props.className}
            style={props.style}
            {...props.animationProps}
            {...props.motionAdvancedProps}
        >
            <motion.div
                className='floor-description floor-description-background'
                {...props.backgroundAnimationProps}
            ></motion.div>
            <div
                className='floor-description floor-description-text'
                style={{
                    gridColumnEnd: `span ${props.floorGridTemplateColumns.length - 2}`,
                    margin: props.gridLineThickness / 2,
                    borderWidth: props.gridLineThickness,
                    transform: `translateX(${props.gridLineThickness}px)`,
                }}
            >
                <div className='floor-description-lines'>
                    <AnimatePresence>
                        {isPresent && toShowDescriptionComponent ? descriptionComponent : null}
                    </AnimatePresence>
                </div>
            </div>
            <div className='floor-direction'>
                {direction !== 'current' ? (
                    <DirectionArrow
                        sideLength={50}
                        strokeWidth={7}
                        animate='show'
                        duration={1.2}
                        delay={props.delay}
                        exitDelay={0.3}
                        rotation={getDirectionArrowRotation(direction)}
                    />
                ) : null}
            </div>
            <div className='floor-number'>{props.children}</div>
        </motion.div>
    );
}

function getDirectionArrowRotation(direction: FloorDirection) {
    switch (direction) {
        case 'up':
            return 180;

        case 'down':
        default:
            return 0;
    }
}
