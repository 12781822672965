import React, {useMemo} from 'react';
import Transition from "../components/Transition";
import {MediaType, ThemeType} from "../shared";
import Background from "../components/Background";
import Media from "../components/Media";
import Typography from "../components/Typography";
import {getColor} from "../styles/color";
import QrSection, {QrCodeInterface} from "../components/QrSection";

interface UgcCardProps {
    title: string;
    subtitle: string;
    media: Array<MediaType>;
    theme: ThemeType;
    variant: 'default' | 'large'
    qr_code?: QrCodeInterface;
}

export default function UgcCard({
                                    title = '',
                                    subtitle = '',
                                    media = [],
                                    qr_code,
                                    theme = 'dark',
                                    variant = 'default',
                                    ...other
                                }: UgcCardProps) {
    const height = useMemo(() => variant === 'default' ? 934 : 1207, [variant]);
    const color = useMemo(() => theme === 'dark' ? 'white' : 'black', [theme])

    return (
        <Transition variant={'wipe-x'} cover>
            <Background
                variant={theme === 'dark' ? 'dark-dust' : 'light-dust'}
            >
                <div style={{}}>
                    <Media
                        media={media}
                        width={1080}
                        height={height}
                        theme={theme} delay={1_000}
                    />
                    <div style={{marginTop: '36px', marginLeft: '36px'}}>
                        <Typography
                            variant={'jordan-condensed'}
                            size={400}
                            color={color}>
                            #INMYJS
                        </Typography>
                        <div>
                            <Typography variant={'pp-ultralight-italic'} size={52} color={color}>
                                {title}
                            </Typography>
                            <Typography variant={'pp-ultralight'} size={52} color={color}>
                                {subtitle}
                            </Typography>
                        </div>
                    </div>
                    <div style={{position: 'absolute', bottom: '40px', left: '40px', color: getColor(color)}}>
                        <QrSection qrCode={qr_code} theme={theme} />
                    </div>
                </div>
            </Background>
        </Transition>
    )
}