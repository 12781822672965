import React, { useEffect, useState } from 'react';
import './RiseHorizontalFS.scss';
import { NIKE_EASE } from '../../../../constants/nikerise';
import _ from 'lodash';
import MediaBlock, { BLOCK_TRANSITIONS } from '../../components/MediaBlock/MediaBlock.js';
import LottiePlayer, { LottieModes } from '../../components/LottiePlayer/LottiePlayer.js';
import ColorBlock, { COLOR_TRANSITIONS } from '../../components/ColorBlock/ColorBlock.js';
import TextBlock, { TEXT_SIZES } from '../../components/TextBlock/TextBlock.js';

const DEFAULT_TRANSITION = { ease: NIKE_EASE, duration: 2 }

const TIMINGS = {
    mediaDuration: 8,
}

const KEYFRAMES = {
    Intro: 0,
    Text: 1,
    Media1: 2,
    Blocks: 3,
}


const RiseHorizontalFS = ({ headline, body, media, width, height, top, left, grid, onEvent }) => {

    const [activeMedia, setActiveMedia] = useState(null);
    const [mediaIndex, setMediaIndex] = useState(0);
    const [keyframe, setKeyframe] = useState("intro");

    const [dims, setDims] = useState({
        width: width ? width : '100%',
        height: height ? height : '100%',
        left: left ? left : 0,
        top: top ? top : 0,
        initial: true,
    });


    const media0 = _.get(media, '[0]');
    const media1 = _.get(media, '[1]');

    useEffect(() => {
        setActiveMedia(media0);
        setTimeout(() => { advanceToNextKeyframe(KEYFRAMES.Text) }, 1000);
        setTimeout(() => { advanceToNextKeyframe(KEYFRAMES.Media1) }, 3000);
        setTimeout(() => { advanceToNextKeyframe(KEYFRAMES.Blocks) }, 3000);
    }, []);

    const advanceToNextKeyframe = (k) => {
        switch (k) {
            case KEYFRAMES.Text:
                setKeyframe(KEYFRAMES.Text);
                break
            case KEYFRAMES.Media1:
                setKeyframe(KEYFRAMES.Media1);
                if (media1) {
                    setActiveMedia(media1)
                    setMediaIndex(1);
                }
                break;
            default:
                setKeyframe(k);
                break
        }
    }

    return <div className="RiseHorizontalFS" style={{ ...dims, overflow: 'hidden', position: 'absolute' }}>

        {grid && <LottiePlayer grid={grid} onEvent={onEvent} mode={LottieModes.Chapter} />}

        {

            activeMedia && !headline && <MediaBlock
                media={activeMedia}
                key={`media${mediaIndex}`}
                left={"0"}
                width={"100%"}
                height={"100%"}
                duration={TIMINGS.mediaDuration}
                entry={mediaIndex === 0 ? BLOCK_TRANSITIONS.Top : BLOCK_TRANSITIONS.Fade}
                delay={0} />

        }

        {

            activeMedia && headline && <MediaBlock
                media={media0}
                key={`media0`}
                left={"0"}
                width={"100%"}
                height={"100%"}
                duration={TIMINGS.mediaDuration - 3}
                steps={{ left: '50%', top: '5.6%', height: '88.8%', width: '50%' }}
                exit={BLOCK_TRANSITIONS.Right}
                delay={0} />

        }

        {
            // keyframe === "midpoint" && !headline && <React.Fragment></React.Fragment>
            keyframe >= KEYFRAMES.Blocks && <React.Fragment>


                {
                    !headline && <ColorBlock
                        key={`color-1-${keyframe}`}
                        top={"5.6%"}
                        left={"-100%"}
                        width={"50%"}
                        height={"44.4%"}
                        duration={TIMINGS.mediaDuration / 3}
                        delay={0}
                        direction={COLOR_TRANSITIONS.LeftToRight}
                    />
                }

                < ColorBlock
                    key={`color-2-${keyframe}`}
                    top={"50%"}
                    left={"100%"}
                    width={"50%"}
                    height={"44.4%"}
                    duration={TIMINGS.mediaDuration / 3}
                    delay={TIMINGS.mediaDuration / 4}
                    direction={COLOR_TRANSITIONS.RightToLeft}
                />


            </React.Fragment>
        }


        {
            keyframe >= KEYFRAMES.Text && headline &&
            <TextBlock
                text={headline}
                // left={"50.4%"}
                left={'5.6%'}
                bottom={"50%"}
                width={"50%"}
                rotate={false}
                textMode={'dark'}
                delay={2}
                duration={4}
                type={TEXT_SIZES.Heading}
            />

        }
    </div>

};

export default RiseHorizontalFS;
