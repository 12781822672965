import * as React from 'react';
import { useInView } from 'react-intersection-observer';
import { usePrevious } from '../usePrevious';

const Text = ({
	content,
	language,
	elementSize,
	height,
	showEffect,
	effectTypes,
	effectStaggerTime = 0,
	elementIndex,
	solarizationInset,
	lineHeight,
}) => {
	const [internalShowEffect, setInternalShowEffect] = React.useState(
		showEffect,
	);
	const [startCodification, setStartCodification] = React.useState(
		showEffect,
	);

	const { ref, inView } = useInView({
		threshold: 0,
	});
	const previousShowEffect = usePrevious(showEffect);

	const delay = React.useMemo(() => effectStaggerTime * elementIndex, [
		effectStaggerTime,
		elementIndex,
	]);

	React.useEffect(() => {
		if (previousShowEffect && !showEffect) {
			if (delay) {
				const timer = window.setTimeout(
					() => setInternalShowEffect(showEffect),
					delay * 1000,
				);
				return () => window.clearTimeout(timer);
			}
		}
		setInternalShowEffect(showEffect);
	}, [previousShowEffect, showEffect, delay, setInternalShowEffect]);

	const solarization = React.useMemo(
		() => internalShowEffect && effectTypes.includes('solarization'),
		[effectTypes, internalShowEffect],
	);
	const codification = React.useMemo(
		() => internalShowEffect && effectTypes.includes('codification'),
		[effectTypes, internalShowEffect],
	);

	React.useEffect(() => {
		// uncommenting this should enable the codification but it causes
		// the text to shift for some reason. Need to look into it more

		// if (delay && codification) {
		// 	const timer = window.setTimeout(
		// 		() => setStartCodification(codification),
		// 		delay * 1000,
		// 	);
		// 	return () => window.clearTimeout(timer);
		// }
		setStartCodification(codification);
	}, [codification, delay, setStartCodification]);

	return (
		<div
			style={{
				padding: `${height * solarizationInset}px 0`,
			}}
		>
			<div className="text" ref={ref}>
				<h2
					style={{

						fontSize: `${language === 'EN' ? elementSize : elementSize * .8}px`,
						// fontSize: `${elementSize}px`,
						lineHeight: `${lineHeight}px`,
						fontFamily: 'var(--font-f-primary)',
					}}
				>
					{content[language]}
				</h2>
				{/*<TickerText*/}
				{/*	text={text[language]}*/}
				{/*	elementSize={elementSize}*/}
				{/*	height={height}*/}
				{/*	start={inView && startCodification}*/}
				{/*	isStatic={!startCodification}*/}
				{/*/>*/}
				{solarization && (
					<span
						className="solarization"
						style={{
							// @ts-ignore
							'--delay': `${delay}s`,
						}}
					>
						<h2
							style={{
								// fontSize: `${elementSize}px`,
								fontSize: `${language === 'EN' ? elementSize : elementSize * .8}px`,
								lineHeight: `${lineHeight}px`,
								fontFamily: 'var(--font-f-primary)',
							}}
						>
							{content[language]}
						</h2>
						{/*<TickerText*/}
						{/*	text={text[language]}*/}
						{/*	elementSize={elementSize}*/}
						{/*	height={height}*/}
						{/*	start={inView && startCodification}*/}
						{/*	isStatic={!startCodification}*/}
						{/*/>*/}
					</span>
				)}
			</div>
		</div>
	);
};

export default Text;
