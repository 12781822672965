import {CSSProperties, ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import Typography from "../../jordan/components/Typography";
import {useEcosystemBoardStore} from "../animations/ecosystem-board/store";
import {Chapter, useCity} from "../shared";
import {compress} from "lzutf8";
import {get} from "lodash";
import {useWeatherSingleLine} from "../../../utils/weather";
import {MediaType} from "../../jordan/shared";

export default function QrCode({
                                   url = 'https://nike-x-apps-t3g4h5p.netlify.app/takeaway/ecosystem',
                                   size = 150,
                                   icon,
                                   hideSwoosh = false,
                                   style = {},
                                   cta,
                                   ...props
                               }: {
    url?: string;
    cta?: string;
    size?: number;
    icon?: ReactNode;
    style?: CSSProperties;
    [x: string]: unknown;
}) {
    const activeChapter = useEcosystemBoardStore(s => s.chapterIndex);
    const chapters = useEcosystemBoardStore(s => s.chapters);
    const [qrCode, setQrCode] = useState<string | null>(null);
    const {weather, loading} = useWeatherSingleLine();
    const city = useCity();
    const multiplier = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const size = urlParams.get('qr-size');

        if (!size) {
            return 1
        }

        return Number(size) ?? 1;
    }, []);

    useEffect(() => {
        if (!chapters?.length || !weather) {
            return;
        }
        const ch = chapters.filter(i => i.template === 'raw_event').slice(0, 6).map((c: Chapter) => ({
            pillar: c.pillar,
            event_type: c.event_type,
            // limit to 20 characters
            title: c.title?.slice(0, 100) ?? '',
            subtitle: c.subtitle?.slice(0, 100) ?? '',
            cta: c.cta,
            media: c.media?.[0]?.url ?? '',
        }));

        const index = chapters?.[activeChapter]?.pre_index ?? 0;
        console.log('index', index);

        const base64 = compress(JSON.stringify({chapters: ch, weather, city, index}), {
            outputEncoding: "Base64",
        });

        const takeAwayUrl = get(window, 'animation_store.json_meta.take_away_url')

        const fullUrl = `${takeAwayUrl}?state=${encodeURIComponent(base64)}`;

        setQrCode(fullUrl);

    }, [weather, chapters, city, activeChapter]);

    return (
        <div style={{
            position: 'absolute',
            bottom: '50px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column',
            right: '50px',
            zIndex: 99,
            textAlign: 'right'
        }}>
            <Typography color={'currentColor'} size={36}
                        style={{fontWeight: 'bold', marginBottom: 35, marginLeft: 2, textAlign: 'right'}}
                        variant={'brut'}>
                {cta || 'move with us'}
            </Typography>
            <div style={{display: 'flex', justifyContent: 'flex-end', gap: !hideSwoosh ? 20 : 0}}>
                {icon}
                {/*{*/}
                {/*    !hideSwoosh && (*/}
                {/*        <img*/}
                {/*            src={new URL('../demo/ecosystem-board/assets/logo_1.png', import.meta.url).toString()}*/}
                {/*            style={{*/}
                {/*                borderRadius: '15%',*/}
                {/*                width: size * multiplier,*/}
                {/*                height: size * multiplier,*/}
                {/*                ...style*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    )*/}
                {/*}*/}

                {
                    !loading && qrCode && (
                        <QrImage url={qrCode} size={size} multiplier={multiplier}/>
                    )
                }

            </div>
        </div>
    );
}

function QrImage({url, size, style, multiplier, ...props}: {
    url: string;
    size: number;
    style?: CSSProperties,
    multiplier: number
}) {
    const qrCode = useMemo(() => {
        // return `https://nikeqr.io?url=${encodeURIComponent(url)}`;
        return `https://api.nikesportpulse.com/api/public/qr?url=${encodeURIComponent(url)}`;
    }, [url]);

    return (
        <div style={{
            position: 'relative',
            width: size * multiplier,
            height: size * multiplier,
            borderRadius: '15%',
            overflow: 'hidden',
        }}>
            <img
                src={qrCode}
                style={{
                    width: '100%',
                    height: '100%',
                    ...style
                }}
                {...props}
            />

            {/*<svg*/}
            {/*    viewBox="0 0 60 60"*/}
            {/*    fill="none"*/}
            {/*    xmlns="http://www.w3.org/2000/svg"*/}
            {/*    style={{*/}
            {/*        width: '100%',*/}
            {/*        height: '100%',*/}
            {/*        position: 'absolute',*/}
            {/*        top: 0,*/}
            {/*        left: 0,*/}
            {/*        zIndex: 1,*/}
            {/*        objectFit: 'fill',*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <rect width="17" height="17" fill="white"/>*/}
            {/*    <rect x="42" width="17" height="17" fill="white"/>*/}
            {/*    <rect y="42" width="17" height="17" fill="white"/>*/}
            {/*    <circle cx="8.99994" cy="8.99994" r="3.73333" fill="black"/>*/}
            {/*    <circle cx="8.99994" cy="49.9999" r="3.73333" fill="black"/>*/}
            {/*    <circle cx="50.9999" cy="8.99994" r="3.73333" fill="black"/>*/}
            {/*    <circle cx="9" cy="9" r="6.93333" stroke="black" strokeWidth="2.13333"/>*/}
            {/*    <circle cx="9" cy="50" r="6.93333" stroke="black" strokeWidth="2.13333"/>*/}
            {/*    <circle cx="51" cy="9" r="6.93333" stroke="black" strokeWidth="2.13333"/>*/}
            {/*</svg>*/}

            <svg
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    objectFit: 'fill',
                }}
                width="1000" height="1000" viewBox="0 0 1000 1000" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="52.6709" y="52.4082" width="275" height="275" fill="white"/>
                <ellipse cx="190.335" cy="191.999" rx="57.0677" ry="57.8667" fill="black"/>
                <path
                    d="M296.09 192C296.09 251.57 248.526 299.467 190.336 299.467C132.145 299.467 84.5809 251.57 84.5809 192C84.5809 132.43 132.145 84.5333 190.336 84.5333C248.526 84.5333 296.09 132.43 296.09 192Z"
                    stroke="black" strokeWidth="33.0667"/>
                <rect x="52.2683" y="672" width="275" height="275" fill="white"/>
                <ellipse cx="190.334" cy="811.999" rx="57.0677" ry="57.8667" fill="black"/>
                <path
                    d="M296.09 812C296.09 871.57 248.526 919.467 190.335 919.467C132.145 919.467 84.5807 871.57 84.5807 812C84.5807 752.43 132.145 704.533 190.335 704.533C248.526 704.533 296.09 752.43 296.09 812Z"
                    stroke="black" strokeWidth="33.0667"/>
                <rect x="675.68" y="56" width="275" height="275" fill="white"/>
                <ellipse cx="809.802" cy="191.999" rx="57.0677" ry="57.8667" fill="black"/>
                <path
                    d="M915.557 192C915.557 251.57 867.994 299.467 809.803 299.467C751.612 299.467 704.048 251.57 704.048 192C704.048 132.43 751.612 84.5333 809.803 84.5333C867.994 84.5333 915.557 132.43 915.557 192Z"
                    stroke="black" strokeWidth="33.0667"/>
            </svg>


        </div>

    )
}