import {useMembership} from "../store";
import {MEMBERSHIP_COLOR, useRpx, useTranslatable} from "../shared";
import Collapsable from "./collapsable";
import Typography from "./typography";
import Line from "./line";
import {MultiLangText} from "../../../../house-of-innovation/shared";
import {motion} from "framer-motion";

export default function Header() {
    const rpx = useRpx();
    const isTitleSectionActive = useMembership(state => state.isTitleSectionActive());

    return (
        <motion.div
            style={{
                flex: 1,
                flexGrow: isTitleSectionActive ? 1 : 0 ,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                maxHeight: '50%'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    gap: rpx(20),
                }}
            >
                <Logo/>
                <Content/>
            </div>
        </motion.div>
    )
}

function Content() {
    const {t} = useTranslatable();
    const rpx = useRpx();
    const animationData = useMembership(state => state.animationData);
    const isTitleSectionActive = useMembership(state => state.isTitleSectionActive());

    if (!animationData) {
        return null;
    }

    return (
        <div
            style={{
                marginBottom: rpx(50),
            }}
        >
            <Typography
                size={60}
                font={'palatino'}
            >
                {t(animationData.title)}
            </Typography>

            <Collapsable show={isTitleSectionActive}>
                <Typography
                    font={'helvetica-neue'}
                    size={30}
                    style={{
                        marginBottom: rpx(40),
                        marginTop: rpx(10),
                        maxWidth: '80%',
                        lineHeight: '120%',
                    }}
                >
                    {t(animationData.description)}
                </Typography>

            </Collapsable>
        </div>
    );
}

function Logo() {
    const rpx = useRpx();

    return (
        <div
            style={{
                color: MEMBERSHIP_COLOR,
                width: rpx(170)
            }}
        >
            <svg viewBox="0 0 90 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M24.671 28.196C17.641 31.1607 6.60893 34.5747 2.00723 27.1927C-0.555939 23.1049 1.0629 16.7262 4.85519 10.9014C7.25347 7.18799 10.3563 3.77401 13.444 0.375C11.7353 3.16009 6.87874 12.4737 13.2192 17.1305C15.6924 18.9123 20.0393 19.092 25.4355 17.6995L89.9493 0.659499L24.671 28.196Z"
                    fill="currentColor"/>
            </svg>
        </div>
    )
}