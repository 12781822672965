import {useRpx} from "../shared";

export default function Bottom() {
    const rpx = useRpx();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={rpx(480)}
            height={rpx(421)}
            fill="none"
            viewBox="0 0 480 421"
        >
            <g stroke="#222" clipPath="url(#clip0_1439_886)">
                <path d="M.5-29C.5-277.252 201.748-478.5 450-478.5h329.5v899H450C201.748 420.5.5 219.252.5-29z"></path>
                <path
                    d="M120-29c0-181.978 147.522-329.5 329.5-329.5H779v659H449.5C267.522 300.5 120 152.978 120-29z"></path>
                <path
                    d="M240-29c0-115.704 93.796-209.5 209.5-209.5H779v419H449.5C333.796 180.5 240 86.704 240-29z"></path>
            </g>
            <defs>
                <clipPath id="clip0_1439_886">
                    <path
                        fill="#fff"
                        d="M0 0H480V421H0z"
                        transform="matrix(1 0 0 -1 0 421)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    )
}