import {ThemeType} from "../shared";
import {getColor} from '../styles/color';

interface LeftBarProps {
    theme: ThemeType
}

export default function Line({theme}: LeftBarProps) {
    return (
        <div
            style={{
                height: '100%',
                width: '2px',
                backgroundColor: theme === 'dark' ? getColor('white') : getColor('near-black')
            }}
        />
    )
}