import gsap from 'gsap';
import { ScrambleTextPlugin } from '/src/touchpoints/live/lib/js/vendors/ScrambleTextPlugin.min.js';
import {getSeason} from '/src/touchpoints/live/lib/js/utils.js';
gsap.registerPlugin(ScrambleTextPlugin);

export default function presetTL (onPixelEffect, meta) {
  const tl = this.tl;
  const { uniforms } = this.visualLayer;

  const cpixa = 60;




  tl.add(gsap.set(this.verticalTextTop?.querySelector('h2'), { scrambleText: { text: '  ' }}), 0);
  tl.add(gsap.set(this.verticalTextBottom?.querySelector('h2'), { scrambleText: { text: '  ' }}), 0);
  tl.add(gsap.set(uniforms.mosaicAmount, { value: 1 }), 0);
  tl.add(gsap.set(this.getAllInOverlay('.swatch-item'), { opacity: 0 }), 0);
  tl.add(gsap.set(this.getAllInOverlay('.swatch-item'), { y: -50 }), 0);
  tl.add(gsap.set(uniforms.img1Pix, { value: 80 }), 0);
  tl.add(gsap.set(uniforms.maskPixelAmount, { value: 20 }), 0);
  tl.add(gsap.set(uniforms.contourPixelAmount, { value: 8 }), 0);
  tl.add(gsap.set(uniforms.imgMosaicAmount, { value: 0 }), 0);

  tl.add(gsap.to(uniforms.mosaicAmount, { value: 0, duration: 1.5, ease: 'power1.out' }), 0.1);
  tl.add(gsap.to(uniforms.img1Pix, { value: 1, duration: 1.5 }), 0.1);
  tl.add(gsap.to(this.getAllInOverlay('.swatch-item'), { opacity: 1, stagger: 0.1, duration: 0.7, ease: 'expo.in' }), 1.3);
  tl.add(gsap.to(this.getAllInOverlay('.swatch-item'), { y: 0, stagger: 0.1, duration: 0.7 }), 1.3);

  tl.add(gsap.to(uniforms.maskPixelAmount, { value: 1, duration: 0.7, ease: 'power2.out' }), 1.5);
  tl.add(gsap.to(uniforms.contourPixelAmount, { value: cpixa, duration: 0.7, ease: 'power2.out' }), 1.5);
  tl.add(gsap.to(uniforms.imgMosaicAmount, { value: 1, duration: 1.0, ease: 'power0.none' }), 1.5);

  tl.add(gsap.to(this.verticalTextBottom?.querySelector('h2'), {
    duration: 0.33,
    scrambleText: {
      text: getSeason(),
      chars: '             NIKE             ',
    }
  }), 1.5);
  tl.add(gsap.to(this.verticalTextTop?.querySelector('h2'), {
    duration: 1,
    scrambleText: {
      text: meta,
      chars: '             NIKE             ',
    }
  }), 1.5);

  tl.add(gsap.to(uniforms.img2Pix, { value: 50, duration: 0.5 }), 4);
  tl.add(gsap.to(uniforms.maskPixelAmount, { value: 50, duration: 0.5, ease: 'power2.out' }), 4);
  tl.add(gsap.to(uniforms.contourPixelAmount, { value: 6, duration: 0.5, ease: 'power2.in' }), 4);
  tl.add(gsap.to(uniforms.imgMosaicAmount, { value: 0.3, duration: 0.5, ease: 'power0.none' }), 4);

  tl.add(onPixelEffect, 4.5);

  tl.add(gsap.to(uniforms.img2Pix, { value: 1, duration: 0.5 }), 4.5);
  tl.add(gsap.to(uniforms.maskPixelAmount, { value: 1, duration: 0.5, ease: 'power2.out' }), 4.5);
  tl.add(gsap.to(uniforms.contourPixelAmount, { value: cpixa, duration: 0.5, ease: 'power2.out' }), 4.5);
  tl.add(gsap.to(uniforms.imgMosaicAmount, { value: 1, duration: 0.5, ease: 'power0.none' }), 4.5);

  tl.add(gsap.to(this.verticalTextTop?.querySelector('h2'), {
    duration: 1,
    scrambleText: {
      text: meta,
      chars: '             NIKE             ',
    }
  }), 4.5);


  tl.add(gsap.to(this.getAllInOverlay('h2'), {
    duration: 1,
    scrambleText: {
      text: '  ',
      chars: '             NIKE             ',
    }
  }), 7);

  tl.add(gsap.to(this.getAllInOverlay('.swatch-item'), { opacity: 0, duration: 1, ease: 'expo.out' }), 7);
  tl.add(gsap.to(uniforms.mosaicAmount, { value: 1, duration: 2.0, ease: 'power3.out' }), 7);
}
