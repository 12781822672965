[
  {
    "name": "ChapterSetup",
    "delayVertical": true,
    "duration": "1000",
    "id": 0,
    "horizontal": [
      {
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "startPosition": 5.6,
        "name": "PrimaryTop"
      },
      {
        "drawFromPosition": 0,
        "startPosition": 50,
        "drawToPosition": 50,
        "drawFull": true,
        "name": "Equator",
        "delay": 100
      },
      {
        "startPosition": 50,
        "delay": 100,
        "drawFromPosition": 50,
        "name": "EquatorRight",
        "drawFull": true,
        "drawToPosition": 100
      },
      {
        "drawToPosition": 100,
        "startPosition": 94.4,
        "drawFromPosition": 0,
        "name": "PrimaryBottom",
        "delay": 200,
        "drawFull": true
      }
    ],
    "verticalDelayDirection": -1,
    "delayHorizontal": true,
    "mask": [],
    "vertical": [
      {
        "drawFull": true,
        "startPosition": 3.1,
        "name": "PrimaryLeft",
        "drawFromPosition": 100,
        "drawToPosition": 0
      },
      {
        "drawFromPosition": 0,
        "startPosition": 26,
        "drawToPosition": 50,
        "drawFull": true,
        "name": "Left1"
      },
      {
        "name": "Left1Bottom",
        "drawFromPosition": 50,
        "drawFull": true,
        "startPosition": 26,
        "drawToPosition": 100
      },
      {
        "drawFromPosition": 0,
        "drawToPosition": 50,
        "startPosition": 50,
        "drawFull": true,
        "name": "Equator"
      },
      {
        "name": "EquatorBottom",
        "startPosition": 50,
        "drawFromPosition": 50,
        "drawToPosition": 100,
        "drawFull": true
      },
      {
        "startPosition": 74,
        "drawFull": true,
        "drawFromPosition": 0,
        "name": "Right1",
        "drawToPosition": 50
      },
      {
        "drawToPosition": 100,
        "name": "RightBottom",
        "drawFromPosition": 50,
        "drawFull": true,
        "startPosition": 74
      },
      {
        "name": "PrimaryRight",
        "drawFromPosition": 100,
        "drawFull": true,
        "startPosition": 96.9,
        "drawToPosition": 0
      }
    ],
    "drawBorder": true,
    "circle": []
  },
  {
    "name": "ChapterEntry",
    "id": 1,
    "delayHorizontal": false,
    "horizontal": [
      {
        "drawToPosition": 25,
        "drawFromPosition": 0,
        "startPosition": 5.6,
        "drawFull": false,
        "delay": 1000,
        "name": "AccentLeft",
        "segmentGroup": {
          "numLines": "4",
          "segmentPositionEnd": 22.2
        }
      },
      {
        "drawToPosition": 100,
        "name": "PrimaryTop",
        "startPosition": 5.6,
        "drawFull": true,
        "drawFromPosition": 0
      },
      {
        "drawFromPosition": 0,
        "endPosition": 27.8,
        "delay": 100,
        "startPosition": 50,
        "name": "Equator",
        "drawToPosition": 50,
        "drawFull": true
      },
      {
        "drawToPosition": 100,
        "drawFromPosition": 50,
        "name": "EquatorRight",
        "startPosition": 50,
        "drawFull": true,
        "delay": 100
      },
      {
        "drawToPosition": 100,
        "delay": 100,
        "endPosition": 72.2,
        "startPosition": 50,
        "drawFromPosition": 50,
        "drawFull": true,
        "name": "Right"
      },
      {
        "delay": 200,
        "drawToPosition": 100,
        "drawFull": true,
        "name": "PrimaryBottom",
        "startPosition": 94.4,
        "drawFromPosition": 0
      }
    ],
    "duration": "1000",
    "mask": [
      {
        "grid": 0,
        "horizontal": [
          "EquatorRight",
          "Right"
        ],
        "colorFill": true,
        "vertical": [
          "EquatorBottom",
          "RightBottom"
        ],
        "name": "Headline",
        "polyOnly": true,
        "asset": 0
      },
      {
        "name": "Description",
        "horizontal": [
          "Right",
          "PrimaryBottom"
        ],
        "grid": 0,
        "vertical": [
          "EquatorBottom",
          "AuxRight"
        ],
        "asset": 0,
        "colorFill": true,
        "polyOnly": true
      },
      {
        "horizontal": [
          "Equator",
          "PrimaryBottom"
        ],
        "name": "Hero",
        "grid": 0,
        "asset": 0,
        "assetPositionWithMask": true,
        "vertical": [
          "Left Border",
          "HeroMaskLine"
        ]
      },
      {
        "coverFill": true,
        "container": "mask",
        "asset": 0,
        "grid": 0,
        "vertical": [
          "Grid1MaskStartLine",
          "Grid1MaskLine"
        ],
        "name": "Grid1",
        "horizontal": [
          "PrimaryTop",
          "EquatorRight"
        ]
      },
      {
        "grid": 0,
        "vertical": [
          "Grid2MaskStartLine",
          "Grid2MaskLine"
        ],
        "horizontal": [
          "Right",
          "PrimaryBottom"
        ],
        "asset": 0,
        "name": "Grid2",
        "container": "mask",
        "coverFill": true
      }
    ],
    "vertical": [
      {
        "name": "Left1",
        "startPosition": 26,
        "drawToPosition": 50,
        "endPosition": -1,
        "drawFromPosition": 0,
        "drawFull": true
      },
      {
        "drawToPosition": 0,
        "startPosition": 3.1,
        "drawFull": true,
        "drawFromPosition": 100,
        "endPosition": -1,
        "name": "PrimaryLeft"
      },
      {
        "drawFromPosition": 50,
        "startPosition": 26,
        "drawToPosition": 100,
        "name": "Left1Bottom",
        "drawFull": true,
        "endPosition": -1
      },
      {
        "drawFull": true,
        "drawToLine": "Equator",
        "name": "AuxLeft",
        "delay": 1000,
        "drawFromLine": "PrimaryTop",
        "startPosition": 0,
        "endPosition": 25
      },
      {
        "startPosition": 50,
        "drawFull": true,
        "drawToPosition": 50,
        "drawFromPosition": 0,
        "name": "Equator"
      },
      {
        "drawFromPosition": 50,
        "drawToPosition": 100,
        "startPosition": 50,
        "drawFull": true,
        "name": "EquatorBottom"
      },
      {
        "drawToPosition": 50,
        "drawFull": true,
        "name": "Right1",
        "drawFromPosition": 0,
        "startPosition": 74,
        "endPosition": 75
      },
      {
        "name": "AuxRight",
        "startPosition": 101,
        "endPosition": 87.5,
        "delay": 1000,
        "drawToLine": "PrimaryBottom",
        "drawFull": false,
        "extend": true,
        "drawFromLine": "Right"
      },
      {
        "drawToPosition": 100,
        "name": "RightBottom",
        "drawFull": true,
        "endPosition": 101,
        "startPosition": 74,
        "drawFromPosition": 50
      },
      {
        "name": "PrimaryRight",
        "drawToPosition": 0,
        "startPosition": 96.9,
        "drawFromPosition": 100,
        "endPosition": 101,
        "drawFull": true
      },
      {
        "drawFromPosition": 0,
        "startAlpha": 0.01,
        "endPosition": 50,
        "drawToPosition": 100,
        "name": "HeroMaskLine",
        "delay": 1000,
        "drawFull": true,
        "startPosition": 0
      },
      {
        "name": "Grid1MaskLine",
        "endPosition": 100,
        "delay": 500,
        "startPosition": 75,
        "drawToPosition": 100,
        "drawFull": true,
        "startAlpha": 0.01,
        "drawFromPosition": 0
      },
      {
        "name": "Grid2MaskLine",
        "startPosition": 87.5,
        "delay": 2000,
        "drawFull": true,
        "drawToPosition": 100,
        "drawFromPosition": 0,
        "endPosition": 100,
        "startAlpha": 0.01
      },
      {
        "drawToPosition": 100,
        "endPosition": 75,
        "startPosition": 75,
        "name": "Grid1MaskStartLine",
        "drawFull": true,
        "startAlpha": 0.01,
        "drawFromPosition": 0
      },
      {
        "startAlpha": 0.01,
        "startPosition": 87.5,
        "drawFull": true,
        "name": "Grid2MaskStartLine",
        "drawFromPosition": 0,
        "drawToPosition": 100
      }
    ],
    "delayVertical": false,
    "drawBorder": true,
    "circle": []
  },
  {
    "horizontal": [
      {
        "drawFromPosition": 0,
        "drawFull": true,
        "startPosition": 5.6,
        "drawToPosition": 25,
        "name": "AccentLeft",
        "segmentGroup": {
          "segmentPositionEnd": 22.2,
          "numLines": "4"
        },
        "delay": 100
      },
      {
        "name": "PrimaryTop",
        "drawFromPosition": 0,
        "drawFull": true,
        "startPosition": 5.6,
        "drawToPosition": 100,
        "endPosition": 5.6
      },
      {
        "drawToPosition": 50,
        "startPosition": 27.8,
        "drawFromPosition": 0,
        "endPosition": 27.8,
        "delay": 100,
        "drawFull": true,
        "name": "Equator"
      },
      {
        "drawFull": true,
        "drawFromPosition": 50,
        "name": "EquatorRight",
        "endPosition": 50,
        "startPosition": 50,
        "delay": 100,
        "drawToPosition": 100
      },
      {
        "drawFromPosition": 50,
        "name": "Right",
        "delay": 100,
        "endPosition": 72.2,
        "drawToPosition": 100,
        "drawFull": true,
        "startPosition": 72.2
      },
      {
        "drawToPosition": 100,
        "drawFromPosition": 0,
        "startPosition": 94.4,
        "drawFull": true,
        "endPosition": 94.4,
        "delay": 200,
        "name": "PrimaryBottom"
      }
    ],
    "delayVertical": false,
    "drawBorder": true,
    "duration": "1000",
    "id": 1,
    "delayHorizontal": false,
    "name": "ChapterEntry",
    "mask": [
      {
        "name": "Headline",
        "vertical": [
          "EquatorBottom",
          "RightBottom"
        ],
        "horizontal": [
          "EquatorRight",
          "Right"
        ],
        "asset": 0,
        "polyOnly": true,
        "colorFill": true,
        "grid": 0
      },
      {
        "polyOnly": true,
        "colorFill": true,
        "name": "Description",
        "asset": 0,
        "horizontal": [
          "Right",
          "PrimaryBottom"
        ],
        "grid": 0,
        "vertical": [
          "EquatorBottom",
          "AuxRight"
        ]
      },
      {
        "asset": 0,
        "vertical": [
          "Left Border",
          "HeroMaskLine"
        ],
        "coverFill": true,
        "grid": 0,
        "container": "mask",
        "name": "Hero",
        "horizontal": [
          "Equator",
          "PrimaryBottom"
        ]
      },
      {
        "grid": 0,
        "vertical": [
          "Grid1MaskStartLine",
          "Grid1MaskLine"
        ],
        "coverFill": true,
        "horizontal": [
          "PrimaryTop",
          "EquatorRight"
        ],
        "asset": 0,
        "name": "Grid1",
        "container": "mask"
      },
      {
        "coverFill": true,
        "name": "Grid2",
        "grid": 0,
        "vertical": [
          "Grid2MaskStartLine",
          "Grid2MaskLine"
        ],
        "container": "mask",
        "asset": 0,
        "horizontal": [
          "Right",
          "PrimaryBottom"
        ]
      }
    ],
    "circle": [],
    "vertical": [
      {
        "startPosition": -1,
        "drawToPosition": 50,
        "name": "Left1",
        "endPosition": -1,
        "drawFromPosition": 0,
        "drawFull": true
      },
      {
        "endPosition": -1,
        "name": "PrimaryLeft",
        "drawFromPosition": 100,
        "drawToPosition": 0,
        "startPosition": -1,
        "drawFull": true
      },
      {
        "drawFull": true,
        "startPosition": -1,
        "drawFromPosition": 50,
        "drawToPosition": 100,
        "name": "Left1Bottom",
        "endPosition": -1
      },
      {
        "delay": 1000,
        "name": "AuxLeft",
        "startPosition": 25,
        "endPosition": 25,
        "drawFromLine": "PrimaryTop",
        "drawFull": true,
        "drawToLine": "Equator"
      },
      {
        "drawFull": true,
        "drawToPosition": 50,
        "name": "Equator",
        "startPosition": 50,
        "drawFromPosition": 0,
        "endPosition": 50
      },
      {
        "drawFull": true,
        "startPosition": 50,
        "drawToPosition": 100,
        "drawFromPosition": 50,
        "endPosition": 50,
        "name": "EquatorBottom"
      },
      {
        "drawFull": true,
        "startPosition": 75,
        "name": "Right1",
        "drawFromPosition": 0,
        "drawToPosition": 50
      },
      {
        "drawFull": true,
        "endPosition": 87.5,
        "startPosition": 87.5,
        "drawToLine": "PrimaryBottom",
        "drawFromLine": "Right",
        "delay": 1000,
        "name": "AuxRight",
        "extend": true
      },
      {
        "drawFromPosition": 50,
        "drawFull": true,
        "name": "RightBottom",
        "startPosition": 101,
        "drawToPosition": 100,
        "endPosition": 101
      },
      {
        "endPosition": 101,
        "drawFromPosition": 100,
        "startPosition": 101,
        "drawToPosition": 0,
        "name": "PrimaryRight",
        "drawFull": true
      },
      {
        "endPosition": 0,
        "name": "HeroMaskLine",
        "drawToPosition": 100,
        "startAlpha": 0.01,
        "drawFull": true,
        "drawFromPosition": 0,
        "delay": 3500,
        "startPosition": 50
      },
      {
        "drawFromPosition": 0,
        "endPosition": 75,
        "drawToPosition": 100,
        "delay": 2500,
        "startAlpha": 0.01,
        "startPosition": 100,
        "name": "Grid1MaskLine",
        "drawFull": true
      },
      {
        "delay": 1500,
        "name": "Grid2MaskLine",
        "endPosition": 87.5,
        "startAlpha": 0.01,
        "startPosition": 100,
        "drawToPosition": 100,
        "drawFromPosition": 0,
        "drawFull": true
      },
      {
        "name": "Grid1MaskStartLine",
        "drawToPosition": 100,
        "drawFromPosition": 0,
        "drawFull": true,
        "startPosition": 75,
        "startAlpha": 0.01
      },
      {
        "name": "Grid2MaskStartLine",
        "startPosition": 87.5,
        "drawToPosition": 100,
        "drawFull": true,
        "drawFromPosition": 0,
        "startAlpha": 0.01
      }
    ]
  },
  {
    "id": 2,
    "circle": [],
    "duration": "1000",
    "drawBorder": true,
    "name": "ChapterExit",
    "horizontal": [
      {
        "drawFull": true,
        "drawToPosition": 100,
        "startPosition": 5.6,
        "drawFromPosition": 0,
        "name": "PrimaryTop"
      },
      {
        "delay": 1000,
        "startPosition": 5.6,
        "extend": true,
        "drawToLine": "AuxLeft",
        "segmentGroup": {
          "segmentPositionEnd": 22.2,
          "numLines": "4"
        },
        "drawFull": false,
        "drawFromPosition": 0,
        "name": "AccentLeft"
      },
      {
        "drawFull": true,
        "endPosition": 50,
        "drawFromPosition": 0,
        "delay": 1000,
        "name": "Equator",
        "startPosition": 27.8,
        "drawToPosition": 50
      },
      {
        "delay": 100,
        "name": "EquatorRight",
        "drawFromPosition": 50,
        "startPosition": 50,
        "drawToPosition": 100,
        "drawFull": true
      },
      {
        "delay": 200,
        "drawFromPosition": 0,
        "startPosition": 94.4,
        "drawFull": true,
        "name": "PrimaryBottom",
        "drawToPosition": 100
      },
      {
        "drawFromPosition": 50,
        "drawFull": true,
        "name": "Right",
        "startPosition": 72.2,
        "endPosition": 94.4,
        "delay": 1000,
        "drawToPosition": 100
      }
    ],
    "delayVertical": false,
    "mask": [],
    "delayHorizontal": false,
    "vertical": [
      {
        "drawToPosition": 27.5,
        "endPosition": -1,
        "startPosition": 25,
        "drawFromLine": "PrimaryTop",
        "drawFull": true,
        "extend": false,
        "name": "AuxLeft"
      },
      {
        "startPosition": -1,
        "drawFromPosition": 100,
        "drawToPosition": 0,
        "endPosition": 3.1,
        "delay": 1000,
        "name": "PrimaryLeft",
        "drawFull": true
      },
      {
        "name": "Left1",
        "endPosition": 26,
        "drawFull": true,
        "delay": 1000,
        "drawToPosition": 50,
        "startPosition": -1,
        "drawFromPosition": 0
      },
      {
        "drawToPosition": 100,
        "drawFromPosition": 50,
        "endPosition": 26,
        "drawFull": true,
        "startPosition": -1,
        "name": "Left1Bottom",
        "delay": 1000
      },
      {
        "drawFull": true,
        "startPosition": 50,
        "drawToPosition": 50,
        "drawFromPosition": 0,
        "name": "Equator"
      },
      {
        "startPosition": 50,
        "drawFromPosition": 50,
        "name": "EquatorBottom",
        "drawFull": true,
        "drawToPosition": 100
      },
      {
        "drawFull": true,
        "delay": 1000,
        "drawToPosition": 50,
        "drawFromPosition": 0,
        "name": "Right1",
        "startPosition": 101,
        "endPosition": 74
      },
      {
        "drawFull": true,
        "drawFromPosition": 50,
        "endPosition": 74,
        "delay": 1000,
        "startPosition": 101,
        "name": "RightBottom",
        "drawToPosition": 100
      },
      {
        "drawToPosition": 0,
        "startPosition": 101,
        "endPosition": 96.9,
        "name": "PrimaryRight",
        "drawFromPosition": 100,
        "drawFull": true,
        "delay": 1000
      },
      {
        "drawFull": true,
        "extend": true,
        "startPosition": 87.5,
        "name": "AuxRight",
        "drawToLine": "PrimaryBottom",
        "drawFromLine": "Right",
        "endPosition": 101
      }
    ]
  }
]