#pragma glslify: circle = require('../../../../lib/glsl/circle.glsl')
#pragma glslify: ring = require('../../../../lib/glsl/ring.glsl')
#pragma glslify: box = require('../../../../lib/glsl/box.glsl')
#pragma glslify: grid = require('../../../../lib/glsl/grid.glsl')
#pragma glslify: mosaic = require('../../../../lib/glsl/mosaic.glsl')

#pragma glslify: coverUV = require('../../../../lib/glsl/coverUV.glsl')
#pragma glslify: pixelateUV = require('../../../../lib/glsl/pixelateUV.glsl')
#pragma glslify: placedUV = require('../../../../lib/glsl/placedUV.glsl')
#pragma glslify: zoomedUV = require('../../../../lib/glsl/zoomedUV.glsl')
#pragma glslify: gammaCorrect = require('../../../../lib/glsl/gammaCorrect.glsl')

#ifdef GL_ES
precision mediump float;
#endif

varying vec2 vUv;

uniform float time;

// general stuffs
uniform vec2 res; // res
uniform float pixa; // pixelization amount (size of each cell/pixel)
uniform float pixr; // pixel ratio
uniform float stroke; // stroke width

// mosaic
uniform float mosaicAmount; // how much mosaic is covering the screen (0-1)
uniform float mosaicColor; // greyscale color

uniform sampler2D row0Tex;
uniform float row0Offset;
uniform float row0Speed;
uniform float row0Width;
// uniform vec2 row0Res;

uniform sampler2D row1Tex;
uniform float row1Offset;
uniform float row1Speed;
uniform float row1Width;
// uniform vec2 row1Res;

uniform sampler2D row2Tex;
uniform float row2Offset;
uniform float row2Speed;
uniform float row2Width;
// uniform vec2 row2Res;

uniform sampler2D row3Tex;
uniform float row3Offset;
uniform float row3Speed;
uniform float row3Width;
// uniform vec2 row3Res;

uniform float rowScale;
uniform float rowOffset;
uniform float dimy;

vec3 rowImage (vec3 color, sampler2D img, vec2 uv, vec2 pos, vec2 dim, float offset, float pr) {
  vec2 center = gl_FragCoord.xy - pos;
  float b = box(center, dim);
  return mix(color, texture2D(img, vec2((uv.x + offset), uv.y), -5.).rgb, b);
}

void main() {
  vec3 color = vec3(1.);

  vec2 st = (gl_FragCoord.xy / res) / pixr;
  float ar = (res.x / res.y);
  float px = (res.x / pixa) * pixr;

  // divide x by 2 because we have a 18 images row but want to display only 9 at a time
  // divide y by 4 because there's 4 rows fitted in the canvas height
  vec2 uv = vec2((st.x / 2.), (st.y * 4.)) * pixr;
  uv = pixelateUV(uv, px, 12.);

  vec2 dim = vec2(res.x, dimy) * pixr;

  vec2 pos0 = (dim * vec2(.5)) + vec2(-(dim.x * .5) * abs(1. - row0Width), (dim.y * 0.));
  vec2 uv0 = vec2((uv.x - row0Width), uv.y);
  color = rowImage(color, row0Tex, uv0, pos0, vec2((dim.x * row0Width), dim.y), row0Offset, pixr);

  vec2 pos1 = (dim * vec2(.5)) + vec2((dim.x * .5) * abs(1. - row1Width), (dim.y * 1.));
  vec2 uv1 = vec2((uv.x + row1Width), uv.y);
  color = rowImage(color, row1Tex, uv1, pos1, vec2((dim.x * row1Width), dim.y), row1Offset, pixr);

  vec2 pos2 = (dim * vec2(.5)) + vec2(-(dim.x * .5) * abs(1. - row2Width), (dim.y * 2.));
  vec2 uv2 = vec2((uv.x - row2Width), uv.y);
  color = rowImage(color, row2Tex, uv2, pos2, vec2((dim.x * row2Width), dim.y), row2Offset, pixr);

  vec2 pos3 = (dim * vec2(.5)) + vec2((dim.x * .5) * abs(1. - row3Width), (dim.y * 3.));
  vec2 uv3 = vec2((uv.x + row3Width), uv.y);
  color = rowImage(color, row3Tex, uv3, pos3, vec2((dim.x * row3Width), dim.y), row3Offset, pixr);

  color = mix(color, vec3(mosaicColor), mosaic(st, ar, time, mosaicAmount));

  gl_FragColor = vec4(gammaCorrect(color.rgb, 1.0), 1.);
}
