import {useCallback, useEffect, useMemo, useState} from "react";
import {countDown, truncate} from "../shared";
import Media from "./Media";
import Typography from "./Typography";
import {dateFormat, DropInterface, SnkrsCalMode} from "../screens/SnkrsCal";
import {containsJapanese} from "../../../utilities";

export default function Drop({
                                 drop,
                                 mode,
                                 index,
                                 onTop = false,
                                 hide_dates = false,
                                 locale,
                                 available_copy
                             }: { drop: DropInterface, mode: SnkrsCalMode, index: number, hide_dates?: boolean, locale: string, available_copy?: string, onTop: boolean }) {
    const [wasOnTop, setWasOnTop] = useState(false);
    const [zIndex, setZindex] = useState(0);
    const url = useMemo(() => drop?.images?.[0], [drop]);
    const droppingAt = useMemo(() => dateFormat(drop, {locale, available_copy}), [drop]);

    useEffect(() => {
        // reset it
        if (onTop == false && wasOnTop === false) {
            setZindex(2);
            setTimeout(() => {
                setZindex(0);
            }, 1_000);

            setWasOnTop(onTop);
        }
    }, [onTop]);

    const hasJapanese = useCallback((content) => {
        return containsJapanese(content);
    }, []);

    return (
        <div style={{width: '50%', height: '33.33%', zIndex}}>
            {
                mode === 'drops' && (
                    <>
                        <Media
                            preventExit
                            preventInitial
                            preventInitialSlide
                            layoutId={`${drop.slug}-${index}`}
                            style={{marginTop: '-80px'}}
                            transparent
                            media={[url]}
                            width={'100%'}
                            height={'100%'}
                        />
                        <div style={{marginLeft: 50, marginTop: '-80px'}}>
                            <Typography
                                size={30}
                                variant={hasJapanese(drop.subtitle) ? 'pp-ultralight' : 'pp-ultralight-italic'}
                                color={'black'}
                            >
                                {truncate(drop.subtitle, 20)}
                            </Typography>
                            <Typography
                                size={30}
                                variant={'pp-ultralight'}
                                color={'black'}
                            >
                                {truncate(drop.colorDescription, 20)}
                            </Typography>
                            {
                                !hide_dates && (
                                    <Typography
                                        size={30}
                                        variant={'pp-ultralight'}
                                        color={'black'}
                                    >
                                        {droppingAt}
                                    </Typography>
                                )
                            }
                        </div>
                    </>
                )
            }


            {
                mode === 'stories' && (
                    <Media
                        preventInitial
                        preventExit
                        preventInitialSlide
                        layoutId={`${drop.slug}-${index}`}
                        style={{marginTop: '0px'}}
                        transparent
                        media={[drop?.cover]}
                        width={'100%'}
                        height={'100%'}
                    />
                )
            }
        </div>
    )
}