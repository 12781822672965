import React from 'react';
import {MediaType, stringFallback, ThemeType} from "../shared";
import Transition from "../components/Transition";
import Background from "../components/Background";
import Slide from "../components/Slide";
import GlitchTypography from "../components/GlitchText/GlitchTypography";
import Typography from "../components/Typography";
import StoreJumpman from "../components/StoreJumpman";

interface IntroProps {
    variant: 'general' | 'store';
    fly_asset: MediaType;
    title_asset: MediaType;
    logo: MediaType;
    supertitle: string;
    title: string;
    subtitle: string;
    theme: ThemeType;
}

const ASSETS = {
    jordan_1: new URL('../demo/core/assets/intro/jordan_1.png?as=webp', import.meta.url),
    jordan_2: new URL('../demo/core/assets/intro/jordan_2.png?as=webp', import.meta.url),
    title_1: new URL('../demo/core/assets/intro/title_1.png?as=webp', import.meta.url),
    title_2: new URL('../demo/core/assets/intro/title_2.png?as=webp', import.meta.url),
    title_milan: new URL('../demo/core/assets/intro/title_milan.png?as=webp', import.meta.url),
    logo: new URL('../assets/images/logo.png?as=webp', import.meta.url),
};

const toAsset = (url) => {
    return {
        url,
        resource_type: 'image',
    } as MediaType;
}

export default function Intro(props: IntroProps) {
    const component = props.variant === 'store' ?
        <Store {...props} /> : <DynamicGeneral {...props} />;

    return (
        <Transition variant={'wipe-x'} cover>
            {component}
        </Transition>
    )
}

function DynamicGeneral({
                            logo,
                            fly_asset = toAsset(ASSETS.jordan_2),
                            supertitle,
                            title,
                            subtitle,
                            theme
                        }: IntroProps) {
    supertitle = stringFallback(supertitle, 'WELCOME TO');
    title = stringFallback(title, 'WORLD OF\nFLIGHT');
    subtitle = stringFallback(subtitle, 'You just entered the flyest\nstore in the world.');

    return (
        <Background variant={'black-print-wash'}>
            <div style={{position: 'relative', width: '100%', height: '100%'}}>
                <FlyBy asset={fly_asset}/>

                <SlideElement>
                    <div style={{marginBottom: 60, marginTop: 350}}>
                        <Typography
                            variant={'pp-ultralight'}
                            color={'white'}
                            align={'center'}
                            size={70}
                        >
                            {supertitle}
                        </Typography>
                    </div>
                    <div style={{marginTop: 0}}>
                        <GlitchTypography
                            variant={'jordan-condensed'}
                            size={530}
                            scaleX={.36}
                            align={'center'}
                            color={'white'}
                        >
                            {title}
                        </GlitchTypography>
                    </div>
                    <div style={{marginTop: 100}}>
                        <Typography
                            variant={'pp-ultralight'}
                            color={'white'}
                            align={'center'}
                            size={70}
                        >
                            {subtitle}
                        </Typography>
                    </div>
                </SlideElement>

            </div>
        </Background>
    )
}

function Store({
                   logo = toAsset(ASSETS.logo),
                   title_asset = toAsset(ASSETS.title_milan),
                   fly_asset = toAsset(ASSETS.jordan_1),
                   title,
                   supertitle = 'Welcome to the flyest\nstore in the world.',
                   subtitle
               }: IntroProps) {
    return (
        <Background variant={'black-grit'}>
            <div style={{position: 'relative', width: '100%', height: '100%'}}>
                <FlyBy asset={fly_asset}/>

                <SlideElement>
                    <div
                        style={{
                            position: 'absolute',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: 150
                        }}
                    >
                        <Typography align={'center'} variant={'pp-ultralight'} color={'white'} size={50}>
                            {supertitle}
                        </Typography>
                    </div>
                    <img width={'100%'} src={title_asset.url} alt=""/>
                </SlideElement>
                <SlideImage asset={title_asset}/>

                <div
                    style={{
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        left: 0, top: '0'
                    }}
                >
                    <Slide
                        direction={'to-top'}
                        showAfter={.5}
                        duration={2}
                        multiplier={1}
                    >
                        <div
                            style={{display: 'flex', height: '100%', alignItems: 'flex-end', justifyContent: 'center'}}>
                            <div style={{marginBottom: 80, width: 340}}>
                                <StoreJumpman theme={'dark'}/>
                            </div>

                            {/*<img*/}
                            {/*    src={logo.url}*/}

                            {/*    alt=""*/}
                            {/*/>*/}
                        </div>

                    </Slide>
                </div>

            </div>
        </Background>
    )
}

function FlyBy({asset}: { asset: MediaType }) {
    return (
        <div style={{position: 'absolute', width: '100%', height: '100%', top: 0, zIndex: 2}}>
            <Slide
                position={'absolute'}
                direction={'to-left'}
                showAfter={2}
                hideAfter={10}
                duration={1.5}
            >
                <img width={'100%'} src={asset.url} alt=""/>
            </Slide>
        </div>
    )
}

function SlideImage({asset}: { asset: MediaType }) {
    return (
        <SlideElement>
            <img width={'100%'} src={asset.url} alt=""/>
        </SlideElement>
    )
}

function SlideElement({children}: { children: React.ReactNode }) {
    return (
        <Slide direction={'to-top'} duration={1.5} showAfter={.5} multiplier={1.1}>
            {children}
        </Slide>
    )
}