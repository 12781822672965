import gsap from 'gsap';
import { ScrambleTextPlugin } from '/src/touchpoints/live/lib/js/vendors/ScrambleTextPlugin.min.js';
import {getSeason} from '/src/touchpoints/live/lib/js/utils.js';
gsap.registerPlugin(ScrambleTextPlugin);

export default function presetTL (switches=[]) {
  const tl = this.tl;
  const { uniforms } = this.visualLayer;
  const vf = this.viewFinderMarks;
  const cornerText = this.verticalTextTop.querySelector('h2');

  tl.add(gsap.set(uniforms.pixa, { value: 120 }), 0);
  tl.add(gsap.set(uniforms.img1Zoom, { value: 1.25 }), 0);
  tl.add(gsap.set(vf, { scale: 2 }), 0);
  tl.add(gsap.set(this.getAllInOverlay('.swatch-item'), { value: 0 }), 0);
  tl.add(gsap.set(vf, { opacity: 0 }), 0);

  tl.add(gsap.to(uniforms.pixa, { value: 1, duration: 0.7, ease: 'power2.out' }), 0.1);
  tl.add(gsap.to(vf, { scale: 1.0, duration: 0.5, ease: 'expo.in' }), 0.1);
  tl.add(gsap.to(uniforms.img1Zoom, { value: 1, duration: 0.5, ease: 'power2.in' }), 0.1);

  tl.add(gsap.to(this.getAllInOverlay('.swatch-item'), { opacity: 1, duration: 1, ease: 'expo.in' }), 0.3);

  tl.add(gsap.to(this.verticalTextBottom.querySelector('h2'), {
    duration: 1,
    scrambleText: {
      text: getSeason(),
      chars: '             NIKE             ',
    }
  }), 0.1);
  textSwitch(0.3, this.datas.metaText?.EN?.toUpperCase(), 1);

  tl.add(gsap.to(vf, { opacity: 1, duration: 0.1, ease: 'steps(1)' }), 0.1);
  tl.add(gsap.to(vf, { opacity: 0, duration: 0.1, ease: 'steps(1)' }), 0.2);
  tl.add(gsap.to(vf, { opacity: 1, duration: 0.1, ease: 'steps(1)' }), 0.3);

  function textSwitch (time, text) {
    tl.add(gsap.to(cornerText, {
      duration: 1,
      scrambleText: {
        text,
        chars: '             NIKE             ',
      }
    }), time + 0.4);
  }

  function pixelSwitch (time, onPixelEffect) {
    tl.add(gsap.to(uniforms.img1Zoom, { value: 1.25, duration: 0.5, ease: 'power2.in' }), time);
    tl.add(gsap.to(uniforms.pixa, {
      value: 120,
      duration: 0.5,
      ease: 'expo.in',
      onComplete: onPixelEffect
    }), time);
    tl.add(gsap.to(uniforms.pixa, { value: 1, duration: 0.5, ease: 'expo.out' }), (time + 0.5));
    tl.add(gsap.to(uniforms.img1Zoom, { value: 1, duration: 0.5, ease: 'power2.out' }), (time + 0.5));
  }

  switches.forEach(({ time, text, onPixelEffect }) => {
    pixelSwitch(time, onPixelEffect);
    text && textSwitch(time, text.toUpperCase());
  });

  tl.add(gsap.to(uniforms.pixa, { value: 120, duration: 0.5, ease: 'expo.in' }), 6);
}
