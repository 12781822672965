import {v4} from 'uuid';
import {storage} from "./utilities";

export default class Vitals {

	constructor() {
		this.sessionUuid = this.getUuid();
		this.startedAt = new Date();
		this.current = null;
		this.windowWidth = window.innerWidth;
		this.windowHeight = window.innerHeight;
		this.screenWidth = window.screen.width;
		this.screenHeight = window.screen.height;
		this.dpi = window.devicePixelRatio;
		this.os = window.navigator.platform;
		this.browser = this.detectBrowser();
		this.cpuCores = navigator.hardwareConcurrency;
		this.performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance;
		this.memory = this.getMemory();
		this.userAgent = {
			short: window.navigator.userAgent,
			...window.navigator.userAgentData
		};
	}

	getUuid() {
		let id = storage.get('sessionUuid', null);

		if (!id) {
			id = v4();
			storage.set('sessionUuid', id);
		}

		return id;
	}

	detectBrowser() {
		var ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
		if(/trident/i.test(M[1])){
			tem=/\brv[ :]+(\d+)/g.exec(ua) || [];
			return {name:'IE',version:(tem[1]||'')};
		}
		if(M[1]==='Chrome'){
			tem=ua.match(/\bOPR|Edge\/(\d+)/)
			if(tem!=null)   {return {name:'Opera', version:tem[1]};}
		}
		M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
		if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}

		return `${M[0]} ${M[1]}`;
	}

	getMemory() {
		try {
			return JSON.parse(JSON.stringify(this.performance?.memory, [
				'jsHeapSizeLimit',
				'totalJSHeapSize',
				'usedJSHeapSize'
			]));
		} catch (error) {
			console.error(`Unable to retrieve memory records`, error);
		}
	}

	snapshot() {
		this.current = new Date();
		this.secondsRunning = Math.round((this.current - this.startedAt) / 1000);
		return this.toData();
	}

	toData() {
		return JSON.parse(JSON.stringify(this));
	}
}
