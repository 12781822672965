if (module.hot) {
  module.hot.accept(() => {
    location.reload()
  })
}


import {
  Uniform,
  Vector2,
  Texture,
  Color,
} from 'three';

import UniversalLiveTemplate from '/src/touchpoints/live/lib/js/UniversalLiveTemplate';

import defaultSwoosh from 'url:/src/touchpoints/live/lib/assets/swoosh.png';

import vert from '/src/touchpoints/live/lib/glsl/shader.vert';
import frag from './pixel-cutout.frag';
import presetTL from './pixel-cutout-timeline';

import './pixel-cutout.scss'

class PixelCutOut extends UniversalLiveTemplate {
  constructor (name, datas, onComplete, gui) {
    super(name);

    this.setReferencesDimensions(1620, 1080);

    this.datas = datas;

    const background = this.datas?.background?.assets ?? [];
    const primary = this.datas?.primary?.assets ?? [];

    this.overlayWrapper = this.addNode({type: 'overlayWrapper', freeflow:true});

    const borderThicknesses = {
      SMALL: '1px',
      MEDIUM: '1px',
      LARGE: '1px',
    }
    document.documentElement.style.setProperty('--lls-cs-cutout-border-thick', borderThicknesses[window.SIZE_ID]);

    if (primary[0]?.swatches?.length) {
      const offsets = [0, 10, 0, 12, 0]; // quick and dirty
      const swatches = new Array(6).fill({}).map((e, i) => {
        return {
          color: primary[0].swatches[i % primary[0].swatches?.length],
          offset: this.relHeight(offsets[i]) || 0
        };
      });
 
      this.addNode({type: 'swatchList', parent: true,
        content: { swatches }})
    }
    this.verticalTextTop = this.addNode({type: 'verticalText', parent: true, className:"top-right",
      content: { text: '' }})
      
    if(this.datas?.primary?.assets[0]?.meta) {
      this.wrapper.querySelector('.vertical-text.top-right').style.minHeight = `${(this.datas?.primary?.assets[0]?.meta.length) * 20.5}px`
    }

    this.verticalTextBottom = this.addNode({type: 'verticalText', parent: true, className:"bottom-right",
      content: { text: '' }})

    this.registerTextureURL({
      src: background[0]?.url ?? defaultSwoosh,
      key: 'img1',
      pos: { x: (this.width / 2), y: (this.height / 2) },
      dim: { x: this.width, y: this.height },
      offset: { x: 0, y: 0 },
      pixel: 1,
      zoom: 1.5,
      forceJPG: true,
    });

    this.registerTextureURL({
      src: primary[0]?.url ?? defaultSwoosh,
      key: 'img2',
      pos: { x: (this.width / 2), y: (this.height / 2) },
      dim: { x: this.width, y: this.height },
      offset: { x: 0, y: 0 },
      pixel: 1,
      zoom: Math.min(0.8, (this.height / this.width)),
      forceJPG: true,
    });

    const center = new Vector2(
      (this.width / 2),
      (this.height / 2)
    );

    const uniforms = {
      time: new Uniform(0),
      res: new Uniform(screen),
      pixa: new Uniform(40),
      pixr: new Uniform(window.devicePixelRatio),
      stroke: new Uniform(1),
      padding: new Uniform(window.ORIENTATION === 'LANDSCAPE' ? 1.25 : 0.75),
      // mosaic
      mosaicAmount: new Uniform(0),
      mosaicColor: new Uniform(1),
      imgMosaicAmount: new Uniform(0),
      // mask
      maskPixelAmount: new Uniform(10),
      contourPixelAmount: new Uniform(35),
      maskZoom: new Uniform(0.2),
      maskLevel: new Uniform(240),
      maskGamma: new Uniform(1.0),
    }

    this.createVisualLayer({ frag, vert }, uniforms);
    this.setupTimeline(onComplete);

    this.setupCutOutTex(primary[0]?.maskUrl, primary[0]?.maskType);
  }

  setupCutOutTex (texURL, maskType) {
    this.setGLUni('cutoutTexture', new Texture());

    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = texURL?.replace('.webp', '.png');
    img.addEventListener('load', (e) => {
      this.setGLUni('cutoutTexture', new Texture(img), true);
      // let mz = (maskType === 'automatic') ? 0.2 : 0.1;
      // this.setGLUni('maskZoom', mz, true);
    });
  }

  populateTimeline () {
    presetTL.bind(this)(() => {
      this.visualLayer.createTexture({
        src: this.datas?.primary?.assets[1]?.url ?? defaultSwoosh,
        key: 'img2',
        forceJPG: true,
      }, false);
      this.setupCutOutTex(this.datas?.primary?.assets[1]?.maskUrl, this.datas?.primary?.assets[1]?.maskType);
    }, this.datas?.primary?.assets[0]?.meta);
  }

  preStart () {
    this.visualLayer.createTexture({
      src: this.datas?.primary?.assets[0]?.url ?? defaultSwoosh,
      key: 'img2',
      forceJPG: true,
    }, false);
    this.setupCutOutTex(this.datas?.primary?.assets[0]?.maskUrl, this.datas?.primary?.assets[0]?.maskType);
    this.wrapper.classList.add('active');
  }
}
export default PixelCutOut;
