import gsap from 'gsap';

export default function createTL (scenes, onComplete, onStart) {
  const tl = gsap.timeline({
    onComplete,
    paused: true,
    onStart: onStart,
    defaults: { ease: 'power2.inOut' }
  });

  const uniforms = scenes.map(scene => scene.userData.shader.uniforms);

  const cg = '.cs-type .content-grid';

  const cgi1 = '.cs-type .content-grid .grid-item-1';

  const w = window.innerWidth;
  const h = window.innerHeight;

  // INITIAL STATES
  tl.add(() => {
    gsap.set(uniforms[0].mosaic, { value: 0 });
    gsap.set(uniforms[0].pixels, { value: 60 });

    // gsap.set(`${cgi1} .overlay`, { scale: 0.8 });

    console.log('CS TYPE');
  }, 0);
  // ANIMATIONS

  // tl.to(`${cgi1} .overlay`, { scale: 1, duration: 1 }, 0.);

  tl.to(uniforms[0].mosaic, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.1);
  tl.to(uniforms[0].pixels, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.1);


  tl.to(uniforms[0].pixels, { value: 60, duration: 1, ease: 'power0.easeNone' }, 3.5);
  tl.to(uniforms[0].mosaic, { value: 0, duration: 1, ease: 'power0.easeNone' }, 3.5);

  tl.add(() => {}, 5.0);

  return tl;
}