import React, {useCallback, useMemo, useRef} from 'react';
import cls from 'classnames';
import {AnimatePresence, motion} from 'framer-motion';
import PropTypes from 'prop-types';
import {DEFAULT_DURATION, DEFAULT_TRANSITION} from '../../../../constants/transition';
import {useHubContext} from '../../hooks/useHubContext';
import {LEVEL_INNER_VARIANTS, LEVEL_ROOT_VARIANTS} from './LevelBlock.const';
import {LevelBlockLegend} from './LevelBlockLegend';
import {LevelBlockTitle} from './LevelBlockTitle';
import FloorNumber from "./FloorNumber";
import {containsChinese, containsKorean, containsLanguage} from "../../../../../../utilities";

export const LevelBlock = ({
                             active,
                             dark,
                             description,
                             date,
                             heightRatio,
                             image,
                             legend,
                             maxHeight,
                             reverse,
                             style,
                             title,
                             wide,
                             icon,
                           }) => {


  const getLanguage = useCallback((content) => {
    // containsLanguage(content);
    return '';
  }, [description, title, date]);

  const activeTitleRef = useRef();
  const {initialized, onInitBlock, onNext, onReverseBlock} = useHubContext();

  const activeTitleHeight = activeTitleRef.current?.getBoundingClientRect().height;
  const hasImage = typeof image?.url === 'string';
  const hasIcon = typeof icon?.url === 'string';
  const hasLegend = legend?.length;
  const rootClassName = cls('c-level-block', {
    'c-level-block--active': active,
    'c-level-block--with-image': hasImage,
    'c-level-block--with-legend': hasLegend,
  });

  const titleProps = {
    active,
    description,
    reverse,
    title,
    date,
  };

  const legendProps = {
    items: legend,
    reverse,
  };

  const getInnerHeight = () => {
    if (!hasImage) {
      return '100%';
    }
    // somehow makes the animation jumpy, probably because it changes the height
    // return active && hasImage && activeTitleHeight ? activeTitleHeight : '100%';
    return active && hasImage && activeTitleHeight ? activeTitleHeight : activeTitleHeight;
  };

  const getImageHeight = () => {
    return hasImage && activeTitleHeight ? maxHeight - activeTitleHeight : 'initial';
  };

  let variants = LEVEL_INNER_VARIANTS;
  const channelColorOverride = window.channel?.channels?.[0]?.json_config?.block_color;

  if (channelColorOverride) {
    const hex = channelColorOverride;
    variants.expand.background = `linear-gradient(90deg, ${hex} 0%, ${hex} 68.33%)`;
  }

  const iconInvertable = useMemo(() => {
    // input might be a stringified version of `"true" | "false"`
    const {invertable = true} = icon ?? {};
    return String(invertable) === 'true';
  }, [icon]);

  return (
    <motion.div
      className={rootClassName}
      custom={{
        maxHeight: hasLegend || hasImage ? maxHeight : activeTitleHeight,
        heightRatio,
      }}
      initial="shrink"
      animate={active ? 'expand' : 'shrink'}
      variants={LEVEL_ROOT_VARIANTS}
      style={style}
      transition={DEFAULT_TRANSITION}
    >
      <AnimatePresence>
        {hasImage && (
          <motion.div
            className="c-level-block__image"
            animate={active ? {height: getImageHeight()} : {height: 0}}
            transition={DEFAULT_TRANSITION}
            style={{backgroundImage: `url(${image?.url})`}}
          />
        )}
      </AnimatePresence>
      <motion.div
        className="c-level-block__inner"
        custom={{dark}}
        initial={"shrink"}
        animate={active ? 'expand' : 'shrink'}
        transition={!reverse ? DEFAULT_TRANSITION : {duration: 0, delay: 0}}
        variants={variants}
        style={{height: getInnerHeight()}}
      >
        {/* TODO: not sure if rendering the same component twice
				is the best method for the title and legend transitions */}
        {title && !legend?.length && (
          <>
            {
              hasIcon && (
                <div style={{position: 'absolute', top: '0%', height: '100%', display: 'flex', alignItems: 'center'}}>
                  <motion.img
                    src={icon?.url}
                    initial={false}
                    animate={{
                      width: active ? '9vw' : '6.5vw',
                      marginTop: '0%',
                      filter: ((active || dark) && iconInvertable) ? `invert(100%)` : `invert(0%)`,
                    }}
                    style={{
                      borderRadius: '1.2vw',
                      border: iconInvertable === false && dark && !active ? '.15vw solid white' : '.15vw solid transparent',
                    }}
                    transition={DEFAULT_TRANSITION}
                  />
                </div>
              )
            }
            <LevelBlockTitle
              {...titleProps}
              className={`c-level-block__title--initial ${hasIcon && 'has-icon'} is-${getLanguage(titleProps.title, false)}`}
              titleSize="xl"
              onReady={!initialized ? onInitBlock : reverse ? onReverseBlock : null}
            />
            <LevelBlockTitle
              {...titleProps}
              ref={activeTitleRef}
              className={`c-level-block__title--active ${hasIcon && 'has-icon'} is-${getLanguage(titleProps.description, false)}`}
              start={active}
              titleSize="3xl"
              showDescription
              onReady={onNext}
            />
          </>
        )}
        {legend?.length && (
          <>
            <FloorNumber
              title={title}
              dark={dark}
              active={active}
              wide={wide}
            />
            <div className="c-level-block__content">
              <LevelBlockLegend
                {...legendProps}
                className={`c-level-block__list-initial is-${getLanguage(legendProps.items)}`}
                fontSize="xl"
                opacity={active ? 0 : 1}
                onReady={!initialized ? onInitBlock : reverse ? onReverseBlock : null}
              />
              <LevelBlockLegend
                {...legendProps}
                className={`c-level-block__list-active is-${getLanguage(legendProps.items)}`}
                fontSize="3xl"
                opacity={active ? 1 : 0}
                showSubheadings
                start={active}
                subheadingsDelay={DEFAULT_DURATION / 2 * 1000}
                onReady={onNext}
              />
            </div>
          </>
        )}
      </motion.div>
    </motion.div>
  );
};

LevelBlock.propTypes = {
  active: PropTypes.bool,
  heightRatio: PropTypes.number,
  image: PropTypes.string,
  legend: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    subheadings: PropTypes.arrayOf(PropTypes.string),
  })),
  maxHeight: PropTypes.number,
  rootDimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  style: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string
};
