import { isEmpty, isUndefined, includes, isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
var isBetween = require('dayjs/plugin/isBetween');
var advancedFormat = require('dayjs/plugin/advancedFormat');

dayjs.extend(advancedFormat);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);

import { fetchEvents } from './js/dataService';
import NSILSchedule from './NSILSchedule';
import { getChannel } from '../../../rise/api';

export default function App({ data, channel, testing }) {
  const { title, supertitle, status, air_table } = useMemo(() => {
    return {
      title: data?.title ?? 'AVAILABLE',
      supertitle: data?.supertitle ?? 'ALL DAY',
      status: data?.status ?? 'default',
      air_table: data?.air_table ?? false,
    };
  }, [data]);

  const [offset, setOffset] = useState(null);
  const [eventData, setEventData] = useState([]);
  const [currentEvent, setCurrentEvent] = useState({});
  const [showUpcoming, setShowUpcoming] = useState(true);
  const [modTitle, setModTitle] = useState(title);
  const [modSupertitle, setModSupertitle] = useState(supertitle);
  const [modStatus, setModStatus] = useState(status);
  const [channelData, setChannelData] = useState({});
  const [roomName, setRoomName] = useState('Court 5');

  let refreshPage = null;

  const now = dayjs().tz('America/Los_Angeles');

  useEffect(() => {
    if (air_table) {
      loadChannel();
      loadEvents(offset);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(channelData)) {
      setRoomName(channelData.channels[0].json_config.room_name);
      const refreshEvents = setInterval(() => {
        setEventData([]);
        setOffset(null);
        loadEvents(null);
      }, channelData.channels[0].json_config.refresh_minutes * 60000);

      return () => {
        clearInterval(refreshEvents);
      };
    }
  }, [channelData]);

  useEffect(() => {
    if (offset) {
      loadEvents(offset);
    }
  }, [offset]);

  useEffect(() => {
    if (!isNil(refreshPage)) clearInterval(refreshPage);
    refreshPage = setInterval(() => {
      findCurrentEvent();
    }, 60000);

    return () => {
      clearInterval(refreshPage);
    };
  }, [eventData]);

  async function loadEvents(offset = null) {
    fetchEvents(offset, testing).then(response => {
      let temp = [];
      if (!isNil(offset)) temp = eventData;
      temp.push(...response.records);
      setEventData(temp);
      findCurrentEvent();
      if (response.offset) setOffset(response.offset);
    });
  }

  async function loadChannel() {
    const temp = await getChannel(channel);
    setChannelData(temp);
  }

  function findCurrentEvent() {
    if (air_table) {
      let eventsToday = [];
      let roomEvents = [];
      let bookedEvents = [];
      let upcomingEvents = [];

      bookedEvents = eventData.filter(event => {
        if (!isUndefined(event.fields['Status'])) {
          return event.fields['Status'].match(/Booked/gm);
        }
      });

      roomEvents = bookedEvents.filter(event => {
        if (!isUndefined(event.fields['Space Name'])) {
          return event.fields['Space Name'].find(element => {
            return includes(element, roomName);
          });
        }
      });

      if (roomEvents.length > 0) {
        eventsToday = roomEvents.filter(event => {
          if (!isUndefined(event.fields['Event Date'])) {
            return (
              now.date() == dayjs(event.fields['Event Date']).date() &&
              now.month() == dayjs(event.fields['Event Date']).month()
            );
          } else if (!isUndefined(event.fields['Event Start'])) {
            return (
              now.date() ==
                dayjs(event.fields['Event Start'])
                  .tz('America/Los_Angeles')
                  .date() &&
              now.month() ==
                dayjs(event.fields['Event Start'])
                  .tz('America/Los_Angeles')
                  .month()
            );
          } else {
            return false;
          }
        });
      }

      // if event currently going on, show that
      if (eventsToday.length > 0) {
        const timedEvents = eventsToday.filter(event => {
          return (
            !isUndefined(event.fields['Event Start']) &&
            !isUndefined(event.fields['Event End'])
          );
        });
        if (timedEvents.length > 0) {
          // occurring event starts 5 minutes before time and ends 5 minutes after time
          const occuringEvent = timedEvents.filter(event => {
            return dayjs().isBetween(
              dayjs(event.fields['Event Start'])
                .tz('America/Los_Angeles')
                .subtract(5, 'minute'),
              dayjs(event.fields['Event End']).tz('America/Los_Angeles'),
            );
          });
          if (occuringEvent.length > 0) {
            setShowUpcoming(false);
            setCurrentEvent(occuringEvent[0]);
            setModStatus('quiet');
            return;
          }
        }

        const allDayEvents = eventsToday.filter(event => {
          return (
            isUndefined(event.fields['Event Start']) &&
            isUndefined(event.fields['Event End'])
          );
        });

        if (allDayEvents.length > 0) {
          setShowUpcoming(false);
          setCurrentEvent(allDayEvents[0]);
          setModSupertitle('ALL DAY');
          setModStatus('quiet');
          return;
        } else if (allDayEvents.length === 0 && upcomingEvents.length > 0) {
          setShowUpcoming(true);
          setModStatus('open');
          return;
        }
      }

      if (isEmpty(currentEvent)) {
        setShowUpcoming(true);
        setModStatus('open');
        setModSupertitle('');
        setModTitle('AVAILABLE');
      }
    }
  }

  let words = [];
  let characters = 0;
  if (!isEmpty(currentEvent)) {
    words = currentEvent?.fields['Team Name']?.split(' ');
    characters = currentEvent?.fields['Team Name'].length;
  }
  let fontTest = false;
  if (words.length > 0) fontTest = words.find(word => word.length > 8);
  if (characters > 30) fontTest = true;

  const poc =
    !isEmpty(currentEvent) &&
    !isEmpty(currentEvent?.fields?.['Point of Contact'])
      ? currentEvent?.fields?.['Point of Contact']
      : '';

  return (
    <div className="NSILSchedule">
      <NSILSchedule
        supertitle={
          !isEmpty(currentEvent) &&
          !isUndefined(currentEvent.fields['Event Start']) &&
          !isUndefined(currentEvent.fields['Event End'])
            ? `${dayjs(currentEvent.fields['Event Start'])
                .tz('America/Los_Angeles')
                .format('h:mmA')} — ${dayjs(currentEvent.fields['Event End'])
                .tz('America/Los_Angeles')
                .format('h:mmA')} \n ${poc}`
            : modSupertitle
        }
        title={
          !isEmpty(currentEvent) ? currentEvent.fields['Team Name'] : modTitle
        }
        status={
          !isEmpty(currentEvent) &&
          !isUndefined(currentEvent.fields['Activity Monitor Status'])
            ? currentEvent.fields['Activity Monitor Status']
                .trim()
                .toLowerCase()
                .replace(/\s/gm, '_')
            : modStatus
        }
        showUpcoming={showUpcoming ? 'no-event' : 'current-event'}
        currentMonth={now.format('MMMM')}
        currentDate={now.format('Do')}
        smallFont={fontTest}
      />
    </div>
  );
}
