import * as THREE from 'three';

import defaultSwoosh from 'url:/src/touchpoints/live/lib/assets/swoosh.png';

export default class LLSTemplate {
  constructor (datas, canvas, renderer, { vert, frag }, name, gui) {
    this.name = name;
    this.datas = datas;

    this.refWidth = 1620;
    this.refHeight = 1080;

    if (typeof this.preInit === 'function') {
      this.preInit();
    }

    this.setupCanvas(canvas);
    this.setupRenderer(renderer);

    this.screenRatio = {
      x: (this.canvas.clientWidth / this.refWidth),
      y: (this.canvas.clientHeight / this.refHeight)
    };

    this.setupUniforms();
    this.setupTextures();
    this.setupShader(vert, frag);

    this.setupQuad();

    window.addEventListener('resize', this.onWindowResize.bind(this), false);

    gui && this.createGUI(gui);

    this.running = false;
  }

  setupCanvas (canvas) {
    this.canvas = canvas;
  }

  setupRenderer (renderer) {
    this.renderer = renderer;

    this.camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 1, 10);

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xFFFFFF);

    this.clock = new THREE.Clock();
  }

  setupUniforms () {}
  setupTextures () {}

  setupShader (vert, frag) {
    const screen = new THREE.Vector2(
      this.canvas.clientWidth,
      this.canvas.clientHeight
    );
    this.uniforms.res = new THREE.Uniform(screen);

    this.uniforms.screenRatio = new THREE.Uniform(new THREE.Vector2(
      this.screenRatio.x,
      this.screenRatio.y
    ));

    this.shader = new THREE.ShaderMaterial({
      uniforms: this.uniforms,
      vertexShader: vert,
      fragmentShader: frag,
    });
  }

  setupQuad () {
    this.quad = new THREE.Mesh(new THREE.PlaneGeometry(2, 2), this.shader);
    this.quad.position.set(0, 0, -2);
    this.scene.add(this.quad);
  }

  createTexture ({ src, key, pos, dim, offset, pixel, zoom, zoomVF, opacity }, pristine=true) {
    const img = new Image();

    const texture = new THREE.Texture(img);
    // texture.wrapS = THREE.RepeatWrapping;
    // texture.wrapT = THREE.RepeatWrapping;
    texture.encoding = THREE.sRGBEncoding;
    texture.minFilter = THREE.LinearMipmapLinearFilter;
    texture.magFilter = THREE.LinearFilter;
    texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();

    img.src = src ?? defaultSwoosh;
    img.crossOrigin = 'anonymous';
    img.onload = () => {
      this.uniforms[key].value = texture;
      this.uniforms[key + 'Res'].value.x = texture.image.width;
      this.uniforms[key + 'Res'].value.y = texture.image.height;
      texture.needsUpdate = true;
    }

    if (pristine) {
      this.uniforms[key] = new THREE.Uniform(texture);
      this.uniforms[key + 'Res'] = new THREE.Uniform(new THREE.Vector2(
        texture.image.width,
        texture.image.height
      ));
      if (pos !== undefined) this.uniforms[key + 'Pos'] = new THREE.Uniform(pos);
      if (dim !== undefined) this.uniforms[key + 'Dim'] = new THREE.Uniform(dim);
      if (offset !== undefined) this.uniforms[key + 'Off'] = new THREE.Uniform(offset);
      if (pixel !== undefined) this.uniforms[key + 'Pix'] = new THREE.Uniform(pixel);
      if (zoom !== undefined) this.uniforms[key + 'Zoom'] = new THREE.Uniform(zoom);
      if (zoomVF !== undefined) this.uniforms[key + 'ZoomVF'] = new THREE.Uniform(zoomVF);
      if (opacity !== undefined) this.uniforms[key + 'Opacity'] = new THREE.Uniform(opacity);
    }
  }

  createGUI (gui) {
    this.gui = gui.addFolder({
      title: `${this.name} opts`,
      hidden: true
    });

    this.guiModel = {
      timeScale: 1,
    };
    this.gui.addInput(this.guiModel, 'timeScale', { min: 0, max: 3, step: 0.01 }).on('change', ({ value }) => {
      this.timeline.timeScale(value);
    });

    if (typeof this.setupGUI === 'function') {
      this.setupGUI();
    }
  }

  relWidth (w) {
    return w * (this.canvas.clientWidth / (this.refWidth || 1620));
  }
  relHeight (h) {
    return h * (this.canvas.clientHeight / (this.refHeight || 1080));
  }

  setupTL ({ createTL }, onEnd, timeScale=1) {
    this.timeline = createTL(this.start.bind(this), this.stop.bind(this), onEnd, this.uniforms);
    this.timeline.timeScale(timeScale);
  }

  regiterImageSwitch (url, texName, time) {
    this.timeline.add(() => {
      this.createTexture({ src: url, key: texName }, false);
    }, time);
  }

  regiterTimeLineEvent (event, time) {
    this.timeline.add(() => { event(); }, time);
  }

  render () {
    // this.fpsGraph.begin();

    if (this.running) {
      const dt = this.clock.getDelta();
      this.uniforms.time.value += dt;

      if (typeof this.update === 'function') {
        this.update();
      }
    }

    this.renderer.render(this.scene, this.camera);

    // this.fpsGraph.end();
    this.raf = requestAnimationFrame(this.render.bind(this));
  }

  onKeyPress (e) {
  }

  onWindowResize () {
    this.camera.updateProjectionMatrix();

    this.screenRatio = {
      x: (this.canvas.clientWidth / this.refWidth),
      y: (this.canvas.clientHeight / this.refHeight)
    };

    this.uniforms.screenRatio.x = this.screenRatio.x;
    this.uniforms.screenRatio.y = this.screenRatio.y;

    this.uniforms.res.value.x = this.canvas.clientWidth;
    this.uniforms.res.value.y = this.canvas.clientHeight;
  }

  start (keyframe) {
    if (typeof this.preStart === 'function') this.preStart();
    console.log(`${this.name} start.`);
    window.cancelAnimationFrame(this.raf);
    this.raf = requestAnimationFrame(this.render.bind(this));
    this.running = true;
    this.timeline.play(this.keyframes?.[keyframe] ?? 0);
  }

  resume () {
    if (typeof this.preResume === 'function') this.preResume();
    this.running = true;
    this.timeline.resume();
    this.clock.start();
  }

  pause () {
    if (typeof this.prePause === 'function') this.prePause();
    this.running = false;
    this.timeline.pause();
    this.clock.stop();
  }

  stop () {
    if (typeof this.preStop === 'function') this.preStop();
    window.cancelAnimationFrame(this.raf);
    this.running = false;
  }
}
