import gsap from 'gsap';

export default function createTL (scenes, onComplete, onStart, id) {
  const tl = gsap.timeline({
    onComplete,
    paused: true,
    onStart: onStart,
    defaults: { ease: 'power1.inOut' }
  });

  const uniforms = scenes.map(scene => scene.userData.shader.uniforms);

  console.log('id', id);

  const gc = `#${id}.ca-LTCC .grid-container`;

  const cgi = `#${id}.ca-LTCC .grid-container .grid-item`;
  const cgi1 = `#${id}.ca-LTCC .grid-container .grid-item-1`;
  const cgi2 = `#${id}.ca-LTCC .grid-container .grid-item-2`;
  const cgi3 = `#${id}.ca-LTCC .grid-container .grid-item-3`;
  const cgi4 = `#${id}.ca-LTCC .grid-container .grid-item-4`;

  const w = window.innerWidth;
  const h = window.innerHeight;

  // INITIAL STATES
  tl.add(() => {
    gsap.set(uniforms[0].pixels, { value: 120 });
    gsap.set(uniforms[1].pixels, { value: 120 });
    gsap.set(uniforms[2].pixels, { value: 120 });
    gsap.set(uniforms[3].pixels, { value: 120 });

    gsap.set(uniforms[0].opacity, { value: 0 });
    gsap.set(uniforms[3].opacity, { value: 1 });

    gsap.set(gc, { opacity: 0 });
    gsap.set(gc, { scale: 4 });
    gsap.set(gc, {
      x: (w * 1.333),
      y: (h * 1.4),
    });

    console.log('CA LTCC');
  }, 0);

  // tl.timeScale(0.5);

  // ANIMATIONS
  tl.to(gc, { opacity: 1, duration: 0.2 }, 0.1);
  tl.to(uniforms[0].opacity, { value: 1, duration: 0.5 }, 0.5);
  tl.to(uniforms[0].pixels, { value: 1, duration: 1 }, 0.5);

  tl.to(gc, { scale: 3.8, repeat: 1, yoyo: true, duration: 0.5 }, 2.5);
  tl.to(cgi1, { x: '-=200px', repeat: 1, yoyo: true, duration: 1 }, 2.75);
  tl.to(uniforms[0].pixels, { value: 120, duration: 0.5 }, 2.75);
  tl.to(cgi1, { scale: 1.33, duration: 0.5 }, 2.75);

  tl.to(gc, {
    duration: 0.5,
    x: -(w * 1.333),
    y: (h * 1.4),
  }, 3);
  tl.to(uniforms[1].pixels, { value: 1, duration: 1 }, 3);

  tl.to(gc, { scale: 3.8, repeat: 1, yoyo: true, duration: 0.5 }, 4.5);
  tl.to(cgi2, { y: '-=200px', repeat: 1, yoyo: true, duration: 1 }, 4.75);
  tl.to(uniforms[1].pixels, { value: 120, duration: 0.5 }, 4.75);
  tl.to(cgi2, { scale: 1.33, duration: 0.5 }, 4.75);

  tl.to(gc, {
    duration: 0.5,
    x: -(w * 1.333),
    y: -(h * 0.4),
  }, 5);
  tl.to(uniforms[2].pixels, { value: 1, duration: 1 }, 5);

  tl.to(gc, { scale: 3.8, repeat: 1, yoyo: true, duration: 0.5 }, 6.5);
  tl.to(cgi3, { x: '+=200px', repeat: 1, yoyo: true, duration: 1 }, 6.75);
  tl.to(uniforms[2].pixels, { value: 120, duration: 0.5 }, 6.75);
  tl.to(cgi3, { scale: 1.33, duration: 0.5 }, 6.75);

  tl.to(gc, {
    duration: 0.5,
    x: (w * 1.333),
    y: -(h * 0.4),
  }, 7);
  tl.to(uniforms[3].pixels, { value: 1, duration: 1 }, 7);

  tl.to(gc, { opacity: 0, duration: 0.2 }, 9.5);
  tl.to(uniforms[3].opacity, { value: 0, duration: 0.5 }, 9.5);
  tl.add(() => {}, 10);

  return tl;
}
