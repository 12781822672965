import Line from './line';

export default function MixedText(content) {
    const that = {};

    that.text = content.text;
    that.width = content.width * 2;
    that.height = content.height * 2;
    that.subheadings = content.subheadings;
    that.isCentered = that.subheadings ? false : true;
    that.containsKorean = content.constainsKorean;
    // Text Style
    that.fontStyle = content.style?.toString();
    that.langHeight = content.langHeight || 3;
    that.fontsCollection = ['NIKE_FUTURA', 'HelveticaNeue', 'NeuePixelGrotesk'];
    that.fontsCollectionChinese = ['NIKE_FUTURA', 'HelveticaNeue', 'Noto'];
    // Text Metrics
    that.maxLineLength = content.maxLineLength || 12;
    that.maxDirectoryLength = content.maxLineLength || 30;
    that.lines = [];
    that.paragraphHeightCount = 0;
    that.isDirectory = !!content?.isDirectory;
    that.isDirectorySize = that.isDirectory ? 30 : 48;
    that.isDirectorySizeMax = that.isDirectory ? 100 : 105;


    const calculateFontSize = (text, minSize, maxSize, maxLineLength) => {
        const lerp = (x, y, a) => x * (1 - a) + y * a;
        const textArray = text.split('\n');
        const longest = textArray.reduce((a, b) => a.length < b.length ? b : a, "");
        return lerp(maxSize * 1.6, minSize * 1.6, longest.length / maxLineLength);
    }

    const getLineHeight = ((fontStyle) => {
        switch (fontStyle) {
            case 'helvetica':
                return that.isDirectory ? 1.2 : 0.95;
            case 'mixed':
            case 'futura':
                return that.containsKorean ? 1 : 0.86;
            case 'pixel':
                return 1;
            case 'NeuePixelGrotesk':
                return 1.1;
            default:
                return 1.1;
        }
    });

    const getMinSize = ((fontStyle) => {
        switch (fontStyle) {
            case 'helvetica':
                return that.isDirectorySize;
            case 'mixed':
                return 50;
            case 'pixel':
                return 35;
            case 'futura':
                return 50
            case 'pixel-subheading':
                return 35;
            default:
                return 25;
        }
    });

    const getMaxSize = ((fontStyle) => {
        switch (fontStyle) {
            case 'helvetica':
                return that.isDirectorySizeMax;
            case 'mixed':
                return 108;
            case 'pixel-subheading':
                return 45;
            case 'pixel':
                return 90;
            case 'futura':
                return 100;
            default:
                return 70;
        }
    });

    const getChinese = ((text) => {
        const chineseRegex = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/g;
        let isChinese = chineseRegex.test(text);
        return isChinese;
    });

    const getMixedFont = ((index, line) => {
        return getChinese(line) ? that.fontsCollectionChinese[index % that.fontsCollectionChinese.length] : that.fontsCollection[index % that.fontsCollection.length];
    });

    const getFontStyle = ((fontStyle) => {
        switch (fontStyle) {
            case 'helvetica':
                return 'HelveticaNeue';
            case 'pixel':
                return 'NeuePixelGrotesk';
            case 'pixel-subheading':
                return 'NeuePixelGrotesk';
            case 'noto':
                return 'NOTO_SANS';
            default:
                return 'NIKE_FUTURA';
        }
    });

    const createCanvas = () => {
        that.canvas = document.createElement('canvas');
    };

    const setCanvasSize = () => {
        that.canvas.width = that.width;
        that.canvas.height = that.height;
    };

    const setCanvasStyle = () => {
        that.ctx = that.canvas.getContext('2d');
        that.ctx.fillStyle = 'white';
        that.ctx.fillRect(0, 0, that.width, that.height);
    };

    const createParagraphs = (heading, subheading) => {
        const offset = that.isDirectory ? 0 : -150;
        const offset2 = that.isDirectory ? 5400 : -500;
        if (that.subheadings) {
            that.subheadings[0] = that.subheadings[0] ? that.subheadings[0] : " ";
            that.subheadings[1] = that.subheadings[1] ? that.subheadings[1] : " ";
            let fontSizeHeading00 = getFontSize(that.text, that.fontStyle, that.maxLineLength);
            let fontSizeHeading01 = that.isDirectory ? getFontSize(that.subheadings[0], 'helvetica', that.maxDirectoryLength) : 200;
            let fontSizeHeading02 = getFontSize(that.subheadings[1], 'pixel-subheading',  that.maxLineLength);
            let size00 = calculateSizes(that.text, fontSizeHeading00, that.fontStyle) + offset2;
            let size01 = calculateSizes(that.subheadings[0], fontSizeHeading01, 'helvetica') + offset;
            let size02 = calculateSizes(that.subheadings[1], fontSizeHeading02, 'pixel-subheading') - 500;
            let totalSize = size00 + size01 + size02;
            let margin = ((that.height * 0.85) - (totalSize)) / 2;

            createLines(that.text, that.fontStyle, fontSizeHeading00, false, that.fontStyle === 'noto' ? 180 : getFontResponsiveValue(50, 180), size00);
            createLines(that.subheadings[0], 'helvetica', fontSizeHeading01, false, size00 + (margin), size01, true);
            createLines(that.subheadings[1], 'pixel-subheading', fontSizeHeading02, false, size00 + size01 + (margin * 2), size02);
            render();
        } else {
            let fontSizeHeading00 = getFontSize(that.text, that.fontStyle,  that.maxLineLength);
            let size00 = calculateSizes(that.text, fontSizeHeading00, that.fontStyle);
            let fontSizeHeadingHorizontal = window.innerWidth > window.innerHeight ? Math.min(Math.max(parseInt(fontSizeHeading00), 100), 380) : fontSizeHeading00;
            let sizeHorizontal = window.innerWidth > window.innerHeight ? size00 - fontSizeHeadingHorizontal : size00;
            createLines(that.text, that.fontStyle, fontSizeHeadingHorizontal, true, false, sizeHorizontal);
            render();
        }
    };

    const getFontResponsiveValue = (minSize, maxSize) => {
        let minWindowSize = 350
        let maxWindowSize = 2000
        let diffRange = maxWindowSize - minWindowSize
        let diffSize = maxSize - minSize
        return Math.round(minSize + diffSize * ((window.innerWidth - minWindowSize) / diffRange))
    }

    const getFontSize = (text, style, maxLineLength) => {
        let minFontSize = getMinSize(style);
        let maxFontSize = getMaxSize(style);
        return calculateFontSize(text, minFontSize, maxFontSize, maxLineLength) * getFontResponsiveValue(1.6, 4);
    }

    const calculateSizes = (text, fontSize, style) => {
        let lineHeight = getLineHeight(style);
        let lineDistance = fontSize * lineHeight;
        const lines = text.split('\n');
        return fontSize + (lines.length * lineDistance);
    }

    const createLines = (text, style, fontSize, isCentered, verticalDistance, size, subheading = false) => {
        const lines = text.split('\n');
        let lineHeight = getLineHeight(style);
        let lineDistance = fontSize * lineHeight;
        let verticalAlign = isCentered ? ((that.height / (window.innerHeight > window.innerWidth ? 2 : getChinese(text) ? 1.75 : 2)) - size / 2) : verticalDistance ? verticalDistance + 40 : getFontResponsiveValue(70, 120);
        lines.forEach((line, index) => {
            let linesStyle = style === 'mixed' ? getMixedFont(index, line) : getFontStyle(style);
            let baseline = (fontSize + (index * lineDistance) + verticalAlign);
            let fontBasedLine = fontSize;
            if (isCentered) {
                baseline -= lineDistance / 4;
                if (index === 2) baseline *= 1.01;
            } else {
                if(that.isDirectory && subheading) {
                    fontBasedLine *= .55;
                    baseline *= .5;
                } else {
                    if (lines.length > 3) {
                        if ((index === 1 || index === 2) && getChinese(line)) {
                            fontBasedLine *= .9;
                            baseline *= .91;
                        } else {
                            style === 'helvetica' ? baseline *= .75 : baseline *= .9;
                        }
                    } else if (getChinese(line)) {
                        if(!getChinese(lines[0]) && !getChinese(lines[2]) && style !== 'pixel-subheading'){
                            baseline *= .896;
                        } else {
                            baseline *= .91;
                        }
                        fontBasedLine *= .9;
                    } else if (lines.length === 3 && (style === 'pixel-subheading' || getChinese(lines[0]))) {
                        baseline *= .912;
                    } else if (lines.length === 2 && (getChinese(lines[1]) || getChinese(lines[0]))) {
                        baseline *= .912;
                    } else {
                        baseline *= .9;
                    }
                }
            }
            console.log(`line text = ${line}, fontBasedLine = ${fontBasedLine}, baseline = ${baseline}`);
            that.lines.push(new Line(that.ctx, line.trim(), fontBasedLine, baseline, that.width, linesStyle));
        })
        that.currentTickerIndex = that.lines.length - 1;
    }

    const render = () => {
        setCanvasStyle();
        that.lines.forEach((line, index) => {
            line.render(index, that.isCentered);
        })
        requestAnimationFrame(render);
    }

    const generateTexture = () => {
        return that.canvas.toDataURL("image/png");
    }

    createCanvas();
    setCanvasSize();
    setCanvasStyle();
    createParagraphs();

    return generateTexture();
}

