import gsap from 'gsap';

import Word from './word';

export default class Line {
    constructor (ctx, text, fontSize, baseline, width, fontStyle) {
        this.ctx = ctx;
        this.text = text.toUpperCase();
        this.fontSize = fontSize;
        this.baseline = baseline;
        this.width = width;
        this.fontStyle = fontStyle;
        this.timing = 0;
        this.isChinese = this.getChinese(text);
        this.setWords();
        this.tm = this.ctx.measureText(this.text);
        this.currentTickerIndex = this.words.length - 1;
        this.margin = this.getFontResponsiveValue(20, 150);
    
    }

    getChinese(text) {
        const chineseRegex = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/g;
        let isChinese = chineseRegex.test(text);
        return isChinese;
    };

    setWords() {
        const words = this.text.toUpperCase().split(' ');
        this.words = words.map(
            (w, i) => new Word(w, i, words.length, this.ctx, this.fontStyle, this.isChinese),
        );
        let cursor = 0;
        this.words.forEach((word, i) => {
            word.setSize(this.fontSize);
            word.setBaseline(this.baseline);
            word.draw(this.ctx, cursor);  
            if(this.isChinese) {
                
                word.setChinese(true);   
            }
                
        })
    }

    getFontResponsiveValue(minSize, maxSize) {
        let minWindowSize = 100
        let maxWindowSize = 2000
        let diffRange = maxWindowSize - minWindowSize
        let diffSize = maxSize - minSize
        return Math.round(minSize + diffSize * ((window.innerWidth - minWindowSize) / diffRange))
    }

    render(lineIndex, isCentered) {
        let cursor = isCentered ? (this.width - this.tm.width)/ 2 -  this.fontSize * 0.24 : this.margin;
        this.words.forEach((word) => {
            if(word.word === 'N/') {
                cursor = 0;
            } else {
                cursor += isCentered ? this.fontSize * 0.25 : this.margin - 20;
                cursor = word.draw(cursor);
            }
        })
    }
}
