import gsap from 'gsap';
import { ScrambleTextPlugin } from '/src/touchpoints/live/lib/js/vendors/ScrambleTextPlugin.min.js';

gsap.registerPlugin(ScrambleTextPlugin);

export default function createTL (scenes, onComplete, onStart) {
  const tl = gsap.timeline({
    onComplete,
    paused: true,
    onStart: onStart,
    defaults: { ease: 'power2.inOut' }
  });

  const uniforms = scenes.map(scene => scene.userData.shader.uniforms);

  const cg = '.ca-grid .content-grid';
  const bgg = '.ca-grid .background-grid';
  const ffi = '.ca-grid .freeflow-item';
  const cgi = '.ca-grid .content-grid .grid-item';

  const cgi1 = '.ca-grid .content-grid .grid-item-1';
  const cgi2 = '.ca-grid .content-grid .grid-item-2';
  const cgi3 = '.ca-grid .content-grid .grid-item-3';

  const w = window.innerWidth;
  const h = window.innerHeight;

  // INITIAL STATES
  tl.add(() => {
    gsap.set(bgg, { x: 0, scale: 1.0 });
    gsap.set(cg, { x: 0, scale: 1.0 });

    gsap.set(uniforms[0].mosaic, { value: 0 });
    gsap.set(uniforms[1].mosaic, { value: 0 });
    gsap.set(uniforms[2].mosaic, { value: 0 });
    gsap.set(uniforms[3].mosaic, { value: 0 });

    gsap.set(uniforms[0].pixels, { value: 60 });
    gsap.set(uniforms[1].pixels, { value: 60 });
    gsap.set(uniforms[2].pixels, { value: 60 });
    gsap.set(uniforms[3].pixels, { value: 40 });

    gsap.set(`${cgi1} .overlay`, { opacity: 0, scale: 0.8 });
    gsap.set(`${cgi2} .overlay`, { opacity: 0, scale: 0.8 });
    gsap.set(`${cgi3} .overlay`, { opacity: 0, scale: 0.8 });

    console.log('CA GRID');
  }, 0);

  // ANIMATIONS

  tl.to(`${cgi1} .overlay`, { scale: 1, duration: 1 }, 0.);
  tl.to(`${cgi1} .overlay`, { opacity: 1, duration: 0.5, repeat: 1, ease: 'steps(1)' }, 0.5);

  tl.to(`${cgi2} .overlay`, { scale: 1, duration: 1 }, 0.1);
  tl.to(`${cgi2} .overlay`, { opacity: 1, duration: 0.5, repeat: 1, ease: 'steps(1)' }, 0.6);

  tl.to(`${cgi3} .overlay`, { scale: 1, duration: 1 }, 0.15);
  tl.to(`${cgi3} .overlay`, { opacity: 1, duration: 0.5, repeat: 1, ease: 'steps(1)' }, 0.65);

  tl.to(uniforms[0].mosaic, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.1);
  tl.to(uniforms[1].mosaic, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.2);
  tl.to(uniforms[2].mosaic, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.3);

  tl.to(uniforms[0].pixels, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.1);
  tl.to(uniforms[1].pixels, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.2);
  tl.to(uniforms[2].pixels, { value: 1, duration: 1, ease: 'power0.easeNone' }, 0.3);


  tl.to(uniforms[1].zoom, { value: 1.25, duration: 1 }, 2);

  tl.to(uniforms[3].mosaic, { value: 1, duration: 1, ease: 'power0.easeNone' }, 2.5);
  tl.to(uniforms[3].pixels, { value: 1, duration: 1, ease: 'power0.easeNone' }, 2.5);

  tl.to(uniforms[0].zoom, { value: 1.25, duration: 1 }, 3);

  tl.to(uniforms[0].pixels, { value: 60, duration: 1, ease: 'power0.easeNone' }, 6);
  tl.to(uniforms[1].pixels, { value: 60, duration: 1, ease: 'power0.easeNone' }, 6);
  tl.to(uniforms[2].pixels, { value: 60, duration: 1, ease: 'power0.easeNone' }, 6);
  tl.to(uniforms[3].pixels, { value: 40, duration: 1, ease: 'power0.easeNone' }, 6.25);

  tl.to(uniforms[0].mosaic, { value: 0, duration: 1, ease: 'power0.easeNone' }, 6);
  tl.to(uniforms[1].mosaic, { value: 0, duration: 0.8, ease: 'power0.easeNone' }, 6.2);
  tl.to(uniforms[2].mosaic, { value: 0, duration: 0.5, ease: 'power0.easeNone' }, 6.33);
  tl.to(uniforms[3].mosaic, { value: 0, duration: 0.8, ease: 'power0.easeNone' }, 6.5);

  tl.to(`${cgi1} .overlay`, { scale: 0.9, duration: 0.2 }, 7);
  tl.to(`${cgi2} .overlay`, { scale: 0.9, duration: 0.2 }, 7);
  tl.to(`${cgi3} .overlay`, { scale: 0.9, duration: 0.2 }, 7);
  tl.to(cgi, { opacity: 0, duration: 0.2 }, 7);

  tl.add(() => {}, 8);

  return tl;
}
