const _ = require('lodash');
import * as Logger from '../utils/console';
/**
 * Remaps timing for both JSON sequences
 * and waitFrames
 */

export const timeRemap = (sequence, scale) => {
    let timeRemapped = _.cloneDeep(sequence);
    for (let frame = 0; frame < timeRemapped.length; frame++) {
        if (timeRemapped[frame].hasOwnProperty('duration')) {
            timeRemapped[frame].duration *= scale;
            Logger.log(timeRemapped[frame].duration)
        }
        if (timeRemapped[frame].hasOwnProperty('wait')) {
            timeRemapped[frame].wait *= scale;
            Logger.log(timeRemapped[frame].wait)
        }
    }
    return timeRemapped;
}

/**
 * Joins multiple sequences together
 *
 */
export const concatSequence = (sequences) => {
    let finalSequence = [];
    for (let seq of sequences) {
        finalSequence = finalSequence.concat(seq)
    }
    return finalSequence;
}

/**
 * Remaps dynamic chapter names
 *
 */
export const remapKeyframe = (sequence, mapping) => {

    const { append, assets } = mapping;

    // const appendId = mapping.hasOwnProperty('append');
    // const remapAssets = mapping.hasOwnProperty('assets');

    let clonedSequence = _.cloneDeep(sequence);
    for (let frame of clonedSequence) {

        if (append) {
            if (frame.hasOwnProperty("name")) {
                frame.name = `${frame.name}${mapping.append}`
            }
        }

        if (_.isArray(assets) && assets.length > 0) {
            Logger.highlight('REMAP ASSETS');
            let masks = frame.mask;

            for (let mask of masks) {

                if (mask.hasOwnProperty('asset')) {
                    const asset = parseInt(mask.asset);

                    if (assets[asset]) {
                        mask.asset = assets[asset].name;
                    } else {
                        mask.asset = assets[0].name;
                    }
                }
            }
        }
    }
    return clonedSequence;
};

/**
 * Detects if active keyframe
 *
 */
export const keyframeInfo = (name) => {

    let frameName = name;
    let frameId;
    let frameType;
    let chapterId;

    if (name != undefined) {
        if (name.indexOf("#") > -1) {
            frameName = name.split('#')[0];
            frameType = name.split('#')[1].split('.')[0];
            frameId = name.split('#')[1].split('.')[1];
            chapterId = `${frameType}.${frameId}`;
        }
    }


    return { frameName, frameId, frameType, chapterId }

}

/**
 * Detects if active keyframe
 *
 */
export const withinKeyframe = (keyframes, frameName) => {


    if (frameName) {

        if (frameName.indexOf('*') > -1) {


            let normalizedName = frameName.split('*')[0];

            Logger.alert(`${frameName},${normalizedName}`)
            return keyframes.includes(normalizedName);
        } else {
            return keyframes.includes(frameName);
        }


    }
    return false;
}


/**
 * Maps Holds to Waits
 *
 */
export const mapHoldsToWaits = (sequence, holdFrames) => {
    const holds = concatSequence(holdFrames);
    let convertedWaits = [];
    holds.map(hold => {



        let index = 0;
        for (const frame of sequence) {

            if (frame.name) {
                if (frame.name.indexOf(hold.frame) > -1) {
                    if (index < sequence.length) {
                        Logger.highlight('ADD HOLD')
                        Logger.log(hold.frame, index)
                        convertedWaits.push({ frame: index + 1, wait: hold.hold })
                    }
                }
            }



            index++;
        }

        // let frames = sequence.filter((frame) => {
        //     return frame.name === hold.frame
        // });


        // let frameId = sequence.findIndex(frame => { return frame.name === hold.frame })

        // if (frameId > -1) {
        //     Logger.log('[addHold]', hold.frame, frameId)
        //     convertedWaits.push({ frame: frameId + 1, wait: hold.hold })
        // }

    })
    Logger.log('[addHold]', "CONVERTED HOLD TO WAIT", convertedWaits)
    return convertedWaits;
}

export const waitAll = (sequence, wait) => {
    let waits = [];


    let index = 0;
    for (const frame of sequence) {
        waits.push({ frame: index, wait: wait })
        index++;
    }
    return waits;
}


/**
 * Determines status for react components
 *
 */

export const KEYFRAME_STATUS = {
    STANDBY: 'standby',
    BEGIN: 'begin',
    PLAYING: 'playing',
    END: 'end',
    COMPLETE: 'complete'
}

export class KeyframeManager {

    _status = KEYFRAME_STATUS.STANDBY;
    _started = false;
    keyframes = [];

    constructor(keyframes) {
        this.keyframes = keyframes;
        this.keyframe_length = this.keyframes.length;
        Logger.log(this.keyframes)
    }

    isPlaying = (frame) => {

        const { index, name } = frame;

        const previous_status = this._status;

        const is_active_keyframe = this.keyframes.includes(name);

        let _isPlaying = (is_active_keyframe || (this._started && previous_status !== KEYFRAME_STATUS.COMPLETE));

        Logger.log(_isPlaying);

        return _isPlaying


    }
    status = (frame) => {

        const { index, name } = frame;

        const previous_status = this._status

        const is_active_keyframe = this.keyframes.includes(name)

        Logger.log('KEYFRAME', '» Frame', name, index, is_active_keyframe)

        if (index === 0) {
            this._started = false;
            this._status = KEYFRAME_STATUS.STANDBY
        }

        if (is_active_keyframe || (this._started && previous_status !== KEYFRAME_STATUS.COMPLETE)) {
            this._status = KEYFRAME_STATUS.PLAYING;
        }

        if (name === this.keyframes[0]) {
            this._started = true;
            this._status = KEYFRAME_STATUS.BEGIN;
        }

        if (name === this.keyframes[this.keyframe_length - 1]) {
            this._status = KEYFRAME_STATUS.END;
        }


        if (!is_active_keyframe && previous_status === KEYFRAME_STATUS.END && this._started) {
            this._status = KEYFRAME_STATUS.COMPLETE
        }


        Logger.log('KEYFRAME', 'Status', this._status)

        // TODO add playing actions
        return this._status;

    }


}

/**
 * Joins multiple sequences together
 *
 */
export const mergeFrame = (frame, withFrame) => {

    let mergedFrame = _.cloneDeep(frame);

    const mergeObjects = ['circle', 'horizontal', 'vertical', 'mask'];
    const mergeProps = ['delayVertical', 'delayHorizontal', 'horizontalDelayDirection', 'verticalDelayDirection'];

    mergeObjects.map(element => {
        if (frame.hasOwnProperty(element) && withFrame.hasOwnProperty(element)) {
            frame[element] = [...frame[element], ...withFrame[element]];
        }
    })

    mergeProps.map(prop => {
        if (withFrame.hasOwnProperty(prop)) {
            frame[prop] = withFrame[prop];
        }
    })

    return mergedFrame;
}
