import { shaderMaterial } from '@react-three/drei';
import { createDerivedMaterial } from 'troika-three-utils';
import * as THREE from 'three';
import { extend } from '@react-three/fiber';
const textureLoader = new THREE.TextureLoader();
const tickerMaterials: { [key: string]: THREE.MeshBasicMaterial } = {};
const the_canvas = document.createElement('canvas');

export const getGradientMaterial = (
  text: string,
  width: number,
  height: number,
  color1?: string,
  color2?: string,
) => {
  // create canvas
  the_canvas.width = width;
  the_canvas.height = height;

  // get context
  var context = the_canvas.getContext('2d');
  if (context) {
    // draw gradient
    context.rect(0, 0, width, height);
    var gradient = context.createLinearGradient(0, 0, width, 0);
    gradient.addColorStop(0, color1 ?? 'white'); // light blue
    gradient.addColorStop(1, color2 ?? 'black'); // dark blue
    context.fillStyle = gradient;
    context.fillRect(0, 0, width, height);
  }

  const texture = new THREE.Texture(the_canvas);
  texture.repeat.set(1, 1);
  texture.needsUpdate = true; // important!
  return texture;
};

const getTickerMaterial = (
  idx: number,
  url: string | null,
  color: THREE.Color | undefined,
) => {
  const key = `${idx}-${url || ''}-${color?.getHexString() || ''}`;
  if (tickerMaterials[key]) return tickerMaterials[key];
  const texture = textureLoader.load(url);
  texture.minFilter = THREE.LinearMipMapLinearFilter;
  texture.magFilter = THREE.LinearFilter;
  tickerMaterials[key] = new THREE.MeshBasicMaterial({
    color: color,
    side: THREE.FrontSide,
    transparent: true,
    map: url ? texture : null,
  });
  return tickerMaterials[key];
};

const getTickerTextMaterial = () =>
  new THREE.MeshBasicMaterial({
    side: THREE.FrontSide,
    transparent: true,
  });

export const customMaterial = (
  radius: number,
  sectorLength: number,
  angleOffset: number,
  sector: number,
  idx: number,
  squash: number = 1,
  url: string | null = null,
  color?: THREE.Color,
) =>
  createDerivedMaterial(getTickerMaterial(idx, url, color), {
    uniforms: {
      sectorLength: { value: sectorLength },
      radius: { value: radius },
      sector: { value: sector },
      angleOffset: { value: angleOffset },
      idx: { value: idx },
      squash: { value: squash },
    },
    vertexDefs: `
      uniform float sectorLength;
      uniform float radius;
      uniform float sector;
      uniform float idx;
      uniform float squash;
      uniform float angleOffset;
      `,
    vertexTransform: `
      float scale = ((1.0  / sector) / sectorLength) * PI * 2.0;
      float theta = position.x * scale * squash;
      float r = radius;
      float r2 = r + position.y * scale * r;
      position.x = -cos(theta + (2.0 * PI * (idx/sector)) + angleOffset) * r2;
      position.y = sin(theta + (2.0 * PI * (idx/sector)) + angleOffset) * r2;
      `
  });

export const customTextMaterial = (
  radius: number,
  sectorLength: number,
  angleOffset: number,
  sector: number,
  idx: number,
  squash: number = 1,
  url: string | null = null,
  color?: THREE.Color,
) =>
  createDerivedMaterial(getTickerTextMaterial(), {
    uniforms: {
      sectorLength: { value: sectorLength },
      radius: { value: radius },
      sector: { value: sector },
      angleOffset: { value: angleOffset },
      idx: { value: idx },
      squash: { value: squash },
    },
    vertexDefs: `
        uniform float sectorLength;
        uniform float radius;
        uniform float sector;
        uniform float idx;
        uniform float squash;
        uniform float angleOffset;
        `,
    vertexTransform: `
        float scale = ((1.0  / sector) / sectorLength) * PI * 2.0;
        float theta = position.x * scale * squash;
        float r = radius;
        float r2 = r + position.y * scale * r;
        position.x = -cos(theta + (2.0 * PI * (idx/sector)) + angleOffset) * r2;
        position.y = sin(theta + (2.0 * PI * (idx/sector)) + angleOffset) * r2;
        `,
  });

export const ColorShiftMaterial = shaderMaterial(
  {
    offset: 0,
    direction: 1,
    invert: 1.0,
    vertical: false,
    resolution: new THREE.Vector3(window.innerWidth, window.innerHeight, 1),
  },
  // vertex shader
  /*glsl*/ `
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
  // fragment shader
  /*glsl*/ `
      uniform float offset;
      uniform vec3 resolution;
      uniform float direction;
      uniform float invert;
      uniform bool vertical; 
      varying vec2 vUv;
      
      float plot(vec2 st, float pct){
        return  smoothstep( pct-0.02, pct, st.y) -
                smoothstep( pct, pct+0.02, st.y);
      }
         
      void mainImage( out vec4 fragColor, in vec2 fragCoord ){
        vec2 uv = vUv;
        float y = step(offset,uv.x);
        vec3 color = vec3(y);
        float pct = plot(uv,y);
        color = (1.0-pct)*color+pct*vec3(0.0,1.0,0.0);
        if(direction == 1.0){
          if(invert == 0.0){
            fragColor = vec4(1.0 - color.r,1.0 -color.g,1.0 -color.b,1.0);
          } else {
            fragColor = vec4(color,1.0);
          }
        } else {
          if(invert == 0.0){
            fragColor = vec4(color,1.0);
          } else {
            fragColor = vec4(1.0 - color.r,1.0 -color.g,1.0 -color.b,1.0);
          }
        }
      }
      void main() {
        float offsetY = 0.0f;
        vec3 green = vec3(0.0,1.0,0.0);
        if(vertical){ 
          vec2 uv = vUv.xy/resolution.xy;
          if(direction == 1.0){
            if(invert == 0.0){
              float v = step( uv.y, 1. - offset);
              gl_FragDepth = v;
              gl_FragColor = vec4(vec3(v),1.0);
            } else {
              float v = step( 1. - offset, uv.y);
              gl_FragDepth = 1. - v;
              gl_FragColor = vec4(vec3(v),1.0);
            }
          } else {
            if(invert == 0.0){
              float v = step( offset, uv.y );
              gl_FragDepth = v;
              gl_FragColor = vec4(vec3(v),1.0);
            } else {
              float v = step( uv.y, offset );
              gl_FragDepth = 1. - v;
              gl_FragColor = vec4(vec3(v),1.0);
            }
          }
        } else {
          mainImage(gl_FragColor, gl_FragCoord.xy);
        }
      }
    `,
);

// declaratively
extend({ ColorShiftMaterial });
