if (module.hot) {
    module.hot.accept(() => {
      location.reload()
    })
  }
  
  import gsap from 'gsap';
  import Text from '../../../life365/L365-Text';
  import '../../../../styles/main.css';
  import '../../../life365/L365.scss';
  import '../../../../styles/universal-live-template.scss'


  class Intro {
    constructor (name, datas, data, onComplete, index) {
      this.id = `${name}-${index}`;
      this.name = name;
      this.datas = datas;
      this.wrapper = document.createElement('div');
      this.wrapper.id = this.id;
      this.onComplete = onComplete;
      this.timeline = gsap.timeline({ paused: true, onComplete: () => {
        this.onComplete();
        this.wrapper.style.display = 'none';
        this.wrapper.classList.remove('active');
      } });

      const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
      const heading = data?.title ?? {EN: 'SNKRS\nUPCOMING'}
      const top_right = data?.top_right ?? {EN: 'SNKRS UPCOMING'};
      const bottom_right = data?.bottom_right ?? {EN: `${monthNames[new Date().getMonth()]}`};

      this.chap = new Text(
        datas = {
          heading,
          top_right,
          bottom_right,
          duration: 4,
          type: 'TEXT',
          color_0: "rgb(255,0,0)",
          color_1: "rgb(0,0,0)",
          color_2: "rgb(255,255,255)",
          heading_style: "futura",
          index: 0,
          show_view_finder: true,
          theme: "SNKRS",
          use_swatches: true
          // isCatalog: true

      }, 5.5, 'EN', true);
      this.chap.preTexture('grid')
      this.timeline.add(() => {
        this.chap.start();
      });

      this.timeline.add(() => {
        this.onComplete();
      }, 5);
    
      this.timeline.add(() => {}, (10));
    }

      start () {
        this.wrapper.style.display = 'block';
        this.timeline?.seek(0);
        this.timeline.play();
        this.running = true;

      }
      stop () {
        this.running = false;
        this.timeline?.pause();
        this.timeline?.seek(0);
        this.wrapper.style.display = 'none';
      }
      pause () {
        this.running = false;
        this.timeline?.pause();
      }
      resume () {
        this.timeline?.resume();
        this.timeline?.seek(0);
        this.running = true;
      }

  }
  export default Intro;
  