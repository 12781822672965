import React, { useEffect, useState } from 'react';
// import Codification from '../Codification';
import Codification from '../../../../components/Codification';
import './PodText.scss';

const VisibleKeyframes = ['MainA', 'MainB', 'MainC']

const PodText = ({ text, grids, frame }) => {

	let startTimer = 0;

	const { index: frameIndex, name: frameName } = frame;

	const [dims, setDims] = useState({
		width: 0,
		height: 0,
		left: 0,
		top: 0,
		initial: true,
	});

	const [start, setStart] = useState(false)

	useEffect(() => {
		grids.masks[0].addCallback('TextPrimary', params => {
			setDims({
				top: params.y,
				left: params.x,
				width: params.width,
				height: params.height,
				initial:
					dims.initial === true ? dims.initial : params.width > 10,
			});
		});
	}, []);

	useEffect(() => {
		let _start = frameName === VisibleKeyframes[0] || frameName === VisibleKeyframes[2]
		clearTimeout(startTimer);
		startTimer = setTimeout(() => {
			setStart(_start)
		}, frameName === VisibleKeyframes[2] ? 500 : 1500);
	}, [frame]);

	return text ? (
		<div className="PodText" style={{ ...dims, overflow: 'hidden', position: 'absolute' }}>
			{VisibleKeyframes.includes(frameName) &&
				<div className={'PodText-container'} style={{ maxWidth: 800 }}>
					<Codification
						text={text}
						typography={{
							primary: true,
							typeStyle: { fontSize: '134px', lineHeight: '123px' },
							tag: 'span',
						}}
						characterSwitchAmount={6}
						characterNextTrigger={2}
						characterTimeout={30}
						start={start}
						reverse={frameName === VisibleKeyframes[2]}
					/>
				</div>
			}
		</div>
	) : (
		<div />
	);
};

export default PodText;
