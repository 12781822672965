import gsap from 'gsap';
import { ScrambleTextPlugin } from '/src/touchpoints/live/lib/js/vendors/ScrambleTextPlugin.min.js';

gsap.registerPlugin(ScrambleTextPlugin);


export default function createTL (scenes, onComplete, onStart, credits=[], galleryArray) {

  const tl = gsap.timeline({
    onComplete,
    paused: true,
    onStart: onStart,
    defaults: { ease: 'power2.inOut' }
  });

  let currentName = ''; 
  let currentTime = '  '; 
  const uniforms = scenes.map(scene => scene.userData.shader.uniforms);
  const w = window.innerWidth;
  const h = window.innerHeight;

  tl.add(() => {
    gsap.set(uniforms[0].rangeFinder.value, { x: 0, y: 0 });
    galleryArray.wrapper.style.clipPath = `ellipse(${window.innerWidth/4}px ${window.innerWidth/4}px at ${window.innerWidth/2}px ${window.innerHeight/2}px)`;
    galleryArray.renderer.domElement.style.clipPath = `ellipse(${window.innerWidth/4}px ${window.innerWidth/4}px at ${window.innerWidth/2}px ${window.innerHeight/2}px)`;
  }, 0);
  tl.fromTo(galleryArray.rangeFinder, {scale:0.14}, {scale: 0.14, duration: 0.2, ease: 'expo.inOut'}, 0)
  
  tl.to(galleryArray.wrapper, { ease: 'expo.Out', duration: 0.8, "clip-path": `ellipse(${window.innerWidth * 5}px ${window.innerWidth * 5}px at ${window.innerWidth/2}px ${window.innerHeight/2.1}px)`}, 0.2);
  tl.to(galleryArray.renderer.domElement, { ease: 'expo.Out', duration: 0.9, "clip-path": `ellipse(${window.innerWidth * 5}px ${window.innerWidth * 5}px at ${window.innerWidth/2}px ${window.innerHeight/2.1}px)`}, 0.2);
  tl.fromTo(galleryArray.rangeFinder, {scale:0.14}, {scale: 1, duration: 0.8, ease: 'expo.Out'}, 0.2)
  tl.fromTo(galleryArray.viewFinderMarks, { opacity: 0, scale: 0.95 }, {opacity: 1, scale: 1, duration: 0.25, ease: 'power0.easeNone'}, 0.4);   

    galleryArray.images.forEach((element, index) => {
    let assetTime = (4 * index);

    tl.set(uniforms[0].rangeFinder.value, { x: 0, onComplete: () => {
      galleryArray.updateTex(galleryArray.parent, index, false);
    }}, assetTime - 0.3);
    tl.fromTo(uniforms[0].pixels, {value: 1}, {value: 60, duration: 0.5, ease: 'power0.easeNone'}, assetTime);   
    if(index !== 0) {
     
      tl.fromTo(galleryArray.rangeFinder, {scale:0}, {scale: 1, duration: 1.5, ease: 'expo.inOut'}, assetTime)
    }
    if(galleryArray.snkrNameArray[index] !== currentName){
    tl.to(galleryArray.topCredit.querySelector('h2'), {
      duration: 1,
      opacity: 1,
      scrambleText: {
        text: galleryArray.snkrNameArray[index],
        chars: '             nike             ',
      }
    }, assetTime);
    currentName = galleryArray.snkrNameArray[index]; 
    }

    if(galleryArray.dropTime[index] !== currentTime){
      tl.to(galleryArray.bottomCredit.querySelector('h2'), {
        duration: 1,
        opacity: 1,
        scrambleText: {
          text: galleryArray.dropTime[index],
          chars: '             nike             ',
        }
      
      }, assetTime);
      currentTime = galleryArray.dropTime[index]; 
    }

    tl.fromTo(uniforms[0].rangeFinder.value,{x:0}, {x: ((document.documentElement.clientHeight * window.devicePixelRatio)), duration: 1.5, ease: 'expo.inOut', onComplete: () => {
      galleryArray.updateTex(galleryArray.parent, index, true);
    }}, assetTime);  
   
    tl.to(uniforms[0].pixels, { value: 1, duration: 1, ease: 'power0.easeNone'}, assetTime + 0.4)
  });


  tl.add(() => {}, galleryArray.images.length * 4);

  return tl;
}
