import {SIZES} from "../constants";

export default (trackHeight, {size= SIZES.WIDE, showCircle = true}) => {
  const circleLinePosition = trackHeight === 225 ? 21.5 : 22;

  if (size === SIZES.COMPACT) {
    return renderSnug(trackHeight, circleLinePosition, showCircle);
  }

  return [
    {
      insetBorder: true,
      duration: 1000,
      horizontal: [
        {
          drawFull: false,
          drawFromPosition: 71.8,
          startPosition: 31.25,
          drawToPosition: 87.35,
          delay: 1200,
        },
        {
          startPosition: 68.75,
          delay: 800,
          drawToPosition: 97.3,
          drawFull: false,
          drawFromPosition: 2.7,
        },
      ],
      vertical: [
        {
          startPosition: 2.7,
          drawFromPosition: 100,
          drawToPosition: 0,
          drawFull: false,
          name: 'Left',
        },
        {
          drawFromPosition: 100,
          drawFromLine: null,
          name: 'Circle Center',
          delay: 100,
          drawToPosition: 0,
          startPosition: 15,
          drawFull: false,
        },
        {
          startPosition: circleLinePosition,
          drawFull: false,
          delay: 200,
          segmentGroup: {
            segmentPositionEnd: 33.5,
            numLines: '4',
          },
          drawFromLine: null,
          name: 'Left Seg',
          drawFromPosition: 100,
          drawToPosition: 0,
        },
        {
          drawToPosition: 0,
          segmentGroup: {
            numLines: '4',
            segmentPositionEnd: 87.3,
          },
          drawFromPosition: 100,
          delay: 700,
          drawFull: false,
          drawFromLine: null,
          startPosition: 41,
          name: 'Right Seg',
        },
        {
          name: 'Right',
          delay: 1100,
          drawToPosition: 0,
          startPosition: 97.3,
          drawFromPosition: 100,
          drawFull: false,
        },
      ],
      circle: [
        {
          startPositionY: 50,
          startPositionX: 15,
          delay: 800,
          startBoundaryY: 37.5,
          drawFromAngle: 0,
          drawFull: false,
          clockwise: false,
          drawAngle: 270,
        },
      ],
      drawBorder: false,
      mask: [],
      verticalDelayDirection: 1,
    },
    {
      insetBorder: true,
      mask: [],
      horizontal: [
        {
          startPosition: 31.25,
          drawFromPosition: 71.8,
          drawFull: true,
          drawToPosition: 87.35,
          delay: 1200,
        },
        {
          drawFull: true,
          delay: 800,
          drawFromPosition: 2.7,
          drawToPosition: 97.3,
          startPosition: 68.75,
        },
      ],
      vertical: [
        {
          drawFull: true,
          drawToPosition: 0,
          drawFromPosition: 100,
          startPosition: 2.7,
          name: 'Left',
        },
        {
          drawFromPosition: 100,
          drawToPosition: 0,
          drawFromLine: null,
          drawFull: true,
          delay: 100,
          startPosition: 15,
          name: 'Circle Center',
        },
        {
          drawToPosition: 0,
          drawFromLine: null,
          name: 'Left Seg',
          delay: 200,
          startPosition: circleLinePosition,
          drawFull: true,
          segmentGroup: {
            segmentPositionEnd: 33.5,
            numLines: '4',
          },
          drawFromPosition: 100,
        },
        {
          drawToPosition: 0,
          drawFromLine: null,
          startPosition: 41,
          name: 'Right Seg',
          segmentGroup: {
            segmentPositionEnd: 87.3,
            numLines: '4',
          },
          delay: 700,
          drawFull: true,
          drawFromPosition: 100,
        },
        {
          name: 'Right',
          delay: 1100,
          drawFull: true,
          drawToPosition: 0,
          drawFromPosition: 100,
          startPosition: 97.3,
        },
      ],
      duration: 1000,
      drawBorder: false,
      circle: [
        {
          collapse: true,
          drawFull: true,
          drawAngle: 270,
          drawFromAngle: 90,
          startBoundaryY: 37.5,
          clockwise: true,
          startPositionY: 50,
          startPositionX: 15,
        },
        {
          drawFromAngle: 0,
          delay: 800,
          clockwise: false,
          startPositionX: 15,
          startPositionY: 50,
          drawAngle: 270,
          startBoundaryY: 37.5,
          drawFull: false,
        },
      ],
    },
    {
      mask: [],
      horizontal: [
        {
          drawToPosition: 87.35,
          drawFromPosition: 71.8,
          drawFull: true,
          startPosition: 31.25,
          delay: 1200,
        },
        {
          drawFull: true,
          drawToPosition: 97.3,
          drawFromPosition: 2.7,
          delay: 800,
          startPosition: 68.75,
        },
      ],
      circle: [
        {
          startPositionY: 50,
          drawFull: true,
          startBoundaryY: 37.5,
          drawAngle: 270,
          collapse: true,
          drawFromAngle: 90,
          startPositionX: 15,
          clockwise: true,
        },
        {
          startPositionY: 50,
          startPositionX: 15,
          startBoundaryY: 37.5,
          drawFromAngle: 0,
          delay: 800,
          clockwise: false,
          drawFull: false,
          drawAngle: 270,
        },
      ],
      insetBorder: true,
      drawBorder: false,
      duration: 1000,
      vertical: [
        {
          drawFromPosition: 100,
          startPosition: 2.7,
          name: 'Left',
          drawFull: true,
          drawToPosition: 0,
        },
        {
          drawFull: true,
          drawFromLine: null,
          drawFromPosition: 100,
          drawToPosition: 0,
          startPosition: 15,
          delay: 100,
          name: 'Circle Center',
        },
        {
          startPosition: circleLinePosition,
          name: 'Left Seg',
          drawFromLine: null,
          delay: 200,
          drawToPosition: 0,
          drawFull: true,
          drawFromPosition: 100,
          segmentGroup: {
            numLines: '4',
            segmentPositionEnd: 33.5,
          },
        },
        {
          drawFull: true,
          drawFromLine: null,
          startPosition: 41,
          drawFromPosition: 100,
          segmentGroup: {
            segmentPositionEnd: 87.3,
            numLines: '4',
          },
          drawToPosition: 0,
          name: 'Right Seg',
          delay: 700,
        },
        {
          name: 'Right',
          drawFull: true,
          drawFromPosition: 100,
          drawToPosition: 0,
          delay: 1100,
          startPosition: 97.3,
        },
      ],
    },
    {
      vertical: [
        {
          delay: 1000,
          startPosition: 2.7,
          name: 'Left',
          drawFull: true,
          drawFromPosition: 0,
          drawDirection: -1,
          drawToPosition: 100,
        },
        {
          drawDirection: -1,
          startPosition: 15,
          delay: 900,
          name: 'Circle Center',
          drawFull: true,
          drawToPosition: 100,
          drawFromPosition: 0,
        },
        {
          drawFromPosition: 0,
          drawDirection: -1,
          drawFull: true,
          delay: 500,
          name: 'Left Seg',
          drawToPosition: 100,
          startPosition: circleLinePosition,
          segmentGroup: {
            numLines: '4',
            segmentPositionEnd: 33.5,
          },
        },
        {
          drawFull: true,
          segmentGroup: {
            segmentPositionEnd: 87.3,
            numLines: '4',
          },
          drawToPosition: 100,
          delay: 100,
          name: 'Right Seg',
          startPosition: 41,
          drawDirection: -1,
          drawFromPosition: 0,
        },
        {
          startPosition: 97.3,
          drawFull: true,
          drawToPosition: 100,
          drawFromPosition: 0,
          drawDirection: -1,
          name: 'Right',
        },
      ],
      verticalDelayDirection: -1,
      insetBorder: true,
      mask: [],
      duration: 1000,
      horizontal: [
        {
          drawFromPosition: 87.35,
          drawDirection: -1,
          drawFull: true,
          delay: 0,
          drawToPosition: 71.8,
          startPosition: 31.25,
        },
        {
          drawFromPosition: 93.3,
          drawFull: true,
          startPosition: 68.75,
          duration: 1400,
          drawToPosition: 2.7,
          drawDirection: -1,
        },
      ],
      circle: [
        {
          startPositionX: 15,
          startPositionY: 50,
          collapse: true,
          clockwise: true,
          drawAngle: 270,
          startBoundaryY: 37.5,
          drawFull: true,
          drawFromAngle: 90,
        },
      ],
      drawBorder: false,
    },
  ];
};


function renderSnug(trackHeight, c, showCircle = true) {
  const circleLinePosition = 27.35;
  const circleAlpha = showCircle ? {} : {startAlpha: 0, endAlpha: 0};

  return [
    {
      insetBorder: true,
      duration: 1000,
      horizontal: [
        // {
        //   drawFull: false,
        //   drawFromPosition: 71.8,
        //   startPosition: 31.25,s
        //   drawToPosition: 87.35,
        //   delay: 1200,
        // },
        // {
        //   startPosition: 68.75,
        //   delay: 800,
        //   drawToPosition: 56.5,
        //   drawFull: false,
        //   drawFromPosition: 2.7,
        // },
      ],
      vertical: [
        // {
        //   startPosition: 2.7,
        //   drawFromPosition: 100,
        //   drawToPosition: 0,
        //   drawFull: false,
        //   name: 'Left',
        // },
        // {
        //   drawFromPosition: 100,
        //   drawFromLine: null,
        //   name: 'Circle Center',
        //   delay: 100,
        //   drawToPosition: 0,
        //   startPosition: 36,
        //   drawFull: false,
        // },
        // {
        //   startPosition: circleLinePosition,
        //   drawFull: false,
        //   delay: 200,
        //   segmentGroup: {
        //     segmentPositionEnd: 36,
        //     numLines: '2',
        //   },
        //   drawFromLine: null,
        //   name: 'Left Seg',
        //   drawFromPosition: 100,
        //   drawToPosition: 0,
        // },
        // {
        //   drawToPosition: 0,
        //   segmentGroup: {
        //     numLines: '3',
        //     segmentPositionEnd: 87.3,
        //   },
        //   drawFromPosition: 100,
        //   delay: 700,
        //   drawFull: false,
        //   drawFromLine: null,
        //   startPosition: 41,
        //   name: 'Right Seg',
        // },
        // {
        //   name: 'Right',
        //   delay: 1100,
        //   drawToPosition: 0,
        //   startPosition: 56.1,
        //   drawFromPosition: 100,
        //   drawFull: false,
        // },
      ],
      circle: [
        {
          startPositionY: 50,
          startPositionX: 36,
          delay: 800,
          startBoundaryY: 47,
          drawFromAngle: 0,
          drawFull: false,
          clockwise: false,
          drawAngle: 270,
          ...circleAlpha,
        },
      ],
      drawBorder: false,
      mask: [],
      verticalDelayDirection: 1,
    },
    {
      insetBorder: true,
      mask: [],
      horizontal: [
        // {
        //   startPosition: 31.25,
        //   drawFromPosition: 71.8,
        //   drawFull: true,
        //   drawToPosition: 87.35,
        //   delay: 1200,
        // },
        // {
        //   drawFull: true,
        //   delay: 800,
        //   drawFromPosition: 2.7,
        //   drawToPosition: 56.5,
        //   startPosition: 68.75,
        // },
      ],
      vertical: [
        // {
        //   drawFull: true,
        //   drawToPosition: 0,
        //   drawFromPosition: 100,
        //   startPosition: 2.7,
        //   name: 'Left',
        // },
        // {
        //   drawFromPosition: 100,
        //   drawToPosition: 0,
        //   drawFromLine: null,
        //   drawFull: true,
        //   delay: 100,
        //   startPosition: 36,
        //   name: 'Circle Center',
        //   debug: true,
        // },
        // {
        //   drawToPosition: 0,
        //   drawFromLine: null,
        //   name: 'Left Seg',
        //   delay: 200,
        //   startPosition: circleLinePosition,
        //   drawFull: true,
        //   segmentGroup: {
        //     segmentPositionEnd: 36,
        //     numLines: '2',
        //   },
        //   drawFromPosition: 100,
        // },
        // {
        //   drawToPosition: 0,
        //   drawFromLine: null,
        //   startPosition: 41,
        //   name: 'Right Seg',
        //   segmentGroup: {
        //     segmentPositionEnd: 87.3,
        //     numLines: '3',
        //   },
        //   delay: 700,
        //   drawFull: true,
        //   drawFromPosition: 100,
        // },
        // {
        //   name: 'Right',
        //   delay: 1100,
        //   drawFull: true,
        //   drawToPosition: 0,
        //   drawFromPosition: 100,
        //   startPosition: 56.1,
        // },
      ],
      duration: 1000,
      drawBorder: false,
      circle: [
        {
          collapse: true,
          drawFull: true,
          drawAngle: 270,
          drawFromAngle: 90,
          startBoundaryY: 47,
          clockwise: true,
          startPositionY: 50,
          startPositionX: 36,
          ...circleAlpha,
        },
        {
          drawFromAngle: 0,
          delay: 800,
          clockwise: false,
          drawAngle: 270,
          startBoundaryY: 47,
          drawFull: false,
          startPositionY: 50,
          startPositionX: 36,
          ...circleAlpha,
        },
      ],
    },
    {
      mask: [],
      horizontal: [
        // {
        //   drawToPosition: 87.35,
        //   drawFromPosition: 71.8,
        //   drawFull: true,
        //   startPosition: 31.25,
        //   delay: 1200,
        // },
        // {
        //   drawFull: true,
        //   drawToPosition: 56.5,
        //   drawFromPosition: 2.7,
        //   delay: 800,
        //   startPosition: 68.75,
        // },
      ],
      circle: [
        {
          startPositionX: 36,
          startPositionY: 50,
          drawFull: true,
          startBoundaryY: 47,
          drawAngle: 270,
          collapse: true,
          drawFromAngle: 90,
          clockwise: true,
          ...circleAlpha,
        },
        {
          startPositionX: 36,
          startPositionY: 50,
          startBoundaryY: 47,
          drawFromAngle: 0,
          delay: 800,
          clockwise: false,
          drawFull: false,
          drawAngle: 270,
          ...circleAlpha,
        },
      ],
      insetBorder: true,
      drawBorder: false,
      duration: 1000,
      vertical: [
        // {
        //   drawFromPosition: 100,
        //   startPosition: 2.7,
        //   name: 'Left',
        //   drawFull: true,
        //   drawToPosition: 0,
        // },
        // {
        //   drawFull: true,
        //   drawFromLine: null,
        //   drawFromPosition: 100,
        //   drawToPosition: 0,
        //   startPosition: 36,
        //   delay: 100,
        //   name: 'Circle Center',
        // },
        // {
        //   startPosition: circleLinePosition,
        //   name: 'Left Seg',
        //   drawFromLine: null,
        //   delay: 200,
        //   drawToPosition: 0,
        //   drawFull: true,
        //   drawFromPosition: 100,
        //   segmentGroup: {
        //     numLines: '2',
        //     segmentPositionEnd: 36,
        //   },
        // },
        // {
        //   drawFull: true,
        //   drawFromLine: null,
        //   startPosition: 41,
        //   drawFromPosition: 100,
        //   segmentGroup: {
        //     segmentPositionEnd: 87.3,
        //     numLines: '3',
        //   },
        //   drawToPosition: 0,
        //   name: 'Right Seg',
        //   delay: 700,
        // },
        // {
        //   name: 'Right',
        //   drawFull: true,
        //   drawFromPosition: 100,
        //   drawToPosition: 0,
        //   delay: 1100,
        //   startPosition: 56.1,
        // },
      ],
    },
    {
      vertical: [
        // {
        //   delay: 1000,
        //   startPosition: 2.7,
        //   name: 'Left',
        //   drawFull: true,
        //   drawFromPosition: 0,
        //   drawDirection: -1,
        //   drawToPosition: 100,
        // },
        // {
        //   drawDirection: -1,
        //   startPosition: 36,
        //   delay: 900,
        //   name: 'Circle Center',
        //   drawFull: true,
        //   drawToPosition: 100,
        //   drawFromPosition: 0,
        // },
        // {
        //   drawFromPosition: 0,
        //   drawDirection: -1,
        //   drawFull: true,
        //   delay: 500,
        //   name: 'Left Seg',
        //   drawToPosition: 100,
        //   startPosition: circleLinePosition,
        //   segmentGroup: {
        //     numLines: '2',
        //     segmentPositionEnd: 36,
        //   },
        // },
        // {
        //   drawFull: true,
        //   segmentGroup: {
        //     segmentPositionEnd: 87.3,
        //     numLines: '3',
        //   },
        //   drawToPosition: 100,
        //   delay: 100,
        //   name: 'Right Seg',
        //   startPosition: 41,
        //   drawDirection: -1,
        //   drawFromPosition: 0,
        // },
        // {
        //   startPosition: 56.1,
        //   drawFull: true,
        //   drawToPosition: 100,
        //   drawFromPosition: 0,
        //   drawDirection: -1,
        //   name: 'Right',
        // },
      ],
      verticalDelayDirection: -1,
      insetBorder: true,
      mask: [],
      duration: 1000,
      horizontal: [
        // {
        //   drawFromPosition: 87.35,
        //   drawDirection: -1,
        //   drawFull: true,
        //   delay: 0,
        //   drawToPosition: 71.8,
        //   startPosition: 31.25,
        // },
        // {
        //   drawFromPosition: 93.3,
        //   drawFull: true,
        //   startPosition: 68.75,
        //   duration: 1400,
        //   drawToPosition: 2.7,
        //   drawDirection: -1,
        // },
      ],
      circle: [
        {
          startPositionX: 36,
          startPositionY: 50,
          collapse: true,
          clockwise: true,
          drawAngle: 270,
          startBoundaryY: 47,
          drawFull: true,
          drawFromAngle: 90,
          ...circleAlpha,
        },
      ],
      drawBorder: false,
    },
  ];
}