if (module.hot) {
    module.hot.accept(() => {
      location.reload()
    })
  }
  
  import * as THREE from 'three';
  
  import moment from 'moment';

  import markup from './snkr-markup';
  import vert from '/src/touchpoints/live/lib/glsl/shader.vert';
  import frag from './snkr.frag';
  import createTL from './snkr-timeline';
  import './snkr.scss';
  import Colorthief from 'colorthief';

  import verticalText from '/src/touchpoints/live/lib/js/components/VerticalText/VerticalText';
  import placedCanvas from '/src/touchpoints/live/lib/js/components/PlacedCanvas/PlacedCanvas';
  import viewFinderMarks from '/src/touchpoints/live/lib/js/components/ViewFinderMarks';
  import rangeFinder from '/src/touchpoints/live/lib/js/components/RangeFinder';
  import swatchList from '/src/touchpoints/live/lib/js/components/SwatchList/SwatchList';
  import defaultSwoosh from 'url:/src/touchpoints/live/lib/assets/swoosh.png';

  class Snkr {
    constructor (name, datas, onComplete, index, renderers, grid) {
      this.renderers = renderers;
      this.id = `${name}-${index}`;
      this.name = name;
      this.onComplete = onComplete;
      const width = window.innerWidth;
      const height = window.innerHeight;
      this.wrapper = document.createElement('div');
      this.wrapper.id = this.id;
      this.wrapper.classList.add('snikr-wrapper');
      this.wrapper.classList.add(`is-size-${window.SIZE_ID?.toLowerCase()}`);
      this.datas = datas;
      this.wrapper.classList.add('loading');
      const units = {
        SMALL: '15px',
        MEDIUM: '25px',
        LARGE: '40px',
      }
  
      document.documentElement.style.setProperty('--lls-ca-closeup-unit', units[window.SIZE_ID]);
      const borderThicknesses = {
        SMALL: '1px',
        MEDIUM: '2px',
        LARGE: '2px',
      }
      document.documentElement.style.setProperty('--lls-ca-closeup-border-thick', borderThicknesses[window.SIZE_ID]);
  
      if (width < height) {
        this.isVertical = true;
        this.wrapper.classList.add('is-vertical');
      }
      this.wrapper.innerHTML += markup;
      document.body.appendChild(this.wrapper);
  
      this.renderer = this.createRenderer('ca-closeup-main-canvas', renderers.front);
  
      this.colors = localStorage.snkrCalColors ? JSON.parse(localStorage.snkrCalColors) : [];
      // Adding html elements
      let viewFinderContent =  {
        size: 150,
        height: 100,
        stroke: '3px'
      }
      this.viewFinderMarks = viewFinderMarks(viewFinderContent);
      this.wrapper.appendChild(this.viewFinderMarks);

      let rangeFinderContent =  {
        size: `${window.innerHeight}`,
        stroke: 10
      }
      this.rangeFinder = rangeFinder(rangeFinderContent)
      this.rangeFinder.style.width = `${window.innerHeight * 2}px`
      this.rangeFinder.style.height = `${window.innerHeight * 2}px`
      this.wrapper.appendChild(this.rangeFinder);
      this.creditsTexts = [];

      this.currentSnicker = 0;
      this.currentImage = 0;
      this.maxSnickers = this.datas.length % 3 === 0 ? 3 : this.datas.length % 2 === 0 ? 2 : 1;
      this.maxImages = 3;
      this.imageGalleryArray = [];
      this.nameGalleryArray = [];
      this.dropTimeArray = [];

      let swatchListContent =  {
        size: 40,
          groupSize: 2,
          swatches: [
            {
              color: this.colors.length > 0 ? this.rgbToHex(this.colors[this.currentSnicker][0][0],this.colors[this.currentSnicker][0][1], this.colors[this.currentSnicker][0][2]) : 'black' 
            }, {
              color: 'white'
            }, {
              color:  this.colors.length > 0 ? this.rgbToHex(this.colors[this.currentSnicker][2][0],this.colors[this.currentSnicker][2][1], this.colors[this.currentSnicker][2][2]) : 'red'
            },
            {
              color: this.colors.length > 0 ? this.rgbToHex(this.colors[this.currentSnicker][3][0],this.colors[this.currentSnicker][3][1], this.colors[this.currentSnicker][3][2]) :  'black'
            }
          ]
      }

      this.swatchList = swatchList(swatchListContent);
      this.wrapper.appendChild(this.swatchList);
      this.wrapper.style.clipPath = `ellipse(${window.innerWidth/4}px ${window.innerWidth/4}px at ${window.innerWidth/2}px ${window.innerHeight/2}px)`;
      this.renderer.domElement.style.clipPath = `ellipse(${window.innerWidth/4}px ${window.innerWidth/4}px at ${window.innerWidth/2}px ${window.innerHeight/2}px)`;
      this.swatches = this.wrapper.querySelectorAll('.swatch-item');



      this.scenes = [];
      this.creditsTexts = [];      
      
      this.loadIndex = 1;
      this.running = false;
    

      const title = this.datas[this.currentSnicker]?.subtitle ?? this.datas[this.currentSnicker]?.title ?? '';
      this.createCreditsText(`.overlay`, `${title.substring(0, 40)}`, 'top');
      this.createCreditsText(`.overlay`,  '', 'bottom');
   }

   updateSwatchList(parent, indexSnicker) {
    clearInterval(parent.timer);
    let newTimeX = new Date(parent.dropTimeRawArray[indexSnicker]);
    let nowX = new Date();
    let diffDateX =  Math.round((newTimeX - nowX)/(1000*60*60*24));
    
    if(diffDateX <= 1 && diffDateX > -2) {
      parent.timer = setInterval(() => {
        parent.showRemaining(parent.dropTimeRawArray[indexSnicker], parent.timer);
      }, 10);
    }

    if(parent.colorArray?.length > 0){
      parent.swatches.forEach((item, index) => {
        if (index == 0) {
          item.style.backgroundColor = "white";
        } else {
          if(parent.colorArray[indexSnicker]) {
            item.style.backgroundColor = parent.colorArray[indexSnicker][index - 1];
          }
        }
      
      })
    }
   }

    componentToHex(c){
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    }

    rgbToHex(r, g, b){
      return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
    }

    formatTime(time){
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

      let newTime = new Date(time);
      let now = new Date();
      let diffDate =  Math.round((newTime - now)/(1000*60*60*24));
      let timeText = '';

      if(diffDate < -3) {
        timeText = '';
      } else {
        if(diffDate == -1 && diffDate > -2) {
          timeText = 'TODAY';
        } else if(diffDate <= 1 && diffDate > -1) {
          timeText = this.showRemaining(newTime, this.timer);
        } else if(diffDate > 1 && diffDate < 6) {
          let hours = newTime.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
          });
          timeText = `${days[newTime.getDay()]} ${hours}`;
        } else {
          let hours = newTime.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
          });
          timeText = `${monthNames[newTime.getMonth()]} ${newTime.getDate()}, ${hours}`;
        } 
      }
      return timeText;
    }


    updateAsset(parent, index, isImg) {
      parent.updateSwatchList(parent, index);
      parent.updateTexture( parent.imageGalleryArray[index], 0, isImg);
    }

    createNextArray() {
      this.imageGalleryArray = [];
      this.nameGalleryArray = [];
      this.dropTimeArray = [];
      this.colorArray = [];
      this.dropTimeRawArray = [];

      this.datas.forEach( (snkr, indexSnkr) => {
        if(indexSnkr >= this.currentSnicker && indexSnkr < (this.currentSnicker + this.maxSnickers)) {
          if(snkr.images.length >= this.maxImages) {
            snkr.images.forEach((image, index) => {
              if(index < this.maxImages) {
              this.imageGalleryArray.push(image.url);
              this.nameGalleryArray.push(snkr.subtitle);
              this.dropTimeArray.push(this.formatTime(snkr.startEntryDate));
              this.dropTimeRawArray.push(new Date(snkr.startEntryDate));
              let colors = []
                
              if(this.colors.length > 0) {
                for (let colorindex = 0; colorindex < 3; colorindex++) {
                 colors.push(this.rgbToHex(this.colors[indexSnkr][colorindex][0],this.colors[indexSnkr][colorindex][1], this.colors[indexSnkr][colorindex][2]));
                }
              }
                this.colorArray.push(colors);

              }
            })
          }
        }
      })



      this.insertMediaView(`.freeflow-item`, this.imageGalleryArray[0], 0);
      this.currentSnicker = this.currentSnicker + this.maxSnickers <= this.datas.length - 1 ? this.currentSnicker + this.maxSnickers : 0;
    }
    
    createCreditsText (selector, credits, position) {
      const vt = verticalText({ text: credits });
      vt.classList.add(`vt-${position}`);
      this.wrapper.querySelector(`${selector} .corner-${position}-right`).appendChild(vt);
      this.creditsTexts.push(vt);
    }
  
    createRenderer (className, renderer) {
      renderer.setClearColor(0xFFFFFF, 0);
      
      this.resizeRendererToDisplaySize(renderer);
      renderer.domElement.classList.add('renderer');
      return renderer;
    }
  
    resizeRendererToDisplaySize (renderer) {
      const canvas = renderer.domElement;
      const pixelRatio = window.devicePixelRatio;
      const width = window.innerWidth;
      const height = window.innerHeight;
      const needResize = canvas.width !== width || canvas.height !== height;
      if (needResize) {
        renderer.setSize(width, height, false);
      }
      return needResize;
    }

    updateRangeFinderTexture (url, index) {
      this.createTexture(url, (texture) => {
        texture.needsUpdate = true;
        this.scenes[0].userData.shader.uniforms.newTexture.value = texture;
      });
    }

    updateTexture (url, index, isImg) {
      this.createTexture(url, (texture) => {
        texture.needsUpdate = true;
        if(isImg) {
          this.scenes[index].userData.shader.uniforms.img.value = texture;
        } else {
          this.scenes[index].userData.shader.uniforms.newTexture.value = texture;
        }
        
        this.scenes[index].userData.shader.uniforms.img.value.x = texture.image.width;
        this.scenes[index].userData.shader.uniforms.img.value.y = texture.image.height;
      });
    }
  
    onStart () {
      this.renderers.front.domElement.style.display = 'block';
      this.wrapper.classList.remove('loading');
     
    }
  
    createTexture (url, onLoaded) {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = url?.replace('.webp', '.png');
      const texture = new THREE.Texture(img);
      texture.encoding = THREE.sRGBEncoding;
      texture.generateMipmaps = false;
      texture.minFilter = THREE.NearestFilter;
      texture.magFilter = THREE.NearestFilter;
      texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
      this.currentTexture = texture;
      img.addEventListener('load', () => {
        onLoaded(texture);
      });
  
      img.crossOrigin = 'anonymous';
      // img.src = url?.replace(/(.png)/gmi, '.jpg') || defaultSwoosh;
    }
  
    createRangeFinderTexture (url, shader) {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = url?.replace('.webp', '.png');
      img.addEventListener('load', (e) => {
        const texture = new THREE.Texture(img);
        texture.encoding = THREE.sRGBEncoding;
        texture.generateMipmaps = false;
        texture.minFilter = THREE.NearestFilter;
        texture.magFilter = THREE.NearestFilter;
        texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
        shader.uniforms.newTexture.value = texture;
        shader.uniforms.newTexture.value.needsUpdate = true;
      });
    }



    insertMediaView (selector, url, index) {
      const width = window.innerWidth;
      const height = window.innerHeight;
  
      this.createTexture(url, (texture) => {
        texture.needsUpdate = true;
        texture.encoding = THREE.sRGBEncoding;
        texture.generateMipmaps = false;
        texture.minFilter = THREE.NearestFilter;
        texture.magFilter = THREE.NearestFilter;
        
        const scene = placedCanvas({
          parent: document.querySelector('.renderer'),
          uniforms: {
            // GENERAL
            seed: (Math.random() * 1000),
            time: new THREE.Uniform(1),
            opacity: new THREE.Uniform(1),
            pixr: new THREE.Uniform(window.devicePixelRatio),
            res: new THREE.Uniform(new THREE.Vector2(width, height)),
            
            // IMG
            img: new THREE.Uniform(texture),
            newTexture: new THREE.Uniform(texture),
            offset: new THREE.Uniform(new THREE.Vector2(0, 0.31)),
            imgRes: new THREE.Uniform(new THREE.Vector2(
              texture.image.width,
              texture.image.height
            )),
            
            mosaic: new THREE.Uniform(1),
            pixels: new THREE.Uniform(1),
            rangeFinder: new THREE.Uniform(new THREE.Vector2(200, 200)),
            zoom: new THREE.Uniform(0.47),
          },
          renderer: this.renderer,
          shader: {
            vert,
            frag: frag
          },
        });
   
        this.scenes[index] = scene;

        let timelineObject = {
          images: this.imageGalleryArray,
          updateTex: this.updateAsset,
          parent: this,
          rangeFinder: this.rangeFinder,
          viewFinderMarks: this.viewFinderMarks,
          topCredit: this.creditsTexts[0],
          bottomCredit: this.creditsTexts[1],
          snkrNameArray: this.nameGalleryArray,
          wrapper: this.wrapper,
          renderer: this.renderer,
          dropTime: this.dropTimeArray,
          maxSnickers: this.maxSnickers
        }
   

        
        if (this.scenes.filter(s => s).length === this.loadIndex) {
          // if(!this.tl) {
           this.tl = createTL(this.scenes, this.onComplete, this.onStart.bind(this), this.credits, timelineObject);
          // }
          if (this.delayedPlay) {
            this.tl.play(0);
          }
        }
      });
    }

    showRemaining (end, timer) {
      let now = new Date();
      let _second = 1000;
      let _minute = _second * 60;
      let _hour = _minute * 60;
      let _day = _hour *24;
  
      let distance = end - now;
      if (distance <= 0 ) {
        clearInterval( timer ); 
        return 'TODAY';
      }
      let hours = Math.floor( (distance % _day) / _hour );
      let minutes = Math.floor( (distance % _hour) / _minute );
      let seconds = Math.floor( (distance % _minute) / _second );
      let milliseconds = String(distance % _second).slice(0, 2);
      this.wrapper.querySelector('.vt-bottom h2').innerHTML = `${("0" + hours).slice(-2)}:${("0" + minutes).slice(-2)}:${("0" +  seconds).slice(-2)}:${milliseconds}`;
    }
  
    renderScene (scene, renderer) {
      const element = scene.userData.parent;
      const rect = element.getBoundingClientRect();
  
      const width = (rect.right - rect.left);
      const height = (rect.bottom - rect.top);
      const left = rect.left;
      const bottom = (renderer.domElement.clientHeight - rect.bottom);
      const vp = new THREE.Vector4(left, bottom, width, height);
  
      renderer.setViewport(vp);
      renderer.setScissor(vp);
  
      scene.userData.shader.uniforms.time.value++;
      scene.userData.shader.uniforms.res.value.x = width;
      scene.userData.shader.uniforms.res.value.y = height;
  
      renderer.render(scene, scene.userData.camera);
    }
  
    render () {
      if (this.running) {
        this.renderer.setScissorTest(true);
        this.scenes.forEach((scene) => {
          this.renderScene(scene, this.renderer);
        });
        this.renderer.setScissorTest(false);
      }
  
      this.loop = requestAnimationFrame(this.render.bind(this));
    }

    start () {
      this.wrapper.style.display = 'block';
      this.wrapper.style.clipPath = `ellipse(${window.innerWidth/4}px ${window.innerWidth/4}px at ${window.innerWidth/2}px ${window.innerHeight/2}px)`;
      this.renderer.domElement.style.clipPath = `ellipse(${window.innerWidth/4}px ${window.innerWidth/4}px at ${window.innerWidth/2}px ${window.innerHeight/2}px)`;
      this.renderer.domElement.style.zIndex = "20";
      document.querySelector('.canvas-wrapper-front').style.display = 'block';
 
      this.createNextArray();
      if (this.tl) {
        this.tl.play(0);
      } else {
        this.delayedPlay = true;
      }
      this.running = true;
      this.loop = requestAnimationFrame(this.render.bind(this));
    }
    stop () {
      this.wrapper.style.display = 'none';
      this.running = false;
      this.tl?.pause();
      this.tl?.seek(0);
      cancelAnimationFrame(this.loop);
      document.querySelector('.canvas-wrapper-front').style.display = 'none';
      this.renderer.domElement.style.zIndex = "0";
    }
    pause () {
      this.running = false;
      this.tl?.pause();
    }
    resume () {
      this.tl?.resume();
      this.running = true;
    }
  }
  export default Snkr;
  