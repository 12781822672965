import React, { Component } from 'react';
import { isNumber } from "lodash";
import { getDisplay } from './api';
import AppBootstrap from "../../touchpoints/hoi/animations/AppBootstrap";

const REFRESH_RATE = 60 * 1000;

import { DATA_BASE_URL } from '../../config';
import Overlay from "../../touchpoints/core/animations/overlay/Overlay";

class Template extends Component {

    constructor(props) {
        super(props);
        this.state = {
            display: null,
            animation: null,
        }
    }

    componentDidMount() {
        this.loadData();
    }


    isDifferent = (existing, updated) => {
        console.log('[Template]', 'isDifferent()', { existing, updated });

        if ((!existing && updated) || (existing && !updated)) {
            return true;
        }

        const types = ['animation', 'collection']

        for (let i = 0; i < types.length; i++) {

            const k = types[i];

            if ((existing[k] && !updated[k]) || (!existing[k] && updated[k])) {
                console.log(k, 'is different')
                return true;
            }

            if (existing[k] && updated[k] && existing[k].id !== updated[k].id) {
                console.log(k, 'id at is different')
                return true;
            }

            if (existing[k] && updated[k] && existing[k].updated_at !== updated[k].updated_at) {
                console.log(k, 'updated at is different')
                return true;
            }

            if (k === 'collection' && existing[k] && updated[k] && JSON.stringify(existing[k].json_data.animations) !== JSON.stringify(updated[k].json_data.animations)) {
                // check to see if animations within the collection were updated
                return true;
            }
        }
    }

    loadData = async () => {
        console.log('[Template]', 'loadData()');

        const { displayId } = this.props;
        const { display } = this.state;

        if ((display && display.id && !displayId) || (displayId && display && String(display.id) !== String(displayId))) {
            // display has changed
            window.location.reload();
            return;
        }

        if (displayId) {
		    const update = await getDisplay({ displayId });

            if (display && (this.isDifferent(display, update))) {
                // a new animation has been assigned to this display, hack right now is to reload window
                window.location.reload();
            } else if (!display) {
                // animation wasn't previously set, so set new animation and start refresh
                this.setState({ display: update })
            }
        }

        setTimeout(this.loadData, REFRESH_RATE);
    }


    loadExternal = async () => {
        console.log('[Template]', 'loadExternal()', { state: this.state, props: this.props });
        const { display: { animation: { json_data: { js, json } } } } = this.state;
        await new Promise((res, rej) => {
            const script = document.createElement("script");
            script.src = js;
            script.async = true;
            document.body.appendChild(script);
            script.onload = res;
            script.onerror = rej;
        });

        window.Template.init({ divId: 'hyperlive-container', apiBaseUrl: DATA_BASE_URL, data: JSON.parse(json) })
    }


    buildTicker = (collection) => {
        console.log('[Template]', '.buildTicker()', { _t: this, collection, TickerAnimation: window.TickerAnimation });


        if (!this.ticker && window.TickerAnimation) {
            this.ticker = new window.TickerAnimation({ logger: console });
        }

        if (!collection || (!collection.collection || collection.collection.length <= 0)) {
            return;
        }

        this.ticker.destroy();

        const { displayWidth, displayHeight, animations } = collection;

        const formattedAnimations = animations.map((a) => {
            const { id, json_data } = a;
            const { container: { width, height }, orientation, speed, direction, backColor, frontColor, theme, space, items } = json_data;

            const content = [];

            items.forEach((item) => {
                const { font, value } = item;

                if (item.type === 'typography') {
                    content.push({ type: 'text', copy: value, font: font ? font.toLowerCase() : 'futura' });
                }

                if (item.type === 'icon') {
                    content.push({ type: 'image', url: `/ticker/res/svg/${value}.svg` });
                }

                content.push({ 'type': 'spacer', 'width': space.width });
            });

            return {
                id,
                width,
                height,
                orientation,
                speed,
                direction,
				theme,
                frontColor,
                backColor,
                content,
            };

        });

        const ticker = JSON.parse(JSON.stringify({
            divId: 'hyperlive-container',
            data: {
                displayWidth,
                displayHeight,
                animations: formattedAnimations,
                collection: collection.collection,
            }
        }));

        this.ticker.init(ticker);

        return ticker;
    }


    buildCalendar = (data) => {


        console.log('[Template]', '.buildCalendar()', { _t: this, data, CalendarAnimation: window.CalendarAnimation });

        if (!this.calendar && window.CalendarAnimation) {
            this.calendar = new window.CalendarAnimation({ logger: console });
        }

        this.calendar.destroy();

        const calendar = JSON.parse(JSON.stringify({
            divId: 'hyperlive-container',
            data
        }));

        this.calendar.init(calendar);

        setTimeout(()=>{
            window.location.reload(true);
        },(4*60*60*1000));

        return calendar;
    }

    loadTemplate = () => {
        console.log('[Template]', 'loadTemplate()', { state: this.state, props: this.props });
        const { display } = this.state;

        if (!display) {
            return null;
        }

        const { collection, animation } = display;

        // PEDESTAL: 1
        // SLIDESHOW: 2
        // SPEEDSHOP: 3
        // VIDEO: 4
        // VISIBLE_HEAT: 5
        // TICKER: 6
        // EXTERNAL: 7
        // CALENDAR: 8

        const isAnimationAssigned = animation && isNumber(animation.animation_type_id);
        if (isAnimationAssigned) {
            switch (animation.animation_type_id) {
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
					new AppBootstrap(document.querySelector('.hyperlive-container'), DATA_BASE_URL, null);
                    break;
					// new VisibleHeatTest(document.querySelector('.hyperlive-container'), DATA_BASE_URL, display);
					// break;
                case 6:
                    this.buildTicker({
                        displayWidth: display.json_data.width,
                        displayHeight: display.json_data.height,
                        animations: [animation],
                        collection: [
                            {
                                id: animation.id,
                                x: 0,
                                y: 0
                            }
                        ]
                    })
                    break;
                case 7:
                    this.loadExternal()
                    break;
                case 8:
                    this.buildCalendar(animation.json_data)
                    break;
                default:
                    break;
            }
        }

        if (collection) {
			console.log('json', collection);
            this.buildTicker(collection.json_data);
        }

        if (!isAnimationAssigned && !collection) {
            return <Overlay />;
        }

        return null;
    }

    render() {
        console.log('[Template]', 'render()', { state: this.state, props: this.props });
        return (<div>{this.loadTemplate()}</div>)
    }
}

export default Template;
